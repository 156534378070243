/**
 * Encapsulates what states a form can be in
 */
export enum FormStates {
  /**
   * Data is being loaded prior to the user being able to fill in the form
   */
  Loading = 'LOADING',
  /**
   * The form is in a neutral state and can be filled in and submitted
   */
  Ready = 'READY',
  /**
   * The form is awaiting some temporary processing and cannot be submitted at this time
   */
  Busy = 'BUSY',
  /**
   * Submission is disabled
   */
  Disabled = 'DISABLED',
  /**
   * The form is currently being submitted
   */
  Submitting = 'SUBMITTING',
  /**
   * Submission has been attempted but has failed. It's expected that the user can modify the form and re-submit
   */
  Failure = 'FAILURE',
  /**
   * Submission of the form has succeeded
   */
  Success = 'SUCCESS',
}

export const allFormStates = Object.values(FormStates)
