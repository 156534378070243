export class UserCannotBeRemovedFromCourtSystemsError extends Error {
  constructor(public error: any) {
    super(error.message)
    this.message = getMarkdownFormat(error.email, error.courtSystemNames)
    Object.setPrototypeOf(this, UserCannotBeRemovedFromCourtSystemsError.prototype)
  }
}

function getMarkdownFormat(email: string, courtSystemNames: string[]): string {
  return `${email} cannot be removed from the following court systems because they are the only user that can manage User Groups: 

- ${courtSystemNames.join('\n- ')}`
}
