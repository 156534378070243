import { Injectable } from '@angular/core'
import { SimpleWindowRefService } from '../window/simple-window-ref.service'

type BrowserPatternOptions = Record<string, RegExp>
type RenderingEnginePatterns = Record<string, RegExp>

@Injectable({
  providedIn: 'root',
})
export class BrowserSnifferService {
  userAgent: string
  constructor(private readonly windowRefService: SimpleWindowRefService) {
    this.userAgent = this.windowRefService.nativeWindow().navigator.userAgent
  }

  // based on information available at https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
  BROWSER_PATTERNS: BrowserPatternOptions = {
    chrome: /Chrome\/([0-9.]+)/,
    edge: /Edg.*\/([0-9.]+)/,
    safari: /Safari\/([0-9.]+)/,
    android: /Android\s([0-9.]+)/,
    firefox: /Firefox\/([0-9.]+)/,
    seamonkey: /SeaMonkey\/([0-9.]+)/,
    chromium: /Chromium\/([0-9.]+)/,
  }

  RENDERING_ENGINE_PATTERNS: RenderingEnginePatterns = {
    gecko: /Gecko\/([0-9]+)/,
    webkit: /AppleWebKit\/([0-9.]+)/,
    blink: /Chrome\/([0-9.]+)/,
  }

  isSafari(): boolean {
    return this.check(this.BROWSER_PATTERNS.safari) && !this.isChromium() && !this.isChrome() && !this.isEdge()
  }

  isAndroid(): boolean {
    return this.check(this.BROWSER_PATTERNS.android)
  }

  isSeaMonkey(): boolean {
    return this.check(this.BROWSER_PATTERNS.seamonkey) && this.isGecko()
  }

  isFirefox(): boolean {
    return this.check(this.BROWSER_PATTERNS.firefox) && this.isGecko() && !this.isSeaMonkey()
  }

  isEdge(): boolean {
    return this.check(this.BROWSER_PATTERNS.edge)
  }

  isChrome(): boolean {
    return this.check(this.BROWSER_PATTERNS.chrome) && !this.isEdge() && !this.isChromium()
  }

  isChromium(): boolean {
    return this.check(this.BROWSER_PATTERNS.chromium)
  }

  isGecko(): boolean {
    return this.check(this.RENDERING_ENGINE_PATTERNS.gecko) && !this.isWebKit()
  }

  isBlink(): boolean {
    return this.check(this.RENDERING_ENGINE_PATTERNS.blink)
  }

  isWebKit(): boolean {
    return this.check(this.RENDERING_ENGINE_PATTERNS.webkit)
  }

  isChromeLike(): boolean {
    return this.isChrome() || this.isChromium() || this.isEdge()
  }

  check(pattern: RegExp): boolean {
    return pattern.test(this.userAgent)
  }
}
