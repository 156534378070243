import { ConnectedPosition } from '@angular/cdk/overlay'
import { assertUnreachable } from '@ftr/contracts/shared/assertUnreachable'
import { ALIGNMENTS_CLASS_PREFIX, OFFSET } from './tooltip-constants'

export type TooltipPosition = 'left' | 'right' | 'above' | 'below'

/**
 * This is relative to vertical and horizontal positions of the tooltip
 * When position is above/below
 * Start = Left, End = Right
 *
 * When position is left/right
 * Start = Top, End = Bottom
 */
export type TooltipAlignment = 'start' | 'center' | 'end' | 'auto' | 'mouse'

export interface Point {
  x: number
  y: number
}

export function getOppositeAxisOffset(
  tooltipPosition: TooltipPosition,
  tooltipAlignment: TooltipAlignment,
  relativeMouseCoords?: Point,
): number {
  if (tooltipAlignment === 'mouse' && relativeMouseCoords) {
    switch (tooltipPosition) {
      case 'left':
      case 'right':
        return relativeMouseCoords.y
      case 'above':
      case 'below':
        return relativeMouseCoords.x
      default:
        assertUnreachable(tooltipPosition)
    }
  }

  return 0
}

export function getVerticalAlignment(tooltipAlignment: TooltipAlignment): 'top' | 'center' | 'bottom' {
  switch (tooltipAlignment) {
    case 'start':
      return 'top'
    case 'auto':
    case 'center':
      return 'center'
    case 'end':
      return 'bottom'
    case 'mouse':
      return 'top'
    default:
      assertUnreachable(tooltipAlignment)
  }
}
export function getOverlayVerticalAlignment(tooltipAlignment: TooltipAlignment): 'top' | 'center' | 'bottom' {
  if (tooltipAlignment === 'mouse') {
    return 'center'
  }
  return getVerticalAlignment(tooltipAlignment)
}

export function getHorizontalAlignment(tooltipAlignment: TooltipAlignment): 'start' | 'center' | 'end' {
  switch (tooltipAlignment) {
    case 'start':
      return 'start'
    case 'auto':
    case 'center':
      return 'center'
    case 'end':
      return 'end'
    case 'mouse':
      return 'start'
    default:
      assertUnreachable(tooltipAlignment)
  }
}

export function getOverlayHorizontalAlignment(tooltipAlignment: TooltipAlignment): 'start' | 'center' | 'end' {
  if (tooltipAlignment === 'mouse') {
    return 'center'
  }
  return getHorizontalAlignment(tooltipAlignment)
}

export function invertPosition(tooltipPosition: TooltipPosition): TooltipPosition {
  switch (tooltipPosition) {
    case 'left':
      return 'right'
    case 'right':
      return 'left'
    case 'above':
      return 'below'
    case 'below':
      return 'above'
    default:
      assertUnreachable(tooltipPosition)
  }
}

export function getPosition(
  position: TooltipPosition,
  tooltipAlignment: TooltipAlignment,
  relativeMouseCoords?: Point,
): ConnectedPosition {
  const panelClass = `${ALIGNMENTS_CLASS_PREFIX}-${position}-${tooltipAlignment}`
  switch (position) {
    case 'left':
      return {
        originX: 'start',
        originY: getVerticalAlignment(tooltipAlignment),
        overlayX: 'end',
        overlayY: getOverlayVerticalAlignment(tooltipAlignment),
        offsetX: OFFSET * -1,
        offsetY: getOppositeAxisOffset(position, tooltipAlignment, relativeMouseCoords),
        panelClass,
      }
    case 'right':
      return {
        originX: 'end',
        originY: getVerticalAlignment(tooltipAlignment),
        overlayX: 'start',
        overlayY: getOverlayVerticalAlignment(tooltipAlignment),
        offsetX: OFFSET,
        offsetY: getOppositeAxisOffset(position, tooltipAlignment, relativeMouseCoords),
        panelClass,
      }
    case 'above':
      return {
        originX: getHorizontalAlignment(tooltipAlignment),
        originY: 'top',
        overlayX: getOverlayHorizontalAlignment(tooltipAlignment),
        overlayY: 'bottom',
        offsetX: getOppositeAxisOffset(position, tooltipAlignment, relativeMouseCoords),
        offsetY: OFFSET * -1,
        panelClass,
      }
    case 'below':
      return {
        originX: getHorizontalAlignment(tooltipAlignment),
        originY: 'bottom',
        overlayX: getOverlayHorizontalAlignment(tooltipAlignment),
        overlayY: 'top',
        offsetX: getOppositeAxisOffset(position, tooltipAlignment, relativeMouseCoords),
        offsetY: OFFSET,
        panelClass,
      }
    default:
      assertUnreachable(position)
  }
}
