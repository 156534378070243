import { LocalDateTime, nativeJs } from '@js-joda/core'
import { Interval } from 'node-interval-tree'

export class Segment implements Interval {
  constructor(
    readonly high: number,
    readonly low: number,
    readonly id: string,
  ) {}

  getLocalStart(): LocalDateTime {
    return LocalDateTime.from(nativeJs(new Date(this.low)))
  }

  getLocalEnd(): LocalDateTime {
    return LocalDateTime.from(nativeJs(new Date(this.high)))
  }
}
