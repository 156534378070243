<ftr-untyped-form
  class="login__form form-group"
  name="login"
  [submission]="submission"
  [formGroup]="loginForm"
  (formSubmit)="submitForm(loginForm.value)"
  submitLabel="Log in"
  submittingLabel="Logging in"
  [formTemplate]="form"
  [submitFullWidth]="true"
  [submitDirection]="undefined"
>
  <ng-template #form let-highlightErrors="highlightErrors">
    <ul class="form-list">
      <li>
        <ftr-form-input
          name="email"
          label="Email"
          type="email"
          [notrack]="true"
          [ngFormControl]="asFormControl(loginForm.controls['email'])"
          [autofocus]="true"
          [required]="true"
          [spellcheck]="false"
          [highlightError]="highlightErrors"
        >
        </ftr-form-input>
      </li>
      <li>
        <ftr-password
          [ngFormControl]="asFormControl(loginForm.controls['password'])"
          [highlightError]="highlightErrors"
          autocomplete="current-password"
        ></ftr-password>
      </li>
    </ul>
    <div class="text-right">
      <a class="login__form__forgot" routerLink="/reset" data-track>Forgot password?</a>
    </div>
  </ng-template>
</ftr-untyped-form>
