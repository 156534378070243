<div class="footer-logged-in">
  <div class="footer-logged-in__row row justify-content-between">
    <div class="footer-logged-in__menu col-10 col-md-9 align-items-start">
      <ftr-logo [theme]="logoThemes.White"> </ftr-logo>
      <div class="footer-logged-in__links align-self-lg-center">
        @for (menuItem of menuItems | async; track menuItem) {
          <a
            data-track
            [routerLink]="menuItem.path"
            [queryParams]="menuItem.queryParams"
            class="footer-logged-in__link"
          >
            {{ menuItem.title }}
          </a>
        }
      </div>
    </div>
    <div class="footer-logged-in__general-menu">
      <div class="footer-logged-in__general-link">
        <a class="footer-logged-in__link" href="https://fortherecord.releasenotes.io/" target="_blank"
          >Product Updates</a
        >
      </div>
      <ftr-footer-help-links class="footer-logged-in__general-link"></ftr-footer-help-links>
    </div>
  </div>
</div>
