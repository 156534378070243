import { AudioSegmentPlaybackData } from '@ftr/contracts/api/audio-segment'
import { IndexedRecording } from '@ftr/contracts/api/search'
import { AssetStatus, getRecordingAssetStatus, RecordingSegment, RecordingWithSegments } from '@ftr/contracts/read'
import { FeatureName } from '@ftr/contracts/type/core'
import { RecordingType } from '@ftr/contracts/type/recording'
import { LocalDateTimeRange, Uuid } from '@ftr/contracts/type/shared'
import { ArrayUtils, isNotNullOrUndefined } from '@ftr/foundation'
import { AppPaths, CourtSystemPaths, RecordingPlaybackPaths } from '@ftr/routing-paths'

/**
 * Note: When you first click the "Unarchive" button, the assets will still be missing,
 * as they get set to 'in progress' via bus, thus we use "Completed" asset status to refer to the number of
 * RecordingSegments which have definitely completed conversion
 */
export const COMPLETED_ASSET_STATUSES = new Set([AssetStatus.Broken, AssetStatus.Exists])

export enum PlaybackStatus {
  /**
   * Ready For Playback
   */
  Ready,
  /**
   * We are waiting for conversion events
   */
  Loading,
  /**
   * No conversion is happening, we can request it
   */
  Archived,
}

function _isUnplayableRecording(featureMap: Map<FeatureName, boolean>, assetStatus: AssetStatus): boolean {
  if (!featureMap.get(FeatureName.AudioPlayback)) {
    return true
  } else if (!featureMap.get(FeatureName.Archiving) && assetStatus !== AssetStatus.Exists) {
    return true
  }
  return assetStatus === AssetStatus.Broken
}

/**
 * The recording is unplayable in 3 cases:
 *
 * 1. Audio Playback is disabled for the court (We should hit this with the breadcrumb resolver)
 * 2. It's a Broken Recording (Every TRM in the recording failed conversion)
 * 3. Archiving is off, and assets don't exist (Users cant unarchive if the feature is off)
 */
export function isUnplayableRecording(
  featureMap: Map<FeatureName, boolean>,
  recording: { segments: RecordingSegment[] },
): boolean {
  const assetStatus = getRecordingAssetStatus(recording!)
  return _isUnplayableRecording(featureMap, assetStatus)
}

/**
 * The indexed recording is marked unplayable in 3 cases:
 *
 * 1. Audio Playback is disabled for the court (We should hit this with the breadcrumb resolver)
 * 2. It's a Broken Recording (Every TRM in the recording failed conversion)
 * 3. Archiving is off, and assets don't exist (Users cant unarchive if the feature is off)
 */
export function isUnplayableIndexedRecording(
  featureMap: Map<FeatureName, boolean>,
  recording: IndexedRecording,
): boolean {
  return _isUnplayableRecording(featureMap, recording.assetStatus)
}

/**
 * The audio segment is unplayable in 3 cases:
 *
 * 1. Audio Playback is disabled for the court (We should hit this with the breadcrumb resolver)
 * 2. It's a Broken Recording (Every TRM in the recording failed conversion)
 * 3. Archiving is off, and assets don't exist (Users can't unarchive if the feature is off)
 */
export function isUnplayableAudioSegment(
  featureMap: Map<FeatureName, boolean>,
  audioSegmentPlaybackData: AudioSegmentPlaybackData,
): boolean {
  const statuses = audioSegmentPlaybackData.recordingSegments.map(x => x.assetStatus)
  if (!featureMap.get(FeatureName.AudioPlayback)) {
    return true
  } else if (!featureMap.get(FeatureName.Archiving) && !statuses.includes(AssetStatus.Exists)) {
    return true
  }
  return statuses.every(status => status === AssetStatus.Broken)
}

export function getAudioSegmentPlaybackState(audioSegment: AudioSegmentPlaybackData): PlaybackStatus {
  const statuses = new Set(audioSegment.recordingSegments.map(x => x.assetStatus))
  if (statuses.has(AssetStatus.Generating)) {
    return PlaybackStatus.Loading
  }

  if (statuses.has(AssetStatus.Exists)) {
    return PlaybackStatus.Ready
  }

  return PlaybackStatus.Archived
}

/**
 * We are ready to navigate to the recording page if
 *
 * - There is at least 1 converted TRM
 * - 0 or 1 segment is in "in progress" (any more than this, and we risk a really jittery playback experience as
 * conversions/loudness/stt come in via socket at once due to archiving)
 *   - The last segment being "in progress" is very common (For today's recordings, as new TRMs are constantly uploaded)
 * - There are no middle segments loading (If middle segments are loading, they may break the playlist when they load)
 */
export function getPlaybackState(recordingWithSegments: RecordingWithSegments): PlaybackStatus {
  const sortedAssetStatuses = getSortedAssetStatuses(recordingWithSegments)
  const isReady =
    getRecordingAssetStatus(recordingWithSegments) === AssetStatus.Exists &&
    areGeneratingSegmentsSafe(sortedAssetStatuses)

  if (isReady) {
    return PlaybackStatus.Ready
  }

  if (sortedAssetStatuses.includes(AssetStatus.Generating)) {
    return PlaybackStatus.Loading
  }

  return PlaybackStatus.Archived
}

export function getRecordingTypeForAudioSegment(audioSegment: AudioSegmentPlaybackData): RecordingType {
  return audioSegment.recordingSegments[0].recordingType
}

function areGeneratingSegmentsSafe(assetStatuses: AssetStatus[]): boolean {
  const generatingSegments = ArrayUtils.count(assetStatuses, AssetStatus.Generating)
  if (generatingSegments === 0) {
    return true
  }

  if (generatingSegments > 2) {
    return false
  }

  if (generatingSegments === 2) {
    return areTheLastTwoSegmentsGenerating(assetStatuses)
  }

  return isOneOfTheLastTwoSegmentsLoading(assetStatuses)
}

export function getRecordingTimeframe(recordingWithSegments: RecordingWithSegments): LocalDateTimeRange {
  if (recordingWithSegments.segments.length === 0) {
    throw new Error("Recordings shouldn't exist with 0 segments")
  }

  // Note: If segments don't have timecodes we ignore them
  const sorted = getSortedSegments(recordingWithSegments).filter(x => x.timecode)

  const start = sorted[0].timecode!.start
  const end = sorted.at(-1)!.timecode!.end
  return new LocalDateTimeRange(start, end)
}

function getSortedSegments(recordingWithSegments: RecordingWithSegments): RecordingSegment[] {
  // Note: When retrieved via the api, this is a readonly array and sort doesn't work
  const recordingSegments = [...recordingWithSegments.segments]
  return recordingSegments.sort((a, b) => a.startDateTime.compareTo(b.startDateTime))
}

function getSortedAssetStatuses(recordingWithSegments: RecordingWithSegments): AssetStatus[] {
  return getSortedSegments(recordingWithSegments).map(x => x.assetStatus)
}

function areTheLastTwoSegmentsGenerating(assetStatuses: AssetStatus[]): boolean {
  return assetStatuses.at(-1) === AssetStatus.Generating && assetStatuses.at(-2) === AssetStatus.Generating
}

/**
 * Allow one of the last 2 segments to be loading.
 *
 * Last is loading: Current recording being uploaded on demand (lastest TRM)
 * Second to last is loading: Probably a stuck TRM, once 10 minutes have passed it will become "Failed"
 */
function isOneOfTheLastTwoSegmentsLoading(assetStatuses: AssetStatus[]): boolean {
  const lastIsLoading = assetStatuses.at(-1) === AssetStatus.Generating

  if (!lastIsLoading && assetStatuses.length >= 2) {
    return assetStatuses.at(-2) === AssetStatus.Generating
  }

  return true
}

export function getRecordingPath(
  courtSystemId: Uuid,
  recordingId: Uuid,
  recordingType: RecordingType = RecordingType.TRM,
  // Absolute path by default
  relative = false,
): string[] {
  return [
    relative ? undefined : '/',
    AppPaths.CourtSystem,
    courtSystemId,
    CourtSystemPaths.RecordingPlayback,
    // Local Capture and Stream share the same path
    recordingType === RecordingType.TRM ? undefined : RecordingPlaybackPaths.StreamRecording,
    recordingId,
  ].filter(isNotNullOrUndefined)
}
