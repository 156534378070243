import { AfterViewInit, Component, computed, ElementRef, signal, ViewChild } from '@angular/core'
import { toSignal } from '@angular/core/rxjs-interop'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { debounceTime, filter, fromEvent, map, startWith, takeUntil } from 'rxjs'
import { ScreenSize, ScreenSizeService, SimpleWindowRefService } from '../../services'
import { DestroySubscribers } from '../../util'
import { BreadcrumbService } from './breadcrumb.service'

/**
 *
 * Renders breadcrumbs for the activated route.
 *
 * Breadcrumbs are derived from the route hierarchy. BreadcrumbService traverses the route hierarchy top-down and looks
 * for a breadcrumb string in the route data, using that and the route's URL to build a Breadcrumb item.
 *
 * For dynamic breadcrumb, a data resolver can be used by implementing the helper type BreadcrumbResolver and returning
 * the desired breadcrumb label (e.g.: fetching a court system's name by its id).
 *
 * @example
 *
 * // Route setup
 *
 * [{
 *   path: '',
 *   data: {
 *     breadcrumb: 'Breadcrumb for parent route'
 *   },
 *   children: [
 *     {
 *       path: 'child',
 *       resolve: {
 *         data: CustomBreadcrumbResolver
 *       }
 *     }
 *   ]
 * }]
 *
 * // CustomBreadcrumbResolver
 *
 * export class CustomBreadcrumbResolver implements BreadcrumbResolver {
 *   resolve (
 *     route: ActivatedRouteSnapshot,
 *     state: RouterStateSnapshot
 *   ): (string | undefined) | Promise<string | undefined> | Observable<string | undefined> {
 *     return 'Dynamic breadcrumb label'
 *   }
 * }
 *
 * // Resulting breadcrumb for the 'child' route
 *
 * [
 *   {
 *     label: 'Breadcrumb for parent route',
 *     url: '/'
 *   },
 *   {
 *     label: 'Dynamic breadcrumb label',
 *     url: '/child
 *   }
 * ]
 *
 */
@Component({
  selector: 'ftr-breadcrumbs',
  templateUrl: 'breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css'],
})
export class BreadcrumbsComponent extends DestroySubscribers implements AfterViewInit {
  protected breadcrumbs = toSignal(
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.breadcrumbsService.getBreadcrumbs(this.activatedRoute.root)),
      startWith(this.breadcrumbsService.getBreadcrumbs(this.activatedRoute.root)),
      map(breadcrumbs => (breadcrumbs.length === 1 && breadcrumbs[0].link.url === '/' ? [] : breadcrumbs)),
    ),
    { initialValue: [] },
  )

  protected shouldCollapse = computed(() => {
    // If there's <= 2 crumbs, the breadcrumb just includes Home and the leaf node
    // So there's nothing to collapse
    const enoughCrumbsToCollapse = this.breadcrumbs().length > 2
    const mediumDownViewport = this.screenSizeService.sizeSignal() <= ScreenSize.Medium
    return enoughCrumbsToCollapse && (mediumDownViewport || this.exceedsViewportWidth())
  })

  private exceedsViewportWidth = signal<boolean>(false)
  protected clampLeaf = signal<boolean>(false)
  protected clampLink = signal<boolean>(false)

  @ViewChild('outerCrumbs') outerCrumbs: ElementRef<HTMLElement>
  @ViewChild('innerCrumbs') innerCrumbs: ElementRef<HTMLElement>
  @ViewChild('measurementCrumbs') measurementCrumbs: ElementRef<HTMLElement>

  constructor(
    private readonly breadcrumbsService: BreadcrumbService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly screenSizeService: ScreenSizeService,
    private readonly windowService: SimpleWindowRefService,
  ) {
    super()
  }

  ngAfterViewInit(): void {
    this.calculateBreadcrumbContentCollapse()
    fromEvent(this.windowService.nativeWindow(), 'resize')
      .pipe(takeUntil(this.finalize), debounceTime(100))
      .subscribe(() => {
        this.calculateBreadcrumbContentCollapse()
      })
  }

  private calculateBreadcrumbContentCollapse(): void {
    const elementHeight =
      this.measurementCrumbs.nativeElement.querySelector('.breadcrumbs')?.getBoundingClientRect().height || 0
    const parentHeight = this.outerCrumbs.nativeElement.offsetHeight
    this.exceedsViewportWidth.set(elementHeight > parentHeight)

    const leafCrumbHeight =
      this.innerCrumbs.nativeElement.querySelector('.breadcrumbs__item--current')?.getBoundingClientRect().height || 0
    const leafCrumbScrollHeight =
      this.innerCrumbs.nativeElement.querySelector('.breadcrumbs__item--current')?.scrollHeight || 0
    this.clampLeaf.set(leafCrumbScrollHeight > leafCrumbHeight)

    const linkCrumbHeight =
      this.innerCrumbs.nativeElement.querySelector('.breadcrumbs__link--collapsed')?.getBoundingClientRect().height || 0
    const linkCrumbScrollHeight =
      this.innerCrumbs.nativeElement.querySelector('.breadcrumbs__link--collapsed')?.scrollHeight || 0

    this.clampLink.set(linkCrumbScrollHeight > linkCrumbHeight)
  }
}
