import { DatePipe } from '@angular/common'
import { Component, TemplateRef, ViewChild } from '@angular/core'
import { RouterModule } from '@angular/router'
import { AuthEventType, LOGIN_STARTED } from '@ftr/api-shared'
import { UserAuditLoginHistory } from '@ftr/audit-contracts'
import {
  DateComponent,
  DateFormat,
  DestroySubscribers,
  FlagComponent,
  LocalStorageService,
  ToastComponent,
  ToastService,
} from '@ftr/foundation'
import { AppPaths } from '@ftr/routing-paths'
import { AuthenticationService } from '@ftr/ui-user'
import { Store } from '@ngxs/store'
import { ActiveToast } from 'ngx-toastr'
import { combineLatest, distinctUntilChanged, filter, switchMap, takeUntil } from 'rxjs'
import { AuditLogService } from '~app/features/audit-log/audit-log.service'

const DEFAULT_TOAST_TIMEOUT = 1 * 60 * 1000 // 60s

@Component({
  standalone: true,
  imports: [RouterModule, DatePipe, FlagComponent, DateComponent],
  selector: 'ftr-post-login-notification',
  templateUrl: './post-login-notification.component.html',
})
export class PostLoginNotificationComponent extends DestroySubscribers {
  @ViewChild('lastLoginMessage') lastLoginMessage: TemplateRef<any>
  @ViewChild('failedAttemptsMessage') failedAttemptsMessage: TemplateRef<any>
  @ViewChild('permissionsChangedMessage') permissionsChangedMessage: TemplateRef<any>

  loginHistory: UserAuditLoginHistory
  lastLogin:
    | (UserAuditLoginHistory['lastLogin'] & {
        lastLoginDate: Date
      })
    | undefined
  readonly auditLogPath = AppPaths.AuditLog
  readonly dateFormat = DateFormat

  private currentLastLoginToast: ActiveToast<ToastComponent> | undefined
  private currentFailedAttemptsToast: ActiveToast<ToastComponent> | undefined
  private permissionsChangedToast: ActiveToast<ToastComponent> | undefined

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly auditLogService: AuditLogService,
    private readonly localStorageService: LocalStorageService,
    private readonly toastService: ToastService,
    private readonly store: Store,
  ) {
    super()

    combineLatest([
      this.authenticationService.authEvents.pipe(
        takeUntil(this.finalize),
        distinctUntilChanged(),
        filter(event => event.type === AuthEventType.LoginComplete),
      ),
    ])
      .pipe(switchMap(() => this.auditLogService.getMyLoginHistory(this.localStorageService.getGlobal(LOGIN_STARTED))))
      .subscribe(response => {
        if (response.isSuccess()) {
          this.loginHistory = response._data
          this.lastLogin = this.loginHistory.lastLogin
            ? {
                ...this.loginHistory.lastLogin,
                lastLoginDate: new Date(this.loginHistory.lastLogin.timestamp),
              }
            : undefined

          if (this.loginHistory.loginAttempts > 0) {
            if (this.currentFailedAttemptsToast) {
              this.currentFailedAttemptsToast.toastRef.close()
            }
            this.currentFailedAttemptsToast = this.toastService.warning(
              this.failedAttemptsMessage,
              'Unsuccessful login attempts',
              {
                disableTimeOut: true,
              },
            )
          }

          if (this.loginHistory.permissionsChanged) {
            if (this.permissionsChangedToast) {
              this.permissionsChangedToast.toastRef.close()
            }
            this.permissionsChangedToast = this.toastService.warning(this.permissionsChangedMessage, 'Access Changed', {
              disableTimeOut: true,
            })
          }

          if (this.loginHistory.lastLogin) {
            if (this.currentLastLoginToast) {
              this.currentLastLoginToast.toastRef.close()
            }
            this.currentLastLoginToast = this.toastService.info(this.lastLoginMessage, 'Last successful login', {
              timeOut: DEFAULT_TOAST_TIMEOUT,
            })
          }
        }
      })
  }
}
