import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { SetKeyPress, SetKeyPressReleased } from './key-press.actions'
import { KeyPress, KeyPressModel } from './key-press.model'

export function defaultKeyPress(): KeyPressModel {
  return {
    keyPress: undefined,
  }
}

@State<KeyPressModel>({
  name: 'keyPress',
  defaults: defaultKeyPress(),
})
@Injectable()
export class KeyPressState {
  @Selector()
  static currentKeyboardShortcut(state: KeyPressModel): KeyPress | undefined {
    return state.keyPress
  }

  @Action(SetKeyPress)
  setKeyPress({ patchState }: StateContext<KeyPressModel>, { keyPress }: SetKeyPress): void {
    patchState({ keyPress })
  }

  @Action(SetKeyPressReleased)
  setKeyPressReleased({ patchState }: StateContext<KeyPressModel>): void {
    patchState({ keyPress: undefined })
  }
}
