<div class="collapsible">
  <div class="collapsible__action" (click)="toggle()">
    <a
      ><span class="collapsible__action-text">{{ getAction() }}</span> {{ title
      }}<ftr-icon align="Middle" [icon]="getIcon()"></ftr-icon
    ></a>
  </div>
  @if (isOpen) {
    <div class="collapsible__content">
      <ng-content></ng-content>
    </div>
  }
</div>
