import { Injectable } from '@angular/core'
import { assertUnreachable } from '@ftr/contracts/shared/assertUnreachable'
import { Uuid } from '@ftr/contracts/type/shared'
import { RealTimePlaybackKey } from '@ftr/data-realtime-playback'
import { LocalTime } from '@js-joda/core'
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store'
import { patch } from '@ngxs/store/operators'
import { memoize } from 'lodash-es'
import { TimelineSectionWithChildren } from '../types'
import { mapToNestedTimelineSections } from '../utils'
import {
  UpdateHearingTimelineSectionsCommand,
  UpdatePlaybackTimeCommand,
  UpdateRecordingTimelineLiveLocalTimeCommand,
  UpdateSealedTimelineSectionsCommand,
  UpdateSessionTimelineSectionsCommand,
} from './recording-timeline.commands'
import { RecordingTimelineInstanceStateModel, RecordingTimelineStateModel } from './recording-timeline.model'

export function defaultRecordingTimelineState(): RecordingTimelineStateModel {
  return {
    recordingTimelineInstanceState: {},
  }
}

export function defaultRecordingTimelineInstanceState(): RecordingTimelineInstanceStateModel {
  return {
    sessionTimes: [],
    hearingTimes: [],
    sealedTimes: [],
  }
}

@State<RecordingTimelineStateModel>({
  name: 'recordingTimelineState',
  defaults: defaultRecordingTimelineState(),
})
@Injectable()
export class RecordingTimelineState {
  @Selector()
  private static allRecordingTimelineInstanceStates(
    state: RecordingTimelineStateModel,
  ): Record<Uuid, RecordingTimelineInstanceStateModel> {
    return state.recordingTimelineInstanceState
  }

  static readonly timelineSections = memoize(
    (
      playbackKey: RealTimePlaybackKey,
    ): ((
      sourceStates: ReturnType<typeof RecordingTimelineState.allRecordingTimelineInstanceStates>,
    ) => TimelineSectionWithChildren[]) => {
      return createSelector(
        [RecordingTimelineState.allRecordingTimelineInstanceStates],
        (sourceStates: ReturnType<typeof RecordingTimelineState.allRecordingTimelineInstanceStates>) => {
          const instance = sourceStates[getInstanceId(playbackKey)] ?? defaultRecordingTimelineInstanceState()
          return mapToNestedTimelineSections(
            instance.sessionTimes,
            instance.hearingTimes,
            instance.sealedTimes,
            instance.liveLocalTime,
          )
        },
      )
    },
    playbackKey => getInstanceId(playbackKey),
  )

  static readonly playbackTime = memoize(
    (
      playbackKey: RealTimePlaybackKey,
    ): ((
      sourceStates: ReturnType<typeof RecordingTimelineState.allRecordingTimelineInstanceStates>,
    ) => LocalTime | undefined) => {
      return createSelector(
        [RecordingTimelineState.allRecordingTimelineInstanceStates],
        (sourceStates: ReturnType<typeof RecordingTimelineState.allRecordingTimelineInstanceStates>) => {
          const instance = sourceStates[getInstanceId(playbackKey)] ?? defaultRecordingTimelineInstanceState()
          return instance.playbackTime
        },
      )
    },
    playbackKey => getInstanceId(playbackKey),
  )

  @Action(UpdateHearingTimelineSectionsCommand)
  updateHearingTimelineSections(
    stateContext: StateContext<RecordingTimelineStateModel>,
    action: UpdateHearingTimelineSectionsCommand,
  ): void {
    const instanceId = getInstanceId(action.playbackKey)
    this.getOrInitializeRecordingTimelineInstance(stateContext, action)
    stateContext.setState(
      patch({
        recordingTimelineInstanceState: patch({
          [instanceId]: patch({
            hearingTimes: action.hearingTimelineSections,
          }),
        }),
      }),
    )
  }

  @Action(UpdateSessionTimelineSectionsCommand)
  updateSessionTimelineSections(
    stateContext: StateContext<RecordingTimelineStateModel>,
    action: UpdateSessionTimelineSectionsCommand,
  ): void {
    const instanceId = getInstanceId(action.playbackKey)
    this.getOrInitializeRecordingTimelineInstance(stateContext, action)
    stateContext.setState(
      patch({
        recordingTimelineInstanceState: patch({
          [instanceId]: patch({
            sessionTimes: action.sessionTimelineSections,
          }),
        }),
      }),
    )
  }

  @Action(UpdateSealedTimelineSectionsCommand)
  updateSealedTimelineSectionsCommand(
    stateContext: StateContext<RecordingTimelineStateModel>,
    action: UpdateSealedTimelineSectionsCommand,
  ): void {
    const instanceId = getInstanceId(action.playbackKey)
    this.getOrInitializeRecordingTimelineInstance(stateContext, action)
    stateContext.setState(
      patch({
        recordingTimelineInstanceState: patch({
          [instanceId]: patch({
            sealedTimes: action.sealedTimelineSections,
          }),
        }),
      }),
    )
  }

  @Action(UpdatePlaybackTimeCommand)
  updatePlaybackTime(stateContext: StateContext<RecordingTimelineStateModel>, action: UpdatePlaybackTimeCommand): void {
    const instanceId = getInstanceId(action.playbackKey)
    this.getOrInitializeRecordingTimelineInstance(stateContext, action)
    stateContext.setState(
      patch({
        recordingTimelineInstanceState: patch({
          [instanceId]: patch({
            playbackTime: action.playbackTime,
          }),
        }),
      }),
    )
  }

  @Action(UpdateRecordingTimelineLiveLocalTimeCommand)
  updateRecordingTimelineLiveLocalTime(
    stateContext: StateContext<RecordingTimelineStateModel>,
    action: UpdateRecordingTimelineLiveLocalTimeCommand,
  ): void {
    const instanceId = getInstanceId(action.playbackKey)
    this.getOrInitializeRecordingTimelineInstance(stateContext, action)
    stateContext.setState(
      patch({
        recordingTimelineInstanceState: patch({
          [instanceId]: patch({
            liveLocalTime: action.liveLocalTime,
          }),
        }),
      }),
    )
  }

  private getOrInitializeRecordingTimelineInstance(
    { getState, setState }: StateContext<RecordingTimelineStateModel>,
    { playbackKey }: { playbackKey: RealTimePlaybackKey },
  ): RecordingTimelineInstanceStateModel {
    const originalState = getState()
    const instanceId = getInstanceId(playbackKey)
    let instance = originalState.recordingTimelineInstanceState[instanceId]
    if (!instance) {
      instance = defaultRecordingTimelineInstanceState()
      setState(
        patch({
          recordingTimelineInstanceState: patch({
            [instanceId]: instance,
          }),
        }),
      )
    }
    return instance
  }
}

function getInstanceId(key: RealTimePlaybackKey): Uuid {
  switch (key.type) {
    case 'recording':
      return key.recordingId
    case 'audio-segment':
      return key.audioSegmentId
    case 'hearing':
      return key.hearingId
    default:
      assertUnreachable(key)
  }
}
