<div class="dialog__container">
  @if (isShowing && dialogRequest) {
    <div [@backdropEnterLeave] class="dialog__backdrop"></div>
    <div [@dialogEnterLeave] class="dialog" cdkTrapFocus cdkTrapFocusAutoCapture="true">
      @if (dialogRequest.headerIcon || dialogRequest.headerTitle) {
        <div class="dialog__header">
          @if (dialogRequest.headerIcon) {
            <ftr-icon [icon]="dialogRequest.headerIcon" color="Lemon" gapRight="Small" align="Bottom"></ftr-icon>
          }
          @if (dialogRequest.headerTitle) {
            <h2>{{ dialogRequest.headerTitle }}</h2>
          }
          <span class="dialog__close">
            <button ftrButton data-track displayType="icon" type="button" title="Close" (click)="hide()">
              <ftr-icon icon="Close" color="GreyLight" align="Bottom"></ftr-icon>
            </button>
          </span>
        </div>
      }
      <div class="dialog__content">{{ dialogRequest.message }}</div>
      <div class="dialog__footer">
        <button
          ftrButton
          type="button"
          [color]="dialogRequest.cancelButtonColor ?? 'secondary'"
          (click)="confirm(!dialogRequest.confirmAction, dialogRequest.name)"
        >
          {{ dialogRequest.cancelButtonLabel }}
        </button>
        <button
          ftrButton
          type="button"
          color="primary"
          (click)="confirm(!!dialogRequest.confirmAction, dialogRequest.name)"
        >
          {{ dialogRequest.confirmButtonLabel }}
        </button>
      </div>
    </div>
  }
</div>
