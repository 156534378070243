import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class UnauthorizedEventsMonitorService {
  private readonly unauthorizedErrorsSource = new Subject<Error>()
  unauthorizedErrors = this.unauthorizedErrorsSource.asObservable()

  intercept(err: Error): void {
    this.unauthorizedErrorsSource.next(err)
  }
}
