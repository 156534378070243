<ng-template #openerTemplate>
  <div class="court-system-dropdown justify-content-between" (click)="open()">
    <span class="court-system-dropdown__name" [class.hidden]="!isDropdownOpen">{{
      (currentCourtSystem$ | async)?.name
    }}</span>
    <ftr-icon class="court-system-dropdown__icon" icon="KeyboardArrowDown" align="Bottom"></ftr-icon>
  </div>
</ng-template>

@if (navItems$ | async) {
  <ftr-dropdown-menu
    ariaLabel="Court system dropdown menu"
    data-track-group="Court System Dropdown"
    [openerTemplate]="openerTemplate"
    [menuItems]="navItems$"
    (isOpen)="setOpenState($event)"
  >
  </ftr-dropdown-menu>
}
