import { animate, state, style, transition, trigger } from '@angular/animations'
import { NgClass, NgTemplateOutlet } from '@angular/common'
import { Component, Input, ViewEncapsulation } from '@angular/core'
import { MarkdownDirective } from '../../directives'
import { Icon, IconComponent } from '../../lib/icon'
import { assertUnreachable } from '../../util'
import { NotificationType } from './notification-type'

const ANIMATION_DURATION = 100

@Component({
  selector: 'ftr-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css'],
  animations: [
    trigger('smoothEnter', [
      state('in', style({ height: 'auto' })),
      transition('void => *', [style({ height: '0' }), animate(ANIMATION_DURATION)]),
      transition('* => void', [animate(ANIMATION_DURATION, style({ height: 'auto' }))]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
  imports: [NgClass, IconComponent, MarkdownDirective, NgTemplateOutlet],
  standalone: true,
})
export class NotificationComponent {
  @Input() type: NotificationType
  /**
   * Remove the top and bottom margins.
   */
  @Input() compact = false
  /**
   * Render text with markdown syntax (default true), NOTE: this seems to prevent child components from rendering.
   */
  @Input() markdown = true
  /**
   * Display like a toast with no borders, a drop shadow etc.
   */
  @Input() toast = false

  getIcon(): Icon {
    switch (this.type) {
      case NotificationType.Error:
        return 'Error'
      case NotificationType.Info:
        return 'Info'
      case NotificationType.Success:
        return 'CheckCircle'
      case NotificationType.Warn:
        return 'Warning'
      default:
        assertUnreachable(this.type)
    }
  }
}
