import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core'
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { RETURN_URL_HANDLING_KEY, RETURN_URL_KEY, ReturnUrlHandlingType } from '@ftr/api-shared'
import { RegisterUserRequest, RegisterUserResponse } from '@ftr/contracts/api/user'
import { USER_NAME_VALIDATORS, emailValidator } from '@ftr/forms'
import { LocalStorageService, PageStyle, RemoteData } from '@ftr/foundation'
import { SignupParams } from '@ftr/routing-paths'
import { AuthenticationService } from '@ftr/ui-user'
import { ReplaySubject, take, tap } from 'rxjs'
import { TermsOfUseValidator } from '~app/features/signup/signup/terms-of-use-validator'
import { RecaptchaService } from '~app/services/recaptcha/recaptcha.service'
import { SignupAttributesService } from '../signup-attributes.service'

interface FormSubmission {
  givenName: string
  familyName: string
  email: string
  acceptedTerms: boolean
}

@Component({
  selector: 'ftr-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
  host: {
    class: 'signup page',
  },
  encapsulation: ViewEncapsulation.None,
})
export class SignupComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() returnUrl: string
  @Input() returnUrlHandling: ReturnUrlHandlingType

  signupForm: UntypedFormGroup
  submissionSubject = new ReplaySubject<RemoteData<RegisterUserResponse, string>>()
  submission = this.submissionSubject.asObservable()

  readonly pageStyle = PageStyle.Narrow
  readonly recaptchaElementId = 'recaptcha'

  recaptchaWidgetId: number | undefined

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly localStorageService: LocalStorageService,
    private readonly authenticationService: AuthenticationService,
    private readonly signupAttributesService: SignupAttributesService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly recaptchaService: RecaptchaService,
  ) {}

  ngOnInit(): void {
    this.setupForm()
    this.submissionSubject.next(RemoteData.notAsked())
  }

  ngAfterViewInit(): void {
    this.recaptchaService.recaptchaLoaded$
      .pipe(
        tap(() => (this.recaptchaWidgetId = this.recaptchaService.render(this.recaptchaElementId))),
        take(1),
      )
      .subscribe()
  }

  ngOnDestroy(): void {
    if (this.recaptchaWidgetId) {
      this.recaptchaService.reset(this.recaptchaWidgetId)
    }
  }

  setupForm(): void {
    const email = this.activatedRoute.snapshot.queryParamMap.get(SignupParams.AuthenticationEmail)
    this.signupForm = this.formBuilder.group({
      givenName: ['', USER_NAME_VALIDATORS],
      familyName: ['', USER_NAME_VALIDATORS],
      email: [{ value: email || '', disabled: !!email }, [Validators.required, emailValidator]],
      acceptedTerms: [false, TermsOfUseValidator.required],
    })
  }

  submitForm(value: FormSubmission): void {
    if (!this.signupForm.valid) {
      for (const i in this.signupForm.controls) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.signupForm.controls.hasOwnProperty(i)) {
          this.signupForm.controls[i].markAsTouched()
        }
      }
      return
    }

    if (this.recaptchaWidgetId === undefined) {
      this.submissionSubject.next(RemoteData.failure('Please wait until the reCAPTCHA has loaded.'))
      return
    }

    const recaptchaResponse = this.recaptchaService.getResponse(this.recaptchaWidgetId)

    if (!recaptchaResponse) {
      this.submissionSubject.next(RemoteData.failure('Please complete the reCAPTCHA.'))
      return
    }

    const request = new RegisterUserRequest(
      value.email,
      value.givenName,
      value.familyName,
      recaptchaResponse,
      value.acceptedTerms,
    )

    this.authenticationService.signUp(request).subscribe(async remote => {
      if (remote.isSuccess()) {
        this.signupAttributesService.attributes = {
          username: remote.data!.userId,
          givenName: value.givenName,
          familyName: value.familyName,
          email: value.email,
        }
        if (this.returnUrl) {
          // set a returnUrl scoped to this user
          this.localStorageService.set(RETURN_URL_KEY, this.returnUrl)
          if (this.returnUrlHandling) {
            this.localStorageService.set(RETURN_URL_HANDLING_KEY, this.returnUrlHandling)
          }
        }
        await this.router.navigate(['/confirmation-sent'])
      } else if (remote.isFailure()) {
        this.submissionSubject.next(RemoteData.failure(remote.error!.message))
      }
    })
  }

  asFormControl(control: AbstractControl): UntypedFormControl {
    return control as UntypedFormControl
  }
}
