import { Injectable } from '@angular/core'
import { from, Observable } from 'rxjs'
import SparkMD5 from 'spark-md5'
import { EmptyChunkError } from './error/EmptyChunkError'

const CHUNK_SIZE = 2_097_152 // 2MB

@Injectable({
  providedIn: 'root',
})
export class ChecksumService {
  calculateChecksum(file: File): Observable<string> {
    return from(
      new Promise<string>((resolve, reject) => {
        const chunkCount = Math.ceil(file.size / CHUNK_SIZE)

        let currentChunk = 0
        let start: number | undefined
        let end: number | undefined

        const reader = new FileReader()
        const spark = new SparkMD5.ArrayBuffer()

        const loadNextChunk = (): void => {
          start = currentChunk * CHUNK_SIZE
          end = Math.min(start + CHUNK_SIZE, file.size)

          reader.readAsArrayBuffer(file.slice(start, end))
        }

        reader.onloadend = e => {
          // Unlike the "load" event, "loadend" will return even if the file read has not happened successfully,
          // when this happens a `null` value will be returned as target's `result` property. We handle this by
          // wrapping it in a descriptive error so it can go into our logs.
          // We have seen some production users getting null results here, however at the time we did not have
          // enough details being logged so that we could reproduce their error. My speculation is that their file
          // was changing on disk as the `FileReader` was iterating through its chunks.
          if (!(e.target?.result instanceof ArrayBuffer)) {
            reject(new EmptyChunkError(file, { start, end }))
            return
          }

          spark.append(e.target.result)
          currentChunk++

          if (currentChunk < chunkCount) {
            loadNextChunk()
          } else {
            const hex = spark.end()
            const base64 = Buffer.from(hex, 'hex').toString('base64')
            resolve(base64)
          }
        }

        reader.onerror = err => {
          reject(err)
        }

        loadNextChunk()
      }),
    )
  }
}
