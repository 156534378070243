import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { BadgeColors, Colors, Icon } from '@ftr/foundation'
import { HELP_CENTER_NAV_TITLE } from '../card/help-sub-menu-flyout-card.component'

export interface HelpSubMenuItem {
  title: string
  description: string
  icon: Icon
  iconColor: BadgeColors
  badgeColor: Colors
  link?: string
  visible: boolean
}

@Component({
  selector: 'ftr-help-sub-menu-flyout-item',
  templateUrl: './help-sub-menu-flyout-item.component.html',
  styleUrl: './help-sub-menu-flyout-item.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpSubMenuFlyoutItemComponent {
  @Input({ required: true }) item: HelpSubMenuItem
  @Input() className: string = ''

  protected readonly helpCenterNavTitle = HELP_CENTER_NAV_TITLE
}
