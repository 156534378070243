export enum PasswordResetParams {
  /**
   * Query parameter for the verification code for use during the verification process.
   */
  VerificationCode = 'code',
  /**
   * Query parameter for the username for use during the verification process
   */
  Username = 'username',
}
