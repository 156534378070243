import { Injectable } from '@angular/core'
import { io, Socket } from 'socket.io-client'

@Injectable({
  providedIn: 'root',
})
export class SocketIoService {
  connect(url: string): Socket {
    return io(url, {
      /**
       * Polling mode doesn't work when there's > 1 socket eb instance running - we get 400 errors.
       */
      transports: ['websocket', 'polling'],
      path: '/socket.io',
    })
  }
}
