<!-- If we have children we are a group -->
@if (navItem.children?.length) {
  <li class="side-nav-item-group">
    <ul class="side-nav-group">
      @for (navGroupitem of navItem.children; track navGroupitem) {
        <ftr-side-nav-item [navItem]="navGroupitem" (hide)="hide()"></ftr-side-nav-item>
      }
    </ul>
  </li>
} @else {
  <ftr-side-nav-item-inner [navItem]="navItem" (hide)="hide()"></ftr-side-nav-item-inner>
}
