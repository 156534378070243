import { InjectionToken } from '@angular/core'

export const AUTH_TOKEN_FETCHER = new InjectionToken<AuthTokenFetcher>('AUTH_TOKEN_FETCHER')

export const TOKEN_EXPIRY_THRESHOLD_SECONDS = 30

export interface TokenStatus {
  isTokenValid: boolean
  isTokenNew?: boolean
}
/**
 * A simple interface for fetching an auth token, it is implemented and supplied in app via @see AuthTokenFetcherService
 */
export interface AuthTokenFetcher {
  getToken(): Promise<string>
}
