import { Injectable } from '@angular/core'
import { isRecordingLiveAndOnTheRecord } from '@ftr/api-regional'
import { ApiClient, ApiClientFactory, serializeHttpParams } from '@ftr/api-shared'
import { PageDetails, PagedQuery } from '@ftr/contracts/api/core'
import { PagedResult } from '@ftr/contracts/api/core/response/PagedResult'
import { CourtSystem } from '@ftr/contracts/api/court-system'
import { SortDirection } from '@ftr/contracts/api/shared'
import {
  CreateSharedRecordingBody,
  ListSharedRecordingsForRecordingQuery,
  ListSharedRecordingsForUserBody,
  SharedRecording,
  SharedRecordingResult,
  SharedRecordingWithCourtSystem,
  SharedRecordingWithUser,
  SharedRecordingsListSortBy,
} from '@ftr/contracts/api/shared-recording'
import { RecordingSegment, getRecordingEnd, getRecordingStart } from '@ftr/contracts/read'
import { HearingSegment } from '@ftr/contracts/type/court'
import { RecordingType } from '@ftr/contracts/type/recording'
import { LocalDateTimeRange, Uuid } from '@ftr/contracts/type/shared'
import { LocalTimeRange } from '@ftr/contracts/type/shared/LocalTimeRange'
import { ApiResult, createAndAssign, mapData } from '@ftr/foundation'
import { classToPlain, plainToClass } from '@ftr/serialization'

/**
 * Admins and customers see different sets of badges
 */
export enum SharingView {
  Admin = 'admin',
  Customer = 'customer',
}

const SHARED_RECORDINGS_LIST_SIZE = 25
const SHARED_WITH_LIST_SIZE = 100

@Injectable({ providedIn: 'root' })
export class SharedRecordingService {
  private readonly apiClient: ApiClient

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClient = apiClientFactory.build('/shared-recording')
  }

  shareRecording(sharedRecordingBody: CreateSharedRecordingBody): ApiResult<SharedRecordingResult> {
    return this.apiClient.post({ body: classToPlain(sharedRecordingBody) })
  }

  listForUser(
    page: number,
    courtSystems: Uuid[],
    recordingDate: string | undefined,
    sortBy: SharedRecordingsListSortBy,
    sortDirection: SortDirection,
  ): ApiResult<PagedResult<SharedRecordingWithCourtSystem>> {
    return this.apiClient
      .post<PagedResult<SharedRecordingWithCourtSystem>>({
        path: '/listForUser',
        body: new ListSharedRecordingsForUserBody(courtSystems, recordingDate, sortBy, sortDirection),
        params: serializeHttpParams(new PagedQuery(new PageDetails(page, SHARED_RECORDINGS_LIST_SIZE))),
      })
      .pipe(
        mapData(response => ({
          ...response,
          items: response.items.map(i => plainToClass(SharedRecordingWithCourtSystem, i)),
        })),
      )
  }

  listCourtSystems(): ApiResult<CourtSystem[]> {
    return this.apiClient.get({ path: '/listCourtSystems', responseBodyType: CourtSystem })
  }

  getById(sharedRecordingId: Uuid): ApiResult<SharedRecordingWithCourtSystem> {
    return this.apiClient.get({ path: sharedRecordingId, responseBodyType: SharedRecordingWithCourtSystem })
  }

  listForRecording(
    page: number,
    recordingId: Uuid,
    courtSystemId: Uuid,
  ): ApiResult<PagedResult<SharedRecordingWithUser>> {
    return this.apiClient
      .get<PagedResult<SharedRecordingWithUser>>({
        path: '/listForRecording',
        params: serializeHttpParams(
          createAndAssign(ListSharedRecordingsForRecordingQuery, {
            recordingId,
            courtSystemId,
            page: new PageDetails(page, SHARED_WITH_LIST_SIZE),
          }),
        ),
      })
      .pipe(
        mapData(response => ({
          ...response,
          items: response.items.map(i => plainToClass(SharedRecordingWithUser, i)),
        })),
      )
  }

  delete(sharedRecordingId: Uuid): ApiResult {
    return this.apiClient.delete({ path: sharedRecordingId })
  }
}

function toRecordingLocalDateTimeRange(segments: RecordingSegment[]): LocalDateTimeRange {
  const recordingStart = getRecordingStart(segments)
  const recordingEnd = getRecordingEnd(segments)
  return new LocalDateTimeRange(recordingStart!, recordingEnd!)
}

export function getHearingSegments(sharedRecording: SharedRecording): HearingSegment[] {
  const hearingSegments = sharedRecording.hearingSegments
  if (hearingSegments && hearingSegments.length) {
    return hearingSegments
  }

  return [new HearingSegment(toRecordingLocalDateTimeRange(sharedRecording.recording.segments))]
}

export function getRecordingTimeRange(segments: RecordingSegment[]): LocalTimeRange {
  return toRecordingLocalDateTimeRange(segments).toLocalTimeRange()
}

export function downloadIncluded(sharedRecording: SharedRecording, sharingView: SharingView): boolean {
  return (
    sharedRecording.downloadIncluded &&
    (logSheetsIncluded(sharedRecording, sharingView) || !sharedRecording.recording.hasLogSheets) &&
    (videoIncluded(sharedRecording) || !sharedRecording.recording.hasVideo) &&
    (sealedContentIncluded(sharedRecording, sharingView) || !sharedRecording.recording.hasSealedContent)
  )
}

export function logSheetsIncluded(sharedRecording: SharedRecording, sharingView: SharingView): boolean {
  const shared = sharedRecording.recording.recordingType === RecordingType.TRM && sharedRecording.logSheetsIncluded
  return sharingView === SharingView.Customer ? shared && sharedRecording.recording.hasLogSheets : shared
}

export function speechToTextIncluded(sharedRecording: SharedRecording, sharingView: SharingView): boolean {
  const shared = sharedRecording.recording.sttEnabled && sharedRecording.speechToTextIncluded
  return sharingView === SharingView.Customer ? shared && sharedRecording.recording.hasStt : shared
}

export function videoIncluded(sharedRecording: SharedRecording): boolean {
  return sharedRecording.recording.hasVideo && sharedRecording.videoIncluded
}

export function sealedContentIncluded(sharedRecording: SharedRecording, sharingView: SharingView): boolean {
  const shared = sharedRecording.sealedContentIncluded
  return sharingView === SharingView.Customer ? shared && sharedRecording.recording.hasSealedContent : shared
}

export function anythingIncluded(sharedRecording: SharedRecording, sharingView: SharingView): boolean {
  return (
    speechToTextIncluded(sharedRecording, sharingView) ||
    logSheetsIncluded(sharedRecording, sharingView) ||
    downloadIncluded(sharedRecording, sharingView) ||
    videoIncluded(sharedRecording) ||
    sealedContentIncluded(sharedRecording, sharingView)
  )
}

export function isSharedRecordingLiveAndOnRecord(
  sharedRecording: SharedRecording | SharedRecordingWithCourtSystem,
): boolean {
  return (
    'isLive' in sharedRecording && sharedRecording.isLive && isRecordingLiveAndOnTheRecord(sharedRecording.recording)
  )
}
