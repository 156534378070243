export enum AppPaths {
  Account = 'account',
  Admin = 'admin',
  AuditLog = 'audit-log',
  CourtroomDisplay = 'display',
  CourtroomJoin = 'public/court-system/:courtSystemId/locations/:courthouseId/locations/:courtroomId/join',
  CourtroomJoinDetail = 'public/court-system/:courtSystemId/locations/:courthouseId/locations/:courtroomId/join-detail',
  CourtSystemForbidden = 'court-system-forbidden',
  CourtSystem = 'court-system',
  CourtSystems = 'court-systems',
  DefaultPage = '',
  DesignSystem = 'design-system',
  ExternalLogin = 'external-login',
  Forbidden = 'forbidden',
  Help = 'help',
  Jobs = 'jobs',
  Login = 'login',
  DesktopLoginSplash = 'desktop-login-splash',
  Logout = 'logout',
  MultiFactorAuthentication = 'multifactor-authentication',
  NotFound = 'not-found',
  Orders = 'orders',
  Recording = 'recording',
  SharedRecordings = 'shared-recordings',
  SharedWithMe = 'shared-with-me',
  StandaloneAppDownload = 'download',
  Styles = 'styles',
  SupportedBrowsers = 'supported-browsers',
  Transcripts = 'transcripts',
  Uploads = 'uploads',
  Locations = 'locations',
}
