import { AudioOrderingFeature, UpdateAudioOrderingFeatureBody } from '@ftr/contracts/api/audio-ordering-feature'
import {
  RealTimeOrderingFeature,
  UpdateRealTimeOrderingFeatureBody,
} from '@ftr/contracts/api/real-time-ordering-feature'
import {
  SharingRecordingsFeature,
  UpdateSharingRecordingFeatureBody,
} from '@ftr/contracts/api/sharing-recordings-feature'
import {
  TranscriptOrderingFeature,
  UpdateTranscriptOrderingFeatureBody,
} from '@ftr/contracts/api/transcript-ordering-feature'
import { Uuid } from '@ftr/contracts/type/shared'
import { ApiResult } from '@ftr/foundation'

export type SupportedFeature =
  | AudioOrderingFeature
  | TranscriptOrderingFeature
  | RealTimeOrderingFeature
  | SharingRecordingsFeature

export type SupportedUpdateFeatureBody =
  | UpdateAudioOrderingFeatureBody
  | UpdateTranscriptOrderingFeatureBody
  | UpdateRealTimeOrderingFeatureBody
  | UpdateSharingRecordingFeatureBody

export abstract class FeatureService<T extends SupportedFeature> {
  abstract updateFeature<B extends SupportedUpdateFeatureBody>(featureId: Uuid, body: B): ApiResult<T>

  abstract getForCourtSystem(courtSystemId: Uuid): ApiResult<T>
}
