import { CommonModule } from '@angular/common'
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core'

@Component({
  selector: 'ftr-scrollable',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './scrollable.component.html',
  styleUrls: ['./scrollable.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class ScrollableComponent implements OnInit, AfterContentInit {
  @Input() contentHasShadow = true
  @Input() color = ''
  @ViewChild('scroller', { static: true }) scroller: ElementRef
  @ViewChild('content', { static: true }) content: ElementRef

  scrollIsLeftmost = true
  scrollIsRightmost = false
  canScroll = false

  ngOnInit(): void {
    this.updateScrollPositions()
  }

  ngAfterContentInit(): void {
    this.updateScrollPositions()
  }

  onScroll(): void {
    this.updateScrollPositions()
  }

  @HostListener('window:resize')
  onWindowResize(): void {
    this.updateScrollPositions()
  }

  cssClasses(): Record<string, boolean> {
    const prefix = (str: string): string => `scrollable__wrapper--${str}`
    return {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      scrollable__wrapper: true,
      [prefix('leftmost-scroll')]: this.scrollIsLeftmost,
      [prefix('rightmost-scroll')]: this.scrollIsRightmost,
      [prefix('can-scroll')]: this.canScroll,
      [prefix('content-has-shadow')]: this.contentHasShadow,
      [prefix(this.color)]: this.color !== '',
    }
  }

  private updateScrollPositions(): void {
    this.canScroll = this.scrollWidth > this.contentWidth
    this.scrollIsLeftmost = this.scrollerElem.scrollLeft === 0
    this.scrollIsRightmost = this.scrollerElem.scrollLeft + this.contentWidth >= this.scrollWidth
  }

  private get scrollerElem(): HTMLDivElement {
    return this.scroller.nativeElement
  }

  private get contentElem(): HTMLElement {
    return this.content.nativeElement
  }

  private get contentWidth(): number {
    return this.contentElem.getBoundingClientRect().width
  }

  private get scrollWidth(): number {
    return this.scrollerElem.scrollWidth
  }
}
