import { CommonModule } from '@angular/common'
import { Component, Input, OnChanges, OnInit } from '@angular/core'
import { ICONS, Icon } from './icon'

/**
 * Encapsulates the available icon sizes, based on the classes provided in icon.component.css
 */
export const ICON_SIZES = {
  ExtraSmall: 'extra-small',
  Text: 'text',
  Small: 'small',
  Regular: 'regular',
  Medium: 'medium',
  Large: 'large',
  ExtraLarge: 'extra-large',
  Jumbo: 'jumbo',
  Title: 'title',
  DoubleTitle: 'double-title',
  Double: 'double',
  Max: 'max',
} as const

export type IconSize = keyof typeof ICON_SIZES

/**
 * Keep these in line with the icon size variables in CSS
 */
const ICON_DIMENSIONS: Record<keyof typeof ICON_SIZES, string> = {
  ExtraSmall: '12',
  Text: '16',
  Small: '18',
  Regular: '20',
  Medium: '22',
  Large: '24',
  ExtraLarge: '26',
  Jumbo: '46',
  Title: '32',
  DoubleTitle: '64',
  Double: '40',
  Max: '84',
}

/**
 * Encapsulates the available icon colors, based on classes provided in icon.component.css
 */
export const ICON_COLORS = {
  Brand: 'brand',
  BrandDarker: 'brand-darker',
  Primary: 'primary',
  Secondary: 'secondary',
  Grey: 'grey',
  GreyLighter: 'grey-lighter',
  GreyLight: 'grey-light',
  Grey30: 'grey-30',
  White: 'white',
  Apple: 'apple',
  Lemon: 'lemon',
  Blueberry: 'blueberry',
  Aqua: 'aqua',
} as const

export type IconColor = keyof typeof ICON_COLORS

export const ICON_ALIGNMENTS = {
  Baseline: 'baseline',
  TextTop: 'text-top',
  TextBottom: 'text-bottom',
  Middle: 'middle',
  Top: 'top',
  Bottom: 'bottom',
  Sub: 'sub',
} as const

export type IconAlignment = keyof typeof ICON_ALIGNMENTS

export const ICON_GAPS = {
  Regular: 'regular',
  ThreeQuarter: 'three-quarter',
  Small: 'small',
  Tiny: 'tiny',
} as const

export type IconGap = keyof typeof ICON_GAPS

const CLASS_PREFIX = 'icon'

/**
 * A wrapper for a svg element, including optional rendering parameters.
 */
@Component({
  standalone: true,
  selector: 'ftr-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.css'],
  imports: [CommonModule],
})
export class IconComponent implements OnInit, OnChanges {
  @Input() icon: Icon

  /**
   * An optional size.
   * According to the design system, the size of the icon depends on the size of the text it is used beside.
   */
  @Input() size: IconSize = 'Large'

  /**
   * An optional color, otherwise the icon will inherit the text `color` of its parent (or body text).
   */
  @Input() color?: IconColor

  /**
   * Icon vertical alignments with text.
   * Different sized text with different style icons seem to require different alignment
   */
  @Input() align: IconAlignment = 'Baseline'

  /**
   * Include a margin half the dimensions of the icon to the right.
   */
  @Input() gapRight?: IconGap

  /**
   * Include a margin half the dimensions of the icon to the left.
   */
  @Input() gapLeft?: IconGap

  /**
   * Whether the icon is interactive and applies its own hover/active states.
   */
  @Input() interactive = false

  /**
   * An optional aria-label attribute
   */
  @Input() label?: string

  protected iconUrl = ''
  protected cssClasses: string[]

  protected iconDimensions = ICON_DIMENSIONS

  private applyClass(cssClass: string): void {
    this.cssClasses.push(cssClass)
  }

  private get iconValue(): (typeof ICONS)[Icon] {
    return ICONS[this.icon]
  }

  private get sizeValue(): (typeof ICON_SIZES)[IconSize] {
    return ICON_SIZES[this.size]
  }

  private get colorValue(): (typeof ICON_COLORS)[IconColor] | undefined {
    return this.color && ICON_COLORS[this.color]
  }

  private get alignValue(): (typeof ICON_ALIGNMENTS)[IconAlignment] {
    return ICON_ALIGNMENTS[this.align]
  }

  private get gapRightValue(): (typeof ICON_GAPS)[IconGap] | undefined {
    return this.gapRight && ICON_GAPS[this.gapRight]
  }

  private get gapLeftValue(): (typeof ICON_GAPS)[IconGap] | undefined {
    return this.gapLeft && ICON_GAPS[this.gapLeft]
  }

  ngOnInit(): void {
    this.calculateProperties()
  }

  ngOnChanges(): void {
    this.calculateProperties()
  }

  private calculateProperties(): void {
    this.cssClasses = []

    this.applyClass(CLASS_PREFIX)
    this.applyClass(`${CLASS_PREFIX}__${this.sizeValue}`)

    if (this.colorValue) {
      this.applyClass(`${CLASS_PREFIX}__${this.colorValue}`)
    }

    this.applyClass(`${CLASS_PREFIX}__${this.alignValue}`)

    if (this.gapRightValue) {
      this.applyClass(`${CLASS_PREFIX}__${this.sizeValue}--gap-${this.gapRightValue}`)
    }

    if (this.gapLeftValue) {
      this.applyClass(`${CLASS_PREFIX}__${this.sizeValue}--gap-left-${this.gapLeftValue}`)
    }

    if (this.interactive) {
      this.applyClass(`${CLASS_PREFIX}__${this.colorValue}--interactive`)
    }

    this.iconUrl = `#${this.iconValue}`
  }
}
