import { Injectable, Signal, computed } from '@angular/core'
import { DEFAULT_VOCABULARY_TERMS, VocabularyTerms } from '@ftr/contracts/type/core/VocabularyTerms'
import { Uuid } from '@ftr/contracts/type/shared/Uuid'
import { ApiResult, isNotNullOrUndefined, selectWith } from '@ftr/foundation'
import { Store } from '@ngxs/store'
import { Observable, filter, first, map } from 'rxjs'
import { FetchVocabularyTermsAction, VocabularyState } from '../store'

@Injectable({
  providedIn: 'root',
})
export class VocabularyTermsService {
  constructor(private readonly store: Store) {}

  /**
   * Gets terms from the store, otherwise retrieves them from the api if they are not cached.
   */
  observeTerms(courtSystemId: Uuid): Observable<VocabularyTerms> {
    this.store.dispatch(new FetchVocabularyTermsAction(courtSystemId))
    return this.store.select(VocabularyState.termsByCourtSystemId).pipe(
      map(fn => fn(courtSystemId)),
      filter(isNotNullOrUndefined),
    )
  }

  /**
   * Gets terms from the store, otherwise retrieves them from the api if they are not cached.
   *
   * Does not remain listening to the store after the first response.
   */
  observeTermsSnapshot(courtSystemId: Uuid): Observable<VocabularyTerms> {
    return this.observeTerms(courtSystemId).pipe(first())
  }

  /**
   * Gets terms from the store, otherwise retrieves them from the api if they are not cached.
   * Returns as an ApiResult for convenience, when using as part of ApiResult.combine
   */
  observeRemoteTerms(courtSystemId: Uuid): ApiResult<VocabularyTerms> {
    return ApiResult.from(this.observeTerms(courtSystemId))
  }

  private allTerms = selectWith(() => VocabularyState.termsByCourtSystemId)
  terms(courtSystemId: () => Uuid): Signal<VocabularyTerms> {
    return computed(() => this.allTerms()(courtSystemId()) ?? DEFAULT_VOCABULARY_TERMS)
  }
}
