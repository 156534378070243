import { CommonModule } from '@angular/common'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { AnimatedRemoteComponent, BadgeComponent, IconComponent, PageComponent } from '@ftr/foundation'
import { ElevioArticleDirective } from './elevio-article/elevio-article.directive'
import { HelpContactDetailsComponent } from './help-contact-details/help-contact-details.component'
import { HelpLinkComponent } from './help-link/help-link.component'
import { HelpOrderingInformationComponent } from './help-ordering-information/help-ordering-information.component'
import { HelpPlatformSupportComponent } from './help-platform-support/help-platform-support.component'
import { HelpSubMenuFlyoutCardComponent } from './help-sub-menu-flyout/card/help-sub-menu-flyout-card.component'
import { HelpSubMenuFlyoutItemComponent } from './help-sub-menu-flyout/item/help-sub-menu-flyout-item.component'
import { HelpComponent } from './help.component'

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ElevioArticleDirective,
    AnimatedRemoteComponent,
    PageComponent,
    IconComponent,
    BadgeComponent,
  ],
  declarations: [
    HelpComponent,
    HelpContactDetailsComponent,
    HelpPlatformSupportComponent,
    HelpOrderingInformationComponent,
    HelpLinkComponent,
    HelpSubMenuFlyoutItemComponent,
    HelpSubMenuFlyoutCardComponent,
  ],
  exports: [
    HelpComponent,
    HelpContactDetailsComponent,
    HelpPlatformSupportComponent,
    HelpOrderingInformationComponent,
    HelpLinkComponent,
    HelpSubMenuFlyoutCardComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HelpModule {}
