import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http'
import { ApiResult } from '@ftr/foundation'
import { LoggingService } from '@ftr/ui-observability'
import { Observable, catchError, from, map, mergeMap, of, throwError } from 'rxjs'
import { ChecksumService } from '../checksum/checksum.service'
import { FileTooLargeError } from './file-too-large-error'
import {
  FileUpload,
  FileUploadError,
  FileUploadStatusEvent,
  FileUploadWithChecksum,
  InitializedFileUpload,
  SuccessfulFileUpload,
  UploadStates,
  UploadedFile,
  UploadingOrFinalizingFileUpload,
} from './file-upload'

/**
 * A generic service for uploading files to our backend.
 *
 * Our upload process typically follows these steps:
 * - Initialization (request to our API to get a signed S3 URL)
 * - Upload (request to S3)
 * - Finalization (request to our API to indicate that upload has finished)
 *
 * Despite these steps being the same across the various uploads we do, the endpoints
 * we upload to, the data we're expected to send to them, and the data they return
 * differs between each upload type.
 *
 * This abstract class should be used to implement a service for each type of upload
 * we do. Initialization/finalization will need to be implemented for each subclass.
 *
 * @see {OrderAttachmentFileUploadService}
 * @see {TranscriptFileUploadService}
 * @see {TranscriptRevisionFileUploadService}
 */
export abstract class FileUploadService<FileUploadPropertiesType, FileUploadResponseType> {
  /**
   * Virus scanning is disabled by default and should be enabled by the concrete services as required.
   */
  addVirusScanning = false

  protected constructor(
    protected readonly http: HttpClient,
    protected readonly loggingService: LoggingService,
    protected readonly checksumService: ChecksumService,
  ) {}

  upload(
    upload: FileUpload<FileUploadPropertiesType, FileUploadResponseType>,
    sizeLimit?: number,
  ): Observable<FileUploadStatusEvent<FileUploadPropertiesType, FileUploadResponseType>> {
    return this.checksumUpload(upload).pipe(
      mergeMap(uploadToSize => checkFileSize(uploadToSize, sizeLimit)),
      mergeMap(uploadWithChecksum => this.initializeUpload(uploadWithChecksum)),
      mergeMap(initializedUpload => this.uploadWithProgress(initializedUpload)),
      mergeMap(uploadProgress => this.mapUploadWithProgress(uploadProgress)),
      catchError((error: Error) => {
        this.loggingService.warn({
          message: FileUploadError.FileUploadFailed,
          uploadId: upload.id,
          fileId: upload.fileId,
          error,
        })
        return throwError(() => error)
      }),
    )
  }

  /**
   * Delete file is disabled by default and should be implemented by the concrete services as required.
   * This only applies to new files being uploaded.
   */
  deleteFile(_: FileUpload<FileUploadPropertiesType, FileUploadResponseType>): ApiResult {
    return ApiResult.success(null)
  }

  /**
   * Initialize an upload. This method's implementation should get a signed
   * S3 URL which we can upload to.
   * @param upload
   */
  abstract initializeUpload(
    upload: FileUploadWithChecksum<FileUploadPropertiesType, FileUploadResponseType>,
  ): Observable<InitializedFileUpload<FileUploadPropertiesType, FileUploadResponseType>>

  /**
   * Finalize an upload. This method's implementation should indicate to the API
   * that the upload to S3 has been successful.
   */
  abstract finalizeUpload(
    upload: InitializedFileUpload<FileUploadPropertiesType, FileUploadResponseType>,
  ): Observable<SuccessfulFileUpload<FileUploadPropertiesType, FileUploadResponseType>>

  private mapUploadWithProgress(
    upload: FileUploadStatusEvent<FileUploadPropertiesType, FileUploadResponseType>,
  ): Observable<FileUploadStatusEvent<FileUploadPropertiesType, FileUploadResponseType>> {
    if (upload.status === UploadStates.Finalizing) {
      return this.finalizeUpload(upload)
    }
    return of(upload)
  }

  private uploadWithProgress(
    upload: InitializedFileUpload<FileUploadPropertiesType, FileUploadResponseType>,
  ): Observable<UploadingOrFinalizingFileUpload<FileUploadPropertiesType, FileUploadResponseType>> {
    const headers = new HttpHeaders().set('Content-MD5', upload.checksum!)
    const req = new HttpRequest('PUT', upload.url!, upload.file, {
      headers,
      reportProgress: true,
    })
    return this.http.request<string>(req).pipe(map(event => mapUploadProgressToFileUpload(event, upload)))
  }

  private checksumUpload(
    upload: FileUpload<FileUploadPropertiesType, FileUploadResponseType>,
  ): Observable<FileUploadWithChecksum<FileUploadPropertiesType, FileUploadResponseType>> {
    if (isUploadedFile(upload.file)) {
      return from([{ ...upload, checksum: '' }])
    } else {
      return this.checksumService.calculateChecksum(upload.file).pipe(map(checksum => ({ ...upload, checksum })))
    }
  }
}

function isUploadedFile(file: File | UploadedFile): file is UploadedFile {
  return (file as File).type === undefined
}

function checkFileSize<
  FileUploadPropertiesType,
  FileUploadResponseType,
  T extends FileUpload<FileUploadPropertiesType, FileUploadResponseType>,
>(upload: T, sizeLimit?: number): Observable<T> {
  if (sizeLimit && upload.file.size > sizeLimit) {
    return throwError(() => new FileTooLargeError())
  }
  return of(upload)
}

function mapUploadProgressToFileUpload<FileUploadPropertiesType, FileUploadResponseType>(
  event: HttpEvent<string>,
  upload: InitializedFileUpload<FileUploadPropertiesType, FileUploadResponseType>,
): UploadingOrFinalizingFileUpload<FileUploadPropertiesType, FileUploadResponseType> {
  if (
    event.type === HttpEventType.UploadProgress ||
    event.type === HttpEventType.ResponseHeader ||
    event.type === HttpEventType.DownloadProgress
  ) {
    const uploadEvent = event as HttpProgressEvent
    const progress = uploadEvent.loaded / uploadEvent.total!
    return { ...upload, progress, status: UploadStates.Uploading }
  } else if (event.type === HttpEventType.Sent) {
    return { ...upload, progress: 0, status: UploadStates.Uploading }
  } else if (event instanceof HttpResponse) {
    return { ...upload, progress: 1, status: UploadStates.Finalizing }
  } else {
    throw new Error(`Unknown response event: ${event.type}`)
  }
}

export function getPublicUrl(s3Url: string): string {
  // There is no S3 SDK to get the URL for a public object, as it can be constructed from region, bucket and key,
  // or simply extracted from the upload URL
  return s3Url.split('?')[0]
}

export function getFileNameFromImageUrl(url: string): string {
  return url?.substring(url.lastIndexOf('/') + 1) || ''
}
