import { LocalTimeRange } from '@ftr/contracts/type/shared/LocalTimeRange'
import { assertUnreachable } from '@ftr/foundation'
import { LocalTime } from '@js-joda/core'
import {
  TimeValidation,
  TimeValidationReason,
  formatSuggestedTime,
  isFutureEndTime,
  runValidators,
  timeRangeContainsWithInclusiveEnd,
  timeRangeContainsWithInclusiveStart,
  validateBoundaries,
  validateSectionTimes,
} from '../time-validation-utils'

export type SessionTimeValidationReason = TimeValidationReason | 'already-exists' | 'end-time-update-for-live-session'

export type SessionTimeValidation = TimeValidation<SessionTimeValidationReason>

export function validateSessionTimes(
  startTime: LocalTime,
  endTime: LocalTime,
  onRecordTimeRanges: LocalTimeRange[],
  recordingStartTime: LocalTime,
  recordingEndTime: LocalTime | undefined,
  existingTimeRange: LocalTimeRange | undefined,
  liveLocaltime: LocalTime | undefined,
): SessionTimeValidation | undefined {
  if (!recordingEndTime) {
    return { reason: 'end-is-out-of-bounds' }
  }

  // Round down the start time, round up the end time if it has nanos
  const recordingTimeRange = new LocalTimeRange(
    recordingStartTime.withNano(0),
    recordingEndTime.withNano(0).plusSeconds(recordingEndTime.nano() > 0 ? 1 : 0),
  )
  const startBoundary = getStartBoundary(startTime, recordingTimeRange, existingTimeRange)
  const endBoundary = getEndBoundary(endTime, recordingTimeRange, existingTimeRange)

  const validators: (() => SessionTimeValidation | undefined)[] = [
    () => alreadyExists(startTime, endTime, onRecordTimeRanges),
    () => validateSectionTimes(startTime, endTime),
    () => endTimeUpdatedOnLiveSession(endTime, existingTimeRange, liveLocaltime),
    () => validateBoundaries(startBoundary, endBoundary),
    () => isFutureEndTime(endTime, endBoundary!, liveLocaltime),
  ]

  return runValidators(validators)
}

function getStartBoundary(
  startTime: LocalTime,
  recordingTimeRange: LocalTimeRange,
  existingTimeRange: LocalTimeRange | undefined,
): LocalTimeRange | undefined {
  if (timeRangeContainsWithInclusiveStart(startTime, recordingTimeRange)) {
    return recordingTimeRange
  }

  if (!existingTimeRange?.start.equals(startTime)) {
    // we only expand validation boundary if this side of it was not changed
    return undefined
  }

  const expandedBoundary = new LocalTimeRange(existingTimeRange.start, recordingTimeRange.end)
  return timeRangeContainsWithInclusiveStart(startTime, expandedBoundary) ? expandedBoundary : undefined
}

function getEndBoundary(
  endTime: LocalTime,
  recordingTimeRange: LocalTimeRange,
  existingTimeRange: LocalTimeRange | undefined,
): LocalTimeRange | undefined {
  if (timeRangeContainsWithInclusiveEnd(endTime, recordingTimeRange)) {
    return recordingTimeRange
  }

  if (!existingTimeRange?.end.equals(endTime)) {
    // we only expand validation boundary if this side of it was not changed
    return undefined
  }

  const expandedBoundary = new LocalTimeRange(recordingTimeRange.start, existingTimeRange.end)
  return timeRangeContainsWithInclusiveEnd(endTime, expandedBoundary) ? expandedBoundary : undefined
}

function alreadyExists(
  startTime: LocalTime,
  endTime: LocalTime,
  onRecordTimeRanges: LocalTimeRange[],
): SessionTimeValidation | undefined {
  if (
    onRecordTimeRanges.some(
      existingSession => existingSession.start.equals(startTime) && existingSession.end.equals(endTime),
    )
  ) {
    return { reason: 'already-exists' }
  }
  return undefined
}

function endTimeUpdatedOnLiveSession(
  endTime: LocalTime,
  existingSession: LocalTimeRange | undefined,
  liveLocalTime: LocalTime | undefined,
): SessionTimeValidation | undefined {
  if (!liveLocalTime || !existingSession) {
    return undefined
  }
  const endTimeUpdated = !endTime.equals(existingSession.end)
  const isOngoing = existingSession.contains(liveLocalTime) || existingSession.end.equals(liveLocalTime)
  return endTimeUpdated && isOngoing ? { reason: 'end-time-update-for-live-session' } : undefined
}

export function mapSessionTimeValidationReasonMessage(errorValidation: SessionTimeValidation): string {
  switch (errorValidation.reason) {
    case 'start-is-out-of-bounds':
      return outOfBoundsMessage('start', errorValidation.matchingEndBoundary?.start)
    case 'end-is-out-of-bounds':
      return outOfBoundsMessage('end', errorValidation.matchingEndBoundary?.end)
    case 'both-out-of-bounds':
      return 'The start and end times are not within the recording.'
    case 'start-must-be-before-end':
      return 'The start time must be before end time.'
    case 'future-end-time':
      return 'The session end time cannot be in the future.'
    case 'end-time-update-for-live-session':
      return 'The session end time cannot be modified on an ongoing session.'
    case 'already-exists':
      return 'This session time frame already exists. Please choose a different time from within the recording.'
    case 'start-is-not-a-time':
      return 'Please enter a valid start time.'
    case 'end-is-not-a-time':
      return 'Please enter a valid end time.'
    case 'both-are-not-times':
      return 'Please enter valid start and end times.'
    default:
      return assertUnreachable(errorValidation.reason)
  }
}

function outOfBoundsMessage(type: 'start' | 'end', suggestedTime: LocalTime | undefined): string {
  const mainMessage = 'The session time is not within the recording.'
  return suggestedTime ? `${mainMessage} The recording ${type}s at ${formatSuggestedTime(suggestedTime)}.` : mainMessage
}
