// event.keyCode
export enum KeyCodes {
  ENTER = 13,
  ESCAPE = 27,
  LEFT_ARROW = 37,
  RIGHT_ARROW = 39,
  SPACE = 32,
  NBSP = 160,
}

// event.key
export enum KeyboardEventKey {
  BACKSPACE = 'Backspace',
  DELETE = 'Delete',
  ESC = 'Escape',
  ENTER = 'Enter',
  LEFT_ARROW = 'ArrowLeft',
  RIGHT_ARROW = 'ArrowRight',
  DOWN_ARROW = 'ArrowDown',
  UP_ARROW = 'ArrowUp',
  TAB = 'Tab',
  COMMA = ',',
  SEMICOLON = ';',
  SPACE = ' ',
}
