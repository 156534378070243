import { ArrayUtils } from './ArrayUtils'

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * @param min the minimum(inclusive) integer the number can be.
 * @param max the maximum(exclusive) integer the number can be.
 */
export function randomInt(min: number, max: number): number {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

function randomUppercaseString(length: number): string {
  return String.fromCharCode(...ArrayUtils.generate(length, () => randomInt(65, 90)))
}

/**
 * Eg MA-BD-RF
 */
export function randomHostName(): string {
  return `${randomUppercaseString(2)}-${randomUppercaseString(2)}-${randomUppercaseString(2)}`
}

/**
 * Eg 4.9
 */
export function randomVersion(): string {
  return `${randomInt(0, 100)}.${randomInt(0, 100)}`
}

/**
 * 192.321.322.132
 */
export function randomIp(): string {
  return `${randomInt(0, 255)}.${randomInt(0, 255)}.${randomInt(0, 256)}.${randomInt(0, 256)}`
}
