import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { NotificationModule, PageComponent } from '@ftr/foundation'
import { LaunchDarklyModule } from '@ftr/ui-feature-flags'
import { ForbiddenComponent } from './forbidden.component'

@NgModule({
  imports: [CommonModule, NotificationModule, PageComponent, LaunchDarklyModule],
  declarations: [ForbiddenComponent],
  exports: [ForbiddenComponent],
})
export class ForbiddenModule {}
