import { Injectable } from '@angular/core'
import { Event, NavigationEnd, Router } from '@angular/router'
import { AuthEventType } from '@ftr/api-shared'
import { CourtSystem } from '@ftr/contracts/api/court-system'
import { UserGroup } from '@ftr/contracts/api/user-group'
import { Uuid } from '@ftr/contracts/type/shared'
import { ApiResult, ArrayUtils, SimpleWindowRefService, TrackingEventType, isInDesktopApp } from '@ftr/foundation'
import { Store } from '@ngxs/store'
import { Observable, distinctUntilChanged, filter, map } from 'rxjs'
import { UserState } from '../../store'
import { AuthenticationService } from '../authentication.service'
import { AnalyticsEvent } from './analytics-event'

export const EMPTY_TRACKING_VALUE = 'EMPTY'

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  currentCourtSystem$: Observable<CourtSystem | undefined>
  // For the purposes of analytics, we only want to send
  // the groups for the court system the user has selected in the header.
  private readonly userGroups$: ApiResult<UserGroup[]>

  constructor(
    private readonly windowRefService: SimpleWindowRefService,
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
    private readonly store: Store,
  ) {
    this.currentCourtSystem$ = this.store.select(UserState.currentCourtSystem)
    this.userGroups$ = this.store.select(UserState.currentUserGroups)
    this.watchAuthenticationEvents()
    this.watchPageViews()
  }

  track(data: AnalyticsEvent): void {
    this.windowRefService.dataLayer().push(data)
  }

  // On init it should try and register the user as there may be no authEvents firing.
  register(): void {
    this.registerUser()
    this.assignCourtSystem()
    this.assignUserGroups()
  }

  private assignCourtSystem(): void {
    this.currentCourtSystem$.pipe(distinctUntilChanged()).subscribe(currentCourtSystem => {
      if (currentCourtSystem) {
        this.track({ event: TrackingEventType.UserParameterUpdate, courtSystemName: currentCourtSystem.name })
      } else {
        this.track({ event: TrackingEventType.UserParameterUpdate, courtSystemName: EMPTY_TRACKING_VALUE })
      }
    })
  }

  private assignUserGroups(): void {
    // Filter out the default case of UserState.groups (which is undefined, as no groups is [])
    this.userGroups$
      .pipe(
        filter(r => r.isSuccess() && !!r.data),
        map(r => r.data!.map(u => u.name)),
        distinctUntilChanged(ArrayUtils.shallowEquals),
      )
      .subscribe(userGroups => {
        if (userGroups && userGroups.length) {
          this.track({ event: TrackingEventType.UserParameterUpdate, courtSystemUserRoles: userGroups.join(';') })
        } else {
          this.track({ event: TrackingEventType.UserParameterUpdate, courtSystemUserRoles: EMPTY_TRACKING_VALUE })
        }
      })
  }

  private watchAuthenticationEvents(): void {
    this.authenticationService.authEvents.subscribe(authEvent => {
      if (authEvent.type === AuthEventType.Logout) {
        this.logOutUser()
      } else {
        this.registerUser()
      }
    })
  }

  private watchPageViews(): void {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.sendPage(event.urlAfterRedirects)
      }
    })
  }

  private registerUser(): void {
    const user = this.authenticationService.currentUser
    if (user) {
      // So we can segment automated test users out of GA.
      const automatedTestUser = /(^ftr-?\.?|@mailinator.com|@mailosaur.io$)/.test(user.email)
      const userEmailHostName = user.email.split('@')[1]
      const isInternalFtrUser = userEmailHostName.toLowerCase() === 'fortherecord.com'
      this.sendUser(user.id, automatedTestUser, userEmailHostName, isInDesktopApp(), isInternalFtrUser)
    }
  }

  /**
   * Google's policy requires us to unset when the user logs out
   * @see https://support.google.com/tagmanager/answer/4565987?hl=en
   */
  private logOutUser(): void {
    this.sendUser(null, undefined, undefined, undefined, undefined)
  }

  /**
   * GA has built-in userId tracking to link users across devices.
   * Access the user id view in GA by clicking "All Web Site Data" and in the Views pane, User ID view
   */
  private sendUser(
    userId: Uuid | null,
    isAutomatedTestUser: boolean | undefined,
    userEmailHostName: string | undefined,
    userIsInDesktopApp: boolean | undefined,
    isInternalFtrUser: boolean | undefined,
  ): void {
    this.track({
      event: TrackingEventType.UserParameterUpdate,
      userId,
      isAutomatedTestUser,
      userEmailHostName,
      userIsInDesktopApp,
      isInternalFtrUser,
    })
  }

  /**
   * PageView is a Custom Event configured in GTM's Triggers
   */
  private sendPage(url: string): void {
    this.track({ event: TrackingEventType.PageView, url })
  }
}
