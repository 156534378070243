import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Uuid } from '@ftr/contracts/type/shared'
import { CourtSystemParams } from '@ftr/routing-paths'
import { CourtSystemForbiddenType } from '~app/features/court-system-forbidden/court-system-forbidden.component'

@Component({
  template:
    '<ftr-court-system-forbidden [courtSystemId]="courtSystemId" [courtSystemForbiddenType]="courtSystemForbiddenType"></ftr-court-system-forbidden>',
})
export class CourtSystemForbiddenPage {
  courtSystemId: Uuid
  courtSystemForbiddenType: CourtSystemForbiddenType

  constructor(route: ActivatedRoute) {
    const forbiddenType = route.snapshot.queryParamMap.get(CourtSystemParams.Forbidden)
    this.courtSystemForbiddenType = forbiddenType
      ? (forbiddenType as CourtSystemForbiddenType)
      : CourtSystemForbiddenType.UseConditionsNotAccepted
    this.courtSystemId = route.snapshot.queryParamMap.get(CourtSystemParams.CourtSystemId)!
  }
}
