import { Inject, Injectable } from '@angular/core'
import { API_CONFIGURATION, FullApiClientConfiguration } from '../configuration'
import { HttpClientRemoteData } from '../http-client'
import { ApiClient } from './api-client'
import { ApiClientType } from './api-client-config'
import { ApiErrorMapper } from './api-error.mapper'

/**
 * Create a client service for ftr-api for a given controller path.
 */
@Injectable({
  providedIn: 'root',
})
export class ApiClientFactory {
  constructor(
    private readonly http: HttpClientRemoteData,
    @Inject(API_CONFIGURATION) private readonly config: FullApiClientConfiguration,
    private readonly apiErrorMapper: ApiErrorMapper,
  ) {}

  build(path: string, httpService: HttpClientRemoteData = this.http): ApiClient {
    return new ApiClient(
      httpService,
      this.config,
      this.apiErrorMapper,
      { clientType: ApiClientType.api },
      `/${path.replace(/^\//, '')}`,
    )
  }
}
