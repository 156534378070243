import {
  IndexedLogNote,
  IndexedLogSheet,
  IndexedOrder,
  IndexedRecording,
  IndexedRemark,
  SearchResultType,
  SearchResultTypeDiscriminator,
  SearchedCase,
  SearchedHearing,
} from '@ftr/contracts/api/search'
import { assertUnreachable } from '@ftr/contracts/shared/assertUnreachable'
import { ElasticsearchIndex } from '@ftr/contracts/type/core/elasticsearch'
import { LineItemType } from '@ftr/contracts/type/order'
import { Uuid } from '@ftr/contracts/type/shared'
import { toMomentTimezonedDateAndFormatOrEmpty } from '@ftr/foundation'
import { AppPaths, CourtSystemPaths, OrderPaths } from '@ftr/routing-paths'
import { ZoneId } from '@js-joda/core'

export function viewOrderLink(lineItemType: LineItemType, courtSystemId: Uuid, orderId: Uuid, jobId?: Uuid): string[] {
  switch (lineItemType) {
    case LineItemType.Audio:
      return [AppPaths.CourtSystem, courtSystemId, CourtSystemPaths.AudioOrders, orderId]
    case LineItemType.RealTime:
      return [AppPaths.CourtSystem, courtSystemId, CourtSystemPaths.RealTimeOrders, orderId]
    case LineItemType.CertifiedTranscript:
      return jobId ? [AppPaths.CourtSystem, courtSystemId, CourtSystemPaths.TranscriptOrders, jobId] : []
    default:
      assertUnreachable(lineItemType)
  }
}

export function viewOrderPlaybackLink(orderId?: Uuid): string[] {
  /**
   * When no order id is provided, we will not return a URL, which will result
   * in the same page being linked.
   */
  if (orderId === undefined) {
    return []
  }

  return [AppPaths.Orders, orderId, OrderPaths.Playback]
}

export function viewSharedRecordingLink(sharedRecordingId: Uuid): string[] {
  return [AppPaths.SharedRecordings, sharedRecordingId]
}

export function isIndexedOrder(_: SearchResultType, type: SearchResultTypeDiscriminator): _ is IndexedOrder {
  return type === ElasticsearchIndex.Order
}

export function isIndexedRecording(
  body: SearchResultType | undefined,
  type: SearchResultTypeDiscriminator = ElasticsearchIndex.Recording,
): body is IndexedRecording {
  return type === ElasticsearchIndex.Recording && !!(body as IndexedRecording | undefined)?.courtSystemId
}

export function isSearchedCase(
  body: SearchResultType | undefined,
  type: SearchResultTypeDiscriminator,
): body is SearchedCase {
  return type === 'Cases'
}

export function isSearchedHearing(
  body: SearchResultType | undefined,
  type: SearchResultTypeDiscriminator,
): body is SearchedHearing {
  return type === 'Hearings'
}

export function isIndexedRemark(
  body: SearchResultType | undefined,
  type: SearchResultTypeDiscriminator = ElasticsearchIndex.Recording,
): body is IndexedRemark {
  return type === ElasticsearchIndex.Recording && !!(body as IndexedRemark | undefined)?.content
}

export function isIndexedLogSheet(
  body: SearchResultType | undefined,
  type: SearchResultTypeDiscriminator = ElasticsearchIndex.Recording,
): body is IndexedLogSheet {
  return type === ElasticsearchIndex.Recording && !!(body as IndexedLogSheet | undefined)?.description
}

export function isIndexedLogNote(
  body: SearchResultType | undefined,
  type: SearchResultTypeDiscriminator = ElasticsearchIndex.Recording,
): body is IndexedLogNote {
  return (
    // Since only having a speaker name value constitutes a valid log note and an indexed utterance also
    // has a speaker name, explicitly ensure that it isn't an utterance
    !isIndexedRemark(body, type) &&
    type === ElasticsearchIndex.Recording &&
    !!((body as IndexedLogNote | undefined)?.note || (body as IndexedLogNote | undefined)?.speakerName)
  )
}

export function getPlaybackStart(recording: IndexedRecording, courtSystemTimeZoneId: ZoneId): string {
  const date = recording.transcript?.length ? recording.transcript[0].startTime : recording.recordingStart!
  return toMomentTimezonedDateAndFormatOrEmpty(date, recording.timeZoneId ?? courtSystemTimeZoneId)
}

export function getPlaybackStartForRemark(indexedRemark: IndexedRemark, courtSystemTimeZoneId: ZoneId): string {
  const date = indexedRemark.startTime
  return toMomentTimezonedDateAndFormatOrEmpty(date, indexedRemark.timeZoneId ?? courtSystemTimeZoneId)
}

export function getPlaybackStartForLogNote(logNote: IndexedLogNote, courtSystemTimeZoneId: ZoneId): string {
  return toMomentTimezonedDateAndFormatOrEmpty(logNote.timestamp, logNote.timeZoneId ?? courtSystemTimeZoneId)
}

export function getPlaybackStartForLogSheet(logSheet: IndexedLogSheet, courtSystemTimeZoneId: ZoneId): string {
  return toMomentTimezonedDateAndFormatOrEmpty(logSheet.timestamp, logSheet.timeZoneId ?? courtSystemTimeZoneId)
}
