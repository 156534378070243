import { Injectable } from '@angular/core'
import { UpcomingHearing } from '@ftr/annotations-contracts'
import { AnnotationsApiClientFactory } from '@ftr/api-annotations'
import { Uuid } from '@ftr/contracts/type/shared'
import { RemoteData } from '@ftr/foundation'
import { CourtSystemRegionCache } from '@ftr/ui-court-system'
import { LocalDate } from '@js-joda/core'
import { Action, createSelector, Selector, State, StateContext, StateOperator } from '@ngxs/store'
import { patch } from '@ngxs/store/operators'
import { memoize } from 'lodash-es'
import { firstValueFrom, Observable, of } from 'rxjs'
import {
  FetchNextUpcomingHearingsPageCommand,
  FetchUpcomingHearingsPageCommand,
  InitialiseUpcomingHearingsListCommand,
  SetOngoingCaseReferenceCommand,
} from './upcoming-hearings-list.commands'
import { UpcomingHearingsListAccessForbidden } from './upcoming-hearings-list.events'
import {
  PageResult,
  UpcomingHearingsListInstanceStateModel,
  UpcomingHearingsListStateModel,
} from './upcoming-hearings-list.model'

export const PAGE_SIZE = 10

export function defaultUpcomingHearingsListState(): UpcomingHearingsListStateModel {
  return { instances: {} }
}

@State<UpcomingHearingsListStateModel>({
  name: 'upcomingHearingListState',
  defaults: defaultUpcomingHearingsListState(),
})
@Injectable()
export class UpcomingHearingsListState {
  constructor(
    private readonly regionCache: CourtSystemRegionCache,
    private readonly annotationsApiFactory: AnnotationsApiClientFactory,
  ) {}

  @Selector()
  static allInstanceStates(
    state: UpcomingHearingsListStateModel,
  ): Record<Uuid, UpcomingHearingsListInstanceStateModel> {
    return state?.instances ?? {}
  }

  static readonly upcomingHearings = memoize(
    (
      courtroomId: Uuid,
      date: LocalDate,
    ): ((sourceStates: ReturnType<typeof UpcomingHearingsListState.allInstanceStates>) => UpcomingHearing[]) => {
      return createSelector([UpcomingHearingsListState.allInstanceStates], sourceStates => {
        const instance = sourceStates[getInstanceId(courtroomId, date)]
        return instance?.upcomingHearings ?? []
      })
    },
    (courtroomId, date) => getInstanceId(courtroomId, date),
  )

  static readonly ongoingCaseReference = memoize(
    (
      courtroomId: Uuid,
      date: LocalDate,
    ): ((sourceStates: ReturnType<typeof UpcomingHearingsListState.allInstanceStates>) => string | undefined) => {
      return createSelector([UpcomingHearingsListState.allInstanceStates], sourceStates => {
        const instance = sourceStates[getInstanceId(courtroomId, date)]
        return instance?.ongoingCaseReference
      })
    },
    (courtroomId, date) => getInstanceId(courtroomId, date),
  )

  static readonly pageResultRemote = memoize(
    (
      courtroomId: Uuid,
      date: LocalDate,
    ): ((sourceStates: ReturnType<typeof UpcomingHearingsListState.allInstanceStates>) => RemoteData<PageResult>) => {
      return createSelector([UpcomingHearingsListState.allInstanceStates], sourceStates => {
        const instance = sourceStates[getInstanceId(courtroomId, date)]
        return instance?.pageResultRemote ?? RemoteData.notAsked()
      })
    },
    (courtroomId, date) => getInstanceId(courtroomId, date),
  )

  @Action(InitialiseUpcomingHearingsListCommand)
  private initialiseUpcomingHearingsList(
    { setState, dispatch }: StateContext<UpcomingHearingsListStateModel>,
    { courtSystemId, courtroomId, date, searchTerm }: InitialiseUpcomingHearingsListCommand,
  ): Observable<void> {
    const instanceId = getInstanceId(courtroomId, date)
    setState(
      patch({
        instances: patch({
          [instanceId]: {
            courtSystemId,
            courtroomId,
            date,
            upcomingHearings: [],
            pageResultRemote: RemoteData.notAsked(),
            searchTerm,
            ongoingCaseReference: undefined,
          },
        }),
      }),
    )

    return dispatch(new FetchUpcomingHearingsPageCommand(instanceId, 0, PAGE_SIZE))
  }

  @Action(FetchUpcomingHearingsPageCommand)
  private async fetchUpcomingHearingsPage(
    { getState, setState, dispatch }: StateContext<UpcomingHearingsListStateModel>,
    { instanceId, offset, limit }: FetchUpcomingHearingsPageCommand,
  ): Promise<void> {
    const { courtSystemId, courtroomId, date, pageResultRemote, upcomingHearings, searchTerm } =
      getState().instances[instanceId]

    if (pageResultRemote.isLoading()) {
      return
    }

    setState(
      makeInstancePatch(instanceId, {
        pageResultRemote: RemoteData.loading(),
      }),
    )

    const apiClient = this.annotationsApiFactory.build(
      (await firstValueFrom(this.regionCache.getOrFetchCourtSystem(of(courtSystemId)))).region,
    )

    try {
      const result = await apiClient.upcomingHearings.findUpcomingHearings({
        query: {
          courtSystemId,
          locationId: courtroomId,
          fromDate: date.toString(),
          toDate: date.toString(),
          offset,
          limit,
          searchTerm,
        },
      })

      // short circuit if the search term changes before the request completed
      if (getState().instances[instanceId].searchTerm !== searchTerm) {
        return
      }

      if (result.status === 200) {
        setState(
          makeInstancePatch(instanceId, {
            upcomingHearings: upcomingHearings.concat(result.body.items),
            pageResultRemote: RemoteData.success({ hasMore: result.body.items.length === limit }),
          }),
        )
      } else {
        setState(
          makeInstancePatch(instanceId, {
            pageResultRemote: RemoteData.failure(
              new Error('Failed to fetch upcoming hearings', { cause: result.body }),
            ),
          }),
        )
        if (result.status === 403) {
          dispatch(new UpcomingHearingsListAccessForbidden(courtroomId))
        }
      }
    } catch (err) {
      setState(
        makeInstancePatch(instanceId, {
          pageResultRemote: RemoteData.failure(new Error('Failed to fetch upcoming hearings', { cause: err })),
        }),
      )
    }
  }

  @Action(FetchNextUpcomingHearingsPageCommand)
  private fetchNextUpcomingHearingsPage(
    { getState, dispatch }: StateContext<UpcomingHearingsListStateModel>,
    { courtroomId, date }: FetchNextUpcomingHearingsPageCommand,
  ): Observable<void> {
    const instanceId = getInstanceId(courtroomId, date)
    const { upcomingHearings } = getState().instances[instanceId]

    const offset = upcomingHearings.length
    return dispatch(new FetchUpcomingHearingsPageCommand(instanceId, offset, PAGE_SIZE))
  }

  @Action(SetOngoingCaseReferenceCommand)
  private setOngoingCaseReference(
    { setState }: StateContext<UpcomingHearingsListStateModel>,
    { courtroomId, date, caseReference }: SetOngoingCaseReferenceCommand,
  ): void {
    const instanceId = getInstanceId(courtroomId, date)
    setState(
      makeInstancePatch(instanceId, {
        ongoingCaseReference: caseReference,
      }),
    )
  }
}

function makeInstancePatch(
  instanceId: string,
  instancePartial: Partial<UpcomingHearingsListInstanceStateModel>,
): StateOperator<UpcomingHearingsListStateModel> {
  return patch({
    instances: patch({
      [instanceId]: patch(instancePartial),
    }),
  })
}

export function getInstanceId(courtroomId: Uuid, date: LocalDate): string {
  return `courtroom:${courtroomId}_date:${date.toString()}`
}
