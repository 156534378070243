import { Injectable } from '@angular/core'
import { isForbiddenError, isNotFoundError } from '@ftr/api-shared'
import { CustomerOrder, LineItem } from '@ftr/contracts/api/order'
import { Uuid } from '@ftr/contracts/type/shared'
import { ErrorPageService, RemoteData, tapFailure } from '@ftr/foundation'
import { Action, Selector, State, StateContext, createSelector } from '@ngxs/store'
import { memoize } from 'lodash-es'
import { filter, tap } from 'rxjs'
import { OrderService } from '../../services'
import {
  FetchCustomerOrderAction,
  RequestToRejoinRealTimeOrderAction,
  RequestToStopRealTimeOrderAction,
  ResetOrderStateAction,
  ResetRealTimeOrderAction,
  SetRealTimeOrderEligibleToRejoinAction,
  SetRealTimeOrderIsInProgressAction,
  SetRealTimeOrderUsageFeeAction,
  StartRealTimeOrderAction,
} from './order.actions'
import { OrderStateModel, RealTimeOrderDetails } from './order.model'

export function defaultOrderState(): OrderStateModel {
  return {
    customerOrder: {},
    realTimeOrder: undefined,
  }
}

const orderNotAsked = RemoteData.notAsked()

@State<OrderStateModel>({
  name: 'orderState',
  defaults: defaultOrderState(),
})
@Injectable()
export class OrderState {
  constructor(
    private readonly orderService: OrderService,
    private readonly errorPageService: ErrorPageService,
  ) {}

  static customerOrderById = memoize(
    <TLineItem extends LineItem = LineItem>(
      orderId: Uuid,
    ): ((state: OrderStateModel<TLineItem>) => RemoteData<CustomerOrder<TLineItem>>) => {
      return createSelector(
        [OrderState],
        state => (state.customerOrder && state.customerOrder[orderId]) ?? orderNotAsked,
      )
    },
  )

  @Selector()
  static realTimeOrder(state: OrderStateModel): RealTimeOrderDetails | undefined {
    return state.realTimeOrder
  }

  @Action(FetchCustomerOrderAction)
  fetchCustomerOrder(
    { patchState }: StateContext<OrderStateModel>,
    { orderId, orderIsShared }: FetchCustomerOrderAction,
  ): void {
    this.orderService[orderIsShared ? 'getSharedLineItem' : 'getWithLineItem'](orderId)
      .pipe(
        filter(remote => remote.isCompleted()),
        tapFailure(error => {
          if (isForbiddenError(error)) {
            this.errorPageService.showForbiddenPageWithMessage(`Sorry, you do not have access to this order.
            This could be because the order was not placed by or shared with this account.`)
          }

          if (isNotFoundError(error)) {
            this.errorPageService.showForbiddenPageWithMessage('The requested order does not exist.')
          }
        }),
        tap(customerOrder =>
          patchState({
            /* Note: we rewrite the entire customerOrder each time we fetch a new order so there's only one
           order at a time. We still save orderId to make sure it's the correct order */
            customerOrder: {
              [orderId]: customerOrder,
            },
          }),
        ),
      )
      .subscribe()
  }

  @Action(ResetOrderStateAction)
  resetOrderState({ setState }: StateContext<OrderStateModel>): void {
    setState(defaultOrderState())
  }

  @Action(StartRealTimeOrderAction)
  startLiveOrder({ patchState }: StateContext<OrderStateModel>, { userPlacedOrder }: StartRealTimeOrderAction): void {
    patchState({
      realTimeOrder: {
        userPlacedOrder,
        isInProgress: false,
        usageFee: undefined,
        requestedToStop: false,
        requestedToRejoin: false,
        eligibleToRejoin: false,
      },
    })
  }

  @Action(ResetRealTimeOrderAction)
  resetRealTimeOrder({ patchState }: StateContext<OrderStateModel>): void {
    patchState({ realTimeOrder: undefined })
  }

  @Action(RequestToStopRealTimeOrderAction)
  requestToStopRealTimeOrder(
    { getState, patchState }: StateContext<OrderStateModel>,
    { requestedToStop }: RequestToStopRealTimeOrderAction,
  ): void {
    const realTimeOrder = getState()?.realTimeOrder
    if (realTimeOrder) {
      patchState({
        realTimeOrder: {
          ...realTimeOrder,
          requestedToStop,
        },
      })
    }
  }

  @Action(RequestToRejoinRealTimeOrderAction)
  requestToRejoinRealTimeOrder(
    { getState, patchState }: StateContext<OrderStateModel>,
    { requestedToRejoin }: RequestToRejoinRealTimeOrderAction,
  ): void {
    const realTimeOrder = getState()?.realTimeOrder
    if (realTimeOrder) {
      patchState({
        realTimeOrder: {
          ...realTimeOrder,
          requestedToRejoin,
        },
      })
    }
  }

  @Action(SetRealTimeOrderIsInProgressAction)
  setRealTimeOrderIsInProgressAction(
    { getState, patchState }: StateContext<OrderStateModel>,
    { isInProgress }: SetRealTimeOrderIsInProgressAction,
  ): void {
    const realTimeOrder = getState()?.realTimeOrder
    if (realTimeOrder) {
      patchState({
        realTimeOrder: {
          ...realTimeOrder,
          isInProgress,
        },
      })
    }
  }

  @Action(SetRealTimeOrderUsageFeeAction)
  setUsageFee(
    { getState, patchState }: StateContext<OrderStateModel>,
    { usageFee }: SetRealTimeOrderUsageFeeAction,
  ): void {
    const realTimeOrder = getState()?.realTimeOrder
    if (realTimeOrder) {
      patchState({
        realTimeOrder: {
          ...realTimeOrder,
          usageFee,
        },
      })
    }
  }

  @Action(SetRealTimeOrderEligibleToRejoinAction)
  setRealTimeOrderEligibleToRejoinAction(
    { getState, patchState }: StateContext<OrderStateModel>,
    { eligibleToRejoin }: SetRealTimeOrderEligibleToRejoinAction,
  ): void {
    const realTimeOrder = getState()?.realTimeOrder
    if (realTimeOrder) {
      patchState({
        realTimeOrder: {
          ...realTimeOrder,
          eligibleToRejoin,
        },
      })
    }
  }
}
