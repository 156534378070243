import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MultiChannelPlaylistModule } from '@ftr/ui-playback'
import { HeaderUnderlayComponent } from '~app/features/header-underlay/header-underlay.component'

@NgModule({
  declarations: [HeaderUnderlayComponent],
  imports: [CommonModule, MultiChannelPlaylistModule],
  exports: [HeaderUnderlayComponent],
})
export class HeaderUnderlayModule {}
