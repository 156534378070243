import { Uuid } from '@ftr/contracts/type/shared'
import { matchSttContext, SttContext } from './SttContext'

/*
 * This is used by the Audio Order Playback Page.
 * We are listen to socket events based on the OrderId.
 * We query data based on the AudioSegmentId.
 */

export interface AudioOrderSttContext {
  type: 'audio-order'
  orderId: Uuid
  audioSegmentId: Uuid
}

export function buildAudioOrderSttContext(orderId: Uuid, audioSegmentId: Uuid): AudioOrderSttContext {
  return {
    type: 'audio-order',
    orderId,
    audioSegmentId,
  }
}

export const audioOrderSttContextEquals =
  (other: SttContext) =>
  (self: AudioOrderSttContext): boolean =>
    matchSttContext(other, {
      mapAudioOrder: ({ orderId }) => orderId === self.orderId,
      mapRecording: () => false,
      mapSharedRecording: () => false,
    })
