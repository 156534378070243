import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { IDENTITY_PROVIDER, RETURN_TO_DESKTOP, RETURN_URL_KEY, RootParams } from '@ftr/api-shared'
import { IdentityProviderName } from '@ftr/contracts/type/account'
import { LocalStorageService } from '@ftr/foundation'
import { AppPaths } from '@ftr/routing-paths'
import { AuthenticationService } from '@ftr/ui-user'

@Component({
  selector: 'ftr-external-login',
  template: '<ftr-loader [isLoading]="true" [fullPage]="true"></ftr-loader>',
})
export class ExternalLoginComponent implements OnInit {
  @Input() authorizationCode: string
  @Input() state: string

  private returnUrl: string | undefined

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
    private readonly localStorageService: LocalStorageService,
  ) {}

  async ngOnInit(): Promise<void> {
    const identityProvider = this.localStorageService.getGlobal<IdentityProviderName>(IDENTITY_PROVIDER)
    const returnToDesktop = !!this.localStorageService.getGlobal<boolean>(RETURN_TO_DESKTOP)
    this.localStorageService.remove(RETURN_TO_DESKTOP)

    if (identityProvider) {
      this.returnUrl = this.localStorageService.getGlobal<string>(RETURN_URL_KEY)

      if (returnToDesktop) {
        await this.router.navigate(['/', AppPaths.DesktopLoginSplash], {
          queryParams: {
            [RootParams.AuthorizationCode]: this.authorizationCode,
            [RootParams.State]: this.state,
          },
          replaceUrl: true,
        })
      } else {
        await this.authenticationService.exchangeCodeForToken(identityProvider, this.authorizationCode, this.state)

        const { result: postLoginResult, getValidReturnUrl } = await this.authenticationService.postLoginProcess()
        if (!postLoginResult) {
          await this.router.navigateByUrl(getValidReturnUrl(this.returnUrl), {
            replaceUrl: true,
          })
        }
      }
    } else {
      // There's an edge case where a user can open two deep links to the desktop app,
      // and the stored provider is already consumed
      await this.router.navigateByUrl(AppPaths.Login, {
        replaceUrl: true,
      })
    }
  }
}
