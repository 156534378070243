import { Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core'
import { KeyPress, SetKeyPress, SetKeyPressReleased } from '@ftr/ui-core'
import { Store } from '@ngxs/store'
import { Subject, fromEventPattern, map, takeUntil } from 'rxjs'

// Meta on mac is `command`, and `Windows ⊞` key on windows.
const MODIFIER_KEYS = ['Control', 'Shift', 'Meta']

/**
 * This directive listens for key presses and writes to the store when a shortcut combination is pressed.
 * Also clears the store when the key is released.
 */
@Injectable({
  providedIn: 'root',
})
export class RecordKeyPressToStoreService implements OnDestroy {
  private removeClickEventListener: () => void
  private finalize$ = new Subject<void>()
  private renderer: Renderer2

  constructor(
    rendererFactory: RendererFactory2,
    private readonly store: Store,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null)
  }

  syncKeyPressesToStore(): void {
    const keyDownEvents$ = fromEventPattern<Event | KeyboardEvent>(
      handler => this.createKeydownEventListener(handler),
      () => this.removeClickEventListener(),
    ).pipe(takeUntil(this.finalize$), map(this.toKeyPress))

    const keyUpEvents$ = fromEventPattern<Event | KeyboardEvent>(
      handler => this.createKeydownUpListener(handler),
      () => this.removeClickEventListener(),
    ).pipe(takeUntil(this.finalize$))

    keyDownEvents$.subscribe(keypress => this.store.dispatch(new SetKeyPress(keypress)))
    keyUpEvents$.subscribe(() => this.store.dispatch(new SetKeyPressReleased()))
  }

  ngOnDestroy(): void {
    this.finalize$.next()
  }

  private toKeyPress(event: Event | KeyboardEvent): KeyPress | undefined {
    // using autofill can trigger keydown and keyup events using Event
    if (event instanceof KeyboardEvent && !MODIFIER_KEYS.includes(event.key)) {
      return {
        key: event.key.toLowerCase(),
        modifiers: { ctrl: event.ctrlKey, shift: event.shiftKey, meta: event.metaKey, alt: event.metaKey },
      }
    }
    return undefined
  }

  private createKeydownEventListener(handler: () => void): void {
    this.removeClickEventListener = this.renderer.listen('window', 'keydown', handler)
  }

  private createKeydownUpListener(handler: () => void): void {
    this.removeClickEventListener = this.renderer.listen('window', 'keyup', handler)
  }
}
