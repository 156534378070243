// eslint-disable-next-line max-classes-per-file
import { generateUuid, Uuid } from '@ftr/contracts/type/shared'
import { AbstractBuilder, HIGHLIGHT_TAG, HIGHLIGHT_TAG_CLOSING } from '@ftr/foundation'
import { LocalDateTime } from '@js-joda/core'

export interface RealTimeSearchStateModel {
  searchInstanceState: Record<Uuid, RealTimeSearchInstanceStateModel | undefined>
}

const HIGHLIGHT_TAG_REGEX = new RegExp(HIGHLIGHT_TAG, 'g')
const HIGHLIGHT_TAG_CLOSING_REGEX = new RegExp(HIGHLIGHT_TAG_CLOSING, 'g')

export class RealTimeSearchFieldMatch {
  /**
   * When there are highlighting snippets this will be a html string that will contain the rawValue merged
   * with the highlighting parts. This will include one level of <em> tags around search matches. This value should be treated
   * with caution when rendering to ensure only em tags are shown since speaker names (and possibly results) may contain
   * user entered values.
   *
   * This will be undefined if there are no highlightedSnippets, in which case the raw value should be used instead and
   * it can be escaped via angular templating as it does not need to render <em> tags.
   *
   * ST-1656 Replace this with an array that breaks the result into highlighted and non-highligted text context
   */
  public readonly valueWithHighlightingHtml: string | undefined

  /**
   *
   * @param rawValue The full raw value of the search field, e.g. for the remark content this is the entire text of the remark.
   * @param highlightedSnippets An array of html string that contains <em> tags around bits that were matched in the
   *  search. Note that these snippets will contain text around the matches but may not make up the entire fields value if
   *  concatenated.
   *
   */
  constructor(
    readonly rawValue: string,
    readonly highlightedSnippets?: string[],
  ) {
    this.rawValue = rawValue
    this.highlightedSnippets = highlightedSnippets
    this.valueWithHighlightingHtml = this.mergeHighlightedPartsWithRawValue()
  }

  private mergeHighlightedPartsWithRawValue(): string | undefined {
    if (!this.highlightedSnippets?.length) {
      return undefined
    }

    let mergedContent = this.rawValue
    for (const highlightedSnippet of this.highlightedSnippets) {
      const withoutHighlightTags = highlightedSnippet
        .replace(HIGHLIGHT_TAG_REGEX, '')
        .replace(HIGHLIGHT_TAG_CLOSING_REGEX, '')
      mergedContent = mergedContent.replace(withoutHighlightTags, highlightedSnippet)
    }

    return mergedContent
  }
}

export interface RealTimeSearchResult {
  remarkId: string
  startTime: LocalDateTime
  endTime: LocalDateTime
  speakerNameMatch: RealTimeSearchFieldMatch
  contentMatch: RealTimeSearchFieldMatch
}

export interface RealTimeSearchPageInfo {
  currentPage: number
  morePages: boolean
  numResults: number
}

export enum RealTimeSearchErrorCode {
  Unknown = 'Unknown',
  RateLimit = 'RateLimit',
}

export interface RealTimeSearchRequestInfo {
  requestId: Uuid
  type: 'initial' | 'next-page'
  loading: boolean
  error: RealTimeSearchErrorCode | undefined
}

export interface RealTimeSearchScrollPosition {
  scrollTop: number
}

export interface RealTimeSearchInstanceStateModel {
  searchTerm: string
  selectedSearchResultIndex: number | undefined
  hoveredSearchResultIndex: number | undefined
  request: RealTimeSearchRequestInfo | undefined
  results: RealTimeSearchResult[]
  pageInfo: RealTimeSearchPageInfo | undefined
  scrollPosition: RealTimeSearchScrollPosition | undefined
}

export class RealTimeSearchResultBuilder extends AbstractBuilder<RealTimeSearchResult> {
  constructor() {
    super({
      remarkId: generateUuid(),
      startTime: LocalDateTime.parse('2018-08-12T18:00'),
      endTime: LocalDateTime.parse('2018-08-12T18:01'),
      contentMatch: new RealTimeSearchFieldMatch('Some content'),
      speakerNameMatch: new RealTimeSearchFieldMatch('Bow chikka wow-wow'),
    })
  }

  static militaryCouncilHighlightedResult(options: {
    highlightHtml: boolean
    highlightSpeaker: boolean
  }): RealTimeSearchResultBuilder {
    const highlightContentSnippets = options.highlightHtml
      ? ['The military <em>council</em> decrees that...']
      : undefined
    const highlightSpeakerNameSnippets = options.highlightSpeaker ? ['Defense <em>Witness</em>'] : undefined

    return new RealTimeSearchResultBuilder().with({
      contentMatch: new RealTimeSearchFieldMatch(
        'The military council decrees that and some more text here.',
        highlightContentSnippets,
      ),
      speakerNameMatch: new RealTimeSearchFieldMatch('Defense Witness', highlightSpeakerNameSnippets),
    })
  }
}
