import { Directive, OnDestroy } from '@angular/core'
import { Subject } from 'rxjs'

/**
 * Add this to your component if you wish to use something like `takeUntil(this.finalize)` to complete a
 * subscription within your component.
 * NOTE: This is not required for simple API calls, which `complete` the subscription as soon as the result is returned.
 * An example use-case is below. Without takeUntil(this.finalize), the subscription will not complete and may
 * linger in the application across page views, increasing the memory footprint used by the app. Explicitly using
 * takeUntil is a safeguard that means the subscription will be removed when the component is destroyed.
 *
 * @Component(...)
 * export class TestComponent extends DestroySubscribers {
 *   @Input() observable: Observable<Uuid>
 *   constructor(private readonly someService: SomeService) { super() }
 *   ngOnInit (): void {
 *     this.observable.pipe(takeUntil(this.finalize)).subscribe(() => doThings())
 *   }
 * }
 */

@Directive()
export abstract class DestroySubscribers implements OnDestroy {
  readonly finalize = new Subject<void>()

  ngOnDestroy(): void {
    // Useful for debugging:
    // console.log('Destroying subscriptions on ', this.constructor.name)
    this.finalize.next()
    this.finalize.complete()
  }
}
