import { Component, HostBinding, Input } from '@angular/core'
import { Spacing } from '../util'

@Component({
  standalone: true,
  selector: 'ftr-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css'],
  imports: [],
})
export class CardComponent {
  @Input()
  @HostBinding('style.padding')
  padding = Spacing.Double
}
