import { AfterViewInit, Directive, Inject, Input, Self } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { FORM_INPUT_TOKEN, FormInputComponent } from './form-input.component'

/**
 * Autofocus the HTML input that this directive is applied to.
 *
 * This is in lieu of HTML's native autofocus attribute, which does not reliably
 * work with Angular components, since they can be loaded in *after* initial page load.
 *
 * This directive is restricted to `ftr-form-input` components.
 *
 * @example
 *
 * <ftr-form-input [ngFormControl]="someControl" [autofocus]="true"></ftr-form-input>
 */

@Directive({
  selector: 'ftr-form-input[autofocus], ftr-search-input[autofocus]',
  standalone: true,
})
export class FormInputAutofocusDirective implements AfterViewInit {
  @Input('autofocus') shouldAutofocus = false
  @Input() ngFormControl: UntypedFormControl

  private hasTouchedInputOnce = false

  constructor(@Inject(FORM_INPUT_TOKEN) @Self() private inputComponent: Partial<FormInputComponent>) {}

  ngAfterViewInit(): void {
    if (this.shouldAutofocus) {
      const inputElement = this.inputComponent.input?.nativeElement
      inputElement?.focus()
      inputElement?.addEventListener('blur', () => this.onBlur())
    }
  }

  onBlur(): void {
    if (this.shouldAutofocus && !this.hasTouchedInputOnce && this.ngFormControl.pristine) {
      this.hasTouchedInputOnce = true
      this.ngFormControl.markAsUntouched()
    }
  }
}
