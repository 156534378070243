import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { Subscription } from 'rxjs'
import { GlobalEventsService } from '../../services/global-events/global-events.service'

/**
 * Handles closing of a component when focus is lost or the user clicks outside the element.
 *
 */

@Directive({
  selector: '[clickOutside]',
  standalone: true,
})
export class OnClickOutsideDirective implements OnInit, OnDestroy {
  /**
   * Only emit an event on outside click when the element is in an open state
   */
  @Input() openState: boolean
  /**
   * In safari, some events are setting custom components as the target which
   * incorrectly trigger the `clickOutside` event. Use invalidTargetNames if you need to filter
   * out a list of specific components.
   */
  @Input() invalidTargetNames?: string[]

  /**
   * The event to emit when clicking outside the element this directive is bound to.
   * @type {EventEmitter<MouseEvent>}
   */
  @Output() clickOutside = new EventEmitter<MouseEvent | FocusEvent>()

  private eventSubscription: Subscription

  constructor(
    private readonly elementRef: ElementRef,
    private readonly globalEventsService: GlobalEventsService,
  ) {}

  ngOnInit(): void {
    this.eventSubscription = this.globalEventsService.event$.subscribe(event => {
      if (event instanceof MouseEvent) {
        this.handleClickEvent(event)
      }

      if (event instanceof FocusEvent) {
        this.handleFocusEvent(event)
      }
    })
  }

  ngOnDestroy(): void {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe()
    }
  }

  private handleClickEvent(event: MouseEvent): void {
    if (!event?.target || !this.openState) {
      return
    }

    const clickedInside = this.elementRef.nativeElement.contains(event.target)
    if (!clickedInside) {
      this.clickOutside.emit(event)
    }
  }

  private handleFocusEvent(event: FocusEvent): void {
    const target = event?.target as HTMLElement
    const invalidTarget = this.invalidTargetNames?.includes(target?.localName)

    if (!target || !this.openState || invalidTarget) {
      return
    }
    const focusedInside = this.elementRef.nativeElement.contains(target)
    if (!focusedInside && target.localName !== 'body') {
      this.clickOutside.emit(event)
    }
  }
}
