import { Injectable } from '@angular/core'
import { ApiClientFactory, CacheableApiClient } from '@ftr/api-shared'
import { GetCourtroomsQuery, Location } from '@ftr/contracts/api/location'
import { Uuid } from '@ftr/contracts/type/shared'
import { ApiResult } from '@ftr/foundation'
import { classToPlain } from '@ftr/serialization'
import { ChildLocation } from '../types'

@Injectable({
  providedIn: 'root',
})
export class CourtroomService extends CacheableApiClient<ChildLocation> {
  constructor(apiClientFactory: ApiClientFactory) {
    super(apiClientFactory, 'location')
  }

  protected retrieveAll(courtSystemId: Uuid, courtroomIds: Uuid[]): ApiResult<ChildLocation[]> {
    return this.apiClient.post({
      path: '/courtrooms',
      body: classToPlain<GetCourtroomsQuery>({
        courtSystemId,
        courtroomIds,
      }),
      responseBodyType: Location,
    })
  }
}
