import { A11yModule } from '@angular/cdk/a11y'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { IconComponent } from '@ftr/foundation'
import { SideNavItemInnerComponent } from './side-nav-item/side-nav-item-inner.component'
import { SideNavItemComponent } from './side-nav-item/side-nav-item.component'
import { SideNavMenuToggleComponent } from './side-nav-menu-toggle/side-nav-menu-toggle.component'
import { SideNavComponent } from './side-nav.component'

@NgModule({
  imports: [CommonModule, IconComponent, RouterModule, A11yModule],
  declarations: [SideNavComponent, SideNavItemComponent, SideNavMenuToggleComponent, SideNavItemInnerComponent],
  exports: [SideNavComponent, SideNavMenuToggleComponent],
})
export class SideNavModule {}
