import { ShortcutMapEntries } from '@ftr/contracts/type/desktop'

export const SHORTCUT_SEPARATOR = '+'
export const SHORTCUT_SHIFT_MODIFIER = 'Shift'

/**
 * Takes a generic shortcut and makes the modifiers platform specific, eg:
 *  CommandOrControl+1 becomes Cmd+1 on macOS, or Ctrl+1 on windows
 */
export function mapAcceleratorsPlatformSpecificModifiers(
  shortcuts: ShortcutMapEntries,
  isMacOs: boolean,
): ShortcutMapEntries {
  const mappings = getShortcutMappings(isMacOs)
  return shortcuts.map(([shortcut, accelerator]) => [shortcut, mapStringAccelerator(accelerator, mappings)])
}

function mapStringAccelerator(accelerator: string, mappings: Map<string, string>): string {
  return keysToAccelerator(acceleratorToKeys(accelerator).map(key => mappings.get(key) ?? key))
}

export function mapAcceleratorModifier(key: string, isMacOs: boolean): string {
  return getShortcutMappings(isMacOs).get(key) ?? key
}

function getShortcutMappings(isMacOs: boolean): Map<string, string> {
  return isMacOs ? SHORTCUT_MODIFIER_MAPPINGS_FOR_MAC : SHORTCUT_MODIFIER_MAPPINGS_FOR_WINDOWS
}

export function keysToAccelerator(keys: string[]): string {
  return keys.join(SHORTCUT_SEPARATOR)
}

function acceleratorToKeys(accelerator: string): string[] {
  return accelerator.split(SHORTCUT_SEPARATOR)
}

// Only map Meta -> Win for presentation on windows, as Win is not a valid accelerator in electron
const PRESENTATION_MODIFIER_MAPPINGS_FOR_WINDOWS = new Map<string, string>([['Meta', 'Win']])

export function mapAcceleratorToDisplayKeys(accelerator: string, isMac: boolean): string[] {
  let keys = acceleratorToKeys(accelerator)
  if (!isMac) {
    keys = keys.map(key => PRESENTATION_MODIFIER_MAPPINGS_FOR_WINDOWS.get(key) ?? key)
  }
  return keys
}

export function mapAcceleratorToDisplayAccelerator(accelerator: string, isMac: boolean): string {
  return mapAcceleratorToDisplayKeys(accelerator, isMac).join(` ${SHORTCUT_SEPARATOR} `)
}

export function mapKeycodeToAccelerator(code: string): string {
  return Object.prototype.hasOwnProperty.call(codeToAccelerator, code)
    ? codeToAccelerator[code]
    : code.replace(/Digit|Key/, '')
}

export function findShortcutModifier(event: KeyboardEvent): string | undefined {
  return (
    checkModifier(event.code, event.altKey, 'Alt') ||
    checkModifier(event.code, event.ctrlKey, 'Command') ||
    checkModifier(event.code, event.ctrlKey, 'Control') ||
    checkModifier(event.code, event.metaKey, 'Meta') ||
    checkModifier(event.code, event.shiftKey, SHORTCUT_SHIFT_MODIFIER)
  )
}

function checkModifier(code: string, modifierOn: boolean, modifierString: string): string | undefined {
  if (modifierOn && code.includes(modifierString)) {
    return modifierString
  }
  return undefined
}

const SHORTCUT_MODIFIER_MAPPINGS_FOR_WINDOWS = new Map<string, string>([
  ['CommandOrControl', 'Ctrl'],
  ['CmdOrCtrl', 'Ctrl'],
  ['Control', 'Ctrl'],
])

const SHORTCUT_MODIFIER_MAPPINGS_FOR_MAC = new Map<string, string>([
  ['Alt', 'Option'],
  ['Meta', 'Cmd'],
  ['CommandOrControl', 'Cmd'],
  ['CmdOrCtrl', 'Cmd'],
  ['Control', 'Ctrl'],
])

// Maps keyboard codes to valid electron accelerators
const codeToAccelerator: Record<string, string> = {
  Backquote: '`',
  Minus: '-',
  Equal: '=',
  BracketLeft: '[',
  BracketRight: ']',
  Backslash: '\\',
  Semicolon: ';',
  Quote: "'",
  Comma: ',',
  Period: '.',
  Slash: '/',
  IntlBackslash: '`',
  Return: 'Enter',
  NumpadEnter: 'Enter',
  ArrowUp: 'Up',
  ArrowDown: 'Down',
  ArrowLeft: 'Left',
  ArrowRight: 'Right',
  Numpad0: 'num0',
  Numpad1: 'num1',
  Numpad2: 'num2',
  Numpad3: 'num3',
  Numpad4: 'num4',
  Numpad5: 'num5',
  Numpad6: 'num6',
  Numpad7: 'num7',
  Numpad8: 'num8',
  Numpad9: 'num9',
  NumpadDecimal: 'numdec',
  NumpadAdd: 'numadd',
  NumpadSubtract: 'numsub',
  NumpadMultiply: 'nummult',
  NumpadDivide: 'numdiv',
}
