<div class="list-filter">
  <ftr-select
    class="list-filter__select"
    [control]="control"
    [items$]="options"
    [enabled]="true"
    [singular]="singular || 'a filter'"
    [defaultValue]="initialValue"
    [selectedItemFormatter]="selectedItemFormatter.bind(this)"
    (onSelectionChange)="onFilterSelectionChange($event)"
  >
  </ftr-select>
</div>
