<ftr-page [pageStyle]="pageStyle">
  <div class="card card--borderless card--shadowless password-card reset-sent">
    <h1 class="reset-sent__title">Change Password Email Sent</h1>
    <p class="password-card__paragraph-text">
      We've sent a message to <span class="reset-sent__email">{{ email }}</span
      >. Please follow the instructions in this email to change your password.
    </p>
    @if (!isHelpShown) {
      <a (click)="showHelp()" class="reset-sent__link password-card__subtitle" data-track
        >I have not received an email</a
      >
    } @else {
      <p class="password-card__paragraph-text">
        It can take a few minutes for emails to arrive and sometimes they may appear in your spam or junk folder. If
        there is still no sign of your message maybe you have yet to complete the
        <a [routerLink]="['/signup']" data-track>sign up process</a>. If you are sure you already have an account you
        can <a (click)="resendCode()" class="reset-sent__link" data-track>request another email</a> or contact us at
        <a data-track data-track-group="Support" href="mailto:support@fortherecord.com">support&#64;fortherecord.com</a
        >.
      </p>
      <ftr-notification-flash></ftr-notification-flash>
    }
  </div>
</ftr-page>
