<div
  class="loader"
  [class.loader--loading]="isLoading"
  [class.loader--fullPage]="fullPage"
  [class.loader--collapse]="collapse"
  [class.loader--fitToBottom]="fitToBottom"
  [class.loader--fitToButtonBottom]="fitToButtonBottom"
  role="progressbar"
>
  <div class="loader__text">
    {{ message }}
    @if (!collapse) {
      <div class="loader__line">
        <div class="loader__indeterminate"></div>
      </div>
    }
    @if (collapse) {
      <div class="loader__line" [@grow]="'in'">
        <div class="loader__indeterminate"></div>
      </div>
    }
  </div>
</div>
