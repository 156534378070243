import { InjectionToken } from '@angular/core'

export const OBSERVABILITY_CONFIGURATION = new InjectionToken<ObservabilityConfiguration>('OBSERVABILITY_CONFIGURATION')

/**
 * NOTE: Deliberately left extendable for sentry
 */
export interface ObservabilityConfiguration {
  logging: LoggingConfiguration
}

export interface LoggingConfiguration {
  url: string
  debug: boolean
}
