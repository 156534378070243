import { Injectable } from '@angular/core'
import { SavedCard } from '@ftr/contracts/read/order-account'
import { ApiResult, RemoteData, switchMapData } from '@ftr/foundation'
import { UserService, UserState } from '@ftr/ui-user'
import { Action, Selector, State, StateContext, Store } from '@ngxs/store'
import { tap } from 'rxjs'
import { OrderAccountService } from '../../services'
import { FetchSavedCardsAction } from './order-account.actions'
import { OrderAccountStateModel } from './order-account.model'

export function defaultOrderAccountState(): OrderAccountStateModel {
  return {
    savedCards: undefined,
  }
}

/**
 * State for all user specific ordering related information (Such as saved cards)
 */
@State<OrderAccountStateModel>({
  name: 'orderAccountState',
  defaults: defaultOrderAccountState(),
})
@Injectable()
export class OrderAccountState {
  constructor(
    private readonly orderAccountService: OrderAccountService,
    private readonly userService: UserService,
    private readonly store: Store,
  ) {}

  /**
   * Note: this action fetches saved cards for the current user only
   */
  @Action(FetchSavedCardsAction)
  fetchSavedCards({ patchState }: StateContext<OrderAccountStateModel>): ApiResult<SavedCard[]> {
    const stateUser = this.store.selectSnapshot(UserState.user).get()
    return (stateUser && stateUser.id ? ApiResult.success(stateUser) : this.userService.getUserDetails()).pipe(
      switchMapData(user =>
        this.orderAccountService.listSavedCardsByUserId(user.id).pipe(tap(savedCards => patchState({ savedCards }))),
      ),
    )
  }

  @Selector()
  static savedCards(state: OrderAccountStateModel): RemoteData<SavedCard[]> {
    return state.savedCards || RemoteData.notAsked()
  }
}
