import { Pipe, PipeTransform } from '@angular/core'
import { CardBrand } from '@ftr/contracts/type/order/CardDetails'

/**
 * Convert: 'American Express' to 'amex' for the card images.
 */
@Pipe({ name: 'ftrCreditCardBrandImage', standalone: true })
export class CreditCardBrandImagePipe implements PipeTransform {
  transform(brand: string): string {
    switch (brand) {
      case CardBrand.AmericanExpress:
        return 'amex'
      case CardBrand.DinersClub:
        return 'diners'
      case CardBrand.Discover:
        return 'discover'
      case CardBrand.JCB:
        return 'jcb'
      case CardBrand.MasterCard:
        return 'mastercard'
      case CardBrand.Visa:
        return 'visa'
      default:
        return 'unknown'
    }
  }
}
