import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { DasherizePipe, LineLoaderComponent, RemoteComponent } from '@ftr/foundation'
import { MoneyAmountInputDirective } from '../../directives'
import { CurrencyNamePipe, CurrencyPipe, MoneyAmountPipe } from '../../pipes'
import { PaymentBreakdownComponent } from '../payment/breakdown/payment-breakdown.component'
import { PaymentComponent } from '../payment/payment.component'
import { MoneyComponent } from './money.component'
/**
 * This module contains reusable components, pipes and directives for money display and manipulation
 */

@NgModule({
  imports: [CommonModule, DasherizePipe, RemoteComponent, LineLoaderComponent],
  declarations: [
    CurrencyPipe,
    CurrencyNamePipe,
    MoneyAmountPipe,
    MoneyAmountInputDirective,
    MoneyComponent,
    PaymentComponent,
    PaymentBreakdownComponent,
  ],
  exports: [
    CurrencyPipe,
    CurrencyNamePipe,
    MoneyAmountPipe,
    MoneyAmountInputDirective,
    MoneyComponent,
    PaymentComponent,
    PaymentBreakdownComponent,
  ],
})
export class MoneyModule {}
