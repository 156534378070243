import { TemplatePortal } from '@angular/cdk/portal'
import { Injectable, TemplateRef, ViewContainerRef } from '@angular/core'
import { PortalService } from '../portal'

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private _isOpen = false

  constructor(private readonly portalService: PortalService) {}

  close(): void {
    this.portalService.setContent(undefined, true)
    this._isOpen = false
  }

  open(templateRef: TemplateRef<any>, viewContainerRef: ViewContainerRef): void {
    this.portalService.setContent(new TemplatePortal(templateRef, viewContainerRef), true)
    this._isOpen = true
  }

  get isOpen(): boolean {
    return this._isOpen
  }
}
