import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { LogoutParams } from '@ftr/routing-paths'
import { AuthenticationService, SignOutReason } from '@ftr/ui-user'
@Component({
  selector: 'ftr-logout',
  template: '<ftr-app-loader [ariaValueText]="\'Logging out\'"></ftr-app-loader>',
})
export class LogoutComponent implements OnInit {
  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    const reason = this.route.snapshot.queryParamMap.get(LogoutParams.LogoutReason) as SignOutReason
    this.authenticationService.signOut(reason || SignOutReason.LOGOUT)
  }
}
