/* eslint-disable max-classes-per-file */
import { Uuid } from '@ftr/contracts/type/shared'
import { RealTimePlaybackKey } from '@ftr/data-realtime-playback'
import { HearingSectionModel } from './annotations.model'

export class HearingSectionCreatedEvent {
  static readonly type = '[Annotations] Create Hearing Section Event'
  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly courtSystemId: Uuid,
    readonly id: Uuid,
    readonly triggerSource: 'right-click stt' | 'playback clock' | 'live clock' | 'upcoming hearing',
  ) {}
}

export class HearingSectionDeletedEvent {
  static readonly type = '[Annotations] Delete Hearing Section Event'
  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly id: Uuid,
    readonly source: 'popover' | 'conflict',
  ) {}
}

export class HearingSectionUpdatedEvent {
  static readonly type = '[Annotations] Edit Hearing Section Event'
  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly id: Uuid,
    readonly previousHearingSection: HearingSectionModel,
    readonly updatedHearingSection: HearingSectionModel,
  ) {}
}
