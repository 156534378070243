import { CommonModule } from '@angular/common'
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core'
import { IconComponent, IconSize } from '@ftr/foundation'
import { AvatarColors, AvatarUser, UserAvatarService } from '../../services'

const DEFAULT_AVATAR_SIZE: IconSize = 'Large'

@Component({
  selector: 'ftr-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.css'],
  standalone: true,
  imports: [IconComponent, CommonModule],
})
export class UserAvatarComponent implements OnInit, OnChanges {
  @Input() user: AvatarUser | undefined
  @Input() color: AvatarColors | undefined
  @Input() size: IconSize = DEFAULT_AVATAR_SIZE

  userInitials: string | undefined

  constructor(private readonly userAvatarService: UserAvatarService) {}

  ngOnInit(): void {
    if (this.user) {
      this.updateAvatar(this.user)
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user) {
      this.updateAvatar(changes.user.currentValue)
    }
  }

  updateAvatar(user: AvatarUser | undefined): void {
    if (user?.givenName || user?.familyName) {
      // In the future we can check if the user has an image attached to their user object
      //  and display that instead of the initials/colors
      this.userInitials = (user.givenName?.[0] || '') + (user.familyName?.[0] || '')
      this.color = this.userAvatarService.getAvatarColorFromUser(user)
    }
  }
}
