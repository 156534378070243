import { Injectable } from '@angular/core'
import { ApiClient } from '@ftr/api-shared'
import { Uuid } from '@ftr/contracts/type/shared'
import { Observable } from 'rxjs'
import { RegionalApiClientFactory } from './regional-api-client.factory'

@Injectable({
  providedIn: 'root',
})
export class RegionalApiClientSetupService {
  regionalApiPath: string
  readonly regionalApiClientMap = new Map<string, Observable<ApiClient>>()

  constructor(private readonly regionalApiClientFactory: RegionalApiClientFactory) {}

  regionalApiClient(path: string, courtSystemId: Uuid): Observable<ApiClient> {
    const uniqueKey = `${path}-${courtSystemId}`
    if (!this.regionalApiClientMap.has(uniqueKey)) {
      // Note on testing: regionalApiClientFactory.build needs to return from([apiClient.object]),
      // not of(apiClient.object)
      this.regionalApiClientMap.set(uniqueKey, this.regionalApiClientFactory.buildForCourt(path, courtSystemId))
    }
    return this.regionalApiClientMap.get(uniqueKey)!
  }
}
