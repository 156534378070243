<div class="input-minus-plus">
  <div class="input-minus-plus__inputs">
    <button
      class="input-minus-plus__minus"
      type="button"
      (click)="minus()"
      ftrButton
      [disabled]="isMinusDisabled"
      displayType="icon"
      title="Subtract"
      aria-label="Subtract"
    >
      <ftr-icon icon="RemoveCircle" align="Middle"></ftr-icon>
    </button>

    <input
      class="input-minus-plus__input input__control"
      type="number"
      ftrStopPropagation
      [required]="true"
      [formControl]="internalControl"
      (keyup.escape)="setFormControlValue($event.target)"
      [attr.aria-label]="label"
    />

    <button
      class="input-minus-plus__plus"
      type="button"
      (click)="plus()"
      ftrButton
      [disabled]="isPlusDisabled"
      displayType="icon"
      title="Add"
      aria-label="Add"
    >
      <ftr-icon icon="AddCircle" align="Middle"></ftr-icon>
    </button>
  </div>
</div>
