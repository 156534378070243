import { AsyncPipe, NgClass } from '@angular/common'
import { Component, Input, forwardRef } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { AnchorComponent, IconComponent, StopPropagationDirective } from '@ftr/foundation'
import { ValidationErrorHintDirective } from '../../directives'
import { FORM_INPUT_TOKEN, FormInputComponent } from '../form-input'

export enum SearchInputClear {
  Clear = 'clear',
  Cross = 'cross',
}

@Component({
  selector: 'ftr-search-input',
  templateUrl: './search-input.component.html',
  providers: [{ provide: FORM_INPUT_TOKEN, useExisting: forwardRef(() => SearchInputComponent) }],
  imports: [
    NgClass,
    IconComponent,
    AsyncPipe,
    ReactiveFormsModule,
    AnchorComponent,
    StopPropagationDirective,
    ValidationErrorHintDirective,
  ],
  standalone: true,
})
/**
 * An extension of ftr-form-input with a custom css for search boxes.
 */
export class SearchInputComponent extends FormInputComponent {
  /**
   * Specifies which type of clear button to use – CLEAR or X.
   */
  @Input() clearType?: SearchInputClear

  readonly searchInputClear = SearchInputClear

  clear(): void {
    this.ngFormControl.reset()
  }
}
