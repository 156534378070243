import { NgClass } from '@angular/common'
import { Component, Input } from '@angular/core'
import { RouterModule } from '@angular/router'
import { ThemedLogoPathPipe } from '../../pipes/themed-logo-path'
import { LogoSize, LogoTheme } from '../../types/logo'

@Component({
  selector: 'ftr-logo',
  templateUrl: './ftr-logo.component.html',
  styleUrls: ['./ftr-logo.component.css'],
  standalone: true,
  imports: [RouterModule, ThemedLogoPathPipe, NgClass],
})
export class FtrLogoComponent {
  @Input() size: LogoSize = LogoSize.Default
  @Input() theme: LogoTheme = LogoTheme.Themed
  readonly themes = LogoTheme
  readonly sizes = LogoSize
}
