import { LocalTimeRange } from '@ftr/contracts/type/shared/LocalTimeRange'
import { DefaultTimeStringParseMethod, TimeStringParser } from '@ftr/foundation'
import { LocalTime } from '@js-joda/core'
import { isUUID } from 'class-validator'
import { SttMarkerType } from '../lib/features/shared'
import { HearingAnnotationTimeValidationReason, SessionTimeValidationReason } from '../utils'
import { TimeValidation } from '../utils/time-validation-utils'

export enum RealTimeSttMarkerEditSource {
  StartMarker = 'start-marker',
  EndMarker = 'end-marker',
  Header = 'header',
}

/**
 * When we update time on a session/sealing marker, we save the `future marker id` (it will change),
 * because we need to refresh the recording each time after an update.
 * After the refresh, we set this particular marker's status to `Saved` so that
 * the update is visually smoother
 */
export interface RealTimeSttSavedMarker {
  id: string
  source?: RealTimeSttMarkerEditSource
}

type RealTimeSttMarkerTimeValidationReason = SessionTimeValidationReason | HearingAnnotationTimeValidationReason

export function parseStartEndTimes<T extends RealTimeSttMarkerTimeValidationReason>(rawTimes: {
  startTime: string
  endTime: string
}): { parseError: TimeValidation<T> } | { startTime: LocalTime; endTime: LocalTime } {
  let startTime: LocalTime | null = null
  let endTime: LocalTime | null = null

  try {
    startTime = TimeStringParser.parse(rawTimes.startTime.trim(), [DefaultTimeStringParseMethod])
  } catch {
    // parsing failed :/
  }
  try {
    endTime = TimeStringParser.parse(rawTimes.endTime.trim(), [DefaultTimeStringParseMethod])
  } catch (e) {
    // parsing failed :/
  }

  if (!startTime && !endTime) {
    return { parseError: { reason: 'both-are-not-times' } as TimeValidation<T> }
  }
  if (!startTime) {
    return { parseError: { reason: 'start-is-not-a-time' } as TimeValidation<T> }
  }
  if (!endTime) {
    return { parseError: { reason: 'end-is-not-a-time' } as TimeValidation<T> }
  }
  return { startTime, endTime }
}

export function isOngoing(liveLocalTime: LocalTime | undefined, timeRange: LocalTimeRange): boolean {
  if (!liveLocalTime) {
    return false
  }

  return timeRange.contains(liveLocalTime)
}

export function getRealTimeSttMarkerTypeById(id: string): SttMarkerType | undefined {
  if (isUUID(id)) return SttMarkerType.Hearing
  if (id.includes(SttMarkerType.Sealing)) return SttMarkerType.Sealing
  if (id.includes(SttMarkerType.Session)) return SttMarkerType.Session
  return undefined
}
