import { Inject, Injectable } from '@angular/core'
import { DigitalJusticeApiClientFactory, DigitalJusticeApiClientSetupService } from '@ftr/api-core'
import { API_CONFIGURATION, FullApiClientConfiguration } from '@ftr/api-shared'
import { DJ_COURT_SYSTEM_ID, DJ_KEY } from '@ftr/contracts/digital-justice'
import { Uuid } from '@ftr/contracts/type/shared'
import {
  ApiResult,
  LocalStorageService,
  RemoteData,
  SimpleWindowRefService,
  tapData,
  unwrapData,
} from '@ftr/foundation'
import { catchError, map, switchMap } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class DigitalJusticeAuthService extends DigitalJusticeApiClientSetupService {
  constructor(
    digitalJusticeApiClientFactory: DigitalJusticeApiClientFactory,
    private readonly windowRefService: SimpleWindowRefService,
    private readonly localStorageService: LocalStorageService,
    @Inject(API_CONFIGURATION) readonly configurationService: FullApiClientConfiguration,
  ) {
    super(digitalJusticeApiClientFactory)
  }

  handlePostKey(courtSystemId: Uuid, authHash: string): ApiResult<string | undefined> {
    return this.postKey(courtSystemId, authHash).pipe(
      tapData(region => {
        this.localStorageService.removeGlobal(DJ_KEY)
        this.localStorageService.removeGlobal(DJ_COURT_SYSTEM_ID)
        const djUrl = this.configurationService.digitalJusticeWeb.endpointMap[region!]
        this.windowRefService.location().assign(`${djUrl}/auth/callback`)
      }),
      catchError(e => {
        console.error('Failed to post auth to dj api', e)
        return ApiResult.success(undefined)
      }),
    )
  }

  private postKey(courtSystemId: Uuid, authHash: string): ApiResult<string | undefined> {
    return this.digitalJusticeApiClient('auth-key', courtSystemId).pipe(
      switchMap(apiClient =>
        apiClient
          .post({
            headers: { [DJ_KEY]: authHash },
          })
          .pipe(
            unwrapData(),
            map(() => RemoteData.success(apiClient.clientConfig.region)),
          ),
      ),
    )
  }
}
