import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { RootParams } from '@ftr/api-shared'

@Component({
  template: '<ftr-mfa-challenge [returnUrl]="returnUrl"></ftr-mfa-challenge>',
})
export class MfaChallengePage implements OnInit {
  returnUrl: string

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams[RootParams.ReturnUrl]
  }
}
