import { TranscriptOrderStatus } from '@ftr/contracts/read'
import { assertUnreachable } from '@ftr/contracts/shared/assertUnreachable'
import { OrderStatus } from '@ftr/contracts/type/order'
import { JobStatus } from '@ftr/contracts/type/transcript'

/**
 * Reconciles OrderStatus and JobStatus into a single OrderStatus.
 *
 * Why? Because on our current backend an Order and Job have similar statuses that transition
 * asynchronously to one another. Everything is eventually consistent, but until it is the UI
 * has the potential to be left in a weird state if it tries to only display OrderStatus or only
 * display JobStatus.
 *
 * An example is canceling an Order. When an order gets canceled its status is set to `canceled`,
 * and an asynchronous command is scheduled to also update its Job to `canceled`, however since the completion
 * of the async call is not deterministic, we can't always rely on the Job's status to be the most up-to-date.
 *
 * Similarly, an Order's status cannot be trusted to be the most up-to-date record either, because when a Job is
 * assigned, its Order will eventually transition to `in progress`, but when this happens is also asynchronous and
 * non-deterministic.
 *
 * This ugly solution takes care of the scenarios described above where an Order and Job may not have statuses
 * that are in sync with each other. Hopefully with updates to the backend this will be able to be deprecated soon.
 */
export class OrderStatusNormalizer {
  /**
   * Returns a normalised OrderStatus from a given OrderStatus and JobStatus.
   *
   * @param {OrderStatus} orderStatus
   * @param {JobStatus} jobStatus
   * @returns {OrderStatus}
   */
  normalize(orderStatus: OrderStatus, jobStatus: JobStatus): TranscriptOrderStatus {
    if (orderStatus === OrderStatus.InProgress) {
      switch (jobStatus) {
        case JobStatus.Pending:
          return TranscriptOrderStatus.AwaitingAssignment
        case JobStatus.Assigned:
          return TranscriptOrderStatus.InProgress
        case JobStatus.InReview:
          return TranscriptOrderStatus.AwaitingTranscriptConfirmation
        case JobStatus.Completed:
          // this is only possible in the time between fulfilling the order and the order status being updated via bus
          return TranscriptOrderStatus.Completed
        case JobStatus.Canceled:
          // this shouldn't be possible
          return TranscriptOrderStatus.Error
        default:
          assertUnreachable(jobStatus)
      }
    }

    return orderStatus as {} as TranscriptOrderStatus
  }
}
