import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { PanelComponent } from '../panel'

export enum PageStyle {
  Default = 'default',
  Fluid = 'fluid',
  Flat = 'flat',
  Narrow = 'narrow',
}

export const PAGE_CONTAINER_CLASS = 'page__container'

export type PageClasses = Record<string, boolean>

@Component({
  standalone: true,
  selector: 'ftr-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css'],
  imports: [CommonModule, PanelComponent],
})
export class PageComponent {
  /**
   * Adds the design system classes that set the horizontal auto margin. Can be used with
   * @horizontalOuterMargin for pages that have sections that use the entire width of the viewport.
   * Other sections of the page may need margins, which can be set outside of this component.
   */
  @Input() horizontalAutoMargin = true
  /**
   * Adds the design system classes that set the page's outer margin.
   */
  @Input() horizontalOuterMargin = true

  /**
   * Skips the 20px margin on the top of the page.
   */
  @Input() noMargin = false

  @Input() pageStyle = PageStyle.Default

  readonly pageContainerClass = PAGE_CONTAINER_CLASS
  readonly defaultPage = PageStyle.Default
  readonly narrowPage = PageStyle.Narrow
}
