import { CommonModule } from '@angular/common'
import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core'
import { CookieAuthenticationService } from '../../services'

/**
 * Use when you need to display a link or button to download an external file via a non-XHR request, where a cookie
 * must first be set using <code>CookieAuthenticationService</code>.
 */
@Component({
  selector: 'ftr-auth-download-link',
  templateUrl: './auth-download-link.component.html',
  standalone: true,
  imports: [CommonModule],
})
export class AuthDownloadLinkComponent implements OnInit {
  @Input() linkUrl: string

  @ContentChild('loaded') loadedTemplate: TemplateRef<unknown>

  readyToDownload = false

  constructor(private readonly cookieAuthenticationService: CookieAuthenticationService) {}

  ngOnInit(): void {
    this.cookieAuthenticationService.validCookie.subscribe(auth => (this.readyToDownload = auth))
  }
}
