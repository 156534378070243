import { Component, Input, ViewEncapsulation } from '@angular/core'
import { DatePipe } from '../../pipes'
import { DateFormat, MomentConvertible, toMoment } from '../../util'

@Component({
  standalone: true,
  selector: 'ftr-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.css'],
  encapsulation: ViewEncapsulation.None,
  imports: [DatePipe],
})
/**
 * Displays a date in a given format, optionally showing a tooltip which when hovered
 * shows an extended date format.
 *
 * @see DatePipe
 *
 * Note: the tooltip on this component is displayed by using an HTML title="" attribute. This means that
 * it does not work no mobile (since there is no hover on mobile) and is hidden on some browsers
 * when the date text is clicked. If we find that the tooltip has been useful for people, I'd recommend that
 * we look at using a JS/CSS method of displaying the tooltip, which would work the same for all our users.
 */
export class DateComponent {
  /**
   * The date to display
   */
  @Input() date: MomentConvertible | undefined | null
  /**
   * The format to display the date in
   */
  @Input() format: DateFormat
  /**
   * The format to display the date in, inside the tooltip.
   * If undefined, no tooltip is shown.
   */
  @Input() tooltipFormat: DateFormat | undefined
  /**
   * A custom function for converting the given date to a moment.
   */
  @Input() toMoment = toMoment
  /**
   * The value to display if the date is falsy or can't be converted (defaults to empty string)
   */
  @Input() defaultValue = ''
}
