import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { CourtSystem } from '@ftr/contracts/api/court-system'
import { Uuid } from '@ftr/contracts/type/shared'
import { ApiResult, RemoteData, tapFailure, unwrapData } from '@ftr/foundation'
import { Action, Selector, State, StateContext, createSelector } from '@ngxs/store'
import { memoize } from 'lodash-es'
import { EMPTY, Observable, catchError, of, tap } from 'rxjs'
import { ConfigurePersonalizationService, GetCourtSystemService, PersonalizationConfiguration } from '../services'
import { FetchCourtSystemAction, FetchCourtSystemPersonalizationAction } from './court-systems.actions'
import { CourtSystemsStateModel } from './court-systems.model'

export function defaultCourtSystemState(): CourtSystemsStateModel {
  return {}
}

/**
 * Intention:
 * To prevent duplicate requests and reduce boilerplate in components.
 * Currently we access courtSystemId and pass it to components, then call courtSystemService.get(id).
 * CourtSystemBreadcrumbResolver has already done the work to fetch the court system before the page has loaded,
 * so there is no reason to request it again in the component.
 * CourtSystemService could implement its own caching, but that doesn't reduce the boilerplate.
 *
 * This could become a BreadcrumbEntityState if we want, where we could store things like locations or user group names,
 * and getEntityById(UserGroup, id), updating the state model to be [UserGroup.name]: {[id]: UserGroup} for example.
 *
 * Suggestion:
 * Dispatch an action in the breadcrumb resolver to store the response, and access that in the Page component
 * e.g. see CourtSystemPage
 * NOTE: This does mean that you will only be able to use the courtSystemById selector,
 * if you are using it on a page that the court system breadcrumb resolver is nested under.
 */
@State<CourtSystemsStateModel>({
  name: 'courtSystemsState',
  defaults: defaultCourtSystemState(),
})
@Injectable()
export class CourtSystemsState {
  constructor(
    private readonly getCourtSystemService: GetCourtSystemService,
    private readonly personalizationService: ConfigurePersonalizationService,
    private readonly router: Router,
  ) {}

  @Selector()
  static state(state: CourtSystemsStateModel): CourtSystemsStateModel {
    return state
  }

  /**
   * Relies on CourtSystemBreadcrumbResolver being defined in a parent route, to the component
   * at the route calling this selector.
   * @param courtSystemId
   */
  static courtSystemById = memoize(
    (courtSystemId: Uuid): ((state: CourtSystemsStateModel) => CourtSystem | undefined) => {
      return createSelector([CourtSystemsState], (state: CourtSystemsStateModel) => state[courtSystemId]?.details)
    },
  )

  /**
   * Relies on CourtSystemBreadcrumbResolver being defined in a parent route, to the component
   * at the route calling this selector.
   * @param state
   */
  @Selector()
  static courtSystemPersonalization(
    state: CourtSystemsStateModel,
  ): (courtSystemId: Uuid) => RemoteData<PersonalizationConfiguration | undefined> {
    return (courtSystemId: Uuid): RemoteData<PersonalizationConfiguration | undefined> => {
      return state[courtSystemId]?.personalization ?? RemoteData.notAsked()
    }
  }

  @Action(FetchCourtSystemAction, { cancelUncompleted: true })
  fetchCourtSystem(
    { getState, patchState }: StateContext<CourtSystemsStateModel>,
    { courtSystemId, bypassCache }: FetchCourtSystemAction,
  ): Observable<CourtSystem> {
    const courtSystemById = getState()[courtSystemId]?.details
    if (courtSystemById && !bypassCache) {
      return of(courtSystemById)
    }
    // We prevent the breadcrumb resolver from returning while the request is in RemoteData.Loading
    return this.getCourtSystemService.getCourtSystem(courtSystemId).pipe(
      tapFailure(_ => this.router.navigate(['/'])),
      unwrapData(),
      catchError(_ => EMPTY),
      tap(courtSystem => {
        patchState({
          [courtSystem.id]: {
            details: courtSystem,
            personalization: getState()[courtSystem.id]?.personalization || RemoteData.notAsked(),
          },
        })
      }),
    )
  }

  @Action(FetchCourtSystemPersonalizationAction)
  fetchCourtSystemPersonalization(
    { getState, patchState }: StateContext<CourtSystemsStateModel>,
    { courtSystemId, bypassCache }: FetchCourtSystemPersonalizationAction,
  ): ApiResult<PersonalizationConfiguration | undefined> {
    const personalizationData = getState()[courtSystemId]?.personalization
    if (personalizationData && (personalizationData.isSuccess() || personalizationData.isLoading()) && !bypassCache) {
      return of(personalizationData)
    }

    patchState({
      [courtSystemId]: {
        details: getState()[courtSystemId]?.details,
        personalization: RemoteData.loading(),
      },
    })

    return this.personalizationService.getPersonalizationConfiguration(courtSystemId).pipe(
      tap(rd =>
        patchState({
          [courtSystemId]: {
            details: getState()[courtSystemId]?.details,
            personalization: rd,
          },
        }),
      ),
    )
  }
}
