import {
  AbstractControl,
  FormArray,
  FormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms'

/**
 * @param formGroup The group of controls to compare the validated field's value to.
 * @param message Custom error message, supports placeholder of {index}, where index starts from 1.
 */
export function isUnique(formGroup: UntypedFormGroup, message?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null
    }

    const allControls = Object.keys(formGroup.controls).map(k => formGroup.get(k)!)
    const matchingControlIndex = allControls.findIndex(
      otherControl => otherControl !== control && otherControl.value === control.value,
    )

    if (matchingControlIndex !== -1) {
      return isUniqueValidatorError(matchingControlIndex, message)
    }

    return null
  }
}

/**
 * @param formArray The group of controls to compare the validated field's value to.
 * @param message Custom error message, supports placeholder of {index}, where index starts from 1.
 */
export function isUniqueArray<T>(formArray: FormArray<FormControl<T>>, message?: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null
    }

    const allControls: FormControl<T>[] = formArray.controls
    const matchingControlIndex = allControls.findIndex(
      otherControl => otherControl !== control && otherControl.value === control.value,
    )

    if (matchingControlIndex !== -1) {
      return isUniqueValidatorError(matchingControlIndex, message)
    }

    return null
  }
}

function isUniqueValidatorError(index: number, message?: string): ValidationErrors {
  if (message) {
    return {
      isUnique: message.replace('{index}', (index + 1).toString(10)),
    }
  } else {
    return { error: true }
  }
}
