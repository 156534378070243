import { Injectable } from '@angular/core'
import { RegionalApiClientFactory, RegionalApiClientSetupService } from '@ftr/api-regional'
import { ApiClient } from '@ftr/api-shared'
import { HearingPlaybackData, HearingWithStt } from '@ftr/contracts/regional-api/hearing'
import { addSpeakerToUtterances } from '@ftr/contracts/regional-api/stt'
import { Uuid } from '@ftr/contracts/type/shared'
import { ApiResult, mapData } from '@ftr/foundation'
import { switchMap } from 'rxjs'
import { RealTimeHearingWithSttRemarks } from '../../types'
import { utterancesToRemarks } from '../../utils'

@Injectable({
  providedIn: 'root',
})
export class RegionalHearingsService extends RegionalApiClientSetupService {
  constructor(regionalApiClientFactory: RegionalApiClientFactory) {
    super(regionalApiClientFactory)
  }

  override regionalApiPath = 'hearing'

  getHearingWithSttRemarks(courtSystemId: Uuid, hearingId: Uuid): ApiResult<RealTimeHearingWithSttRemarks> {
    return this.regionalApiClient(this.regionalApiPath, courtSystemId).pipe(
      switchMap(apiClient => {
        return this.getHearingWithStt(apiClient, courtSystemId, hearingId).pipe(
          mapData(({ id, utterances, speakers }) => {
            return {
              id,
              remarks: utterancesToRemarks(addSpeakerToUtterances(utterances, speakers)),
            }
          }),
        )
      }),
    )
  }

  getHearingPlayback(courtSystemId: Uuid, hearingId: Uuid): ApiResult<HearingPlaybackData> {
    return this.regionalApiClient(this.regionalApiPath, courtSystemId).pipe(
      switchMap(apiClient =>
        apiClient.get<HearingPlaybackData>({
          path: `${hearingId}/playback`,
          headers: { courtSystemId },
          responseBodyType: HearingPlaybackData,
        }),
      ),
    )
  }

  private getHearingWithStt(apiClient: ApiClient, courtSystemId: Uuid, hearingId: Uuid): ApiResult<HearingWithStt> {
    return apiClient.get({
      path: `${hearingId}/stt`,
      headers: { courtSystemId },
    })
  }
}
