import { Params, ResolveFn } from '@angular/router'
import { Breadcrumb } from './breadcrumb'

/**
 *
 * Supporting type for a dynamic data resolver for a breadcrumb.
 *
 * Implement this type on a subclass, return the desired breadcrumb label and associate the subclass with a route data
 * resolver.
 *
 * See BreadcrumbsComponent for a full example.
 *
 */
export interface BreadcrumbResolver {
  resolve: ResolveFn<string | undefined>
}
export interface BreadcrumbObjectResolver {
  resolve: ResolveFn<Breadcrumb | undefined>
}

export function buildBreadcrumb(label: string, url: string, queryParams?: Params, parent?: Breadcrumb): Breadcrumb {
  return {
    label,
    link: { url, queryParams: queryParams || {} },
    customParent: parent,
  }
}
