// eslint-disable-next-line max-classes-per-file
import { Case } from '@ftr/annotations-contracts'
import { Uuid } from '@ftr/contracts/type/shared'

export class CaseUpdatedEvent {
  static readonly type = '[Cases] Case Update'

  constructor(
    readonly caseId: Uuid,
    readonly originalCase: Case,
    readonly updatedCase: Case,
  ) {}
}

export class CaseUpdateFailedEvent {
  static readonly type = '[Cases] Case Update Failed'

  constructor(
    readonly caseId: Uuid,
    readonly error: 'conflict' | 'unknown',
  ) {}
}

export class CaseDeletedEvent {
  static readonly type = '[Cases] Case Deleted'

  constructor(readonly caseId: Uuid) {}
}

export class CaseDeletionFailedEvent {
  static readonly type = '[Cases] Case Deletion Failed'

  constructor(readonly caseId: Uuid) {}
}
