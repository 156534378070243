import { Inject } from '@angular/core'
import { initialize, LDClient } from 'launchdarkly-js-client-sdk'
import { LAUNCH_DARKLY_CONFIG, LaunchDarklyConfig } from './launch-darkly-config'

/*
It's important that the LDClient be a singleton. We ensure this
by injecting the factory at the 'root' level, which makes the _factory_
a singleton. The factory then encapsulates a single instance of an LDClient, only
instantiating it the first time it is requested.
*/
export class LaunchDarklySdkClientFactory {
  private ldClient: LDClient | undefined

  constructor(@Inject(LAUNCH_DARKLY_CONFIG) private config: LaunchDarklyConfig) {
    this.ldClient = undefined
  }

  getClient(): LDClient {
    if (this.ldClient) return this.ldClient

    this.ldClient = initialize(
      this.config.launchDarklyClientId,
      {
        key: 'anonymous',
      },
      {
        evaluationReasons: true,
        // We don't want insights when we get all flags with identify - only the flags the client uses
        sendEventsOnlyForVariation: true,
      },
    )

    return this.ldClient
  }
}
