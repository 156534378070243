<ftr-page>
  <article class="not-found">
    <div class="card card--borderless">
      <div class="not-found__thumb">
        <ftr-illustration [illustration]="illustration.FourOhFour"></ftr-illustration>
      </div>
      <p class="not-found__message1">Sorry, the page you are looking for does not exist or is no longer available.</p>
      <p class="not-found__message2">Perhaps you were looking for one of the following:</p>
      <ul class="not-found__menu">
        <li class="not-found__menu__item"><a routerLink="/">Home</a></li>
        <li class="not-found__menu__item"><a routerLink="/uploads">My Uploads</a></li>
        <li class="not-found__menu__item"><a routerLink="/orders">My Orders</a></li>
        <li class="not-found__menu__item"><a routerLink="/account">Account Details</a></li>
      </ul>
    </div>
  </article>
</ftr-page>
