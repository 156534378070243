@if (pills) {
  <div class="pill-set">
    <h2 class="pill-set__title">{{ title }}</h2>
    @if (pills.length > 0) {
      <a class="pill-set__clear-all" href="javascript:void(0)" (click)="clearAllPills()"> Clear all </a>
    }
    <section class="pill-set__pills">
      <ng-template #closeIcon>
        <ftr-icon tabindex="1" icon="Close" align="Middle"></ftr-icon>
      </ng-template>
      <div class="row">
        @for (pill of pills.slice(0, countToShow); track pill) {
          <div class="col-6 col-md-4 pill-set__pills--pill">
            <span> {{ pill.tooltip }}</span>
            <ftr-pill [iconAfter]="closeIcon" (iconAfterClick)="removePill(pill.id)">
              {{ pill.label }}
            </ftr-pill>
          </div>
        }
      </div>
    </section>
    @if (shouldShowMorePillsButton()) {
      <a class="pill-set__select-all" href="javascript:void(0)" (click)="showAllPills()">
        Show more selected {{ pluralNoun | lowercase }}
      </a>
    }
  </div>
}
