import { Component, Input, ViewEncapsulation } from '@angular/core'
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { SavedCard } from '@ftr/contracts/read/order-account'
import { resetControlAndValidators, setControlValidators } from '@ftr/forms'
import { Card, StripeService } from '@ftr/ui-money'
import { Observable } from 'rxjs'
import { OrderReviewFieldNames } from '~app/features/order-form-shared/steps/order-review/order-review.component'

/**
 * This payment data is derived from the input fields and provides enough information to make a payment, i.e.:
 * either a cardId (to use a saved card) or a paymentToken (to use a newly entered card).
 */
export interface PaymentData {
  cardId: string | undefined
  paymentToken: string | undefined
  paymentTokenError?: string
  saveCreditOrDebitCard: boolean
}

@Component({
  selector: 'ftr-payment-fields',
  templateUrl: './payment-fields.component.html',
  styleUrls: ['./payment-fields.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class PaymentFieldsComponent {
  @Input() formGroup: UntypedFormGroup
  @Input() highlightErrors: Observable<boolean>

  /**
   * A public api key used with Stripe to create charges
   */
  @Input() paymentApiKey: string

  /**
   * Cards saved against an account
   */
  @Input() savedCards: SavedCard[]

  constructor(private readonly stripeService: StripeService) {}

  /**
   * Handle events emitted from saved cards to update the form's validity.
   */
  useSavedCard(value: SavedCard | undefined): void {
    if (value) {
      this.formGroup.controls[OrderReviewFieldNames.PaymentSavedCardId].setValue(value)
      this.formGroup.controls[OrderReviewFieldNames.PaymentSavedCardId].markAsTouched({
        onlySelf: true,
      })
      setControlValidators(this.formGroup.controls[OrderReviewFieldNames.PaymentSavedCardId])
      resetControlAndValidators(this.formGroup.controls[OrderReviewFieldNames.PaymentNewCard])
    } else {
      setControlValidators(this.formGroup.controls[OrderReviewFieldNames.PaymentNewCard])
      resetControlAndValidators(this.formGroup.controls[OrderReviewFieldNames.PaymentSavedCardId])
    }
  }

  // Should only show save this card when there is a card id field, but no value in it.
  showSaveCreditOrDebitCard(): boolean {
    return (
      !!this.formGroup.controls[OrderReviewFieldNames.PaymentSavedCardId] &&
      !this.formGroup.controls[OrderReviewFieldNames.PaymentSavedCardId].value
    )
  }

  /**
   * Once form is valid, call this to derive the data required to make a payment
   */
  async getData(): Promise<PaymentData> {
    const cardIdControl = this.formGroup.controls[OrderReviewFieldNames.PaymentSavedCardId]
    const newCardControl = this.formGroup.controls[OrderReviewFieldNames.PaymentNewCard]
    const cardId = (cardIdControl.value && cardIdControl.value.cardId) || undefined
    const saveCreditOrDebitCard = !!this.formGroup.get(OrderReviewFieldNames.PaymentSaveCard)!.value

    const newCardControlValue: Card = newCardControl.value

    const paymentData: PaymentData = {
      saveCreditOrDebitCard,
      cardId,
      paymentToken: undefined,
    }

    try {
      paymentData.paymentToken = (newCardControlValue && (await this.getPaymentToken(newCardControlValue))) || undefined
    } catch (error) {
      const tokenError = 'An error occurred validating your card. Please check the card details and try again.'
      paymentData.paymentTokenError = error.type === 'card_error' ? error.message : tokenError
    }

    return paymentData
  }

  private async getPaymentToken(newCardControlValue: Card): Promise<string> {
    const stripeToken = await this.stripeService.createToken(newCardControlValue.stripe, newCardControlValue.element)
    return stripeToken.id
  }

  asFormControl(control: AbstractControl): UntypedFormControl {
    return control as UntypedFormControl
  }
}
