import { NgTemplateOutlet } from '@angular/common'
import { Component, computed, OnInit, signal, TemplateRef, viewChild, ViewContainerRef } from '@angular/core'
import { takeUntil } from 'rxjs'
import { MarkdownDirective } from '../../directives'
import { DestroySubscribers } from '../../util'
import { ModalComponent, ModalService } from '../modal'
import {
  ConfirmationModalDefinitionWithCallbacks,
  ConfirmationModalService,
  DEFAULT_CONFIRMATION_MODAL_DEFINITION,
} from './confirmation-modal.service'

/**
 * This component is responsible for rendering the confirmation modal. It should be treated as a singleton and be placed
 * at the root of the application. It listens for changes to the modal definition managed by the `ConfirmationModalService`
 * and renders the modal accordingly.
 *
 * **Important:** This component should not be used directly in templates. Instead, use the `ConfirmationModalService` to
 * open the modal.
 */
@Component({
  standalone: true,
  selector: 'ftr-confirmation-modal',
  imports: [ModalComponent, NgTemplateOutlet, MarkdownDirective],
  templateUrl: './confirmation-modal.component.html',
})
export class ConfirmationModalComponent extends DestroySubscribers implements OnInit {
  protected modalDefinition = signal<ConfirmationModalDefinitionWithCallbacks<unknown>>(
    DEFAULT_CONFIRMATION_MODAL_DEFINITION,
  )

  protected modalTextContent = computed(() => {
    const content = this.modalDefinition().content
    return typeof content === 'string' ? content : undefined
  })

  private modalPortalRef = viewChild.required('modalPortal', { read: TemplateRef })
  private modalContentsVcr = viewChild.required('modalContents', { read: ViewContainerRef })

  constructor(
    private readonly modalService: ModalService,
    private readonly confirmationModalService: ConfirmationModalService,
    private readonly viewContainerRef: ViewContainerRef,
  ) {
    super()
  }

  ngOnInit(): void {
    this.confirmationModalService
      .observeModalDefinition()
      .pipe(takeUntil(this.finalize))
      .subscribe(modalDef => {
        // First cancel any existing modals
        this.modalDefinition().onCancel()

        // Now set up the new one
        this.modalDefinition.set(modalDef)

        this.modalService.open(this.modalPortalRef(), this.viewContainerRef)

        // dynamically create the content component
        if (typeof modalDef.content !== 'string') {
          const contentComponent = this.modalContentsVcr().createComponent(modalDef.content.type)
          Object.entries(modalDef.content.inputs || {}).forEach(([key, value]) => {
            contentComponent.setInput(key as keyof typeof modalDef.content.inputs, value)
          })
        }
      })

    this.finalize.subscribe(() => this.modalService.close())
  }

  onAction(): void {
    this.modalDefinition().onConfirm()
    this.modalService.close()
    this.modalDefinition.set(DEFAULT_CONFIRMATION_MODAL_DEFINITION)
  }

  onClose(): void {
    this.modalDefinition().onCancel()
    this.modalDefinition.set(DEFAULT_CONFIRMATION_MODAL_DEFINITION)
  }
}
