// See https://www.typescriptlang.org/docs/handbook/triple-slash-directives.html
/// <reference types="grecaptcha" />

import { Injectable } from '@angular/core'
import { ReplaySubject } from 'rxjs'
import { ConfigurationService } from '~app/services/configuration/configuration.service'
import { RECAPTCHA_CALLBACK_NAME } from '~app/services/recaptcha/recaptcha.consts'
import { WindowRefService } from '~app/services/window/window-ref.service'

const RECAPTCHA_ELEMENT_ID = 'recaptchav2'
const RECAPTCHA_URL = `https://www.google.com/recaptcha/api.js?onload=${RECAPTCHA_CALLBACK_NAME}&render=explicit`

/**
 * Implements reCAPTCHA v2 checkbox.
 * @see https://developers.google.com/recaptcha/docs/display
 * Our keys are owned by devops@fortherecord.com here
 * @see https://www.google.com/recaptcha/admin/site/457741093
 */
@Injectable({ providedIn: 'root' })
export class RecaptchaService {
  /**
   * Emits when the recaptcha dependencies have been loaded.
   */
  readonly recaptchaLoaded$ = new ReplaySubject<void>(1)
  /**
   * The grecaptcha global.
   */
  private recaptcha: ReCaptchaV2.ReCaptcha | undefined

  constructor(
    private readonly windowRefService: WindowRefService,
    private readonly configurationService: ConfigurationService,
  ) {}

  /**
   * Gets the response token for the widget with the given ID.
   * The response token is blank if the recaptcha has not been completed.
   */
  getResponse(widgetId: number): string {
    return this.recaptcha?.getResponse(widgetId) || ''
  }

  /**
   * Attaches the onload callback and loads the script.
   */
  loadRecaptchaScript(): void {
    if (!this.windowRefService.nativeWindow()[RECAPTCHA_CALLBACK_NAME]) {
      this.windowRefService.nativeWindow()[RECAPTCHA_CALLBACK_NAME] = () => {
        this.recaptcha = grecaptcha
        this.recaptchaLoaded$.next()
      }
    }

    if (!this.windowRefService.document().getElementById(RECAPTCHA_ELEMENT_ID)) {
      const scriptElement = this.windowRefService.document().createElement('script')
      scriptElement.src = RECAPTCHA_URL
      scriptElement.id = RECAPTCHA_ELEMENT_ID
      document.body.appendChild(scriptElement)
    }
  }

  /**
   * Renders the widget using the element with the given ID and returns a widget ID to interact with this widget.
   */
  render(elementId: string): number {
    if (!this.recaptcha) {
      throw new Error('reCAPTCHA not loaded')
    }

    return this.recaptcha.render(elementId, { sitekey: this.configurationService.recaptchaPublicKey })
  }

  /**
   * Resets the widget with the given ID and removes it from the DOM.
   *
   * NOTE: There is a nasty bug with the reCAPTCHA script which results in an unhandled promise when calling .reset(),
   * however calling .reset() is preferable to leaving widgets lying around.
   * @see https://github.com/google/recaptcha/issues/269
   * @see https://github.com/DethAriel/ng-recaptcha/issues/123
   */
  reset(widgetId: number): void {
    this.recaptcha?.reset(widgetId)
  }
}
