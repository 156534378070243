import { Directive, ElementRef, EventEmitter, Inject, Input, OnInit } from '@angular/core'

@Directive({
  selector: '[ftrFocus]',
  standalone: true,
})
export class FocusDirective implements OnInit {
  @Input('ftrFocus') focusEvent: EventEmitter<boolean>

  constructor(@Inject(ElementRef) private element: ElementRef) {}

  ngOnInit(): void {
    this.focusEvent?.subscribe(() => {
      this.element.nativeElement.focus()
    })
  }
}
