<h2 class="help__heading">For The Record Support</h2>

<p class="help__description">
  If you are having trouble with logging in, uploading and playing TRMs, or any other issues with For The Record, please
  contact
  <a
    class="help__email"
    data-track
    data-track-group="Support"
    href="mailto:support@fortherecord.com?subject=Support request from app.fortherecord.com{{
      isLoggedIn ? ' (User ID: ' + (currentUser$ | async)?.data?.id + ')' : ''
    }}"
  >
    support&#64;fortherecord.com</a
  >
</p>
