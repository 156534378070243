import { ModuleWithProviders, NgModule } from '@angular/core'
import {
  ApplyClassIfFeatureFlagDisabledDirective,
  ApplyClassIfFeatureFlagEnabledDirective,
  LaunchDarklyFeatureFlagDisabledDirective,
  LaunchDarklyFeatureFlagEnabledDirective,
} from './directives'
import { LaunchDarklySdkClientFactory } from './launch-darkly-client-factory'
import { LAUNCH_DARKLY_CONFIG } from './launch-darkly-config'
import { LaunchDarklyService } from './services'

@NgModule({
  declarations: [
    LaunchDarklyFeatureFlagEnabledDirective,
    LaunchDarklyFeatureFlagDisabledDirective,
    ApplyClassIfFeatureFlagEnabledDirective,
    ApplyClassIfFeatureFlagDisabledDirective,
  ],
  providers: [LaunchDarklyService],
  exports: [
    LaunchDarklyFeatureFlagEnabledDirective,
    LaunchDarklyFeatureFlagDisabledDirective,
    ApplyClassIfFeatureFlagEnabledDirective,
    ApplyClassIfFeatureFlagDisabledDirective,
  ],
})
export class LaunchDarklyModule {
  static forRoot(): ModuleWithProviders<LaunchDarklyModule> {
    return {
      ngModule: LaunchDarklyModule,
      providers: [
        {
          provide: LaunchDarklySdkClientFactory,
          useClass: LaunchDarklySdkClientFactory,
          deps: [LAUNCH_DARKLY_CONFIG],
        },
      ],
    }
  }
}
