import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MarkdownDirective } from '../../directives'
import { IconComponent } from '../../lib/icon'
import { NotificationFlashComponent } from './notification-flash.component'
import { NotificationComponent } from './notification.component'

/**
 * This module contains reusable notification components
 */
@NgModule({
  imports: [CommonModule, IconComponent, MarkdownDirective, NotificationComponent, NotificationFlashComponent],
  declarations: [],
  exports: [NotificationComponent, NotificationFlashComponent],
})
export class NotificationModule {}
