import { OverlayModule } from '@angular/cdk/overlay'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { TooltipComponent } from './tooltip.component'
import { TooltipDirective } from './tooltip.directive'
import { TooltipUnpaddedComponent, TooltipUnpaddedDirective } from './unpadded'

@NgModule({
  imports: [CommonModule, OverlayModule],
  declarations: [TooltipComponent, TooltipUnpaddedComponent, TooltipDirective, TooltipUnpaddedDirective],
  exports: [TooltipDirective, TooltipUnpaddedDirective],
})
export class TooltipModule {}
