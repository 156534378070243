import { Inject, Injectable } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import {
  AUTH_CONFIGURATION,
  AuthConfiguration,
  AuthenticationServerValidationError,
  MissingCognitoUserError,
  PasswordResetRequired,
  SoftwareMfaRequired,
  TOKEN_EXPIRY_THRESHOLD_SECONDS,
  TokenStatus,
  isCognitoValidationError,
} from '@ftr/api-shared'
import { AppPaths } from '@ftr/routing-paths'
import { LoggingService } from '@ftr/ui-observability'
import { AuthenticationDetails, ClientMetadata, CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js'
import { msToSeconds } from '../oauth'
import { CognitoUserFactory } from './cognito-user-factory'

// Standard login errors when the users emails or password is incorrect
const LOGIN_FAILURES = ['NotAuthorizedException', 'UserNotFoundException']
const PASSWORD_RESET_REQUIRED = 'PasswordResetRequiredException'

// Errors for when has inputed a invalid code or the code is expired/used

const MFA_GENERIC_FAILURE_MESSAGE = 'Invalid or expired code, please try again.'

const MFA_EXPIRED_CODE_EXCEPTION = 'ExpiredCodeException'
const MFA_EXPIRED_CODE_MESSAGE = 'Your code has already been used once.'

const MFA_EXPIRED_SESSION_EXCEPTION = 'NotAuthorizedException'
const MFA_EXPIRED_SESSION_MESSAGE = 'Invalid session for the user, session is expired.'

const AUTH_THROTTLE_MESSAGE = 'Password attempts exceeded'

export const PASSWORD_RESET_REQUIRED_MESSAGE = 'Login failed. You are required to reset your password.'

export const INVALID_LOGIN_DETAILS_MESSAGE = 'Invalid email or password'
export const GENERIC_LOGIN_FAILURE_MESSAGE =
  'Login failed. Please reload the page and retry, or contact support@fortherecord.com.'
const CHANGE_PASSWORD_FAILURE_MESSAGE =
  'Failed to change password. Please retry in a few minutes, try reloading the page, or contact support@fortherecord.com.'
const UNKNOWN_FAILURE = 'Unknown failure. Please try reloading the page, or contact support@fortherecord.com.'
export const COMPLETED_PASSWORD_CHANGE_MESSAGE = 'Completed password change'
export const LOGIN_ATTEMPT_THROTTLED_MESSAGE =
  'Too many failed password attempts. Please wait a few minutes before attempting to log in again.'

@Injectable({
  providedIn: 'root',
})
export class CognitoAuthenticationService {
  private cognitoUser: CognitoUser | null
  private completePasswordChallengeUser: CognitoUser | undefined
  private completeMFAChallengeUser: CognitoUser | null
  private recoveryAuthenticationDetails: AuthenticationDetails | null

  constructor(
    @Inject(AUTH_CONFIGURATION) readonly configurationService: AuthConfiguration,
    private cognitoUserFactory: CognitoUserFactory,
    private loggingService: LoggingService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.loadCurrentUser()
  }

  login(email: string, password: string, metadata?: ClientMetadata): Promise<void> {
    this.recoveryAuthenticationDetails = null
    const authenticationDetails = new AuthenticationDetails({
      Username: email.toLowerCase(),
      Password: password,
      ClientMetadata: metadata,
    })

    const cognitoUser = this.cognitoUserFactory.create(email)
    const cognitoUsername = cognitoUser.getUsername()
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const authService = this

    return new Promise<void>((resolve, reject) => {
      const rejectOnNewPasswordRequired = (): void => {
        authService.completePasswordChallengeUser = cognitoUser
        this.loggingService.warn({
          message: PASSWORD_RESET_REQUIRED_MESSAGE,
          email: cognitoUsername,
        })
        reject(new PasswordResetRequired(cognitoUsername))
      }

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: () => {
          this.loggingService.info({
            message: 'Login success',
            email: email.toLowerCase(),
          })
          this.loadCurrentUser()
          resolve()
        },

        onFailure: error => {
          // Handle cases where we get an exception here for PASSWORD_RESET_REQUIRED
          if (error.name === PASSWORD_RESET_REQUIRED) {
            rejectOnNewPasswordRequired()
            return
          }

          let message: string
          if (LOGIN_FAILURES.includes(error.name)) {
            if (error.message === AUTH_THROTTLE_MESSAGE) {
              message = LOGIN_ATTEMPT_THROTTLED_MESSAGE
            } else {
              message = INVALID_LOGIN_DETAILS_MESSAGE
            }
          } else {
            message = GENERIC_LOGIN_FAILURE_MESSAGE
          }
          this.handleCognitoError(
            error,
            {
              message,
              email: email.toLowerCase(),
              error,
            },
            () => reject({ message }),
          )
        },

        newPasswordRequired(): void {
          rejectOnNewPasswordRequired()
        },

        mfaRequired(): void {
          throw new Error('Multi-factor authentication is not currently supported.')
        },

        totpRequired: _ => {
          // set the user so we can complete challenge after navigating to challenge page
          this.completeMFAChallengeUser = cognitoUser
          this.recoveryAuthenticationDetails = authenticationDetails
          const queryParams = this.activatedRoute.snapshot.queryParams
          this.router.navigate([AppPaths.MultiFactorAuthentication], { queryParams })

          // Rejecting here to resolve the promise in the component
          reject(new SoftwareMfaRequired())
        },

        customChallenge(): void {
          throw new Error('Custom challenges are not currently supported.')
        },
      })
    })
  }

  changePassword(oldPassword: string, newPassword: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const cognitoUser = this.cognitoUser
      if (!cognitoUser) {
        throw new MissingCognitoUserError()
      }

      cognitoUser.changePassword(oldPassword, newPassword, (error, success) => {
        if (error) {
          let message = CHANGE_PASSWORD_FAILURE_MESSAGE
          if (LOGIN_FAILURES.includes(error.name)) {
            message = INVALID_LOGIN_DETAILS_MESSAGE
          } else {
            this.handleCognitoError(
              error,
              {
                message,
                username: cognitoUser.getUsername(),
                error,
              },
              () => reject({ message }),
            )
          }
        }
        if (success === 'SUCCESS') {
          this.loggingService.info({
            message: COMPLETED_PASSWORD_CHANGE_MESSAGE,
            username: cognitoUser.getUsername(),
          })
          this.loadCurrentUser()
          resolve()
        } else {
          reject({ message: UNKNOWN_FAILURE })
        }
      })
    })
  }

  completeNewPasswordChallenge(newPassword: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const cognitoUser = this.completePasswordChallengeUser
      if (!cognitoUser) {
        throw new MissingCognitoUserError()
      }
      cognitoUser.completeNewPasswordChallenge(newPassword, undefined, {
        onSuccess: () => {
          this.loggingService.info({
            message: 'Completed new password',
            username: cognitoUser.getUsername(),
          })
          this.loadCurrentUser()
          resolve()
        },
        onFailure: error => {
          const message = GENERIC_LOGIN_FAILURE_MESSAGE
          this.handleCognitoError(
            error,
            {
              message,
              username: cognitoUser.getUsername(),
              error,
            },
            () => reject({ message }),
          )
        },
      })
    })
  }

  signOut(): void {
    if (this.cognitoUser !== null) {
      this.cognitoUser.signOut()
      this.cognitoUser = null
    }
  }

  /**
   * Validates the current user session and throws an error if it is invalid.
   */
  validateSession(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.cognitoUser) {
        this.cognitoUser.getSession((sessionErr: Error, session: CognitoUserSession | null) => {
          if (sessionErr || !session) {
            this.loggingService.info({
              message: 'Unable to get user session from Cognito',
              sessionErr,
            })
            reject(sessionErr)
            return
          }

          if (!session.isValid()) {
            reject({ message: 'Session is not valid' })
            return
          }

          resolve()
        })
      } else {
        reject(new MissingCognitoUserError())
      }
    })
  }

  get mfaChallengeUser(): CognitoUser | null {
    return this.completeMFAChallengeUser
  }

  /**
   * Completes a multi-factor authentication challenge for the completeMFAChallengeUser.
   *
   * @param totpCode The TOTP code to use for the SOFTWARE_TOKEN_MFA challenge.
   * @returns A Promise that resolves when the MFA challenge if sucessful, and rejects with an error.
   * error exception is part of MFA_CHALLENGE_FAILURES the error message is thrown, otherwise GENERIC_LOGIN_FAILURE_MESSAGE is used.
   * if completeMFAChallengeUser is not set this function is rejected with the 'Cognito user not set' error.
   */
  completeMFAChallenge(totpCode: string): Promise<void> {
    const mfaType = 'SOFTWARE_TOKEN_MFA'
    return new Promise<void>((resolve, reject) => {
      const cognitoUser = this.completeMFAChallengeUser
      if (!cognitoUser) {
        throw new MissingCognitoUserError()
      }
      cognitoUser.sendMFACode(
        totpCode,
        {
          onSuccess: () => {
            this.loggingService.info({
              message: `MFA Challenge ${mfaType} successful`,
              username: cognitoUser.getUsername(),
            })
            this.recoveryAuthenticationDetails = null
            this.loadCurrentUser()
            resolve()
          },
          onFailure: error => {
            this.handleCognitoError(
              error,
              {
                message: `MFA Challenge: ${mfaType} failed: ${error.message}`,
                username: cognitoUser.getUsername(),
                error,
              },
              () => {
                if (isErrorMfaSessionExpiry(error)) {
                  // Pass the error through if it's an expired MFA session, so the submit
                  // flow can handle it.
                  reject(error)
                } else {
                  reject({
                    message:
                      error?.code === MFA_EXPIRED_CODE_EXCEPTION
                        ? MFA_EXPIRED_CODE_MESSAGE
                        : MFA_GENERIC_FAILURE_MESSAGE,
                  })
                }
              },
            )
          },
        },
        mfaType,
      )
    })
  }

  /**
   * Associates a software token with the current user.
   *
   * @returns A promise that resolves to a string containing the user's secret code.
   * @throws An error if there is no authenticated user or software token could not be generated.
   */
  associateSoftwareToken(): Promise<string> {
    this.loadCurrentUser()
    return new Promise((resolve, reject) => {
      this.validateSession()
        .then(() => {
          this.cognitoUser!.associateSoftwareToken({
            onFailure: err => {
              this.loggingService.info({
                message: 'Unable to associate software token',
                err,
              })
              reject(err)
            },
            associateSecretCode: secretCode => {
              resolve(secretCode)
            },
          })
        })
        .catch(reject)
    })
  }

  /**
   * Gets the Multi-Factor Authentication (MFA) status for the current Cognito user.
   * @param clearUserData Clears currently stored user data. (default=false)
   * If true latest Authentication status is retrieved.
   * @returns {Promise<boolean>} A promise that resolves with the MFA status.
   */
  getMultifactorAuthenticationEnabled(clearUserData: boolean = false): Promise<boolean> {
    this.loadCurrentUser()
    return new Promise((resolve, reject) => {
      this.validateSession()
        .then(() => {
          if (clearUserData) {
            this.clearCurrentUserData()
          }

          this.cognitoUser!.getUserData((err, data) => {
            if (err) {
              this.loggingService.info({
                message: 'Unable to get user data from Cognito',
                err,
              })
              reject(err)
              return
            }

            const hasMFASettingList = data?.UserMFASettingList !== undefined
            const hasPreferredMFASettingSoftwareToken = data?.PreferredMfaSetting === 'SOFTWARE_TOKEN_MFA'

            resolve(hasMFASettingList && hasPreferredMFASettingSoftwareToken)
          })
        })
        .catch(reject)
    })
  }

  /**
   * Sets the Multi-Factor Authentication (MFA) preference for the current user.
   *
   * @param enabled A boolean that indicates whether to enable or disable MFA.
   * @returns A promise that resolves on success, or rejects with an error on failure.
   */
  setMultifactorAuthentication(enabled: boolean): Promise<void> {
    this.loadCurrentUser()
    return new Promise((resolve, reject) => {
      this.validateSession()
        .then(() => {
          const totpMfaSettings = {
            PreferredMfa: enabled,
            Enabled: enabled,
          }

          this.cognitoUser!.setUserMfaPreference(null, totpMfaSettings, (err, _) => {
            if (err) {
              this.loggingService.info({
                message: 'Unable to disable MFA',
                err,
              })
              reject(err)
              return
            }

            this.clearCurrentUserData()
            resolve()
          })
        })
        .catch(reject)
    })
  }

  /**
   * Clears the user data for the current user.
   *
   * @param loadCurrentUser If true, the current user will be loaded before clearing its data.
   */
  clearCurrentUserData(loadCurrentUser: boolean = false): void {
    if (loadCurrentUser) {
      this.loadCurrentUser()
    }

    localStorage.removeItem(
      `CognitoIdentityServiceProvider.${this.configurationService.userPool.getClientId()}.${this.cognitoUser!.getUsername()}.userData`,
    )
  }

  /**
   * Returns `TokenStatus` or an error if the user's session has expired
   */
  getTokenStatus(): Promise<TokenStatus> {
    return new Promise<TokenStatus>((resolve, reject) => {
      if (this.cognitoUser && this.cognitoUser.getSession) {
        // getSession will use the refresh token if the current session has expired
        this.cognitoUser.getSession((err: Error, session: CognitoUserSession | null) => {
          if (err || !session) {
            this.loggingService.info({
              message: 'Unable to get user session from Cognito',
              err,
            })
            reject(err)
            return
          }

          if (!session.isValid()) {
            reject({ message: 'Session is not valid' })
            return
          }

          this.refreshSessionIfRequired(session)
            .then(isTokenNew => resolve({ isTokenValid: true, isTokenNew }))
            .catch(refreshError => reject(refreshError))
        })
      } else {
        resolve({ isTokenValid: false })
      }
    })
  }

  get currentJwtToken(): Promise<string> {
    return this.getSessionToken('currentJwtToken', session => session.getIdToken().getJwtToken())
  }

  get currentCognitoAccessToken(): Promise<string> {
    return this.getSessionToken('currentCognitoAccessToken', session => session.getAccessToken().getJwtToken())
  }

  resetPasswordVerification(email: string, verificationCode: string, newPassword: string): Promise<void> {
    const cognitoUser = this.cognitoUserFactory.create(email)
    return new Promise<void>((resolve, reject) => {
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onFailure: error => {
          this.handleCognitoError(
            error,
            {
              message: 'Reset password verification error',
              email: email.toLowerCase(),
              error,
            },
            err => reject(err),
          )
        },
        onSuccess: () => {
          this.loggingService.info({
            message: 'Reset password verification submitted',
            email: email.toLowerCase(),
          })
          resolve()
        },
      })
    })
  }

  /**
   * This function is used to re-authenticate the user after
   * submitting a valid recovery code during the MFA process.
   *
   * @returns A Promise that resolves to a boolean value indicating whether authentication was successful or not.
   */
  async recoveryAuthenticate(): Promise<boolean> {
    if (!this.recoveryAuthenticationDetails) {
      return false
    }

    const username = this.recoveryAuthenticationDetails.getUsername()
    try {
      await this.login(username, this.recoveryAuthenticationDetails.getPassword())
      return true
    } catch (error) {
      this.loggingService.warn({
        error,
        message: 'Recovery authentication failed.',
        username,
      })
      return false
    }
  }

  /**
   * Returns a promise that resolves with the current user's session token.
   *
   * @param {string} tokenLabel - The label of the token. Only used for error messages.
   * @param {(session: CognitoUserSession) => string} getTokenFn - A function that takes a CognitoUserSession and returns a string.
   * @returns {Promise<string>} A promise that resolves with the session token.
   *
   * @example
   * const token = await getSessionToken('tokenLabel', session => session.getIdToken().getJwtToken());
   */
  private getSessionToken(tokenLabel: string, getTokenFn: (session: CognitoUserSession) => string): Promise<string> {
    this.loadCurrentUser()
    return new Promise<string>((resolve, reject) => {
      if (this.cognitoUser) {
        this.cognitoUser.getSession((err: Error, session: CognitoUserSession | null) => {
          if (err || !session) {
            reject(err)
            return
          }
          const token = getTokenFn(session)
          if (token) {
            resolve(token)
          } else {
            reject(`Error getting token. ${tokenLabel} is null`)
          }
        })
      } else {
        reject(new MissingCognitoUserError())
      }
    })
  }

  private loadCurrentUser(): void {
    this.cognitoUser = this.configurationService.userPool.getCurrentUser()
  }

  private handleCognitoError(
    error: Error,
    errorLogData: Object,
    reject: (error: AuthenticationServerValidationError | Error) => void,
  ): void {
    if (isCognitoValidationError(error)) {
      this.loggingService.warn(errorLogData)
      reject(new AuthenticationServerValidationError(error.message, error.code))
    } else {
      this.loggingService.warn(errorLogData)
      reject(new Error('An unknown error has occurred'))
    }
  }

  /**
   * Refreshes the token if it will expire in TOKEN_EXPIRY_THRESHOLD_SECONDS
   * and returns a boolean indicating whether the token is new.
   */
  private async refreshSessionIfRequired(currentSession: CognitoUserSession): Promise<boolean> {
    const now = msToSeconds(Date.now())
    const exp = currentSession.getIdToken().getExpiration()

    // Token refresh is not required
    if (now < exp - TOKEN_EXPIRY_THRESHOLD_SECONDS) {
      return false
    }

    return new Promise<boolean>((resolve, reject) => {
      this.cognitoUser?.refreshSession(
        currentSession.getRefreshToken(),
        (err?: Error, session?: CognitoUserSession) => {
          if (err) {
            reject(err)
            return
          }
          if (!session || !session.isValid()) {
            reject({ message: 'Session is not valid' })
            return
          }
          resolve(true)
        },
      )
    })
  }
}

export const isErrorMfaSessionExpiry = (error: Error): boolean => {
  return error.name === MFA_EXPIRED_SESSION_EXCEPTION && error.message === MFA_EXPIRED_SESSION_MESSAGE
}
