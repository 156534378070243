@if (item.link) {
  <a href="{{ item.link }}" target="_blank">
    <ng-container *ngTemplateOutlet="flyOutItemTemplate"></ng-container>
  </a>
} @else {
  <ng-container *ngTemplateOutlet="flyOutItemTemplate"></ng-container>
}

<ng-template #flyOutItemTemplate>
  <div [class]="'help-sub-menu-flyout-item ' + className" [class.elevio__help]="item.title === helpCenterNavTitle">
    <div class="help-sub-menu-flyout-item__badge">
      <div class="help-sub-menu-flyout-item__badge-circle" [style.background]="item.badgeColor">
        <ftr-badge [icon]="item.icon" [color]="item.iconColor"></ftr-badge>
      </div>
    </div>
    <div class="help-sub-menu-flyout-item__detail">
      <div class="help-sub-menu-flyout-item__title">{{ item.title }}</div>
      <div class="help-sub-menu-flyout-item__description">{{ item.description }}</div>
    </div>
  </div>
</ng-template>
