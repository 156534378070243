import { NgModule } from '@angular/core'
import { PageComponent } from '@ftr/foundation'
import { ElevioArticleDirective } from '../elevio-article/elevio-article.directive'
import { SupportedBrowsersComponent } from './supported-browsers.component'

@NgModule({
  imports: [ElevioArticleDirective, PageComponent],
  declarations: [SupportedBrowsersComponent],
  exports: [SupportedBrowsersComponent],
})
export class SupportedBrowsersModule {}
