import { CombinedError } from '@ftr/foundation'
import { NotFoundApiError } from './not-found-api.error'

export function isNotFoundError(error: Error): boolean {
  if (error instanceof NotFoundApiError) {
    return true
  }
  if (error instanceof CombinedError) {
    return error.originalErrors.some(originalError => originalError instanceof NotFoundApiError)
  }
  return false
}
