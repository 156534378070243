import { CommonModule } from '@angular/common'
import { Component, Input, ViewEncapsulation } from '@angular/core'

@Component({
  selector: 'ftr-divider',
  templateUrl: './divider.component.html',
  styleUrls: ['./divider.component.css'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule],
})
export class DividerComponent {
  /**
   * Determines whether the divider should have extra margin around it.
   */
  @Input() loose = false
}
