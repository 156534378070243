import { CommonModule } from '@angular/common'
import { Component, ContentChild, Input, TemplateRef } from '@angular/core'
import { AuthDownloadLinkComponent } from '../auth-download-link/auth-download-link.component'

export interface DownloadLink {
  name: string
  href: string
  label?: string
}

/**
 * Displays a list of links to download files.
 */
@Component({
  selector: 'ftr-download-links',
  templateUrl: './download-links.component.html',
  styleUrls: ['./download-links.component.css'],
  standalone: true,
  imports: [AuthDownloadLinkComponent, CommonModule],
})
export class DownloadLinksComponent {
  @Input() downloadLinks: DownloadLink[]
  @ContentChild('noItems') noItemsTemplate: TemplateRef<any>
}
