import { AbstractControl, ValidationErrors } from '@angular/forms'

export class TermsOfUseValidator {
  private static readonly validationMessage = `<div>
You must agree to our <b>Terms of Use</b> and <b>Privacy Policy</b> before creating an account.
</div>`

  static required = (control: AbstractControl): ValidationErrors | null => {
    return control.value ? null : { error: this.validationMessage }
  }
}
