import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router'
import {
  Illustration,
  NotificationDisplayType,
  NotificationService,
  NotificationType,
  PageStyle,
  unwrapData,
} from '@ftr/foundation'
import { AuthenticationService } from '@ftr/ui-user'
import { SignupAttributesService } from '../signup-attributes.service'

@Component({
  selector: 'ftr-confirmation-sent',
  templateUrl: './confirmation-sent.component.html',
  styleUrls: ['./confirmation-sent.component.css'],
  host: {
    class: 'confirmation-sent page',
  },
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmationSentComponent implements OnInit {
  email: string
  isHelpShown = false

  readonly pageStyle = PageStyle.Narrow
  readonly illustration = Illustration

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly signupAttributesService: SignupAttributesService,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.signupAttributesService.attributes && this.signupAttributesService.attributes!.email) {
      this.email = this.signupAttributesService.attributes!.email
    } else {
      await this.router.navigate(['/signup'])
    }
  }

  showHelp(): void {
    this.isHelpShown = true
  }

  resendCode(): void {
    const username = this.signupAttributesService.attributes!.username
    this.authenticationService
      .resendCode(username)
      .pipe(unwrapData())
      .subscribe({
        complete: () => {
          this.notificationService.clear()
          this.notificationService.show(NotificationDisplayType.Now, {
            type: NotificationType.Info,
            message:
              'We have sent you another confirmation email. ' +
              'Please only use the most recent email to confirm your account.',
          })
        },
        error: error => {
          this.notificationService.clear()
          this.notificationService.show(NotificationDisplayType.Now, {
            type: NotificationType.Error,
            message: error.message,
          })
        },
      })
  }
}
