import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'
import { LATIN_EXTENDED_REGEXP_NAMES } from './LatinExtendedRegex'

export function isLatinExtendedName(allowEmpty = false, message?: string): ValidatorFn {
  const validationError = message ? { nonAlphanumericValue: message } : { error: true }

  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value.toString().trim()

    if (allowEmpty && value.length === 0) {
      return null
    }

    if (!value || value.match(LATIN_EXTENDED_REGEXP_NAMES)) {
      return validationError
    }

    return null
  }
}
