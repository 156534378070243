import { Injectable } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { BehaviorSubject, distinctUntilChanged, filter } from 'rxjs'
import { LocalStorageService } from '../local-storage/local-storage.service'
import { DisplayableNotification, Notification } from './notification'
import { NotificationDisplayType } from './notification-display-type'
import { NotificationType } from './notification-type'

// localStorage key for notifications to show after an external redirect
export const STORED_NOTIFICATIONS_KEY = 'storedNotifications'

/**
 * Schedules notifications to be shown to the user.
 *
 * Consumers of this service should subscribe to the <code>notifications</code> observable
 * to get an array of what notifications should be shown to the user at any given time, and
 * should use the <code>show()</code> method to schedule a notification to be shown.
 */
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  /**
   * Emits an array of notifications which should be displayed to the user.
   */
  notifications = new BehaviorSubject<DisplayableNotification[]>([])

  private notificationsList: DisplayableNotification[] = []

  constructor(
    router: Router,
    private readonly localStorageService: LocalStorageService,
  ) {
    this.scheduleStoredNotifications()
    router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        distinctUntilChanged(navigationEndDifferent),
      )
      .subscribe(() => this.handleNavigation())
  }

  /**
   * Schedule a notification to be shown to the user.
   *
   * Depending on the <code>display</code> argument provided, the notification
   * is either shown immediately, after the next navigation completes, or after an external redirect.
   *
   * @param {NotificationDisplayType} display
   * @param {Notification} notifications
   */
  show(display: NotificationDisplayType, ...notifications: Notification[]): void {
    if (display === NotificationDisplayType.AfterExternalRedirect) {
      this.localStorageService.setGlobal(STORED_NOTIFICATIONS_KEY, notifications)
      return
    }

    this.notificationsList = this.notificationsList.concat(
      notifications.map(notification => ({
        ...notification,
        display,
      })),
    )
    this.updateNotificationsObservable()
  }

  /**
   * Convenience method to show an immediate error
   */
  showErrorNow(message: string): void {
    this.show(NotificationDisplayType.Now, { type: NotificationType.Error, message })
  }

  /**
   * Convenience method to show an error on next load
   */
  showErrorOnNavigate(message: string): void {
    this.show(NotificationDisplayType.AfterNextNavigation, { type: NotificationType.Error, message })
  }

  /**
   * Convenience method for showing an immediate success notification.
   */
  showSuccessNow(message: string): void {
    this.clear()
    this.show(NotificationDisplayType.Now, { type: NotificationType.Success, message })
  }

  /**
   * Convenience method to show a success on next load
   */
  showSuccessOnNavigate(message: string): void {
    this.show(NotificationDisplayType.AfterNextNavigation, { type: NotificationType.Success, message })
  }

  clear(): void {
    this.notificationsList = []
    this.updateNotificationsObservable()
  }

  private handleNavigation(): void {
    this.notificationsList = this.notificationsList
      .filter(n => n.display === NotificationDisplayType.AfterNextNavigation)
      .map(n => ({ ...n, display: NotificationDisplayType.Now }))

    this.updateNotificationsObservable()
  }

  private updateNotificationsObservable(): void {
    const listCopy = this.notificationsList.filter(n => n.display === NotificationDisplayType.Now)
    this.notifications.next(listCopy)
  }

  private scheduleStoredNotifications(): void {
    const storedNotifications = this.localStorageService.getGlobal<Notification[]>(STORED_NOTIFICATIONS_KEY)
    this.localStorageService.removeGlobal(STORED_NOTIFICATIONS_KEY)

    if (storedNotifications?.length) {
      this.show(NotificationDisplayType.AfterNextNavigation, ...storedNotifications)
    }
  }
}

function navigationEndDifferent(a: NavigationEnd, b: NavigationEnd): boolean {
  if (a === undefined || b === undefined) {
    return false
  }
  const [urlA] = a.url.split('?')
  const [urlB] = b.url.split('?')
  return urlA === urlB
}
