import { Injectable } from '@angular/core'
import { AuthTokenFetcher } from '@ftr/api-shared'
import { AuthenticationService } from './authentication.service'

@Injectable()
export class AuthTokenFetcherService implements AuthTokenFetcher {
  constructor(private readonly authService: AuthenticationService) {}

  public async getToken(): Promise<string> {
    return this.authService.currentJwtToken
  }
}
