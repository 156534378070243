import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { LoggedInGuard } from '@ftr/ui-user'
import { PasswordResetModule } from '~app/features/password-reset/password-reset.module'
import { PasswordResetParams } from './password-reset.params'
import { ResetSentPage } from './reset-sent.page'
import { ResetPage } from './reset.page'

@NgModule({
  imports: [
    PasswordResetModule,
    RouterModule.forChild([
      {
        path: 'reset',
        component: ResetPage,
        canActivate: [LoggedInGuard],
        data: {
          title: 'Reset Your Password',
          shouldShowSidenav: false,
        },
      },
      {
        path: 'reset-sent',
        component: ResetSentPage,
        canActivate: [LoggedInGuard],
        data: {
          title: 'Request Email Sent',
          shouldShowSidenav: false,
        },
      },
      // Lazy load the verify route page in its own module.
      // Keeps password verification library out of the main bundle.
      {
        path: `resetverify/:${PasswordResetParams.Username}`,
        loadChildren: () => import('./password-reset-verify-page.module').then(m => m.PasswordResetVerifyPageModule),
      },
    ]),
  ],
  declarations: [ResetPage, ResetSentPage],
  exports: [RouterModule],
})
export class PasswordResetPagesModule {}
