import {
  catchError,
  combineLatest,
  concat,
  debounceTime,
  EMPTY,
  first,
  map,
  ObservableInput,
  ObservedValueOf,
  of,
  OperatorFunction,
  skip,
  switchMap,
} from 'rxjs'

/**
 * Filters out undefined/null and returns the correct types
 *
 * example:
 *
 * instead of....
 *
 * `pipe(
 *      map(component => component.nativeElement)
 *      filter(el => !!el),
 *    ).subscribe(el => el!.getClientBoundingBox())`
 *
 * do
 *
 *
 * `pipe(
 *     collect(component => component.nativeElement)
 *   ).subscribe(el => el.getClientBoundingBox())`
 *
 * @param f the function to retrieve the variable
 */
export function collect<A, B>(f: (a: A) => B | undefined | null): OperatorFunction<A, B> {
  return obs =>
    obs.pipe(
      switchMap(v => {
        const res = f(v)
        if (res === undefined || res === null) {
          return EMPTY
        }
        return of(res)
      }),
    )
}

/**
 * Filters out exceptions from this stream.
 *
 * Exceptions will actually completely kill a stream, this is used to prevent that happening
 *
 * Use this to make it really clear to other developers that errors are ignored deliberately.
 *
 * Usually used in conjunction with a subscribe
 *
 * `pipe(
 *     ignoreErrors()
 *   ).subscribe(data => doSomethingWithData)`
 */
export function ignoreErrors<A>(): OperatorFunction<A, A> {
  return obs => obs.pipe(catchError(_ => EMPTY))
}

/**
 * Use as a replacement of debounceTime that emits the first item, then continues with normal debounce behaviour
 */
export function debounceTimeEmitImmediately<A>(time: number): OperatorFunction<A, A> {
  return obs => concat(obs.pipe(first()), obs.pipe(skip(1), debounceTime(time)))
}

/**
 * Much like a normal `switchMap`, but combines the resulting observed value/s to the incoming data set.
 * Note: if multiple observables are returned, they are combined using a `combineLatest`.
 *
 * ### Example
 * Consider we have:
 * ```
 *   let someObs : Observable<{ name: string, age: number }>
 *   let otherObs: Observable<string>
 * ```
 *
 * Instead of:
 * ```
 *   const returnObs: Observable<{ name: string, age: number, gender: string }> = someObs.pipe(
 *     switchMap( val => otherObs.pipe(map(gender => ({ ...val, gender })))
 *   )
 * ```
 *
 * Just do:
 * ```
 *   const returnObs: Observable<{ name: string, age: number, gender: string }> = someObs.pipe(
 *     switchCombine(() => ({ gender: otherObs }))
 *   )
 * ```
 *
 * @param project
 */
export function switchCombine<T extends Record<string, any>, O extends Record<string, ObservableInput<unknown>>>(
  project: (value: T) => O,
): OperatorFunction<
  T,
  T & {
    [Name in keyof O]: ObservedValueOf<O[Name]>
  }
> {
  return obs =>
    obs.pipe(switchMap(values => combineLatest(project(values)).pipe(map(newValues => ({ ...values, ...newValues })))))
}
