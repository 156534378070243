import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core'
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { PasswordResetRequired, RESET_PASSWORD_WARNING, SoftwareMfaRequired } from '@ftr/api-shared'
import { LEGACY_PASSWORD_MIN_LENGTH } from '@ftr/contracts/api/user'
import { emailValidator } from '@ftr/forms'
import { DestroySubscribers, RemoteData, isQuotaExceeded } from '@ftr/foundation'
import { AuthenticationService } from '@ftr/ui-user'
import { ReplaySubject } from 'rxjs'

interface FormSubmission {
  email: string
  password: string
}

@Component({
  selector: 'ftr-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginFormComponent extends DestroySubscribers implements OnInit {
  @Input() returnUrl: string | undefined

  loginForm: UntypedFormGroup
  submissionSubject = new ReplaySubject<RemoteData<void, string>>()
  submission = this.submissionSubject.asObservable()

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
  ) {
    super()
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', emailValidator],
      password: ['', [Validators.required, Validators.minLength(LEGACY_PASSWORD_MIN_LENGTH)]],
    })
    this.submissionSubject.next(RemoteData.notAsked())
  }

  async submitForm(value: FormSubmission): Promise<void> {
    this.submissionSubject.next(RemoteData.loading())
    try {
      const email = value.email.trim()
      const password = value.password
      await this.authenticationService.login(email, password)
    } catch (error) {
      if (error instanceof PasswordResetRequired) {
        await this.router.navigate(['/reset'], {
          queryParams: { [RESET_PASSWORD_WARNING]: true },
        })
        return
      } else if (error instanceof SoftwareMfaRequired) {
        // Bypass RemoteData failure. User has been redirected to MFA Challenge page.
        return
      } else if (isQuotaExceeded(error)) {
        this.submissionSubject.next(
          RemoteData.failure(
            "An error occurred. Please clear your browser's cache and cookies, reload the page and try again.",
          ),
        )
        return
      }

      this.submissionSubject.next(RemoteData.failure(error.message))
      return
    }

    const { result: postLoginResult, getValidReturnUrl } = await this.authenticationService.postLoginProcess()
    if (!postLoginResult) {
      /**
       * Having this outside of the try/catch ensures that we don't propagate any errors from this call to the user. No
       * errors coming from this are of any use to a user.
       */
      await this.router.navigateByUrl(getValidReturnUrl(this.returnUrl))
    }
  }

  asFormControl(control: AbstractControl): UntypedFormControl {
    return control as UntypedFormControl
  }
}
