import { PrivateConfiguration } from '@ftr/contracts/api/configuration'
import { CourtSystem } from '@ftr/contracts/api/court-system'
import { Feature } from '@ftr/contracts/api/feature'
import { UserLocation, UserSettings, UserWithGlobalAdministratorRole } from '@ftr/contracts/api/user'
import { UserGroup } from '@ftr/contracts/api/user-group'
import { AudioPlaybackFeature } from '@ftr/contracts/read/audio-playback-feature'
import { RemoteData } from '@ftr/foundation'

export enum MfaSetupModalType {
  Generic = 'Generic',
  Internal = 'Internal',
  External = 'External',
  InternalUnsupportedSSO = 'InternalUnsupportedSSO',
  ExternalUnsupportedSSO = 'ExternalUnsupportedSSO',
  InternalSSO = 'InternalSSO',
  ExternalSSO = 'ExternalSSO',
  Closed = 'Closed',
}

export enum MfaSetupModalAction {
  NotNow = 'NotNow',
  ReturnToList = 'ReturnToList',
}

export interface MultiFactorAuthenticationState {
  mfaRecoveryModalOpen: boolean
  mfaSetupModalType: MfaSetupModalType
  mfaSetupModalAction: MfaSetupModalAction
}

export interface UserStateModel {
  user: RemoteData<UserWithGlobalAdministratorRole> | undefined
  userGroups: RemoteData<UserGroup[]> | undefined
  userLocation: UserLocation | undefined
  userSettings: UserSettings
  courtSystems: RemoteData<CourtSystem[]> | undefined
  courtSystem?: CourtSystem
  courtSystemConfiguration: RemoteData<PrivateConfiguration> | undefined
  audioPlaybackFeature: RemoteData<AudioPlaybackFeature> | undefined
  enabledFeatures: RemoteData<Feature[]> | undefined
  mfa: MultiFactorAuthenticationState
}
