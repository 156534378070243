import { Injectable, NgZone } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { isEqual } from 'lodash-es'
import { Subscription, map, tap } from 'rxjs'
import {
  ComplexFilterData,
  RadioListFilter,
  arrayToComplexFilterData,
  getFilterValue,
  mapFilterValueToQSValue,
} from '../../lib/complex-filter'
import {
  ClearAllFilters,
  StartListeningToFilterChanges,
  StopListeningToFilterChanges,
  UpdateComplexFilter,
} from './complex-filter.actions'
import { ComplexFilterModel } from './complex-filter.model'

export function defaultComplexFilterState(): ComplexFilterModel {
  return {
    data: {},
    filters: [],
  }
}

/**
 * This state handles complex filter updates and corresponding query string parameters.
 */
@State<ComplexFilterModel>({
  name: 'complexFilterState',
  defaults: defaultComplexFilterState(),
})
@Injectable()
export class ComplexFilterState {
  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly zone: NgZone,
  ) {}

  queryParamSubscription: Subscription

  @Selector()
  static data(state: ComplexFilterModel): ComplexFilterData {
    return state.data
  }

  @Selector()
  static allState(state: ComplexFilterModel): ComplexFilterModel {
    return state
  }

  @Action(StartListeningToFilterChanges)
  startListeningToFilterChanges(
    { patchState }: StateContext<ComplexFilterModel>,
    { filters }: StartListeningToFilterChanges,
  ): void {
    this.queryParamSubscription = this.route.queryParams
      .pipe(
        map(queryParams =>
          arrayToComplexFilterData(
            filters.map(filter => ({
              [filter.filterKey]: getFilterValue(filter, queryParams[filter.filterKey]),
            })),
          ),
        ),
        tap(data => patchState({ data, filters })),
      )
      .subscribe()
  }

  @Action(StopListeningToFilterChanges)
  stopListeningToFilterChanges({ patchState }: StateContext<ComplexFilterModel>): void {
    patchState(defaultComplexFilterState())
    this.queryParamSubscription.unsubscribe()
  }

  @Action(ClearAllFilters)
  clearAllFilters({ dispatch, getState }: StateContext<ComplexFilterModel>): void {
    const filters = getState().filters
    const data = arrayToComplexFilterData(
      filters
        .filter(filter => !filter.noClear)
        .map(filter => {
          const values = [filter]
            .concat(filter instanceof RadioListFilter && filter.additionalFilters ? filter.additionalFilters : [])
            .map(f => f.defaultValue || undefined)
          return { [filter.filterKey]: values && values.length === 1 ? values[0] : values } as ComplexFilterData
        }),
    )

    dispatch(new UpdateComplexFilter(data))
  }

  @Action(UpdateComplexFilter)
  updateComplexFilter(
    { getState }: StateContext<ComplexFilterModel>,
    { data, resetPage = true }: UpdateComplexFilter,
  ): void {
    const existingData = getState().data
    const newData = {
      ...getState().data,
      ...data,
    }

    // This is the initial value on page load. If we emit it, we won't be able to preserve query string
    // parameters between page refreshes.
    const isInitialPageLoad = Object.keys(existingData).length === 0 && Object.values(newData).every(x => !x)
    if (isEqual(existingData, newData) || isInitialPageLoad) {
      return
    }

    const queryParams = {
      ...this.route.snapshot.queryParams,
      ...arrayToComplexFilterData(
        Object.entries(data).map(([key, value]) => ({
          [key]: mapFilterValueToQSValue(value),
        })),
      ),
    }

    // In some cases we do not want to reset the page, e.g. when updating dependent filters
    if (resetPage) {
      queryParams.page = undefined
    }

    this.zone.run(() => {
      this.router.navigate([], {
        replaceUrl: true,
        relativeTo: this.route,
        queryParamsHandling: 'merge',
        queryParams,
      })
    })
  }
}
