<ng-template #mfaSetupModalTemplate>
  <ftr-modal
    [modalTitle]="''"
    [hideActions]="true"
    [preventClose]="true"
    [size]="modalSize.Large"
    (close)="closeModal()"
  >
    <div class="mfa-setup-modal">
      <ftr-illustration [illustration]="illustration.Security"></ftr-illustration>
      @switch (mfaSetupModalType) {
        @case (mfaSetupModalTypes.Generic) {
          <ng-container *ngTemplateOutlet="generic"></ng-container>
        }
        @case (mfaSetupModalTypes.Internal) {
          <ng-container *ngTemplateOutlet="internal"></ng-container>
        }
        @case (mfaSetupModalTypes.External) {
          <ng-container *ngTemplateOutlet="external"></ng-container>
        }
        @case (mfaSetupModalTypes.InternalSSO) {
          <ng-container *ngTemplateOutlet="internalSSO"></ng-container>
        }
        @case (mfaSetupModalTypes.ExternalSSO) {
          <ng-container *ngTemplateOutlet="externalSSO"></ng-container>
        }
        @case (mfaSetupModalTypes.InternalUnsupportedSSO) {
          <ng-container *ngTemplateOutlet="internalUnsupportedSSO"></ng-container>
        }
        @case (mfaSetupModalTypes.ExternalUnsupportedSSO) {
          <ng-container *ngTemplateOutlet="externalUnsupportedSSO"></ng-container>
        }
      }

      <div class="mfa-setup-modal__footer">
        @switch (mfaSetupModalAction) {
          @case (mfaSetupModalActions.NotNow) {
            <button color="secondary" type="button" ftrButton (click)="notNow()">Not Now</button>
          }
          @case (mfaSetupModalActions.ReturnToList) {
            <button color="secondary" type="button" ftrButton (click)="closeModal()">Return To List</button>
          }
        }
        @if (
          mfaSetupModalType !== mfaSetupModalTypes.InternalSSO && mfaSetupModalType !== mfaSetupModalTypes.ExternalSSO
        ) {
          <button
            color="primary"
            type="button"
            ftrButton
            (click)="
              mfaSetupModalType === mfaSetupModalTypes.ExternalUnsupportedSSO ||
              mfaSetupModalType === mfaSetupModalTypes.InternalUnsupportedSSO
                ? signOut()
                : navigateToSetupMfa()
            "
          >
            {{
              mfaSetupModalType === mfaSetupModalTypes.ExternalUnsupportedSSO ||
              mfaSetupModalType === mfaSetupModalTypes.InternalUnsupportedSSO
                ? 'Log out'
                : 'Secure Your Account'
            }}
          </button>
        }
      </div>
    </div>
  </ftr-modal>
</ng-template>

<ng-template #generic>
  <h2>Set up Multi-Factor Authentication (MFA)</h2>
  <p>
    Some recordings may require you to set up MFA before you can access them. Setting it up now will allow you to easy
    access any documents you may need.
  </p>
</ng-template>

<ng-template #external>
  <h2>Multi-Factor Authentication Required</h2>
  <p>
    The court administration requires that all users accessing their court to have Multi-Factor Authentication enabled
    before proceeding.
  </p>
</ng-template>

<ng-template #internal>
  <h2>Multi-Factor Authentication Required</h2>
  <p>Your court system has required all users to enable Multi-Factor Authentication before proceeding.</p>
</ng-template>

<ng-template #internalUnsupportedSSO>
  <h2>Multi-Factor Authentication Required</h2>
  <p>
    Single Sign-On is not allowed by your court system. Please log out and sign in via Email & Password. If you do not
    already have an alternative sign in method you will need to sign up again with the same email as your Single Sign-On
    account.
  </p>
</ng-template>

<ng-template #externalUnsupportedSSO>
  <h2>Multi-Factor Authentication Required</h2>
  <p>
    Single Sign-On is not allowed by the active court system. Please log out and sign in via Email & Password. If you do
    not already have an alternative sign in method you will need to sign up again with the same email as your Single
    Sign-On account.
  </p>
</ng-template>

<ng-template #internalSSO>
  <h2>Set up Multi-Factor Authentication (MFA) with Single Sign-on</h2>
  <p>
    You have not set up Multi-factor Authentication with your chosen Single Sign-on. Your court system has required all
    users to enable Multi-Factor Authentication before proceeding.
  </p>
</ng-template>

<ng-template #externalSSO>
  <h2>Set up Multi-Factor Authentication (MFA) with Single Sign-on</h2>
  <p>
    The court administration requires that all users accessing their court to have Multi-Factor Authentication enabled
    through your chosen Single Sign-on before proceeding.
  </p>
</ng-template>
