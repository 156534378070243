import { animate, animateChild, group, query, state, style, transition, trigger } from '@angular/animations'
import { TemplatePortal } from '@angular/cdk/portal'
import { Component, HostListener, Input, OnDestroy, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core'
import { PortalService } from '@ftr/foundation'
import { StaticMenuItem } from '@ftr/menus'
import { Observable } from 'rxjs'

export type SideNavOpenFrom = 'left' | 'right'

const BACKGROUND_OPACITY = 0.7

@Component({
  selector: 'ftr-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css'],
  animations: [
    trigger('sideNavAnimations', [
      transition(':enter', [
        group([query('@slideInMenu', [animateChild()]), query('@slideInOverlay', [animateChild()])]),
      ]),
    ]),
    trigger('slideInMenu', [
      state('open', style({ transform: 'none' })),
      state('closed', style({ transform: '{{transformValue}}' }), { params: { transformValue: 'none' } }),
      transition(':enter', [animate('300ms', style({ transform: 'none' }))]),
      transition('open => closed', [animate('300ms')]),
    ]),
    trigger('slideInOverlay', [
      state('open', style({ opacity: BACKGROUND_OPACITY })),
      state('closed', style({ opacity: 0 })),
      transition(':enter', [animate('300ms', style({ opacity: BACKGROUND_OPACITY }))]),
      transition(':leave', [animate('300ms')]),
    ]),
  ],
})
export class SideNavComponent implements OnDestroy {
  /**
   * Is the menu open
   */
  isShowing = false

  /**
   * When set to true this will ignore the slide animation done callback once, useful for
   * when another portal is adding content as the side nav slides closed
   */
  private ignoreSlideAnimationDone = false

  /**
   * Open from the left or right side, depending on which menu you want to display.
   */
  @Input() openFrom: SideNavOpenFrom = 'left'
  /**
   * The items to render in the navigation menu
   */
  @Input() navItems: Observable<StaticMenuItem[]>

  @Input() openerTemplate: TemplateRef<unknown>
  /**
   * Content to display in the header link area.
   */
  @Input() headerTemplate: TemplateRef<unknown>

  @Input() fixedHeightHeader = true

  @ViewChild('sideNavMenu') sideNavMenu: TemplateRef<unknown>

  constructor(
    private readonly portalService: PortalService,
    private readonly viewContainerRef: ViewContainerRef,
  ) {}

  ngOnDestroy(): void {
    this.close()
    this.portalService.setContent(undefined)
  }

  close(ignoreSlideAnimationDone = false): void {
    this.isShowing = false
    this.ignoreSlideAnimationDone = ignoreSlideAnimationDone
  }

  toggle(): void {
    if (!this.isShowing) {
      this.portalService.setContent(new TemplatePortal<any>(this.sideNavMenu, this.viewContainerRef))
    }
    this.isShowing = !this.isShowing
  }

  slideAnimationDone(): void {
    if (this.ignoreSlideAnimationDone) {
      this.ignoreSlideAnimationDone = false
      return
    }

    // Clear out the portal content whenever the menu is closed
    if (!this.isShowing) {
      this.portalService.setContent(undefined)
    }
  }

  @HostListener('document:keydown.escape', ['$event'])
  handleKeydown($event: KeyboardEvent): void {
    if (this.isShowing) {
      this.close()
      $event.stopPropagation()
    }
  }
}
