import { Injectable } from '@angular/core'
import { ApiClient, ApiClientFactory } from '@ftr/api-shared'
import { LogEventBody } from '@ftr/contracts/api/audit-proxy'
import { ApiResult, mapData, recover, tapFailure } from '@ftr/foundation'
import { LoggingService } from '@ftr/ui-observability'

@Injectable({
  providedIn: 'root',
})
export class AuditService {
  private readonly apiClient: ApiClient
  constructor(
    apiClientFactory: ApiClientFactory,
    private readonly logger: LoggingService,
  ) {
    this.apiClient = apiClientFactory.build('audit-proxy')
  }

  logEvent(type: LogEventBody['eventType'], payload: Omit<LogEventBody, 'eventType'>): ApiResult<void> {
    return this.apiClient
      .post({
        path: '/log-event',
        body: {
          eventType: type,
          ...payload,
        },
      })
      .pipe(
        tapFailure(error => {
          // Actions continue normally despite errors from logEvent
          this.logger.warn({ message: 'Failed to logEvent via API endpoint', error })
        }),
        mapData(() => undefined),
        recover(undefined),
      )
  }
}
