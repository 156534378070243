import { Component, EventEmitter, Input, Output } from '@angular/core'
import { StaticMenuItem } from '@ftr/menus'

@Component({
  selector: 'ftr-side-nav-item-inner',
  templateUrl: './side-nav-item-inner.component.html',
  styleUrls: ['./side-nav-item.component.css'],
})
export class SideNavItemInnerComponent {
  @Input() navItem: StaticMenuItem
  // eslint-disable-next-line @angular-eslint/no-output-rename
  @Output('hide') hideEvents = new EventEmitter()

  hide(): void {
    this.hideEvents.emit()
  }

  select(): void {
    if (this.navItem.onSelect) {
      this.navItem.onSelect()
    }
  }
}
