<a
  class="menu-toggle align-items-center"
  [class.menu-toggle--is-showing]="isShowing"
  [title]="isShowing ? 'Close menu' : 'Open menu'"
  (click)="toggle()"
  data-track
>
  <div class="menu-toggle__hamburger">
    <div aria-hidden="true"></div>
    <div aria-hidden="true"></div>
    <div aria-hidden="true"></div>
  </div>

  @if (isShowing) {
    <div class="menu-toggle__text">Close</div>
  }
</a>
