import { Inject, Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router } from '@angular/router'
import { API_CONFIGURATION, FullApiClientConfiguration, RootParams } from '@ftr/api-shared'
import { DJ_REGION } from '@ftr/contracts/digital-justice'
import { Region } from '@ftr/contracts/type/court/Region'
import { LocalStorageService, SimpleWindowRefService } from '@ftr/foundation'
import { AuthenticationService } from '../../services'

@Injectable({
  providedIn: 'root',
})
export class LoggedInGuard {
  constructor(
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService,
    @Inject(API_CONFIGURATION) readonly configurationService: FullApiClientConfiguration,
    private readonly localStorageService: LocalStorageService,
    private readonly windowRefService: SimpleWindowRefService,
  ) {}

  canActivate(activatedRoute: ActivatedRouteSnapshot): Promise<boolean> {
    return this.authenticationService.isLoggedIn.then(isLoggedIn => {
      // Allow desktop to call native SSO even if we're logged in, see: login.component.ts -> handleNativeAuth
      if (
        activatedRoute.queryParams[RootParams.IdentityProvider] &&
        activatedRoute.queryParams[RootParams.CodeChallenge] &&
        activatedRoute.queryParams[RootParams.State]
      ) {
        return true
      }

      if (isLoggedIn) {
        this.router.navigate([], { queryParams: activatedRoute.queryParams })
        return false
      }

      const region = this.localStorageService.getGlobal<Region>(DJ_REGION)
      if (region) {
        this.localStorageService.removeGlobal(DJ_REGION)
        const djUrl = this.configurationService.digitalJusticeWeb.endpointMap[region]
        this.windowRefService.location().assign(`${djUrl}/auth/callback`)
        return false
      }

      return true
    })
  }
}
