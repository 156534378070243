import { Injectable } from '@angular/core'
import { ApiClient, ApiClientType, ApiErrorMapper, HttpClientRemoteData } from '@ftr/api-shared'
import { collect } from '@ftr/foundation'
import { CourtSystemRegionCache } from '@ftr/ui-court-system'
import { Observable, from } from 'rxjs'
import { ConfigurationService } from '~app/services/configuration/configuration.service'

/**
 * Create a client service for ftr-regional-audit (API) for a given controller path and a courtSystemId or region
 */
@Injectable({
  providedIn: 'root',
})
export class AuditApiClientFactory {
  constructor(
    private readonly http: HttpClientRemoteData,
    private readonly configurationService: ConfigurationService,
    private readonly apiErrorMapper: ApiErrorMapper,
    private readonly courtSystemRegionCache: CourtSystemRegionCache,
  ) {}

  build(path: string, courtSystemId: string, httpService: HttpClientRemoteData = this.http): Observable<ApiClient> {
    const courtSystem$ = this.courtSystemRegionCache.getOrFetchCourtSystem(from([courtSystemId]))

    return courtSystem$.pipe(collect(courtSystem => this.buildForRegion(path, courtSystem.region, httpService)))
  }

  buildForRegion(path: string, region: string, httpService: HttpClientRemoteData = this.http): ApiClient {
    return new ApiClient(
      httpService,
      this.configurationService,
      this.apiErrorMapper,
      { clientType: ApiClientType.audit, region },
      `/${path.replace(/^\//, '')}`,
    )
  }
}
