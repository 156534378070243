import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

@Component({
  template: '<ftr-help [isLoggedIn]="isLoggedIn"></ftr-help>',
})
export class HelpPage {
  isLoggedIn = this.route.snapshot.data?.isLoggedIn

  constructor(private route: ActivatedRoute) {}
}
