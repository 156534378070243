import { LocalTimeRange } from '@ftr/contracts/type/shared/LocalTimeRange'
import { toMoment } from '@ftr/foundation'
import { LocalDateTime } from '@js-joda/core'
import { isTimeRangeWithinTimeRanges } from '../utils'
import { PauseBreakingTranscriptElement } from './real-time-stt-segment'
import { isSealingMarker, isSessionMarker } from './real-time-stt-transcript-element'

export interface RealTimeSttPauseConfiguration {
  readonly canAccessSealedContent: boolean
  readonly pauseThresholdMillis: number
  readonly segmentMapHasSttContent: boolean
}

export type RealTimeSttPauseType = 'in-session-pause' | 'session-break'

export class RealTimeSttPause {
  readonly durationMillis: number
  readonly id: string
  readonly type = 'Pause' as const
  constructor(
    readonly startTime: LocalDateTime,
    readonly endTime: LocalDateTime,
    readonly pauseType: RealTimeSttPauseType,
  ) {
    this.durationMillis = toMoment(endTime).moment.diff(toMoment(startTime).moment)
    this.id = sanitizeId(startTime.toString() + this.durationMillis.toString())
  }

  static betweenPauseBreakingElements(
    start: PauseBreakingTranscriptElement,
    end: PauseBreakingTranscriptElement,
  ): RealTimeSttPause {
    const pauseType: RealTimeSttPauseType = isSessionBreak(start, end) ? 'session-break' : 'in-session-pause'

    return new RealTimeSttPause(start.endTime, end.startTime, pauseType)
  }

  toString(): string {
    return `${this.startTime} - ${this.endTime} (${this.durationMillis}ms)`
  }
}

export function pausesEqual(a: RealTimeSttPause | undefined, b: RealTimeSttPause | undefined): boolean {
  if (a === b) {
    return true
  }
  if (!b || !a) {
    return false
  }

  return (
    a.durationMillis === b.durationMillis &&
    a.startTime.isEqual(b.startTime) &&
    a.endTime.isEqual(b.endTime) &&
    a.pauseType === b.pauseType
  )
}

function sanitizeId(id: string): string {
  return `pause-id-${id.replace(/:|\./g, '-')}`
}

function isSessionBreak(start: PauseBreakingTranscriptElement, end: PauseBreakingTranscriptElement): boolean {
  const startsAtSessionEnd = isSessionMarker(start) && start.dividerType === 'sessionEnd'
  const endsAtSessionStart = isDividerRepresentingSessionStart(end)

  return startsAtSessionEnd && endsAtSessionStart
}

export function isDividerRepresentingSessionStart(element: PauseBreakingTranscriptElement): boolean {
  return (
    isSessionMarker(element) && (element.dividerType === 'sessionStart' || element.dividerType === 'futureSessionStart')
  )
}

export function shouldIgnorePause(
  configuration: RealTimeSttPauseConfiguration,
  pause: RealTimeSttPause,
  start: PauseBreakingTranscriptElement | undefined,
  end: PauseBreakingTranscriptElement,
  sealedTimeRanges: LocalTimeRange[],
): boolean {
  const inSessionPauseWhenNoSttPresent =
    !configuration.segmentMapHasSttContent && pause.pauseType === 'in-session-pause'

  return (
    inSessionPauseWhenNoSttPresent ||
    pause.durationMillis < configuration.pauseThresholdMillis ||
    shouldSkipSealedPause(configuration, pause, start, end, sealedTimeRanges)
  )
}

function shouldSkipSealedPause(
  configuration: RealTimeSttPauseConfiguration,
  pause: RealTimeSttPause,
  start: PauseBreakingTranscriptElement | undefined,
  end: PauseBreakingTranscriptElement,
  sealedTimeRanges: LocalTimeRange[],
): boolean {
  if (!configuration.canAccessSealedContent && pauseIsBetweenSealingMarkers(start, end)) {
    return pauseTimeRangeIsSealed(pause, sealedTimeRanges)
  }
  return false
}

function pauseIsBetweenSealingMarkers(
  start: PauseBreakingTranscriptElement | undefined,
  end: PauseBreakingTranscriptElement,
): boolean {
  return start !== undefined && isSealingMarker(start) && isSealingMarker(end)
}

function pauseTimeRangeIsSealed(pause: RealTimeSttPause, sealedTimeRanges: LocalTimeRange[]): boolean {
  const pauseTimeRange = new LocalTimeRange(pause.startTime.toLocalTime(), pause.endTime.toLocalTime())
  return isTimeRangeWithinTimeRanges(pauseTimeRange, sealedTimeRanges)
}
