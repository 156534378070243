// Convenience methods, stolen from Maybe/Option based data types, designed to be used with the common ts pattern of
// unioning undefined
export class Optional {
  static exists2<A, B>(a: A | undefined, b: B | undefined, f: (a: A, b: B) => boolean): boolean {
    return !!a && !!b && f(a, b)
  }

  static map2<A, B, C>(a: A | undefined, b: B | undefined, f: (a: A, b: B) => C | undefined): C | undefined {
    if (!!a && !!b) {
      return f(a, b)
    }
    return undefined
  }

  static exists3<A, B, C>(
    a: A | undefined,
    b: B | undefined,
    c: C | undefined,
    f: (a: A, b: B, c: C) => boolean,
  ): boolean {
    return !!a && !!b && !!c && f(a, b, c)
  }
}
