import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BYPASS_403_REDIRECT } from '@ftr/api-core'
import { MissingCognitoUserError, SKIP_AUTHENTICATION_HEADER } from '@ftr/api-shared'
import { ErrorPageService, RouterActivityService } from '@ftr/foundation'
import { AuthenticationService, UnauthorizedEventsMonitorService } from '@ftr/ui-user'
import HttpStatus from 'http-status-codes'
import { EMPTY, Observable, catchError, throwError } from 'rxjs'
import { ConfigurationService } from '../configuration/configuration.service'

/**
 * Redirects the user to the login page when they receive an unauthorized response from a http call and
 * to the forbidden page when receiving a forbidden response. Currently applies to every http-client call.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthRedirectInterceptor implements HttpInterceptor {
  constructor(
    private readonly errorPageService: ErrorPageService,
    private readonly routerActivityService: RouterActivityService,
    private readonly unauthorizedEventsMonitorService: UnauthorizedEventsMonitorService,
    private readonly configurationService: ConfigurationService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Any request that is not the FTR/Regional/Court-Uploader API should just go through.
    const isApiReq = req.url.startsWith(this.configurationService.server.rawUrl)
    const regionalEndpoints = Object.values(this.configurationService.regionalApi.endpointMap)
    const courtUploaderServerEndpoints = Object.values(this.configurationService.courtUploaderServer.endpointMap)
    const digitalJusticeEndpoints = Object.values({
      ...this.configurationService.digitalJusticeApi.endpointMap,
      ...this.configurationService.digitalJusticeWeb.endpointMap,
    })
    const isRegionalReq = regionalEndpoints.some(ep => req.url.startsWith(ep))
    const isCourtUploaderServerReq = courtUploaderServerEndpoints.some(ep => req.url.startsWith(ep))
    const isDigitalJusticeReq = digitalJusticeEndpoints.some(ep => req.url.startsWith(ep))
    if (!isApiReq && !isRegionalReq && !isCourtUploaderServerReq && !isDigitalJusticeReq) {
      return next.handle(req)
    }

    // In order to properly handle unauthenticated routes, we use a skipAuthentication header.
    if (!req.headers.has(AuthenticationService.authHeader) && !req.headers.has(SKIP_AUTHENTICATION_HEADER)) {
      this.handleLogout(new MissingCognitoUserError())
      return EMPTY
    }

    if (req.headers.has(SKIP_AUTHENTICATION_HEADER) && req.headers.has(AuthenticationService.authHeader)) {
      req = req.clone({
        headers: req.headers.delete(AuthenticationService.authHeader).delete(SKIP_AUTHENTICATION_HEADER),
      })
    }

    return next.handle(req).pipe(
      catchError(err => {
        if (err.status === HttpStatus.UNAUTHORIZED || err instanceof MissingCognitoUserError) {
          this.handleLogout(err)
        } else if (err.status === HttpStatus.FORBIDDEN && !req.headers.has(BYPASS_403_REDIRECT)) {
          this.routerActivityService.waitForRedirect().then(() => {
            this.errorPageService.showForbiddenPage(err.error)
          })
        }
        return throwError(() => err)
      }),
    )
  }

  private handleLogout(err: Error): void {
    this.unauthorizedEventsMonitorService.intercept(err)
  }
}
