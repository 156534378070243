import { LocalDate, LocalDateTime, LocalTime, ZonedDateTime } from '@js-joda/core'
import { Moment } from 'moment-timezone'

export const MOMENT_DATE_CONVERSION_FORMAT = 'YYYY-MM-DD'
export const MOMENT_TIME_CONVERSION_FORMAT = 'HH:mm:ss'
export const MOMENT_DATETIME_CONVERSION_FORMAT = `${MOMENT_DATE_CONVERSION_FORMAT}T${MOMENT_TIME_CONVERSION_FORMAT}.SSS`

// Moment's default format is *almost* ISO 8601, EXCEPT missing decimal seconds (millis/nanos)
export const MOMENT_DATETIME_ZONED_CONVERSION_FORMAT = `${MOMENT_DATE_CONVERSION_FORMAT}T${MOMENT_TIME_CONVERSION_FORMAT}.SSSZ`

export function toLocalDate(moment: Moment): LocalDate {
  return LocalDate.parse(moment.format(MOMENT_DATE_CONVERSION_FORMAT))
}

export function toLocalTime(moment: Moment): LocalTime {
  return LocalTime.parse(moment.format(MOMENT_TIME_CONVERSION_FORMAT))
}

export function toLocalDateTime(moment: Moment): LocalDateTime {
  return LocalDateTime.parse(moment.format(MOMENT_DATETIME_CONVERSION_FORMAT))
}

export function toZonedDateTime(moment: Moment): ZonedDateTime {
  return ZonedDateTime.parse(moment.format(MOMENT_DATETIME_ZONED_CONVERSION_FORMAT))
}
