import { Component, Input } from '@angular/core'
import { UserState } from '@ftr/ui-user'
import { Store } from '@ngxs/store'
import { Subscription } from 'rxjs'

@Component({
  selector: 'ftr-help-platform-support',
  templateUrl: './help-platform-support.component.html',
  styleUrls: ['./help-platform-support.component.css'],
})
export class HelpPlatformSupportComponent {
  @Input() isLoggedIn: boolean

  subscription: Subscription
  readonly currentUser$ = this.store.select(UserState.user)

  constructor(private readonly store: Store) {}
}
