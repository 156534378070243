import { Component, EventEmitter, Input, Output } from '@angular/core'
import { RouterModule } from '@angular/router'
import { IconComponent } from '@ftr/foundation'
import { StaticMenuItem } from '../../static-menu-item'

@Component({
  standalone: true,
  imports: [RouterModule, IconComponent],
  selector: 'ftr-dropdown-menu-items',
  templateUrl: './dropdown-menu-items.component.html',
  styleUrls: ['./dropdown-menu-items.component.css'],
})
export class DropdownMenuItemsComponent {
  @Input() menuItems: StaticMenuItem[]
  @Input() routerLinkActiveExact: boolean = true
  @Output() hide = new EventEmitter()

  close(): void {
    this.hide.emit()
  }
}
