import { Injectable } from '@angular/core'
import { ApiClient, ApiClientFactory, serializeHttpParams } from '@ftr/api-shared'
import { RemoveSavedCardParams } from '@ftr/contracts/api/order-account'
import { Account, ListAccountsByUserIdParams, SavedCard } from '@ftr/contracts/read/order-account'
import { Uuid } from '@ftr/contracts/type/shared'
import { ApiResult, createAndAssign, mapData } from '@ftr/foundation'

@Injectable({
  providedIn: 'root',
})
export class OrderAccountService {
  private readonly apiClient: ApiClient

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClient = apiClientFactory.build('/order-account')
  }

  /**
   * Removes the saved card, but does not delete the card aggregate root as it may still be required for re-auth.
   */
  removeSavedCard(params: RemoveSavedCardParams): ApiResult {
    return this.apiClient.delete({
      path: `/${params.accountId}/removeSavedCard/${params.savedCardId}`,
    })
  }

  listSavedCardsByUserId(userId: Uuid): ApiResult<SavedCard[]> {
    return this.apiClient
      .get<Account[]>({
        path: '/listByUserId',
        params: serializeHttpParams(createAndAssign(ListAccountsByUserIdParams, { userId })),
      })
      .pipe(mapData(data => data.flatMap(account => account.savedCards)))
  }
}
