/* eslint-disable max-classes-per-file */
import { Uuid } from '@ftr/contracts/type/shared'
import { CourtSystemMfaRequirementsStateModel } from './mfa-requirements.model'

export class SetCourtSystemMfaRequirementsStateAction {
  static readonly type = '[MfaRequirements] Set Court System MFA Requirements State'

  constructor(
    readonly courtSystemId: Uuid,
    readonly state: CourtSystemMfaRequirementsStateModel,
  ) {}
}

export class FetchCourtSystemMfaRequirementsAction {
  static readonly type = '[MfaRequirements] Fetch Court System MFA Requirements'

  constructor(
    readonly courtSystemId: Uuid,
    readonly bypassCache?: boolean,
  ) {}
}
