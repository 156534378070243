import { Injectable } from '@angular/core'
import { ConfigurationService } from '~app/services/configuration/configuration.service'
import { WindowRefService } from '~app/services/window/window-ref.service'

@Injectable()
export class DeepLinkService {
  constructor(
    private readonly windowRefService: WindowRefService,
    private readonly configurationService: ConfigurationService,
  ) {}

  openDeepLink(url?: string | undefined): void {
    const targetUrl = url ?? this.windowRefService.location().href

    this.windowRefService.open(targetUrl.replace('https', this.configurationService.desktopUriScheme), '_self')
  }
}
