import { AsyncPipe, NgClass } from '@angular/common'
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit } from '@angular/core'
import { Location } from '@ftr/contracts/api/location'
import { VocabularyTerms } from '@ftr/contracts/type/core'
import { IconComponent } from '@ftr/foundation'
import { Observable, map } from 'rxjs'
import { ToggleableRootLocation } from './toggleable-root-location'

const MAX_LOCATIONS_SHOWN = 100

@Component({
  selector: 'ftr-location-select-list',
  templateUrl: './location-select-list.component.html',
  styleUrls: ['./location-select-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, IconComponent, NgClass],
  standalone: true,
})
export class LocationSelectListComponent implements OnInit {
  @Input() locations: Observable<ToggleableRootLocation[]>
  @Input() selectLocationEmitter: EventEmitter<Location>
  @Input() selected: Location
  @Input() terms: VocabularyTerms

  limitedLocations: Observable<ToggleableRootLocation[]>
  maxResults = MAX_LOCATIONS_SHOWN

  ngOnInit(): void {
    this.limitedLocations = this.locations.pipe(map(l => l.slice(0, MAX_LOCATIONS_SHOWN)))
  }

  selectLocation(value: Location): void {
    this.selectLocationEmitter.emit(value)
  }

  isSelected(location: Location): boolean {
    return this.selected && this.selected.id === location.id
  }

  isMaxResults(locations: ToggleableRootLocation[]): boolean {
    return locations && locations.length === MAX_LOCATIONS_SHOWN
  }
}
