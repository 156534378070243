import { animate, style, transition, trigger } from '@angular/animations'
import { CommonModule } from '@angular/common'
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { RouterModule } from '@angular/router'
import { IconComponent, SizesService } from '@ftr/foundation'
import { DropdownMenuComponent, StaticMenuItem } from '@ftr/menus'
import { BehaviorSubject, Observable, combineLatest, fromEvent, map, shareReplay, startWith } from 'rxjs'
import { MainNavMenuItemsProvider } from '~app/services/navigation/main-nav-menu-items.provider'
import { WindowRefService } from '~app/services/window/window-ref.service'

// If you change any styles relevant to this component you may need to adjust these values
const AVERAGE_CHARACTER_WIDTH = 8.4375
const MENU_ITEM_PADDING = 24
const OVERFLOW_LABEL_WIDTH = 88

const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]),
  transition(':leave', [style({ opacity: 1 }), animate('300ms', style({ opacity: 0 }))]),
])

/**
 * @deprecated. Scheduled for removal post nav release
 */
@Component({
  selector: 'ftr-legacy-overflow-menu',
  templateUrl: './legacy-overflow-menu.component.html',
  styleUrls: ['./legacy-overflow-menu.component.css'],
  animations: [fadeAnimation],
  standalone: true,
  imports: [CommonModule, DropdownMenuComponent, IconComponent, RouterModule],
})
export class LegacyOverflowMenuComponent implements OnInit {
  @ViewChild('overflowMenu', { static: true }) overflowMenuElement: ElementRef<HTMLDivElement>
  @ViewChild(DropdownMenuComponent) dropdown: DropdownMenuComponent

  private readonly dropdownItems = new BehaviorSubject<StaticMenuItem[]>([])
  readonly dropdownItems$ = this.dropdownItems.asObservable()

  menuItems$: Observable<StaticMenuItem[]>

  constructor(
    private readonly windowRefService: WindowRefService,
    private readonly navItemProvider: MainNavMenuItemsProvider,
    private readonly headerSizeService: SizesService,
  ) {}

  ngOnInit(): void {
    this.menuItems$ = combineLatest([
      this.navItemProvider.provide(),
      fromEvent(this.windowRefService.nativeWindow(), 'resize').pipe(startWith(undefined)),
      this.headerSizeService.headerIsRendered,
    ]).pipe(
      map(([navItems]) => this.sortNavItems(navItems)),
      shareReplay(1),
    )
  }

  sortNavItems(navItems: StaticMenuItem[]): StaticMenuItem[] {
    const menuItems: StaticMenuItem[] = []
    const dropdownItems: StaticMenuItem[] = []

    // Calculate the available and estimated required widths
    const requiredWidth = calculateRequiredWidth(navItems)
    const availableWidth = this.overflowMenuElement.nativeElement.clientWidth

    if (requiredWidth > availableWidth) {
      // Knowing the width of the 'More' label we can specify a limit on
      // the widths of the labels that can be displayed across the top
      const targetWidth = availableWidth - OVERFLOW_LABEL_WIDTH

      // Counter to make sure we don't go over
      let cumulativeWidth = 0

      // For each item we were given
      for (const item of navItems) {
        // See how much space the label will take up
        const itemWidth = calculateEstimatedLabelWidth(item.title)

        if (cumulativeWidth + itemWidth < targetWidth) {
          // If there's room, it goes across the top
          menuItems.push(item)
        } else {
          // Otherwise it goes in the drop-down list
          dropdownItems.push(item)
        }

        // Increment the counter by the width of the label
        cumulativeWidth += itemWidth
      }
    } else {
      // If we have more space than we need just display the menu items
      menuItems.push(...navItems)
    }

    // Feed the drop-down menu items into an observable for the list
    this.dropdownItems.next(dropdownItems)

    return menuItems
  }

  toggle(): void {
    this.dropdown.toggle()
  }
}

function calculateEstimatedLabelWidth(label: string): number {
  const chars = label.length
  return chars * AVERAGE_CHARACTER_WIDTH + MENU_ITEM_PADDING
}

function calculateRequiredWidth(navItems: StaticMenuItem[]): number {
  return navItems.map(navItem => calculateEstimatedLabelWidth(navItem.title)).reduce((a, b) => a + b)
}
