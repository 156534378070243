import { Component, Input, OnInit } from '@angular/core'
import { UserState } from '@ftr/ui-user'
import { Store } from '@ngxs/store'

/**
 * This component must be available for any users, logged in or not, that's why we only resolve
 * `isLoggedIn` and load its children only if the user is logged in to show additional information
 * Every `help-link` should have a class which will be used in Elevio admin to assign an article to it.
 * Ftr-web has no knowledge of what articles to use, they are managed via Elevio settings: https://app.elev.io/helpers
 */
@Component({
  selector: 'ftr-help-ordering-information',
  templateUrl: './help-ordering-information.component.html',
  styleUrls: ['./help-ordering-information.component.css'],
})
export class HelpOrderingInformationComponent implements OnInit {
  @Input() isLoggedIn: boolean

  showOrderingInformation = true

  constructor(private readonly store: Store) {}

  ngOnInit(): void {
    this.store.select(UserState.currentCourtSystem).subscribe(courtSystem => {
      // ignore publicOrderingEnabled, so ordering information can be reviewed before going public with orders
      this.showOrderingInformation = !courtSystem || courtSystem?.isPublished
    })
  }
}
