/* eslint-disable max-classes-per-file */
import { KeyPress } from './key-press.model'

export class SetKeyPress {
  static readonly type = '[Keyboard Shortcut] Keyboard shortcut pressed'
  constructor(public keyPress: KeyPress | undefined) {}
}

export class SetKeyPressReleased {
  static readonly type = '[Keyboard Shortcut] Keyboard shortcut released'
}
