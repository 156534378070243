import { Inject, Injectable } from '@angular/core'
import {
  API_CONFIGURATION,
  ApiClient,
  ApiClientType,
  ApiErrorMapper,
  FullApiClientConfiguration,
  HttpClientRemoteData,
} from '@ftr/api-shared'
import { CourtSystemRegionCache } from '@ftr/ui-court-system'
import { Observable, from, map } from 'rxjs'

/**
 * Create a client service for ftr-dj-api for a given controller path & courtSystemId
 */
@Injectable({
  providedIn: 'root',
})
export class DigitalJusticeApiClientFactory {
  constructor(
    private readonly http: HttpClientRemoteData,
    @Inject(API_CONFIGURATION) readonly configurationService: FullApiClientConfiguration,
    private readonly apiErrorMapper: ApiErrorMapper,
    private readonly courtSystemRegionCache: CourtSystemRegionCache,
  ) {}

  build(
    path: string,
    courtSystemId: string,
    region?: string,
    httpService: HttpClientRemoteData = this.http,
  ): Observable<ApiClient> {
    if (!region) {
      const courtSystem$ = this.courtSystemRegionCache.getOrFetchCourtSystem(from([courtSystemId]))
      return courtSystem$.pipe(
        map(courtSystem => {
          return this.buildApiClient(httpService, path, courtSystem.region)
        }),
      )
    }
    return from([this.buildApiClient(httpService, path, region)])
  }

  private buildApiClient(httpService: HttpClientRemoteData, path: string, region: string): ApiClient {
    return new ApiClient(
      httpService,
      this.configurationService,
      this.apiErrorMapper,
      { clientType: ApiClientType.digitalJustice, region },
      `/${path.replace(/^\//, '')}`,
    )
  }

  getCourtSystemRegion(courtSystemId: string): Observable<string> {
    const courtSystem$ = this.courtSystemRegionCache.getOrFetchCourtSystem(from([courtSystemId]))

    return courtSystem$.pipe(map(courtSystem => courtSystem.region))
  }

  getWebEndpoint(courtSystemId: string): Observable<string> {
    const courtSystem$ = this.courtSystemRegionCache.getOrFetchCourtSystem(from([courtSystemId]))

    return courtSystem$.pipe(
      map(courtSystem => this.configurationService.digitalJusticeWeb.endpointMap[courtSystem.region]),
    )
  }
}
