import { Injectable } from '@angular/core'
import { ApiClient, ApiClientFactory, serializeHttpParams } from '@ftr/api-shared'
import { UserGroup, UserGroupPermissionId } from '@ftr/contracts/api/user-group'
import { UserGetGroupsParams } from '@ftr/contracts/read'
import { Uuid } from '@ftr/contracts/type/shared'
import { ApiResult, unwrapData } from '@ftr/foundation'
import { Observable, map } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class UserPermissionService {
  private readonly userApiClient: ApiClient

  constructor(private apiClientFactory: ApiClientFactory) {
    this.userApiClient = this.apiClientFactory.build('/user')
  }

  fetchCurrentUserGroups(): ApiResult<UserGroup[]> {
    return this.userApiClient.get({ path: '/groups' })
  }

  fetchCurrentUserGroupsForCourtSystem(courtSystemId: Uuid): ApiResult<UserGroup[]> {
    const queryParameters: UserGetGroupsParams = {
      courtSystemId,
    }
    return this.userApiClient.get({ path: '/groups', params: serializeHttpParams(queryParameters) })
  }

  hasOnlyPermissionInCourtSystem(courtSystemId: Uuid, permissionId: UserGroupPermissionId): Observable<boolean> {
    return this.fetchCurrentUserGroupsForCourtSystem(courtSystemId).pipe(
      unwrapData(),
      map(userGroups => {
        return !userGroups
          .flatMap(userGroup => userGroup.permissions)
          .filter(permission => permission.isEnabled)
          .some(permission => permission.id !== permissionId)
      }),
    )
  }
}
