import { Component, input } from '@angular/core'
import { Breadcrumb } from './breadcrumb'

@Component({
  selector: 'ftr-breadcrumbs-dropdown-display',
  templateUrl: 'breadcrumbs-dropdown-display.component.html',
  styleUrls: ['breadcrumbs-display.component.css'],
})
export class BreadcrumbsDropdownDisplayComponent {
  collapsed = input.required<Breadcrumb[]>()
}
