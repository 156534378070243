const swallowKeyPress = (fn: (event: Event) => void) => {
  return (event: Event) => {
    event.preventDefault()
    event.stopPropagation()

    fn(event)
    return false
  }
}

const getCardsAndCurrentPlacement = (
  commonSelector: string,
  currentCard: EventTarget | null,
): {
  previousCards: HTMLElement[]
  currentCard: HTMLElement
  nextCards: HTMLElement[]
} => {
  const cards = Array.from(document.querySelectorAll<HTMLElement>(commonSelector))
  const currentCardIndex = cards.findIndex((card: HTMLElement) => card === currentCard)

  return {
    previousCards: cards.slice(0, currentCardIndex),
    currentCard: currentCard as HTMLElement,
    nextCards: cards.slice(currentCardIndex + 1),
  }
}

const findCardAbove = (targetCard: HTMLElement, searchingCards: HTMLElement[]): HTMLElement | null => {
  const targetCardBounds = targetCard.getBoundingClientRect()

  if (searchingCards.length) {
    const [nextCard, ...nextSearch] = searchingCards
    if (nextCard) {
      const nextCardBounds = nextCard.getBoundingClientRect()

      // Search up, and to the left
      if (nextCardBounds.top < targetCardBounds.top && nextCardBounds.left <= targetCardBounds.left) {
        return nextCard
      }
    }
    return findCardAbove(targetCard, nextSearch)
  }

  return null
}

const findCardBelow = (targetCard: HTMLElement, searchingCards: HTMLElement[]): HTMLElement | null => {
  const targetCardBounds = targetCard.getBoundingClientRect()

  if (searchingCards.length) {
    const [nextCard, ...nextSearch] = searchingCards
    if (nextCard) {
      const nextCardBounds = nextCard.getBoundingClientRect()

      // Make sure its lower than the current target
      if (nextCardBounds.top > targetCardBounds.top) {
        // if its in the same column, then select it
        if (nextCardBounds.left >= targetCardBounds.left) {
          return nextCard
        }

        // And if there are no more cards on this row, select it
        if (nextSearch.at(0)?.getBoundingClientRect().top !== nextCardBounds.top) {
          return nextCard
        }
      }
    }

    return findCardBelow(targetCard, nextSearch)
  }

  return null
}

export abstract class KeyboardNavigationCard {
  abstract commonSelector: string

  setFocusToPrevious(event: Event): void {
    const { previousCards } = KeyboardNavigationCard.getCardsAndCurrentPlacement(this.commonSelector, event.target)
    const focusCard = previousCards.at(previousCards.length - 1)

    if (focusCard) {
      focusCard.focus()
    }
  }

  setFocusToNext(event: Event): void {
    const { nextCards } = KeyboardNavigationCard.getCardsAndCurrentPlacement(this.commonSelector, event.target)
    const focusCard = nextCards.at(0)

    if (focusCard) {
      focusCard.focus()
    }
  }

  setFocusToAbove = KeyboardNavigationCard.swallowKeyPress((event: Event) => {
    const { currentCard, previousCards } = KeyboardNavigationCard.getCardsAndCurrentPlacement(
      this.commonSelector,
      event.target,
    )
    const focusCard = KeyboardNavigationCard.findCardAbove(currentCard, previousCards.reverse())

    if (focusCard) {
      focusCard.focus()
    }
  })

  setFocusToBelow = KeyboardNavigationCard.swallowKeyPress((event: Event) => {
    const { currentCard, nextCards } = KeyboardNavigationCard.getCardsAndCurrentPlacement(
      this.commonSelector,
      event.target,
    )
    const focusCard = KeyboardNavigationCard.findCardBelow(currentCard, nextCards)

    if (focusCard) {
      focusCard.focus()
    }
  })

  private static swallowKeyPress = swallowKeyPress

  private static getCardsAndCurrentPlacement = getCardsAndCurrentPlacement

  private static findCardAbove = findCardAbove

  private static findCardBelow = findCardBelow
}
