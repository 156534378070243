<div>
  <ng-template #openerTemplate>
    <div (click)="toggle()" class="account-menu">
      <ftr-icon align="Bottom" icon="AccountCircle"></ftr-icon>
      @if (isXLarge) {
        <span class="account-menu__name" [attr.aria-label]="'Account Menu for ' + (userFullName$ | async)">{{
          userFullName$ | async
        }}</span>
      }
      <ftr-icon align="Bottom" icon="KeyboardArrowDown"></ftr-icon>
    </div>
  </ng-template>

  @if (!isMobile) {
    <ftr-dropdown-menu
      ariaLabel="Account dropdown menu"
      [menuItems]="accountMenuItems$"
      [openerTemplate]="openerTemplate"
      data-track-group="Account Dropdown"
    >
    </ftr-dropdown-menu>
  }

  @if (isMobile) {
    <ftr-side-nav
      [headerTemplate]="accountTemplate"
      [navItems]="accountMenuItems$"
      [openerTemplate]="openerTemplate"
      data-track-group="Account Side Nav"
      openFrom="right"
    ></ftr-side-nav>
  }

  <ng-template #accountTemplate>
    <a (click)="toggle()" [routerLink]="['/account']" class="account-menu__account">
      <ftr-icon gapRight="Regular" align="Bottom" color="Primary" icon="AccountCircle"></ftr-icon>
      {{ userFullName$ | async }}
    </a>
  </ng-template>
</div>
