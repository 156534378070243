import { NgClass } from '@angular/common'
import { Component, input } from '@angular/core'
import { OnTheRecordTimesConflicts } from '@ftr/contracts/regional-api'
import { VocabularyTerms } from '@ftr/contracts/type/core/VocabularyTerms'
import { LocalTimeRange } from '@ftr/contracts/type/shared'
import { CapitalizeFirstLetterPipe, DatePipe, NotificationComponent, NotificationType } from '@ftr/foundation'
import { RealTimeSessionsConflictsItemComponent } from './items/real-time-sessions-conflicts-item.component'

@Component({
  standalone: true,
  selector: 'ftr-real-time-sessions-conflicts-modal',
  templateUrl: './real-time-sessions-conflicts-modal.component.html',
  styleUrls: ['./real-time-sessions-conflicts-modal.component.css'],
  imports: [
    DatePipe,
    NgClass,
    NotificationComponent,
    CapitalizeFirstLetterPipe,
    RealTimeSessionsConflictsItemComponent,
  ],
})
export class RealTimeSessionsConflictsModalComponent {
  readonly title = input.required<string>()

  readonly timeRange = input.required<LocalTimeRange>()
  readonly conflicts = input.required<OnTheRecordTimesConflicts>()
  readonly hasPreviousConflictResolutionTokenExpired = input.required<boolean>()
  readonly terms = input.required<VocabularyTerms>()

  protected readonly notificationType = NotificationType
}
