import { Injectable } from '@angular/core'
import { Feature } from '@ftr/contracts/api/feature'
import { UserGroupPermissionId } from '@ftr/contracts/api/user-group'
import { isNotNullOrUndefined, unwrapData } from '@ftr/foundation'
import { StaticMenuItem } from '@ftr/menus'
import { CourtSystemNavItems, GlobalNavItems, NavItemsProvider } from '@ftr/ui-core'
import { UserState } from '@ftr/ui-user'
import { VocabularyState } from '@ftr/ui-vocab'
import { Store } from '@ngxs/store'
import { EMPTY, Observable, combineLatest, filter, map, of, switchMap } from 'rxjs'
import { GlobalAdminNavItems } from './global-admin-nav-items'

@Injectable({
  providedIn: 'root',
})
export class CourtDropdownMenuItemsProvider implements NavItemsProvider {
  constructor(
    private navItems: CourtSystemNavItems,
    private globalNavItems: GlobalNavItems,
    private globalAdminNavItems: GlobalAdminNavItems,
    private store: Store,
  ) {}

  provide(): Observable<StaticMenuItem[]> {
    const currentCourtSystem$ = this.store.select(UserState.currentCourtSystem).pipe(filter(isNotNullOrUndefined))

    return combineLatest([
      this.store.select(UserState.currentUserGroups),
      currentCourtSystem$,
      this.store.select(UserState.permissionsInCurrentCourtSystem),
      currentCourtSystem$.pipe(
        switchMap(courtSystem =>
          this.store.select(VocabularyState.termsByCourtSystemId).pipe(
            map(fn => fn(courtSystem.id)),
            filter(isNotNullOrUndefined),
          ),
        ),
      ),
      this.store.select(UserState.isGlobalAdmin),
      this.store.select(UserState.currentCourtSystemEnabledFeatures).pipe(unwrapData()),
    ]).pipe(
      switchMap(
        ([
          userGroups,
          currentCourtSystem,
          permissionsInCurrentCourtSystem,
          vocabularyTerms,
          isGlobalAdmin,
          courtSystemFeatures,
        ]) => {
          if (currentCourtSystem && currentCourtSystem.id) {
            const adminMenuItem = this.navItems.adminMenu(currentCourtSystem.id)
            adminMenuItem.children = [
              this.navItems.userGroups(currentCourtSystem.id),
              this.navItems.courtUsers(currentCourtSystem.id),
              this.navItems.recordingVault(currentCourtSystem.id),
              this.navItems.producers(currentCourtSystem.id),
              this.navItems.courthouses(currentCourtSystem.id, vocabularyTerms),
              this.navItems.holidays(currentCourtSystem.id),
              this.navItems.removeFiles(currentCourtSystem.id),
              this.navItems.billingGroups(currentCourtSystem.id),
            ]
              .filter(item => this.checkFeatures(item, courtSystemFeatures))
              .filter(item => this.checkPermissions(item, permissionsInCurrentCourtSystem))

            const menuItems = [adminMenuItem, this.navItems.reports(currentCourtSystem.id)].filter(item =>
              this.checkPermissions(item, permissionsInCurrentCourtSystem),
            )

            // Need to filter out the duplicate court systems.
            const courtSystems = new Set(userGroups.data?.map(s => s.courtSystemId))

            if (courtSystems.size > 1) {
              menuItems.push(this.globalNavItems.selectCourtSystem())
            }

            if (isGlobalAdmin) {
              menuItems.push(this.globalAdminNavItems.globalAdminForCourtSystem(currentCourtSystem.id))
            }

            return of(menuItems)
          } else {
            return EMPTY
          }
        },
      ),
    )
  }

  checkFeatures(item: StaticMenuItem, courtSystemFeatures: Feature[] | undefined): boolean | undefined {
    return (
      courtSystemFeatures &&
      (!item.features ||
        item.features?.length === 0 ||
        item.features?.some(name => courtSystemFeatures.some(feature => feature.name === name)))
    )
  }

  checkPermissions(
    item: StaticMenuItem,
    permissionsInCurrentCourtSystem?: UserGroupPermissionId[],
  ): boolean | undefined {
    return (
      permissionsInCurrentCourtSystem &&
      (!item.permissions ||
        item.permissions?.length === 0 ||
        item.permissions?.map(permission => permissionsInCurrentCourtSystem.includes(permission)).includes(true))
    )
  }
}
