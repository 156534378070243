import { Component, Input, OnInit } from '@angular/core'
import { BadgeColors, BadgeComponent } from '@ftr/foundation'

@Component({
  selector: 'ftr-mfa-status',
  templateUrl: './mfa-status.component.html',
  standalone: true,
  imports: [BadgeComponent],
})
export class MfaStatusComponent implements OnInit {
  @Input() isMfaEnabled: boolean | undefined

  mfaStatus: string
  badgeColor: BadgeColors

  ngOnInit(): void {
    if (this.isMfaEnabled === undefined) {
      this.mfaStatus = 'Not Applicable'
      this.badgeColor = BadgeColors.Grey20
    } else if (this.isMfaEnabled) {
      this.mfaStatus = 'Enabled'
      this.badgeColor = BadgeColors.Apple
    } else {
      this.mfaStatus = 'Disabled'
      this.badgeColor = BadgeColors.Watermelon
    }
  }
}
