<div
  class="select input"
  [ngClass]="{
    'input--highlight': highlightError | async,
    'select--has-value': internalControl.value !== undefined || internalControl.value !== null,
    'select--menu-open': isOpen,
    'select--display': !isOpen,
    'select--disabled': !enabled,
    'select--invalid': !internalControl.valid,
    'input--invalid': !internalControl.valid,
    'select--touched': internalControl.touched,
    'input--touched': internalControl.touched,
    'select--hide-label': !!hideLabel,
    'select--search-type': searchType,
  }"
  (clickOutside)="close()"
  [openState]="isOpen"
>
  <div class="select__value-container">
    <div
      [tabIndex]="0"
      class="select__button"
      aria-haspopup="listbox"
      [attr.aria-expanded]="isOpen"
      (click)="open()"
      (focusin)="open()"
    >
      Select {{ singular }}
    </div>
    <div class="select__value">
      {{ selectedLabel }}
    </div>
    <label class="select__label">{{ label }}</label>
    <span class="select__bar input__bar"></span>
    <ftr-icon class="select__arrow select__arrow--right" icon="KeyboardArrowRight"></ftr-icon>
    @if (!searchType) {
      <ftr-icon class="select__arrow select__arrow--down" icon="ArrowDropDown"></ftr-icon>
    }
    @if (searchType) {
      <ftr-icon class="select__arrow select__arrow--down" icon="KeyboardArrowDown"></ftr-icon>
    }
    <span
      class="input__validation select__validation"
      ftrValidationErrorHint
      [label]="label"
      [ngFormControl]="internalControl"
      [controlTouched]="internalControl.touched"
      [submitAttempted]="submitAttempted"
    ></span>
  </div>
  @if (items$ | async; as items) {
    <div
      class="select__open-menu-container"
      role="listbox"
      [attr.aria-required]="required"
      [attr.aria-labelledby]="id + '-label'"
      [attr.aria-activedescendant]="getActiveDescendant(items)"
    >
      <div class="select__input-container">
        <button type="button" ftrButton displayType="icon" class="select__close" (click)="close()">
          <ftr-icon icon="KeyboardArrowLeft"></ftr-icon>
        </button>
        <span class="select__opened-label" [id]="id + '-label'">Select {{ singular }}</span>
      </div>
      <div class="select__items" [id]="id">
        @for (item of items; track item; let i = $index) {
          <div
            class="select__item"
            [tabIndex]="0"
            [ngClass]="{
              'select__item--selected': item.value === internalControl.value,
            }"
            role="option"
            [id]="id + '-item-' + i"
            [attr.aria-selected]="item.value === internalControl.value"
            (click)="setValue(item.value)"
          >
            {{ item.key }}
          </div>
        }
      </div>
    </div>
  }
</div>
