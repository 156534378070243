import { LowerCasePipe } from '@angular/common'
import { Component, EventEmitter, Input, OnInit, Output, TrackByFunction } from '@angular/core'
import { Uuid } from '@ftr/contracts/type/shared/Uuid'
import { IconComponent, PillComponent, TrackingAction, TrackingEventType, TrackingService } from '@ftr/foundation'

export interface PillModel {
  id: Uuid
  label: string
  tooltip: string
}

@Component({
  selector: 'ftr-pillset',
  templateUrl: './pillset.component.html',
  styleUrls: ['./pillset.component.css'],
  imports: [IconComponent, LowerCasePipe, PillComponent],
  standalone: true,
})
export class PillsetComponent implements OnInit {
  @Input()
  pills: PillModel[]

  @Input()
  title: string

  @Input()
  maxPills: number

  @Input()
  analyticType: TrackingEventType

  @Input()
  pluralNoun: string

  @Output()
  remove: EventEmitter<Uuid> = new EventEmitter<Uuid>()

  @Output()
  clearAll: EventEmitter<void> = new EventEmitter<void>()

  countToShow: number

  constructor(readonly analyticsService: TrackingService) {}

  ngOnInit(): void {
    this.countToShow = this.maxPills
  }

  trackBy: TrackByFunction<PillModel> = (_index: number, pill: PillModel) => {
    return pill.id
  }

  removePill(id: Uuid): void {
    this.remove.emit(id)
    this.analyticsService.track({
      action: TrackingAction.Click,
      actionDescriptor: 'Pills - Remove Item',
      event: this.analyticType,
    })
  }

  showAllPills(): void {
    this.countToShow = this.pills.length
    this.analyticsService.track({
      action: TrackingAction.Click,
      actionDescriptor: 'Pills - Show All',
      event: this.analyticType,
    })
  }

  clearAllPills(): void {
    this.analyticsService.track({
      action: TrackingAction.Click,
      actionDescriptor: 'Pills - Clear All',
      event: this.analyticType,
    })
    this.clearAll.emit()
  }

  shouldShowMorePillsButton(): boolean {
    return this.pills && this.countToShow === this.maxPills && this.pills.length > this.maxPills
  }
}
