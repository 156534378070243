import { TitleCasePipe } from '@angular/common'
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit } from '@angular/core'
import { AbstractControl } from '@angular/forms'
import { Location } from '@ftr/contracts/api/location'
import { VocabularyTerms } from '@ftr/contracts/type/core'
import { SelectSearchComponent } from '@ftr/forms'
import { titleCase } from '@ftr/foundation'
import { Observable } from 'rxjs'
import { LocationSelectListComponent } from './location-select-list.component'
import { LocationViewModel } from './location-view-model'

@Component({
  selector: 'ftr-location-select',
  templateUrl: './location-select.component.html',
  styleUrls: ['./location-select.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [LocationSelectListComponent, TitleCasePipe, SelectSearchComponent],
  standalone: true,
})
export class LocationSelectComponent implements OnInit {
  @Input() control: AbstractControl
  @Input() locations: Observable<Location[]>
  @Input() enabled = true
  @Input() highlightError: Observable<boolean>
  @Input() terms: VocabularyTerms
  selectLocationEmitter = new EventEmitter<Location>()
  instructions: string

  ngOnInit(): void {
    if (this.terms) {
      this.instructions = `(${titleCase(this.terms.courthouse?.singular)}) ${titleCase(this.terms.courtroom?.singular)}`
    }
  }

  filterLocations(courthouses: Location[], locationSearch: string): Location[] {
    return courthouses.filter(courthouse => {
      const children = courthouse.children as LocationViewModel[] | undefined
      children?.forEach(child => {
        child.isShowing =
          !locationSearch ||
          filter(`${courthouse.name.toLowerCase()} ${child.name.toLowerCase()}`, locationSearch.toLowerCase())
      })
      return (
        children?.some(child => child.isShowing) ||
        filter(`${courthouse.name.toLowerCase()}`, locationSearch.toLowerCase())
      )
    })
  }

  selectItem(item: Location): Location {
    return item.children?.find(child => (child as LocationViewModel).isShowing)!
  }
}

function filter(subject: string, filterTerms: string): boolean {
  const terms = filterTerms.split(' ')
  return terms.every(term => subject.includes(term))
}
