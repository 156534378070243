import { Uuid } from '@ftr/contracts/type/shared'
import { matchSttContext, SttContext } from './SttContext'

/*
 * This is used by the Recording Playback Page.
 * We are listen to socket events and query data based on the RecordingId.
 * We use the TranscriptId to differentiate between sessions when
 * the GET TEXT button is clicked.
 */

export interface RecordingSttContext {
  type: 'recording'
  recordingId: Uuid
  hasStt: boolean
}

export function buildRecordingSttContext(recordingId: Uuid, hasStt: boolean): RecordingSttContext {
  return {
    type: 'recording',
    recordingId,
    hasStt,
  }
}

export const recordingSttContextEquals =
  (other: SttContext) =>
  (self: RecordingSttContext): boolean =>
    matchSttContext(other, {
      mapAudioOrder: () => false,
      mapRecording: ({ recordingId }) => recordingId === self.recordingId,
      mapSharedRecording: () => false,
    })
