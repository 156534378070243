import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { AppPaths } from '@ftr/routing-paths'
import { MultiFactorAuthenticationModule } from '~app/features/multifactor-authentication/multifactor-authentication.module'
import { MfaChallengePage } from '~app/pages/multifactor-authentication/challenge/mfa-challenge'
import { MfaRecoverAccountPage } from '~app/pages/multifactor-authentication/recover-account/mfa-recover-account.page'

@NgModule({
  imports: [
    MultiFactorAuthenticationModule,
    RouterModule.forChild([
      {
        path: AppPaths.MultiFactorAuthentication,
        children: [
          {
            path: '',
            component: MfaChallengePage,
          },
          {
            path: 'recover-account',
            component: MfaRecoverAccountPage,
          },
        ],
      },
    ]),
  ],
  declarations: [MfaRecoverAccountPage, MfaChallengePage],
})
export class MultifactorAuthenticationPagesModule {}
