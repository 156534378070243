import { Injectable } from '@angular/core'
import { ApiClient, ApiClientFactory, serializeHttpParams } from '@ftr/api-shared'
import { PageDetails, PagedQuery } from '@ftr/contracts/api/core'
import { PagedResult } from '@ftr/contracts/api/core/response/PagedResult'
import { CourtSystemUser } from '@ftr/contracts/api/court-system'
import {
  GetUserGroupsQuery,
  PostUserGroupBody,
  PutUserGroupBody,
  UserGroup,
  UserGroupAddMembers,
  UserGroupAddMembersResponse,
  UserGroupPermissionId,
  UserGroupRemoveMembers,
} from '@ftr/contracts/api/user-group'
import { Uuid } from '@ftr/contracts/type/shared'
import { ApiResult, createAndAssign } from '@ftr/foundation'
import { classToPlain } from '@ftr/serialization'

const USER_GROUP_LIST_SIZE = 100

@Injectable()
export class UserGroupService<T extends UserGroup = UserGroup> {
  private readonly apiClient: ApiClient

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClient = apiClientFactory.build(this.getPath())
  }

  add(postUserGroupBody: PostUserGroupBody): ApiResult<T> {
    const body = classToPlain(postUserGroupBody)
    return this.apiClient.post({
      body,
    })
  }

  update(putUserGroupBody: PutUserGroupBody): ApiResult<T> {
    const body = classToPlain(putUserGroupBody)
    return this.apiClient.put({
      path: `${putUserGroupBody.id}`,
      body,
    })
  }

  listUserGroups(
    courtSystemId: Uuid,
    isEnabled: boolean | undefined,
    page: number,
    pageSize = USER_GROUP_LIST_SIZE,
  ): ApiResult<PagedResult<UserGroup>> {
    return this.apiClient.get({
      params: serializeHttpParams(
        createAndAssign(GetUserGroupsQuery, {
          courtSystemId,
          isEnabled,
          page: new PageDetails(page, pageSize),
        }),
      ),
    })
  }

  get(userGroupId: Uuid): ApiResult<T> {
    return this.apiClient.get({ path: userGroupId })
  }

  listMembers(userGroupId: Uuid, page: number): ApiResult<PagedResult<CourtSystemUser>> {
    return this.apiClient.get({
      path: `${userGroupId}/listMembers`,
      params: serializeHttpParams(new PagedQuery(new PageDetails(page, USER_GROUP_LIST_SIZE))),
    })
  }

  addMembers(userGroupId: Uuid, userGroupAddMembers: UserGroupAddMembers): ApiResult<UserGroupAddMembersResponse> {
    const body = classToPlain(userGroupAddMembers)
    return this.apiClient.post({
      path: `${userGroupId}/addMembers`,
      body,
    })
  }

  removeMembers(userGroupId: Uuid, userGroupRemoveMembers: UserGroupRemoveMembers): ApiResult<T> {
    const body = classToPlain(userGroupRemoveMembers)
    return this.apiClient.post({
      path: `${userGroupId}/removeMembers`,
      body,
    })
  }

  delete(userGroupId: Uuid): ApiResult<undefined> {
    return this.apiClient.delete({ path: userGroupId })
  }

  hasPermission(userGroup: UserGroup, permissionId: UserGroupPermissionId): boolean {
    return userGroup.permissions.some(permission => permission.id === permissionId)
  }

  protected getPath(): string {
    return '/user-group'
  }
}
