import { Injectable } from '@angular/core'
import { ApiClient, ApiClientFactory, serializeHttpParams } from '@ftr/api-shared'
import {
  CreateFavorite,
  Favorite,
  GetFavoritesQuery,
  GetFavoritesResponse,
  UpdateFavorite,
  UpdateFavoritesBody,
} from '@ftr/contracts/api/favorites'
import { Uuid } from '@ftr/contracts/type/shared'
import { ApiResult, createAndAssign } from '@ftr/foundation'

@Injectable({
  providedIn: 'root',
})
export class FavoritesService {
  private favouritesApiClient: ApiClient

  constructor(private readonly apiClientFactory: ApiClientFactory) {
    this.favouritesApiClient = apiClientFactory.build('/favorites')
  }

  listFavourites(userId: Uuid, courtSystemId: Uuid): ApiResult<GetFavoritesResponse> {
    return this.favouritesApiClient.get<GetFavoritesResponse>({
      params: serializeHttpParams(createAndAssign(GetFavoritesQuery, { userId, courtSystemId })),
    })
  }

  addFavourite(userId: Uuid, courtSystemId: Uuid, url: string, label: string): ApiResult<Favorite> {
    return this.favouritesApiClient.post<Favorite>({
      body: {
        userId,
        courtSystemId,
        url,
        label,
      } as CreateFavorite,
    })
  }

  deleteFavorite(favoriteId: Uuid): ApiResult<void> {
    return this.favouritesApiClient.delete<undefined>({ path: `/${favoriteId}` })
  }

  updateFavorites(favorites: UpdateFavorite[]): ApiResult<GetFavoritesResponse> {
    return this.favouritesApiClient.put<GetFavoritesResponse>({
      body: {
        favorites,
      } as UpdateFavoritesBody,
    })
  }
}
