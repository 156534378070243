import { Uuid } from '@ftr/contracts/type/shared'
import { matchSttContext, SttContext } from './SttContext'

/*
 * This is used by the Shared Recording Playback Page.
 * We listen to socket events based on the SharedRecordingId.
 * We query data based on the AudioSegmentId.
 */

export interface SharedRecordingSttContext {
  type: 'shared-recording'
  sharedRecordingId: Uuid
  audioSegmentId: Uuid
}

export function buildSharedRecordingSttContext(
  sharedRecordingId: Uuid,
  audioSegmentId: Uuid,
): SharedRecordingSttContext {
  return {
    type: 'shared-recording',
    sharedRecordingId,
    audioSegmentId,
  }
}

export const sharedRecordingSttContextEquals =
  (other: SttContext) =>
  (self: SharedRecordingSttContext): boolean =>
    matchSttContext(other, {
      mapSharedRecording: ({ sharedRecordingId }) => sharedRecordingId === self.sharedRecordingId,
      mapAudioOrder: () => false,
      mapRecording: () => false,
    })
