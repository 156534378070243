<section class="download-links">
  @for (downloadLink of downloadLinks; track downloadLink) {
    <ftr-auth-download-link class="download-links__link">
      <ng-template #loaded>
        <a [href]="downloadLink.href">{{ downloadLink.name }}</a>
        @if (downloadLink.label) {
          <span class="download-links__link__label">({{ downloadLink.label }})</span>
        }
      </ng-template>
    </ftr-auth-download-link>
  }
  @if (downloadLinks.length === 0) {
    <div>
      <ng-container *ngTemplateOutlet="noItemsTemplate"></ng-container>
    </div>
  }
</section>
