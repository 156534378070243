import { Component, Input } from '@angular/core'
import { AbstractControl, UntypedFormControl, ValidationErrors } from '@angular/forms'
import { Observable } from 'rxjs'
import { emailValidator } from '../../validators'
import { FormInputPillListComponent, trimPillInput } from '../form-input-pill-list'

@Component({
  selector: 'ftr-email-pill-list-input',
  templateUrl: './email-pill-list-input.component.html',
  standalone: true,
  imports: [FormInputPillListComponent],
})
export class EmailPillListInputComponent {
  @Input() listControl: AbstractControl
  @Input() label = 'Emails'
  @Input() maxEmails?: number
  @Input() required = false
  @Input() submitAttempted$?: Observable<boolean>
  /**
   * If set - deems the user's email invalid
   */
  @Input() userEmail: string | undefined

  /**
   * Split pasted input at the following characters to make n pills.
   */
  splitInputAt = /[,;\s]/
  validateEmailInput = this.validateInputControl.bind(this)

  displayHintOn(value: string): boolean {
    return this.listControl.value && this.listControl.value.length === 0 && !!value && value.indexOf('@') !== -1
  }

  /**
   * Validates the input control and the list
   */
  validateInputControl(control: AbstractControl): ValidationErrors | null {
    let inputValue: string = control.value
    const emailListValue = this.listControl.value
    const numberInList = (emailListValue && emailListValue.length) || 0

    if (inputValue) {
      inputValue = inputValue.toLowerCase()
      const multipleEmailsEntered = this.splitInputAt.test(inputValue)
      let hasInvalidEmails = false
      let hasReachedMax = this.maxEmails && numberInList >= this.maxEmails
      if (multipleEmailsEntered) {
        const emails = trimPillInput(inputValue.split(this.splitInputAt))
        hasReachedMax = this.maxEmails && numberInList + emails.length > this.maxEmails
        hasInvalidEmails = emails.some((email: string) => emailValidator(new UntypedFormControl(email)) !== null)
      }

      if (this.userEmail?.toLowerCase() === inputValue) {
        return {
          currentUserNotAllowed: `Your email address (${inputValue.toLowerCase()}) cannot be a recipient.`,
        }
      }
      if (hasReachedMax) {
        return {
          reachedMaximum: `You have reached the ${this.maxEmails} email recipient limit.`,
        }
      }

      if (hasInvalidEmails) {
        return {
          invalidEmailAddress: 'One or more email addresses you just entered are invalid.',
        }
      }
      return multipleEmailsEntered ? null : emailValidator(control)
    } else {
      return this.required && numberInList === 0
        ? { hasAtLeastOneEmail: 'One or more email addresses must be provided.' }
        : null
    }
  }
}
