import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

/**
 * HostListener negatively affects performance when used in too many component instances.
 * This service provides observables for document events any client can subscribe to.
 * Used by OnClickOutsideDirective to close components when they lose focus or the user clicks outside the element.
 */
@Injectable({
  providedIn: 'root',
})
export class GlobalEventsService {
  private eventSubject = new Subject<MouseEvent | FocusEvent>()

  event$ = this.eventSubject.asObservable()

  registerEvent(event: MouseEvent | FocusEvent): void {
    this.eventSubject.next(event)
  }
}
