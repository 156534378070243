import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApiClient, ApiClientFactory } from '@ftr/api-shared'
import { InitializeFileUploadResponse } from '@ftr/contracts/api/file'
import { JobWithTranscriptResponse, SubmitTranscriptRequest } from '@ftr/contracts/api/job'
import { Money } from '@ftr/contracts/type/order'
import { Uuid } from '@ftr/contracts/type/shared'
import { collectData } from '@ftr/foundation'
import {
  ChecksumService,
  FileUploadService,
  FileUploadWithChecksum,
  InitializedFileUpload,
  SuccessfulFileUpload,
  UploadStates,
} from '@ftr/ui-files'
import { LoggingService } from '@ftr/ui-observability'
import { LocalDateTime } from '@js-joda/core'
import { Observable } from 'rxjs'

export interface TranscriptUploadProps {
  jobId: Uuid
  fileId: Uuid
  transcriberFee: Money
}

@Injectable()
export class TranscriptFileUploadService extends FileUploadService<TranscriptUploadProps, JobWithTranscriptResponse> {
  private readonly apiClient: ApiClient

  constructor(
    http: HttpClient,
    loggingService: LoggingService,
    checksumService: ChecksumService,
    apiClientFactory: ApiClientFactory,
  ) {
    super(http, loggingService, checksumService)
    this.apiClient = apiClientFactory.build('/job')
  }

  initializeUpload(
    upload: FileUploadWithChecksum<TranscriptUploadProps, JobWithTranscriptResponse>,
  ): Observable<InitializedFileUpload<TranscriptUploadProps, JobWithTranscriptResponse>> {
    const body = {
      fileId: upload.props.fileId,
      fileName: upload.file.name,
      byteSize: upload.file.size,
      sourceCreatedAt: LocalDateTime.now().toString(),
      checksum: upload.checksum,
    }

    return this.apiClient
      .post<InitializeFileUploadResponse>({
        path: `/${upload.props.jobId}/initializeTranscriptSubmission`,
        body,
      })
      .pipe(
        collectData(response => {
          return {
            ...upload,
            url: response.uploadUrl,
            fileId: response.fileId,
            progress: 0,
            status: UploadStates.Uploading,
          }
        }),
      )
  }

  finalizeUpload(
    upload: InitializedFileUpload<TranscriptUploadProps, JobWithTranscriptResponse>,
  ): Observable<SuccessfulFileUpload<TranscriptUploadProps, JobWithTranscriptResponse>> {
    const body: SubmitTranscriptRequest = {
      fileId: upload.props.fileId,
      transcriberFee: upload.props.transcriberFee,
    }

    return this.apiClient
      .post<JobWithTranscriptResponse>({
        path: `/${upload.props.jobId}/submitTranscript`,
        body,
        responseBodyType: JobWithTranscriptResponse,
      })
      .pipe(
        collectData(response => {
          return {
            ...upload,
            status: UploadStates.Success,
            response,
          }
        }),
      )
  }
}
