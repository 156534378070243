import { InjectionToken } from '@angular/core'
import { CognitoUserPool } from 'amazon-cognito-identity-js'

export const AUTH_CONFIGURATION = new InjectionToken<AuthConfiguration>('AUTH_CONFIGURATION')

export interface AuthConfiguration {
  userPool: CognitoUserPool
  cognitoAuthUrl: string
  cognitoLogoutUrl: string
  cognitoProxyViaApi: boolean
  microsoftAuthAppId: string
  microsoftAuthUrl: string
  mockAuthUrl: string
}
