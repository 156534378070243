import { Component, Input } from '@angular/core'
import { VocabularyTerms } from '@ftr/contracts/type/core'
import { ILLUSTRATION_ASSET_PATH, Illustration, IllustrationDefaults, getIllustrationDefaults } from './illustration'

@Component({
  standalone: true,
  selector: 'ftr-illustration',
  templateUrl: './illustration.component.html',
})
export class IllustrationComponent {
  @Input() illustration: Illustration
  @Input() width?: number
  @Input() height?: number
  @Input() alt?: string
  @Input() extension?: string = 'svg'
  @Input() vocabularyTerms: VocabularyTerms

  get defaults(): IllustrationDefaults {
    return getIllustrationDefaults(this.illustration, this.vocabularyTerms)
  }

  get src(): string {
    return `${ILLUSTRATION_ASSET_PATH}/${this.illustration}.${this.extension}`
  }
}
