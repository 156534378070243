export class OrderPlacementValidationError extends Error {
  constructor() {
    super(
      'One or more order details appear to be invalid. ' +
        'Please go back through the form to check all fields are valid before trying again,' +
        ' try reloading the page,' +
        ' or contact support@fortherecord.com for support.',
    )
    Object.setPrototypeOf(this, OrderPlacementValidationError.prototype)
  }
}
