import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core'
import { AbstractControl, ReactiveFormsModule, UntypedFormControl } from '@angular/forms'
import { ButtonComponent, FocusDirective, IconComponent, OnClickOutsideDirective } from '@ftr/foundation'
import { Observable, combineLatest, debounceTime, map, shareReplay, startWith, tap } from 'rxjs'
import { ValidationErrorHintDirective } from '../../directives'

export type Search<TItem> = (items: TItem[], searchTerm: string) => TItem[]

@Component({
  selector: 'ftr-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.css'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    FocusDirective,
    IconComponent,
    OnClickOutsideDirective,
    ReactiveFormsModule,
    ValidationErrorHintDirective,
  ],
})
/**
 * A component for selecting a single thing from a long list of things. If you need to select from a very short list
 * this component with it's searching and full screen display on mobile is probably overkill
 */
export class SelectSearchComponent<TItem> implements OnInit {
  /**
   * The control that contains this components value
   * NOTE: If an item is not in the list anymore, it still seems to display the saved value in the list.
   * This is required behavior for 'archived locations' (i.e. editing an order for an archived location should NOT
   * reset its location to null)
   */
  @Input() control: AbstractControl
  /**
   * The list of things the user needs to select one of
   */
  @Input() items: Observable<TItem[]>
  /**
   * A label for this control
   */
  @Input() label: string
  /**
   * How to refer to a single item
   */
  @Input() singular: string
  /**
   * How to refer to multiple items
   */
  @Input() plural: string
  /**
   * Optional supplementary text to help the user understand the value that has been selected. Appears as
   * subtext below the control when it has a value
   */
  @Input() instructions = ''
  /**
   * Whether the control is enabled and can be clicked
   */
  @Input() enabled = true
  /**
   * Uses to select an item from the list. Should be used by the template for selecting a value. See itemsTemplate.
   */
  @Input() selectItem: EventEmitter<TItem>
  /**
   * A function uses to perform the filter on the items. It is passed the list of items and the users's search string
   * and expects you to return a filtered list
   */
  @Input() filterItems: Search<TItem>
  /**
   * The template used to display a selected value on the control when it is not being edited. The implicit value
   * is the item that has been selected
   *
   * For example:
   * <ng-template #valueTemplate let-value>
   *    {{value.someproperty}}
   * </ng-template>
   */
  @Input() valueTemplate: TemplateRef<any>
  /**
   * The template used to display the list of values to be selected. The implicit value is the filtered list of items.
   * it is expected that the items in the contained list will be interactive and lead to an event being emitted to
   * the selectItem Emitter to select one of them.
   *
   * For example:
   * <ng-template #itemsTemplate let-items>
   *   <div
   *     *ngFor="let item of (items | async)"
   *     (click)="onSelect(item)"
   *   >
   *     {{item.someproperty}}
   *   </div>
   * </ng-template>
   */
  @Input() itemsTemplate: TemplateRef<any>
  /**
   * Puts the component in a highlighted state with a larger border when true
   */
  @Input() highlightError: Observable<boolean>
  /**
   * Allows the user to override how an item is selected when using keyboard input.
   */
  @Input() selectItemFunction: (item: TItem) => TItem = selectItemDefault
  /**
   * Override what happens when the search-select component is closed.
   */
  @Input() closeFunction?: () => void
  /**
   * Force the select-search component to always be open, and not be closable.
   */
  @Input() isAlwaysOpen = false

  filteredItems: Observable<TItem[]>
  searchTerm = new UntypedFormControl()
  firstItem: TItem
  focusEmitter = new EventEmitter<boolean>(true)
  editing = this.isAlwaysOpen

  ngOnInit(): void {
    this.setupFilter()

    if (this.isAlwaysOpen) {
      this.open()
    }
  }

  setupFilter(): void {
    const DEBOUNCE_TIME = 200
    this.filteredItems = combineLatest([
      this.items,
      this.searchTerm.valueChanges.pipe(startWith(''), debounceTime(DEBOUNCE_TIME)),
    ]).pipe(
      map(([items, searchTerm]) => this.filterItems(items, searchTerm)),
      tap(items => (this.firstItem = items[0])),
      shareReplay(1),
    )

    this.selectItem.subscribe((item: TItem) => {
      this.setItemControl(item)
    })
  }

  close(): void {
    if (this.closeFunction) {
      this.closeFunction()
      return
    }
    this.editing = false
    this.control.markAsTouched({ onlySelf: true })
    this.searchTerm.setValue('')
  }

  open(): void {
    this.editing = true
    this.focusEmitter.emit(true)
  }

  onSearchSelect(event: Event): void {
    event.preventDefault()
    this.setItemControl(this.firstItem ? this.selectItemFunction(this.firstItem) : undefined)
  }

  setItemControl(item: TItem | undefined): void {
    this.control.setValue(item)
    this.close()
  }
}

function selectItemDefault(item: any): any {
  return item
}
