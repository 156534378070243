import { Injectable } from '@angular/core'
import { RegionalApiClientFactory, RegionalApiClientSetupService } from '@ftr/api-regional'
import {
  AddOnTheRecordTimesBody,
  DeleteOnTheRecordTimesBody,
  UpdateOnTheRecordTimesBody,
} from '@ftr/contracts/regional-api'
import { Uuid } from '@ftr/contracts/type/shared'
import { LocalTimeRange } from '@ftr/contracts/type/shared/LocalTimeRange'
import { ApiResult } from '@ftr/foundation'
import { switchMap } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class RealTimeSessionsService extends RegionalApiClientSetupService {
  constructor(apiClientFactory: RegionalApiClientFactory) {
    super(apiClientFactory)
  }

  addSession(courtSystemId: Uuid, recordingId: Uuid, timeRange: LocalTimeRange): ApiResult {
    return this.regionalApiClient('/live-stream', courtSystemId).pipe(
      switchMap(client =>
        client.post<null>({
          headers: { courtSystemId },
          path: `/recording/${recordingId}/on-the-record-times`,
          body: new AddOnTheRecordTimesBody(timeRange),
        }),
      ),
    )
  }

  deleteSession(
    courtSystemId: Uuid,
    recordingId: Uuid,
    timeRange: LocalTimeRange,
    conflictResolutionToken?: string,
  ): ApiResult {
    return this.regionalApiClient('/live-stream', courtSystemId).pipe(
      switchMap(client =>
        client.delete<null>({
          headers: { courtSystemId },
          path: `/recording/${recordingId}/on-the-record-times`,
          body: new DeleteOnTheRecordTimesBody(timeRange, conflictResolutionToken),
        }),
      ),
    )
  }

  updateSession(
    courtSystemId: Uuid,
    recordingId: Uuid,
    existing: LocalTimeRange,
    updated: LocalTimeRange,
    conflictResolutionToken?: string,
  ): ApiResult {
    return this.regionalApiClient('/live-stream', courtSystemId).pipe(
      switchMap(client =>
        client.put<null>({
          headers: { courtSystemId },
          path: `/recording/${recordingId}/on-the-record-times`,
          body: new UpdateOnTheRecordTimesBody(existing, updated, conflictResolutionToken),
        }),
      ),
    )
  }
}
