<ftr-page [pageStyle]="pageStyle">
  <div class="recover-account">
    <a class="recover-account__back link--no-underline" [routerLink]="[mfaChallengePath]" [queryParams]="{ returnUrl }">
      <ftr-icon icon="ChevronLeft" color="Primary" align="TextTop"></ftr-icon>
      Back
    </a>
    <h1>Recover Account</h1>
    <p>Please enter one of your recovery codes that was provided when first enabling your MFA.</p>
    <div class="recover-account__more-info">
      <ftr-icon icon="Info" color="Primary" size="Large" align="Bottom" gapRight="Small"></ftr-icon>
      <span
        >For more information, please refer to our
        <ftr-help-link cls="elevio__recovery-codes" label="Recovery Codes"></ftr-help-link> article.</span
      >
    </div>
    <ftr-untyped-form
      name="recoverAccount"
      [submission]="submission"
      [formGroup]="recoverAccountForm"
      (formSubmit)="submitForm()"
      submitLabel="Continue"
      submittingLabel="Validating"
      [formTemplate]="form"
      [submitDirection]="undefined"
      [footerLeftAligned]="true"
    >
      <ng-template #form let-highlightErrors="highlightErrors">
        <ul class="form-list">
          <li>
            <ftr-form-input
              name="recoveryCode"
              label="Recovery Code"
              autocomplete="off"
              [ngFormControl]="asFormControl(recoverAccountForm.controls['recoveryCode'])"
              [required]="true"
              [highlightError]="highlightErrors"
              [notrack]="true"
              [autofocus]="true"
              [submitAttempted]="submitAttempted"
            >
            </ftr-form-input>
          </li>
        </ul>
      </ng-template>
    </ftr-untyped-form>
  </div>
</ftr-page>
