import { capitalize } from './capitalize'

export function titleCase(str: string | undefined): string {
  if (!str) {
    return ''
  }
  return str
    .split(' ')
    .map(w => capitalize(w.toLowerCase()))
    .join(' ')
}
