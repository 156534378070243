import { Injectable } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { filter, map } from 'rxjs'
import { OVERRIDE_TITLE_SERVICE } from './OverrideTitleService'

export const FTR_TITLE = 'For The Record'

/**
 * If this param is set to true, then we set the page's title to be the same as the breadcrumb that's been
 * specified (or provided via a resolver) for the given page.
 */
export const OVERRIDE_PAGE_TITLE_WITH_BREADCRUMB_PARAM = 'overridePageTitleWithBreadcrumb'

/**
 * Sets the page title (the one that appears in the `title` HTML tag) of the page.
 *
 * The page title can be set within a route's declaration, as part of the route data's
 * "title" attribute.
 *
 * It's possible to reuse the breadcrumb for the given route for the page title as well, by setting
 * the `overridePageTitleWithBreadcrumb` property to `true`.
 *
 * @example
 *  // Setting the page title on a route
 *  RouterModule.forChild({
 *    path: `:id`,
 *    data: {
 *      title: "My page"
 *    }
 *  })
 *
 *  // Overriding a page title with the breadcrumb
 *  RouterModule.forChild({
 *    path: `:id`,
 *    resolve: {
 *      breadcrumb: MyPageBreadrumbResolver
 *    },
 *    data: {
 *      overridePageTitleWithBreadcrumb: true
 *    }
 *  })
 */
@Injectable({
  providedIn: 'root',
})
export class PageTitleService {
  suffix = 'For The Record'
  separator = ' - '

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private router: Router,
    private readonly titleService: Title,
  ) {}

  initialize(): void {
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        map(() => {
          // Finds the lower-most child in the router object to get the data from
          let child = this.activatedRoute.firstChild
          while (child) {
            if (child.firstChild) {
              child = child.firstChild
            } else if (
              child.snapshot.data?.[OVERRIDE_PAGE_TITLE_WITH_BREADCRUMB_PARAM] === true &&
              child.snapshot.data?.breadcrumb !== undefined
            ) {
              return child.snapshot.data?.breadcrumb
            } else if (child.snapshot.data && child.snapshot.data.title) {
              return child.snapshot.data.title
            } else {
              return null
            }
          }
          return null
        }),
        // If you want to handle the title in a component, set its data.title to OVERRIDE_TITLE_SERVICE
        filter(title => title !== OVERRIDE_TITLE_SERVICE),
      )
      // Reset to default title in case the activated route doesn't set its own title.
      // The route component's ngOnInit will fire after this init and has the opportunity to override the title.
      .subscribe(title => {
        if (title) {
          this.setTitle(title)
        } else {
          this.setTitle(FTR_TITLE, false)
        }
      })
  }

  setTitle(title: string, withSuffix = true): void {
    this.titleService.setTitle(`${title}${withSuffix ? this.separator + this.suffix : ''}`)
  }
}
