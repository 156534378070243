@if (collapsed()) {
  <section class="breadcrumbs-dropdown">
    <nav aria-label="Breadcrumbs">
      <ul class="breadcrumbs-dropdown__list" data-track-group="Breadcrumbs">
        @for (breadcrumb of collapsed(); track breadcrumb; let i = $index) {
          @if (i >= 1) {
            <li class="breadcrumbs-dropdown__item" [style.margin-left.rem]="i - 1">
              <ftr-icon icon="SubArrowRight" size="Text" color="Grey30" class="breadcrumbs-dropdown__icon"></ftr-icon>
              <a
                class="breadcrumbs-dropdown__link"
                data-track
                [routerLink]="breadcrumb.link.url"
                [queryParams]="breadcrumb.link.queryParams"
                [attr.aria-label]="'Breadcrumbs Navigation:' + breadcrumb.label"
              >
                {{ breadcrumb.label }}
              </a>
            </li>
          } @else {
            <li class="breadcrumbs-dropdown__item">
              <a
                class="breadcrumbs-dropdown__link"
                data-track
                [routerLink]="breadcrumb.link.url"
                [queryParams]="breadcrumb.link.queryParams"
                [attr.aria-label]="'Breadcrumbs Navigation: ' + breadcrumb.label"
              >
                {{ breadcrumb.label }}
              </a>
            </li>
          }
        }
      </ul>
    </nav>
  </section>
}
