import { Inject, Injectable } from '@angular/core'
import { DESKTOP_CONFIGURATION, DesktopConfiguration } from '@ftr/api-shared'
import { DesktopPlatform } from '@ftr/contracts/api/desktop'
import { SimpleWindowRefService } from '@ftr/foundation'

@Injectable({
  providedIn: 'root',
})
export class DesktopDownloadUrlService {
  constructor(
    private readonly windowRefService: SimpleWindowRefService,
    @Inject(DESKTOP_CONFIGURATION) readonly configurationService: DesktopConfiguration,
  ) {}

  getDownloadUrl(): string {
    return `${this.configurationService.desktopAppUrl}?platform=${
      this.windowRefService.isMac() ? DesktopPlatform.MacOS : DesktopPlatform.Windows
    }`
  }

  openDownloadUrl(): void {
    this.windowRefService.open(this.getDownloadUrl(), '_blank')
  }
}
