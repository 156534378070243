export enum CourtroomRecordingsQueryParams {
  YearMonth = 'yearMonth',
}
export enum RecordingPlaybackParams {
  RecordingId = 'recordingId',
  StreamingSessionId = 'streamingSessionId',
  ShowRegenerate = 'showRegenerate',
  DataStore = 'dataStore',
}
export enum RecordingPlaybackQueryParams {
  StartFromDateTime = 'start',
  SpeakerId = 'speakerId',
}
// If RecordingPlaybackPaths is changed then the route types and const in RecordingPlaybackTabService may need updating
export enum RecordingPlaybackPaths {
  /** @deprecated Use ?tab=stt */
  SpeechToText = 'speech-to-text',
  /** @deprecated Use ?tab=logSheet */
  LogSheet = 'log-sheet',
  ActivityLog = 'activity-log',
  StreamRecording = 'stream',
  /** @deprecated */
  Transcript = 'transcript',
}
