// eslint-disable-next-line max-classes-per-file
import { Hearing } from '@ftr/annotations-contracts'
import { Uuid } from '@ftr/contracts/type/shared'

export class HearingUpdatedEvent {
  static readonly type = '[Hearings] Hearing Update'

  constructor(
    readonly hearingId: Uuid,
    readonly newCaseId: Uuid,
    readonly hasContentChanged: boolean,
    readonly original: Hearing,
    readonly updated: Hearing,
  ) {}
}

export class HearingUpdateFailedEvent {
  static readonly type = '[Hearings] Hearing Update Failed'

  constructor(readonly hearingId: Uuid) {}
}

export class HearingDeletedEvent {
  static readonly type = '[Hearings] Hearing Deleted'

  constructor(
    readonly hearingId: Uuid,
    readonly caseDeleted: boolean,
  ) {}
}

export class HearingDeletionFailedEvent {
  static readonly type = '[Hearings] Hearing Deletion Failed'

  constructor(readonly hearingId: Uuid) {}
}
