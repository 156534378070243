import { Injectable, TemplateRef } from '@angular/core'
import { ActiveToast, IndividualConfig, ToastContainerDirective, ToastrService } from 'ngx-toastr'
import { ToastComponent } from './toast.component'

export interface ToastPayload {
  iconTemplate: TemplateRef<any> | undefined
  messageTemplate: TemplateRef<any> | undefined
  hideClose: boolean | undefined
  type: ToastType
}

export enum ToastType {
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
}

interface ToastOptions extends Partial<IndividualConfig> {
  hideClose: boolean
  iconTemplate: TemplateRef<any>
  hideTitle: boolean
}

const tenSecondsMs = 10_000

const defaultConfig: Partial<IndividualConfig> = {
  positionClass: 'toast-bottom-right',
  tapToDismiss: false,
  timeOut: tenSecondsMs,
  toastComponent: ToastComponent,
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private readonly toastr: ToastrService) {}

  set container(toastContainer: ToastContainerDirective) {
    this.toastr.overlayContainer = toastContainer
  }

  error(
    message: string | TemplateRef<any>,
    title = 'Error',
    options?: Partial<ToastOptions>,
  ): ActiveToast<ToastComponent> {
    return this.show(message, title, options, ToastType.Error)
  }

  info(
    message: string | TemplateRef<any>,
    title = 'Info',
    options?: Partial<ToastOptions>,
  ): ActiveToast<ToastComponent> {
    return this.show(message, title, options, ToastType.Info)
  }

  success(
    message: string | TemplateRef<any>,
    title = 'Success',
    options?: Partial<ToastOptions>,
  ): ActiveToast<ToastComponent> {
    return this.show(message, title, options, ToastType.Success)
  }

  warning(
    message: string | TemplateRef<any>,
    title = 'Warning',
    options?: Partial<ToastOptions>,
  ): ActiveToast<ToastComponent> {
    return this.show(message, title, options, ToastType.Warning)
  }

  /**
   * Removes a single toast by id or all shown toasts
   */
  clear(toastId?: number): void {
    this.toastr.clear(toastId)
  }

  private show(
    message: string | TemplateRef<any>,
    title: string,
    options: Partial<ToastOptions> = {},
    type: ToastType,
  ): ActiveToast<ToastComponent> {
    let messageTemplate: TemplateRef<any> | undefined

    if (typeof message === 'object') {
      messageTemplate = message
      message = ''
    }

    const { iconTemplate, hideClose, hideTitle, ...customConfig } = options
    const payload: ToastPayload = { iconTemplate, messageTemplate, type, hideClose }
    let override: Partial<IndividualConfig>

    switch (type) {
      case ToastType.Error:
        override = { ...defaultConfig, disableTimeOut: true, ...customConfig, payload }
        break
      default:
        override = { ...defaultConfig, ...customConfig, payload }
    }

    return this.toastr[type](message, hideTitle ? undefined : title, override)
  }
}
