import { Injectable } from '@angular/core'
import { CourtSystemUser } from '@ftr/contracts/api/court-system'
import { User } from '@ftr/contracts/api/user'
import { hashCode } from '@ftr/foundation'

// The list of all avaliable avatar colors
// Adding a new color here will shift everyones default avatar colors
export enum AvatarColors {
  Watermelon = 'watermelon',
  Mandarin = 'mandarin',
  Lemon = 'lemon',
  Apple = 'apple',
  Aqua = 'aqua',
  Blueberry = 'blueberry',
  Grape = 'grape',
}

export type AvatarUser = User | CourtSystemUser

@Injectable({
  providedIn: 'root',
})
export class UserAvatarService {
  private uuidToColor: Record<string, AvatarColors> = {}
  private readonly avatarColorArray: AvatarColors[]

  constructor() {
    this.avatarColorArray = Object.values(AvatarColors)
  }

  // Gets the users avatar color based on an integer hash of their uuid
  getAvatarColorFromUser(user: AvatarUser): AvatarColors {
    if (this.uuidToColor[user.id] === undefined) {
      this.uuidToColor[user.id] = this.avatarColorArray[Math.abs(hashCode(user.id)) % this.avatarColorArray.length]
    }
    return this.uuidToColor[user.id]
  }
}
