import { Injectable, Renderer2, RendererFactory2 } from '@angular/core'
import { ApiClient, HttpResponseType, WithResponseHeaders, serializeHttpParams } from '@ftr/api-shared'
import { AuditEventItem, AuditLogResponse, UserAuditLoginHistory } from '@ftr/audit-contracts'
import { PageDetails } from '@ftr/contracts/api/core'
import { assertUnreachable } from '@ftr/contracts/shared/assertUnreachable'
import { Region } from '@ftr/contracts/type/court'
import { Uuid } from '@ftr/contracts/type/shared'
import { ApiResult, ToastService, tapFailure, unwrapData } from '@ftr/foundation'
import { ComplexFilterValue, DateRadioListOptions } from '@ftr/lists'
import { getExportFilename, isTruncatedResponse } from '@ftr/ui-audit'
import { LocalDateTime, ZoneId, ZonedDateTime } from '@js-joda/core'
import { shareReplay, switchMap, take } from 'rxjs'
import { CourtSystemEntityAuditLogView } from '~app/features/audit-log/CourtSystemEntityAuditLogView'
import { ConfigurationService } from '~app/services/configuration/configuration.service'
import { AuditApiClientFactory } from '~app/services/ftr-regional-audit/audit-api-client.factory'
import { WindowRefService } from '~app/services/window/window-ref.service'
import { AuditLogEventDateRange } from './filters/audit-log-filter-configuration'

const GLOBAL_AUDIT_REGION_DEV = Region['ap-southeast-2']
const GLOBAL_AUDIT_REGION_PROD = Region['us-west-2']
const PRODUCTION_DOMAIN = 'fortherecord.com'
const AUDIT_LOG_PAGE_SIZE = 25
const AUDIT_LOG_ENDPOINT_PREFIX = 'audit-log'
const AUDIT_COURT_SYSTEM_ENTITY_EXPORT_FILENAME = {
  [CourtSystemEntityAuditLogView.AdminUserAuditLog]: 'court-system-user-audit-log.csv',
  [CourtSystemEntityAuditLogView.UserGroupAuditLog]: 'court-system-user-group-audit-log.csv',
  [CourtSystemEntityAuditLogView.BillingGroupAuditLog]: 'court-system-billing-group-audit-log.csv',
  [CourtSystemEntityAuditLogView.CaseAuditLog]: 'court-system-case-audit-log.csv',
  [CourtSystemEntityAuditLogView.HearingAuditLog]: 'court-system-hearing-audit-log.csv',
}

@Injectable({
  providedIn: 'root',
})
export class AuditLogService {
  private globalApiClient: ApiClient
  private renderer: Renderer2

  constructor(
    private readonly windowRefService: WindowRefService,
    private readonly rendererFactory: RendererFactory2,
    private readonly toastService: ToastService,
    private readonly auditApiClientFactory: AuditApiClientFactory,
    configurationService: ConfigurationService,
  ) {
    if (configurationService.server.rawUrl.endsWith(PRODUCTION_DOMAIN)) {
      this.globalApiClient = auditApiClientFactory.buildForRegion(AUDIT_LOG_ENDPOINT_PREFIX, GLOBAL_AUDIT_REGION_PROD)
    } else {
      this.globalApiClient = auditApiClientFactory.buildForRegion(AUDIT_LOG_ENDPOINT_PREFIX, GLOBAL_AUDIT_REGION_DEV)
    }
    this.renderer = this.rendererFactory.createRenderer(null, null)
  }

  getMyAuditLog(page: number, dateRange?: AuditLogEventDateRange): ApiResult<AuditLogResponse> {
    return this.globalApiClient.get<AuditLogResponse>({
      path: '/user/me',
      params: serializeHttpParams({
        ...new PageDetails(page, AUDIT_LOG_PAGE_SIZE),
        ...convertDateRangeToUTC(dateRange),
      }),
    })
  }

  exportMyAuditLog(dateRange?: AuditLogEventDateRange): ApiResult<WithResponseHeaders<Blob>> {
    const result = this.globalApiClient
      .getWithResponseHeaders<Blob>({
        path: '/user/me/export',
        params: serializeHttpParams({
          ...convertDateRangeToUTC(dateRange),
        }),
        responseType: HttpResponseType.Blob,
      })
      .pipe(shareReplay(1))
    this.onExport(result, 'my-audit-log.csv')
    return result
  }

  getMyLoginHistory(dateTo?: string): ApiResult<UserAuditLoginHistory> {
    return this.globalApiClient.get<UserAuditLoginHistory>({
      path: '/user/me/last',
      params: serializeHttpParams(
        dateTo
          ? {
              to: dateTo,
            }
          : {},
      ),
    })
  }

  getCourtSystemAuditLog(
    page: number,
    courtSystemId: Uuid,
    dateRange?: AuditLogEventDateRange,
  ): ApiResult<AuditLogResponse> {
    const params = serializeHttpParams({
      ...new PageDetails(page, AUDIT_LOG_PAGE_SIZE),
      ...convertDateRangeToUTC(dateRange),
    })
    return this.auditApiClientFactory.build(AUDIT_LOG_ENDPOINT_PREFIX, courtSystemId).pipe(
      switchMap(apiClient =>
        apiClient.get<AuditLogResponse>({
          path: '/court-system/' + courtSystemId,
          params,
        }),
      ),
    )
  }

  exportCourtSystemAuditLog(
    courtSystemId: Uuid,
    dateRange?: AuditLogEventDateRange,
  ): ApiResult<WithResponseHeaders<Blob>> {
    const result = this.auditApiClientFactory.build(AUDIT_LOG_ENDPOINT_PREFIX, courtSystemId).pipe(
      switchMap(apiClient => {
        return apiClient.getWithResponseHeaders<Blob>({
          path: `/court-system/export/${courtSystemId}`,
          params: serializeHttpParams({
            ...convertDateRangeToUTC(dateRange),
          }),
          responseType: HttpResponseType.Blob,
        })
      }),
      shareReplay(1),
    )
    this.onExport(result, 'court-system-audit-log.csv')
    return result
  }

  getUserAuditLogEvent(eventId: Uuid): ApiResult<AuditEventItem> {
    return this.globalApiClient.get<AuditEventItem>({
      path: '/user/' + eventId,
    })
  }

  getCourtSystemAuditLogEvent(courtSystemId: Uuid, eventId: Uuid): ApiResult<AuditEventItem> {
    return this.auditApiClientFactory.build(AUDIT_LOG_ENDPOINT_PREFIX, courtSystemId).pipe(
      switchMap(apiClient =>
        apiClient.get<AuditEventItem>({
          path: '/court-system/' + courtSystemId + '/' + eventId,
        }),
      ),
    )
  }

  getCourtSystemEntityAuditLog(
    auditLogView: CourtSystemEntityAuditLogView,
    page: number,
    courtSystemId: Uuid,
    entityId: Uuid,
    dateRange?: AuditLogEventDateRange,
  ): ApiResult<AuditLogResponse> {
    const params = serializeHttpParams({
      ...new PageDetails(page, AUDIT_LOG_PAGE_SIZE),
      ...convertDateRangeToUTC(dateRange),
    })
    return this.auditApiClientFactory.build(AUDIT_LOG_ENDPOINT_PREFIX, courtSystemId).pipe(
      switchMap(apiClient =>
        apiClient.get<AuditLogResponse>({
          path: `/court-system/${getAuditLogPathByView(auditLogView, courtSystemId, entityId)}`,
          params,
        }),
      ),
    )
  }

  getCourtSystemEntityAuditEvent(
    auditLogView: CourtSystemEntityAuditLogView,
    courtSystemId: Uuid,
    eventId: Uuid,
  ): ApiResult<AuditEventItem> {
    return this.auditApiClientFactory.build(AUDIT_LOG_ENDPOINT_PREFIX, courtSystemId).pipe(
      switchMap(apiClient =>
        apiClient.get<AuditEventItem>({
          path: `/court-system/${getAuditEventPathByView(auditLogView, courtSystemId, eventId)}`,
        }),
      ),
    )
  }

  exportCourtSystemEntityAuditLog(
    auditLogView: CourtSystemEntityAuditLogView,
    courtSystemId: Uuid,
    entityId: Uuid,
    dateRange?: AuditLogEventDateRange,
  ): ApiResult<WithResponseHeaders<Blob>> {
    const result = this.auditApiClientFactory.build(AUDIT_LOG_ENDPOINT_PREFIX, courtSystemId).pipe(
      switchMap(apiClient =>
        apiClient.getWithResponseHeaders<Blob>({
          path: `/court-system/${getExportAuditLogPathByView(auditLogView, courtSystemId, entityId)}`,
          params: serializeHttpParams({
            ...convertDateRangeToUTC(dateRange),
          }),
          responseType: HttpResponseType.Blob,
        }),
      ),
      shareReplay(1),
    )

    this.onExport(result, AUDIT_COURT_SYSTEM_ENTITY_EXPORT_FILENAME[auditLogView as CourtSystemEntityAuditLogView])
    return result
  }

  private onExport(result: ApiResult<WithResponseHeaders<Blob>>, filename: string): void {
    result
      .pipe(
        take(2),
        tapFailure(_ => {
          this.toastService.error('Failed to generate audit export CSV.')
        }),
        unwrapData(),
      )
      .subscribe(({ data, headers }) => {
        const exportFilename = getExportFilename(headers, filename)
        const exportDownload: HTMLAnchorElement = this.renderer.createElement('a')
        exportDownload.href = this.windowRefService.nativeWindow().URL.createObjectURL(data)
        exportDownload.setAttribute('download', exportFilename)
        exportDownload.click()
        exportDownload.remove()

        if (isTruncatedResponse(headers)) {
          this.toastService.warning(`
            Your export contains too many rows and has been truncated.
            Use the filters to reduce your number of results.
            Exported audit log to ${exportFilename}.
          `)
        } else {
          this.toastService.success(`Exported audit log to ${exportFilename}.`)
        }
      })
  }
}

export function getAuditLogEventDateRange(value: ComplexFilterValue | null): AuditLogEventDateRange | undefined {
  const [filterValue, dateFrom, dateTo] = (value ?? []) as string[]
  switch (filterValue) {
    case DateRadioListOptions.SelectDateRange:
      return {
        from: dateFrom,
        to: dateTo,
      }
    default:
      return undefined
  }
}

function convertDateRangeToUTC(dateRange?: AuditLogEventDateRange): AuditLogEventDateRange | undefined {
  if (!dateRange) {
    return undefined
  }
  return {
    from: convertDateToUTC(`${dateRange.from}T00:00:00.000`),
    to: convertDateToUTC(`${dateRange.to}T23:59:59.999`),
  }
}

function convertDateToUTC(date: string): string {
  return ZonedDateTime.ofLocal(LocalDateTime.parse(date), ZoneId.SYSTEM).withZoneSameInstant(ZoneId.UTC).toString()
}

function getAuditLogPathByView(
  auditLogView: CourtSystemEntityAuditLogView,
  courtSystemId: Uuid,
  entityId: Uuid,
): string {
  switch (auditLogView) {
    case CourtSystemEntityAuditLogView.AdminUserAuditLog:
      return `${courtSystemId}/user/${entityId}`
    case CourtSystemEntityAuditLogView.UserGroupAuditLog:
    case CourtSystemEntityAuditLogView.BillingGroupAuditLog:
      return `${courtSystemId}/user-group/${entityId}`
    case CourtSystemEntityAuditLogView.CaseAuditLog:
      return `${courtSystemId}/case/${entityId}`
    case CourtSystemEntityAuditLogView.HearingAuditLog:
      return `${courtSystemId}/hearing/${entityId}`
    default:
      assertUnreachable(auditLogView)
  }
}

function getAuditEventPathByView(
  auditLogView: CourtSystemEntityAuditLogView,
  courtSystemId: Uuid,
  eventId: Uuid,
): string {
  switch (auditLogView) {
    case CourtSystemEntityAuditLogView.UserGroupAuditLog:
    case CourtSystemEntityAuditLogView.BillingGroupAuditLog:
      return `${courtSystemId}/user-group/event/${eventId}`
    case CourtSystemEntityAuditLogView.AdminUserAuditLog:
      return `${courtSystemId}/${eventId}`
    case CourtSystemEntityAuditLogView.CaseAuditLog:
      return `${courtSystemId}/case/event/${eventId}`
    case CourtSystemEntityAuditLogView.HearingAuditLog:
      return `${courtSystemId}/hearing/event/${eventId}`
    default:
      assertUnreachable(auditLogView)
  }
}

function getExportAuditLogPathByView(
  auditLogView: CourtSystemEntityAuditLogView,
  courtSystemId: Uuid,
  entityId: Uuid,
): string {
  switch (auditLogView) {
    case CourtSystemEntityAuditLogView.AdminUserAuditLog:
      return `export/${courtSystemId}/user/${entityId}`
    case CourtSystemEntityAuditLogView.UserGroupAuditLog:
    case CourtSystemEntityAuditLogView.BillingGroupAuditLog:
      return `${courtSystemId}/user-group/${entityId}/export`
    case CourtSystemEntityAuditLogView.CaseAuditLog:
      return `${courtSystemId}/case/${entityId}/export`
    case CourtSystemEntityAuditLogView.HearingAuditLog:
      return `${courtSystemId}/hearing/${entityId}/export`
    default:
      assertUnreachable(auditLogView)
  }
}
