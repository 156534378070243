<ng-container *ngTemplateOutlet="openerTemplate"></ng-container>
<ng-template #sideNavMenu>
  <div
    [@sideNavAnimations]="isShowing ? 'open' : 'closed'"
    class="side-nav-container"
    [class.showing]="isShowing"
    [attr.aria-hidden]="!isShowing"
  >
    @if (isShowing) {
      <div [@slideInOverlay]="isShowing ? 'open' : 'closed'" class="overlay__black" (click)="close()"></div>
    }
    <nav
      [@slideInMenu]="{
        value: isShowing ? 'open' : 'closed',
        params: { transformValue: openFrom === 'left' ? 'translateX(-100%)' : 'translateX(100%)' },
      }"
      (@slideInMenu.done)="slideAnimationDone()"
      class="side-nav"
      [class.side-nav--left]="openFrom === 'left'"
      [class.side-nav--right]="openFrom === 'right'"
      cdkTrapFocus
      cdkTrapFocusAutoCapture="true"
    >
      <div
        [class.side-nav__header__fixed-height]="fixedHeightHeader"
        class="side-nav__header justify-content-around align-items-left"
      >
        <div class="side-nav__header__title">
          <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
        </div>
        <a class="side-nav__header__close" (click)="close()"><ftr-icon icon="Close" align="TextBottom"></ftr-icon></a>
      </div>
      <ul class="side-nav-items">
        @for (navItem of navItems | async; track navItem) {
          <ftr-side-nav-item [navItem]="navItem" (hide)="close()"></ftr-side-nav-item>
        }
      </ul>
    </nav>
  </div>
</ng-template>
