/* eslint-disable max-classes-per-file */
import { Timeframe } from '@ftr/contracts/api/shared'
import { LocalTimeRange, Uuid } from '@ftr/contracts/type/shared'
import { RealTimePlaybackKey } from '@ftr/data-realtime-playback'
import { LocalDate, LocalTime } from '@js-joda/core'
import { RealTimeSttMarkerEditSource } from '../../types'

export class SetSessionMarkersAction {
  static readonly type = '[RealTimeSessions] Set Session Markers'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly recordingDate: LocalDate,
    readonly timeframes: Timeframe[],
  ) {}
}

export class UpdateSessionRecordingEndTimeAction {
  static readonly type = '[RealTimeSessions] Update Recording End Time'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly recordingEndTime?: LocalTime,
  ) {}
}

export class UpdateSessionRecordingStartTimeAction {
  static readonly type = '[RealTimeSessions] Update Recording Start Time'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly recordingStartTime: LocalTime,
  ) {}
}

export class UpdateSessionLiveLocalTimeAction {
  static readonly type = '[RealTimeSessions] Update Session Live LocalTime'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly liveLocalTime: LocalTime | undefined,
  ) {}
}

export class CreateSessionAction {
  static readonly type = '[RealTimeSessions] Create Session'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly startTime: LocalTime,
    readonly courtSystemId: Uuid,
  ) {}
}

export class DeleteSessionAction {
  static readonly type = '[RealTimeSessions] Delete Session'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly courtSystemId: Uuid,
    readonly timeRange: LocalTimeRange,
  ) {}
}

export class UpdateSessionAction {
  static readonly type = '[RealTimeSessions] Update Session'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly markerId: string,
    readonly startTime: string,
    readonly endTime: string,
    readonly courtSystemId: string,
    readonly source?: RealTimeSttMarkerEditSource,
  ) {}
}
