import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { DownloadLinksComponent } from '@ftr/ui-playback'
import { OrderAttachmentFileUploadService } from './order-attachment-upload/order-attachment-file-upload.service'
import { OrderAttachmentsComponent } from './order-attachments/order-attachments.component'

/**
 * Components specific to the processing of orders
 */
@NgModule({
  imports: [CommonModule, DownloadLinksComponent],
  declarations: [OrderAttachmentsComponent],
  exports: [OrderAttachmentsComponent],
  providers: [OrderAttachmentFileUploadService],
})
export class OrderAttachmentsModule {}
