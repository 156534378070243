import { Injectable } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router'
import { Favorite } from '@ftr/contracts/api/favorites'
import { Uuid } from '@ftr/contracts/type/shared'
import { Breadcrumb, unwrapData } from '@ftr/foundation'
import { Store } from '@ngxs/store'
import { Observable, combineLatest, filter, map, startWith, switchMap, take } from 'rxjs'
import { UserState } from '../store'

export interface CurrentFavoriteData {
  courtSystemId?: Uuid
  userId: Uuid
  label: string
  url: string
}

Favorite
@Injectable({
  providedIn: 'root',
})
export class CurrentFavoriteService {
  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly title: Title,
  ) {}

  currentFavoriteData(): Observable<CurrentFavoriteData> {
    return combineLatest([
      this.store.select(UserState.user).pipe(unwrapData()),
      this.store.select(UserState.currentCourtSystem),
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        startWith(this.activatedRouteData()),
        switchMap(() => {
          return this.activatedRouteData()
        }),
      ),
    ]).pipe(
      map(([user, courtSystem, routeData]) => {
        const breadcrumb: Breadcrumb | string = routeData.breadcrumb
        let currentPageUrl: string
        let currentPageTitle: string
        if (!breadcrumb || typeof breadcrumb === 'string') {
          // Persist all search params if we have no explicit crumb path
          currentPageUrl = location.pathname + location.search
          currentPageTitle = breadcrumb ? (breadcrumb as string) : this.title.getTitle()
        } else {
          const crumb = breadcrumb as Breadcrumb
          currentPageTitle = crumb.label
          // Specific crumb link URLs include explicitly persisted query params
          currentPageUrl = crumb.link.url
        }
        return {
          courtSystemId: courtSystem?.id,
          userId: user.id,
          label: currentPageTitle,
          url: currentPageUrl,
        } as CurrentFavoriteData
      }),
    )
  }

  private activatedRouteData(): Observable<Data> {
    let leaf: ActivatedRoute = this.activatedRoute
    while (leaf.firstChild) {
      leaf = leaf.firstChild
    }
    return leaf.data.pipe(take(1))
  }
}
