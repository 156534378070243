import { Injectable } from '@angular/core'
import { AnnotationsApiClientFactory } from '@ftr/api-annotations'
import { NotFoundApiError } from '@ftr/api-shared'
import { Uuid } from '@ftr/contracts/type/shared/Uuid'
import { RemoteData } from '@ftr/foundation'
import { CourtSystemRegionCache } from '@ftr/ui-court-system'
import { Action, Selector, State, StateContext, createSelector } from '@ngxs/store'
import { patch } from '@ngxs/store/operators'
import { ɵPatchSpec } from '@ngxs/store/operators/patch'
import { memoize } from 'lodash-es'
import { firstValueFrom, of } from 'rxjs'
import { DeleteCaseAction, FetchCaseAction, SetCaseRemoteAction, UpdateCaseAction } from './cases.actions'
import { CaseDeletedEvent, CaseDeletionFailedEvent, CaseUpdateFailedEvent, CaseUpdatedEvent } from './cases.events'
import { CaseInstanceStateModel, CasesStateModel } from './cases.model'

export function defaultCasesState(): CasesStateModel {
  return {
    caseInstanceState: {},
  }
}

export function defaultCaseInstanceState(): CaseInstanceStateModel {
  return {
    caseRemote: RemoteData.notAsked(),
    expires: Date.now(),
  }
}

@State<CasesStateModel>({
  name: 'casesState',
  defaults: defaultCasesState(),
})
@Injectable()
export class CasesState {
  constructor(
    private readonly regionCache: CourtSystemRegionCache,
    private readonly annotationsApiFactory: AnnotationsApiClientFactory,
  ) {}

  @Selector()
  static allCaseInstanceStates(state: CasesStateModel): CasesStateModel['caseInstanceState'] {
    return state.caseInstanceState
  }

  static readonly caseInstanceState = memoize(
    (caseId: Uuid): ((sourceStates: ReturnType<typeof CasesState.allCaseInstanceStates>) => CaseInstanceStateModel) => {
      return createSelector(
        [CasesState.allCaseInstanceStates],
        (sourceStates: ReturnType<typeof CasesState.allCaseInstanceStates>) =>
          sourceStates[caseId] ?? defaultCaseInstanceState(),
      )
    },
  )

  @Action(FetchCaseAction)
  async fetchCase(
    { getState, dispatch }: StateContext<CasesStateModel>,
    { courtSystemId, caseId }: FetchCaseAction,
  ): Promise<void> {
    const originalState = getState().caseInstanceState[caseId] ?? defaultCaseInstanceState()

    // Don't re-fetch the active case if there is already a result or request in flight
    // Clear the state first if you want to re-fetch
    const existingFetch = originalState.caseRemote

    const expired = Date.now() > originalState.expires

    if (existingFetch.isLoading() || (existingFetch.isSuccess() && !expired)) {
      // Do nothing if it is already loading or has a successful result which is not expired
      return
    }

    dispatch(new SetCaseRemoteAction(caseId, RemoteData.loading()))

    runWithoutWaitingForActionToEnd(async () => {
      const apiClient = this.annotationsApiFactory.build(
        (await firstValueFrom(this.regionCache.getOrFetchCourtSystem(of(courtSystemId)))).region,
      )
      const result = await apiClient.case.getCase({ params: { caseId } }).catch(err => ({ fetchError: err }))
      if ('fetchError' in result) {
        dispatch(new SetCaseRemoteAction(caseId, RemoteData.failure(result.fetchError as Error)))
      } else if (result.status !== 200) {
        dispatch(
          new SetCaseRemoteAction(
            caseId,
            RemoteData.failure(result.status === 404 ? new NotFoundApiError() : new Error(result.body.error)),
          ),
        )
      } else {
        dispatch(new SetCaseRemoteAction(caseId, RemoteData.success(result.body)))
      }
    })
  }

  @Action(UpdateCaseAction)
  async updateCase(
    { getState, dispatch }: StateContext<CasesStateModel>,
    { caseId, caseUpdate }: UpdateCaseAction,
  ): Promise<void> {
    const originalState = getState().caseInstanceState[caseId] ?? defaultCaseInstanceState()
    if (!originalState.caseRemote.isSuccess()) {
      dispatch(new CaseUpdateFailedEvent(caseId, 'unknown'))
      return
    }

    const groomedCaseUpdate = {
      ...caseUpdate,
      title: caseUpdate.title?.trim() || null,
    }

    const apiClient = this.annotationsApiFactory.build(
      (await firstValueFrom(this.regionCache.getOrFetchCourtSystem(of(originalState.caseRemote._data.courtSystemId))))
        .region,
    )
    const result = await apiClient.case
      .updateCase({
        body: groomedCaseUpdate,
        params: { caseId },
      })
      .catch(err => ({ fetchError: err }))
    if ('fetchError' in result) {
      dispatch(new CaseUpdateFailedEvent(caseId, 'unknown'))
      return
    }

    if (result.status !== 200) {
      dispatch(new CaseUpdateFailedEvent(caseId, result.status === 409 ? 'conflict' : 'unknown'))
      return
    }

    dispatch([
      new SetCaseRemoteAction(caseId, RemoteData.success(result.body)),
      new CaseUpdatedEvent(caseId, originalState.caseRemote._data, result.body),
    ])
  }

  @Action(DeleteCaseAction)
  async deleteCase({ getState, dispatch }: StateContext<CasesStateModel>, { caseId }: DeleteCaseAction): Promise<void> {
    const originalState = getState().caseInstanceState[caseId] ?? defaultCaseInstanceState()

    if (!originalState.caseRemote.isSuccess()) {
      dispatch(new CaseDeletionFailedEvent(caseId))
      return
    }

    const apiClient = this.annotationsApiFactory.build(
      (await firstValueFrom(this.regionCache.getOrFetchCourtSystem(of(originalState.caseRemote._data.courtSystemId))))
        .region,
    )
    const result = await apiClient.case
      .deleteCase({
        params: { caseId },
      })
      .catch(err => ({ fetchError: err }))
    if ('fetchError' in result) {
      dispatch(new CaseDeletionFailedEvent(caseId))
      return
    }

    if (result.status !== 204) {
      dispatch(new CaseDeletionFailedEvent(caseId))
      return
    }

    dispatch([new SetCaseRemoteAction(caseId, RemoteData.notAsked()), new CaseDeletedEvent(caseId)])
  }

  @Action(SetCaseRemoteAction)
  async setCase(
    { setState }: StateContext<CasesStateModel>,
    { caseId, caseRemote }: SetCaseRemoteAction,
  ): Promise<void> {
    patchCaseInstanceState(setState, caseId, {
      caseRemote,
      expires: Date.now() + 5000,
    })
  }
}

function patchCaseInstanceState(
  setState: StateContext<CasesStateModel>['setState'],
  caseId: Uuid,
  update: ɵPatchSpec<CaseInstanceStateModel>,
): void {
  setState(
    patch<CasesStateModel>({
      caseInstanceState: patch({
        [caseId]: patch(update),
      }),
    }),
  )
}

function runWithoutWaitingForActionToEnd(callback: () => Promise<void>): void {
  void callback()
}
