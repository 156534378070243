<div class="ds-overlay">
  <button
    class="ds-overlay__visibility-button"
    ftrButton
    displayType="icon"
    (click)="showFormAndGrid = !showFormAndGrid"
  >
    @if (!showFormAndGrid) {
      <ftr-icon icon="Visibility" color="Primary" [interactive]="false" align="Top"> </ftr-icon>
    }
    @if (showFormAndGrid) {
      <ftr-icon icon="VisibilityOff" color="Primary" [interactive]="false" align="Top"> </ftr-icon>
    }
  </button>

  @if (showFormAndGrid) {
    <div class="ds-overlay__form-container">
      <ftr-select
        [items$]="pageLayouts"
        [control]="overlayForm.controls[pageLayoutsControlName]"
        defaultValue="None"
        label="Page Layout"
        class="ds-overlay__select"
      >
      </ftr-select>
      <ftr-select
        [items$]="opacities"
        [control]="overlayForm.controls[opacitiesControlName]"
        defaultValue="0.3"
        label="Opacity"
        class="ds-overlay__select"
      >
      </ftr-select>
    </div>
  }
  @if (showFormAndGrid) {
    <div [class]="'ds-overlay__grid ' + overlayForm.value.pageLayouts" [style.opacity]="overlayForm.value.opacities">
      <div class="ds-overlay__col"></div>
      <div class="ds-overlay__col"></div>
      <div class="ds-overlay__col"></div>
      <div class="ds-overlay__col"></div>
      <div class="ds-overlay__col"></div>
      <div class="ds-overlay__col"></div>
      <div class="ds-overlay__col"></div>
      <div class="ds-overlay__col"></div>
      <div class="ds-overlay__col"></div>
      <div class="ds-overlay__col"></div>
      <div class="ds-overlay__col"></div>
      <div class="ds-overlay__col"></div>
    </div>
  }
</div>
