import { Component, Input, OnInit } from '@angular/core'
import { RouterModule } from '@angular/router'
import { Uuid } from '@ftr/contracts/type/shared'
import { Illustration, IllustrationComponent, PageComponent, PageStyle } from '@ftr/foundation'
import { AppPaths, CourtSystemPaths } from '@ftr/routing-paths'

export enum CourtSystemForbiddenType {
  UseConditionsNotAccepted = 'useConditionsNotAccepted',
  MfaNotEnabled = 'mfaNotEnabled',
}

@Component({
  standalone: true,
  selector: 'ftr-court-system-forbidden',
  templateUrl: './court-system-forbidden.component.html',
  styleUrls: ['./court-system-forbidden.component.css'],
  imports: [RouterModule, PageComponent, IllustrationComponent],
})
export class CourtSystemForbiddenComponent implements OnInit {
  @Input() courtSystemId: Uuid
  @Input() courtSystemForbiddenType: CourtSystemForbiddenType

  readonly courtSystemForbiddenTypes = CourtSystemForbiddenType
  readonly illustration = Illustration
  readonly pageStyleNarrow = PageStyle.Narrow

  acceptUseConditionsPath: string

  ngOnInit(): void {
    if (this.courtSystemForbiddenType === CourtSystemForbiddenType.UseConditionsNotAccepted) {
      this.acceptUseConditionsPath = `/${AppPaths.CourtSystem}/${this.courtSystemId}/${CourtSystemPaths.AcceptUseConditions}`
    }
  }
}
