import { animate, state, style, transition, trigger } from '@angular/animations'
import { A11yModule } from '@angular/cdk/a11y'
import {
  Component,
  EnvironmentInjector,
  HostListener,
  OnInit,
  ViewEncapsulation,
  inject,
  runInInjectionContext,
} from '@angular/core'
import { TrackingAction, TrackingEventType, TrackingService } from '../../services'
import { ButtonComponent } from '../button'
import { IconComponent } from '../icon'
import { DialogRequest, DialogService } from './dialog.service'

const ANIMATION_TIME_MS = 150

/**
 * A simple confirmation dialog similar to `window.confirm()` but
 * with styling more aligned to our app.
 *
 * Use `DialogService` to show this component.
 */
@Component({
  selector: 'ftr-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css'],
  animations: [
    trigger('backdropEnterLeave', [
      state('in', style({ opacity: '1' })),
      transition(':enter', [style({ opacity: '0' }), animate(ANIMATION_TIME_MS)]),
      transition(':leave', [animate(ANIMATION_TIME_MS, style({ opacity: '0' }))]),
    ]),

    trigger('dialogEnterLeave', [
      state('in', style({ transform: 'translateY(0)', opacity: '1' })),
      transition(':enter', [style({ transform: 'translateY(40px)', opacity: '0' }), animate(ANIMATION_TIME_MS)]),
      transition(':leave', [animate(ANIMATION_TIME_MS, style({ transform: 'translateY(0)', opacity: '0' }))]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [IconComponent, ButtonComponent, A11yModule],
})
export class DialogComponent implements OnInit {
  isShowing = false
  dialogRequest: DialogRequest | undefined

  constructor(
    private readonly dialogService: DialogService,
    private readonly injector: EnvironmentInjector,
  ) {}

  ngOnInit(): void {
    this.dialogService.dialogRequest.subscribe(request => this.show(request))
    this.dialogService.dialogResponse.subscribe(() => this.hide())
  }

  /**
   * Called when the confirmation dialog has either been
   * confirmed or denied. Should be called within the
   * component's template.
   */
  confirm(didConfirm: boolean, name: string | null): void {
    this.dialogService.respondToDialog(didConfirm)
    if (name) {
      this.trackConfirm(didConfirm, name)
    }
  }

  async hide(): Promise<void> {
    this.isShowing = false
    this.dialogRequest = undefined
    return wait(ANIMATION_TIME_MS)
  }

  private async show(dialogRequest: DialogRequest): Promise<void> {
    this.isShowing = true
    this.dialogRequest = dialogRequest
    this.trackOpen(dialogRequest.name)
    return wait(ANIMATION_TIME_MS)
  }

  private trackOpen(eventLabel: string): void {
    runInInjectionContext(this.injector, () => {
      inject(TrackingService).track({
        event: TrackingEventType.ModalInteraction,
        action: TrackingAction.Open,
        eventLabel,
      })
    })
  }

  private trackConfirm(didConfirm: boolean, name: string): void {
    runInInjectionContext(this.injector, () => {
      inject(TrackingService).track({
        event: TrackingEventType.ModalInteraction,
        action: TrackingAction.Click,
        eventLabel: didConfirm ? `Confirmed ${name}` : `Canceled ${name}`,
      })
    })
  }

  @HostListener('document:keydown.escape', ['$event'])
  private onKeydown($event: KeyboardEvent): void {
    if (this.isShowing) {
      $event.stopPropagation()
      this.hide()
    }
  }
}

async function wait(ms: number): Promise<void> {
  return new Promise<void>(resolve => {
    setTimeout(() => resolve(), ms)
  })
}
