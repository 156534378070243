<ul class="dropdown-menu-items" data-track-group="Dropdown menu">
  @for (menuItem of menuItems; track menuItem) {
    <li
      [routerLinkActiveOptions]="{ exact: routerLinkActiveExact }"
      class="dropdown-menu-item"
      [routerLinkActive]="menuItem.path ? 'dropdown-menu-item--active' : ''"
    >
      @if (menuItem.path) {
        <a
          data-track
          (click)="close()"
          [routerLink]="menuItem.path"
          [queryParams]="menuItem.queryParams"
          class="dropdown-menu-item__link"
        >
          {{ menuItem.title }}
          @if (menuItem.icon) {
            <ftr-icon [icon]="menuItem.icon" [align]="'Middle'" [size]="'ExtraSmall'"></ftr-icon>
          }
        </a>
      } @else {
        <a data-track (click)="menuItem.onSelect ? menuItem.onSelect() : {}" class="dropdown-menu-item__link">
          {{ menuItem.title }}
        </a>
      }
      @if (menuItem.children) {
        <ftr-dropdown-menu-items (hide)="close()" [menuItems]="menuItem.children"></ftr-dropdown-menu-items>
      }
    </li>
  }
</ul>
