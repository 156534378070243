import { Injectable } from '@angular/core'
import { Uuid } from '@ftr/contracts/type/shared'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { SetCurrentCourtroomIdAction } from './courtroom.actions'
import { CourtroomStateModel } from './courtroom.model'

export function defaultCourtroomState(): CourtroomStateModel {
  return {
    currentCourtroomId: undefined,
  }
}

@State<CourtroomStateModel>({
  name: 'courtroomState',
  defaults: defaultCourtroomState(),
})
@Injectable()
export class CourtroomState {
  @Selector()
  static currentCourtRoomId(state: CourtroomStateModel): Uuid | undefined {
    return state.currentCourtroomId
  }

  @Action(SetCurrentCourtroomIdAction)
  setCurrentCourtroomId(
    { patchState }: StateContext<CourtroomStateModel>,
    { currentCourtroomId }: SetCurrentCourtroomIdAction,
  ): void {
    patchState({ currentCourtroomId })
  }
}
