<div
  class="input input--icon"
  [ngClass]="{
    'input--password': type === 'password',
    'input--text': type === 'text',
    'input--number': type === 'number',
    'input--prefix': !!prefix,
    'input--highlight': highlightError | async,
    'input--hide-label': !!hideLabel,
    'input--disabled': ngFormControl.disabled,
    'input--flat': displayMode === 'FLAT',
  }"
>
  <ftr-icon class="input__icon" icon="Search" color="GreyLight"></ftr-icon>
  <input
    [id]="id"
    #input
    class="input__control input__search"
    [ngClass]="{ 'input__control--has-value': hasValue, 'input__search--show-clear': clearType }"
    [type]="type"
    [required]="required"
    [attr.name]="name"
    [attr.placeholder]="placeholder"
    [attr.pattern]="pattern"
    [attr.autocomplete]="autocomplete ? autocomplete : null"
    [attr.spellcheck]="spellcheck"
    [attr.tabindex]="tabIndex"
    [attr.data-notrack]="notrack"
    [formControl]="ngFormControl"
    [attr.aria-invalid]="ngFormControl.touched && !ngFormControl.valid"
    [maxlength]="maxLength ? maxLength : null"
  />
  @if (clearType && hasValue) {
    <a
      class="input__search-clear"
      type="button"
      ftrButton
      ftrStopPropagation
      displayType="text"
      href="javascript:void(0)"
      (click)="clear()"
    >
      @if (clearType === searchInputClear.Clear) {
        Clear
      }
      @if (clearType === searchInputClear.Cross) {
        <ftr-icon icon="Close" color="GreyLight"></ftr-icon>
      }
    </a>
  }
  @if (!!prefix) {
    <span class="input__prefix">{{ prefix }}</span>
  }
  <label class="input__label" [for]="id">{{ label }}</label>
  <div class="input__bar" [class.input__bar--disabled]="ngFormControl.disabled"></div>
  <span
    class="input__validation"
    ftrValidationErrorHint
    [label]="validationLabel || label"
    [ngFormControl]="ngFormControl"
    [controlTouched]="ngFormControl.touched"
    [submitAttempted]="submitAttempted"
  ></span>
</div>
