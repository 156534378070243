import { Injectable } from '@angular/core'
import { RegionalApiClientFactory, RegionalApiClientSetupService } from '@ftr/api-regional'
import { AddSealedTimesBody, DeleteSealedTimesBody, UpdateSealedTimesBody } from '@ftr/contracts/regional-api'
import { LocalTimeRange, Uuid } from '@ftr/contracts/type/shared'
import { ApiResult } from '@ftr/foundation'
import { switchMap } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class RealTimeSealingService extends RegionalApiClientSetupService {
  constructor(apiClientFactory: RegionalApiClientFactory) {
    super(apiClientFactory)
  }

  addSealedSegment(courtSystemId: Uuid, recordingId: Uuid, timeRange: LocalTimeRange): ApiResult {
    return this.regionalApiClient('/live-stream', courtSystemId).pipe(
      switchMap(client =>
        client.post<null>({
          headers: { courtSystemId },
          path: `/recording/${recordingId}/sealed-times`,
          body: new AddSealedTimesBody(timeRange),
        }),
      ),
    )
  }

  deleteSealedSegment(courtSystemId: Uuid, recordingId: Uuid, timeRange: LocalTimeRange): ApiResult {
    return this.regionalApiClient('/live-stream', courtSystemId).pipe(
      switchMap(client =>
        client.delete<null>({
          headers: { courtSystemId },
          path: `/recording/${recordingId}/sealed-times`,
          body: new DeleteSealedTimesBody(timeRange),
        }),
      ),
    )
  }

  updateSealedSegment(
    courtSystemId: Uuid,
    recordingId: Uuid,
    existing: LocalTimeRange,
    updated: LocalTimeRange,
  ): ApiResult {
    return this.regionalApiClient('/live-stream', courtSystemId).pipe(
      switchMap(client =>
        client.put<null>({
          headers: { courtSystemId },
          path: `/recording/${recordingId}/sealed-times`,
          body: new UpdateSealedTimesBody(existing, updated),
        }),
      ),
    )
  }
}
