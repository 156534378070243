import { Directive, ElementRef, EventEmitter, HostListener, Inject, Input, Output } from '@angular/core'
import { AbstractControl } from '@angular/forms'
import { SimpleWindowRefService } from '@ftr/foundation'
import { PillNavigationPrev } from './pill-navigation.directive'

const EVENT = '$event'
const TARGET = '$event.target'

@Directive({
  selector: '[ftrPillInput]',
  standalone: true,
})
export class PillInputDirective {
  @Input() ftrPillInput: AbstractControl
  @Input() pillCreationTriggers: Set<string>
  @Output() onCreateNewPill = new EventEmitter<void>()
  @Output() onPaste = new EventEmitter<void>(true)
  @Output() onNavigatePrev = new EventEmitter<PillNavigationPrev>()

  constructor(
    @Inject(ElementRef) private element: ElementRef,
    private readonly windowRefService: SimpleWindowRefService,
  ) {}

  /**
   * Tab is separated from other create keys to allow users to tab out of the field.
   */
  @HostListener('keydown.Tab', [EVENT, TARGET])
  onTabCreateNewPill(event: KeyboardEvent, targetElement: HTMLInputElement): void {
    if (!targetElement.value) {
      return
    }
    event.preventDefault()
    this.onCreateNewPill.emit()
  }

  /**
   * Paste needs to be async to automatically add pills.
   */
  @HostListener('paste')
  onPasteCreateNewPill(): void {
    this.onPaste.emit()
  }

  /**
   * The keys used to trigger the creation of a new pill
   * Prevent default ensures that hitting enter will not try
   * to submit the form, or add semi colons or commas to the input.
   */
  @HostListener('keydown', [EVENT])
  onCreateNewPillKeys(event: KeyboardEvent): void {
    if (this.pillCreationTriggers.has(event.key)) {
      event.preventDefault()
      this.onCreateNewPill.emit()
    }
  }

  /**
   * When the cursor is at the first character in the input and the user presses arrow left, or shift-tab,
   * it should focus on the previous sibling.
   * NOTE: selectionStart does not work on type="email" elements, hence it is a type="text" input.
   */
  @HostListener('keydown.Shift.Tab', [EVENT, TARGET])
  @HostListener('keydown.ArrowUp', [EVENT, TARGET])
  @HostListener('keydown.ArrowLeft', [EVENT, TARGET])
  @HostListener('keydown.Backspace', [EVENT, TARGET])
  onLeaveInput(event: KeyboardEvent, targetElement: HTMLInputElement): void {
    const windowSelection = this.windowRefService.getSelection()
    if (!windowSelection) {
      return
    }
    const selection = windowSelection.toString()
    if (targetElement.selectionStart === 0 && !selection) {
      const previousSibling = this.element.nativeElement.parentNode.querySelector('ftr-pill:last-of-type')
      if (previousSibling) {
        // Prevents Shift+Tab from selecting the first pill.
        event.preventDefault()
        this.onNavigatePrev.emit({ previousSibling })
      }
    }
  }
}
