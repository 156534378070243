import { Injectable } from '@angular/core'
import { RecordingSegment } from '@ftr/contracts/read'
import { ApproximateTimeRange, StrictTimeRange } from '@ftr/foundation'
import moment from 'moment-timezone'

const DEFAULT_TRM_DURATION = 5
const MILLISECONDS_IN_SECONDS = 1000

/*
 * This has been copied from ftr-concatenator-ecs that assumes that as a
 * recording gets longer, the drift between the elapsed time and wall clock
 * becomes greater; so we add this in as an error to compensate.
 */
const TIMECODE_DRIFT_MILLISECONDS = 120000 // Two minutes

@Injectable()
export class TimeRangeService {
  /**
   * Reduces a list of time ranges into contiguous time range blocks. Time ranges are contiguous
   * if the time difference between the `to` and `from` times of two blocks is less than 2 minutes.
   */
  convertToContiguousApproximateTimeRanges(segments: RecordingSegment[]): ApproximateTimeRange[] {
    const sortedTimeRanges = segments
      .map(toTimeRange)
      .sort((a: StrictTimeRange, b: StrictTimeRange) => a.start.diff(b.start))

    return toContiguousBlocks(sortedTimeRanges)
  }
}

function toContiguousBlocks(timeRanges: StrictTimeRange[]): ApproximateTimeRange[] {
  const result: StrictTimeRange[] = []

  if (timeRanges.length && timeRanges[0].start) {
    timeRanges.forEach((_, index) => {
      const resultItem = result[result.length - 1]
      const currentItem = timeRanges[index]

      if (!resultItem) {
        result.push({ ...currentItem })
      } else if (
        timeRangesAreContiguous(resultItem, currentItem) &&
        resultItem.recordingId === currentItem.recordingId &&
        resultItem.recordingType === currentItem.recordingType
      ) {
        result[result.length - 1] = {
          ...resultItem,
          end: currentItem.end,
        }
      } else {
        result.push(currentItem)
      }
    })
  }

  return result.map(r => ({ ...r, endIsApproximate: true }))
}

function toTimeRange(segment: RecordingSegment): StrictTimeRange {
  return {
    start: moment.utc(segment.startDateTime.toString()),
    end: moment.utc(
      segment.timecode
        ? segment.startDateTime.plusSeconds(segment.timecode.duration / MILLISECONDS_IN_SECONDS).toString()
        : segment.startDateTime.plusMinutes(DEFAULT_TRM_DURATION).toString(),
    ),
    recordingId: segment.recordingId,
    recordingType: segment.recordingType,
  }
}

/**
 * Determine if two time ranges are close enough to each other to be considered contiguous
 * @param a The earliest time range
 * @param b The latest time range
 */
function timeRangesAreContiguous(a: StrictTimeRange, b: StrictTimeRange): boolean {
  const differenceMs = Math.abs(moment.duration(a.end.diff(b.start)).asMilliseconds())
  return differenceMs <= TIMECODE_DRIFT_MILLISECONDS
}
