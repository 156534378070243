import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core'
import { ButtonBase } from './button.base'

/**
 * @see ButtonComponent
 */
@Component({
  standalone: true,
  selector: 'a[ftrButton]',
  styleUrls: ['./button.component.css'],
  template: '<ng-content></ng-content>',
  exportAs: 'ftrButton',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnchorComponent extends ButtonBase {}
