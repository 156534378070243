import { Inject, Injectable } from '@angular/core'
import { contract } from '@ftr/annotations-contracts'
import { API_CONFIGURATION, AUTH_TOKEN_FETCHER, AuthTokenFetcher, FullApiClientConfiguration } from '@ftr/api-shared'
import { Region } from '@ftr/contracts/type/court'
import { initClient, tsRestFetchApi } from '@ts-rest/core'
import { AnnotationsApiClient } from './types'

/**
 * Create a client service for ftr-annotations for a given region
 */
@Injectable({
  providedIn: 'root',
})
export class AnnotationsApiClientFactory {
  constructor(
    @Inject(API_CONFIGURATION) private readonly config: FullApiClientConfiguration,
    @Inject(AUTH_TOKEN_FETCHER) private readonly authTokenFetcher: AuthTokenFetcher,
  ) {}

  build(region: Region): AnnotationsApiClient {
    const baseUrl = this.config.annotationsApi.endpointMap[region]
    return initClient(contract, {
      baseUrl,
      baseHeaders: {},
      api: async args => {
        const token = await this.authTokenFetcher.getToken()
        return tsRestFetchApi({ ...args, headers: { ...args.headers, Authorization: `Bearer ${token}` } })
      },
    })
  }
}
