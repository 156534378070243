@if (componentToDisplay === 'slider') {
  <div class="slider">
    <div class="slider__ticks">
      @for (item of items | async; track item) {
        <div class="slider__tick" [attr.aria-label]="item.key"></div>
      }
    </div>
    <ol class="slider__items">
      @for (item of items | async; track item; let i = $index) {
        <li class="slider__item" title="{{ item.key }}">
          <span
            class="slider__item-text"
            [ngClass]="{ 'slider__item-text--selected': step.value === i }"
            (click)="changeValue(i)"
            >{{ item.key }}</span
          >
        </li>
      }
    </ol>
    <input
      [id]="id"
      [attr.name]="name"
      class="slider__input"
      type="range"
      min="0"
      [max]="values.length - 1"
      [formControl]="step"
      (change)="onChange($event)"
      (touchend)="setValueFromTouch($event)"
      [attr.aria-label]="label"
      [attr.aria-valuemin]="0"
      [attr.aria-valuemax]="values.length - 1"
      [attr.aria-valuenow]="ngFormControl.value"
      [attr.aria-valuetext]="getValueText(ngFormControl.value)"
    />
  </div>
}
@if (componentToDisplay === 'select') {
  <ftr-select
    [singular]="singular"
    [label]="label"
    [items$]="items"
    [control]="ngFormControl"
    [required]="true"
    [defaultValue]="ngFormControl.value"
  ></ftr-select>
}
