import { Injectable } from '@angular/core'
import { UserAcceptedConditions } from '@ftr/contracts/api/court-use-conditions'
import { Uuid } from '@ftr/contracts/type/shared'
import { unwrapData } from '@ftr/foundation'
import { CourtSystemUseConditionsService } from '@ftr/ui-court-system'
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store'
import { patch } from '@ngxs/store/operators'
import { memoize } from 'lodash-es'
import { firstValueFrom } from 'rxjs'
import {
  FetchConditionsAcceptedByUserAction,
  FetchCourtSystemUseConditionsAction,
  SetCourtSystemUseConditionsFormDataStateAction,
  SetCourtSystemUseConditionsStateAction,
} from './use-conditions.actions'
import { CourtSystemUseConditionsStateModel, UseConditionsStateModel } from './use-conditions.model'

export function defaultUseConditionsState(): UseConditionsStateModel {
  return {}
}

function defaultCourtSystemUseConditionsState(): CourtSystemUseConditionsStateModel {
  return {
    configuration: undefined,
    acceptedTimestamp: undefined,
    formData: undefined,
  }
}

@State<UseConditionsStateModel>({
  name: 'useConditionsState',
  defaults: defaultUseConditionsState(),
})
@Injectable()
export class UseConditionsState {
  @Selector()
  static allUseConditionsStates(state: UseConditionsStateModel): UseConditionsStateModel {
    return state
  }

  static readonly courtSystemUseConditionsState = memoize(
    (
      courtSystemId: Uuid,
    ): ((
      sourceStates: ReturnType<typeof UseConditionsState.allUseConditionsStates>,
    ) => CourtSystemUseConditionsStateModel | undefined) => {
      return createSelector(
        [UseConditionsState],
        (sourceStates: ReturnType<typeof UseConditionsState.allUseConditionsStates>) => sourceStates[courtSystemId],
      )
    },
  )

  static readonly configuredUseConditions = createMemoizedUseConditionsStateSelector('configuration')

  static readonly acceptedTimestamp = createMemoizedUseConditionsStateSelector('acceptedTimestamp')

  static readonly formDataUseConditions = createMemoizedUseConditionsStateSelector('formData')

  constructor(private readonly courtSystemUseConditionsService: CourtSystemUseConditionsService) {}

  @Action(SetCourtSystemUseConditionsStateAction)
  setUseConditionsSourceState(
    { setState }: StateContext<UseConditionsStateModel>,
    { courtSystemId, state }: SetCourtSystemUseConditionsStateAction,
  ): void {
    setState(
      patch<UseConditionsStateModel>({
        [courtSystemId]: state,
      }),
    )
  }

  @Action(SetCourtSystemUseConditionsFormDataStateAction)
  setCourtSystemUseConditionsFormDataState(
    { setState }: StateContext<UseConditionsStateModel>,
    { courtSystemId, formData }: SetCourtSystemUseConditionsFormDataStateAction,
  ): void {
    setState(
      patch({
        [courtSystemId]: patch({
          formData,
        }),
      }),
    )
  }

  @Action(FetchCourtSystemUseConditionsAction)
  async fetchCourSystemUseConditions(
    { getState, setState, dispatch }: StateContext<UseConditionsStateModel>,
    { courtSystemId, bypassCache }: FetchCourtSystemUseConditionsAction,
  ): Promise<void> {
    const originalState = getState()[courtSystemId]

    if (originalState?.configuration && !bypassCache) {
      return
    }

    if (!originalState) {
      await firstValueFrom(
        dispatch(new SetCourtSystemUseConditionsStateAction(courtSystemId, defaultCourtSystemUseConditionsState())),
      )
    }

    const courtSystemUseConditions = await firstValueFrom(
      this.courtSystemUseConditionsService.getForCourtSystem(courtSystemId).pipe(unwrapData()),
    )

    setState(
      patch({
        [courtSystemId]: patch({
          configuration: courtSystemUseConditions,
        }),
      }),
    )
  }

  @Action(FetchConditionsAcceptedByUserAction)
  async fetchConditionsAcceptedByUser(
    stateContext: StateContext<UseConditionsStateModel>,
    { courtSystemId, bypassCache }: FetchConditionsAcceptedByUserAction,
  ): Promise<void> {
    const originalState = stateContext.getState()[courtSystemId]
    if (originalState?.configuration && !bypassCache) {
      return
    }

    const userAcceptedConditions = await firstValueFrom(
      this.courtSystemUseConditionsService.getForUser().pipe(unwrapData()),
    )

    // Patch updated timestamp for all court system conditions accepted by the user
    userAcceptedConditions.forEach(async accepted => await this.patchAcceptedTimestampForCourt(stateContext, accepted))
  }

  private async patchAcceptedTimestampForCourt(
    { getState, setState, dispatch }: StateContext<UseConditionsStateModel>,
    userAcceptedConditions: UserAcceptedConditions,
  ): Promise<void> {
    const originalState = getState()[userAcceptedConditions.courtSystemId]

    if (!originalState) {
      await firstValueFrom(
        dispatch(
          new SetCourtSystemUseConditionsStateAction(
            userAcceptedConditions.courtSystemId,
            defaultCourtSystemUseConditionsState(),
          ),
        ),
      )
    }

    setState(
      patch({
        [userAcceptedConditions.courtSystemId]: patch({
          acceptedTimestamp: userAcceptedConditions.acceptedTimestamp,
        }),
      }),
    )
  }
}

function createMemoizedUseConditionsStateSelector<T extends keyof CourtSystemUseConditionsStateModel>(
  property: T,
): (
  courtSystemId: Uuid,
) => (sourceStates: CourtSystemUseConditionsStateModel | undefined) => CourtSystemUseConditionsStateModel[T] {
  return memoize(
    (
      courtSystemId: Uuid,
    ): ((sourceStates: CourtSystemUseConditionsStateModel | undefined) => CourtSystemUseConditionsStateModel[T]) => {
      const selectedUseConditionsState = UseConditionsState.courtSystemUseConditionsState(courtSystemId)
      return createSelector([selectedUseConditionsState], (state: ReturnType<typeof selectedUseConditionsState>) => {
        return state?.[property] ?? defaultCourtSystemUseConditionsState()[property]
      })
    },
  )
}
