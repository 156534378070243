import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { RESET_PASSWORD_WARNING } from '@ftr/api-shared'

@Component({
  template: '<ftr-reset [showResetPasswordWarning]="showResetPasswordWarning"></ftr-reset>',
})
export class ResetPage implements OnInit {
  showResetPasswordWarning: boolean

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.showResetPasswordWarning = this.route.snapshot.queryParams[RESET_PASSWORD_WARNING]
  }
}
