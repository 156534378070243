import { AbstractControl, ValidationErrors } from '@angular/forms'

const iso88591NonControl = /^[\x20-\x7E\xA0-\xFF]+$/
const iso88591NonControlWithNewLine = /^[\x20-\x7E\xA0-\xFF\t\r\n]+$/

export enum SupportedLineMode {
  SINGLE = 'single',
  MULTI = 'multi',
}

export const NON_EMPTY_SINGLE_LINE = iso88591NonControl
export const NON_EMPTY_MULTI_LINE = iso88591NonControlWithNewLine

export const ERROR_STATE = { error: true }

/**
 * See IsSupportedText in ftr-beast for a full explanation
 */

export const validateRequiredSingleLineText = (control: AbstractControl): ValidationErrors | null =>
  validate(control, SupportedLineMode.SINGLE)

export const validateRequiredMultiLineText = (control: AbstractControl): ValidationErrors | null =>
  validate(control, SupportedLineMode.MULTI)

export const validateOptionalSingleLineText = (control: AbstractControl): ValidationErrors | null =>
  validateOptional(control, SupportedLineMode.SINGLE)

export const validateOptionalMultiLineText = (control: AbstractControl): ValidationErrors | null =>
  validateOptional(control, SupportedLineMode.MULTI)

const validate = (control: AbstractControl, mode: SupportedLineMode): ValidationErrors | null => {
  if (!control.value || control.value.trim().length === 0) {
    return ERROR_STATE
  }

  // forbid values starting with whitespace (currently only enforced in web)
  if (control.value.startsWith(' ')) {
    return ERROR_STATE
  }

  const regex = mode === SupportedLineMode.SINGLE ? NON_EMPTY_SINGLE_LINE : NON_EMPTY_MULTI_LINE

  const isValid = new RegExp(regex).test(control.value)
  return !isValid ? ERROR_STATE : null
}

/**
 * A variation of isSupportedText that allows empty input for optional values
 */
const validateOptional = (control: AbstractControl, mode: SupportedLineMode): ValidationErrors | null => {
  return control.value ? validate(control, mode) : null
}
