@if (hasPreviousConflictResolutionTokenExpired()) {
  <ftr-notification [type]="notificationType.Warn">
    <p>Sorry, related times have been edited. Please reconfirm.</p>
  </ftr-notification>
}

<p class="real-time-sessions-conflicts-modal__description">{{ title() }}</p>

<div class="real-time-sessions-conflicts-modal__content">
  @if (conflicts().hearingConflicts; as hearingConflicts) {
    <ftr-real-time-sessions-conflicts-item
      [term]="terms().hearing.singular"
      [times]="hearingConflicts"
    ></ftr-real-time-sessions-conflicts-item>
  }

  @if (conflicts().sealedConflicts; as sealedConflicts) {
    <ftr-real-time-sessions-conflicts-item
      [term]="terms().sealed.singular"
      [times]="sealedConflicts"
    ></ftr-real-time-sessions-conflicts-item>
  }
</div>
