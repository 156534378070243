import { NgClass, NgTemplateOutlet } from '@angular/common'
import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, ValidatorFn } from '@angular/forms'
import { DestroySubscribers, IconComponent, KeyboardEventKey, PillComponent, isOfNonZeroLength } from '@ftr/foundation'
import { Observable, takeUntil, tap } from 'rxjs'
import { ValidationErrorHintDirective } from '../../directives'
import { PillInputDirective } from './pill-input.directive'
import {
  PillNavigationDelete,
  PillNavigationDirective,
  PillNavigationNext,
  PillNavigationPrev,
} from './pill-navigation.directive'

let nextId = 0

@Component({
  selector: 'ftr-pill-list-input',
  templateUrl: './form-input-pill-list.component.html',
  styleUrls: ['./form-input-pill-list.component.css'],
  imports: [
    PillComponent,
    PillNavigationDirective,
    ValidationErrorHintDirective,
    IconComponent,
    PillInputDirective,
    NgClass,
    NgTemplateOutlet,
    ReactiveFormsModule,
  ],
  standalone: true,
})
export class FormInputPillListComponent extends DestroySubscribers implements OnInit {
  /**
   * The id of this form input pill list
   */
  @Input() id = `formInputPillList-${nextId++}`
  /**
   * The placeholder and label for the component.
   */
  @Input() label: string
  /**
   * Input name
   */
  @Input() name: string
  /**
   * An optional label that is added into the validation hint message.
   */
  @Input() validationLabel: string
  /**
   * An optional validator for the input
   */
  @Input() inputValidator?: ValidatorFn
  /**
   * The control for the list of pills
   */
  @Input() listControl: AbstractControl
  /**
   * Split pasted input at the following characters to make n pills.
   */
  @Input() splitInputAt: RegExp
  /**
   * The display message hint to render
   */
  @Input() displayHintTemplate: TemplateRef<any> | null = null
  /**
   * A method passed in that determines whether to display the hint or not.
   */
  @Input() displayHintOn?: (value: string) => boolean

  @Input() required = false
  @Input() submitAttempted$?: Observable<boolean>

  /**
   * Which keys being pressed cause new pills to be created
   */
  @Input() pillCreationTriggers = new Set<string>([
    KeyboardEventKey.ENTER,
    KeyboardEventKey.SPACE,
    KeyboardEventKey.COMMA,
    KeyboardEventKey.SEMICOLON,
  ])

  @ViewChild('input') input: ElementRef

  /**
   * The control for the text input
   */
  inputControl: UntypedFormControl

  /**
   * Whether to display a hint to the user about how to use this field.
   */
  displayHint = false

  ngOnInit(): void {
    this.inputControl = new UntypedFormControl(null, this.inputValidator || null)
    this.inputControl.valueChanges.subscribe(value => {
      if (!this.inputControl.valid) {
        this.listControl.setErrors({ pillListInputValid: false })
      } else {
        this.listControl.setErrors({ pillListInputValid: null })
        this.listControl.updateValueAndValidity()
      }
      if (this.displayHintOn) {
        this.displayHint = this.displayHintOn(value)
      }
    })

    if (this.validationLabel === undefined) {
      this.validationLabel = this.label
    }

    // if the form is submitted while invalid, mark as touched to show the validation error (if any)
    this.submitAttempted$
      ?.pipe(
        tap(formValid => {
          if (!formValid) {
            this.inputControl.markAsTouched()
          }
        }),
        takeUntil(this.finalize),
      )
      .subscribe()
  }

  get listControlValue(): string[] {
    return this.listControl.value || []
  }

  /**
   * Convert a string of text with spaces, tabs, commas or semi colons to individual pills.
   */
  createNewPill(): void {
    const inputValue = this.inputControl.value
    if (!this.inputControl.valid) {
      this.inputControl.markAsTouched()
    }
    if (!inputValue || !this.inputControl.valid) {
      return
    }
    const possiblePills = inputValue.split(this.splitInputAt)
    const validPills = trimPillInput(possiblePills)
    this.inputControl.reset()
    this.listControl.setValue([...new Set(this.listControlValue.concat(...validPills))])
    // For required pill inputs, we must update the validity after we've set the value to the listControl.
    this.inputControl.updateValueAndValidity({ onlySelf: true, emitEvent: false })
  }

  deletePill(label: string): void {
    const pillIndex = this.listControlValue.indexOf(label)
    if (pillIndex === -1) {
      return
    }
    this.listControlValue.splice(pillIndex, 1)
    this.listControl.updateValueAndValidity()
    this.inputControl.updateValueAndValidity()

    // if the control is now invalid, e.g. because it's required, mark as touched to show the validation error
    if (!this.inputControl.valid) {
      this.inputControl.markAsTouched()
    }
  }

  navigatePrev(previousNavigation: PillNavigationPrev): void {
    if (previousNavigation.previousSibling) {
      previousNavigation.previousSibling.focus()
    }
  }

  navigateNext(nextNavigation: PillNavigationNext): void {
    if (nextNavigation.nextSibling) {
      nextNavigation.nextSibling.focus()
    }
  }

  deletePillByNavigation(deleteNavigation: PillNavigationDelete): void {
    this.deletePill(deleteNavigation.label)
    /**
     * Apply expected behavior to backspace (deletes backward) and delete (deletes forward)
     */
    switch (deleteNavigation.key) {
      case KeyboardEventKey.BACKSPACE:
        if (deleteNavigation.previousSibling) {
          deleteNavigation.previousSibling.focus()
        } else if (deleteNavigation.nextSibling) {
          deleteNavigation.nextSibling.focus()
        }
        break
      case KeyboardEventKey.DELETE:
        if (deleteNavigation.nextSibling) {
          deleteNavigation.nextSibling.focus()
        } else if (deleteNavigation.previousSibling) {
          deleteNavigation.previousSibling.focus()
        }
        break
      default:
        return
    }
  }
}

export function trimPillInput(items: string[]): string[] {
  return items.map(value => value.trim()).filter(isOfNonZeroLength)
}
