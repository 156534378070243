import { Injectable } from '@angular/core'
import { ApiClient, ApiClientFactory, serializeHttpParams } from '@ftr/api-shared'
import { CourtSystemIdQuery } from '@ftr/contracts/api/shared'
import {
  SharingRecordingsFeature,
  UpdateSharingRecordingFeatureBody,
} from '@ftr/contracts/api/sharing-recordings-feature'
import { Uuid } from '@ftr/contracts/type/shared'
import { ApiResult, createAndAssign, mapData } from '@ftr/foundation'
import { classToPlain } from '@ftr/serialization'
import { FeatureService } from './feature.service'

@Injectable()
export class SharingRecordingsFeatureService extends FeatureService<SharingRecordingsFeature> {
  private readonly apiClient: ApiClient

  constructor(private apiClientFactory: ApiClientFactory) {
    super()
    this.apiClient = this.apiClientFactory.build('/sharing-recordings-feature')
  }

  isEnabled(courtSystemId: Uuid): ApiResult<boolean> {
    return this.getForCourtSystem(courtSystemId).pipe(mapData(d => d.isEnabled))
  }

  getForCourtSystem(courtSystemId: Uuid): ApiResult<SharingRecordingsFeature> {
    return this.apiClient.get<SharingRecordingsFeature>({
      path: '/getForCourtSystem',
      params: serializeHttpParams(createAndAssign(CourtSystemIdQuery, { courtSystemId })),
    })
  }

  updateFeature(featureId: Uuid, body: UpdateSharingRecordingFeatureBody): ApiResult<SharingRecordingsFeature> {
    return this.apiClient.patch({
      path: `/${featureId}`,
      body: classToPlain(body),
    })
  }
}
