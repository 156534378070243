import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms'

export function isUntypedFormGroup(control: AbstractControl): control is UntypedFormGroup {
  if ('controls' in control) {
    const controlWithControls = control as typeof control & { controls: unknown }
    if (isPOJO(controlWithControls.controls)) {
      return true
    }
  }
  return false
}

export function isUntypedFormArray(control: AbstractControl): control is UntypedFormArray {
  if ('controls' in control && 'length' in control) {
    const controlWithControls = control as typeof control & { controls: unknown; length: unknown }
    if (Array.isArray(controlWithControls.controls)) return true
  }
  return false
}

function isPOJO(obj: any): obj is object {
  return obj && typeof obj === 'object' && Object.getPrototypeOf(obj) === Object.prototype
}
