import { Injectable } from '@angular/core'
import { ApiClient, ApiClientFactory, serializeHttpParams } from '@ftr/api-shared'
import { CourtSystemIdQuery } from '@ftr/contracts/api/shared'
import { BillingGroup } from '@ftr/contracts/api/user-group/BillingGroup'
import { Uuid } from '@ftr/contracts/type/shared'
import { ApiResult } from '@ftr/foundation'
import { UserGroupService } from '@ftr/ui-user'

@Injectable()
export class BillingGroupService extends UserGroupService<BillingGroup> {
  private readonly userApiClient: ApiClient

  constructor(apiClientFactory: ApiClientFactory) {
    super(apiClientFactory)
    this.userApiClient = apiClientFactory.build('user')
  }

  /**
   * Returns true if given billing group can view order prices
   */
  canViewOrderPrices(billingGroup: BillingGroup): boolean {
    return billingGroup.configuration.viewOrderPrices
  }

  /**
   * Returns custom payment message if defined, otherwise returns default message.
   */
  getBilledToUserGroupMessage(billingGroup: BillingGroup, isMultiDayOrder: boolean = false): string {
    return (
      billingGroup.configuration.customPaymentMessage ||
      `As a member of ${billingGroup.name}, payment is not required for ${
        isMultiDayOrder ? 'these orders' : 'this order'
      }.`
    )
  }

  getBillingGroupForAuthenticatedUserInCourt(courtSystemId: Uuid): ApiResult<BillingGroup | null> {
    const params: CourtSystemIdQuery = { courtSystemId }
    return this.userApiClient.get({
      path: 'billing-group',
      params: serializeHttpParams(params),
      responseBodyType: BillingGroup,
    })
  }

  getBillingGroupForUserInCourt(userId: Uuid, courtSystemId: Uuid): ApiResult<BillingGroup | null> {
    const params: CourtSystemIdQuery = { courtSystemId }
    return this.userApiClient.get({
      path: `${userId}/billing-group`,
      params: serializeHttpParams(params),
      responseBodyType: BillingGroup,
    })
  }

  protected override getPath(): string {
    return '/billing-group'
  }
}
