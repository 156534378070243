import { Inject, Injectable } from '@angular/core'
import { AUTH_CONFIGURATION, AuthConfiguration } from '@ftr/api-shared'
import { CognitoUser } from 'amazon-cognito-identity-js'

@Injectable({
  providedIn: 'root',
})
export class CognitoUserFactory {
  constructor(@Inject(AUTH_CONFIGURATION) readonly configurationService: AuthConfiguration) {}

  create(username: string): CognitoUser {
    return new CognitoUser({
      Username: username.toLowerCase(),
      Pool: this.configurationService.userPool,
    })
  }
}
