import { WebFeatureFlags } from '../feature-flags'

export class SetFeatureFlagValues {
  static readonly type = '[FeatureFlag] Set Feature Flag Values'

  constructor(
    public featureFlags: Partial<WebFeatureFlags>,
    public hasInitializedNonDefaultFlagValues: boolean,
  ) {}
}
