import { Injectable } from '@angular/core'
import { CustomerOrder, PaymentWaivedReason } from '@ftr/contracts/api/order'
import { Charge } from '@ftr/contracts/read/charge'
import { ChargeStatus, Money, hasUnwaivableFees } from '@ftr/contracts/type/order'
import { TranscriptOrder } from '../../types'
import { isRealTimeLineItem } from '../../utils'

export interface PricesViewModel {
  balance?: Money
  finalPrice?: Money
  estimateCharge?: Charge
  balanceCharge?: Charge
  serviceFee?: Money
  connectionFee?: Money
  perMinuteFee?: Money

  /**
   * If the initially authorized amount for the order has been partially or totally released, this property holds the
   * amount released. Otherwise, it is undefined.
   */
  fundsReleased?: Money
  /**
   * For orders prior to the introduction of the auth/settle order charge flow, a manual refund may be required when
   * the final price is greater than the estimate initially settled.
   */
  refund?: Money
  /**
   * The settlement amount to display to the customer based off the estimate charge details.
   *
   * This is based off the settlement amount on the charge itself, but also, when settlement fails, this property
   * contains a settlement of zero to reassure the customer that no money has been taken.
   */
  fundsSettled?: Money
}

export type PricesOrderType = CustomerOrder | TranscriptOrder

@Injectable()
export class OrderPricesService {
  shouldShowPrices(order: CustomerOrder): boolean {
    if (order.paymentWaivedReason === PaymentWaivedReason.BilledToUserGroup) {
      return false
    }

    if (hasUnwaivableFees(order.lineItem)) {
      return true
    }

    return order.paymentWaivedReason !== PaymentWaivedReason.CostWaiverRequested
  }

  buildPricesViewModel(order: PricesOrderType): PricesViewModel {
    const { serviceFee, balance, balanceCharge, estimateCharge, finalPrice } = order
    const realTimePrices = getRealTimePrices(order)
    return {
      ...realTimePrices,
      serviceFee,
      balance,
      balanceCharge,
      estimateCharge,
      finalPrice,
      fundsReleased: deriveFundsReleased(order),
      refund: deriveRefund(order),
      fundsSettled: deriveFundsSettled(order),
    }
  }
}

function getRealTimePrices(order: PricesOrderType): PricesViewModel {
  if (order instanceof TranscriptOrder || !isRealTimeLineItem(order.lineItem)) {
    return {}
  }
  return {
    connectionFee: order.lineItem.connectionFeePaid,
    perMinuteFee: order.lineItem.perMinuteFeePaidTotal,
  }
}

function deriveFundsReleased(order: PricesOrderType): Money | undefined {
  const charge = order.estimateCharge
  if (!charge) {
    return undefined
  }
  if (charge.status === ChargeStatus.Released || charge.status === ChargeStatus.SettlementFailed) {
    return charge.authorizedAmount
  }

  if (
    charge.status === ChargeStatus.Settled &&
    charge.authorizedAmount &&
    charge.settlementAmount!.amount.isLessThan(charge.authorizedAmount.amount)
  ) {
    return new Money(
      charge.authorizedAmount.amount.minus(charge.settlementAmount!.amount),
      charge.authorizedAmount.currency,
    )
  }

  return undefined
}

function deriveFundsSettled(order: PricesOrderType): Money | undefined {
  const charge = order.estimateCharge
  if (!charge) {
    return undefined
  }

  if (charge.settlementAmount) {
    return charge.settlementAmount
  }

  if (!charge.authorizedAmount) {
    return undefined
  }

  if (charge.status === ChargeStatus.SettlementFailed) {
    // When settlement fails, we want to reassure the customer we took nothing off the credit card
    return new Money(0, charge.authorizedAmount.currency)
  }

  return undefined
}

function deriveRefund(order: PricesOrderType): Money | undefined {
  if (order.balance && order.balance.amount.isLessThan(0)) {
    return new Money(order.balance.amount.absoluteValue(), order.balance.currency)
  }

  return undefined
}
