<div
  class="panel"
  [ngClass]="{
    'panel--flat': panelStyle === 'flat',
    'panel--fluid': panelStyle === 'fluid',
    'panel--narrow': panelStyle === 'narrow',
    'panel--centered': panelStyle === 'centered',
    'panel--fill': displayMode === 'FILL',
  }"
>
  @if (panelStyle === 'narrow' || panelStyle === 'centered') {
    <div class="panel__content">
      <ng-container *ngTemplateOutlet="childContent"></ng-container>
    </div>
  } @else {
    <ng-container *ngTemplateOutlet="childContent"></ng-container>
  }
</div>

<ng-template #childContent><ng-content></ng-content></ng-template>
