import { Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core'
import {
  BUTTON_COLORS,
  BUTTON_DIRECTIONS,
  BUTTON_DISPLAY_TYPES,
  BUTTON_SIZES,
  ButtonColor,
  ButtonDirection,
  ButtonDisplayType,
  ButtonSize,
} from './button'

@Directive()
export class ButtonBase implements OnInit, OnChanges {
  /**
   * The color of the button. By default the button is rendered using our Primary
   * color style.
   *
   * Note: this may only be set when the button is first rendered. Any subsequent
   * changes to this value will not take any effect.
   */
  @Input() color?: ButtonColor = 'primary'

  /**
   * The display type for the button (text or icon).
   *
   * Note: this may only be set when the button is first rendered. Any subsequent
   * changes to this value will not take any effect.
   */
  @Input() displayType?: ButtonDisplayType

  /**
   * The size the button should take up on the page.
   *
   * Note: this may only be set when the button is first rendered. Any subsequent
   * changes to this value will not take any effect.
   */
  @Input() size?: ButtonSize

  /**
   * Whether to span the button across the full width of the page or not.
   *
   * Note: this may only be set when the button is first rendered. Any subsequent
   * changes to this value will not take any effect.
   */
  @Input() fullWidth?: boolean = false

  /**
   * Whether to span the button across the full width of the page when in mobile.
   *
   * Note: this may only be set when the button is first rendered. Any subsequent
   * changes to this value will not take any effect.
   */
  @Input() fullWidthMobile?: boolean = false

  @Input() flexLayout?: boolean = false

  /**
   * Lets the button calculate its own width
   *
   * Useful for icon buttons
   */
  @Input() autoWidth?: boolean = false

  /**
   * Used when this button:
   * - Is a primary action button, and:
   *   - Navigates to a new page
   *   - Navigates to the next step in a wizard
   *
   * Note: this may only be set when the button is first rendered. Any subsequent
   * changes to this value will not take any effect.
   */
  @Input() direction?: ButtonDirection

  /**
   * When true, if the button is being rendered as a `button` element, then adds a `disabled="disabled"` attribute
   * to the element's attributes. If the button is being rendered as an `a` tag (which doesn't support the
   * `disabled` attribute) it instead adds a `disabled` class to the element's class list.
   */
  @Input() disabled?: boolean = false

  private prefix = 'button'
  private delimiter = '--'

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.setElement()
  }

  ngOnChanges({ disabled }: SimpleChanges): void {
    if (disabled !== undefined) {
      if (this.el.nativeElement instanceof HTMLButtonElement) {
        this.applyAttribute('disabled', 'disabled', !!disabled.currentValue)
      } else {
        this.applyClass('disabled', !!disabled.currentValue)
      }
    }
  }

  private setElement(): void {
    this.applyClass(this.prefix)

    if (this.displayType && BUTTON_DISPLAY_TYPES.includes(this.displayType)) {
      this.applyClass(`${this.prefix}${this.delimiter}${this.displayType}`)

      if (this.displayType === 'icon') {
        this.applyClass(`${this.prefix}${this.delimiter}text`)
      }
    }

    if (this.autoWidth) {
      this.applyClass(`${this.prefix}${this.delimiter}${this.displayType}--auto-width`)
    }

    if (this.color && BUTTON_COLORS.includes(this.color)) {
      this.applyClass(`${this.prefix}${this.delimiter}${this.color}`)
    }

    if (this.size && BUTTON_SIZES.includes(this.size)) {
      this.applyClass(`${this.prefix}${this.delimiter}${this.size}`)
    }

    if (this.direction && BUTTON_DIRECTIONS.includes(this.direction)) {
      this.applyClass(`${this.prefix}${this.delimiter}${this.direction}`)
    }

    if (this.fullWidth) {
      this.applyClass(`${this.prefix}${this.delimiter}full-width`)
    }

    if (this.fullWidthMobile) {
      this.applyClass(`${this.prefix}${this.delimiter}full-width-mobile`)
    }

    if (this.flexLayout) {
      this.applyClass(`${this.prefix}${this.delimiter}flex-layout`)
    }
  }

  private applyClass(cssClass: string, shouldAddClass = true): void {
    if (shouldAddClass) {
      this.renderer.addClass(this.el.nativeElement, cssClass)
    } else {
      this.renderer.removeClass(this.el.nativeElement, cssClass)
    }
  }

  private applyAttribute(attributeName: string, attributeValue: string, shouldAddAttribute = true): void {
    if (shouldAddAttribute) {
      this.renderer.setAttribute(this.el.nativeElement, attributeName, attributeValue)
    } else {
      this.renderer.removeAttribute(this.el.nativeElement, attributeName)
    }
  }
}
