import { Injectable } from '@angular/core'

import { AuthenticationService } from '@ftr/ui-user'

/**
 * This resolver provides the user's logged in status that
 * is be injected into the Help & Support page
 */
@Injectable()
export class LoggedInResolver {
  constructor(private authenticationService: AuthenticationService) {}

  resolve(): Promise<boolean> {
    return this.authenticationService.isLoggedIn
  }
}
