import { sha256 } from 'js-sha256'

export function base64URLEncode(input: string): string {
  return btoa(input).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
}

export function toSha256(input: string): string {
  let result = ''
  const byteArray = sha256.array(input)
  for (const b of byteArray) {
    result += String.fromCharCode(b)
  }
  return result
}
