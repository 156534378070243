/* eslint-disable max-classes-per-file */
import { LiveSttResult } from '@ftr/contracts/regional-api/live-stt'
import { Region } from '@ftr/contracts/type/court/Region'
import { Uuid } from '@ftr/contracts/type/shared'
import { RealTimePlaybackKey } from '@ftr/data-realtime-playback'
import { LocalDateTime, LocalTime } from '@js-joda/core'
import { RealTimeSttPauseConfiguration, RealTimeSttSegmentMap } from '../../types'
import { RealTimeSttSourceStateModel } from './real-time-stt.model'

export interface RealTimeSttAction {
  readonly playbackKey: RealTimePlaybackKey
}

export class UpdateRealTimeSttSourceStateSegmentMapAction implements RealTimeSttAction {
  static readonly type = '[RealTimeStt] Update Source Segment Map'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly segmentMap: RealTimeSttSegmentMap,
  ) {}
}

export class FetchRealTimeSttSegmentMapAction implements RealTimeSttAction {
  static readonly type = '[RealTimeStt] Fetch Source Segment Map'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly courtSystemId: Uuid,
    readonly recordingStartTime: LocalDateTime,
    readonly pauseConfiguration: RealTimeSttPauseConfiguration,
    readonly canReadStt: boolean,
  ) {}
}

export class UpsertRealTimeSttLiveRemarksAction implements RealTimeSttAction {
  static readonly type = '[RealTimeStt] Upsert Live Remarks'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly update: LiveSttResult,
  ) {}
}

export class StreamRealTimeSttSegmentMapLiveUpdatesAction implements RealTimeSttAction {
  static readonly type = '[RealTimeStt] Stream Segment Map Updates'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly courtSystemId: Uuid,
    readonly region: Region,
    readonly canAccessSealedContent: boolean,
  ) {}
}

export class SetRealTimeSttFatalErrorLiveStreamingAction implements RealTimeSttAction {
  static readonly type = '[RealTimeStt] Set Fatal Error'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly fatalError: boolean,
  ) {}
}

export class SetRealTimeSttFailedToSetupConnectionAction implements RealTimeSttAction {
  static readonly type = '[RealTimeStt] Failed to setup RealTime stt connection'

  constructor(readonly playbackKey: RealTimePlaybackKey) {}
}
export class StopRealTimeSttSegmentMapLiveUpdatesAction implements RealTimeSttAction {
  static readonly type = '[RealTimeStt] Stop Streaming Segment Map Live Updates'

  constructor(readonly playbackKey: RealTimePlaybackKey) {}
}

export class SetRealTimeSttIsLivePinnedAction implements RealTimeSttAction {
  static readonly type = '[RealTimeStt] Set Stt is Live Pinned'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly isLivePinned: boolean,
  ) {}
}

export class SetRealTimeSttSourceStateAction implements RealTimeSttAction {
  static readonly type = '[RealTimeStt] Set Stt Source State'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly state: RealTimeSttSourceStateModel,
  ) {}
}

export class JumpRealTimeSttToTimestampAction implements RealTimeSttAction {
  static readonly type = '[RealTimeStt] Jump Stt To Timestamp'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly timestamp: LocalTime,
  ) {}
}

export class JumpRealTimeSttToTranscriptElementAction implements RealTimeSttAction {
  static readonly type = '[RealTimeStt] Jump Stt To Transcript Element'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly transcriptElementId: string,
    readonly segmentId: string,
  ) {}
}

export class JumpRealTimeSttToHearingSectionAction implements RealTimeSttAction {
  static readonly type = '[RealTimeStt] Jump Stt To Hearing Section'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly hearingSectionId: Uuid,
  ) {}
}

export class JumpRealTimeSttToLiveAction implements RealTimeSttAction {
  static readonly type = '[RealTimeStt] Jump Stt To Live'

  constructor(readonly playbackKey: RealTimePlaybackKey) {}
}

export class HighlightRealTimeSttTranscriptElementsAtTimestamp implements RealTimeSttAction {
  static readonly type = '[RealTimeStt] Highlight remarks at timestamp'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly timestamp: LocalDateTime,
  ) {}
}

export class MarkRealTimeSttRemarksToBeSealedDuringTimeRange implements RealTimeSttAction {
  static readonly type = '[RealTimeStt] Mark remarks to be sealed during time range'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly startTime: LocalDateTime | undefined,
    readonly endTime: LocalDateTime | undefined,
  ) {}
}

export class FetchRealTimeSttEnabledAction implements RealTimeSttAction {
  static readonly type = '[RealTimeStt] Fetch RealTime Stt Enabled'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly courtSystemId: Uuid,
    readonly locationId: Uuid,
  ) {}
}

export class FetchRealTimeSttCanSealContentAction implements RealTimeSttAction {
  static readonly type = '[RealTimeStt] Fetch RealTime Stt Can Seal Content'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly courtSystemId: Uuid,
  ) {}
}

export class ShowRealTimeSttPopover implements RealTimeSttAction {
  static readonly type = '[RealTimeStt] Show Real Time Stt Popover'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly element: HTMLElement,
    readonly id: string,
  ) {}
}
