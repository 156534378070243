import { Injectable } from '@angular/core'
import { UserGroupPermissionId } from '@ftr/contracts/api/user-group'
import { ArrayUtils } from '@ftr/foundation'
import { StaticMenuItem } from '@ftr/menus'
import { UserState } from '@ftr/ui-user'
import { Store } from '@ngxs/store'
import { combineLatest, distinctUntilChanged, map, Observable } from 'rxjs'
import { CourtFooterMenuItemsProvider } from '../../lib/court-footer-menu-items-provider/court-footer-menu-items.provider'
import { CourtSystemNavItems } from '../court-system-nav-items'
import { GlobalNavItems } from '../global-nav-items'
import { NavItemsProvider } from '../nav-item'
import { TranscriberNavItems } from '../transcriber-nav-items'

@Injectable({
  providedIn: 'root',
})
export class FooterMenuItemsProvider implements NavItemsProvider {
  constructor(
    private navItems: GlobalNavItems,
    private courtNavItems: CourtSystemNavItems,
    private transcriberNavItems: TranscriberNavItems,
    private store: Store,
    private readonly courtNavItemProvider: CourtFooterMenuItemsProvider,
  ) {}

  provide(): Observable<StaticMenuItem[]> {
    return combineLatest([
      this.store.select(UserState.currentUserGroups),
      this.store.select(UserState.currentCourtSystem),
      this.store
        .select(UserState.hasPermissionInCourtSystem)
        .pipe(map(fn => fn(UserGroupPermissionId.PlaybackRecordings))),
      this.store
        .select(UserState.hasPermissionInAnyCourtSystem)
        .pipe(map(fn => fn(UserGroupPermissionId.TranscribeRecordings))),
      this.courtNavItemProvider.provide(),
    ]).pipe(
      distinctUntilChanged(ArrayUtils.shallowEquals),
      map(([userGroups, courtSystem, hasPlaybackRecordingsPermission, hasTranscriberPermissions]) => {
        const menuItems = [
          this.navItems.myOrders(),
          this.navItems.orderLive(),
          this.navItems.orderTranscript(),
          this.navItems.orderAudio(),
          this.navItems.sharedRecordings(),
          this.navItems.myUploads(),
          this.navItems.accountDetails(),
        ]

        if (courtSystem?.id) {
          if (hasPlaybackRecordingsPermission) {
            menuItems.unshift(this.courtNavItems.recordings(courtSystem.id))
          }
          if (hasTranscriberPermissions) {
            menuItems.unshift(this.transcriberNavItems.jobsList())
          }

          const courtSystems = userGroups.data
            ?.map(s => s.courtSystemId)
            .filter((value, index, self) => {
              return self.indexOf(value) === index
            })

          if (courtSystems && courtSystems.length > 1) {
            menuItems.push(this.navItems.selectCourtSystem())
          }
        }
        return menuItems
      }),
    )
  }
}
