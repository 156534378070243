import { Injectable } from '@angular/core'
import { Uuid } from '@ftr/contracts/type/shared'
import { StaticMenuItem } from '@ftr/menus'
import { AppPaths } from '@ftr/routing-paths'

/**
 * Any links only for global admins
 */
@Injectable({
  providedIn: 'root',
})
export class GlobalAdminNavItems {
  globalAdmin(): StaticMenuItem {
    return {
      title: 'Global Admin',
      path: [`/${AppPaths.Admin}`],
      icon: 'OpenInNew',
    }
  }

  globalAdminForCourtSystem(courtSystemId: Uuid): StaticMenuItem {
    return {
      title: 'Global Admin',
      path: [`/${AppPaths.Admin}`, 'court-systems', courtSystemId],
      icon: 'OpenInNew',
    }
  }
}
