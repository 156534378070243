<ftr-pill-list-input
  [label]="label"
  name="email"
  validationLabel="email"
  [required]="required"
  [inputValidator]="validateEmailInput"
  [listControl]="listControl"
  [splitInputAt]="splitInputAt"
  [displayHintOn]="displayHintOn"
  [displayHintTemplate]="hintTemplate"
  [submitAttempted$]="submitAttempted$"
></ftr-pill-list-input>
<ng-template #hintTemplate><strong>Tip:</strong> Use a comma or enter to add a new email.</ng-template>
