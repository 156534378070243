import { Component, OnInit, ViewChild } from '@angular/core'
import { CourtSystem } from '@ftr/contracts/api/court-system'
import { isNotNullOrUndefined } from '@ftr/foundation'
import { DropdownMenuComponent, StaticMenuItem } from '@ftr/menus'
import { UserState } from '@ftr/ui-user'
import { Store } from '@ngxs/store'
import { Observable, filter } from 'rxjs'
import { CourtDropdownMenuItemsProvider } from '~app/services/navigation/court-dropdown-menu-items.provider'

@Component({
  selector: 'ftr-court-system-dropdown',
  templateUrl: './court-system-dropdown.component.html',
  styleUrls: ['./court-system-dropdown.component.css'],
})
export class CourtSystemDropdownComponent implements OnInit {
  @ViewChild(DropdownMenuComponent) dropdown: DropdownMenuComponent
  navItems$: Observable<StaticMenuItem[]>
  isDropdownOpen = false
  currentCourtSystem$: Observable<CourtSystem>

  constructor(
    private readonly navItemsProvider: CourtDropdownMenuItemsProvider,
    private readonly store: Store,
  ) {}

  ngOnInit(): void {
    this.currentCourtSystem$ = this.store.select(UserState.currentCourtSystem).pipe(filter(isNotNullOrUndefined))
    this.navItems$ = this.navItemsProvider.provide()
  }

  open(): void {
    this.dropdown.toggle()
  }

  setOpenState(value: boolean): void {
    this.isDropdownOpen = value
  }
}
