import { Pipe, PipeTransform } from '@angular/core'
import { VocabularyTerm } from '@ftr/contracts/type/core'

@Pipe({
  name: 'ftrVocabGrammaticalNumber',
  standalone: true,
})
export class VocabGrammaticalNumber implements PipeTransform {
  transform(vocabularyTerm: VocabularyTerm, itemCount: number): string {
    return itemCount === 1 ? vocabularyTerm.singular : vocabularyTerm.plural
  }
}
