import { Injectable } from '@angular/core'
import { Uuid } from '@ftr/contracts/type/shared'
import { unwrapData } from '@ftr/foundation'
import { MultifactorAuthenticationService } from '@ftr/ui-user'
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store'
import { patch } from '@ngxs/store/operators'
import { memoize } from 'lodash-es'
import { firstValueFrom } from 'rxjs'
import {
  FetchCourtSystemMfaRequirementsAction,
  SetCourtSystemMfaRequirementsStateAction,
} from './mfa-requirements.actions'
import { CourtSystemMfaRequirementsStateModel, MfaRequirementsStateModel } from './mfa-requirements.model'

export function defaultMfaRequirementsState(): MfaRequirementsStateModel {
  return {}
}

function defaultCourtSystemMfaRequirementsState(): CourtSystemMfaRequirementsStateModel {
  return {
    requirements: undefined,
  }
}

@State<MfaRequirementsStateModel>({
  name: 'mfaRequirementsState',
  defaults: defaultMfaRequirementsState(),
})
@Injectable()
export class MfaRequirementsState {
  @Selector()
  static allMfaRequirementsStates(state: MfaRequirementsStateModel): MfaRequirementsStateModel {
    return state
  }

  static readonly courtSystemMfaRequirementsState = memoize(
    (
      courtSystemId: Uuid,
    ): ((
      sourceStates: ReturnType<typeof MfaRequirementsState.allMfaRequirementsStates>,
    ) => CourtSystemMfaRequirementsStateModel | undefined) => {
      return createSelector(
        [MfaRequirementsState],
        (sourceStates: ReturnType<typeof MfaRequirementsState.allMfaRequirementsStates>) => sourceStates[courtSystemId],
      )
    },
  )

  static readonly mfaRequirements = createMemoizedMfaRequirementsStateSelector('requirements')

  constructor(private readonly multifactorAuthenticationService: MultifactorAuthenticationService) {}

  @Action(SetCourtSystemMfaRequirementsStateAction)
  setMfaRequirementsSourceState(
    { setState }: StateContext<MfaRequirementsStateModel>,
    { courtSystemId, state }: SetCourtSystemMfaRequirementsStateAction,
  ): void {
    setState(
      patch<MfaRequirementsStateModel>({
        [courtSystemId]: state,
      }),
    )
  }

  @Action(FetchCourtSystemMfaRequirementsAction)
  async fetchCourSystemMfaRequirements(
    { getState, setState, dispatch }: StateContext<MfaRequirementsStateModel>,
    { courtSystemId, bypassCache }: FetchCourtSystemMfaRequirementsAction,
  ): Promise<void> {
    const originalState = getState()[courtSystemId]

    if (originalState?.requirements && !bypassCache) {
      return
    }

    if (!originalState) {
      await firstValueFrom(
        dispatch(new SetCourtSystemMfaRequirementsStateAction(courtSystemId, defaultCourtSystemMfaRequirementsState())),
      )
    }

    const courtSystemMfaRequirements = await firstValueFrom(
      this.multifactorAuthenticationService.getRequirementConfiguration(courtSystemId).pipe(unwrapData()),
    )
    setState(
      patch({
        [courtSystemId]: patch({
          requirements: courtSystemMfaRequirements.mfaRequirements,
        }),
      }),
    )
  }
}

function createMemoizedMfaRequirementsStateSelector<T extends keyof CourtSystemMfaRequirementsStateModel>(
  property: T,
): (
  courtSystemId: Uuid,
) => (sourceStates: CourtSystemMfaRequirementsStateModel | undefined) => CourtSystemMfaRequirementsStateModel[T] {
  return memoize(
    (
      courtSystemId: Uuid,
    ): ((
      sourceStates: CourtSystemMfaRequirementsStateModel | undefined,
    ) => CourtSystemMfaRequirementsStateModel[T]) => {
      const selectedMfaRequirementsState = MfaRequirementsState.courtSystemMfaRequirementsState(courtSystemId)
      return createSelector(
        [selectedMfaRequirementsState],
        (state: ReturnType<typeof selectedMfaRequirementsState>) => {
          return state?.[property] ?? defaultCourtSystemMfaRequirementsState()[property]
        },
      )
    },
  )
}
