import { ModuleWithProviders, NgModule } from '@angular/core'
import { TimeRangeService } from '@ftr/ui-playback'
import { JobService, TranscriptFileUploadService } from '@ftr/ui-transcript'

@NgModule({
  providers: [JobService],
})
export class JobServiceModule {
  static forRoot(): ModuleWithProviders<JobServiceModule> {
    return {
      ngModule: JobServiceModule,
      providers: [TranscriptFileUploadService, TimeRangeService],
    }
  }
}
