import { Injectable } from '@angular/core'
import { RegionalApiClientFactory, RegionalApiClientSetupService } from '@ftr/api-regional'
import { ApiClient } from '@ftr/api-shared'
import { SttSpeaker, SttUtteranceWithContent, addSpeakerToUtterances } from '@ftr/contracts/regional-api/stt'
import { DataStore, Uuid } from '@ftr/contracts/type/shared'
import { ApiResult, mapData } from '@ftr/foundation'
import { switchMap } from 'rxjs'
import { RealTimeSttWithRemarks } from '../../types'
import { utterancesToRemarks } from '../../utils'

@Injectable({
  providedIn: 'root',
})
export class RealTimeCourtRecordingSttService extends RegionalApiClientSetupService {
  override readonly regionalApiPath = 'stt'

  constructor(regionalApiClientFactory: RegionalApiClientFactory) {
    super(regionalApiClientFactory)
  }

  getSttWithRemarks(courtSystemId: Uuid, recordingId: Uuid, canReadStt: boolean): ApiResult<RealTimeSttWithRemarks> {
    return canReadStt
      ? this.regionalApiClient(this.regionalApiPath, courtSystemId).pipe(
          switchMap(apiClient =>
            ApiResult.combine([
              this.getSpeakers(apiClient, courtSystemId, recordingId),
              this.getUtterances(apiClient, courtSystemId, recordingId),
            ]).pipe(
              mapData(([speakers, utterances]) => {
                return {
                  recordingId,
                  remarks: utterancesToRemarks(addSpeakerToUtterances(utterances, speakers)),
                }
              }),
            ),
          ),
        )
      : ApiResult.success({ recordingId, remarks: [] })
  }

  private getSpeakers(apiClient: ApiClient, courtSystemId: Uuid, recordingId: Uuid): ApiResult<SttSpeaker[]> {
    return apiClient.get({
      path: `${recordingId}/speakers`,
      headers: {
        courtSystemId,
        dataStore: DataStore.Regional,
      },
    })
  }

  private getUtterances(
    apiClient: ApiClient,
    courtSystemId: Uuid,
    recordingId: Uuid,
  ): ApiResult<SttUtteranceWithContent[]> {
    return apiClient.get({
      path: `${recordingId}/utterances`,
      headers: {
        courtSystemId,
        dataStore: DataStore.Regional,
      },
    })
  }
}
