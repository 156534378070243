export enum SignupParams {
  /**
   * Query parameter for the verification code for use during the verification process.
   */
  VerificationCode = 'code',
  /**
   * Query parameter for the username for use during the verification process
   */
  Username = 'username',
  /**
   * The email address to pre-fill into the signup form.
   */
  AuthenticationEmail = 'authenticationEmail',
  /**
   * The default action for sign up
   */
  AuthenticationDefaultAction = 'authenticationDefaultAction',
}

export enum AuthenticationActions {
  /**
   * Redirect to the sign up page when authenticationDefaultAction=signup
   */
  SignUp = 'signup',
}
