<div class="app-layout">
  <div
    class="app-layout__slide"
    [ngClass]="{
      'app-layout__slide--close': !sideNavOpen,
      'app-layout__slide--open': sideNavOpen,
    }"
  >
    <div
      class="app-layout__side-panel"
      [ngClass]="{
        'app-layout__side-panel--default': !sideNavExpanded,
        'app-layout__side-panel--expanded': sideNavExpanded,
      }"
    >
      <ftr-side-nav-display
        [navGroups]="(sideNavModel | async)?.navGroups ?? []"
        [isLoading]="(sideNavModel | async)?.navGroups ? false : true"
        [footerItems]="(sideNavModel | async)?.footerItems ?? []"
        [expanded]="sideNavMode() === 'OVERLAY' ? true : sideNavExpanded"
        [currentCourtSystemId]="currentCourtSystemId()"
        [displayMode]="sideNavMode()"
        [isOpened]="sideNavOpen"
        (expandedToggled)="handleSideNavExpandedChange()"
        (sideBarToggled)="handleSideNavOpenChange()"
      ></ftr-side-nav-display>
    </div>
    <div class="app-layout__slide-backdrop" (click)="handleSideNavOpenChange(false)"></div>
  </div>
  <div class="app-layout__main-panel">
    <header class="app-layout__top-bar">
      <ftr-top-bar-display
        [currentCourtSystemId]="currentCourtSystemId()"
        (sideBarToggled)="handleSideNavOpenChange()"
      ></ftr-top-bar-display>
    </header>
    <div class="app-layout__content-wrapper">
      <main
        id="main-content"
        [ngClass]="[
          appContentWindowSelector,
          !routeData()?.whiteBackground ? appContentWindowSelectorGreyBackground : '',
        ]"
      >
        <ng-content></ng-content>
      </main>
    </div>
  </div>
</div>
