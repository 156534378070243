import {
  AudioLineItem,
  CustomerOrder,
  LineItem,
  PerMinuteFeeCharge,
  RecordingsForOrder,
  TranscriptLineItem,
} from '@ftr/contracts/api/order'
import { Quote } from '@ftr/contracts/api/quote'
import { Timeframe } from '@ftr/contracts/api/shared'
import { HearingSegment } from '@ftr/contracts/type/court'
import { toMoment } from '@ftr/foundation'
import { isNatural } from '@ftr/ui-money'
import { LocalDate } from '@js-joda/core'
import { Moment } from 'moment-timezone'
import { isAudioLineItem } from './IsLineItemType'

export function extractHearingDateMomentFromSegments(
  hearingSegments: HearingSegment[] | undefined,
): Moment | undefined {
  return hearingSegments && hearingSegments.length > 0
    ? toMoment(HearingSegment.getSingleHearingDate(hearingSegments)).moment
    : undefined
}

export function extractHearingDate<TLineItem extends AudioLineItem | TranscriptLineItem>(
  order: CustomerOrder<TLineItem>,
): LocalDate {
  return HearingSegment.getSingleHearingDate(order.lineItem.hearingSegments)
}

export function getOrderLink<TLineItem extends LineItem>(order: CustomerOrder<TLineItem>): string[] {
  return ['/orders', order.id]
}

export function hasAdditionalFees(quote?: Quote): boolean {
  if (!quote) {
    return false
  }

  return isNatural(quote.serviceFee) || isNatural(quote.paperCopyFee) || isNatural(quote.sttFee)
}

/**
 * The total duration of per minute fee charges rounded up to the nearest minute
 */
export function getTotalDurationForPerMinuteFeeCharges(charges: PerMinuteFeeCharge[] = []): number {
  return charges.reduce((acc, val) => acc + val.duration, 0)
}

export function isSealedContentAccessIncluded(lineItem: LineItem): boolean {
  return isAudioLineItem(lineItem) && lineItem.sealedContentIncluded
}

export function hasSealedSegments(recordingsForOrder: RecordingsForOrder): boolean {
  return getSealedTimeframes(recordingsForOrder).length > 0
}

function getSealedTimeframes(recordingsForOrder: RecordingsForOrder): Timeframe[] {
  return recordingsForOrder.recordings.flatMap(r => r.sealedSegments)
}
