import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ReturnUrlHandlingType, RootParams } from '@ftr/api-shared'

@Component({
  template: ' <ftr-signup [returnUrl]="returnUrl" [returnUrlHandling]="returnUrlHandling" ></ftr-signup>',
})
export class SignupPage implements OnInit {
  returnUrl: string
  returnUrlHandling: ReturnUrlHandlingType

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams[RootParams.ReturnUrl]
    this.returnUrlHandling = this.route.snapshot.queryParams.returnUrlHandling
  }
}
