<button
  class="form__submit submit"
  type="submit"
  ftrButton
  [fullWidth]="fullWidth"
  [fullWidthMobile]="fullWidthMobile"
  [direction]="direction"
  [color]="color"
  [size]="size"
  [class.button--error]="highlightErrors | async"
  [class.button--submitting]="formState === formStates.Submitting"
  [class.button--loading]="formState === formStates.Busy"
  [disabled]="formState === formStates.Submitting || formState === formStates.Busy || formState === formStates.Disabled"
  [attr.data-track]="dataTrack ? '' : null"
  [attr.data-track-group]="dataTrackGroup"
>
  @if (highlightErrors | async) {
    Error
  } @else {
    @if (icon) {
      <ftr-icon [icon]="icon" align="Top"></ftr-icon>
    }
    @switch (formState) {
      @case (formStates.Submitting) {
        {{ submittingLabel }}
        <ftr-line-loader class="submit__loader" message="" [fitToButtonBottom]="true"></ftr-line-loader>
      }
      @case (formStates.Busy) {
        {{ submitLabel }}
        <ftr-line-loader class="submit__loader" message="" [fitToButtonBottom]="true"></ftr-line-loader>
      }
      @default {
        {{ submitLabel }}
      }
    }
  }
</button>
