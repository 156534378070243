import { Component } from '@angular/core'
import { RouterLink } from '@angular/router'
import { DesktopDownloadUrlService } from '@ftr/api-core'
import { isInDesktopApp } from '@ftr/foundation'

@Component({
  selector: 'ftr-footer-help-links',
  templateUrl: './footer-help-links.component.html',
  styleUrls: ['./footer-help-links.component.css'],
  imports: [RouterLink],
  standalone: true,
})
export class FooterHelpLinksComponent {
  downloadAppUrl: string
  showDesktopDownloadLink = !isInDesktopApp()

  constructor(private readonly desktopDownloadUrlService: DesktopDownloadUrlService) {
    this.downloadAppUrl = this.desktopDownloadUrlService.getDownloadUrl()
  }
}
