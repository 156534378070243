import { Directive, HostListener } from '@angular/core'

@Directive({
  standalone: true,
  selector: '[ftrStopPropagation]',
})
export class StopPropagationDirective {
  @HostListener('click', ['$event'])
  @HostListener('keydown', ['$event'])
  public onClick(event: MouseEvent | KeyboardEvent): void {
    event.stopPropagation()
  }
}
