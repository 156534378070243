// Disabled, because of a cyclic dependency error. It is possible to fix, but I fear the change would be quite large
// for a lot of developers (It means removing the static RemoteData.success() etc.)
// eslint-disable-next-line max-classes-per-file
import { EMPTY, Observable, of } from 'rxjs'
import { ApiResult } from './api-result'
import { CombinedError } from './combined-error'

const impossibleError = 'This is not possible'

/**
 * A class to encapsulate data retrieved from a remote source. Should be created using the static methods based
 * on the various states the remote data can be in. Best used with authentication/http/remote-data for fetching
 * data and shared/remote for display.
 */
export abstract class RemoteData<TData, TError = Error> {
  /**
   * Creates a new RemoteData in the not asked state
   */
  static notAsked(): NotAsked {
    return NotAsked.INSTANCE
  }

  /**
   * Creates a new RemoteData in the loading state
   */
  static loading(): Loading {
    return Loading.INSTANCE
  }

  /**
   * Creates a new RemoteData in the failure state
   */
  static failure<TData = never, TError = Error>(error: TError): Failure<TData, TError> {
    return new Failure(error)
  }

  /**
   * Creates a new RemoteData in the success state
   */
  static success<TData>(data: TData): Success<TData> {
    return new Success(data)
  }

  /**
   * If both RemoteData's are Success, applies the function and returns a result, otherwise it returns first
   * failure/loading
   *
   * Priority of emition if both are not success
   *
   * - Failure
   * - Loading
   * - NotAsked
   */
  static map2<TData, TData2, TData3, TError = Error>(
    a: RemoteData<TData, TError>,
    b: RemoteData<TData2, TError>,
    f: (data1: TData, data2: TData2) => TData3,
  ): RemoteData<TData3, TError> {
    if (a.isSuccess() && b.isSuccess()) {
      return RemoteData.success(f(a._data, b._data))
    } else if (a.isFailure()) {
      // A's failure, is more important than B's, using standard the standard fail fast mentality
      return a.patchSuccessToNever()
    } else if (b.isFailure()) {
      return b.patchSuccessToNever()
    } else if (a.isLoading()) {
      return a
    } else if (b.isLoading()) {
      return b
    } else if (a.isNotAsked()) {
      return a
    } else if (b.isNotAsked()) {
      return b
    }
    // Actually unreachable
    return a.patchSuccessToNever()
  }

  static combine<T extends Record<string, RemoteData<unknown, unknown>>>(
    remotes: T,
  ): RemoteData<{ [K in keyof T]: T[K] extends RemoteData<infer U> ? U : never }, CombinedError> {
    if (Object.values(remotes).every(remote => remote.isNotAsked())) {
      return RemoteData.notAsked()
    }

    const failures = Object.values(remotes).filter((remote): remote is Failure => remote.isFailure())
    if (failures.length) {
      return RemoteData.failure(new CombinedError(failures.map(failure => failure._error)))
    }

    if (Object.values(remotes).every(remote => remote.isSuccess())) {
      return RemoteData.success(
        Object.fromEntries(Object.entries(remotes).map(([key, remote]) => [key, remote.data])),
      ) as RemoteData<any, never>
    }

    return RemoteData.loading()
  }

  isCompleted(): boolean {
    return this.isSuccess() || this.isFailure()
  }

  isSuccess(): this is Success<TData, TError> {
    return this instanceof Success
  }

  isFailure(): this is Failure<TData, TError> {
    return this instanceof Failure
  }

  isLoading(): this is Loading {
    return this instanceof Loading
  }

  isNotAsked(): this is NotAsked {
    return this instanceof NotAsked
  }

  /**
   * Guarded if statements now work correctly, with no undefined, unless the `TError` is `Error | undefined`
   *
   * if (remoteData.isFailure()) {
   *   remoteData._error
   * }
   *
   * TODO: FPD-235 remove this getter, and rename Success._error => Failure.error
   * @deprecated Prefer guarded if checks.
   */
  get error(): TError | undefined {
    return this.isFailure() ? this._error : undefined
  }

  /**
   * Guarded if statements now work correctly, with no undefined, unless the `TData` is `Foo | undefined`
   *
   * if (remoteData.isSuccess()) {
   *   remoteData._data
   * }
   *
   * TODO: FPD-235 remove this getter, and rename Success._data => Success.data
   * @deprecated Prefer guarded if checks.
   */
  get data(): TData | undefined {
    return this.isSuccess() ? this._data : undefined
  }

  /**
   * Allows you to modify 'this.data'
   *
   * Modifies the data on success, otherwise returns this.
   *
   * @example:
   *
   * RemoteData<CourtSystem, Error>.map(courtSystem => courtSystem.uuid)
   * returns
   * RemoteData<Uuid, Error>
   */
  map<TData2>(_: (data: TData) => TData2): RemoteData<TData2, TError> {
    return this.patchSuccessToNever()
  }

  /**
   * Allows mapping to a RemoteData without double wrapping the data. Never double wrap ;)
   */
  flatMap<TData2>(_: (data: TData) => RemoteData<TData2, TError>): RemoteData<TData2, TError> {
    return this.patchSuccessToNever()
  }

  /**
   * Conditionally convert a Success into a Failure.
   *
   * Loading/Failure/NotAsked get passed along without modification.
   *
   * @example:
   *
   * // Turns all even numbers into RemoteData.failure
   * RemoteData<number, Error>.toFailure(n => isEven(n), n => new Error(`Even numbers are not allowed ${n}`))
   * returns
   * RemoteData<number, Error>
   */
  toFailure<TNarrowed extends TData>(
    _: (data: TData) => data is TNarrowed,
    __: (data: TData) => TError,
  ): RemoteData<TNarrowed, TError>
  toFailure(_: (data: TData) => boolean, __: (data: TData) => TError): RemoteData<TData, TError>
  toFailure(_: (data: TData) => boolean, __: (data: TData) => TError): RemoteData<TData, TError> {
    return this.patchSuccessToNever()
  }

  /**
   * Turns Success(null) and Success(undefined) into a Failure(SomeError)
   *
   * @example:
   *
   * // Turning API response 204's into actual errors that the user sees
   * RemoteData<Remarks[] | null, Error>.failUndefined(() => new Error(`No remarks for this time period`))
   * returns
   * RemoteData<Remarks[], Error>
   */
  failUndefined(errorHandler: () => TError): RemoteData<Exclude<TData, undefined | null>, TError> {
    return this.toFailure(
      (x): x is Exclude<TData, undefined | null> => x === undefined || x === null,
      _ => errorHandler(),
    )
  }

  /**
   * Run a side effect if the remote data is a success
   *
   * @example
   *
   * RemoteData<CourtSystem, Error>.tap(courtSystem => (this.courtSystemId = courtSystem.uuid))
   */
  tap(_: (t: TData) => void): this {
    return this
  }

  /**
   * Run a side effect using the error if the remote data is a failure
   *
   * @example
   *
   * RemoteData<CourtSystem, Error>.tapFailure(error => alert('Whoops'))
   */
  tapFailure(_: (t: TError) => void): this {
    return this
  }

  /**
   * Run a side effect if this is in the loading state
   *
   * @example (Form submission with a loading state)
   *
   * RemoteData<CourtSystem, Error>.tapLoading(() => (this.isSubmitting = true))
   */
  tapLoading(_: () => void): this {
    return this
  }

  /**
   * Allows you to modify 'this.error'
   *
   * Modifies the error on failure, otherwise returns the original remote data
   * Useful for changing the error type, or changing error messages.
   *
   * @example:
   *
   * RemoteData<CourtSystem, Error>.mapFailure(error => error.message)
   * returns
   * RemoteData<CourtSystem, string>
   */
  mapFailure<TError2>(_: (t: TError) => TError2): RemoteData<TData, TError2> {
    return this.patchFailureToNever()
  }

  get(): TData | undefined {
    if (this.isSuccess()) {
      return this._data
    }
    return undefined
  }

  /**
   * Apply a function to 'this.error', potentially recovering from a failure, turning the RemoteData into a
   * successful state
   *
   *
   * Useful for returning default values if an error is of a certain type.
   *
   * @example
   * RemoteData<Courtroom[], Error>
   *   .attemptRecover(err => err instanceof NotFoundError ? ApiResult.success([]) : ApiResult.error(err))
   */
  attemptRecover<TData2>(_: (err: TError) => ApiResult<TData | TData2, TError>): ApiResult<TData | TData2, TError> {
    return of(this)
  }

  /**
   * Allows you to make an api call, when this remote data is a success,
   * using the result of that api call as the returned observable
   *
   * Essentially flatMap, where the result of the mapped function is an ApiResult
   */
  traverse<TData2>(_: (e: TData) => ApiResult<TData2, TError>): ApiResult<TData2, TError> {
    return of(this.patchSuccessToNever())
  }

  unwrap(): Observable<TData> {
    return EMPTY
  }

  /**
   * Get the data, or return a default value if it is not available
   * @param defaultValue The value to return if the remote data is not a success
   * @returns
   * - Success -> data
   * - Failure -> defaultValue
   * - Loading -> defaultValue
   * - NotAsked -> defaultValue
   */
  unwrapOr<T>(defaultValue: T): TData | T {
    return defaultValue
  }

  // We know that in success/loading states, we never populate the 'error' field
  //
  // We don't need to create a new instance of RemoteData when the RemoteData.type changes.
  // This may also help with change detection/debouncing, as we are using the original instance
  private patchFailureToNever(): RemoteData<TData, never> {
    if (!this.isFailure()) {
      return this as unknown as RemoteData<TData, never>
    }

    // This should never be called with RemoteData.failure
    throw new Error(impossibleError)
  }

  // We know that in error/loading states, we never populate the 'data' field
  //
  // We don't need to create a new instance of RemoteData when the RemoteData.type changes.
  // Provided the type is unrelated to our current state
  // This may also help with change detection/debouncing as we are using the original instance
  private patchSuccessToNever(): RemoteData<never, TError> {
    if (!this.isSuccess()) {
      return this as unknown as RemoteData<never, TError>
    }

    // This should never be called with RemoteData.success
    throw new Error(impossibleError)
  }

  abstract toString(): string
}

export class NotAsked extends RemoteData<never, never> {
  private constructor() {
    super()
  }

  static INSTANCE = new NotAsked()

  toString(): string {
    return 'Not Asked'
  }
}

export class Loading extends RemoteData<never, never> {
  private constructor() {
    super()
  }

  static INSTANCE = new Loading()

  override tapLoading(onLoading: () => void): this {
    onLoading()
    return this
  }

  toString(): string {
    return 'Loading'
  }
}

export class Failure<TData = never, TError = Error> extends RemoteData<TData, TError> {
  constructor(readonly _error: TError) {
    super()
  }

  override tapFailure(onFailure: (t: TError) => void): this {
    onFailure(this._error)
    return this
  }

  override unwrap(): Observable<TData> {
    throw this._error
  }

  override mapFailure<TError2>(onFailure: (t: TError) => TError2): Failure<TData, TError2> {
    return new Failure(onFailure(this._error))
  }

  override attemptRecover<TData2>(
    onFailure: (err: TError) => ApiResult<TData | TData2, TError>,
  ): ApiResult<TData | TData2, TError> {
    return onFailure(this._error)
  }

  toString(): string {
    return `Failure(${this._error})`
  }
}

export class Success<TData, TError = never> extends RemoteData<TData, TError> {
  constructor(readonly _data: TData) {
    super()
  }

  override toFailure(
    predicate: (data: TData) => boolean,
    errorCreator: (data: TData) => TError,
  ): RemoteData<TData, TError> {
    if (predicate(this._data)) {
      return RemoteData.failure(errorCreator(this._data))
    }
    return this
  }

  override map<TData2>(onSuccess: (data: TData) => TData2): Success<TData2, TError> {
    return new Success(onSuccess(this._data))
  }

  override flatMap<TData2>(onSuccess: (data: TData) => RemoteData<TData2, TError>): RemoteData<TData2, TError> {
    return onSuccess(this._data)
  }

  override unwrapOr(): TData {
    return this._data
  }

  override unwrap(): Observable<TData> {
    return of(this._data)
  }

  override tap(onSuccess: (t: TData) => void): this {
    onSuccess(this._data)
    return this
  }

  override traverse<TData2>(
    onSuccess: (e: TData) => Observable<RemoteData<TData2, TError>>,
  ): Observable<RemoteData<TData2, TError>> {
    return onSuccess(this._data)
  }

  toString(): string {
    return `Success(${this._data})`
  }
}

export function expectSuccess<TData, TError>(
  remoteData: RemoteData<TData, TError>,
): asserts remoteData is Success<TData, TError> {
  if (remoteData.isSuccess()) {
    return
  }
  throw new Error(`Expected ${remoteData} to be success but it wasn't`)
}
