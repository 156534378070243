// eslint-disable-next-line max-classes-per-file
import { Hearing, UpdateHearingRequest } from '@ftr/annotations-contracts'
import { Uuid } from '@ftr/contracts/type/shared'
import { RemoteData } from '@ftr/foundation'

export class FetchHearingAction {
  static readonly type = '[Hearings] Fetch Hearing'

  constructor(
    readonly courtSystemId: Uuid,
    readonly hearingId: Uuid,
  ) {}
}

export class SetHearingRemoteAction {
  static readonly type = '[Hearings] Set Hearing Remote'

  constructor(
    readonly hearingId: Uuid,
    readonly hearingRemote: RemoteData<Hearing>,
  ) {}
}

export class UpdateHearingAction {
  static readonly type = '[Hearings] Update Hearing'

  constructor(
    readonly hearingId: Uuid,
    readonly hearingUpdate: UpdateHearingRequest,
  ) {}
}

export class DeleteHearingAction {
  static readonly type = '[Hearings] Delete Hearing'

  constructor(readonly hearingId: Uuid) {}
}
