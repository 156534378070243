import { LocalTimeRange } from '@ftr/contracts/type/shared/LocalTimeRange'
import { InlineSaveStatus } from '@ftr/inline-forms'
import { LocalDate } from '@js-joda/core'
import { RealTimeSttMarkerEditSource } from './real-time-stt-marker'
import { RealTimeSttTranscriptElementBase } from './real-time-stt-transcript-element-base'

export const SEALING_MARKER_TYPES = ['sealingStart', 'sealingEnd'] as const
export type SealingMarkerType = (typeof SEALING_MARKER_TYPES)[number]

export interface RealTimeSttSealingMarker extends RealTimeSttTranscriptElementBase {
  type: 'SealingMarker'
  dividerType: SealingMarkerType
  sealedTimeRange: LocalTimeRange
  status: InlineSaveStatus<RealTimeSttSealingMarker>
  showAsOngoing: boolean
  source?: RealTimeSttMarkerEditSource
  forceResetFormKey?: string
}

export function getSealingMarkerId(markerType: SealingMarkerType, timeRange: LocalTimeRange): string {
  const startTime = markerType === 'sealingStart' ? timeRange.start : timeRange.end
  return `marker-${markerType}-${startTime.toString()}`
}

export function toSealingMarker(
  sealedTimeRange: LocalTimeRange,
  date: LocalDate,
  dividerType: SealingMarkerType,
): RealTimeSttSealingMarker {
  const startAndEndTime =
    dividerType === 'sealingStart' ? date.atTime(sealedTimeRange.start) : date.atTime(sealedTimeRange.end)
  return {
    type: 'SealingMarker',
    dividerType,
    id: getSealingMarkerId(dividerType, sealedTimeRange),
    sealedTimeRange,
    startTime: startAndEndTime,
    endTime: startAndEndTime,
    status: 'loaded',
    showAsOngoing: false,
  }
}

export function getSealingMarkersFromSealedTimeRanges(
  sealedTimeRanges: LocalTimeRange[],
  date: LocalDate,
): RealTimeSttSealingMarker[] {
  return sealedTimeRanges.flatMap(timeRange => [
    toSealingMarker(timeRange, date, 'sealingStart'),
    toSealingMarker(timeRange, date, 'sealingEnd'),
  ])
}
