<div class="court-system-dropdown" [class.court-system-dropdown--collapsed]="collapsed">
  @if (currentCourtSystemId() && courtSystem) {
    <a class="court-system-dropdown__details" [routerLink]="['/', appPaths.CourtSystem, currentCourtSystemId()]">
      @if (courtSystem.logoUrl) {
        <img [src]="courtSystem.logoUrl" [alt]="'Logo for ' + courtSystem.name" class="court-system-dropdown__icon" />
      } @else {
        <ftr-icon
          class="court-system-dropdown__icon"
          icon="Gavel"
          color="Grey30"
          size="ExtraLarge"
          align="Bottom"
        ></ftr-icon>
      }
      @if (!collapsed) {
        <div class="court-system-dropdown__name-container">
          <div class="court-system-dropdown__name">{{ courtSystem.name }}</div>
          @if (courtSystem.productionStatus !== productionStatus.Production) {
            <div class="court-system-dropdown__status">
              {{
                courtSystem.productionStatus === productionStatus.PreProduction
                  ? 'Pilot'
                  : (courtSystem.productionStatus | titlecase)
              }}
            </div>
          }
        </div>
      }
    </a>
  } @else {
    <div class="court-system-dropdown__ftr-logo" [class.court-system-dropdown--collapsed__ftr-logo]="collapsed">
      <ftr-logo
        [size]="collapsed ? logoSize.ExtraSmall : logoSize.Small"
        [theme]="collapsed ? logoTheme.ThemedNoText : logoTheme.ThemedWhite"
      ></ftr-logo>
    </div>
  }
  @if (displayMode === 'OVERLAY') {
    <ftr-icon
      icon="KeyboardArrowRight"
      size="Small"
      align="Middle"
      tabindex="0"
      class="court-system-dropdown__icon"
      (click)="dropdownOpened.emit(courtSystem)"
    ></ftr-icon>
  } @else {
    <ftr-dropdown-menu
      ariaLabel="Court system dropdown menu"
      [openerTemplate]="openerTemplate"
      [menuItems]="courtSystemMenuItems()"
      [isLeftPositioned]="collapsed"
      [invalidTargetNames]="[componentSelector]"
      data-track-group="CourtSystem Dropdown"
    >
    </ftr-dropdown-menu>
  }
</div>

<ng-template #openerTemplate>
  <div
    class="court-system-dropdown__opener"
    [class.court-system-dropdown__opener--collapsed]="collapsed"
    ftrTooltip
    [message]="currentCourtSystemId() ? 'Select or Exit a Court System' : 'Select a Court System'"
    [showDash]="false"
    position="right"
    [forceDisplayState]="collapsed ? undefined : 'hide'"
    (click)="toggle()"
  >
    <ftr-icon
      [align]="collapsed ? 'Middle' : 'Sub'"
      [size]="collapsed ? 'Large' : 'Small'"
      icon="KeyboardArrowDown"
    ></ftr-icon>
  </div>
</ng-template>
