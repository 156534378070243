import { Injectable } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { unwrapData } from '@ftr/foundation'
import {
  FeatureFlagState,
  LaunchDarklySdkClientFactory,
  SetFeatureFlagValues,
  getIdentifyKeyForUser,
} from '@ftr/ui-feature-flags'
import { CourtroomState } from '@ftr/ui-location'
import { LoggingService } from '@ftr/ui-observability'
import { UserState } from '@ftr/ui-user'
import { Store } from '@ngxs/store'
import { LDFlagSet } from 'launchdarkly-js-client-sdk'
import { EMPTY, Observable, catchError, combineLatest, filter, map, startWith, switchMap } from 'rxjs'
import { WindowRefService } from '~app/services/window/window-ref.service'

interface CourtSystemAttributes {
  courtSystemId?: string
}

interface CourtroomAttributes {
  courtroomId?: string
}

interface UserAttributes {
  anonymous: boolean
  userIsGlobalAdmin: boolean
  userId: string
  lastName?: string
  firstName?: string
  email?: string
}

@Injectable({
  providedIn: 'root',
})
export class LaunchDarklyAttributeService {
  private observing = false

  constructor(
    private readonly store: Store,
    private readonly launchDarklySdkClientFactory: LaunchDarklySdkClientFactory,
    private readonly router: Router,
    private readonly logger: LoggingService,
    private readonly windowRefService: WindowRefService,
  ) {}

  observeAndApplyAttributes(): void {
    if (this.observing) return
    this.observing = true

    const ldClient = this.launchDarklySdkClientFactory.getClient()

    combineLatest([
      this.observeUserAttributes(),
      this.observeCourtSystemAttributes(),
      this.observeCourtroomAttributes(),
      this.observeRouterUpdates(),
    ])
      .pipe(
        switchMap(
          async ([userAttributes, courtSystemAttributes, courtroomAttributes]) =>
            [
              userAttributes,
              await ldClient.identify({
                anonymous: userAttributes.anonymous,
                email: userAttributes.email,
                firstName: userAttributes.firstName,
                lastName: userAttributes.lastName,
                key: getIdentifyKeyForUser(userAttributes),
                custom: {
                  userIsGlobalAdmin: userAttributes.userIsGlobalAdmin,
                  userId: userAttributes.userId,
                  ...courtSystemAttributes,
                  ...courtroomAttributes,
                  domainName: this.windowRefService.getHostName(),
                },
              }),
            ] as const,
        ),
        // Loop through all the keys the client knows about and get the variation
        // This allows us to get accurate insights into which keys are being loaded by the deployed clients
        map(
          ([userAttributes]) =>
            [
              userAttributes,
              Object.keys(this.store.selectSnapshot(FeatureFlagState.allFeatureFlags)).reduce(
                (flagSet, flagKey) => ({ ...flagSet, [flagKey]: ldClient.variation(flagKey) }),
                {} as LDFlagSet,
              ),
            ] as const,
        ),
        catchError(err => {
          this.logger.warn({ message: 'LaunchDarkly failed to identify', err })
          return EMPTY
        }),
      )
      .subscribe(([userAttributes, flags]) => {
        this.store.dispatch(new SetFeatureFlagValues(flags, !userAttributes.anonymous))
      })
  }

  /** We refresh the flag values when we navigate - this ensures that if the value from ld changed, but none of our attributes do, we get the new value on navigate */
  private observeRouterUpdates(): Observable<void> {
    return this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(() => undefined),
    )
  }

  private observeCourtroomAttributes(): Observable<CourtroomAttributes> {
    return this.store.select(CourtroomState.currentCourtRoomId).pipe(
      map(courtroomId => ({ courtroomId })),
      startWith({}),
    )
  }

  private observeCourtSystemAttributes(): Observable<CourtSystemAttributes> {
    return this.store.select(UserState.currentCourtSystem).pipe(
      map(courtSystem => {
        return {
          courtSystemId: courtSystem?.id,
        }
      }),
      startWith({}),
    )
  }

  private observeUserAttributes(): Observable<UserAttributes> {
    return this.store.select(UserState.user).pipe(
      unwrapData(),
      map(user => {
        return {
          anonymous: false,
          lastName: user.familyName,
          firstName: user.givenName,
          email: user.email,
          userId: user.id,
          userIsGlobalAdmin: user.isGlobalAdministrator,
        }
      }),
      startWith({
        anonymous: true,
        userIsGlobalAdmin: false,
        userId: 'anonymous',
      }),
    )
  }
}
