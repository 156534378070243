import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { RootParams } from '@ftr/api-shared'
import { IdentityProviderName } from '@ftr/contracts/type/account'

@Component({
  template: `<ftr-desktop-login-splash
    [outgoingIdentityProvider]="identityProvider"
    [authorizationCode]="authorizationCode"
    [state]="state"
  ></ftr-desktop-login-splash>`,
})
export class DesktopLoginSplashPage implements OnInit {
  identityProvider: IdentityProviderName | undefined

  authorizationCode: string | undefined
  state: string | undefined

  constructor(private readonly route: ActivatedRoute) {}

  ngOnInit(): void {
    this.identityProvider = this.route.snapshot.queryParams[RootParams.IdentityProvider]

    this.authorizationCode = this.route.snapshot.queryParams[RootParams.AuthorizationCode]
    this.state = this.route.snapshot.queryParams[RootParams.State]
  }
}
