import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { CheckboxComponent } from '@ftr/forms'
import { DividerComponent, IllustrationComponent, NotificationModule, PageComponent } from '@ftr/foundation'
import { SingleSignOnModule } from '~app/features/single-sign-on/single-sign-on.module'
import { FtrFormsModule } from '~app/shared/ftr-forms.module'
import { LoginModule } from '../login/login.module'
import { ConfirmationSentComponent } from './confirmation-sent/confirmation-sent.component'
import { SignupAttributesService } from './signup-attributes.service'
import { SignupComponent } from './signup/signup.component'

@NgModule({
  imports: [
    RouterModule,
    LoginModule,
    SingleSignOnModule,
    IllustrationComponent,
    CheckboxComponent,
    DividerComponent,
    PageComponent,
    FtrFormsModule,
    NotificationModule,
  ],
  declarations: [SignupComponent, ConfirmationSentComponent],
  providers: [SignupAttributesService],
  exports: [SignupComponent, ConfirmationSentComponent],
})
export class SignupModule {}
