import { Directive, ElementRef, EventEmitter, HostListener, Inject, Input, Output } from '@angular/core'

export interface PillNavigationPrev {
  previousSibling: HTMLElement | undefined
}

export interface PillNavigationNext {
  nextSibling: HTMLElement | undefined
}

export interface PillNavigationDelete {
  previousSibling: HTMLElement | undefined
  nextSibling: HTMLElement | undefined
  key: string
  label: string
}

const PREV_SIBLING = '$event.target.previousElementSibling'
const NEXT_SIBLING = '$event.target.nextElementSibling'
const EVENT = '$event'

@Directive({
  selector: '[ftrNavigatePill]',
  standalone: true,
})
export class PillNavigationDirective {
  /**
   * The surrounding pill values, used for navigation and deletion
   */
  @Input() previousPill: string
  @Input() nextPill: string
  @Input() currentPill: string

  @Output() onNavigatePrev = new EventEmitter<PillNavigationPrev>()
  @Output() onNavigateNext = new EventEmitter<PillNavigationNext>()
  @Output() onDelete = new EventEmitter<PillNavigationDelete>()

  constructor(@Inject(ElementRef) private element: ElementRef) {}

  /**
   * Navigate backwards through the list of pills
   */
  @HostListener('keydown.Shift.Tab', [EVENT, PREV_SIBLING])
  @HostListener('keydown.ArrowLeft', [EVENT, PREV_SIBLING])
  @HostListener('keydown.ArrowUp', [EVENT, PREV_SIBLING])
  onNavigateLeftKeyDown(event: KeyboardEvent, previousSibling: HTMLElement | undefined): void {
    if (this.previousPill && previousSibling) {
      event.preventDefault()
      this.onNavigatePrev.emit({ previousSibling })
    }
  }

  /**
   * Navigate forwards through the list of pills
   */
  @HostListener('keydown.Tab', [EVENT, NEXT_SIBLING])
  @HostListener('keydown.ArrowRight', [EVENT, NEXT_SIBLING])
  @HostListener('keydown.ArrowDown', [EVENT, NEXT_SIBLING])
  onNavigateRightKeyDown(event: KeyboardEvent, nextSibling: HTMLElement | undefined): void {
    if (this.nextPill && nextSibling) {
      event.preventDefault()
      this.onNavigateNext.emit({ nextSibling })
    } else if (!this.nextPill) {
      event.preventDefault()
      const inputSibling = this.element.nativeElement.parentNode.querySelector('.form-input-pill-list__input')
      this.onNavigateNext.emit({ nextSibling: inputSibling })
    }
  }

  @HostListener('keydown.Backspace', [EVENT, '$event.key', NEXT_SIBLING, PREV_SIBLING])
  @HostListener('keydown.Delete', [EVENT, '$event.key', NEXT_SIBLING, PREV_SIBLING])
  onDeleteKeyDown(
    event: KeyboardEvent,
    key: string,
    nextSibling: HTMLElement | undefined,
    previousSibling: HTMLElement | undefined,
  ): void {
    event.preventDefault()
    event.stopPropagation()
    this.onDelete.emit({
      label: this.currentPill,
      key,
      nextSibling,
      previousSibling,
    })
  }
}
