import { Injectable } from '@angular/core'
import { ApiClient, ApiClientFactory } from '@ftr/api-shared'
import { Department } from '@ftr/contracts/api/department'
import { JobWithTranscriptResponse } from '@ftr/contracts/api/job'
import { LogSheet } from '@ftr/contracts/api/log-sheet'
import { AudioAvailabilityResult, RecordingSegment, RecordingSummary } from '@ftr/contracts/read'
import { Money } from '@ftr/contracts/type/order'
import { Uuid, generateUuid } from '@ftr/contracts/type/shared'
import { ApiResult, TimeRange, mapData } from '@ftr/foundation'
import { plainToClass } from '@ftr/serialization'
import { FileUpload, UploadStates } from '@ftr/ui-files'
import { RecordingSummaryWithTimeRange, TimeRangeService } from '@ftr/ui-playback'
import { Observable, filter, map } from 'rxjs'
import {
  TranscriptFileUploadService,
  TranscriptUploadProps,
} from '../../lib/transcript-upload/transcript-file-upload.service'

// Recordings without departments will be grouped under 'undefined'
export type RecordingsByDepartment = Map<
  Uuid | undefined,
  {
    department: Department | undefined
    recordings: RecordingSummaryWithTimeRange[]
  }
>

@Injectable()
export class JobService {
  private readonly apiClient: ApiClient

  constructor(
    apiClientFactory: ApiClientFactory,
    private readonly transcriptFileUploadService: TranscriptFileUploadService,
    private readonly timeRangeService: TimeRangeService,
  ) {
    this.apiClient = apiClientFactory.build('/job')
  }

  getWithTranscriptFile(jobId: Uuid): ApiResult<JobWithTranscriptResponse> {
    return this.apiClient.get<JobWithTranscriptResponse>({
      path: `/${jobId}/getWithTranscriptFile`,
      responseBodyType: JobWithTranscriptResponse,
    })
  }

  getTranscriptionBundleLogSheets(jobId: Uuid): ApiResult<LogSheet[]> {
    return this.apiClient.get<LogSheet[]>({
      path: `/${jobId}/getTranscriptionBundleLogSheets`,
    })
  }

  isAudioAvailable(jobId: Uuid): ApiResult<AudioAvailabilityResult> {
    return this.apiClient.get<AudioAvailabilityResult>({
      path: `/${jobId}/isAudioAvailable`,
    })
  }

  submitTranscript(
    jobId: Uuid,
    file: File,
    transcriberFee: Money,
    fileId = generateUuid(),
  ): Observable<JobWithTranscriptResponse> {
    const upload: FileUpload<TranscriptUploadProps, JobWithTranscriptResponse> = {
      id: fileId,
      file,
      status: UploadStates.Initializing,
      props: {
        fileId,
        jobId,
        transcriberFee,
      },
    }

    return this.transcriptFileUploadService.upload(upload).pipe(
      filter(fileUpload => fileUpload.status === UploadStates.Success),
      map(fileUpload => fileUpload.response!),
    )
  }

  listRecordings(jobId: Uuid): ApiResult<RecordingsByDepartment> {
    return this.apiClient
      .get<RecordingSummary, RecordingSummary[]>({
        path: `/${jobId}/listRecordings`,
      })
      .pipe(mapData(response => this.mapRecordingSummaries(plainToClass(RecordingSummary, response))))
  }

  attachRecordings(jobId: Uuid): ApiResult<JobWithTranscriptResponse> {
    return this.apiClient.post<JobWithTranscriptResponse>({
      path: `${jobId}/attachRecordings`,
    })
  }

  private mapRecordingSummaries(recordingSummaries: RecordingSummary[]): RecordingsByDepartment {
    return recordingSummaries.reduce((acc, recording) => {
      if (!acc.has(recording.department?.id)) {
        acc.set(recording.department?.id, {
          department: recording.department,
          recordings: [],
        })
      }

      acc.get(recording.department?.id).recordings.push({
        recording,
        timeRange: this.getRecordingTimeRange(recording.segments),
      })

      return acc
    }, new Map())
  }

  private getRecordingTimeRange(segments: RecordingSegment[]): TimeRange {
    const timeRanges = this.timeRangeService.convertToContiguousApproximateTimeRanges(segments)
    return {
      start: timeRanges[0].start,
      end: timeRanges[timeRanges.length - 1].end,
    }
  }
}
