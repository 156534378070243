@if (breadcrumbs().length > 0) {
  <section class="breadcrumbs-section">
    <nav aria-label="Breadcrumbs">
      <ul class="breadcrumbs" [class.breadcrumbs__collapsed]="collapse()" data-track-group="Breadcrumbs">
        @if (collapse()) {
          <ng-container *ngTemplateOutlet="showAsCollapsed"></ng-container>
        } @else {
          <ng-container *ngTemplateOutlet="showAsExpanded"></ng-container>
        }
      </ul>
    </nav>
  </section>
}

<ng-template #showAsCollapsed>
  <ng-container
    *ngTemplateOutlet="collapsedMenuButton; context: { $implicit: collapsedBreadcrumbs().collapsed }"
  ></ng-container>
  @for (breadcrumb of collapsedBreadcrumbs().current; track trackBy($index, breadcrumb); let last = $last) {
    @if (!last) {
      <ng-container *ngTemplateOutlet="breadcrumbCollapsedLink; context: { $implicit: breadcrumb }"></ng-container>
    } @else {
      <ng-container *ngTemplateOutlet="breadcrumbLeaf; context: { $implicit: breadcrumb }"></ng-container>
    }
  }
</ng-template>

<ng-template #showAsExpanded>
  @for (breadcrumb of breadcrumbs(); track trackBy($index, breadcrumb); let last = $last) {
    @if (!last) {
      <ng-container *ngTemplateOutlet="breadcrumbLink; context: { $implicit: breadcrumb }"></ng-container>
    } @else {
      <ng-container *ngTemplateOutlet="breadcrumbLeaf; context: { $implicit: breadcrumb }"></ng-container>
    }
  }
</ng-template>

<ng-template #breadcrumbLink let-breadcrumb [ftrTypedContext]="breadcrumbs">
  <li class="breadcrumbs__item">
    <a
      class="breadcrumbs__link"
      data-track
      [routerLink]="breadcrumb.link.url"
      [queryParams]="breadcrumb.link.queryParams"
      [attr.aria-label]="'Breadcrumbs Navigation:' + breadcrumb.label"
    >
      {{ breadcrumb.label }}
    </a>
  </li>
  <ng-container *ngTemplateOutlet="breadcrumbsDivider"></ng-container>
</ng-template>

<ng-template #breadcrumbCollapsedLink let-breadcrumb [ftrTypedContext]="breadcrumbs">
  <li
    class="breadcrumbs__item breadcrumbs__link--collapsed"
    ftrTooltip
    [message]="breadcrumb.label"
    [showDash]="false"
    [forceDisplayState]="clampLink() ? undefined : 'hide'"
  >
    <a
      class="breadcrumbs__link"
      data-track
      [routerLink]="breadcrumb.link.url"
      [queryParams]="breadcrumb.link.queryParams"
      [attr.aria-label]="'Breadcrumbs Navigation:' + breadcrumb.label"
    >
      {{ breadcrumb.label }}
    </a>
  </li>
  <ng-container *ngTemplateOutlet="breadcrumbsDivider"></ng-container>
</ng-template>

<ng-template #breadcrumbLeaf let-breadcrumb [ftrTypedContext]="breadcrumbs">
  <li
    class="breadcrumbs__item--current"
    ftrTooltip
    [message]="breadcrumb.label"
    [showDash]="false"
    [forceDisplayState]="clampLeaf() ? undefined : 'hide'"
  >
    {{ breadcrumb.label }}
  </li>
</ng-template>

<ng-template #collapsedMenuButton>
  <li class="breadcrumbs__item--button">
    <button
      class="breadcrumbs__link breadcrumbs__link__icon"
      type="button"
      ftrButton
      color="grey"
      displayType="icon"
      [autoWidth]="true"
      title="Toggle Collapsed Breadcrumbs"
      aria-label="Toggle Collapsed Breadcrumbs"
      #popOver="ngbPopover"
      [ngbPopover]="breadcrumbsDropdown"
      [popoverClass]="'breadcrumbs__dropdown-popover'"
      [animation]="false"
      [autoClose]="'outside'"
      placement="bottom-left"
      (keyup.enter)="handleDropdownOnEnter()"
    >
      <ftr-icon icon="More" size="Large" color="GreyLight" align="Bottom" />
    </button>
  </li>
  <ng-container *ngTemplateOutlet="breadcrumbsDivider"></ng-container>
</ng-template>

<ng-template #breadcrumbsDivider>
  <li class="breadcrumbs__divider" aria-hidden="true">
    <ftr-icon icon="KeyboardArrowRight" size="Small" color="Grey30" align="Middle"></ftr-icon>
  </li>
</ng-template>

<ng-template #breadcrumbsDropdown>
  <ftr-breadcrumbs-dropdown-display
    [collapsed]="collapsedBreadcrumbs().collapsed"
    (focusin)="onFocusIn()"
    (focusout)="onFocusOut()"
  />
</ng-template>
