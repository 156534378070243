import { Injectable } from '@angular/core'
import { AbstractControl, ValidationErrors, Validators } from '@angular/forms'
import { ApiClient, ApiClientFactory } from '@ftr/api-shared'
import { PrivateConfiguration, PublicConfiguration, UpdateConfigurationBody } from '@ftr/contracts/api/configuration'
import { MFARequirementsConfiguration } from '@ftr/contracts/api/court-system/MFARequirementsConfiguration'
import { Feature } from '@ftr/contracts/api/feature'
import {
  GenerateRecoveryCodesBody,
  ValidateRecoveryCodeBody,
  VerifyMultifactorAuthenticationDeviceBody,
} from '@ftr/contracts/api/mfa'
import { UserGroup, UserGroupPermissionId } from '@ftr/contracts/api/user-group'
import { FeatureName } from '@ftr/contracts/type/core'
import { Uuid } from '@ftr/contracts/type/shared'
import { validateRequiredSingleLineText } from '@ftr/forms'
import { ApiResult, mapData } from '@ftr/foundation'
import { classToPlain } from '@ftr/serialization'
import {
  AudioOrderingFeatureService,
  CoreConfigurationService,
  CourtSystemService,
  EnabledCourtSystemFeaturesService,
} from '@ftr/ui-court-system'
import { from, switchMap } from 'rxjs'
import { CognitoAuthenticationService } from '../cognito-authentication'
import { UserPermissionService } from '../permissions'

export const MFA_REDIRECT_URL = 'mfa_redirect_url'

export interface MfaRequirementData {
  courtSystemId: Uuid
  configurationId: Uuid
  showPublicEnforcementOptions?: boolean
  mfaRequirements: MFARequirementsConfiguration
}

@Injectable({
  providedIn: 'root',
})
export class MultifactorAuthenticationService {
  private readonly VERIFICATION_CODE_MIN_LENGTH = 6
  private readonly VERIFICATION_CODE_MAX_LENGTH = 6
  private readonly mfaApiClient: ApiClient
  private readonly configurationApiClient: ApiClient

  constructor(
    apiClientFactory: ApiClientFactory,
    private readonly coreConfigurationService: CoreConfigurationService,
    private readonly courtSystemService: CourtSystemService,
    private readonly enabledCourtSystemFeaturesService: EnabledCourtSystemFeaturesService,
    private readonly audioOrderingFeatureService: AudioOrderingFeatureService,
    private readonly userPermissionService: UserPermissionService,
    private readonly cognitoAuthenticationService: CognitoAuthenticationService,
  ) {
    this.mfaApiClient = apiClientFactory.build('/mfa')
    this.configurationApiClient = apiClientFactory.build('/configuration')
  }

  disableMultifactorAuthentication(): ApiResult<void> {
    return this.mfaApiClient.post({
      path: '/user/disable',
    })
  }

  setupMultifactorAuthenticationDevice(totpCode: string, replacingDevice: boolean): ApiResult<void> {
    return from(this.cognitoAuthenticationService.currentCognitoAccessToken).pipe(
      switchMap(accessToken => {
        const verifyMultifactorAuthenticationDeviceBody = new VerifyMultifactorAuthenticationDeviceBody(
          accessToken,
          totpCode,
        )
        return this.mfaApiClient.post<undefined>({
          path: replacingDevice ? '/user/replaceDevice' : '/user/enable',
          body: classToPlain(verifyMultifactorAuthenticationDeviceBody),
        })
      }),
    )
  }

  validateRecoveryCode(userId: Uuid, recoveryCode: string): ApiResult<void> {
    const validateRecoveryCodeBody = new ValidateRecoveryCodeBody(userId, recoveryCode)
    return this.mfaApiClient.post({
      path: '/user/recovery-code/validate',
      body: classToPlain(validateRecoveryCodeBody),
      skipAuthentication: true,
    })
  }

  generateRecoveryCodes(regenerate: boolean): ApiResult<string[]> {
    const generateRecoveryCodesBody = new GenerateRecoveryCodesBody(regenerate)
    return this.mfaApiClient.post<string[]>({
      path: '/user/recovery-codes',
      body: classToPlain(generateRecoveryCodesBody),
    })
  }

  get totpValidatorRules(): ((control: AbstractControl<any, any>) => ValidationErrors | null)[] {
    return [
      Validators.required,
      Validators.minLength(this.VERIFICATION_CODE_MIN_LENGTH),
      Validators.maxLength(this.VERIFICATION_CODE_MAX_LENGTH),
      validateRequiredSingleLineText,
    ]
  }

  getRequirementConfiguration(courtSystemId: Uuid): ApiResult<MfaRequirementData> {
    return ApiResult.combine([
      this.coreConfigurationService.getByCourtSystem(courtSystemId),
      this.isCourtSystemPublicWithOrders(courtSystemId),
    ]).pipe(
      mapData(([configuration, showPublicEnforcementOptions]) =>
        this.mapConfigurationToRequirementData(configuration, showPublicEnforcementOptions),
      ),
    )
  }

  saveRequirementConfiguration(formData: MfaRequirementData): ApiResult<PrivateConfiguration> {
    const updateBody = new UpdateConfigurationBody()
    updateBody.mfaRequirements = formData.mfaRequirements
    return this.configurationApiClient.patch({
      path: `/${formData.configurationId}`,
      body: classToPlain(updateBody),
    })
  }

  private mapConfigurationToRequirementData(
    configuration: PublicConfiguration,
    showPublicEnforcementOptions: boolean,
  ): MfaRequirementData {
    return configuration.mfaRequirements
      ? {
          mfaRequirements: configuration.mfaRequirements,
          configurationId: configuration.id,
          courtSystemId: configuration.courtSystemId,
          showPublicEnforcementOptions,
        }
      : this.defaultRequirementData(configuration.courtSystemId, configuration.id, showPublicEnforcementOptions)
  }

  private defaultRequirementData(
    courtSystemId: Uuid,
    configurationId: Uuid,
    showPublicEnforcementOptions: boolean,
  ): MfaRequirementData {
    return {
      courtSystemId,
      configurationId,
      mfaRequirements: new MFARequirementsConfiguration(false, false, false, false),
      showPublicEnforcementOptions,
    }
  }

  private isCourtSystemPublicWithOrders(courtSystemId: Uuid): ApiResult<boolean> {
    return ApiResult.combine([
      this.courtSystemService.getCourtSystem(courtSystemId),
      this.userPermissionService.fetchCurrentUserGroupsForCourtSystem(courtSystemId),
      this.enabledCourtSystemFeaturesService.findEnabledFeaturesForCourtSystem(courtSystemId),
      this.audioOrderingFeatureService.getForCourtSystem(courtSystemId),
    ]).pipe(
      mapData(([courtSystem, userGroups, enabledFeatures, audioOrderingFeature]) => {
        // ignore publicOrderingEnabled, so MFA can be configured before going public with orders
        const publicAudioOrdersEnabled =
          audioOrderingFeature.isEnabled && (courtSystem.isPublished || audioOrderingFeature.sharingEnabled)
        const publicRealTimeOrdersEnabled =
          isFeatureEnabled(FeatureName.RealTimeOrdering, enabledFeatures) && courtSystem.isPublished
        const publicTranscriptOrdersEnabled =
          isFeatureEnabled(FeatureName.TranscriptOrdering, enabledFeatures) &&
          (courtSystem.isPublished ||
            permissionActiveInCourtGroups(userGroups, UserGroupPermissionId.DistributeTranscripts))
        const publicOrdersEnabled =
          publicAudioOrdersEnabled || publicRealTimeOrdersEnabled || publicTranscriptOrdersEnabled

        const sharedRecordingsEnabled =
          isFeatureEnabled(FeatureName.SharingRecordings, enabledFeatures) &&
          permissionActiveInCourtGroups(userGroups, UserGroupPermissionId.ShareRecordings)

        return publicOrdersEnabled || sharedRecordingsEnabled
      }),
    )
  }
}

const permissionActiveInCourtGroups = (groups: UserGroup[], permissionId: UserGroupPermissionId): boolean => {
  return groups.filter(group => permissionActiveInGroup(group, permissionId)).length > 0
}

const permissionActiveInGroup = (group: UserGroup, permissionId: UserGroupPermissionId): boolean => {
  return (
    group.permissions.filter(permission => {
      return permission.name === permissionId && permission.isEnabled
    }).length > 0 && groupHasMembers(group)
  )
}

const groupHasMembers = (group: UserGroup): boolean => {
  return (group.numberOfMembersInGroup ?? 0) > 0
}

const isFeatureEnabled = (featureName: FeatureName, features: Feature[] | undefined): boolean => {
  return !!features?.some(feature => feature.name === featureName && feature.isEnabled)
}
