export class EmptyChunkError extends Error {
  constructor(
    readonly file: File,
    readonly readPosition: { start?: number; end?: number },
  ) {
    super(
      'Received an empty chunk while trying to read a file. This could be due to the file ' +
        "changing on disk while it's still in the process of being read",
    )
    Object.setPrototypeOf(this, EmptyChunkError.prototype)
  }
}
