import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { AbstractControl, ReactiveFormsModule, UntypedFormControl } from '@angular/forms'
import { DestroySubscribers, ScreenSize, ScreenSizeService, isMobileScreenSize } from '@ftr/foundation'
import { Observable, takeUntil } from 'rxjs'
import { SelectComponent, SelectItem } from '../select'

const ITEMS_RANGE = {
  max_medium: 4,
  max_large: 5,
}
let nextId = 0

export type SliderComponentToDisplay = 'slider' | 'select'

/**
 * A wrapper component for an input range element
 */
@Component({
  selector: 'ftr-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SelectComponent],
})
export class SliderComponent<T> extends DestroySubscribers implements OnInit {
  @Input() id = `ftr-slider-${nextId++}`
  @Input() name: string
  @Input() ngFormControl: AbstractControl
  @Input() items: Observable<SelectItem<T>[]>
  @Input() singular: string
  @Input() label: string
  @Output() onValueChange = new EventEmitter<number>()
  componentToDisplay: SliderComponentToDisplay
  step = new UntypedFormControl()
  values: T[]
  labels: string[]
  lastChange = new Date().getTime()

  constructor(private readonly screenSizeService: ScreenSizeService) {
    super()
  }

  ngOnInit(): void {
    this.items.pipe(takeUntil(this.finalize)).subscribe(values => {
      this.values = values.map(item => item.value)
      this.labels = values.map(item => item.key)
    })
    /* Currently there is no observable to get both the initial value and changes.
       See https://github.com/angular/angular/issues/15282 for progress on this feature. */
    this.setValue(this.ngFormControl.value)
    this.ngFormControl.valueChanges.subscribe(value => {
      this.setValue(value)
      this.onValueChange.emit(value)
    })
    this.watchScreenSize()
  }

  setValue(value: T): void {
    this.step.setValue(this.values.indexOf(value))
  }

  setValueFromTouch(event: TouchEvent): void {
    const touch = event.changedTouches[0]
    const input = event.target as any
    const step = Math.round((input.max / input.offsetWidth) * (touch.pageX - input.offsetLeft))
    this.ngFormControl.setValue(this.values[step])
  }

  onChange(event: Event): void {
    const target = event.target! as HTMLInputElement
    this.changeValue(parseInt(target.value, 10))
  }

  changeValue(step: number): void {
    this.ngFormControl.setValue(this.values[step])
  }

  getValueText(value: number): string {
    return this.labels[value]
  }

  /**
   * < 5 items:
   *  - mobile - select
   *  - tablet, desktop – slider
   * 5 items:
   *  - mobile, tablet - select
   *  - desktop – slider
   * 6+ items:
   *  - mobile, tablet, desktop – select
   */
  private watchScreenSize(): void {
    this.screenSizeService.size.pipe(takeUntil(this.finalize)).subscribe(size => {
      if (isMobileScreenSize(size)) {
        this.componentToDisplay = 'select'
      } else if (size === ScreenSize.Medium) {
        this.componentToDisplay = this.values.length > ITEMS_RANGE.max_medium ? 'select' : 'slider'
      } else {
        this.componentToDisplay = this.values.length > ITEMS_RANGE.max_large ? 'select' : 'slider'
      }
    })
  }
}
