import { Injectable } from '@angular/core'
import { ApiClient, ApiClientFactory, serializeHttpParams } from '@ftr/api-shared'
import { CourtSystem, CourtSystemWithMetadata } from '@ftr/contracts/api/court-system'
import { CourtSystemForUserQuery } from '@ftr/contracts/api/user'
import { Uuid } from '@ftr/contracts/type/shared'
import { ApiResult } from '@ftr/foundation'

// GetCourtSystemService was isolated from CourtSystemService to prevent needing to
// provide the entire service to the root scope of the application. Retrieving court
// systems is a requirement of RegionalApiClientFactory
@Injectable({
  providedIn: 'root',
})
export class GetCourtSystemService {
  private readonly courtSystemApiClient: ApiClient

  constructor(apiClientFactory: ApiClientFactory) {
    this.courtSystemApiClient = apiClientFactory.build('/court-system')
  }

  getCourtSystem(courtSystemId: Uuid): ApiResult<CourtSystem> {
    return this.courtSystemApiClient.get({
      path: courtSystemId,
      responseBodyType: CourtSystem,
    })
  }

  getCourtSystemsForUser(params: CourtSystemForUserQuery): ApiResult<CourtSystemWithMetadata[]> {
    return this.courtSystemApiClient.get({ params: serializeHttpParams(params) })
  }
}
