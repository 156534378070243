import { Component, ViewChild } from '@angular/core'
import { CourtSystem } from '@ftr/contracts/api/court-system'
import { isNotNullOrUndefined } from '@ftr/foundation'
import { StaticMenuItem } from '@ftr/menus'
import { SideNavComponent } from '@ftr/ui-core'
import { UserState } from '@ftr/ui-user'
import { Store } from '@ngxs/store'
import { Observable, filter } from 'rxjs'
import { MainNavMenuItemsProvider } from '~app/services/navigation/main-nav-menu-items.provider'

@Component({
  selector: 'ftr-main-side-nav',
  templateUrl: './main-side-nav.component.html',
})
export class MainSideNavComponent {
  @ViewChild(SideNavComponent) sideNav: SideNavComponent

  navItems$: Observable<StaticMenuItem[]>
  currentCourtSystem$: Observable<CourtSystem>

  constructor(
    private readonly navItemProvider: MainNavMenuItemsProvider,
    private readonly store: Store,
  ) {
    this.navItems$ = this.navItemProvider.provide()
    this.currentCourtSystem$ = this.store.select(UserState.currentCourtSystem).pipe(filter(isNotNullOrUndefined))
  }

  toggle(): void {
    this.sideNav.toggle()
  }
}
