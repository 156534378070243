import { Inject, Injectable } from '@angular/core'
import { API_CONFIGURATION, AuthEvent, AuthEventType, FullApiClientConfiguration } from '@ftr/api-shared'
import { JoinRoomRequest, getRoomId } from '@ftr/contracts/socket/join-room/JoinRoomRequest'
import { JoinRoomResponse } from '@ftr/contracts/socket/join-room/JoinRoomResponse'
import { Message } from '@ftr/contracts/type/shared'
import { ClassConstructor, classToPlain, plainToClass } from '@ftr/serialization'
import { LoggingService } from '@ftr/ui-observability'
import { Observable, Observer } from 'rxjs'
import { Socket } from 'socket.io-client'
import { AuthenticationService } from '../authentication.service'
import { SocketIoService } from './socket-io.service'

/**
 * A map of message name to the observable which emits messages for that message
 */
type MessageObservableMap = Map<string, Observable<object>>

interface RoomInfo {
  joinRoomRequest: JoinRoomRequest

  messageObservableMap: MessageObservableMap

  onErrorJoiningRoom?: () => void
}
@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private socket: Socket
  /**
   * A map of rooms into their <code>MessageObservableMap</code> which are currently in use
   *
   * @see MessageObservableMap for more details
   */
  private readonly rooms: Map<string, RoomInfo>

  constructor(
    @Inject(API_CONFIGURATION) readonly configuration: FullApiClientConfiguration,
    private readonly loggingService: LoggingService,
    private readonly authenticationService: AuthenticationService,
    private readonly socketIoService: SocketIoService,
  ) {
    this.rooms = new Map<string, RoomInfo>()
    this.socket = this.socketIoService.connect(configuration.socket.url)
    this.authenticationService.authEvents.subscribe(this.onAuthEvent.bind(this))
    this.logEventsAndErrors()
    this.setupReconnect()
  }

  /**
   * Returns an observable of socket messages for the provided message type in the provided room. If the room has not
   * yet been joined, joins the socket room.
   *
   * When all observables within a socket room have been torn down (i.e.: unsubscribed from), leaves the room.
   *
   * IMPORTANT: Events emitted by the returned observable are only filtered by message type, not by the room which emits
   * the message. If the message type can be emitted by multiple different rooms (e.g.: recording specific events), it
   * is the consumer's responsibility to filter out this message. This is because socket.io does not provide data on
   * which room emitted a certain message.
   */
  onMessage<T extends Message>(
    joinRoomRequest: JoinRoomRequest,
    constructor: ClassConstructor<T>,
    onErrorJoiningRoom?: () => void,
  ): Observable<T> {
    const messageName = new constructor().$name

    const roomId = getRoomId(joinRoomRequest)
    let roomInfo = this.rooms.get(roomId)
    if (!roomInfo) {
      this.joinRoomAndHandleErrors(joinRoomRequest, onErrorJoiningRoom)

      roomInfo = {
        joinRoomRequest,
        messageObservableMap: new Map<string, Observable<object>>(),
        onErrorJoiningRoom,
      }
      this.rooms.set(roomId, roomInfo)
    }

    if (!roomInfo.messageObservableMap.has(messageName)) {
      const observable = new Observable<T>((observer: Observer<T>) => {
        this.socket.on(messageName, (data: any) => {
          this.loggingService.debug({
            message: 'Received socket event',
            type: messageName,
            data,
          })
          observer.next(plainToClass(constructor, data as object))
        })

        return () => {
          const latestMap = this.rooms.get(roomId)?.messageObservableMap
          if (!latestMap) {
            return
          }
          latestMap.delete(messageName)
          if (latestMap.size === 0) {
            this.leaveRoom(roomId)
            this.rooms.delete(roomId)
          }
        }
      })
      roomInfo.messageObservableMap.set(messageName, observable)
    }

    return roomInfo.messageObservableMap.get(messageName)! as Observable<T>
  }

  /**
   * Returns an observable of socket messages for the given message type. This method does not keep track of room
   * joining/leaving, leaving it up to the consumer to manage these.
   *
   * @deprecated Only used by user uploads workflow events, use onMessage for all other usages
   */
  onUntrackedMessage<T extends Message>(constructor: ClassConstructor<T>): Observable<T> {
    const messageName = new constructor().$name

    return new Observable<T>((observer: Observer<T>) => {
      this.socket.on(messageName, (data: object) => {
        this.loggingService.debug({
          message: 'Received socket event',
          type: messageName,
          data,
        })
        observer.next(plainToClass(constructor, data))
      })
    })
  }

  private joinRoomAndHandleErrors(joinRoomRequest: JoinRoomRequest, onErrorJoiningRoom?: () => void): void {
    this.joinRoom(joinRoomRequest).catch(() => {
      this.leaveRoom(getRoomId(joinRoomRequest))
      onErrorJoiningRoom?.()
    })
  }

  private async joinRoom(joinRoomRequest: JoinRoomRequest): Promise<void> {
    this.loggingService.debug({
      message: 'socket.io joining room',
      joinRoomRequest,
    })
    const joinResponse = (await this.socket.emitWithAck('join', classToPlain(joinRoomRequest))) as JoinRoomResponse
    if (joinResponse?.success === false) {
      this.loggingService.warn({
        message: 'Failed to successfully join room',
        joinResponse,
      })
      throw new Error('Failed to successfully join room')
    }
  }

  private leaveRoom(roomId: string): void {
    this.loggingService.debug({
      message: 'socket.io leaving room',
      roomId,
    })
    this.socket.emit('leave', { roomId })
  }

  private setupReconnect(): void {
    this.socket.io.on('reconnect', async () => {
      this.loggingService.debug({
        message: 'socket.io rejoining rooms',
      })

      // Note: Ideally we send this token as part of connect/reconnect of the ws socket connection itself so that we
      // have a consistent way of providing auth to the socket server. However, this was out of scope of trying to
      // patch authorisation for live-stream rooms (ST-2121)
      const auth = await this.authenticationService.currentJwtToken
      for (const roomInfo of this.rooms.values()) {
        const joinRequest =
          'auth' in roomInfo.joinRoomRequest ? { ...roomInfo.joinRoomRequest, auth } : roomInfo.joinRoomRequest

        this.joinRoomAndHandleErrors(joinRequest, roomInfo.onErrorJoiningRoom)
      }
    })
  }

  private leaveAll(): void {
    this.loggingService.debug({
      message: 'socket.io leaving rooms',
    })
    this.socket.emit('leave')
  }

  private onAuthEvent(authEvent: AuthEvent): void {
    switch (authEvent.type) {
      case AuthEventType.Login:
        this.joinRoom({ roomId: authEvent.user!.id })
        break
      case AuthEventType.Logout:
        this.leaveAll()
        break
      default:
        break
    }
  }

  private logEventsAndErrors(): void {
    this.socket.onAny((eventName: string, packet: any) => {
      this.loggingService.debug({
        message: 'socket.io received data',
        eventName,
        data: packet,
      })
    })

    this.socket.on('connect_error', (error: any) => {
      this.loggingService.warn({
        message: 'socket.io connection error',
        error,
      })
    })

    this.socket.on('connect_timeout', () => {
      this.loggingService.debug({
        message: 'socket.io connection timeout',
      })
    })
  }
}
