import { Injectable } from '@angular/core'
import { NavigationCancel, NavigationEnd, NavigationError, ResolveStart, Router, Scroll } from '@angular/router'
import { BehaviorSubject, distinctUntilChanged, filter, firstValueFrom, map } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class RouterActivityService {
  /**
   * Whether or not navigation is currently happening
   */
  private readonly canRedirect = new BehaviorSubject<boolean>(false)

  constructor(private router: Router) {
    this.router.events
      .pipe(
        map(
          routerEvent =>
            !(
              routerEvent instanceof NavigationEnd ||
              routerEvent instanceof NavigationCancel ||
              routerEvent instanceof NavigationError ||
              // If a 403 is triggered during route resolution (Aka breadcrumb resolvers),
              // we will not get a navigation end event.
              routerEvent instanceof ResolveStart ||
              // If a scroll is triggered after navigation end, we run into issues with the wait for redirect call.
              routerEvent instanceof Scroll
            ),
        ),
        distinctUntilChanged(),
      )
      .subscribe(isNavigating => this.canRedirect.next(isNavigating))
  }

  get canCurrentlyRedirect(): boolean {
    return this.canRedirect.getValue()
  }

  /**
   * Resolves as soon as the router has stopped navigating
   *
   * Returns immediately if not navigating
   */
  async waitForRedirect(): Promise<boolean> {
    if (!this.canCurrentlyRedirect) {
      return true
    }

    return firstValueFrom(this.canRedirect.pipe(filter(x => !x)))
  }
}
