<button
  class="single-sign-on__identity-provider"
  data-track
  ftrButton
  [fullWidth]="true"
  color="secondary"
  (click)="startSSOLogin(identityProvider.Google)"
>
  <ftr-icon icon="Google" [interactive]="false" align="Top"></ftr-icon>
  Continue with Google
</button>
<button
  class="single-sign-on__identity-provider"
  data-track
  ftrButton
  [fullWidth]="true"
  color="secondary"
  (click)="startSSOLogin(identityProvider.Microsoft)"
>
  <ftr-icon icon="Microsoft" [interactive]="false" align="Top"></ftr-icon>
  Continue with Microsoft
</button>
<ng-container *ifFeatureFlagEnabled="'facebook-sso'">
  <button
    class="single-sign-on__identity-provider"
    data-track
    ftrButton
    [fullWidth]="true"
    color="secondary"
    (click)="startSSOLogin(identityProvider.Facebook)"
  >
    <ftr-icon icon="Facebook" [interactive]="false" align="Top"></ftr-icon>
    Continue with Facebook
  </button>
</ng-container>
@if (showMockLogin) {
  <button
    class="single-sign-on__identity-provider"
    data-track
    ftrButton
    [fullWidth]="true"
    color="secondary"
    id="mockLoginButton"
    (click)="startSSOLogin(identityProvider.Mock)"
  >
    Continue with Mock
  </button>
}
