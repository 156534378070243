import { Component, OnDestroy, OnInit } from '@angular/core'
import { PrivateConfiguration } from '@ftr/contracts/api/configuration'
import { ApiResult, DestroySubscribers } from '@ftr/foundation'
import { GetCurrentUserAction, GetCurrentUserGroupsAction, UserState } from '@ftr/ui-user'
import { Store } from '@ngxs/store'

@Component({
  selector: 'ftr-help-contact-details',
  templateUrl: './help-contact-details.component.html',
  styleUrls: ['./help-contact-details.component.css'],
})
export class HelpContactDetailsComponent extends DestroySubscribers implements OnInit, OnDestroy {
  parent: HTMLElement | null
  child: HTMLElement | null
  contactDetails: ApiResult<PrivateConfiguration>

  constructor(private store: Store) {
    super()
  }

  ngOnInit(): void {
    /* We don't call bootstrap for this route because no user authentication is required,
      but still need to know the user's details if they're logged in
      to maintain the state of the header and to fill in the email subject */
    this.store.dispatch(new GetCurrentUserAction())
    this.store.dispatch(new GetCurrentUserGroupsAction())
    this.contactDetails = this.store.select(UserState.currentCourtSystemConfiguration)
  }
}
