import { UserGroupPermissionId } from '@ftr/contracts/api/user-group'

/**
 * These are the permissions used to show/hide tiles on the Admin page.
 * If you add a tile that requires a different permission, you should add the permission to this list.
 * This list is used to show links to the Admin page if a user has at least one of these permissions.
 */
export const ADMIN_PAGE_PERMISSIONS = [
  UserGroupPermissionId.AdministerBillingGroups,
  UserGroupPermissionId.AdministerHolidays,
  UserGroupPermissionId.AdministerLocations,
  UserGroupPermissionId.AdministerUserGroups,
  UserGroupPermissionId.ConfigureCourtSystem,
  UserGroupPermissionId.RemoveTrms,
  UserGroupPermissionId.WriteRecordingVault,
  UserGroupPermissionId.ManageRealtimeRecorders,
  UserGroupPermissionId.ViewRealTimeRecorders,
]
