import { Injectable } from '@angular/core'
import { Breadcrumb, BreadcrumbObjectResolver, buildBreadcrumb } from '@ftr/foundation'
import { Observable, of } from 'rxjs'

/**
 * This breadcrumb resolver is used for Help & Support page because it doesn't need authentication
 * therefore is not a child of the home path
 */
@Injectable()
export class HelpBreadrumbResolver implements BreadcrumbObjectResolver {
  resolve(): undefined | Observable<Breadcrumb> {
    return of(buildBreadcrumb('Help & Support', '/', {}, buildBreadcrumb('Home', '/')))
  }
}
