import { LocalDateTime } from '@js-joda/core'

interface StartTimedEntity {
  startTime: LocalDateTime
}

export function startTimeSorter(a: StartTimedEntity, b: StartTimedEntity): number {
  return a.startTime.compareTo(b.startTime)
}

/**
 * Tolerance is in seconds, as that's the minimum reasonable time between trm's,
 * in HLS playlists we use 1 second segments for example. And, in regards to
 * sealing, the smallest sealable time is 1 second.
 *
 * The following set of functions are copied from Regional API [1].
 *
 * [1]: GitHub source link https://bit.ly/3pXLlJt
 */
export function isSameWithinTolerance(time1: LocalDateTime, time2: LocalDateTime, tolerance: number = 0): boolean {
  return isSameOrBefore(time1, time2, tolerance) && isSameOrAfter(time1, time2, tolerance)
}

export function isSameOrAfter(time1: LocalDateTime, time2: LocalDateTime, tolerance: number = 0): boolean {
  const toCheck = tolerance === 0 ? time1 : time1.plusSeconds(tolerance)
  return toCheck.isEqual(time2) || toCheck.isAfter(time2)
}

export function isSameOrBefore(time1: LocalDateTime, time2: LocalDateTime, tolerance: number = 0): boolean {
  const toCheck = tolerance === 0 ? time1 : time1.minusSeconds(tolerance)
  return toCheck.isEqual(time2) || toCheck.isBefore(time2)
}

/**
 * Identical to the above but for checking millisecond tolerance. Useful for live remarks and ensuring partial updates
 * are related based on start time.
 */
export function isSameWithinMs(time1: LocalDateTime, time2: LocalDateTime, toleranceMs: number = 0): boolean {
  return isSameOrBeforeMs(time1, time2, toleranceMs) && isSameOrAfterMs(time1, time2, toleranceMs)
}
export function isSameOrAfterMs(time1: LocalDateTime, time2: LocalDateTime, toleranceMs: number = 0): boolean {
  const toCheck = toleranceMs === 0 ? time1 : time1.plusNanos(toleranceMs * NANOS_IN_A_MILLISECOND)
  return toCheck.isEqual(time2) || toCheck.isAfter(time2)
}
export function isSameOrBeforeMs(time1: LocalDateTime, time2: LocalDateTime, toleranceMs: number = 0): boolean {
  const toCheck = toleranceMs === 0 ? time1 : time1.minusNanos(toleranceMs * NANOS_IN_A_MILLISECOND)
  return toCheck.isEqual(time2) || toCheck.isBefore(time2)
}

export const NANOS_IN_A_SECOND = 1_000_000_000
export const NANOS_IN_A_MILLISECOND = 1_000_000

export function isDefinedAndEqual(a: LocalDateTime | undefined, b: LocalDateTime | undefined): boolean {
  return !!a && !!b && a.equals(b)
}
