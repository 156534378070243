import { Injectable } from '@angular/core'
import { CourtSystemRepository } from '@ftr/contracts/court-uploader'
import { Uuid } from '@ftr/contracts/type/shared'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { SetRepositoryAction } from './repository.actions'
import { RepositoryStateModel } from './repository.model'

export function defaultRepositoryState(): RepositoryStateModel {
  return {}
}

@State<RepositoryStateModel>({
  name: 'repositoryState',
  defaults: defaultRepositoryState(),
})
@Injectable()
export class RepositoryState {
  @Selector()
  static getRepositoryById(state: RepositoryStateModel): (repositoryId: Uuid) => CourtSystemRepository | undefined {
    const fn = (repositoryId: Uuid): CourtSystemRepository | undefined => {
      return state[repositoryId]
    }

    return fn
  }

  @Action(SetRepositoryAction)
  setRepository({ patchState }: StateContext<RepositoryStateModel>, { repository }: SetRepositoryAction): void {
    patchState({ [repository.id]: repository })
  }
}
