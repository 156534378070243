import { Injectable } from '@angular/core'
import { SocketService } from '@ftr/ui-user'
import { Observable, filter, tap } from 'rxjs'
import { ForceReloadMessage } from '~app/services/force-reload-service/ForceReloadMessage'
import { WindowRefService } from '~app/services/window/window-ref.service'

const DO_NOT_RELOAD_ON_REGEX = /(orders\/(transcript|audio)\/(new|resubmit)|(transcript-orders\/.+\/edit))/
/**
 * A basic ability to force reload a users page if they are connected to the internet.
 * We tend to run into issues with people sticking on very old versions of our application, so need a way to force them
 * to refresh the page. This is a basic step into providing a proper release notification.
 * To force reload users, you need to send the SQS message:
 * {
    "$name": "ftr-core/NotifyRoom",
    "$version": 0,
    "roomId": "forceReload",
    "message": {
        "$name": "ftr-core/ForceReloadMessage",
        "$version": 0
    }
}
 */
@Injectable({
  providedIn: 'root',
})
export class ForceReloadService {
  constructor(
    private readonly socketService: SocketService,
    private readonly windowRefService: WindowRefService,
  ) {}

  reloadOnSocketMessage(): Observable<ForceReloadMessage> {
    return this.socketService.onMessage({ roomId: 'forceReload' }, ForceReloadMessage).pipe(
      filter(() => !DO_NOT_RELOAD_ON_REGEX.test(this.windowRefService.location().href)),
      tap(() => this.windowRefService.location().reload()),
    )
  }
}
