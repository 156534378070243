import { ONE_SECOND_MS } from '@ftr/contracts/type/shared'
import { LocalTimeRange } from '@ftr/contracts/type/shared/LocalTimeRange'
import { DateFormat, jodaFormatPatternWithLocale } from '@ftr/foundation'
import { Duration, LocalTime } from '@js-joda/core'

export type TimeValidationReason =
  | 'start-must-be-before-end'
  | 'start-is-out-of-bounds'
  | 'end-is-out-of-bounds'
  | 'both-out-of-bounds'
  | 'future-end-time'
  | 'start-is-not-a-time'
  | 'end-is-not-a-time'
  | 'both-are-not-times'

export type DifferentSessionsValidationReason = 'different-sessions'

export interface TimeValidation<TReason> {
  reason: TReason
  matchingStartBoundary?: LocalTimeRange
  matchingEndBoundary?: LocalTimeRange
}

export type BasicTimeValidation = TimeValidation<TimeValidationReason>

export function runValidators<TReason>(
  validators: (() => TimeValidation<TReason> | undefined)[],
): TimeValidation<TReason> | undefined {
  for (const validator of validators) {
    const validationResult = validator()
    if (validationResult !== undefined) {
      return validationResult
    }
  }
  return undefined
}

/**
 * behaviour
 *
 * if startTime: start inclusive/end exclusive
 * if endTime: start exclusive/end inclusive
 */
export function validateSectionTimes(startTime: LocalTime, endTime: LocalTime): BasicTimeValidation | undefined {
  if (startTime.equals(endTime) || startTime.isAfter(endTime)) {
    return { reason: 'start-must-be-before-end' }
  }
  if (Duration.between(startTime, endTime).toMillis() < ONE_SECOND_MS) {
    // Annotations/sessions/sealed segments can only be accurate to the second and must have at least 1 second duration
    return { reason: 'start-must-be-before-end' }
  }

  return undefined
}

export function validateBoundaries(
  startBoundary: LocalTimeRange | undefined,
  endBoundary: LocalTimeRange | undefined,
): BasicTimeValidation | undefined {
  if (!startBoundary && !endBoundary) {
    return { reason: 'both-out-of-bounds' }
  }

  if (!startBoundary) {
    return { reason: 'start-is-out-of-bounds', matchingEndBoundary: endBoundary ?? undefined }
  }
  if (!endBoundary) {
    return { reason: 'end-is-out-of-bounds', matchingStartBoundary: startBoundary ?? undefined }
  }
  return undefined
}

export function validateSameSession(
  startSession: LocalTimeRange,
  endSession: LocalTimeRange,
): TimeValidation<DifferentSessionsValidationReason> | undefined {
  const sameSession = startSession.encloses(endSession)
  if (!sameSession) {
    return { reason: 'different-sessions' }
  }
  return undefined
}

export function isFutureEndTime(
  endTime: LocalTime,
  endBoundary: LocalTimeRange,
  liveLocaltime: LocalTime | undefined,
): BasicTimeValidation | undefined {
  if (!liveLocaltime || endTime.isBefore(liveLocaltime) || endTime.equals(liveLocaltime)) {
    return undefined
  }

  const isOngoing = endTime.equals(endBoundary.end)
  if (!isOngoing) {
    return { reason: 'future-end-time' }
  }
  return undefined
}

export function getEnclosingTimeRangeForStartTime(
  startTime: LocalTime,
  timeRanges: LocalTimeRange[],
): LocalTimeRange | undefined {
  return timeRanges.find(tr => timeRangeContainsWithInclusiveStart(startTime, tr))
}

export function getEnclosingTimeRangeForEndTime(
  endTime: LocalTime,
  timeRanges: LocalTimeRange[],
): LocalTimeRange | undefined {
  return timeRanges.find(tr => timeRangeContainsWithInclusiveEnd(endTime, tr))
}

export function timeRangeContainsWithInclusiveStart(time: LocalTime, timeRange: LocalTimeRange): boolean {
  return timeRange.contains(time)
}

export function timeRangeContainsWithInclusiveEnd(time: LocalTime, timeRange: LocalTimeRange): boolean {
  return timeRange.start.isBefore(time) && (timeRange.end.equals(time) || timeRange.end.isAfter(time))
}

export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function formatSuggestedTime(suggestedTime: LocalTime): string {
  return suggestedTime.format(jodaFormatPatternWithLocale(DateFormat.TimeWithSeconds))
}
