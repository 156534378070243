import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { CustomerOrder, LineItem } from '@ftr/contracts/api/order'
import { OrderAttachmentDetails } from '@ftr/contracts/api/order/OrderAttachmentDetails'
import { OrderAttachmentType } from '@ftr/contracts/type/order'
import { Uuid } from '@ftr/contracts/type/shared'
import { FileUpload, UploadStates, attachmentsRequiredValidator } from '@ftr/ui-files'
import { isAudioOrTranscriptLineItem } from '@ftr/ui-ordering'
import moment from 'moment-timezone'
import { RealTimeOrderFormGroups } from '~app/features/real-time-order-form/real-time-order-form-builder.service'
import { AudioOrderFormGroups } from '../audio-order-form/audio-order-form-builder.service'
import { TranscriptOrderFormGroups } from '../transcript-order-form/transcript-order-form-builder.service'
import { OrderReviewFieldNames } from './steps/order-review/order-review.component'

/**
 * Describes the form group names to assist in fetching the form group for a step.
 */
export enum OrderFormGroups {
  CaseDetails = 'caseDetails',
  Documents = 'documents',
  Review = 'review',
}

export type OrderFormDefaults<TLineItem extends LineItem = LineItem> = Partial<CustomerOrder<TLineItem>> & {
  hearingDate?: moment.Moment
}

export abstract class OrderFormBuilderService {
  protected formGroup: UntypedFormGroup
  protected isSupportingDocumentsRequired = true
  protected isSupportingDocumentsEnabled = true

  protected constructor(protected readonly formBuilder: UntypedFormBuilder) {}

  getFormForStep(
    stepName: OrderFormGroups | AudioOrderFormGroups | TranscriptOrderFormGroups | RealTimeOrderFormGroups,
  ): UntypedFormGroup {
    return this.formGroup.controls[stepName] as UntypedFormGroup
  }

  destroy(): void {
    // Instead of setting the form to undefined, remove its controls
    if (this.formGroup) {
      this.formGroup.controls = {}
    }
  }

  protected buildCaseDetailsFormGroup(defaults: OrderFormDefaults | undefined): UntypedFormGroup {
    const lineItem = defaults?.lineItem

    if (lineItem && !isAudioOrTranscriptLineItem(lineItem)) {
      throw Error('Expected Audio or Transcript line item')
    }

    return this.formBuilder.group({
      caseTitle: [lineItem?.caseTitle, Validators.required],
      caseNumber: [lineItem?.caseNumber, Validators.required],
    })
  }

  protected buildDocumentsFormGroup(defaults: OrderFormDefaults | undefined): UntypedFormGroup {
    const attachments: FileUpload[] = this.toFileUpload(
      defaults?.attachments,
      defaults?.courtSystemId,
      defaults?.userId,
      OrderAttachmentType.SupportingDocument,
    )

    const attachmentsControl = [
      attachments,
      ...(this.isSupportingDocumentsRequired ? [attachmentsRequiredValidator] : []),
    ]

    return this.formBuilder.group({
      attachments: attachmentsControl,
    })
  }

  /**
   * The validity of these fields are determined at the component level, as they are optional
   * depending on whether the user has the PlaceOrderWithoutHolds permission or not.
   */
  protected buildReviewFormGroup(
    defaults: OrderFormDefaults | undefined,
    isCostWaiverDocumentOptional: boolean,
  ): UntypedFormGroup {
    const costWaiverAttachments = this.toFileUpload(
      defaults?.attachments,
      defaults?.courtSystemId,
      defaults?.userId,
      OrderAttachmentType.CostWaiverDocument,
    )

    const costWaiverRequested = !!defaults?.costWaiverStatus

    const costWaiverAttachmentsControl = [
      costWaiverAttachments,
      ...(costWaiverRequested && !isCostWaiverDocumentOptional ? [attachmentsRequiredValidator] : []),
    ]

    return this.formBuilder.group({
      [OrderReviewFieldNames.CostWaiverRequested]: [costWaiverRequested],
      [OrderReviewFieldNames.CostWaiverAttachments]: costWaiverAttachmentsControl,
      [OrderReviewFieldNames.PaymentSaveCard]: [],
      [OrderReviewFieldNames.PaymentSavedCardId]: [],
      [OrderReviewFieldNames.PaymentNewCard]: [],
    })
  }

  private toFileUpload(
    attachments: OrderAttachmentDetails[] | undefined,
    courtSystemId: Uuid | undefined,
    userId: Uuid | undefined,
    filterBy: OrderAttachmentType,
  ): FileUpload[] {
    const filteredAttachments: FileUpload[] =
      attachments
        ?.filter(a => a.type === filterBy)
        .map(a => ({
          id: a.id,
          file: {
            name: a.name,
            size: a.byteSize,
          },
          status: UploadStates.Success,
          fileId: a.fileId,
          props: {
            courtSystemId,
          },
          label: userId !== a.userId ? ' by admin' : undefined,
        })) || []
    return filteredAttachments
  }
}
