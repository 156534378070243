import { Injectable } from '@angular/core'
import { DesktopDownloadUrlService } from '@ftr/api-core'
import { isInDesktopApp } from '@ftr/foundation'
import { StaticMenuItem } from '@ftr/menus'
import { AppPaths, OrderPaths } from '@ftr/routing-paths'

/**
 * Contains navigation items that are used by all users.
 */
@Injectable({
  providedIn: 'root',
})
export class GlobalNavItems {
  constructor(private readonly desktopDownloadUrlService: DesktopDownloadUrlService) {}

  myUploads(): StaticMenuItem {
    return {
      title: 'Upload & Play',
      path: [`/${AppPaths.Uploads}`],
    }
  }

  myOrders(): StaticMenuItem {
    return {
      title: 'My Orders',
      path: [`/${AppPaths.Orders}`],
    }
  }

  orderTranscript(): StaticMenuItem {
    return {
      title: 'Order Transcript',
      path: [`/${AppPaths.Orders}`, OrderPaths.ChooseCourt, OrderPaths.Transcript],
    }
  }

  selectCourtSystem(): StaticMenuItem {
    return {
      title: 'Select a Court System',
      path: [`/${AppPaths.CourtSystem}`],
    }
  }

  orderAudio(): StaticMenuItem {
    return {
      title: 'Order Recording',
      path: [`/${AppPaths.Orders}`, OrderPaths.ChooseCourt, OrderPaths.Audio],
    }
  }

  orderLive(): StaticMenuItem {
    return {
      title: 'Order Live',
      path: [`/${AppPaths.Orders}`, OrderPaths.ChooseCourt, OrderPaths.RealTime],
    }
  }

  accountDetails(): StaticMenuItem {
    return {
      title: 'Account Details',
      path: [`/${AppPaths.Account}`],
    }
  }

  getDesktopApp(): StaticMenuItem {
    if (isInDesktopApp()) {
      return { title: '' }
    }

    return {
      title: 'Get Desktop App',
      onSelect: () => {
        this.desktopDownloadUrlService.openDownloadUrl()
      },
    }
  }

  logout(): StaticMenuItem {
    return {
      title: 'Logout',
      path: [`/${AppPaths.Logout}`],
    }
  }

  sharedRecordings(): StaticMenuItem {
    return {
      title: 'Shared Recordings',
      path: [`/${AppPaths.SharedRecordings}`],
    }
  }

  myAuditLog(): StaticMenuItem {
    return {
      title: 'My Audit Log',
      path: [`/${AppPaths.AuditLog}`],
    }
  }
}
