import { Component, OnInit } from '@angular/core'
import { DestroySubscribers, NotificationType, RouterActivityService } from '@ftr/foundation'
import { LaunchDarklyService } from '@ftr/ui-feature-flags'
import { AuthenticationService, GetCurrentUserAction } from '@ftr/ui-user'
import { Store } from '@ngxs/store'
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs'
import { WindowRefService } from '~app/services/window/window-ref.service'

export const defaultForbiddenError =
  'Sorry, you do not have access to this page. Please use the navigation menu to select another page.'

@Component({
  selector: 'ftr-forbidden',
  templateUrl: './forbidden.component.html',
})
export class ForbiddenComponent extends DestroySubscribers implements OnInit {
  constructor(
    private readonly routerActivityService: RouterActivityService,
    private readonly windowRefService: WindowRefService,
    private readonly launchDarklyService: LaunchDarklyService,
    private readonly authenticationService: AuthenticationService,
    private readonly store: Store,
  ) {
    super()
  }

  errorMessage: string

  readonly notificationType = NotificationType

  async ngOnInit(): Promise<void> {
    await this.routerActivityService.waitForRedirect()
    const state = this.windowRefService.history().state
    this.authenticationService.initialize().then(() => {
      if (!this.authenticationService.getCurrentUserId()) {
        this.errorMessage = state?.userMessage || defaultForbiddenError
      } else {
        this.store.dispatch(new GetCurrentUserAction())
        this.launchDarklyService
          .observeFlagChanges<boolean>('detailed-error-messages')
          .pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.finalize))
          .subscribe(detailedErrorMessageEnabled => {
            if (detailedErrorMessageEnabled) {
              this.errorMessage = state?.developerMessage || state?.userMessage || defaultForbiddenError
            } else {
              this.errorMessage = state?.userMessage || defaultForbiddenError
            }
          })
      }
    })
  }
}
