<div class="appearances-edit">
  <ul class="form-list appearances-edit__list">
    @for (group of internalFormArray.controls; track group; let i = $index) {
      <li class="card card--borderless card--group">
        <div class="appearances-edit__item">
          <ftr-form-input
            class="appearances-edit__role"
            name="role"
            label="Role"
            autocomplete="off"
            [ngFormControl]="asFormControl(group.get('role')!)"
            [required]="true"
            [maxLength]="maxInputLength"
          >
          </ftr-form-input>
          <ftr-form-input
            class="appearances-edit__name"
            name="name"
            label="Full Name"
            autocomplete="off"
            [ngFormControl]="asFormControl(group.get('name')!)"
            [required]="true"
            [maxLength]="maxInputLength"
          >
          </ftr-form-input>
          @if (internalFormArray.controls.length) {
            <button
              ftrButton
              displayType="icon"
              size="large"
              type="button"
              title="Remove appearance"
              class="appearances-edit__remove"
              (click)="removeAppearance(i)"
            >
              <ftr-icon icon="Close" align="Middle" size="ExtraLarge"></ftr-icon>
            </button>
          }
        </div>
      </li>
    }
    @if (internalFormArray.controls.length < maxAppearances) {
      <li class="appearances-edit__add-range">
        <button
          [fullWidthMobile]="!onEditPage"
          type="button"
          (click)="addAppearance()"
          ftrButton
          color="secondary"
          [flexLayout]="true"
        >
          <ftr-icon icon="Add"></ftr-icon>
          <span>Add Appearance</span>
        </button>
      </li>
    }
  </ul>
</div>
