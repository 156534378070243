import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'
import { IS_EMAIL_DEFAULT_OPTIONS } from '@ftr/contracts/shared/validator'
import { isEmail } from 'class-validator'

export const invalidEmailMessage = 'You must enter a valid email.'

export const emailValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const value: string = control.value
  return isEmail(value, IS_EMAIL_DEFAULT_OPTIONS) ? null : { invalidEmail: invalidEmailMessage }
}
