import { HttpParams } from '@angular/common/http'
import { Component, Input, OnInit } from '@angular/core'
import { Router, RouterLink } from '@angular/router'
import { RootParams } from '@ftr/api-shared'
import { IdentityProviderName } from '@ftr/contracts/type/account'
import { LoaderComponent } from '@ftr/foundation'
import { AppPaths } from '@ftr/routing-paths'
import { DeepLinkService } from '~app/features/desktop/deep-link/deep-link.service'
import { WindowRefService } from '~app/services/window/window-ref.service'

@Component({
  standalone: true,
  imports: [LoaderComponent, RouterLink],
  selector: 'ftr-desktop-login-splash',
  templateUrl: 'desktop-login-splash.template.html',
  styleUrls: ['desktop-login-splash.style.css'],
})
export class DesktopLoginSplashComponent implements OnInit {
  @Input() outgoingIdentityProvider: IdentityProviderName | undefined

  @Input() authorizationCode: string | undefined
  @Input() state: string | undefined

  constructor(
    private readonly router: Router,
    private readonly windowRefService: WindowRefService,
    private readonly deepLinkService: DeepLinkService,
  ) {}

  ngOnInit(): void {
    // Don't do anything when showing the splash page for the desktop app outgoing deep link
    if (this.outgoingIdentityProvider && Object.values(IdentityProviderName).includes(this.outgoingIdentityProvider)) {
      return
    }

    // Automatically deep link into the app when we're supplied the state/authorization code
    if (this.authorizationCode && this.state) {
      this.deepLinkService.openDeepLink(this.buildExternalLoginUrl(this.authorizationCode, this.state))
      this.router.navigate([AppPaths.DesktopLoginSplash], { replaceUrl: true })
    } else {
      // If they refresh/navigate to this page without the params, forward them to the default page
      this.navigateToDefaultPage()
    }
  }

  navigateToDefaultPage(): void {
    this.router.navigate([AppPaths.DefaultPage], { replaceUrl: true })
  }

  private buildExternalLoginUrl(authorizationCode: string, state: string): string {
    const params = new HttpParams({
      fromObject: {
        [RootParams.AuthorizationCode]: authorizationCode,
        [RootParams.State]: state,
      },
    })

    return `${this.windowRefService.getHostName()}/${AppPaths.ExternalLogin}?${params.toString()}`
  }
}
