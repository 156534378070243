import { Component, Input, OnInit } from '@angular/core'
import { Params, Router } from '@angular/router'
import { DEFAULT_LOGIN_ROUTE, RETURN_TO_DESKTOP, RETURN_URL_KEY, RootParams } from '@ftr/api-shared'
import { DJ_COURT_SYSTEM_ID, DJ_KEY } from '@ftr/contracts/digital-justice'
import { IdentityProviderName } from '@ftr/contracts/type/account'
import { LocalStorageService, NotificationType, PageStyle } from '@ftr/foundation'
import { AppPaths } from '@ftr/routing-paths'
import { AuthenticationService } from '@ftr/ui-user'
import { BehaviorSubject } from 'rxjs'

@Component({
  selector: 'ftr-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  host: {
    class: 'login page',
  },
})
export class LoginComponent implements OnInit {
  @Input() returnUrl: string | undefined
  @Input() identityProvider: IdentityProviderName | undefined
  @Input() codeChallenge: string | undefined
  @Input() state: string | undefined
  @Input() authorizationCode: string | undefined
  @Input() showMockLogin: boolean

  isHandlingNativeAuth = false
  isAccessingSharedAudio = false

  readonly pageStyle = PageStyle.Narrow
  readonly notificationType = NotificationType

  constructor(
    private localStorageService: LocalStorageService,
    private readonly router: Router,
    private authenticationService: AuthenticationService,
  ) {}

  ngOnInit(): void {
    this.setReturnUrl()
    this.setAccessingSharedAudio()
    this.setDJLocalStorageItems()
    this.handleNativeAuth()
  }

  private setReturnUrl(): void {
    if (!this.returnUrl) {
      const storedReturnUrl = this.localStorageService.getGlobal<string>(RETURN_URL_KEY)

      if (storedReturnUrl && storedReturnUrl !== DEFAULT_LOGIN_ROUTE) {
        this.returnUrl = storedReturnUrl

        // make it obvious when a returnUrl will be used
        this.router.navigate([AppPaths.Login], {
          queryParams: { [RootParams.ReturnUrl]: storedReturnUrl },
          replaceUrl: true,
        })
      }
    }
  }

  private setAccessingSharedAudio(): void {
    if (this.returnUrl) {
      this.isAccessingSharedAudio = this.returnUrl.startsWith(`/${AppPaths.SharedWithMe}`)
    }
  }

  private setDJLocalStorageItems(): void {
    // The library typings are incorrect, this is in fact a BehaviorSubject
    const djKey = (this.router.routerState.root.queryParams as BehaviorSubject<Params>)?.value?.[DJ_KEY]
    djKey && this.localStorageService.setGlobal(DJ_KEY, djKey)
    const djCsId = (this.router.routerState.root.queryParams as BehaviorSubject<Params>)?.value?.[DJ_COURT_SYSTEM_ID]
    djCsId && this.localStorageService.setGlobal(DJ_COURT_SYSTEM_ID, djCsId)
  }

  private handleNativeAuth(): void {
    if (this.identityProvider && this.codeChallenge && this.state) {
      this.isHandlingNativeAuth = true

      this.localStorageService.setGlobal(RETURN_TO_DESKTOP, true)

      this.authenticationService.loginWithRedirect(
        this.identityProvider,
        this.returnUrl,
        this.codeChallenge,
        this.state,
        true,
      )
    }
  }
}
