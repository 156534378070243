import { Injectable } from '@angular/core'
import { ApiClient, ApiClientFactory, serializeHttpParams } from '@ftr/api-shared'
import { CourtSystemIdQuery } from '@ftr/contracts/api/shared'
import {
  ListTranscriptOrderingFeatureForCourtSystemsBody,
  TranscriptOrderingFeature,
} from '@ftr/contracts/api/transcript-ordering-feature'
import { Uuid } from '@ftr/contracts/type/shared'
import { ApiResult, createAndAssign } from '@ftr/foundation'
import { FeatureService } from './feature.service'

@Injectable({
  providedIn: 'root',
})
export class TranscriptOrderingFeatureService extends FeatureService<TranscriptOrderingFeature> {
  private readonly apiClient: ApiClient

  constructor(apiClientFactory: ApiClientFactory) {
    super()
    this.apiClient = apiClientFactory.build('/transcript-ordering-feature')
  }

  updateFeature<UpdateTranscriptOrderingFeatureBody>(
    featureId: Uuid,
    body: UpdateTranscriptOrderingFeatureBody,
  ): ApiResult<TranscriptOrderingFeature> {
    return this.apiClient.patch({ path: `/${featureId}/`, body })
  }

  getForCourtSystem(courtSystemId: Uuid): ApiResult<TranscriptOrderingFeature> {
    return this.apiClient.get<TranscriptOrderingFeature>({
      path: '/getForCourtSystem',
      params: serializeHttpParams(createAndAssign(CourtSystemIdQuery, { courtSystemId })),
    })
  }

  listForCourtSystems(courtSystemIds: Uuid[]): ApiResult<TranscriptOrderingFeature[]> {
    const body = new ListTranscriptOrderingFeatureForCourtSystemsBody()
    body.courtSystemIds = courtSystemIds
    return this.apiClient.post({ path: '/listForCourtSystems', body })
  }
}
