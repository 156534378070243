// See https://www.typescriptlang.org/docs/handbook/triple-slash-directives.html
/// <reference types="stripe-v3" />

import { Injectable } from '@angular/core'
import { STRIPE_API_VERSION } from '@ftr/contracts/type/order'

const STRIPE_URL = 'https://js.stripe.com/v3/'
const STRIPE_ELEMENT_ID = 'stripev3'

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  private loading = false

  async create(token: string): Promise<stripe.Stripe> {
    await this.loadStripeScript()
    return Stripe(token, { apiVersion: STRIPE_API_VERSION })
  }

  async createToken(stripe: stripe.Stripe, cardElement: stripe.elements.Element): Promise<stripe.Token> {
    const res = await stripe.createToken(cardElement)

    if (res.error) {
      throw res.error
    } else {
      return res.token!
    }
  }

  async loadStripeScript(): Promise<{}> {
    if (!this.loading && !document.getElementById(STRIPE_ELEMENT_ID)) {
      this.loading = true
      return new Promise(resolve => {
        const scriptElement = document.createElement('script')
        scriptElement.src = STRIPE_URL
        scriptElement.id = STRIPE_ELEMENT_ID
        scriptElement.onload = () => resolve({})
        document.body.appendChild(scriptElement)
      })
    } else {
      return Promise.resolve({})
    }
  }
}
