/* eslint-disable max-classes-per-file */
import { RealTimePlaybackKey } from '@ftr/data-realtime-playback'
import { LocalTime } from '@js-joda/core'
import { HearingTimelineSection, SealedTimelineSection, SessionTimelineSection } from '../types'

export class UpdateHearingTimelineSectionsCommand {
  static readonly type = '[Recording Timeline] Update Hearing Timeline Sections'
  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly hearingTimelineSections: HearingTimelineSection[],
  ) {}
}

export class UpdateSessionTimelineSectionsCommand {
  static readonly type = '[Recording Timeline] Update Session Timeline Sections'
  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly sessionTimelineSections: SessionTimelineSection[],
  ) {}
}

export class UpdateSealedTimelineSectionsCommand {
  static readonly type = '[Recording Timeline] Update Sealed Timeline Sections'
  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly sealedTimelineSections: SealedTimelineSection[],
  ) {}
}

export class UpdatePlaybackTimeCommand {
  static readonly type = '[Recording Timeline] Update Playback Time'
  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly playbackTime: LocalTime | undefined,
  ) {}
}

export class UpdateRecordingTimelineLiveLocalTimeCommand {
  static readonly type = '[Recording Timeline] Update Recording Timeline Live LocalTime'
  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly liveLocalTime: LocalTime | undefined,
  ) {}
}
