import { Department } from '@ftr/contracts/api/department'
import { JobWithOrderResponse, JobWithOrderSummaryResponse } from '@ftr/contracts/api/job'
import { Location } from '@ftr/contracts/api/location/Location'
import { OrderAttachmentDetails, PaymentWaivedReason } from '@ftr/contracts/api/order'
import { Order } from '@ftr/contracts/api/order/Order'
import { Turnaround } from '@ftr/contracts/api/transcript-turnaround'
import { User } from '@ftr/contracts/api/user/User'
import { TranscriptOrderStatus } from '@ftr/contracts/read'
import { Charge } from '@ftr/contracts/read/charge'
import { HearingSegment } from '@ftr/contracts/type/court'
import { CostWaiverStatus, LineItemType } from '@ftr/contracts/type/order'
import { Money } from '@ftr/contracts/type/order/Money'
import { OrderStatus } from '@ftr/contracts/type/order/OrderStatus'
import { EmailAddress } from '@ftr/contracts/type/shared'
import { Appearance, JobStatus } from '@ftr/contracts/type/transcript'
import { createAndAssign } from '@ftr/foundation'
import { LocalDate, ZonedDateTime } from '@js-joda/core'
import { OrderStatusNormalizer } from '../utils'

export class TranscriptOrder implements Order {
  id: string
  jobId: string
  lineItemId: string
  lineItemType: LineItemType
  reference: string
  placedOn: ZonedDateTime
  resubmittedOn: ZonedDateTime | undefined
  userId: string
  estimate: Money
  transcriberFee: Money | undefined
  finalPrice: Money | undefined
  balance: Money | undefined
  estimateCharge: Charge | undefined
  balanceCharge: Charge | undefined
  user: User
  courtSystemId: string
  dueBy: ZonedDateTime
  hearingDate: LocalDate
  /**
   * Time range(s) to be provided for transcription.
   */
  hearingSegments: HearingSegment[]
  /**
   * Portions of the hearing to be transcribed, e.g. Plea, Statements, Sentencing.
   */
  hearingPortions: string[] | undefined
  turnaround: Turnaround
  location: Location
  caseNumber: string
  caseTitle: string
  caseType: string | undefined
  instructions: string | undefined
  status: OrderStatus
  transcriptOrderStatus: TranscriptOrderStatus
  assignment: User | undefined
  jobStatus: JobStatus
  attachments: OrderAttachmentDetails[]
  cancellationReason: string | undefined
  requestMoreInfoReason: string | undefined
  claimedByUser: User | undefined
  distributionList: EmailAddress[] | undefined
  paymentWaivedReason: PaymentWaivedReason | undefined
  department: Department | undefined
  costWaiverStatus: CostWaiverStatus | undefined
  judges: string | undefined
  languages: string | undefined
  appearances: Appearance[] | undefined
  serviceFee: Money | undefined
  paperCopies: number | undefined

  static fromJobWithOrderResponse(jobWithOrder: JobWithOrderResponse | JobWithOrderSummaryResponse): TranscriptOrder {
    return createAndAssign(TranscriptOrder, {
      id: jobWithOrder.order.id,
      jobId: jobWithOrder.id,
      lineItemId: jobWithOrder.lineItem.id,
      lineItemType: jobWithOrder.lineItem.type,
      reference: jobWithOrder.order.reference,
      placedOn: jobWithOrder.order.placedOn,
      resubmittedOn: jobWithOrder.order.resubmittedOn,
      userId: jobWithOrder.order.userId,
      estimate: jobWithOrder.order.estimate!,
      transcriberFee: jobWithOrder.transcript?.versions.find(v => v.isCurrent)?.transcriberFee,
      finalPrice: jobWithOrder.order.finalPrice,
      balance: jobWithOrder.order.balance,
      estimateCharge: jobWithOrder.order.estimateCharge,
      balanceCharge: jobWithOrder.order.balanceCharge,
      user: jobWithOrder.order.user!,
      courtSystemId: jobWithOrder.courtSystemId,
      dueBy: jobWithOrder.lineItem.dueBy,
      hearingDate: HearingSegment.getSingleHearingDate(jobWithOrder.lineItem.hearingSegments),
      hearingSegments: jobWithOrder.lineItem.hearingSegments,
      hearingPortions: jobWithOrder.lineItem.hearingPortions,
      turnaround: jobWithOrder.lineItem.turnaround!,
      location: jobWithOrder.lineItem.location!,
      caseNumber: jobWithOrder.lineItem.caseNumber,
      caseTitle: jobWithOrder.lineItem.caseTitle,
      caseType: jobWithOrder.lineItem.caseType,
      instructions: jobWithOrder.lineItem.instructions,
      status: jobWithOrder.order.status,
      transcriptOrderStatus: new OrderStatusNormalizer().normalize(jobWithOrder.order.status, jobWithOrder.status),
      assignment: jobWithOrder.assignee,
      jobStatus: jobWithOrder.status,
      attachments: jobWithOrder.order.attachments,
      cancellationReason: jobWithOrder.order.cancellationReason,
      requestMoreInfoReason: jobWithOrder.order.requestMoreInfoReason,
      claimedByUser: jobWithOrder.order.claimedByUser ?? undefined,
      distributionList: jobWithOrder.lineItem.distributionList,
      paymentWaivedReason: jobWithOrder.order.paymentWaivedReason,
      department: jobWithOrder.order.department,
      costWaiverStatus: jobWithOrder.order.costWaiverStatus,
      judges: jobWithOrder.lineItem.judges,
      languages: jobWithOrder.lineItem.languages,
      appearances: jobWithOrder.lineItem.appearances,
      serviceFee: jobWithOrder.order.serviceFee,
      paperCopies: jobWithOrder.lineItem.paperCopies,
    })
  }
}
