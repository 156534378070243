import { Injectable } from '@angular/core'
import { VocabularyTerms } from '@ftr/contracts/type/core'
import { Uuid } from '@ftr/contracts/type/shared'
import { unwrapData } from '@ftr/foundation'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { EMPTY, Observable, catchError, of, tap } from 'rxjs'
import { VocabularyService } from '../services/vocabulary.service'
import { FetchVocabularyTermsAction } from './vocabulary.actions'
import { VocabularyStateModel } from './vocabulary.model'

export function defaultVocabularyTerms(): VocabularyStateModel {
  return {}
}

/**
 * Vocabulary terms cache that supports terms for multiple court systems.
 * Dispatched on app load to provide vocabulary terms for the current court system
 * and to support switching court systems.
 */
@State<VocabularyStateModel>({
  name: 'vocabularyState',
  defaults: defaultVocabularyTerms(),
})
@Injectable({
  providedIn: 'root',
})
export class VocabularyState {
  constructor(private readonly vocabularyService: VocabularyService) {}

  @Selector()
  static state(state: VocabularyStateModel): VocabularyStateModel {
    return state
  }

  /**
   * This is a dynamic selector
   * CourtSystemId is provided into the internal function using a pipe
   *
   * Usage:
   * this.store.select(VocabularyState.termsByCourtSystemId).pipe(
   *    map(fn => fn(courtSystemId)),
   *    filter(terms => !!terms)
   * )
   */
  @Selector([VocabularyState])
  static termsByCourtSystemId(terms: VocabularyStateModel): (courtSystemId: Uuid) => VocabularyTerms {
    return (courtSystemId: Uuid): VocabularyTerms => terms[courtSystemId]
  }

  @Action(FetchVocabularyTermsAction)
  fetchVocabularyTerms(
    { getState, patchState }: StateContext<VocabularyStateModel>,
    { courtSystemId, bypassCache = false }: FetchVocabularyTermsAction,
  ): Observable<VocabularyTerms> {
    const termsByCourtSystemId = getState()[courtSystemId]
    if (termsByCourtSystemId && !bypassCache) {
      return of(termsByCourtSystemId)
    }

    return this.vocabularyService.getCourtSystemTerms(courtSystemId).pipe(
      unwrapData(),
      catchError(_ => EMPTY),
      tap(terms => patchState({ [courtSystemId]: terms })),
    )
  }
}
