export enum OrdersParams {
  OrderId = 'orderId',
  View = 'view',
}

export enum OrderPaths {
  New = 'new',
  ChooseCourt = 'choose-court',
  ChooseDepartment = 'choose-department',
  ChooseProceeding = 'choose-proceeding',
  Audio = 'audio',
  Transcript = 'transcript',
  Resubmit = 'resubmit',
  Playback = 'playback',
  RealTimePlayback = 'real-time-playback',
  RealTime = 'real-time',
  OutstandingPayment = 'outstanding-payment',
}
