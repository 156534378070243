@if (remote | async; as r) {
  <ftr-remote
    class="animated-remote"
    [ngClass]="{ 'animated-remote--fill': displayMode === 'FILL', 'animated-remote--block': displayMode !== 'FILL' }"
    [remote]="r"
    [notAskedTemplate]="notAsked"
    [loadingTemplate]="loading"
    [failureTemplate]="failure"
    [successTemplate]="success"
    [emptyTemplate]="empty"
    [isEmpty]="isEmpty"
  >
    <ng-template #notAsked>
      <div class="animated-remote__state animated-remote__state--not-asked" [@fade]>
        <ng-container *ngTemplateOutlet="notAskedTemplate"></ng-container>
      </div>
    </ng-template>
    <ng-template #loading>
      <div class="animated-remote__state animated-remote__state--loading" [@fade]>
        <ng-container *ngTemplateOutlet="loadingTemplate"></ng-container>
      </div>
    </ng-template>
    <ng-template #failure let-error>
      <div class="animated-remote__state animated-remote__state--error" [@fade]>
        <ng-container *ngTemplateOutlet="failureTemplate; context: { $implicit: error }"></ng-container>
      </div>
    </ng-template>
    <ng-template #success let-data>
      <div class="animated-remote__state animated-remote__state--primary" [@fade]>
        <ng-container *ngTemplateOutlet="successTemplate; context: { $implicit: data }"></ng-container>
      </div>
    </ng-template>
    <ng-template #empty>
      <div class="animated-remote__state animated-remote__state--empty" [@fade]>
        <ng-container *ngTemplateOutlet="emptyTemplate"></ng-container>
      </div>
    </ng-template>
  </ftr-remote>
}
