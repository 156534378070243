import { Injectable } from '@angular/core'
import { ApiClient, ApiClientFactory } from '@ftr/api-shared'
import { UserSettings, UserSettingsBody } from '@ftr/contracts/api/user'
import { ApiResult } from '@ftr/foundation'
import { classToPlain } from '@ftr/serialization'

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  private readonly userApiClient: ApiClient

  constructor(private apiClientFactory: ApiClientFactory) {
    this.userApiClient = this.apiClientFactory.build('/user')
  }

  fetchCurrentUserSettings(): ApiResult<UserSettings | undefined> {
    return this.userApiClient.get({ path: '/settings' })
  }

  updateUserSettings(userSettings: UserSettingsBody): ApiResult<UserSettings> {
    return this.userApiClient.put<UserSettings>({ path: '/settings', body: classToPlain(userSettings) })
  }
}
