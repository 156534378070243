@if (showOrderingInformation) {
  <h2 class="help__heading">Ordering Support</h2>
  <p class="help__description">
    For further information about the ordering process, please see the help articles for
    <ftr-help-link cls="elevio__ordering-audio" label="ordering audio"></ftr-help-link> and
    <ftr-help-link cls="elevio__ordering-transcripts" label="ordering transcripts"></ftr-help-link>.
  </p>
  <p class="help__description">
    If you need help with amending and cancelling orders, status updates or required documentation, please contact the
    court directly.
  </p>
  @if (isLoggedIn) {
    <ftr-help-contact-details></ftr-help-contact-details>
  }
  <p class="help__description">A full list of court contacts can be found in the help articles below:</p>
  <ul class="help__description">
    <li><ftr-help-link cls="elevio__audio-order-support" label="Audio order support"></ftr-help-link></li>
    <li><ftr-help-link cls="elevio__transcript-order-support" label="Transcript order support"></ftr-help-link></li>
  </ul>
}
