import { PageDetails } from '@ftr/contracts/api/core'
import { classToPlain } from '@ftr/serialization'
import { HttpParams } from '../http-client'

/**
 * Represents HTTP params that have been serialized.
 *
 * The type is joined with `__serialized: true` because otherwise the
 * TypeScript compiler uses structural types, and would treat SerializedHttpParams
 * as being equal to HttpParams - in order to make sure that we can differentiate
 * between these two types we need to introduce a difference between them.
 *
 * We don't actually ever put `__serialized: true` onto our `SerializedHttpParams`,
 * so there's no concern of this property leaking to the backend.
 *
 * More info here: https://www.typescriptlang.org/docs/handbook/type-compatibility.html
 */
export type SerializedHttpParams = HttpParams & { __serialized: true }

/**
 * Serializes HTTP params for sending to our backend. Serialization is done through our
 * standard `classToPlain` serialization function, with special care being put into making
 * sure that the `page` pagination parameters are serialized in a way that can be interpreted
 * by our backend.
 *
 * This function should be called with a strongly typed class representing the parameters, however
 * it will also work with a plain object if necessary.
 *
 * @returns SerializedHttpParams
 */
export function serializeHttpParams(params: any): SerializedHttpParams {
  if (!params) {
    return params
  }
  let serialized = classToPlain(params)
  if (isPage(params.page)) {
    const pageParams = { 'page[number]': params.page.number, 'page[size]': params.page.size }
    delete (serialized as { page?: PageDetails }).page
    serialized = { ...serialized, ...pageParams }
  }
  return serialized as SerializedHttpParams
}

function isPage(thing: any): thing is { number: number; size: number } {
  if (!thing) {
    return false
  } else if (thing instanceof PageDetails) {
    return true
  } else {
    return thing.number && typeof thing.number === 'number' && thing.size && typeof thing.size === 'number'
  }
}
