import { TemplatePortal } from '@angular/cdk/portal'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'

export interface PortalContent {
  content: TemplatePortal | undefined
  isModalService: boolean
}

@Injectable({
  providedIn: 'root',
})
export class PortalService {
  private readonly portal$ = new BehaviorSubject<PortalContent | undefined>(undefined)

  getPortal(): Observable<PortalContent | undefined> {
    return this.portal$.asObservable()
  }

  setContent(content: TemplatePortal | undefined, isModalService = false): void {
    const currentValue = this.portal$.getValue()

    // Prevent side-nav from removing modal content when onDestroy is triggered
    if (!content && !!currentValue?.isModalService !== isModalService) {
      return
    }

    this.portal$.next({ content, isModalService })
  }
}
