import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { Router } from '@angular/router'
import {
  Illustration,
  NotificationDisplayType,
  NotificationService,
  NotificationType,
  PageStyle,
} from '@ftr/foundation'
import { AuthenticationService } from '@ftr/ui-user'
import { ResetAttributesService } from '../reset-attributes.service'

@Component({
  selector: 'ftr-reset-sent',
  templateUrl: './reset-sent.component.html',
  styleUrls: ['./reset-sent.component.css'],
  host: {
    class: 'request-sent page',
  },
  encapsulation: ViewEncapsulation.None,
})
export class ResetSentComponent implements OnInit {
  email: string
  isHelpShown = false

  readonly pageStyle = PageStyle.Narrow
  readonly illustration = Illustration

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly resetAttributesService: ResetAttributesService,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    if (this.resetAttributesService.email) {
      this.email = this.resetAttributesService.email
    } else {
      this.router.navigate(['/reset'])
    }
  }

  showHelp(): void {
    this.isHelpShown = true
  }

  resendCode(): void {
    this.authenticationService
      .resetPasswordSendVerification(this.email)
      .then(() => {
        this.notifySent()
      })
      .catch(() => {
        // We are not supposed to show that the user doesn't exist which is the most likely error, see FTRX-3612
        this.notifySent()
      })
  }

  private notifySent(): void {
    this.notificationService.clear()
    this.notificationService.show(NotificationDisplayType.Now, {
      type: NotificationType.Info,
      message:
        'We have sent you another reset password email. ' +
        'Please only use the most recent email to reset your password.',
    })
  }
}
