import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { Turnaround } from '@ftr/contracts/api/transcript-turnaround'
import { SelectItem } from '@ftr/forms'
import {
  buildAvailableTurnarounds,
  buildTurnaroundSelectItems,
  getTurnaroundByIndex,
  getTurnaroundIndex,
} from '@ftr/ui-transcript'
import { Observable, of } from 'rxjs'

/**
 * Partial fields from `UpdateLineItemRequest`.
 */
export interface TranscriptFields {
  turnaround: Turnaround
  paperCopies: number | undefined
}

export function buildTranscriptFields(
  defaults: Partial<TranscriptFields> = {},
  formBuilder: UntypedFormBuilder,
  isPaperCopiesEnabled: boolean,
): UntypedFormGroup {
  const formGroup = formBuilder.group({
    turnaround: [defaults.turnaround, Validators.required],
  })
  if (isPaperCopiesEnabled || (defaults.paperCopies ?? 0) > 0) {
    formGroup.addControl('paperCopies', formBuilder.control(defaults.paperCopies))
  }
  return formGroup
}

@Component({
  selector: 'ftr-transcript-fields',
  templateUrl: './transcript-fields.component.html',
  styleUrls: ['./transcript-fields.component.css'],
})
export class TranscriptFieldsComponent implements OnChanges {
  @Input() transcriptFieldsFormGroup: UntypedFormGroup
  @Input() transcriptTurnarounds: Turnaround[]
  @Input() paperCopiesControl: AbstractControl

  // ftr-slider only supports integers but the form group holds the turnaround object
  turnaroundControl = new UntypedFormControl()

  availableTurnarounds: Turnaround[]
  turnaroundOptions: Observable<SelectItem<number>[]>

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.transcriptTurnarounds) {
      const currentTurnaround = this.transcriptFieldsFormGroup.controls.turnaround.value || undefined
      this.availableTurnarounds = buildAvailableTurnarounds(this.transcriptTurnarounds, currentTurnaround)
      this.turnaroundControl.setValue(getTurnaroundIndex(this.availableTurnarounds, currentTurnaround))
      this.turnaroundOptions = of(buildTurnaroundSelectItems(this.availableTurnarounds))
    }
  }

  getData(): TranscriptFields {
    return {
      turnaround: this.getTurnaround(),
      paperCopies: Number(this.transcriptFieldsFormGroup.controls.paperCopies?.value) || undefined,
    }
  }

  private getTurnaround(): Turnaround {
    const turnaroundIndex = this.turnaroundControl.value
    return (
      getTurnaroundByIndex(this.availableTurnarounds, turnaroundIndex) ||
      this.transcriptFieldsFormGroup.get('turnaround')?.value
    )
  }
}
