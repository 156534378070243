import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { LoaderComponent } from '@ftr/foundation'
import { ExternalLoginComponent } from './external-login.component'

@NgModule({
  imports: [RouterModule, LoaderComponent],
  declarations: [ExternalLoginComponent],
  exports: [ExternalLoginComponent],
})
export class ExternalLoginModule {}
