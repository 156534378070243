import { ChangeDetectorRef, Component, Input } from '@angular/core'
import { Subject } from 'rxjs'
import { ComposableDropdownRenderMode } from './ComposableDropdownRenderMode'

@Component({
  selector: 'ftr-composable-dropdown-body',
  templateUrl: './composable-dropdown-body.component.html',
  styleUrls: ['./composable-dropdown-body.component.css'],
})
export class ComposableDropdownBodyComponent {
  @Input() shouldShowDropDown = new Subject<boolean>()

  renderMode: ComposableDropdownRenderMode = 'drop-down'

  constructor(protected cdr: ChangeDetectorRef) {}

  setRenderMode(value: ComposableDropdownRenderMode): void {
    this.renderMode = value
    this.cdr.detectChanges()
  }
}
