import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { AppPaths } from '@ftr/routing-paths'
import { HelpModule } from '@ftr/ui-help'
import { HelpBreadrumbResolver } from '~app/pages/help/help-breadrumb.resolver'
import { LoggedInResolver } from '~app/pages/help/is-logged-in.resolver'
import { HelpPage } from './help.page'

@NgModule({
  declarations: [HelpPage],
  providers: [HelpBreadrumbResolver, LoggedInResolver],
  imports: [
    CommonModule,
    HelpModule,
    RouterModule.forChild([
      {
        path: AppPaths.Help,
        data: {
          title: 'Help & Support',
        },
        resolve: {
          breadcrumb: HelpBreadrumbResolver,
          isLoggedIn: LoggedInResolver,
        },
        component: HelpPage,
      },
    ]),
  ],
})
export class HelpPageModule {}
