<span class="help-link">
  <a class="help-link__link" href="https://fortherecord.statuspage.io/" target="_blank">System Status</a>
</span>
<span class="help-link">
  <a class="help-link__link" routerLink="/help">Help & Support</a>
</span>
<span class="help-link">
  <a class="help-link__link" href="https://trust.fortherecord.com/" target="_blank">Trust Center</a>
</span>
@if (showDesktopDownloadLink) {
  <span class="help-link">
    <a class="help-link__link" [href]="downloadAppUrl" target="_blank">Get Desktop App</a>
  </span>
}
