import { Injectable } from '@angular/core'
import { CourtSystem } from '@ftr/contracts/api/court-system'
import { Uuid } from '@ftr/contracts/type/shared'
import { unwrapData } from '@ftr/foundation'
import { from, Observable, switchMap, tap } from 'rxjs'
import { GetCourtSystemService } from './get-court-system.service'

/**
 * Request a court-system by id if it does not yet exist in the cache
 */
@Injectable({
  providedIn: 'root',
})
export class CourtSystemRegionCache {
  private courtSystems: CourtSystem[] = []
  constructor(private readonly getCourtSystemService: GetCourtSystemService) {}

  getOrFetchCourtSystem(courtSystemId$: Observable<Uuid>): Observable<CourtSystem> {
    return courtSystemId$.pipe(
      switchMap(courtSystemId => {
        const courtInCache = this.courtSystems.find(court => court.id === courtSystemId)
        if (courtInCache) {
          return from([courtInCache])
        }

        return this.requestAndCache(courtSystemId)
      }),
    )
  }

  private requestAndCache(courtSystemId: Uuid): Observable<CourtSystem> {
    return this.getCourtSystemService.getCourtSystem(courtSystemId).pipe(
      unwrapData(),
      tap(courtSystem => this.courtSystems.push(courtSystem)),
    )
  }
}
