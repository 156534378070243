import { Injectable } from '@angular/core'
import { ApiClient, ApiClientFactory, NotFoundApiError, serializeHttpParams } from '@ftr/api-shared'
import { PageDetails, PagedResult } from '@ftr/contracts/api/core'
import {
  AddressLookupRequest,
  AddressSearchResponse,
  ConfigureLocationQuickDraftSttBody,
  ConfigureRealTimeHearingsBody,
  CreateLocation,
  ListLocationsByCourtSystemParameters,
  ListLocationsByCourtSystemRequest,
  ListLocationsByCourtSystemResponse,
  ListLocationsByStatus,
  ListLocationsQuery,
  Location,
  LocationOverviewParameters,
  LocationOverviewRequest,
  LocationOverviewResponse,
  LocationStatus,
  LocationSummary,
  OverviewValue,
  UpdateLocation,
} from '@ftr/contracts/api/location'
import { Recorder } from '@ftr/contracts/api/recorder'
import { IndexedRecording } from '@ftr/contracts/api/search'
import { Uuid } from '@ftr/contracts/type/shared'
import { ApiResult, failUndefinedData, mapData, recoverType, switchMapData, unwrapData } from '@ftr/foundation'
import { Observable } from 'rxjs'
import { ChildLocation, RootLocation } from '../types'

// TODO: Remove once contracts have been locked down
export interface DetailedLocationOverviewResponse {
  locationId: Location['id']
  audioOrderOverview: OverviewValue<{ totalItems: number }>
  realtimeOrderOverview: OverviewValue<{ totalItems: number }>
  transcriptOrderOverview: OverviewValue<{ totalItems: number }>
  recordings: OverviewValue<PagedResult<IndexedRecording>>
  goldRecorders: OverviewValue<PagedResult<Recorder>>
}

const PAGE_SIZE = 100

export type LocationWithParent = Location & Required<Pick<Location, 'parent' | 'parentId'>>

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  lastCreatedCourtHouse?: Location

  private readonly apiClient: ApiClient

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClient = apiClientFactory.build('location')
  }

  listByCourtSystem({
    courtSystemId,
  }: ListLocationsByCourtSystemParameters): ApiResult<ListLocationsByCourtSystemResponse> {
    return this.apiClient.get<ListLocationsByCourtSystemResponse>({
      path: '/list',
      params: serializeHttpParams(new ListLocationsByCourtSystemRequest(courtSystemId)),
    })
  }

  overviewByCourtSystem({ courtSystemId }: ListLocationsByCourtSystemParameters): ApiResult<LocationSummary[]> {
    return this.apiClient.get<LocationSummary[]>({
      path: '/overview',
      params: serializeHttpParams(new ListLocationsByCourtSystemRequest(courtSystemId)),
    })
  }

  /**
   * @param courtSystemId
   * @param isArchived Return locations based on their isArchived status
   * @param filterInaccessible Hide locations the user doesn't have access to when restricted permissions are enabled
   */
  getAllRootsForCourtSystem(
    courtSystemId: Uuid,
    isArchived = false,
    filterInaccessible = false,
  ): ApiResult<RootLocation[]> {
    const remote = this.apiClient.get<Location[]>({
      params: serializeHttpParams(
        new ListLocationsQuery(courtSystemId, isArchived, undefined, undefined, filterInaccessible),
      ),
    })
    return remote.pipe(mapData(response => response.map(mapRoot)))
  }

  /**
   * @param courtSystemId
   * @param page
   * @param isArchived Return locations based on their isArchived status
   * @param filterInaccessible Hide locations the user doesn't have access to when restricted permissions are enabled
   */
  getRootsForCourtSystem(
    courtSystemId: Uuid,
    page: number,
    isArchived?: boolean | undefined,
    filterInaccessible?: boolean | undefined,
  ): ApiResult<PagedResult<RootLocation>> {
    return this.apiClient
      .get<PagedResult<Location>>({
        params: serializeHttpParams(
          new ListLocationsQuery(
            courtSystemId,
            isArchived,
            new PageDetails(page, PAGE_SIZE),
            undefined,
            filterInaccessible,
          ),
        ),
      })
      .pipe(
        mapData(response => ({
          meta: response.meta,
          items: response.items.map(mapRoot),
        })),
      )
  }

  /**
   * @param courtSystemId
   * @param page
   * @param status Return locations based on their status
   */
  getRootsForCourtSystemByStatus(
    courtSystemId: Uuid,
    page: number,
    status?: LocationStatus | undefined,
  ): ApiResult<PagedResult<RootLocation>> {
    return this.apiClient
      .get<PagedResult<Location>>({
        params: serializeHttpParams(
          new ListLocationsByStatus(courtSystemId, new PageDetails(page, PAGE_SIZE), status, undefined),
        ),
        path: '/listByStatus',
      })
      .pipe(
        mapData(response => ({
          meta: response.meta,
          items: response.items.map(mapRoot),
        })),
      )
  }

  /**
   * Like get, except it populates parent information
   */
  getCourtroom(id: Uuid): ApiResult<LocationWithParent | undefined> {
    return this.get(id).pipe(
      switchMapData(courtroom => {
        if (!courtroom) {
          return ApiResult.success(undefined)
        }
        if (!courtroom?.parentId) {
          return ApiResult.failure(new Error(`${id} is not a courtroom`))
        }
        return this.get(courtroom.parentId).pipe(
          mapData(courthouse => {
            courtroom.parent = courthouse
            return courtroom as LocationWithParent
          }),
        )
      }),
    )
  }

  getCourtroomOrError(id: Uuid): ApiResult<LocationWithParent> {
    return this.getCourtroom(id).pipe(failUndefinedData(() => new Error(`Courtroom not found: ${id}`)))
  }

  get(id: Uuid): ApiResult<Location | undefined> {
    const remote = this.apiClient.get<Location>({ path: id })
    return remote.pipe(recoverType(NotFoundApiError, undefined))
  }
  /**
   * @deprecated Use get and/or getCourtroom instead
   */
  getLocation(id: Uuid): Observable<Location> {
    const remote = this.apiClient.get<Location>({ path: id })
    return remote.pipe(unwrapData())
  }

  listByLocationId(
    id: Uuid,
    { courtSystemId }: ListLocationsByCourtSystemParameters,
  ): ApiResult<ListLocationsByCourtSystemResponse> {
    return this.apiClient.get<ListLocationsByCourtSystemResponse>({
      path: id + '/list',
      params: serializeHttpParams(new ListLocationsByCourtSystemRequest(courtSystemId)),
    })
  }

  overviewByLocationId(id: Uuid, { courtSystemId }: LocationOverviewParameters): ApiResult<LocationOverviewResponse> {
    return this.apiClient.get<LocationOverviewResponse>({
      path: id + '/overview',
      params: serializeHttpParams(new LocationOverviewRequest(courtSystemId)),
    })
  }

  detailedOverviewByLocationId(
    id: Uuid,
    { courtSystemId }: LocationOverviewParameters,
  ): ApiResult<DetailedLocationOverviewResponse> {
    return this.apiClient.get<DetailedLocationOverviewResponse>({
      path: id + '/detailed-overview',
      params: serializeHttpParams(new LocationOverviewRequest(courtSystemId)),
    })
  }

  createLocation(location: CreateLocation): ApiResult<Location> {
    return this.apiClient.post({ body: location })
  }

  updateLocation(id: string, location: UpdateLocation): ApiResult<Location> {
    return this.apiClient.put({ path: id, body: location })
  }

  configureLocationQuickDraftStt(id: string, configuration: ConfigureLocationQuickDraftSttBody): ApiResult<Location> {
    return this.apiClient.put({ path: `${id}/configureQuickDraftStt`, body: configuration })
  }

  configureRealTimeHearings(id: string, configuration: ConfigureRealTimeHearingsBody): ApiResult<Location> {
    return this.apiClient.put({ path: `${id}/configureRealTimeHearings`, body: configuration })
  }

  addressLookup(searchTerm: string, countryCodes: string, limit: number = 10): ApiResult<AddressSearchResponse> {
    return this.apiClient.get<AddressSearchResponse>({
      path: '/address/lookup',
      params: serializeHttpParams(new AddressLookupRequest(searchTerm, countryCodes, limit)),
    })
  }
}

function mapRoot(location: Location): RootLocation {
  return {
    ...location,
    parentId: undefined,
    parent: undefined,
    children: (location.children || []).map(child => mapChild(location as RootLocation, child)),
  }
}

function mapChild(root: RootLocation, child: Location): ChildLocation {
  return {
    ...child,
    parentId: root.id,
    parent: root,
    children: undefined,
  }
}
