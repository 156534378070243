<input
  [id]="id"
  class="input__control"
  [ngClass]="{
    'input__control--has-value': value,
    'input__control--disabled': disabled,
    'input__control--title-bold': isTitleBold,
  }"
  type="checkbox"
  [disabled]="disabled"
  [attr.disabled]="disabled ? true : null"
  [attr.name]="name"
  [attr.aria-labelledby]="labelName"
  [ngModel]="getBooleanValue()"
  (ngModelChange)="onChange($event)"
/>
<label
  class="input__label"
  [ngClass]="{ indeterminate: value === 'indeterminate' ? true : null }"
  [id]="id + 'Label'"
  [for]="id"
>
  @if (icon) {
    <ftr-icon [icon]="icon" gapRight="Small" align="Sub"></ftr-icon>
  }
  {{ name }}
</label>
<span class="input__summary">{{ summary }}</span>
