import { Component } from '@angular/core'
import { Illustration } from '@ftr/foundation'

@Component({
  selector: 'ftr-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css'],
})
export class NotFoundComponent {
  readonly illustration = Illustration
}
