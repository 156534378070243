import { CommonModule } from '@angular/common'
import { Component, Input, TemplateRef, ViewEncapsulation } from '@angular/core'
import { Failure, RemoteData, Success } from '../remote-data'
import { EmptyFunction } from './EmptyFunction'

/**
 * A component to display content based on remote data. Uses templates for the different states remote data
 * can be in.
 */
@Component({
  selector: 'ftr-remote',
  templateUrl: './remote.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule],
})
export class RemoteComponent<TData, TError> {
  @Input() notAskedTemplate: TemplateRef<unknown> | null = null
  @Input() loadingTemplate: TemplateRef<unknown>
  @Input() failureTemplate: TemplateRef<unknown>
  @Input() successTemplate: TemplateRef<unknown>
  @Input() emptyTemplate: TemplateRef<unknown>
  @Input() remote: RemoteData<TData, TError>
  @Input() isEmpty: EmptyFunction<TData> = isEmpty

  asSuccess(remote: RemoteData<TData, TError>): Success<TData, TError> {
    if (remote.isSuccess()) {
      return remote
    }
    throw new Error('Remote not success')
  }

  asFailure(remote: RemoteData<TData, TError>): Failure<TData, TError> {
    if (remote.isFailure()) {
      return remote
    }
    throw new Error('Remote not failure')
  }
}

export function isEmpty<TData>(value: TData | object | object[] | string | number | boolean | Map<any, any>): boolean {
  if (typeof value === 'number' || typeof value === 'string' || typeof value === 'boolean') {
    return false
  } else if (value instanceof Map) {
    return value.size === 0
  } else {
    return (
      value === undefined ||
      value === null ||
      Object.keys(value).length === 0 ||
      (Array.isArray(value) && value.length === 0)
    )
  }
}
