import { Injectable } from '@angular/core'

export interface PopoverState {
  open(event?: MouseEvent | FocusEvent): void

  close(event?: MouseEvent | FocusEvent): void
}

/**
 * Keeps track of the state of the currently active popover.
 */
@Injectable({
  providedIn: 'root',
})
export class ActivePopoverStateService {
  activePopover: PopoverState | undefined

  activate(activatingComponent: PopoverState): void {
    if (activatingComponent !== this.activePopover) {
      this.deactivate()
    }
    this.activePopover = activatingComponent
  }

  deactivate(): void {
    this.activePopover = undefined
  }
}
