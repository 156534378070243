import { Pipe, PipeTransform } from '@angular/core'
import { Card } from '@ftr/contracts/read'
import { CardBrand } from '@ftr/contracts/type/order/CardDetails'

/**
 * @source http://www.dirigodev.com/blog/ecommerce/anatomy-of-a-credit-card-number/
 */
const AMEX_LENGTH = 15
const DINERS_LENGTH = 14
const STANDARD_LENGTH = 16

const AMEX_MASK = /(.{4})(.{6})(.{5})/g
const DINERS_MASK = /(.{4})(.{6})(.{4})/g
const STANDARD_MASK = /(.{4})/g

@Pipe({ name: 'ftrCreditCardMask', standalone: true })
export class CreditCardMaskPipe implements PipeTransform {
  transform(card: Card): string {
    if (!card.last4Digits) {
      return ''
    }
    switch (card.brand) {
      case CardBrand.AmericanExpress:
        return card.last4Digits.padStart(AMEX_LENGTH, '•').replace(AMEX_MASK, '$1 $2 $3')
      case CardBrand.DinersClub:
        return card.last4Digits.padStart(DINERS_LENGTH, '•').replace(DINERS_MASK, '$1 $2 $3')
      default:
        return card.last4Digits.padStart(STANDARD_LENGTH, '•').replace(STANDARD_MASK, '$1 ').trim()
    }
  }
}
