import { Component, OnInit, ViewChild } from '@angular/core'
import { ToastContainerDirective } from 'ngx-toastr'
import { ToastService } from './toast.service'

@Component({
  selector: 'ftr-toast-container',
  template: '<div toastContainer></div>',
})
export class ToastContainerComponent implements OnInit {
  @ViewChild(ToastContainerDirective, { static: true }) toastContainer: ToastContainerDirective

  constructor(private readonly toastService: ToastService) {}

  ngOnInit(): void {
    this.toastService.container = this.toastContainer
  }
}
