import { ErrorHandler, Injectable } from '@angular/core'
import { SimpleWindowRefService } from '@ftr/foundation'
import { LoggingService } from './logging.service'

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService implements ErrorHandler {
  constructor(
    private loggingService: LoggingService,
    private windowRef: SimpleWindowRefService,
  ) {
    this.captureErrors()
  }

  handleError(error: any): void {
    console.error('Intercepted error:', error)

    this.loggingService.error({
      message: 'Unhandled error',
      source: 'ErrorHandlerService.handleError',
      error,
    })
  }

  private captureErrors(): void {
    this.windowRef.nativeWindow().onerror = (
      message: Event | string,
      url?: string,
      line?: number,
      column?: number,
      error?: Error,
    ) => {
      const errorContents = {
        message,
        source: 'window.onerror',
        file: url,
        line,
        column,
        error,
      }

      if (isErrorWithUsefulInformation(message)) {
        this.loggingService.error(errorContents)
      } else {
        this.loggingService.warn(errorContents)
      }
    }
  }
}

/**
 * Determines whether the given error message contains useful information.
 *
 * When a cross-origin script (e.g. an extension, Stripe, GA) errors, we do not have visibility into the
 * error that has been thrown. Instead we get an error object with 0 properties, or a single isTrusted=true
 * property when the error was thrown by an HTTPS script.
 *
 * If we come across one of these error objects with no properties, we consider it to not be useful. Otherwise
 * it is useful.
 */
function isErrorWithUsefulInformation(errorMessage: string | {}): boolean {
  return (
    (typeof errorMessage === 'string' && errorMessage.length > 0) ||
    Object.keys(errorMessage).filter(k => k !== 'isTrusted').length > 0
  )
}
