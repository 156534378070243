import { Injectable } from '@angular/core'
import { CourtroomRealTimeSttConfiguration } from '@ftr/contracts/regional-api/real-time-stt'
import { Uuid } from '@ftr/contracts/type/shared'
import { ApiResult, mapData } from '@ftr/foundation'
import { switchMap } from 'rxjs'
import { RegionalApiClientFactory, RegionalApiClientSetupService } from '../api-client'

interface GetConfigurationArgs {
  locationId: Uuid
  courtSystemId: Uuid
}
@Injectable({
  providedIn: 'root',
})
export class CourtroomRealTimeSttConfigurationService extends RegionalApiClientSetupService {
  override regionalApiPath = 'courtroom-real-time-stt-configuration'

  constructor(regionalApiClientFactory: RegionalApiClientFactory) {
    super(regionalApiClientFactory)
  }

  save(
    configuration: CourtroomRealTimeSttConfiguration,
    { courtSystemId, locationId }: GetConfigurationArgs,
  ): ApiResult<CourtroomRealTimeSttConfiguration> {
    return this.regionalApiClient(this.regionalApiPath, courtSystemId).pipe(
      switchMap(client =>
        client.put({
          headers: { courtSystemId },
          path: `/${locationId}`,
          body: configuration,
        }),
      ),
      mapData(() => configuration),
    )
  }

  get({ courtSystemId, locationId }: GetConfigurationArgs): ApiResult<CourtroomRealTimeSttConfiguration> {
    return this.regionalApiClient(this.regionalApiPath, courtSystemId).pipe(
      switchMap(client =>
        client.get<CourtroomRealTimeSttConfiguration>({
          headers: { courtSystemId },
          path: `/${locationId}`,
        }),
      ),
    )
  }
}
