import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApiResult, RemoteData } from '@ftr/foundation'
import { Observable, catchError, map, of, shareReplay, startWith } from 'rxjs'
import { HttpHeaders as ResponseHeaders } from './http-headers'
import { HttpResponseType } from './http-response-type'
import { WithResponseHeaders } from './with-response-headers'

/**
 * Wraps http-client calls in the RemoteData call to provide an easy way to find out whether the call is still running,
 * has failed or succeeded.
 */
@Injectable({
  providedIn: 'root',
})
export class HttpClientRemoteData {
  constructor(private readonly apiClient: HttpClient) {}

  public get<TReturn>(
    url: string,
    params?: HttpParams,
    headers?: HttpHeaders,
    withCredentials?: boolean,
    responseType?: HttpResponseType,
  ): ApiResult<TReturn, HttpErrorResponse> {
    return toRemoteData(
      this.apiClient.get<TReturn>(url, generateOptions(params, headers, withCredentials, responseType)),
    )
  }

  public getWithResponseHeaders<TReturn>(
    url: string,
    params?: HttpParams,
    headers?: HttpHeaders,
    withCredentials?: boolean,
    responseType?: HttpResponseType,
  ): ApiResult<WithResponseHeaders<TReturn>, HttpErrorResponse> {
    return toRemoteData(
      this.apiClient.get<TReturn>(url, generateOptions(params, headers, withCredentials, responseType)),
      res => ({ data: res.body!, headers: toHttpHeaders(res.headers) }),
    )
  }

  public post<TReturn>(
    url: string,
    body: any,
    params?: HttpParams,
    headers?: HttpHeaders,
    withCredentials?: boolean,
    responseType?: HttpResponseType,
  ): ApiResult<TReturn, HttpErrorResponse> {
    return toRemoteData(
      this.apiClient.post<TReturn>(url, body, generateOptions(params, headers, withCredentials, responseType)),
    )
  }

  public put<TReturn>(
    url: string,
    body: any,
    params?: HttpParams,
    headers?: HttpHeaders,
    withCredentials?: boolean,
    responseType?: HttpResponseType,
  ): ApiResult<TReturn, HttpErrorResponse> {
    return toRemoteData(
      this.apiClient.put<TReturn>(url, body, generateOptions(params, headers, withCredentials, responseType)),
    )
  }

  public patch<TReturn>(
    url: string,
    body: any,
    params?: HttpParams,
    headers?: HttpHeaders,
    withCredentials?: boolean,
    responseType?: HttpResponseType,
  ): ApiResult<TReturn, HttpErrorResponse> {
    return toRemoteData(
      this.apiClient.patch<TReturn>(url, body, generateOptions(params, headers, withCredentials, responseType)),
    )
  }

  public delete<TReturn>(
    url: string,
    body: any = null,
    params?: HttpParams,
    headers?: HttpHeaders,
    withCredentials?: boolean,
    responseType?: HttpResponseType,
  ): ApiResult<TReturn, HttpErrorResponse> {
    return toRemoteData(
      this.apiClient.delete<TReturn>(url, generateOptions(params, headers, withCredentials, responseType, body)),
    )
  }
}

function toRemoteData<TReturn>(
  response: Observable<HttpEvent<any>>,
  onSuccess: (res: HttpResponse<any>) => TReturn = res => res.body!,
): ApiResult<TReturn, HttpErrorResponse> {
  return response.pipe(
    map((res: HttpEvent<TReturn>) => {
      if (res.type === HttpEventType.Response) {
        return RemoteData.success(onSuccess(res))
      } else {
        return RemoteData.loading()
      }
    }),
    catchError((errorResponse: HttpErrorResponse) => {
      return of(RemoteData.failure(errorResponse))
    }),
    startWith(RemoteData.loading()),
    shareReplay(1),
  )
}

function generateOptions(
  params?: HttpParams,
  headers?: HttpHeaders,
  withCredentials = false,
  responseType: HttpResponseType = HttpResponseType.Json,
  body: any = null,
): any {
  return {
    headers,
    observe: 'response',
    params,
    reportProgress: false,
    responseType,
    withCredentials,
    body,
  }
}

function toHttpHeaders(angularHeaders: HttpHeaders): ResponseHeaders {
  return angularHeaders.keys().reduce((record, headerKey) => {
    const headerValues = angularHeaders.getAll(headerKey)
    if (!headerValues) {
      return record
    } else {
      return { ...record, [headerKey]: headerValues.length > 1 ? headerValues : headerValues[0] }
    }
  }, {})
}
