@if (readonly()) {
  <span
    [ngClass]="{
      'readonly-value': true,
      large: size() === 'large',
      'extra-large': size() === 'extra-large',
      error: hasError(),
      multiline: multiline(),
      'line-clamp': maxLines() !== undefined,
      uppercase: upperCase() && displayedText(),
    }"
    [ngStyle]="maxLines() !== undefined ? { '-webkit-line-clamp': maxLines() } : {}"
    >{{ displayedText() || placeholder() }}</span
  >
} @else {
  @if (multiline()) {
    <textarea
      [ngClass]="{
        large: size() === 'large',
        'extra-large': size() === 'extra-large',
        error: hasError(),
        uppercase: upperCase() && displayedText(),
      }"
      type="text"
      [required]="required()"
      [attr.maxlength]="maxLength()"
      [placeholder]="placeholder()"
      [value]="displayedText()"
      (input)="onInput($event)"
      (change)="onChange($event)"
      [attr.aria-label]="label()"
      (focus)="handleFocus($event)"
      (blur)="handleBlur($event)"
      (keydown)="handleKeyDown($event)"
      rows="1"
      cols="1"
      #inputReference
    ></textarea>
  } @else {
    <input
      [ngClass]="{
        large: size() === 'large',
        'extra-large': size() === 'extra-large',
        error: hasError(),
        uppercase: upperCase() && displayedText(),
      }"
      type="text"
      [required]="required()"
      [attr.maxlength]="maxLength()"
      [placeholder]="placeholder()"
      [value]="displayedText()"
      (input)="onInput($event)"
      (change)="onChange($event)"
      [attr.aria-label]="label()"
      (focus)="handleFocus($event)"
      (blur)="handleBlur($event)"
      (keydown)="handleKeyDown($event)"
      size="1"
      #inputReference
    />
    <!-- There is a size 1^ here to ensure the input can shrink below the default browser min size -->
  }
  <span
    [ngClass]="{
      'input-expander': true,
      large: size() === 'large',
      'extra-large': size() === 'extra-large',
      multiline: multiline(),
      'line-clamp': maxLines() !== undefined,
      empty: !displayedText(),
      error: hasError(),
      uppercase: upperCase() && displayedText(),
    }"
    [ngStyle]="maxLines() !== undefined ? { '-webkit-line-clamp': maxLines() } : {}"
    aria-hidden="true"
    >{{ widthExpanderText() }}</span
  >
  @if (hasError()) {
    <ftr-icon [icon]="'Warning'" [align]="'Top'" [size]="'Regular'"></ftr-icon>
  }
}
