import { LocalDate, LocalTime } from '@js-joda/core'
import moment from 'moment-timezone'
import { TimeStringCannotBeParsedError } from './TimeStringCannotBeParsedError'

/**
 * Regexp patterns to use to normalize a range of time inputs to something that looks like "HHMMSS".
 */
const NORMALIZE_FORMAT_PATTERNS: { pattern: RegExp; replacement: string }[] = [
  { pattern: /^(\d+)$/, replacement: '$1' },
  { pattern: /^:(\d)$/, replacement: '$10' },
  { pattern: /^:(\d+)/, replacement: '$1' },
  { pattern: /^(\d):([[0-5])$/, replacement: '0$1$20' },
  { pattern: /^(\d):(\d)$/, replacement: '0$10$2' },
  { pattern: /^(\d):(\d\d)$/, replacement: '$1$2' },
  { pattern: /^(\d\d):([7-9])$/, replacement: '$10$2' },
  { pattern: /^(\d\d):(\d)$/, replacement: '$1$20' },
  { pattern: /^(\d\d):(\d*)$/, replacement: '$1$2' },
  { pattern: /^(\d{3,}):(\d)$/, replacement: '$10$2' },
  { pattern: /^(\d{3,}):(\d{2,})/, replacement: '$1$2' },
  { pattern: /^(\d):(\d):(\d)$/, replacement: '0$10$20$3' },
  { pattern: /^(\d{1,2}):(\d):(\d\d)/, replacement: '$10$2$3' },
]

/**
 * Parses an arbitrary time-like string to a moment.
 *
 * Based off the jQuery TimePicker: https://timepicker.co/
 */
export class TimeStringParser {
  /**
   * Parse the given string to a LocalTime instance.
   * @param input
   */
  static parse(input: string, parserFns?: TimeStringParseMethod[]): LocalTime {
    const parser = new TimeStringParser()
    return parser.parse(input, parserFns)
  }

  /**
   * Parse the given string to a Moment instance.
   * @deprecated Please use parse instead
   * @param input
   */
  static parseMoment(input: string, parseFns?: TimeStringParseMethod[]): moment.Moment {
    const parser = new TimeStringParser()
    return moment(parser.parse(input, parseFns).atDate(LocalDate.now()).toString())
  }

  /**
   * Parse the given string to a LocalTime instance.
   * @param input
   */
  parse(input: string, parseFns: TimeStringParseMethod[] = [DefaultTimeStringParseMethod]): LocalTime {
    const formatted = parseFns.reduce<LocalTime | undefined>((acc, cur) => (acc ? acc : cur(input)), undefined)

    if (formatted === undefined) {
      throw new TimeStringCannotBeParsedError(input)
    }

    return formatted
  }
}

export type TimeStringParseMethod = (input: string) => LocalTime | undefined

export const DurationTimeStringParseMethod = (sourceInput: string): LocalTime | undefined => {
  try {
    const input = sourceInput.toLowerCase()
    if (stripColons(normalizeFormat(dedupeColons(stripAlpha(input.toLowerCase())))) === '') {
      return undefined
    }

    let hours: number | undefined
    let minutes: number | undefined

    if (input.includes(':')) {
      hours = parseInt(input.split(':')[0].padStart(2, '0').slice(0, 2), 10)
      minutes = parseInt(input.split(':')[1].padStart(2, '0').slice(0, 2), 10)
    } else {
      hours = parseInt(normalizeFormat(input).padStart(4, '0').slice(0, 2), 10)
      minutes = parseInt(normalizeFormat(input).padStart(4, '0').slice(2, 4), 10)
    }

    if (!(Number.isNaN(hours) && Number.isNaN(minutes))) {
      return LocalTime.of(Number.isNaN(hours) ? 0 : hours === 24 ? 0 : hours, minutes)
    }

    // IDK what they've entered, but it wasn't a duration
    //  so kick it down the line
    return undefined
  } catch {
    return undefined
  }
}

export const DefaultTimeStringParseMethod = (sourceInput: string): LocalTime | undefined => {
  const period = determinePeriod(sourceInput)
  const { hours, minutes, seconds } = determineTime(sourceInput, period)

  if (hours === undefined || minutes === undefined || seconds === undefined) {
    return undefined
  }

  return LocalTime.of(hours, minutes, seconds)
}

function determinePeriod(input: string): string | undefined {
  input = input.toLowerCase()
  if (/a/.test(input)) {
    return 'am'
  } else if (/p/.test(input)) {
    return 'pm'
  }

  return
}

function determineTime(
  input: string,
  period: string | undefined,
): { hours: number | undefined; minutes: number | undefined; seconds: number | undefined } {
  let hours: number | undefined = undefined
  let minutes: number | undefined = undefined
  let seconds: number | undefined = undefined
  const originalInput = input
  input = stripColons(normalizeFormat(dedupeColons(stripAlpha(input.toLowerCase()))))

  const p = (num: string): number => parseInt(num, 10)

  if (input.length === 1 || input.length === 2) {
    hours = p(input)
  } else if (input.length === 3 || input.length === 5) {
    hours = p(input.substr(0, 1))
    minutes = p(input.substr(1, 2))
    seconds = p(input.substr(3, 2))
  } else if (input.length === 4 || input.length > 5) {
    hours = p(input.substr(0, 2))
    minutes = p(input.substr(2, 2))
    seconds = p(input.substr(4, 2))
  }

  if (input.length > 0 && input.length < 5) {
    if (input.length < 3) {
      minutes = 0
    }
    seconds = 0
  }

  if (period === 'am' && hours === 12) {
    hours = 0
  } else if (period === 'pm' && hours && hours < 12) {
    hours = hours + 12
  }

  // joda wants hours to be 0 - 23
  if (hours === 24) {
    hours = 0
  }

  if ((hours && hours > 23) || (minutes && minutes > 59)) {
    throw new TimeStringCannotBeParsedError(originalInput)
  }

  return {
    hours,
    minutes,
    seconds,
  }
}

function stripAlpha(str: string): string {
  return str.replace(/[^0-9:]/g, '')
}

function dedupeColons(str: string): string {
  return str.replace(/:+/g, ':')
}

function stripColons(str: string): string {
  return str.replace(/:/g, '')
}

function normalizeFormat(str: string): string {
  for (const pattern of NORMALIZE_FORMAT_PATTERNS) {
    if (!pattern.pattern.test(str)) {
      continue
    }
    return str.replace(pattern.pattern, pattern.replacement)
  }
  return str
}
