import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { IllustrationComponent, PageComponent } from '@ftr/foundation'
import { NotFoundComponent } from './not-found.component'

@NgModule({
  imports: [RouterModule, IllustrationComponent, PageComponent],
  declarations: [NotFoundComponent],
  exports: [NotFoundComponent],
})
export class NotFoundModule {}
