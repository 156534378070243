export interface IdentityProviderConfiguration {
  /**
   * The id of the app configured in the authentication/identity provider
   */
  clientId: string
  /**
   * The url to redirect users to login via external identity providers
   */
  authorizeEndpoint: string
  /**
   * The endpoint used to exchange the authorization code for tokens
   */
  tokenEndpoint: string
}

export interface DiscoveryResponse {
  authorization_endpoint: string
  token_endpoint: string
}

export type IdentityProviderConfigurationCache = Record<string, IdentityProviderConfiguration>

/**
 * The length of the state param (random generated string).
 * This value is arbitrary as the documentation doesn't specify a max length.
 */
export const STATE_PARAM_LENGTH = 16

/**
 * The length of the generated code verifier needs to be between 43 and 128 chars.
 * https://datatracker.ietf.org/doc/html/rfc7636
 */
export const CODE_VERIFIER_PARAM_LENGTH = 43

export const ID_TOKEN_KEY = 'oauth:id_token'
export const REFRESH_TOKEN_KEY = 'oauth:refresh_token'
export const ID_TOKEN_EXPIRES_AT_KEY = 'oauth:id_token_expires_at'
export const ACCESS_TOKEN_AMR_CLAIM_KEY = 'oauth:access_token_amr_claim'
export const ACCESS_TOKEN_AMR_CLAIM_HASH_KEY = 'oauth:access_token_amr_claim_hash'
