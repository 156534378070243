export type BuilderCallback<TResult> = (entity: TResult) => Partial<TResult>

/**
 * Base implementation for a generic builder pattern. For classes, ensure constructor is called with an instance of the
 * class, not an anonymous type object. e.g.
 *
 * <pre>
 *   super(Object.assign(new MyClass(), { ... }))
 * </pre>
 */
export abstract class AbstractBuilder<TResult extends {}> {
  protected constructor(protected readonly result: TResult) {}

  with(valueObjectOrCallback: Partial<TResult> | BuilderCallback<TResult>): this {
    const mutation =
      typeof valueObjectOrCallback === 'function' ? valueObjectOrCallback(this.result) : valueObjectOrCallback
    Object.assign(this.result, mutation)
    return this
  }

  build(): TResult {
    return this.result
  }
}
