import { CourtSystemWithFeaturesResult, CourtSystemWithMetadata } from '@ftr/contracts/api/court-system'
import { LicensingTier } from '@ftr/contracts/type/core'
import { Region } from '@ftr/contracts/type/court'
import { ProductionStatus } from '@ftr/contracts/type/court/ProductionStatus'
import { LineItemType } from '@ftr/contracts/type/order'
import { Uuid } from '@ftr/contracts/type/shared'

export interface CourtSystemSelectorItem {
  id: Uuid
  name: string
  code: string
  region: Region
  productionStatus: ProductionStatus
  isPublished: boolean
  licensingTier: LicensingTier
  countryCode?: string
  stateCode?: string
  logoUrl?: string
  externalUrl?: string
  isDepartmentsEnabled?: boolean
}

export function mapCourtSystemWithMetadataToCourtSystemSelectorItem(
  courtSystem: CourtSystemWithMetadata,
): CourtSystemSelectorItem {
  return {
    ...courtSystem,
  }
}

export function mapCourtSystemWithFeaturesResultToCourtSystemSelectorItem(
  courtSystem: CourtSystemWithFeaturesResult,
  lineItemType: LineItemType,
): CourtSystemSelectorItem {
  return {
    ...courtSystem,
    externalUrl: getExternalUrl(courtSystem, lineItemType),
    id: courtSystem.courtSystemId,
  }
}

function getExternalUrl(courtSystem: CourtSystemWithFeaturesResult, lineItemType: LineItemType): string | undefined {
  // This only applies to transcript orders and court systems with disabled transcript ordering
  if (lineItemType !== LineItemType.CertifiedTranscript || courtSystem.transcriptOrderingFeature?.isEnabled) {
    return
  }
  return courtSystem.transcriptOrderingFeature?.externalUrl
}
