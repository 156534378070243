import { animate, state, style, transition, trigger } from '@angular/animations'

import { Component, Input } from '@angular/core'

@Component({
  standalone: true,
  selector: 'ftr-line-loader',
  templateUrl: './line-loader.component.html',
  styleUrls: ['./line-loader.component.css'],
  animations: [
    trigger('grow', [
      state('in', style({ transform: 'scale(1)' })),
      transition(':enter', [style({ transform: 'scale(1, .5)' }), animate('300ms')]),
      transition(':leave', [animate('300ms', style({ transform: 'scale(1, .5)' }))]),
    ]),
  ],
  imports: [],
})
export class LineLoaderComponent {
  @Input() isLoading = true
  /**
   * Setting fullPage make the loader suitable for being centre stage on a page.
   * Uses absolute positioning and more padding.
   */
  @Input() fullPage = false
  /**
   * Collapses the margins so the loader takes up less space and makes it
   * suitable for using without text by making it the same width as it's container.
   */
  @Input() collapse = false
  /**
   * Makes the loader suitable for use on the bottom of a rectangular element.
   */
  @Input() fitToBottom = false
  /**
   * Makes the loader suitable for use on the bottom of the button.
   */
  @Input() fitToButtonBottom = false
  @Input() message = 'Loading...'
}
