<div
  [@smoothEnter]
  class="notification"
  [ngClass]="'notification--' + type"
  [class.notification--compact]="compact"
  [class.notification--toast]="toast"
>
  <ftr-icon class="notification__icon" [icon]="getIcon()" align="Middle"></ftr-icon>
  @if (markdown) {
    <span markdown class="notification__message">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </span>
  } @else {
    <span class="notification__message">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </span>
  }
</div>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
