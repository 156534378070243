import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

// fallback --spacer variable
// MUST BE EXACTLY THE SAME AS 1REM
export const VERTICAL_SPACER_PX = 16
export const LINE_HEIGHT = 35

export const DUMMY_SIZES_SERVICE = {
  headerIsRendered: new Subject<boolean>(),
  getCurrentPlaybackHeaderSizePx: () => 0,
  getCurrentHeaderSizeWithOffsetPx: () => 0,
  isElementAtLeastPartiallyInViewportUnderHeader: () => false,
}

@Injectable()
export abstract class SizesService {
  abstract readonly headerIsRendered: Subject<boolean>
  abstract getCurrentPlaybackHeaderSizePx(): number | undefined
  abstract getCurrentHeaderSizeWithOffsetPx(): number | undefined
  abstract isElementAtLeastPartiallyInViewportUnderHeader(el: HTMLElement): boolean
}
