import { Injectable } from '@angular/core'
import { ApiClient, ApiClientFactory } from '@ftr/api-shared'
import { UpdateVocabularyBody } from '@ftr/contracts/api/vocabulary'
import { VocabularyTerms } from '@ftr/contracts/type/core'
import { Uuid } from '@ftr/contracts/type/shared'
import { ApiResult } from '@ftr/foundation'
import { classToPlain } from '@ftr/serialization'

@Injectable({
  providedIn: 'root',
})
export class VocabularyService {
  private readonly apiClient: ApiClient

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClient = apiClientFactory.build('vocabulary')
  }

  getCourtSystemTerms(courtSystemId: Uuid): ApiResult<VocabularyTerms> {
    return this.apiClient.get<VocabularyTerms>({
      path: `getTermsForCourtSystem?courtSystemId=${courtSystemId}`,
    })
  }

  update(courtSystemId: Uuid, terms: VocabularyTerms): ApiResult<VocabularyTerms, Error> {
    return this.apiClient.put<VocabularyTerms>({
      body: classToPlain(new UpdateVocabularyBody(courtSystemId, terms)),
    })
  }
}
