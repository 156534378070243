<div class="footer-disclaimer">
  <div class="footer-disclaimer__row row align-items-center">
    <span> © Copyright {{ currentYear }}. All Rights Reserved&nbsp;|&nbsp;</span>
    <span class="footer-disclaimer__link-wrapper">
      <a
        data-track
        class="footer-disclaimer__link"
        href="https://www.fortherecord.com/terms-and-conditions/"
        target="_blank"
        >Terms & Conditions</a
      >&nbsp;|
      <a data-track class="footer-disclaimer__link" href="https://www.fortherecord.com/privacy-policy/" target="_blank"
        >Privacy Policy</a
      >
    </span>
  </div>
</div>
