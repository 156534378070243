import { Observable, filter, fromEvent } from 'rxjs'
import { TrackingEvent } from '../tracking/tracking'

export interface ExtraSimpleWindowProps {
  dataLayer: TrackingEvent[]
  document: SimpleDocument
  URL: typeof URL
}

export type SimpleWindowRef = Window & ExtraSimpleWindowProps

export interface ExtraDocumentProps {
  webkitHidden: boolean
  msHidden: boolean
  mozHidden: boolean
}

export type SimpleDocument = Document & ExtraDocumentProps

type OS = 'Windows' | 'Mac' | 'Unix' | 'Linux' | 'Unknown'

/**
 * Window references in angular are generally undesirable so
 * this class has been set up to wrap it so at least
 * mocks can be injected in place of this during testing
 */
export class SimpleWindowRefService {
  isOnline(): boolean {
    return this.nativeWindow().navigator.onLine
  }

  protectWindowClose(): void {
    this.nativeWindow().onbeforeunload = () => true
  }

  clearTimeout(handle?: number): void {
    this.nativeWindow().clearTimeout(handle)
  }

  unprotectWindowClose(): void {
    this.nativeWindow().onbeforeunload = null
  }

  getComputedStyle(elem: HTMLElement): any {
    return this.nativeWindow().getComputedStyle(elem)
  }

  querySelector(selector: string): Element | null {
    return this.document().querySelector(selector)
  }

  querySelectorAll<T extends Element>(selector: string): NodeListOf<T> {
    return this.document().querySelectorAll(selector)
  }

  getElementScrollPosition(selector: string): number {
    const element = this.querySelector(selector)
    return element?.scrollTop || 0
  }

  scrollElementBy(selector: string, distance: number | undefined): void {
    const element = this.querySelector(selector)
    element?.scroll({ left: 0, top: distance || 0, behavior: 'smooth' })
  }

  /**
   * Note: On IE, smooth scroll is always disabled
   */
  scrollTo(top: number = 0, left: number = 0, smooth?: boolean): void {
    this.nativeWindow().scrollTo({
      top,
      left,
      behavior: smooth ? 'smooth' : 'auto',
    })
  }

  getDevicePixelRatio(): number {
    return this.nativeWindow().devicePixelRatio || 1
  }

  nativeWindow(): SimpleWindowRef {
    return window as unknown as SimpleWindowRef
  }

  confirm(message: string): boolean {
    return this.nativeWindow().confirm(message)
  }

  /**
   * Cross-browser friendly way of getting the scroll position on window
   */
  scrollY(): number {
    return this.nativeWindow().scrollY
  }

  scrollVertically(to: number): void {
    this.nativeWindow().scrollTo(0, to)
  }

  dataLayer(): TrackingEvent[] {
    return this.nativeWindow().dataLayer
  }

  location(): Location {
    return this.nativeWindow().location
  }
  referrer(): string {
    return this.document().referrer
  }
  document(): SimpleDocument {
    return this.nativeWindow().document
  }

  isDevelop(): boolean {
    return this.location().href.includes('develop')
  }

  setLocationHref(href: string): void {
    this.location().href = href
  }

  dispatchEvent(event: Event): boolean {
    return this.document().dispatchEvent(event)
  }

  executeCopy(): boolean {
    return this.document().execCommand('copy')
  }

  history(): History {
    return this.nativeWindow().history
  }

  pageIsVisible(): boolean {
    const document = this.document()
    return document.hidden === false || document.webkitHidden === false || document.mozHidden === false
  }

  onPageVisibility(onShow?: Function, onHide?: Function): void {
    const document = this.document()
    const { visibilityChange, hidden } = this.getVisibilityHiddenKeys()
    if (visibilityChange) {
      const handler = (): void => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore Typescript doesn't like this way of accessing Document.
        if (!document[hidden!]) {
          if (typeof onShow === 'function') {
            onShow()
          }
        } else {
          if (typeof onHide === 'function') {
            onHide()
          }
        }
      }
      document.addEventListener(visibilityChange, handler, false)
    }
  }

  /**
   * When the scroll bar is dragged. The event source is the root node of the document
   */
  observeScrollbarDrag(): Observable<Event> {
    return fromEvent(this.nativeWindow(), 'mousedown').pipe(filter(e => e.srcElement === this.document().children[0]))
  }

  open(url: string, target?: string): void {
    this.nativeWindow().open(url, target)
  }

  /**
   * Get the highlighted selection
   * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/getSelection
   */
  getSelection(): Selection | null {
    return this.nativeWindow().getSelection()
  }

  matchMedia(mediaQuery: string): MediaQueryList {
    return this.nativeWindow().matchMedia(mediaQuery)
  }

  getHostName(): string {
    const loc = this.nativeWindow().location
    return `${loc.protocol}//${loc.host}`
  }

  /**
   * Check whether a HTML element is fully or partially visible within the viewport
   *
   * @param el The HTML element to check
   * @param topOffset Use this to check for example, if the top is below a certain point (for example, to see if
   * it's below the fixed header)
   * @param viewPortBuffer Number of viewports either side of the actual viewport to consider as 'in the visible
   * viewport'. Default is 0.
   */
  isElementInViewport(el: HTMLElement, topOffset = 0, viewPortBuffer = 0): boolean {
    const rect = el.getBoundingClientRect()
    return this.isAtLeastPartiallyInViewportVertically(rect, topOffset, viewPortBuffer)
  }

  getElementHeight(id: string): number | undefined {
    return this.document().getElementById(id)?.clientHeight
  }

  getViewportHeight(): number {
    return this.nativeWindow().innerHeight || this.document().documentElement.clientHeight
  }

  getViewportWidth(): number {
    return this.nativeWindow().innerWidth || this.document().documentElement.clientWidth
  }

  getOS(): OS {
    const { appVersion } = this.nativeWindow().navigator
    if (appVersion.includes('Win')) {
      return 'Windows'
    } else if (appVersion.includes('Mac')) {
      return 'Mac'
    } else if (appVersion.includes('X11')) {
      return 'Unix'
    } else if (appVersion.includes('Linux')) {
      return 'Linux'
    }
    return 'Unknown'
  }

  isWindows(): boolean {
    return this.getOS() === 'Windows'
  }

  isMac(): boolean {
    return this.getOS() === 'Mac'
  }

  URL(): typeof URL {
    return this.nativeWindow().URL
  }

  private isAtLeastPartiallyInViewportVertically(rect: DOMRect, topOffset = 0, viewPortBuffer = 0): boolean {
    return (
      this.isBottomOfElementInViewport(rect, topOffset, viewPortBuffer) ||
      this.isTopOfElementInViewport(rect, topOffset, viewPortBuffer) ||
      this.isElementLargerTheViewportVertically(rect, topOffset)
    )
  }

  private isBottomOfElementInViewport(rect: DOMRect, topOffset = 0, viewPortBuffer = 0): boolean {
    return (
      rect.bottom >= topOffset - this.getViewportHeight() * viewPortBuffer &&
      rect.bottom <= this.getViewportHeight() * (1 + viewPortBuffer)
    )
  }

  private isTopOfElementInViewport(rect: DOMRect, topOffset = 0, viewPortBuffer = 0): boolean {
    return (
      rect.top >= topOffset - this.getViewportHeight() * viewPortBuffer &&
      rect.top <= this.getViewportHeight() * (1 + viewPortBuffer)
    )
  }

  private isElementLargerTheViewportVertically(rect: DOMRect, topOffset = 0): boolean {
    return rect.top <= topOffset && rect.bottom >= this.getViewportHeight()
  }

  private getVisibilityHiddenKeys(): {
    visibilityChange: string | undefined
    hidden: string | undefined
  } {
    const document = this.document()
    let visibilityChange: string | undefined
    let hidden: string | undefined
    if (typeof document.hidden !== 'undefined') {
      hidden = 'hidden'
      visibilityChange = 'visibilitychange'
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden'
      visibilityChange = 'webkitvisibilitychange'
    }
    return { visibilityChange, hidden }
  }
}
