/* eslint-disable max-lines */
import { UntypedFormGroup } from '@angular/forms'
import { DepartmentService } from '@ftr/api-core'
import { PublicConfiguration } from '@ftr/contracts/api/configuration'
import { CourtSystem } from '@ftr/contracts/api/court-system'
import { Department } from '@ftr/contracts/api/department'
import { OrderingFeature } from '@ftr/contracts/api/feature'
import {
  CustomerOrder,
  LineItem,
  LineItemRequest,
  OrderAttachmentRequest,
  OrderRequest,
  OrderResponse,
  PaymentWaivedReason,
  PlaceOrderGroupRequest,
  ResubmitOrderRequest,
} from '@ftr/contracts/api/order'
import { CreateQuoteBody, Quote } from '@ftr/contracts/api/quote'
import { Turnaround } from '@ftr/contracts/api/transcript-turnaround'
import { BillingGroup, UserGroup, UserGroupPermissionId } from '@ftr/contracts/api/user-group'
import { assertUnreachable } from '@ftr/contracts/shared/assertUnreachable'
import {
  CaseTypeConfiguration,
  CostWaiverConfiguration,
  JudgesAndAppearancesConfiguration,
  LanguagesConfiguration,
  PaperCopiesConfiguration,
  ServiceFeeConfiguration,
  SupportingDocumentsConfiguration,
  VocabularyTerms,
} from '@ftr/contracts/type/core'
import { Currency, LineItemType, Money } from '@ftr/contracts/type/order'
import { Uuid, generateUuid } from '@ftr/contracts/type/shared'
import { ApiResult, ErrorPageService, isNotNullOrUndefined, mapData, unwrapData } from '@ftr/foundation'
import { BillingGroupService } from '@ftr/ui-admin'
import {
  CoreConfigurationService,
  CourtSystemService,
  FeatureService,
  FetchCourtSystemPersonalizationAction,
} from '@ftr/ui-court-system'
import { ToggleableRootLocation } from '@ftr/ui-location'
import { isNatural } from '@ftr/ui-money'
import { OrderService } from '@ftr/ui-ordering'
import { UserState } from '@ftr/ui-user'
import { VocabularyTermsService } from '@ftr/ui-vocab'
import { ZoneId, ZonedDateTime } from '@js-joda/core'
import { Store } from '@ngxs/store'
import { BehaviorSubject, Observable, catchError, filter, map, switchMap, take, takeWhile, throwError } from 'rxjs'
import { AudioOrderStepNames } from '~app/features/audio-order-form/AudioOrderStepNames'
import { AudioOrderFormGroups } from '~app/features/audio-order-form/audio-order-form-builder.service'
import { OrderLocationService } from '~app/features/order-location/order-location.service'
import { RealTimeOrderStepNames } from '~app/features/real-time-order-form/RealTimeOrderStepNames'
import { RealTimeOrderFormGroups } from '~app/features/real-time-order-form/real-time-order-form-builder.service'
import { TranscriptOrderStepNames } from '~app/features/transcript-order-form/TranscriptOrderStepNames'
import { TranscriptOrderFormGroups } from '~app/features/transcript-order-form/transcript-order-form-builder.service'
import { PaymentData } from '../order-payment/payment-fields.component'
import { OrderFormBuilderService, OrderFormGroups } from './order-form-builder.service'
import { AdditionalData, OrderSummaryData } from './summary/OrderSummaryData'

/**
 * Information required across all steps of the order form, or to configure the order form.
 */
export interface OrderFormConfiguration<T> {
  configuration: PublicConfiguration
  terms: VocabularyTerms
  currentUserGroups: UserGroup[]
  courtSystem: CourtSystem
  department: Department | undefined
  courthouses: ToggleableRootLocation[]
  isPaymentRequired: boolean
  paymentWaivedReason?: PaymentWaivedReason | undefined
  supportingDocuments?: SupportingDocumentsConfiguration | undefined
  billingGroup: BillingGroup | null
  costWaiver?: CostWaiverConfiguration | undefined
  feature: T

  /**
   * Transcript Order Form Specific
   */
  caseType?: CaseTypeConfiguration
  turnarounds?: Turnaround[]
  judgesAndAppearances?: JudgesAndAppearancesConfiguration | undefined
  languages?: LanguagesConfiguration | undefined
  instructionsDescription?: string | undefined
  hearingPortionOptions?: string[]
  paperCopies?: PaperCopiesConfiguration | undefined

  /**
   * Audio Order Form Specific
   */
  isAudioOrderSharingEnabled?: boolean
  isSttOrderingEnabled?: boolean
}

/**
 * The estimated order price data.
 */
export interface OrderQuoteData {
  quote: Quote | undefined
}

/**
 * The due date, turnaround and estimate calculation data.
 */
export interface TranscriptQuoteData extends OrderQuoteData {
  turnaroundId: Uuid
  dueBy: Observable<ZonedDateTime>
}

/**
 * The details of one order placed under a group
 */
export interface OrderSubmissionData<T> {
  id: Uuid
  lineItem: T
}

/**
 * All the data in an order group form submission
 */
export interface OrderGroupSubmissionData<T> {
  orderGroupId?: Uuid
  orders: OrderSubmissionData<T>[]
  paymentToken: string | undefined
  paymentTokenError?: string
  saveCreditOrDebitCard: boolean | undefined
  cardId: Uuid | undefined
  attachments: OrderAttachmentRequest[]
  costWaiverRequested: boolean
  validationError?: string
}

/**
 * Define labels, time precision and max number of segments
 */
export interface HearingSegmentsConfiguration {
  maxHearingSegments: number
  title: string
  preamble: string
}

interface OrderIds {
  orderId: Uuid
  orderGroupId: Uuid | undefined
}

/**
 * Types of customer order price views (on order confirmation screen, although
 * the logic may apply to other screens):
 *
 * - Hidden: We don't want to show any pricing. This usually applies if a
 *   customer belongs to a billing group that does not want its members to see
 *   prices.
 * - Estimate: Estimated price, this is usually shown when the final
 *   price is variable, but the user doesn't need to pay at time of order
 *   placement either due to them belonging to a billing group or applying for a
 *   cost waiver.
 * - Held: Estimated price, similar to above, but since we know there will be a
 *   bill due at the end, we are essentially taking the full estimated price as a
 *   deposit now.
 * - Final: The order price is not variable so we take full payment now.
 */
export type OrderPriceView = OrderPriceHidden | OrderPriceEstimate | OrderPriceHeld | OrderPriceFinal

export interface OrderPriceHidden {
  type: 'hidden'
}

export function isOrderPriceHidden(v: OrderPriceView): v is OrderPriceHidden {
  return v.type === 'hidden'
}

export interface OrderPriceHeld {
  type: 'held'
  amount: Money
}

export function buildOrderPriceHidden(): OrderPriceHidden {
  return { type: 'hidden' }
}

export function isOrderPriceHeld(v: OrderPriceView): v is OrderPriceHeld {
  return v.type === 'held'
}

export function buildOrderPriceHeld(amount: Money): OrderPriceHeld {
  return { type: 'held', amount }
}

export interface OrderPriceEstimate {
  type: 'estimate'
  amount: Money
}

export function isOrderPriceEstimate(v: OrderPriceView): v is OrderPriceEstimate {
  return v.type === 'estimate'
}

function buildOrderPriceEstimate(amount: Money): OrderPriceEstimate {
  return { type: 'estimate', amount }
}

export interface OrderPriceFinal {
  type: 'final'
  amount: Money
}

export function isOrderPriceFinal(v: OrderPriceView): v is OrderPriceFinal {
  return v.type === 'final'
}

function buildOrderPriceFinal(amount: Money): OrderPriceFinal {
  return { type: 'final', amount }
}

export interface OrderPriceViewMatchFunctions<T> {
  hidden: (h: OrderPriceHidden) => T
  estimate: (e: OrderPriceEstimate) => T
  held: (h: OrderPriceHeld) => T
  final: (f: OrderPriceFinal) => T
}

export function matchOrderPriceView<T>(
  v: OrderPriceView,
  { hidden, estimate, held, final }: OrderPriceViewMatchFunctions<T>,
): T {
  if (isOrderPriceHidden(v)) {
    return hidden(v)
  } else if (isOrderPriceEstimate(v)) {
    return estimate(v)
  } else if (isOrderPriceHeld(v)) {
    return held(v)
  } else if (isOrderPriceFinal(v)) {
    return final(v)
  } else {
    assertUnreachable(v)
  }
}

export abstract class OrderFormService<
  TLineItem extends LineItem = LineItem,
  TOrderingFeature extends OrderingFeature = OrderingFeature,
> {
  /**
   * The orderId of the first order.
   */
  orderIdSource = new BehaviorSubject<Uuid | undefined>(undefined)
  /**
   * The orderGroupId of an order group with multiple orders.
   * This should be undefined when submitting or resubmitting a single order.
   */
  orderGroupIdSource = new BehaviorSubject<Uuid | undefined>(undefined)
  /**
   * The estimated order price data.
   */
  protected currentQuoteData = new Map<UntypedFormGroup, BehaviorSubject<OrderQuoteData | undefined>>()
  /**
   * The order form configuration that is required by more than one step on the order form, or for setting up the form
   */
  protected orderFormConfiguration: OrderFormConfiguration<TOrderingFeature>
  /**
   * Whether the order is being resubmitted or not.
   */
  private resubmitting = false
  /**
   * The customer order if a valid orderId query param was passed to the order form.
   */
  private customerOrder: CustomerOrder<TLineItem> | undefined

  private readonly currentUserGroups$: ApiResult<UserGroup[]>

  /**
   * The amount of money released when requested more information
   */
  private fundsReleased: Money | undefined

  protected abstract readonly lineItemType: LineItemType

  protected constructor(
    private readonly coreConfigurationService: CoreConfigurationService,
    private readonly courtSystemService: CourtSystemService,
    protected readonly orderService: OrderService,
    private readonly orderFormBuilderService: OrderFormBuilderService,
    private readonly departmentService: DepartmentService,
    private readonly store: Store,
    private readonly vocabularyTermsService: VocabularyTermsService,
    private readonly orderLocationService: OrderLocationService,
    protected readonly billingGroupService: BillingGroupService,
    protected readonly errorPageService: ErrorPageService,
    protected readonly featureService: FeatureService<TOrderingFeature>,
  ) {
    this.currentUserGroups$ = this.store.select(UserState.currentUserGroups)
  }

  fetchLineItem(orderId?: Uuid): ApiResult<CustomerOrder<TLineItem> | undefined> {
    if (!orderId) {
      return ApiResult.success(undefined)
    }
    return this.orderService.getWithLineItem<TLineItem>(orderId)
  }

  canDistributeTranscripts(): boolean {
    return this.orderFormConfiguration.currentUserGroups.some(u =>
      u.permissions.some(p => p.id === UserGroupPermissionId.DistributeTranscripts),
    )
  }

  isPaymentRequired(): boolean {
    return this.orderFormConfiguration.isPaymentRequired
  }

  /**
   * Payment requirement order of precedence:
   *
   * 1. isPaymentRequired, i.e. does user have BillToUserGroup (audio/transcript) or PlaceOrderWithoutHolds (transcript)
   * 2. hasUnwaivableFees, which negates cost waiver
   * 3. costWaiverRequested, which determines need to pay
   */
  isPaymentRequiredToPlaceOrder(hasUnwaivableFees: boolean, costWaiverRequested: boolean): boolean {
    if (!this.isPaymentRequired()) {
      return false
    }

    if (hasUnwaivableFees) {
      return true
    }

    return !costWaiverRequested
  }

  isCostWaiverSupported(
    customerOrder: CustomerOrder<TLineItem> | undefined,
    isBillToBillingGroupWithUnwaivableFees: boolean,
  ): boolean {
    const costWaiverConfiguration = this.getCostWaiverConfiguration()
    const costWaiverEnabled = costWaiverConfiguration?.enabled || !!customerOrder?.costWaiverStatus

    return costWaiverEnabled && !isBillToBillingGroupWithUnwaivableFees
  }

  /**
   * TODO ST-438 Remove this once we can support billing groups + cost waivers +
   * unwaivable fees (aka STT)
   *
   * Please note that there is a lot of implied logic here as we don't actually
   * check for billing group membership. It's implied through
   * `isPaymentRequired`, the implied conditions are discussed in more detail in
   * the doc block in `this.isPaymentRequiredToPlaceOrder`.
   */
  isBillToBillingGroupWithUnwaivableFees(hasUnwaivableFees: boolean): boolean {
    return !this.isPaymentRequired() && hasUnwaivableFees
  }

  getPaymentWaivedReason(): PaymentWaivedReason | undefined {
    return this.orderFormConfiguration.paymentWaivedReason
  }

  isInternalClient(): boolean {
    return this.getPaymentWaivedReason() === PaymentWaivedReason.PayAfterFulfilment
  }

  isMultiDayOrder(): boolean {
    return this.getFormGroupsForOrdersToBePlaced().length > 1
  }

  getTerms(): VocabularyTerms {
    return this.orderFormConfiguration.terms
  }

  getPaymentApiKey(): string {
    return this.orderFormConfiguration.configuration.stripePublicApiKey
  }

  isResubmitting(): boolean {
    return this.resubmitting
  }

  setResubmittingStatus(resubmitting: boolean): void {
    this.resubmitting = resubmitting
  }

  setOrderId(orderId: Uuid | undefined): void {
    this.orderIdSource.next(orderId)
  }

  setOrderGroupId(orderGroupId: Uuid | undefined): void {
    this.orderGroupIdSource.next(orderGroupId)
  }

  getOrderId(): Uuid | undefined {
    return this.orderIdSource.getValue()
  }

  getOrderGroupId(): Uuid | undefined {
    return this.orderGroupIdSource.getValue()
  }

  getCourtSystemId(): Uuid {
    return this.orderFormConfiguration.courtSystem.id
  }

  getCourtSystemName(): Uuid {
    return this.orderFormConfiguration.courtSystem.name
  }

  getDepartment(): Department | undefined {
    return this.orderFormConfiguration.department
  }

  getBillingGroup(): BillingGroup | null {
    return this.orderFormConfiguration.billingGroup
  }

  setCustomerOrder(customerOrder: CustomerOrder<TLineItem> | undefined): void {
    this.customerOrder = customerOrder
  }

  getCustomerOrder(): CustomerOrder<TLineItem> | undefined {
    return this.customerOrder
  }

  getSupportingDocumentsConfiguration(): SupportingDocumentsConfiguration | undefined {
    return this.orderFormConfiguration.supportingDocuments
  }

  getCostWaiverConfiguration(): CostWaiverConfiguration | undefined {
    return this.orderFormConfiguration.costWaiver
  }

  getJudgesAndAppearancesConfiguration(): JudgesAndAppearancesConfiguration | undefined {
    return this.orderFormConfiguration.judgesAndAppearances
  }

  getLanguagesConfiguration(): LanguagesConfiguration | undefined {
    return this.orderFormConfiguration.languages
  }

  getInstructionsDescription(): string | undefined {
    return this.orderFormConfiguration.instructionsDescription
  }

  getCaseTypeConfiguration(): CaseTypeConfiguration | undefined {
    return this.orderFormConfiguration.caseType
  }

  getTurnarounds(): Turnaround[] {
    return this.orderFormConfiguration.turnarounds || []
  }

  getHearingPortionOptions(): string[] {
    return this.orderFormConfiguration.hearingPortionOptions || []
  }

  isAudioOrderSharingEnabled(): boolean {
    return !!this.orderFormConfiguration.isAudioOrderSharingEnabled
  }

  getFundsReleased(): Money | undefined {
    return this.fundsReleased
  }

  setFundsReleased(value: Money | undefined): void {
    this.fundsReleased = value
  }

  validateAndSubmitOrder(submission: OrderGroupSubmissionData<LineItemRequest>): Observable<OrderResponse[]> {
    if (this.validateSubmissionData(submission)) {
      return this.isResubmitting()
        ? this.resubmitOrderRequest(submission).pipe(map(x => [x]))
        : this.placeOrderRequest(submission)
    } else {
      return throwError(() => submission.paymentTokenError || submission.validationError)
    }
  }

  setCurrentQuoteData(formGroup: UntypedFormGroup, currentQuoteData: OrderQuoteData | undefined): void {
    this.getCurrentQuoteData(formGroup).next(currentQuoteData)
  }

  getCurrentQuoteData(formGroup: UntypedFormGroup): BehaviorSubject<OrderQuoteData | undefined> {
    let currentQuoteData = this.currentQuoteData.get(formGroup)

    if (!currentQuoteData) {
      currentQuoteData = new BehaviorSubject<OrderQuoteData | undefined>(undefined)
      this.currentQuoteData.set(formGroup, currentQuoteData)
    }

    return currentQuoteData
  }

  getHearingSegmentsConfiguration(): HearingSegmentsConfiguration {
    return {
      maxHearingSegments: 10,
      title: 'Transcript Details',
      preamble: '',
    }
  }

  /**
   * Helper method to access generated form groups from the form builder service
   */
  getFormForStep(
    stepName: OrderFormGroups | AudioOrderFormGroups | RealTimeOrderFormGroups | TranscriptOrderFormGroups,
  ): UntypedFormGroup {
    return this.orderFormBuilderService.getFormForStep(stepName)
  }

  getDefaultCurrency(): Currency {
    return this.orderFormConfiguration.configuration.defaultCurrency
  }

  getServiceFeeConfiguration(): ServiceFeeConfiguration | undefined {
    return this.orderFormConfiguration.configuration.serviceFee
  }

  getCourthouses(): ToggleableRootLocation[] {
    return this.orderFormConfiguration.courthouses
  }

  getTimeZoneId(): ZoneId {
    return this.orderFormConfiguration.configuration.timeZoneId
  }

  getOrderPriceView(
    order: CustomerOrder<TLineItem>,
    canViewPrice: boolean,
    isPaymentRequiredToPlaceOrder: boolean,
  ): OrderPriceView {
    if (!canViewPrice) {
      return buildOrderPriceHidden()
    }

    if (isNatural(order.finalPrice)) {
      return buildOrderPriceFinal(order.finalPrice)
    }

    if (isNatural(order.estimate) && isPaymentRequiredToPlaceOrder) {
      return buildOrderPriceHeld(order.estimate)
    }

    if (isNatural(order.estimate)) {
      return buildOrderPriceEstimate(order.estimate)
    }

    return buildOrderPriceHidden()
  }

  canViewOrderPrices(): boolean {
    const billingGroup = this.getBillingGroup()
    return billingGroup ? this.billingGroupService.canViewOrderPrices(billingGroup) : true
  }

  abstract isEstimatedPrice(): boolean

  abstract isTotalPrice(): boolean

  abstract createSummaryData(): OrderSummaryData

  abstract createSubmission(paymentData?: PaymentData): OrderGroupSubmissionData<LineItemRequest>

  abstract createQuoteData(singleDayOrderFormGroup: UntypedFormGroup): CreateQuoteBody | undefined

  abstract hasCompleteQuoteData(singleDayOrderFormGroup: UntypedFormGroup): boolean

  /**
   * Create parameters to generate quotes for each single day order on a single or multi day order.
   */
  abstract mapToCreateQuoteBody(singleDayOrderFormGroup: UntypedFormGroup): CreateQuoteBody

  /**
   * When more than one form group is returned, this order becomes a "multi day order"
   */
  abstract getFormGroupsForOrdersToBePlaced(): UntypedFormGroup[]

  abstract fetchOrderFormConfiguration(
    courtSystemId: Uuid,
    orderId?: Uuid,
    departmentId?: Uuid,
  ): ApiResult<OrderFormConfiguration<TOrderingFeature>>

  abstract getAdditionalReviewPageData(courtSystemId: Uuid): ApiResult<AdditionalData>

  abstract mapStepNameToFormGroup(
    stepName: AudioOrderStepNames | RealTimeOrderStepNames | TranscriptOrderStepNames,
  ): OrderFormGroups | AudioOrderFormGroups | RealTimeOrderFormGroups | TranscriptOrderFormGroups

  // TODO (FPD-220): Revise how the current user group stuff works
  protected doFetchOrderFormConfiguration(
    courtSystemId: Uuid,
    orderId?: Uuid,
    departmentId?: Uuid,
  ): ApiResult<OrderFormConfiguration<TOrderingFeature>> {
    return ApiResult.combine({
      courtSystem: this.courtSystemService.getCourtSystem(courtSystemId),
      feature: this.featureService.getForCourtSystem(courtSystemId),
      canPlaceOrdersInUnpublishedCourts: ApiResult.from(
        this.store.select(UserState.hasPermissionInCourtSystem).pipe(
          map(fn => fn(UserGroupPermissionId.PlaceOrdersInUnpublishedCourts, courtSystemId)),
          filter(isNotNullOrUndefined),
        ),
      ),
    }).pipe(
      unwrapData(),
      takeWhile(({ courtSystem, feature, canPlaceOrdersInUnpublishedCourts }) => {
        if (canPlaceOrder(feature, courtSystem, canPlaceOrdersInUnpublishedCourts)) {
          return true
        } else {
          this.errorPageService.showNotFoundPage()
          return false
        }
      }),
      switchMap(({ courtSystem, feature }) => {
        this.store.dispatch(new FetchCourtSystemPersonalizationAction(courtSystemId))

        return ApiResult.combine([
          this.coreConfigurationService.getByCourtSystem(courtSystemId),
          this.vocabularyTermsService.observeRemoteTerms(courtSystemId),
          ApiResult.from(
            this.currentUserGroups$.pipe(
              filter(d => d && d.isSuccess() && !!d.data),
              map(r => r.data!.filter(u => u.courtSystemId === courtSystemId)),
            ),
          ).pipe(take(1)), // to prevent more calls from making the order form initialize multiple times.
          ApiResult.success(courtSystem),
          this.orderService.canSkipPayment(this.lineItemType, courtSystemId, orderId),
          this.loadDepartment(departmentId),
          this.billingGroupService.getBillingGroupForAuthenticatedUserInCourt(courtSystemId),
          this.orderLocationService.getToggleableCourthouses(courtSystemId),
          ApiResult.success(feature),
        ])
      }),
      mapData(
        ([
          configuration,
          terms,
          currentUserGroups,
          courtSystem,
          canSkipPaymentResponse,
          department,
          billingGroup,
          courthouses,
          feature,
        ]) => {
          const isPaymentRequired = !canSkipPaymentResponse.canSkip
          const paymentWaivedReason = canSkipPaymentResponse.reason

          this.orderFormConfiguration = {
            department,
            configuration,
            terms,
            currentUserGroups,
            courtSystem,
            isPaymentRequired,
            paymentWaivedReason,
            billingGroup,
            courthouses,
            feature,
          }

          return this.orderFormConfiguration
        },
      ),
    )
  }

  protected validateSubmissionData(submission: OrderGroupSubmissionData<LineItemRequest>): boolean {
    return !this.isPaymentRequired() || submission.costWaiverRequested || !submission.paymentTokenError
  }

  protected placeOrderRequest(data: OrderGroupSubmissionData<LineItemRequest>): Observable<OrderResponse[]> {
    if (!data.orderGroupId) {
      throw new Error('orderGroupId is required for new orders')
    }

    const orders = data.orders.map(order => new OrderRequest(order.id, order.lineItem))
    return this.orderService
      .placeOrder(
        new PlaceOrderGroupRequest(
          data.orderGroupId,
          orders,
          data.paymentToken,
          data.saveCreditOrDebitCard,
          data.cardId,
          this.getCourtSystemId(),
          data.attachments,
          data.costWaiverRequested,
        ),
      )
      .pipe(
        catchError(error => {
          return throwError(() => error.message)
        }),
      )
  }

  protected resubmitOrderRequest(data: OrderGroupSubmissionData<LineItemRequest>): Observable<OrderResponse> {
    return this.orderService
      .resubmit(
        new ResubmitOrderRequest(
          data.orders[0].id,
          data.orders[0].lineItem,
          data.paymentToken,
          data.saveCreditOrDebitCard,
          data.cardId,
          this.getCourtSystemId(),
          data.attachments,
          data.costWaiverRequested,
        ),
      )
      .pipe(
        catchError(error => {
          return throwError(() => error.message)
        }),
      )
  }

  protected generateOrderIds(): OrderIds {
    let orderId: Uuid
    let orderGroupId: Uuid | undefined

    if (this.isResubmitting()) {
      // use the existing orderId
      const existingOrderId = this.getOrderId()

      if (!existingOrderId) {
        throw new Error('setOrderId not called for existing order')
      }

      orderId = existingOrderId

      // clear orderGroupId if set
      this.setOrderGroupId(undefined)
    } else {
      // always generate new IDs to help prevent double submissions
      orderId = generateUuid()
      orderGroupId = generateUuid()

      // set orderId for confirmation page
      this.setOrderId(orderId)

      // set orderGroupId if there are multiple orders (orderGroupId is not persisted for single orders)
      this.setOrderGroupId(this.getFormGroupsForOrdersToBePlaced().length > 1 ? orderGroupId : undefined)
    }

    return { orderId, orderGroupId }
  }

  private loadDepartment(departmentId: Uuid | undefined): ApiResult<Department | undefined> {
    if (!departmentId) {
      return ApiResult.success(undefined)
    }
    return this.departmentService.get(departmentId)
  }
}

export function canPlaceOrder(
  feature: OrderingFeature,
  courtSystem: CourtSystem,
  canPlaceOrdersInUnpublishedCourts: boolean,
): boolean {
  return (
    feature.isEnabled &&
    ((courtSystem.isPublished && feature.publicOrderingEnabled) || canPlaceOrdersInUnpublishedCourts)
  )
}
