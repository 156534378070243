import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ApiClient, ApiClientFactory } from '@ftr/api-shared'
import {
  FinalizeOrderAttachmentUploadBody,
  InitializeOrderAttachmentUploadBody,
} from '@ftr/contracts/api/order-attachment'
import { SignedUpload } from '@ftr/contracts/type/core'
import { Uuid } from '@ftr/contracts/type/shared'
import { ApiResult, collectData } from '@ftr/foundation'
import { OrderAttachmentUploadProps } from '@ftr/ui-admin'
import {
  ChecksumService,
  FileUpload,
  FileUploadService,
  FileUploadWithChecksum,
  InitializedFileUpload,
  SuccessfulFileUpload,
  UploadStates,
} from '@ftr/ui-files'
import { LoggingService } from '@ftr/ui-observability'
import { LocalDateTime } from '@js-joda/core'
import { Observable } from 'rxjs'

@Injectable()
export class OrderAttachmentFileUploadService extends FileUploadService<OrderAttachmentUploadProps, undefined> {
  private readonly apiClient: ApiClient

  constructor(
    http: HttpClient,
    loggingService: LoggingService,
    checksumService: ChecksumService,
    apiClientFactory: ApiClientFactory,
  ) {
    super(http, loggingService, checksumService)
    this.apiClient = apiClientFactory.build('/order-attachment')
    this.addVirusScanning = true
  }

  initializeUpload(
    upload: FileUploadWithChecksum<OrderAttachmentUploadProps>,
  ): Observable<InitializedFileUpload<OrderAttachmentUploadProps>> {
    const body: InitializeOrderAttachmentUploadBody = {
      name: upload.file.name,
      size: upload.file.size,
      createdAt: LocalDateTime.now().toString(),
      path: 'unknown',
      checksum: upload.checksum,
      courtSystemId: upload.props.courtSystemId,
    }

    return this.apiClient.post<SignedUpload>({ path: '/initializeOrderAttachmentUpload', body }).pipe(
      collectData(signedUpload => {
        if (signedUpload.signedUploadUrl && signedUpload.fileId) {
          return {
            ...upload,
            url: signedUpload.signedUploadUrl,
            fileId: signedUpload.fileId,
            progress: 0,
            status: UploadStates.Uploading,
          }
        }
        throw new Error('initializeOrderAttachmentUpload was not in expected format')
      }),
    )
  }

  finalizeUpload(
    upload: InitializedFileUpload<OrderAttachmentUploadProps>,
  ): Observable<SuccessfulFileUpload<OrderAttachmentUploadProps>> {
    const body: FinalizeOrderAttachmentUploadBody = {
      fileId: upload.fileId,
    }
    return this.apiClient
      .post<SuccessfulFileUpload<OrderAttachmentUploadProps>>({
        path: 'finalizeOrderAttachmentUpload',
        body,
      })
      .pipe(collectData(() => ({ ...upload, status: UploadStates.Success })))
  }

  download(attachmentId?: Uuid): string {
    if (!attachmentId) {
      return ''
    }
    return `${this.apiClient.baseUrl}/download/${attachmentId}`
  }

  deleteFile(upload: FileUpload<OrderAttachmentUploadProps>): ApiResult {
    return this.apiClient.delete({ path: `/file/${upload.fileId}` })
  }
}
