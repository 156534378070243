import { Component } from '@angular/core'

@Component({
  selector: 'ftr-footer-disclaimer',
  templateUrl: './footer-disclaimer.component.html',
  styleUrls: ['./footer-disclaimer.component.css'],
  standalone: true,
})
export class FooterDisclaimerComponent {
  currentYear = new Date().getFullYear()
}
