@if (imageUrl) {
  @if (addLinkToCourtPage) {
    <a
      data-track-group="Logo"
      data-track
      class="logo-link"
      [title]="courtSystemName"
      [routerLink]="courtSystemHomeUrl()"
    >
      <img class="logo" src="{{ imageUrl }}" alt="Logo for {{ courtSystemName }}" />
    </a>
  } @else {
    <img class="logo" src="{{ imageUrl }}" alt="Logo for {{ courtSystemName }}" />
  }
}
