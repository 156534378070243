import { animate, AnimationTriggerMetadata, state, style, transition, trigger } from '@angular/animations'

export enum FadeAnimationType {
  Fade = 'fade',
  HalfFade = 'halffade',
}

export const accordion = trigger('accordionAnimation', [
  state('showing', style({ height: '*', visibility: 'visible', overflow: 'hidden' })),
  state('hidden, void', style({ height: '0px', visibility: 'hidden', overflow: 'hidden' })),
  transition('showing <=> hidden, void => hidden', animate('300ms ease')),
])

export const accordionWithOverflowFix = trigger('accordionWithOverflowFixAnimation', [
  /**
   * Note: overflow is visible in this animation due to the assign transcriber dropdown list
   * being hidden by its cards overflow-hidden
   */
  state('showing', style({ height: '*', overflow: 'visible' })),
  state('hidden', style({ height: 0, overflow: 'hidden' })),
  transition('showing => hidden', animate('300ms ease')),
  transition('hidden => showing', animate('300ms ease')),
])

export function fade(
  enterDuration: number = 300,
  leaveDuration: number = 100,
  type: FadeAnimationType = FadeAnimationType.Fade,
): AnimationTriggerMetadata {
  const opacity = type === FadeAnimationType.Fade ? 1 : 0.5

  return trigger(type, [
    transition(':enter', [style({ opacity: 0 }), animate(`${enterDuration}ms`, style({ opacity }))]),
    transition(':leave', [style({ opacity }), animate(`${leaveDuration}ms`, style({ opacity: 0 }))]),
  ])
}
