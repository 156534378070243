import { Component, Input, OnInit } from '@angular/core'
import { AbstractControl, ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms'
import { ButtonComponent, DestroySubscribers, IconComponent, StopPropagationDirective } from '@ftr/foundation'
import { distinctUntilChanged, takeUntil } from 'rxjs'

/**
 * A wrapper for a numeric input element.
 * Works with whole integers between min and max
 */
@Component({
  standalone: true,
  selector: 'ftr-form-input-minus-plus',
  templateUrl: './form-input-minus-plus.component.html',
  imports: [ButtonComponent, IconComponent, StopPropagationDirective, ReactiveFormsModule],
  styleUrls: ['./form-input-minus-plus.component.css'],
})
export class FormInputMinusPlusComponent extends DestroySubscribers implements OnInit {
  @Input() min = 0
  @Input() max = 999
  @Input() default = 0
  @Input() ngFormControl: AbstractControl
  @Input() label: string

  internalControl: UntypedFormControl
  // Maintain current value as a fallback in case the user presses escape or enters an invalid value
  private currentValue: number

  constructor() {
    super()
    this.internalControl = new UntypedFormControl(undefined, {
      updateOn: 'blur',
      validators: [Validators.min(this.min), Validators.max(this.max)],
    })
  }

  ngOnInit(): void {
    this.validateInitialValues()
    this.currentValue = Number(this.ngFormControl.value) || this.default
    this.setFormControlValue()

    this.internalControl.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.finalize)).subscribe(value => {
      value = parseInt(value, 10)
      if (Number.isInteger(value) && !Number.isNaN(value)) {
        this.validateInput(value)
      } else {
        this.setFormControlValue()
      }
    })
  }

  validateInitialValues(): void {
    if (this.min > this.default || this.min > this.max || this.default > this.max) {
      throw new Error('FormInputMinusPlusComponent: Incorrect initial values')
    }
  }

  minus(): void {
    this.validateInput(this.internalControl.value - 1)
  }

  plus(): void {
    this.validateInput(this.internalControl.value + 1)
  }

  validateInput(input: number): void {
    if (input >= this.min && input <= this.max) {
      this.currentValue = input
    }

    this.setFormControlValue()
  }

  setFormControlValue(target: EventTarget | null = null): void {
    if (target) {
      const input = target as HTMLInputElement
      input.blur()
    }

    this.internalControl.setValue(this.currentValue)
    this.internalControl.updateValueAndValidity()
    this.ngFormControl.setValue(this.currentValue)
    this.ngFormControl.updateValueAndValidity()
  }

  get isMinusDisabled(): boolean {
    return this.internalControl.value === this.min
  }

  get isPlusDisabled(): boolean {
    return this.internalControl.value === this.max
  }
}
