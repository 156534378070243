import { generateUuid } from '@ftr/contracts/type/shared'
import { AbstractBuilder } from '@ftr/foundation'
import { RealTimeSearchHighlights, RealTimeSttRemark, RealTimeSttRemarkWithMetadata } from '../types'
import { RealTimeSttRemarkUtils } from '../utils'
import { RealTimeSttRemarkBuilder } from './real-time-stt-remark-builder'

export class RealTimeSttRemarkWithMetadataBuilder extends AbstractBuilder<RealTimeSttRemarkWithMetadata> {
  constructor() {
    const id = generateUuid()
    super({
      ...new RealTimeSttRemarkBuilder().build(),
      type: 'Remark',
      content: 'some remark content',
      isFirstRemarkOfSpeaker: true,
      firstRemarkIdOfSpeaker: id,
      isFirstRemarkOfMinute: false,
      id,
      segmentId: generateUuid(),
      isSealed: false,
      isHearing: false,
    })
  }

  /**
   * Regeneration requires the remarks to be in order
   */
  static regenerateMetadata(
    remarks: readonly RealTimeSttRemarkWithMetadata[],
    searchHighlightMatches: RealTimeSearchHighlights,
  ): RealTimeSttRemarkWithMetadata[] {
    return remarks.map((remark, idx) => {
      const firstRemarkIdOfSpeaker = RealTimeSttRemarkUtils.getFirstRemarkOfSpeaker(remarks, idx).id

      const searchHighlightingContext = RealTimeSttRemarkUtils.getSearchHighlightingContextForRemark(
        remark,
        searchHighlightMatches,
      )
      return {
        ...remark,
        isFirstRemarkOfMinute: RealTimeSttRemarkUtils.isFirstRemarkOfMinute(remarks[idx], remarks[idx - 1]),
        isFirstRemarkOfSpeaker: firstRemarkIdOfSpeaker === remark.id,
        firstRemarkIdOfSpeaker,
        searchHighlightingContext,
      }
    })
  }

  static buildFromRemarks(
    segmentId: string,
    remarks: RealTimeSttRemark[],
    searchHighlights: RealTimeSearchHighlights,
  ): RealTimeSttRemarkWithMetadata[] {
    const mappedRemarks: RealTimeSttRemarkWithMetadata[] = remarks.map(r => mapToRemarkWithMetadata(segmentId, r))
    return this.regenerateMetadata(mappedRemarks, searchHighlights)
  }

  static buildFromRemark(
    segmentId: string,
    remark: RealTimeSttRemark,
    searchHighlights: RealTimeSearchHighlights = {
      selected: undefined,
      hovered: undefined,
    },
  ): RealTimeSttRemarkWithMetadata {
    return this.regenerateMetadata([mapToRemarkWithMetadata(segmentId, remark)], searchHighlights)[0]
  }
}

function mapToRemarkWithMetadata(segmentId: string, remark: RealTimeSttRemark): RealTimeSttRemarkWithMetadata {
  return {
    type: 'Remark' as const,
    endTime: remark.endTime,
    speakerId: remark.speakerId,
    speakerName: remark.speakerName,
    startTime: remark.startTime,
    id: remark.id,
    segmentId,
    content: remark.content,
    isSealed: !!remark.isSealed,
    grouping: remark.grouping,
    liveResult: remark.liveResult,
    // dummy values - will be overwritten in  regenerateMetadata
    isFirstRemarkOfMinute: false,
    isFirstRemarkOfSpeaker: false,
    firstRemarkIdOfSpeaker: '',
    isHearing: !!remark.isHearing,
    sealingMarkerId: remark.sealingMarkerId,
    hearingSectionId: remark.hearingSectionId,
  }
}
