import { Component } from '@angular/core'
import { LogoSize, LogoTheme } from '../../types/logo'
import { FooterHelpLinksComponent } from '../footer-help-links'
import { FtrLogoComponent } from '../ftr-logo/ftr-logo.component'

@Component({
  selector: 'ftr-footer-logged-out',
  templateUrl: './footer-logged-out.component.html',
  styleUrls: ['./footer-logged-out.component.css'],
  standalone: true,
  imports: [FtrLogoComponent, FooterHelpLinksComponent],
})
export class FooterLoggedOutComponent {
  readonly logoThemes = LogoTheme
  readonly logoSizes = LogoSize
}
