import { LocalTimeRange } from '@ftr/contracts/type/shared'
import { DateFormat, DatePipe, DefaultTimeStringParseMethod, TimeStringParser } from '@ftr/foundation'
import { LocalTime } from '@js-joda/core'
import { HearingSectionModel } from '../store'
import { RealTimeSttSealingMarker } from '../types'

export function tryFormatTime(timeInput: string): string {
  try {
    const localTime = TimeStringParser.parse(timeInput.trim(), [DefaultTimeStringParseMethod])
    return new DatePipe().transform(localTime, { format: DateFormat.TimeWithSeconds })
  } catch {
    return timeInput
  }
}

export function simpleObjectEquals<T>(a: T, b: T): boolean {
  if (typeof a !== 'object' || a === null || typeof b !== 'object' || b === null) {
    return false
  }

  const objectA = a as Record<string, unknown>
  const objectB = b as Record<string, unknown>
  const aKeys = Object.keys(objectA)
  const bKeys = Object.keys(objectB)
  return aKeys.length === bKeys.length && aKeys.every(key => objectA[key] === objectB[key])
}

/**
 * Whether the time is inside any hearing, start inclusive, end exclusive
 */
export function isTimeInHearings(startTime: LocalTime, hearings: readonly HearingSectionModel[]): boolean {
  return isTimeWithinTimeRanges(
    startTime,
    hearings.map(h => new LocalTimeRange(h.startTime, h.endTime)),
  )
}

/**
 * Whether the time is within a sealed time range, start inclusive, end exclusive
 */
export function isTimeSealed(startTime: LocalTime, sealingMarkers: readonly RealTimeSttSealingMarker[]): boolean {
  return isTimeWithinTimeRanges(
    startTime,
    sealingMarkers.map(s => s.sealedTimeRange),
  )
}

/**
 * Whether the time is within any of the supplied time ranges, start inclusive, end exclusive
 */
export function isTimeWithinTimeRanges(time: LocalTime, timeRanges: readonly LocalTimeRange[]): boolean {
  return timeRanges.some(timeRange => timeRange.contains(time))
}

/**
 * Whether the given time range is enclosed by any of the supplied time ranges, start and end are inclusive
 */
export function isTimeRangeWithinTimeRanges(timeRange: LocalTimeRange, timeRanges: readonly LocalTimeRange[]): boolean {
  return timeRanges.some(t => t.encloses(timeRange))
}

/**
 * Returns the id of a sealed marker that contains the given time
 */
export function getContainingSealingMarkerId(
  startTime: LocalTime,
  sealingMarkers: readonly RealTimeSttSealingMarker[],
): string | undefined {
  const timeRange = sealingMarkers.map(m => m.sealedTimeRange).find(t => t.contains(startTime))
  return (timeRange && sealingMarkers.find(h => h.sealedTimeRange.start.equals(timeRange.start)))?.id
}

/**
 * Returns the id of a hearing section that contains the given time
 */
export function getContainingHearingSectionId(
  startTime: LocalTime,
  hearings: readonly HearingSectionModel[],
): string | undefined {
  const timeRange = hearings.map(h => new LocalTimeRange(h.startTime, h.endTime)).find(t => t.contains(startTime))
  return (timeRange && hearings.find(h => h.startTime.equals(timeRange.start)))?.id
}
