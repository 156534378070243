<footer
  class="footer"
  role="contentinfo"
  data-track-group="Footer"
  [ngClass]="{
    'footer__hide-margin': hideFooterMargin,
  }"
>
  <div class="footer__user">
    <div [class]="mainContainerClass">
      @if (viewModel | async; as vm) {
        <div [class]="pageContainerClass">
          @if (vm.isLoggedIn) {
            @if (vm.hasAdminPermission && vm.hasMinimumItems) {
              <!-- User is logged in and has admin permissions -->
              <ftr-footer-admin></ftr-footer-admin>
            } @else {
              <!-- User is logged in and any permissions other than admin permissions -->
              <ftr-footer-logged-in></ftr-footer-logged-in>
            }
          } @else {
            <ftr-footer-logged-out></ftr-footer-logged-out>
          }
          <!-- User is not logged in -->
        </div>
      }
    </div>
  </div>
  <div class="footer__disclaimer">
    <div [class]="mainContainerClass">
      <div [class]="pageContainerClass">
        <ftr-footer-disclaimer></ftr-footer-disclaimer>
      </div>
    </div>
  </div>
</footer>
