import { VocabularyTerms } from '@ftr/contracts/type/core'
import { titleCase } from '../../util'

export const ILLUSTRATION_ASSET_PATH = '/assets/images/illustrations'

/**
 * Illustrations must be saved as <ILLUSTRATION_ASSET_PATH>/<name>.svg
 */
export enum Illustration {
  AccessDenied = 'access-denied',
  Archive = 'archive',
  AuditLog = 'audit-log',
  AutoscriptPage = 'autoscript-page',
  Courtroom = 'courtroom',
  CourtSystem = 'court-system',
  Error = 'error',
  Export = 'export',
  FootPedal = 'foot-pedal',
  FourOhFour = '404',
  Location = 'location',
  NoRecordings = 'no-recordings',
  NoRecorders = 'no-recorders',
  NoCases = 'no-cases',
  NoHearings = 'no-hearings',
  NoIssues = 'no-issues',
  NoTranscripts = 'no-transcripts',
  Search = 'search',
  SearchResultsNotFound = 'search-results-not-found',
  Security = 'security',
  SpeechToText = 'speech-to-text',
  Tippy = 'tippy',
  Users = 'users',
  CourtroomCalendar = 'courtroom-calendar',
}

export interface IllustrationDefaults {
  width?: number
  height?: number
  alt: string
}

export function getIllustrationDefaults(
  illustration: Illustration,
  vocabularyTerms: VocabularyTerms,
): IllustrationDefaults {
  const illustrationDefaults = {
    [Illustration.Tippy]: {
      width: 740 / 2,
      height: 535 / 2,
      alt: 'Tippy',
    },
    [Illustration.AccessDenied]: {
      width: 166,
      height: 149,
      alt: 'Access Denied illustration',
    },
    [Illustration.Archive]: {
      width: 166,
      height: 149,
      alt: 'Archive illustration',
    },
    [Illustration.AuditLog]: {
      width: 156,
      height: 150,
      alt: 'Audit log illustration',
    },
    [Illustration.AutoscriptPage]: {
      alt: 'A waveform with playback controls and sample text',
    },
    [Illustration.Courtroom]: {
      width: 156,
      height: 149,
      alt: 'Courtroom illustration',
    },
    [Illustration.CourtSystem]: {
      width: 156,
      height: 149,
      alt: 'Court system illustration',
    },
    [Illustration.Error]: {
      width: 156,
      height: 149,
      alt: 'Error illustration',
    },
    [Illustration.Export]: {
      width: 156,
      height: 150,
      alt: 'Export illustration',
    },
    [Illustration.FootPedal]: {
      width: 117,
      height: 87,
      alt: 'Foot Pedal',
    },
    [Illustration.FourOhFour]: {
      alt: '404',
    },
    [Illustration.Location]: {
      width: 156,
      height: 149,
      alt: 'Location illustration',
    },
    [Illustration.NoRecordings]: {
      width: 156,
      height: 149,
      alt: 'Recording illustration',
    },
    [Illustration.NoRecorders]: {
      width: 156,
      height: 149,
      alt: 'Recorder illustration',
    },
    [Illustration.NoCases]: {
      width: 156,
      height: 149,
      alt: `${titleCase(vocabularyTerms?.case?.singular ?? 'case')} illustration`,
    },
    [Illustration.NoHearings]: {
      width: 156,
      height: 149,
      alt: `${titleCase(vocabularyTerms?.hearing?.singular ?? 'hearing')} illustration`,
    },
    [Illustration.NoIssues]: {
      width: 156,
      height: 149,
      alt: 'Tick illustration',
    },
    [Illustration.NoTranscripts]: {
      width: 156,
      height: 149,
      alt: 'Transcript illustration',
    },
    [Illustration.Search]: {
      width: 156,
      height: 149,
      alt: 'Search illustration',
    },
    [Illustration.SearchResultsNotFound]: {
      alt: 'Empty page illustration',
    },
    [Illustration.Security]: {
      alt: 'Security illustration',
    },
    [Illustration.SpeechToText]: {
      width: 170,
      height: 149,
      alt: 'Speech-to-Text illustration',
    },
    [Illustration.Users]: {
      width: 156,
      height: 149,
      alt: 'Users illustration',
    },
    [Illustration.CourtroomCalendar]: {
      width: 156,
      height: 149,
      alt: 'Courtroom calendar',
    },
  }
  const illustrationSettings = illustrationDefaults[illustration]
  if (!illustrationDefaults) {
    throw new Error(`Missing default settings for illustration: ${illustration}`)
  }
  return illustrationSettings
}
