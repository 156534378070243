import { Injectable } from '@angular/core'
import { serializeHttpParams } from '@ftr/api-shared'
import { CheckAnyPermissionQuery } from '@ftr/contracts/api/court-system'
import { CheckPermissionQuery } from '@ftr/contracts/api/court-system/CheckPermissionQuery'
import { ResourceType } from '@ftr/contracts/api/permissions'
import { UserGroupPermissionId } from '@ftr/contracts/api/user-group'
import { ErrorWithUserMessage } from '@ftr/contracts/shared/ErrorWithUserMessage'
import { Uuid } from '@ftr/contracts/type/shared'
import { ApiResult, createAndAssign, mapData } from '@ftr/foundation'
import { switchMap } from 'rxjs'
import { RegionalApiClientFactory, RegionalApiClientSetupService } from '../api-client'

@Injectable({
  providedIn: 'root',
})
export class RegionalPermissionService extends RegionalApiClientSetupService {
  override regionalApiPath = 'permission'

  constructor(regionalApiClientFactory: RegionalApiClientFactory) {
    super(regionalApiClientFactory)
  }

  /**
   * @param courtSystemId The court system you are checking the resourceIds in
   * @param userGroupPermissionId The permission to check access to
   * @param resourceType The type of the resource id e.g. if it's a recordingId, this is 'recording'
   * @param resourceIds the uuid to check access to
   */
  hasPermission(
    courtSystemId: Uuid,
    userGroupPermissionId: UserGroupPermissionId,
    resourceType?: ResourceType,
    resourceIds?: Uuid[],
  ): ApiResult<boolean> {
    return this.checkPermission(courtSystemId, userGroupPermissionId, resourceType, resourceIds).pipe(mapData(x => !x))
  }

  /**
   * @param courtSystemId The court system you are checking the resourceIds in
   * @param userGroupPermissionIds The permissions to check access to
   * @param resourceType The type of the resource id e.g. if it's a recordingId, this is 'recording'
   * @param resourceIds the uuid to check access to
   */
  hasAnyPermission(
    courtSystemId: Uuid,
    userGroupPermissionIds: UserGroupPermissionId[],
    resourceType?: ResourceType,
    resourceIds?: Uuid[],
  ): ApiResult<boolean> {
    return this.checkAnyPermission(courtSystemId, userGroupPermissionIds, resourceType, resourceIds).pipe(
      mapData(x => !x),
    )
  }

  hasRecordingPermission(
    courtSystemId: Uuid,
    recordingId: Uuid,
    userGroupPermissionId: UserGroupPermissionId,
  ): ApiResult<boolean> {
    return this.hasPermission(courtSystemId, userGroupPermissionId, 'recording', [recordingId])
  }

  /**
   * A result of undefined means the user has the permission
   * @param courtSystemId The court system you are checking the resourceIds in
   * @param userGroupPermissionId The permission to check access to
   * @param resourceType The type of the resource id e.g. if it's a recordingId, this is 'recording'
   * @param resourceIds the uuid to check access to
   */
  private checkPermission(
    courtSystemId: Uuid,
    userGroupPermissionId: UserGroupPermissionId,
    resourceType?: ResourceType,
    resourceIds?: Uuid[],
  ): ApiResult<ErrorWithUserMessage | null> {
    return this.regionalApiClient(this.regionalApiPath, courtSystemId).pipe(
      switchMap(client => {
        return client.get<ErrorWithUserMessage | null>({
          path: 'check',
          params: serializeHttpParams(
            createAndAssign(CheckPermissionQuery, {
              userGroupPermissionId,
              resourceIds,
              resourceType,
            }),
          ),
          headers: { courtSystemId },
        })
      }),
    )
  }

  /**
   * A result of undefined means the user has at least one of the permissions
   * @param courtSystemId The court system you are checking the resourceIds in
   * @param userGroupPermissionIds The permissions to check access to
   * @param resourceType The type of the resource id e.g. if it's a recordingId, this is 'recording'
   * @param resourceIds the uuid to check access to
   */
  private checkAnyPermission(
    courtSystemId: Uuid,
    userGroupPermissionIds: UserGroupPermissionId[],
    resourceType?: ResourceType,
    resourceIds?: Uuid[],
  ): ApiResult<ErrorWithUserMessage | null> {
    return this.regionalApiClient(this.regionalApiPath, courtSystemId).pipe(
      switchMap(client => {
        return client.get<ErrorWithUserMessage | null>({
          path: 'check-any',
          params: serializeHttpParams(
            createAndAssign(CheckAnyPermissionQuery, {
              userGroupPermissionIds,
              resourceIds,
              resourceType,
            }),
          ),
          headers: { courtSystemId },
        })
      }),
    )
  }
}
