<a
  data-track-group="Logo"
  data-track
  class="ftr-logo"
  title="For The Record Home Page"
  [routerLink]="['/']"
  [ngClass]="{
    'ftr-logo--extra-small': size === sizes.ExtraSmall,
    'ftr-logo--small': size === sizes.Small,
    'ftr-logo--default': size === sizes.Default,
  }"
>
  <img class="ftr-logo__logo" alt="For The Record logo" src="{{ theme | themedLogoPath }}" />
</a>
