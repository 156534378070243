/* eslint-disable max-classes-per-file */
import { Uuid } from '@ftr/contracts/type/shared'

export class FetchCourtSystemAction {
  static readonly type = '[CourtSystems] Fetch Court System'

  constructor(
    public courtSystemId: Uuid,
    public bypassCache?: boolean,
  ) {}
}

export class FetchCourtSystemPersonalizationAction {
  static readonly type = '[CourtSystems] Fetch Personalization'

  constructor(
    public courtSystemId: Uuid,
    public bypassCache?: boolean,
  ) {}
}
