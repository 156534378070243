<nav
  class="dropdown-menu"
  [attr.aria-label]="ariaLabel"
  [attr.aria-expanded]="isShowing"
  (clickOutside)="close($event)"
  [openState]="isShowing"
  [invalidTargetNames]="invalidTargetNames"
>
  <div class="dropdown-menu__opener" [class.dropdown-menu__opener--showing]="isShowing" [tabIndex]="0">
    <ng-container *ngTemplateOutlet="openerTemplate"></ng-container>
  </div>
  <div
    class="dropdown-menu__menu"
    [class.dropdown-menu__menu--showing]="isShowing"
    [class.dropdown-menu__menu--showing--left]="isLeftPositioned"
  >
    <ng-container *ngTemplateOutlet="menuTemplate"></ng-container>
    @if (!menuTemplate) {
      @if (menuItems | async; as items) {
        <ftr-dropdown-menu-items
          (hide)="close()"
          [menuItems]="items"
          [routerLinkActiveExact]="routerLinkActiveExact"
        ></ftr-dropdown-menu-items>
      }
    }
  </div>
</nav>
