import { Pipe, PipeTransform } from '@angular/core'
import { LogoTheme } from '../../types/logo'

@Pipe({
  name: 'themedLogoPath',
  standalone: true,
})
export class ThemedLogoPathPipe implements PipeTransform {
  transform(value: LogoTheme): string {
    return `../../../assets/images/logo-${value}.svg`
  }
}
