import { AfterViewInit, Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core'
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { RemoteData } from '@ftr/foundation'
import { Observable, ReplaySubject } from 'rxjs'
import { StripeService } from '../../services'

// See https://www.typescriptlang.org/docs/handbook/triple-slash-directives.html
/// <reference types="stripe-v3" />

export type PaymentChangeHandler = (card: Card | undefined) => void

export interface Card {
  stripe: stripe.Stripe
  element: stripe.elements.Element

  /**
   * From Stripe's ElementChangeResponse type
   */
  value?: { postalCode: string | number } | string
}

@Component({
  selector: 'ftr-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PaymentComponent),
      multi: true,
    },
  ],
})
export class PaymentComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  @Input() control: AbstractControl
  @Input() paymentApiKey: string
  @Input() submitAttempted = false
  @Input() highlight: Observable<boolean>
  @Input() autoFocus = false
  @ViewChild('cardContainer') cardContainer: ElementRef

  cardSubject = new ReplaySubject<RemoteData<Card, stripe.Error | Error>>(1)
  card = this.cardSubject.asObservable()
  touched = false
  empty = true
  complete = false

  private changeHandler?: PaymentChangeHandler
  private stripe: stripe.Stripe
  private stripePromise: Promise<stripe.Stripe>
  private elements: stripe.elements.Elements
  private cardElement: stripe.elements.Element

  private elementsOptions: stripe.elements.ElementsCreateOptions = {
    fonts: [
      {
        cssSrc: 'https://use.typekit.net/kbn7vze.css',
      } as stripe.elements.Font,
    ],
  }

  private inputOptions: stripe.elements.ElementsOptions = {
    style: {
      base: {
        fontFamily: 'proxima-nova, Avenir, sans-serif',
        fontSize: '16px',
        color: '#424242',
        '::placeholder': {
          color: '#707174',
        },
      },
    },
    classes: {
      focus: 'focus',
      empty: 'empty',
      invalid: 'invalid',
      complete: 'complete',
    },
  }

  constructor(private readonly stripeService: StripeService) {}

  async ngOnInit(): Promise<void> {
    this.cardSubject.next(RemoteData.notAsked())
    this.stripePromise = this.stripeService.create(this.paymentApiKey)
    this.stripe = await this.stripePromise
    this.setupCardEvents()
  }

  setupCardEvents(): void {
    this.elements = this.stripe.elements(this.elementsOptions)
    this.cardElement = this.elements.create('card', this.inputOptions)
    this.cardElement.on('change', event => this.handleChange(event!))
    this.cardElement.on('blur', () => this.handleBlur())
    if (this.autoFocus) {
      this.cardElement.on('ready', () => this.cardElement.focus())
    }
  }

  async ngAfterViewInit(): Promise<void> {
    await this.stripePromise
    this.cardElement.mount(this.cardContainer.nativeElement)
  }

  writeValue(card?: Card): void {
    if (card) {
      this.cardSubject.next(RemoteData.success(card))
    } else {
      this.cardSubject.next(RemoteData.notAsked())
    }
  }

  registerOnChange(fn: PaymentChangeHandler): void {
    this.changeHandler = fn
  }

  registerOnTouched(_: Function): void {
    // No-op: if (touch) event support is required, this will need to be implemented
  }

  handleBlur(): void {
    this.touched = true
  }

  async handleChange(event: stripe.elements.ElementChangeResponse): Promise<void> {
    this.empty = event.empty
    this.complete = event.complete
    this.cardSubject.next(RemoteData.notAsked())
    if (event.error) {
      this.cardSubject.next(RemoteData.failure(event.error))
      this.notifyChanges(undefined)
    } else if (event.empty) {
      this.notifyChanges(undefined)
    } else if (event.complete) {
      this.touched = true
      const card: Card = {
        stripe: this.stripe,
        element: this.cardElement,
        value: event.value,
      }
      this.cardSubject.next(RemoteData.success(card))
      this.notifyChanges(card)
    } else {
      this.notifyChanges(undefined)
    }
  }

  private notifyChanges(card: Card | undefined): void {
    if (this.changeHandler) {
      this.changeHandler(card)
    }
  }
}
