import { Injectable } from '@angular/core'
import { ApiClient, ApiClientFactory, HttpResponseType } from '@ftr/api-shared'
import { CalculateFromPriceBody, CreateQuoteBody, GeneratePDFQuoteBody, Quote } from '@ftr/contracts/api/quote'
import { Money } from '@ftr/contracts/type/order'
import { ApiResult, collectData, mapData, mapFailure } from '@ftr/foundation'
import { classToPlain } from '@ftr/serialization'
import * as bignumber from 'bignumber.js'
import { Observable } from 'rxjs'

@Injectable()
export class QuoteService {
  private readonly apiClient: ApiClient

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClient = apiClientFactory.build('/quote')
  }

  createQuote(params: CreateQuoteBody): ApiResult<Quote> {
    return this.apiClient.post<Quote>({ body: classToPlain(params) }).pipe(mapData(data => parseQuoteEntity(data)))
  }

  createQuoteObservable(params: CreateQuoteBody): Observable<Quote> {
    return this.createQuote(params).pipe(collectData(d => d))
  }

  createQuoteFromPrice(params: CalculateFromPriceBody): ApiResult<Quote> {
    return this.apiClient
      .post<Quote>({ path: '/calculateFromPrice', body: classToPlain(params) })
      .pipe(mapData(data => parseQuoteEntity(data)))
  }

  downloadPDF(
    courtSystemName: string,
    params: GeneratePDFQuoteBody,
  ): Observable<{ file: Blob; filename: string } | undefined> {
    return this.apiClient
      .post({ path: '/generatePDF', body: classToPlain(params), responseType: HttpResponseType.Blob })
      .pipe(
        mapFailure(error => {
          const errorDetails = error?.message || 'error details unavailable'
          return new Error(`Failed to generate estimate PDF: ${errorDetails}`)
        }),
        collectData(data => {
          const filename = `Order-Estimate-${courtSystemName}-${sanitiseCaseNumber(params.caseNumber)}.pdf`
          return { file: data as Blob, filename }
        }),
      )
  }
}

function parseQuoteEntity(quote: Quote): Quote {
  return {
    price: parseMoney(quote.price),
    serviceFee: parseMoney(quote.serviceFee),
    sttFee: quote.sttFee ? parseMoney(quote.sttFee) : new Money(0, quote.price.currency),
    paperCopyFee: quote.paperCopyFee ? parseMoney(quote.paperCopyFee) : undefined,
    totalPrice: parseMoney(quote.totalPrice),
    perMinuteFee: quote.perMinuteFee ? parseMoney(quote.perMinuteFee) : undefined,
  }
}

/**
 * We need this because money is not being serialised at the moment.
 * The Money type here is actually a serialized Money type.
 */
function parseMoney(price: Money): Money {
  return new Money(
    ['number', 'string'].includes(typeof price.amount) ? new bignumber.BigNumber(price.amount) : price.amount,
    price.currency,
  )
}

function sanitiseCaseNumber(caseNumber: string): string {
  const INVALID_CASE_NUMBER_REGEXP = /[^a-zA-Z0-9\-\_]+/g
  return caseNumber.replace(INVALID_CASE_NUMBER_REGEXP, '_')
}
