import { A11yModule } from '@angular/cdk/a11y'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { CheckboxComponent } from '@ftr/forms'
import {
  AnchorComponent,
  AnimatedRemoteComponent,
  ButtonComponent,
  ErrorRetryComponent,
  IconComponent,
  LineLoaderComponent,
  LoaderComponent,
  ModalComponent,
  NotificationModule,
  PageComponent,
} from '@ftr/foundation'
import { CourtSystemServiceModule, SharingRecordingsFeatureModule } from '@ftr/ui-court-system'
import { HelpModule } from '@ftr/ui-help'
import { MfaRecoverAccountComponent } from '~app/features/multifactor-authentication/recover-account/mfa-recover-account.component'
import { RecoveryCodeModalContentComponent } from '~app/features/multifactor-authentication/recovery-code-modal/modal-content/recovery-code-modal-content.component'
import { MfaRequirementFormComponent } from '~app/features/multifactor-authentication/requirement/mfa-requirement-form.component'
import { MfaRequirementComponent } from '~app/features/multifactor-authentication/requirement/mfa-requirement.component'
import { FtrFormsModule } from '~app/shared/ftr-forms.module'
import { MfaChallengeComponent } from './challenge/mfa-challenge.component'
import { RecoveryCodeModalComponent } from './recovery-code-modal/recovery-code-modal.component'
import { MfaSetupRequiredComponent } from './setup-required/mfa-setup-required.component'

@NgModule({
  declarations: [
    RecoveryCodeModalComponent,
    RecoveryCodeModalContentComponent,
    MfaRecoverAccountComponent,
    MfaChallengeComponent,
    MfaSetupRequiredComponent,
    MfaRequirementFormComponent,
    MfaRequirementComponent,
  ],
  imports: [
    CommonModule,
    ModalComponent,
    A11yModule,
    NotificationModule,
    FtrFormsModule,
    ButtonComponent,
    IconComponent,
    AnchorComponent,
    RouterModule,
    HelpModule,
    ModalComponent,
    PageComponent,
    LineLoaderComponent,
    LoaderComponent,
    SharingRecordingsFeatureModule,
    CourtSystemServiceModule,
    CheckboxComponent,
    AnimatedRemoteComponent,
    ErrorRetryComponent,
  ],
  exports: [
    RecoveryCodeModalComponent,
    MfaRecoverAccountComponent,
    MfaChallengeComponent,
    MfaRequirementFormComponent,
    MfaRequirementComponent,
    MfaSetupRequiredComponent,
  ],
})
export class MultiFactorAuthenticationModule {}
