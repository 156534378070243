import { AsyncPipe } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { RouterLink } from '@angular/router'
import { CourtSystem } from '@ftr/contracts/api/court-system'
import { StaticMenuItem } from '@ftr/menus'
import { UserState } from '@ftr/ui-user'
import { Store } from '@ngxs/store'
import { Observable } from 'rxjs'
import { FooterMenuItemsProvider } from '../../navigation'
import { LogoSize, LogoTheme } from '../../types/logo'
import { CourtFooterMenuItemsProvider } from '../court-footer-menu-items-provider/court-footer-menu-items.provider'
import { FooterHelpLinksComponent } from '../footer-help-links'
import { FtrLogoComponent } from '../ftr-logo'

enum ColumnStyles {
  Single = 'one_column',
  Double = 'two_columns',
}

@Component({
  selector: 'ftr-footer-admin',
  templateUrl: './footer-admin.component.html',
  styleUrls: ['./footer-admin.component.css'],
  standalone: true,
  imports: [FtrLogoComponent, RouterLink, AsyncPipe, FooterHelpLinksComponent],
})
export class FooterAdminComponent implements OnInit {
  columnLength = 6
  columnClass = ColumnStyles.Double
  readonly logoThemes = LogoTheme
  readonly logoSizes = LogoSize

  currentCourtSystem$: Observable<CourtSystem | undefined>

  courtMenuItems: Observable<StaticMenuItem[]>
  generalMenuItems: Observable<StaticMenuItem[]>

  constructor(
    private readonly courtNavItemProvider: CourtFooterMenuItemsProvider,
    private readonly generalNavItemProvider: FooterMenuItemsProvider,
    private readonly store: Store,
  ) {
    this.currentCourtSystem$ = this.store.select(UserState.currentCourtSystem)
  }

  ngOnInit(): void {
    this.courtMenuItems = this.courtNavItemProvider.provide()
    this.generalMenuItems = this.generalNavItemProvider.provide()
    this.courtMenuItems.subscribe(items => {
      this.columnClass = items.length <= this.columnLength ? ColumnStyles.Single : ColumnStyles.Double
    })
  }
}
