import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { RootParams } from '@ftr/api-shared'
import { IdentityProviderName } from '@ftr/contracts/type/account'
import { CoreParams } from '~app/pages/core.params'
import { ConfigurationService } from '~app/services/configuration/configuration.service'

@Component({
  template: `<ftr-login
    [returnUrl]="returnUrl"
    [identityProvider]="identityProvider"
    [codeChallenge]="codeChallenge"
    [state]="state"
    [showMockLogin]="showMockLogin"
  ></ftr-login>`,
})
export class LoginPage implements OnInit {
  returnUrl: string | undefined

  identityProvider: IdentityProviderName | undefined
  codeChallenge: string | undefined
  state: string | undefined

  showMockLogin: boolean

  constructor(
    private route: ActivatedRoute,
    private readonly configurationService: ConfigurationService,
  ) {}

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams[RootParams.ReturnUrl]

    this.identityProvider = this.route.snapshot.queryParams[RootParams.IdentityProvider]
    this.codeChallenge = this.route.snapshot.queryParams[RootParams.CodeChallenge]
    this.state = this.route.snapshot.queryParams[RootParams.State]

    this.showMockLogin =
      this.route.snapshot.queryParams[CoreParams.Mock] === 'true' && !!this.configurationService.mockAuthUrl
  }
}
