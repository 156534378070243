import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { SelectComponent, SelectItem } from '@ftr/forms'
import { LocalStorageService } from '@ftr/foundation'
import { isUUID } from 'class-validator'
import { Observable } from 'rxjs'

export const QUERY_PARAM_SEPARATOR = ','

/**
 * Encapsulates a single list filter.
 *
 * This component presents a select component with the provided <code>options</code>, emits events and updates the query
 * string appropriately when the filter is changed.
 *
 * Filter selections are also saved to local storage as the default option for the next time the page is visited.
 */
@Component({
  selector: 'ftr-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [SelectComponent],
})
export class FilterComponent<FilterType extends string> implements OnInit {
  /**
   * The default value of the filter if none has been set or is present in the URL
   */
  @Input()
  defaultValue: FilterType
  /**
   * The filter options available.
   *
   * *NOTE*: Filter values are assumed not to have hyphens (-) due to URL param (de)serialization.
   */
  @Input()
  options: Observable<SelectItem<FilterType>[]>
  /**
   * A page-unique, user-friendly key used to identify this filter in local storage and in the URL query string.
   */
  @Input()
  filterKey: string
  /**
   * A universally-unique page identifier used to prefix the local storage value key for this filter and prevent
   * conflicts.
   */
  @Input()
  pageIdentifier: string
  /**
   * When set to true the current value will not be saved or retrieved from localStorage. Use this when you need
   * a constant default value.
   */
  @Input()
  skipLocalStorage?: boolean
  /**
   * When set to true the drop down will display the actual default value, otherwise it will display 'Filter'.
   */
  @Input()
  showActualDefault?: boolean
  /**
   * A label for the singular version (defaults to 'a filter')
   */
  @Input()
  singular?: string
  /**
   * Emits events when the selected filter changes
   */
  // eslint-disable-next-line @angular-eslint/no-output-rename
  @Output('filterChanged') selectFilterEmitter = new EventEmitter<FilterType>()

  control = new UntypedFormControl()
  initialValue: FilterType

  private currentFilterValue: FilterType

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) {}

  private get localStorageKey(): string {
    return `${this.pageIdentifier}_${this.filterKey}`
  }

  private get queryStringKey(): string {
    return this.filterKey
  }

  async ngOnInit(): Promise<void> {
    this.initialValue = this.getInitialValue()
    this.control.setValue(this.initialValue)
    await this.setFilter(this.initialValue, true)
  }

  selectedItemFormatter(value: FilterType): string | null {
    return value === this.defaultValue && !this.showActualDefault ? 'Filter' : null
  }

  async onFilterSelectionChange(value: FilterType): Promise<void> {
    await this.setFilter(value)
  }

  private async setFilter(filter: FilterType, isInitialValue = false): Promise<void> {
    if (!this.skipLocalStorage) {
      this.localStorageService.set(this.localStorageKey, filter)
    }

    if (this.currentFilterValue === filter) {
      return
    }

    this.currentFilterValue = filter
    this.selectFilterEmitter.emit(filter)

    const queryParams = {
      ...this.route.snapshot.queryParams,
      [this.queryStringKey]: mapFilterValueToQueryStringValue(filter),
    }

    if (!isInitialValue) {
      queryParams.page = undefined
    }

    await this.router.navigate([], {
      replaceUrl: isInitialValue,
      relativeTo: this.route,
      queryParams,
    })
  }

  private getInitialValue(): FilterType {
    const valueFromLocalStorage = this.skipLocalStorage
      ? this.defaultValue
      : this.localStorageService.get<FilterType>(this.localStorageKey)

    return (
      mapQueryStringValueToFilterValue(this.route.snapshot.queryParamMap.get(this.queryStringKey)) ||
      valueFromLocalStorage ||
      this.defaultValue
    )
  }
}

export function mapFilterValueToQueryStringValue(filter: string): string {
  return isUUID(filter)
    ? filter
    : filter
        // To tell the difference between the actual hyphen and the one we replaced a space with,
        // we encode the real hyphen.
        .replace(/-/g, '%2d')
        // To tell the difference between the actual character and the one we use as a query param separator,
        // we encode the real character.
        .replace(new RegExp(QUERY_PARAM_SEPARATOR, 'g'), encodeURIComponent(QUERY_PARAM_SEPARATOR))
        .replace(/\s/g, '-')
}

export function mapQueryStringValueToFilterValue<FilterType extends string>(
  filter: string | undefined | null,
): FilterType | undefined {
  if (!filter) {
    return undefined
  }

  return (
    isUUID(filter)
      ? filter
      : filter
          .replace(/-/g, ' ')
          .replace(/%2d/g, '-')
          .replace(new RegExp(encodeURIComponent(QUERY_PARAM_SEPARATOR), 'g'), QUERY_PARAM_SEPARATOR)
  ) as FilterType
}
