import { HttpHeaders } from '@ftr/api-shared'

const EXPORT_TRUNCATED_FLAG = '[TRUNCATED]'

export function isTruncatedResponse(headers?: HttpHeaders): boolean {
  if (!headers) {
    return false
  }
  const contentDisposition = (headers['content-disposition'] as string) || ''
  return contentDisposition.includes(EXPORT_TRUNCATED_FLAG)
}

/**
 *
 * @param headers
 * @param baseFilename
 */
export function getExportFilename(headers: HttpHeaders, baseFilename: string): string {
  const basenameMatch = /(.+)\.csv/.exec(baseFilename)
  const isTruncated = isTruncatedResponse(headers)
  return isTruncated && basenameMatch ? `${basenameMatch[1]}-[TRUNCATED].csv` : baseFilename
}
