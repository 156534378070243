import { animate, state, style, transition, trigger } from '@angular/animations'
import { Component, NgZone, ViewEncapsulation } from '@angular/core'
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr'
import { ToastPayload, ToastType } from './toast.service'

@Component({
  selector: '[ftr-toast]',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.css'],
  animations: [
    trigger('flyInOut', [
      state('inactive', style({ opacity: 0 })),
      state('active', style({ opacity: 1 })),
      state('removed', style({ opacity: 0 })),
      transition('inactive => active', animate('300ms ease-in')),
      transition('active => removed', animate('300ms ease-in')),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class ToastComponent extends Toast {
  readonly toastType = ToastType

  // https://github.com/storybookjs/storybook/issues/23534#issuecomment-2010027034
  constructor(toastrService: ToastrService, toastPackage: ToastPackage, ngZone?: NgZone) {
    super(toastrService, toastPackage, ngZone)
  }

  get payload(): ToastPayload {
    return this.options.payload
  }
}
