import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { IllustrationComponent, NotificationModule, PageComponent } from '@ftr/foundation'
import { FtrFormsModule } from '~app/shared/ftr-forms.module'
import { ResetAttributesService } from './reset-attributes.service'
import { ResetSentComponent } from './reset-sent/reset-sent.component'
import { ResetVerifyComponent } from './reset-verify/reset-verify.component'
import { ResetComponent } from './reset/reset.component'

@NgModule({
  imports: [RouterModule, IllustrationComponent, PageComponent, FtrFormsModule, NotificationModule],
  declarations: [ResetComponent, ResetSentComponent, ResetVerifyComponent],
  providers: [ResetAttributesService],
  exports: [ResetComponent, ResetSentComponent, ResetVerifyComponent],
})
export class PasswordResetModule {}
