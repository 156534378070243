import { AbstractControl, ValidationErrors } from '@angular/forms'

const ERROR_MESSAGE = 'You must attach the required documents.'
export const ATTACHMENTS_REQUIRED_ERROR_STATE = { error: ERROR_MESSAGE }

export function attachmentsRequiredValidator(attachments: AbstractControl): ValidationErrors | null {
  if (!attachments.value.length) {
    return ATTACHMENTS_REQUIRED_ERROR_STATE
  }
  return null
}
