/* eslint-disable max-classes-per-file */
import { ComplexFilter, ComplexFilterData } from '../../lib/complex-filter'

export class UpdateComplexFilter {
  static readonly type = '[ComplexFilter] Update Complex Filter'
  constructor(
    public data: ComplexFilterData,
    public resetPage: boolean = true,
  ) {}
}

export class StartListeningToFilterChanges {
  static readonly type = '[ComplexFilter] Start Listening To Complex Filter Changes'

  constructor(public filters: ComplexFilter[]) {}
}

export class StopListeningToFilterChanges {
  static readonly type = '[ComplexFilter] Stop Listening To Complex Filter Changes'
}

export class ClearAllFilters {
  static readonly type = '[ComplexFilter] Clear All Filters'
}
