import { Injectable } from '@angular/core'
import { LocalStorageService as ngLocalStorageService } from 'ngx-localstorage'

/**
 * A local storage service that gets, sets and removes data from localStorage with a username prefix (if available).
 * It also supports "global" data without a username prefix, e.g. for unauthenticated users.
 * All data is prefixed with "ftr-uploads_".
 */
@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  // these are set by AuthenticationService to avoid a circular dependency
  currentUserId: string | undefined
  signupUsername: string | undefined

  constructor(private localStorageService: ngLocalStorageService) {}

  /**
   * If available, retrieve the current user or the user that has just signed up
   * @returns {string}
   */
  private get username(): string {
    if (this.currentUserId) {
      return `${this.currentUserId}.`
    } else if (this.signupUsername) {
      return `${this.signupUsername}.`
    } else {
      return ''
    }
  }

  /**
   * Retrieve the data from storage
   */
  get<T>(storageKey: string): T | undefined {
    const result = this.localStorageService.get(this.derivedKey(storageKey))
    if (result === 'undefined' || result === 'null') {
      return undefined
    }
    return result
  }

  /**
   * Retrieve the global data from storage
   */
  getGlobal<T>(storageKey: string): T | undefined {
    const result = this.localStorageService.get(storageKey)
    if (result === 'undefined' || result === 'null') {
      return undefined
    }
    return result
  }

  /**
   * Set data into storage
   *
   * Note: Instead of setting to undefined, just remove the key
   */
  set(storageKey: string, data: any): void {
    if (data !== undefined && data !== null) {
      this.localStorageService.set(this.derivedKey(storageKey), data)
    } else {
      this.localStorageService.remove(this.derivedKey(storageKey))
    }
  }

  /**
   * Set global data into storage
   *
   * Note: Instead of setting to undefined, just remove the key
   */
  setGlobal(storageKey: string, data: any): void {
    if (data !== undefined && data !== null) {
      this.localStorageService.set(storageKey, data)
    } else {
      this.localStorageService.remove(storageKey)
    }
  }

  /**
   * Remove the data from storage
   */
  remove(storageKey: string): void {
    this.localStorageService.remove(this.derivedKey(storageKey))
  }

  /**
   * Remove the global data from storage
   */
  removeGlobal(storageKey: string): void {
    this.localStorageService.remove(storageKey)
  }

  /**
   * Return a key composed of the username and storageKey
   * @returns {string} e.g. `ftr-uploads.some-user-uuid.recordings`
   */
  private derivedKey(storageKey: string): string {
    return `${this.username}${storageKey}`
  }
}

export function isQuotaExceeded(error: DOMException): boolean {
  return (
    error instanceof DOMException &&
    (error.code === 22 ||
      // Firefox
      error.code === 1014 ||
      // test name field too, because code might not be present
      // everything except Firefox
      error.name === 'QuotaExceededError' ||
      // Firefox
      error.name === 'NS_ERROR_DOM_QUOTA_REACHED')
  )
}
