import { Injectable, InjectionToken } from '@angular/core'
import { WebFeatureFlags } from '../feature-flags'
import { defaultFeatureFlagState } from '../store'

export const FEATURE_FLAG_OVERRIDES = new InjectionToken<FeatureFlagOverrides>('FEATURE_FLAG_OVERRIDES')

/**
 * Used to override web feature flags, designed for storybook
 */
@Injectable()
export class FeatureFlagOverrides {
  featureFlags: Partial<WebFeatureFlags>

  get allFlags(): WebFeatureFlags {
    return {
      ...defaultFeatureFlagState().featureFlags,
      ...this.featureFlags,
    }
  }
}
