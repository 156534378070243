<div class="overflow-menu" #overflowMenu>
  <ul class="main-nav-links">
    @for (menuItem of menuItems$ | async; track menuItem) {
      <li
        class="main-nav-links__item"
        [routerLinkActiveOptions]="{ exact: false }"
        [routerLink]="menuItem.path"
        [queryParams]="menuItem.queryParams"
        routerLinkActive="main-nav-links__item--active"
        @fadeAnimation
        tabindex="-1"
      >
        <a
          [routerLink]="menuItem.path"
          [queryParams]="menuItem.queryParams"
          tabindex="0"
          class="main-nav-links__link"
          data-track
        >
          {{ menuItem.title }}
        </a>
      </li>
    }
    @if ((dropdownItems$ | async)?.length) {
      <li class="main-nav-links__item main-nav-links__item--not-clickable" @fadeAnimation>
        <div class="overflow-more">
          <ng-template #openerTemplate>
            <div (click)="toggle()" class="overflow-menu">
              <span data-track>{{ !(menuItems$ | async)?.length ? 'Menu' : 'More' }}</span>
              <ftr-icon align="Bottom" icon="KeyboardArrowDown"></ftr-icon>
            </div>
          </ng-template>
          <ftr-dropdown-menu
            [ariaLabel]="!(menuItems$ | async)?.length ? 'Menu' : 'More'"
            [menuItems]="dropdownItems$"
            [openerTemplate]="openerTemplate"
            data-track-group="Main Menu Dropdown"
            [routerLinkActiveExact]="false"
          >
          </ftr-dropdown-menu>
        </div>
      </li>
    }
  </ul>
</div>
