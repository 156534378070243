import { convert, Duration, LocalDate, LocalDateTime, LocalTime, ZonedDateTime } from '@js-joda/core'
import moment from 'moment-timezone'
import { Inclusivity } from '../inclusivity'
import { Optional } from '../Optional'
import { toZonedDateTime } from './toJoda'

export class JsJodaUtils {
  static isDuring(
    start: LocalDateTime | undefined,
    end: LocalDateTime | undefined,
    time: LocalDateTime | undefined,
    toleranceMs: number = 0,
    inclusivity: Inclusivity = Inclusivity.Open,
  ): boolean {
    return Optional.exists3(start, end, time, (s, e, t) => {
      switch (inclusivity) {
        case Inclusivity.Open:
        default:
          return this.isSameOrBefore(s, t, toleranceMs) && this.isSameOrAfter(e, t, toleranceMs)
        case Inclusivity.OpenClosed:
          return this.isSameOrBefore(s, t, toleranceMs) && this.isAfterWithTolerance(e, t, toleranceMs)
        case Inclusivity.ClosedOpen:
          return this.isBeforeWithTolerance(s, t, toleranceMs) && this.isSameOrAfter(e, t, toleranceMs)
        case Inclusivity.Closed:
          return this.isBeforeWithTolerance(s, t, toleranceMs) && this.isAfterWithTolerance(e, t, toleranceMs)
      }
    })
  }

  static isBeforeWithTolerance(time1: LocalDateTime, time2: LocalDateTime, toleranceMs: number = 0): boolean {
    return time1.minusNanos(this.toNanos(toleranceMs)).isBefore(time2)
  }

  static isAfterWithTolerance(time1: LocalDateTime, time2: LocalDateTime, toleranceMs: number = 0): boolean {
    return time1.plusNanos(this.toNanos(toleranceMs)).isAfter(time2)
  }

  static isDuringOrBefore(start?: LocalDateTime, end?: LocalDateTime, time?: LocalDateTime): boolean {
    return Optional.exists3(start, end, time, (s, e, t) => this.isDuring(s, e, t) || this.isSameOrBefore(s, t))
  }

  // Similar to how momentjs does the same thing
  static isSameOrBefore(time1?: LocalDateTime, time2?: LocalDateTime, toleranceMs: number = 0): boolean {
    return Optional.exists2(time1, time2, (t1, t2) => {
      const toCheck = toleranceMs === 0 ? t1 : t1.minusNanos(this.toNanos(toleranceMs))
      return toCheck.isEqual(t2) || toCheck.isBefore(t2)
    })
  }

  static isSameWithinTolerance(time1?: LocalDateTime, time2?: LocalDateTime, toleranceMs: number = 0): boolean {
    return Optional.exists2(time1, time2, (t1, t2) => {
      return this.isDuring(t1, t1, t2, toleranceMs)
    })
  }

  static isSameOrAfter(time1?: LocalDateTime, time2?: LocalDateTime, toleranceMs: number = 0): boolean {
    return Optional.exists2(time1, time2, (t1, t2) => {
      const toCheck = toleranceMs === 0 ? t1 : t1.plusNanos(this.toNanos(toleranceMs))
      return toCheck.isEqual(t2) || toCheck.isAfter(t2)
    })
  }

  static amOrPm(dateTime: LocalDateTime | LocalTime): 'AM' | 'PM' {
    return dateTime.hour() >= 12 ? 'PM' : 'AM'
  }

  /**
   * Implements LocalDateTime.atZone(zoneId), working around web not having joda-timezone.
   */
  static localDateTimeToZonedDateTime(datetime: LocalDateTime, timezone: string): ZonedDateTime {
    return toZonedDateTime(moment(convert(datetime).toDate()).tz(timezone, true))
  }

  static fractionalSecondsBetween(a: LocalDateTime | LocalTime, b: LocalDateTime | LocalTime): number {
    return Duration.between(a, b).toMillis() / 1000
  }

  static secondsToLocalDateTime(date: LocalDate, seconds: number): LocalDateTime {
    return date.atTime(this.secondsToLocalTime(seconds))
  }

  static secondsToLocalTime(seconds: number): LocalTime {
    return LocalTime.ofSecondOfDay(seconds)
  }

  /**
   * Includes both 86_399 and 86_400
   */
  static isLastTwoSecondsOfDay(seconds: number): boolean {
    return seconds >= LocalTime.MAX.toSecondOfDay()
  }

  private static toNanos(ms: number): number {
    return ms * 1000000
  }
}
