<ftr-page [pageStyle]="pageStyleNarrow">
  <div class="court-system-forbidden">
    <div class="court-system-forbidden__container">
      <ftr-illustration [illustration]="illustration.AccessDenied"></ftr-illustration>
      <h3>Court System Access Denied</h3>
      @switch (courtSystemForbiddenType) {
        @case (courtSystemForbiddenTypes.UseConditionsNotAccepted) {
          <p>
            Please accept the <a [routerLink]="[acceptUseConditionsPath]">terms and conditions</a> <br />in order to
            access court system data.
          </p>
        }
        @case (courtSystemForbiddenTypes.MfaNotEnabled) {
          <p>
            Please enable or log in with an account that has <br />
            Multifactor Authentication enabled to access court system data.
          </p>
        }
      }
    </div>
  </div>
</ftr-page>
