import { Injectable } from '@angular/core'
import { ApiClient, ApiClientFactory, serializeHttpParams } from '@ftr/api-shared'
import { AudioOrderingFeature, UpdateAudioOrderingFeatureBody } from '@ftr/contracts/api/audio-ordering-feature'
import { CourtSystemIdQuery } from '@ftr/contracts/api/shared'
import { Uuid } from '@ftr/contracts/type/shared'
import { ApiResult, collectData, createAndAssign } from '@ftr/foundation'
import { classToPlain } from '@ftr/serialization'
import { Observable } from 'rxjs'
import { FeatureService } from './feature.service'

@Injectable({
  providedIn: 'root',
})
export class AudioOrderingFeatureService extends FeatureService<AudioOrderingFeature> {
  private readonly apiClient: ApiClient

  constructor(private apiClientFactory: ApiClientFactory) {
    super()
    this.apiClient = this.apiClientFactory.build('/audio-ordering-feature')
  }

  isSttOrderingEnabled(courtSystemId: Uuid): Observable<boolean> {
    return this.getForCourtSystem(courtSystemId).pipe(collectData(d => d.isEnabled && d.sttEnabled))
  }

  isSharingEnabled(courtSystemId: Uuid): Observable<boolean> {
    return this.getForCourtSystem(courtSystemId).pipe(collectData(d => d.isEnabled && d.sharingEnabled))
  }

  /**
   * Retrieve the audio ordering feature for this court system.
   */
  getForCourtSystem(courtSystemId: Uuid): ApiResult<AudioOrderingFeature> {
    return this.apiClient.get<AudioOrderingFeature>({
      path: '/getForCourtSystem',
      params: serializeHttpParams(createAndAssign(CourtSystemIdQuery, { courtSystemId })),
    })
  }

  updateFeature(featureId: Uuid, body: UpdateAudioOrderingFeatureBody): ApiResult<AudioOrderingFeature> {
    return this.apiClient.patch({
      path: `/${featureId}`,
      body: classToPlain(body),
    })
  }
}
