import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { DividerComponent, LoaderComponent, NotificationModule, PageComponent } from '@ftr/foundation'
import { AppLoaderComponent } from '~app/core/app-loader/app-loader.component'
import { SingleSignOnModule } from '~app/features/single-sign-on/single-sign-on.module'
import { FtrFormsModule } from '~app/shared/ftr-forms.module'
import { LoginFormComponent } from './login-form/login-form.component'
import { LoginComponent } from './login/login.component'
import { LogoutComponent } from './logout/logout.component'

@NgModule({
  imports: [
    RouterModule,
    SingleSignOnModule,
    DividerComponent,
    PageComponent,
    LoaderComponent,
    NotificationModule,
    FtrFormsModule,
    AppLoaderComponent,
  ],
  declarations: [LoginComponent, LoginFormComponent, LogoutComponent],
  exports: [LoginComponent, LogoutComponent],
})
export class LoginModule {}
