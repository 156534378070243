import { AsyncPipe } from '@angular/common'
import { Component, OnInit, ViewChild } from '@angular/core'
import { RouterLink } from '@angular/router'
import { RegisteredUser } from '@ftr/contracts/api/user'
import { DestroySubscribers, IconComponent, ScreenSize, ScreenSizeService, isMobileScreenSize } from '@ftr/foundation'
import { DropdownMenuComponent, StaticMenuItem } from '@ftr/menus'
import { SideNavComponent, SideNavModule } from '@ftr/ui-core'
import { UserState } from '@ftr/ui-user'
import { Store } from '@ngxs/store'
import { Observable, filter, map, takeUntil } from 'rxjs'
import { AccountMenuItemsProvider } from '~app/services/navigation/account-menu-items.provider'

@Component({
  selector: 'ftr-account-menu',
  templateUrl: './account-menu.component.html',
  styleUrls: ['./account-menu.component.css'],
  standalone: true,
  imports: [IconComponent, AsyncPipe, DropdownMenuComponent, SideNavModule, RouterLink],
})
export class AccountMenuComponent extends DestroySubscribers implements OnInit {
  @ViewChild(SideNavComponent) sideNav: SideNavComponent
  @ViewChild(DropdownMenuComponent) dropdown: DropdownMenuComponent

  private currentScreenSize: ScreenSize
  userFullName$: Observable<string>
  accountMenuItems$: Observable<StaticMenuItem[]>

  constructor(
    private readonly screenSizeService: ScreenSizeService,
    private readonly accountMenuItemsProvider: AccountMenuItemsProvider,
    private readonly store: Store,
  ) {
    super()
  }

  ngOnInit(): void {
    this.accountMenuItems$ = this.accountMenuItemsProvider.provide()
    this.observeCurrentUser()
    this.watchScreenSize()
  }

  get isXLarge(): boolean {
    return this.currentScreenSize === ScreenSize.XLarge
  }

  get isMobile(): boolean {
    return isMobileScreenSize(this.currentScreenSize)
  }

  toggle(): void {
    if (this.isMobile) {
      this.sideNav.toggle()
    } else {
      this.dropdown.toggle()
    }
  }

  private watchScreenSize(): void {
    this.screenSizeService.size.pipe(takeUntil(this.finalize)).subscribe(size => {
      this.currentScreenSize = size
    })
  }

  private observeCurrentUser(): void {
    this.userFullName$ = this.store.select(UserState.user).pipe(
      filter(userData => userData.isSuccess()),
      map(userData => {
        const currentUser = userData.data as RegisteredUser
        return `${currentUser.givenName} ${currentUser.familyName}`
      }),
    )
  }
}
