import { ELECTRON_DISPLAY_API_KEY } from '@ftr/contracts/type/courtroom-display'
import { ELECTRON_API_KEY } from '@ftr/contracts/type/desktop'

export function isInDesktopApp(): boolean {
  return Object.prototype.hasOwnProperty.call(window, ELECTRON_API_KEY)
}

export function isInDisplayApp(): boolean {
  return Object.prototype.hasOwnProperty.call(window, ELECTRON_DISPLAY_API_KEY)
}
