import { CommonModule } from '@angular/common'
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  computed,
  effect,
  input,
  model,
  output,
  signal,
  viewChild,
} from '@angular/core'
import { IconComponent } from '@ftr/foundation'

export type InlineInputPlaceholder = 'time' | string
export type InlineInputSize = 'default' | 'large' | 'extra-large'

@Component({
  selector: 'ftr-inline-input',
  templateUrl: './inline-input.component.html',
  styleUrl: './inline-input.component.css',
  standalone: true,
  imports: [CommonModule, IconComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineInputComponent {
  size = input<InlineInputSize>('default')
  value = model('')
  required = input(false)
  readonly = input(false)
  hasError = input(false)
  upperCase = input(false)
  unfocusedText = input<string>()
  multiline = input(false)
  maxLength = input<number>()
  maxLines = input<number>()
  blurOnEnter = input(true)

  // Inline forms require a placeholder because otherwise the input is not visible to the user
  placeholder = input.required<InlineInputPlaceholder, 'time' | string>({
    transform: value => (value === 'time' ? 'H:MM:SS AM' : value),
  })

  protected label = computed(() => this.placeholder())

  onFocus = output<Event>()
  onBlur = output<Event>()

  private inputElement = viewChild.required<ElementRef<HTMLInputElement>>('inputReference')

  private inputText = signal('')

  protected displayedText = computed(() => {
    const inputText = this.inputText()
    const isFocused = this.isFocused()
    const unfocusedText = this.unfocusedText()
    const hasError = this.hasError()

    if (!isFocused && unfocusedText && !hasError) {
      return unfocusedText
    }

    return inputText
  })

  protected widthExpanderText = computed(() => {
    const inputText = this.displayedText()

    // ensure there is at least one character in the last line of the input
    if (inputText.endsWith('\n')) {
      return inputText + ' '
    }

    return inputText || this.placeholder()
  })

  private isFocused = signal(false)

  constructor() {
    effect(
      () => {
        this.inputText.set(this.value())
      },
      { allowSignalWrites: true },
    )
  }

  focus(): void {
    setTimeout(() => this.inputElement()?.nativeElement.focus())
  }

  protected onInput($event: Event): void {
    this.inputText.set(($event.target as HTMLInputElement).value)
  }

  protected onChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement
    const value = this.upperCase() ? inputElement.value.toUpperCase() : inputElement.value
    this.value.set(value)
  }

  protected handleFocus(e: Event): void {
    this.onFocus.emit(e)
    this.isFocused.set(true)
  }

  protected handleBlur(e: Event): void {
    this.isFocused.set(false)
    if (this.multiline() && this.maxLines() !== undefined) {
      this.inputElement().nativeElement.scroll({ top: 0, behavior: 'instant' })
    }
    this.onBlur.emit(e)
  }

  protected handleKeyDown(e: KeyboardEvent): void {
    if (this.blurOnEnter() && e.key.toLowerCase() === 'enter' && !e.shiftKey) {
      this.inputElement()?.nativeElement.blur()
    }
  }
}
