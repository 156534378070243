/**
 * Format strings to use when displaying times to our users.
 */
export const TIME_FORMAT = Object.freeze({
  TWELVE_HOUR: {
    MINUTE_PRECISION: {
      /**
       * @example 9:10 PM
       */
      DEFAULT: 'h:mm A',
      /**
       * @example 9:10PM
       */
      NO_WHITESPACE: 'h:mmA',
      /**
       * @example 09:10 PM
       */
      LEADING_ZERO: 'hh:mm A',
    },
    SECOND_PRECISION: {
      /**
       * @example 9:10:33 PM
       */
      DEFAULT: 'h:mm:ss A',
      /**
       * @example 9:10:33PM
       */
      NO_WHITESPACE: 'h:mm:ssA',
    },
    MILLISECOND_PRECISION: {
      /**
       * @example 9:10:33:123 PM
       */
      DEFAULT: 'h:mm:ss:SSS A',
      /**
       * @example 9:10:33:123PM
       */
      NO_WHITESPACE: 'h:mm:ss:SSSA',
    },
  },
  TWENTY_FOUR_HOUR: {
    MINUTE_PRECISION: {
      /**
       * @example 21:10
       */
      DEFAULT: 'H:mm',
      /**
       * @example 09:10
       */
      PADDED: 'HH:mm',
    },
    SECOND_PRECISION: {
      /**
       * @example 21:10:33
       */
      DEFAULT: 'H:mm:ss',
    },
  },
})
