export enum Spacing {
  None = '0' /* 0px */,
  Eighth = '0.125rem' /* 2px */,
  Quarter = '0.25rem' /* 4px */,
  Half = '0.5rem' /* 8px */,
  ThreeQuarter = '0.75rem' /* 12px */,
  Regular = '1rem' /* 16px */,
  OneAndAHalf = '1.5rem' /* 24px */,
  Double = '2rem' /* 32px */,
  TwoAndAHalf = '2.5rem' /* 40px */,
}

export const Spacings = Object.values(Spacing)
