import { Primitive } from './ArrayUtils'

export class MapUtils {
  /**
   * Given a list of values and a function that can extract a key from a value, generate a Map
   *
   * The most common usage is making a Map with the keys as a property of an object
   *
   * @example:
   *
   * buildFromValues(courtSystems, courtSystem => courtSystem.uuid)
   *
   * This would be a map of court system uuid to court system
   *
   */
  static buildFromValues<K, V>(vs: readonly V[], f: (v: V) => K): Map<K, V> {
    return new Map(vs.map(v => [f(v), v] as const))
  }

  /**
   * Given a list of key and a function that can extract a value from a value, generate a Map
   *
   * The most common usage is calling some method to get the value.
   *
   * @example:
   *
   * buildFromKeys(courthouses, courthouse => courthouse.children.length)
   *
   * This would be a map of court system uuid to court system
   *
   */
  static buildFromKeys<K, V>(ks: K[], f: (k: K) => V): Map<K, V> {
    return new Map(ks.map(k => [k, f(k)] as const))
  }

  /**
   * Given
   * - A list of items
   * - A function that can extract a key from an item
   * - A function that can extract a value from an item
   * Generate a Map
   *
   */
  static buildFromItems<K, V, T>(ts: T[], fk: (t: T) => K, fv: (t: T) => V): Map<K, V> {
    return new Map(ts.map(v => [fk(v), fv(v)] as const))
  }

  /**
   * Apply f to all values in the map. Returning an updated map.
   *
   * eg.
   *
   * const map = Map([['A', 1], ['B', 2]])
   * MapUtils.mapValues(map, v => v + 1)
   *
   * Becomes
   *
   * Map([['A', 2], ['B', 3]])
   */
  static mapValues<K, V1, V2>(map: Map<K, V1>, f: (v: V1) => V2): Map<K, V2> {
    const entryArray = new Array(...map.entries())
    return new Map(entryArray.map(([k, v]) => [k, f(v)] as const))
  }

  /**
   * Remove values based on a predicate
   *
   * eg. (Filtering out even values)
   *
   * const map = Map([['A', 1], ['B', 2], ['C', 3], ['D', 4]])
   * MapUtils.filterValues(map, v => v % 2 !== 0)
   *
   * Becomes
   *
   * Map([['A', 1], ['C', 3]])
   */
  static filterValues<K, V>(map: Map<K, V>, f: (v: V) => boolean): Map<K, V> {
    const entryArray = new Array(...map.entries())
    return new Map(entryArray.filter(([_k, v]) => f(v)))
  }

  /**
   * Remove keys based on a predicate
   *
   * eg. (Filtering out keys that match a predicate)
   *
   * const map = Map([['Test', 1], ['All', 2], ['Things', 3]])
   * MapUtils.filterKeys(map, v => v.includes('T'))
   *
   * Becomes
   *
   * Map([['Test', 1], ['Things', 3]])
   */
  static filterKeys<K, V>(map: Map<K, V>, f: (k: K) => boolean): Map<K, V> {
    const entryArray = new Array(...map.entries())
    return new Map(entryArray.filter(([k, _v]) => f(k)))
  }

  /**
   * Same as array reduce, just works on maps.
   *
   * eg. (Making string pairs)
   *
   * const map = Map([['A', 1], ['B', 2]])
   *
   * MapUtils.reduce(map, (acc, [k, v]) => acc + `- ${k}: ${v} `, '')
   *
   * Becomes
   *
   * '- A: 1 - B: 2 '
   */
  static reduce<K, V, T>(map: Map<K, V>, f: (acc: T, v: [K, V]) => T, initial: T): T {
    const entryArray = new Array(...map.entries())
    return entryArray.reduce(f, initial)
  }

  /**
   * Turns a Map<K, Set<V>> into a Map<V, Set<K>>
   */
  static invertSetMultimap<K, V>(map: Map<K, Set<V>>): Map<V, Set<K>> {
    const outputMap = new Map()
    for (const [k, vs] of map.entries()) {
      for (const v of vs) {
        const values = outputMap.get(v) || new Set()
        values.add(k)
        outputMap.set(v, values)
      }
    }
    return outputMap
  }

  /**
   * Builds a map based on grouping function.
   *
   * example:
   * courtrooms.groupBy(courtroom => courtroom.courthouse?.id)
   *
   * becomes a map of CourthouseId => Courtroom
   *
   * Note: If we ever build a multimap this should return that instead
   */
  static buildGroupedFromValues<K, V>(vs: V[], f: (v: V) => K): Map<K, V[]> {
    const map = new Map<K, V[]>()
    vs.forEach(item => {
      const key = f(item)
      const collection = map.get(key)
      if (!collection) {
        map.set(key, [item])
      } else {
        collection.push(item)
      }
    })

    return map
  }

  /**
   * A SetMultimap is a map where the value is a set.
   *
   * Basically, each key has a Set of values, that can be updated.
   */
  static buildGroupedSetMultimap<Input, K, V>(
    vs: Input[],
    keyMapper: (v: Input) => K,
    valueMapper: (v: Input) => V,
  ): Map<K, Set<V>> {
    const map = new Map<K, Set<V>>()
    vs.forEach(item => {
      const key = keyMapper(item)
      const collection = map.get(key)
      if (!collection) {
        map.set(key, new Set([valueMapper(item)]))
      } else {
        map.set(key, new Set([valueMapper(item), ...collection]))
      }
    })

    return map
  }

  /**
   * Merges a bunch of maps together,
   * adding values to the appropriate keys, while retaining distinctness
   */
  static mergeSetMultiMaps<K, V>(...maps: Map<K, Set<V>>[]): Map<K, Set<V>> {
    const result = new Map<K, Set<V>>()
    for (const map of maps) {
      for (const [key, value] of map.entries()) {
        const current: Set<V> = result.get(key) || new Set()
        result.set(key, new Set([...value, ...current]))
      }
    }

    return result
  }

  /**
   * Kinda linke groupBy, except instead of creating a list, sums the values
   *
   * Eg. given a Map<string, number>
   *  Foo: 1
   *  Bar: 2
   *  For: 3
   *
   *  if the grouper is the first letter of the key, this would be the result
   *
   *  F: 4
   *  B: 2
   */
  static groupKeysAndSum<K, K2>(map: Map<K, number>, grouper: (k: K) => K2 | undefined): Map<K2, number> {
    return MapUtils.reduce(
      map,
      (acc, [k, v]) => {
        const newKey = grouper(k)
        if (newKey === undefined) {
          return acc
        }
        const current = acc.get(newKey) ?? 0
        return acc.set(newKey, current + v)
      },
      new Map<K2, number>(),
    )
  }

  /**
   * Similar to group by, except counts the number of matching items rather than returning a list of them
   */
  static buildCountsFromValues<K extends Primitive, V>(array: V[], grouper: (v: V) => K): Map<K, number> {
    const map = MapUtils.buildGroupedFromValues(array, grouper)
    return MapUtils.mapValues(map, x => x.length)
  }

  /**
   * Convert this map to an object
   */
  static toRecord<V>(map: Map<string, V>): Record<string, V> {
    return Object.fromEntries(map.entries())
  }

  static sum<K>(map: Map<K, number>): number {
    return MapUtils.reduce(map, (acc, [_k, v]) => acc + v, 0)
  }

  /**
   * Merges two maps with numeric values together,
   * when encountering the same key in both maps, it will sum the two values together
   *
   * Eg Given Map A:
   *  Foo: 1
   *  Bar: 2
   *
   * And Map B:
   *  Foo: 1
   *  Baz: 3
   *
   * The result will be
   *  Foo: 2
   *  Bar: 2
   *  Baz 3
   */
  static mergeAndSum<K>(mapA: Map<K, number>, mapB: Map<K, number>): Map<K, number> {
    const allKeys = new Set([...mapA.keys(), ...mapB.keys()])

    const keyList = [...allKeys]
    return new Map(
      keyList.map(key => {
        const mapACount = mapA.get(key) ?? 0
        const mapBCount = mapB.get(key) ?? 0
        return [key, mapACount + mapBCount] as const
      }),
    )
  }
}
