import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms'
import { CheckboxComponent, CheckboxItem, ValidationErrorHintDirective, isUntypedFormGroup } from '@ftr/forms'
import { ComposableDropdownModule, ComposableDropdownRenderMode, DestroySubscribers } from '@ftr/foundation'
import { Observable, takeUntil } from 'rxjs'

@Component({
  selector: 'ftr-checkbox-list',
  templateUrl: './checkbox-list.component.html',
  styleUrls: ['./checkbox-list.component.css'],
  standalone: true,
  imports: [CheckboxComponent, CommonModule, ComposableDropdownModule, ValidationErrorHintDirective],
})
export class CheckboxListComponent extends DestroySubscribers implements OnInit {
  /**
   * The items that will be rendered as checkboxes, including any pre-selected. ID must be unique.
   */
  @Input() items: CheckboxItem[]
  /**
   * How the composable component should render the content.
   */
  @Input() renderType: ComposableDropdownRenderMode = 'drop-down'
  /**
   * A label for this control
   */
  @Input() label: string
  /**
   * How to refer to a single item in the list
   */
  @Input() singular: string
  /**
   * Puts the component in a highlighted state with a larger border when true
   */
  @Input() highlightError: Observable<boolean>
  /**
   * Is the input required or optional (default optional)
   * Use this instead of setting Validators.required on the external control
   * @type {boolean}
   */
  @Input() required = false
  /**
   * Whether submission of the form this component is part of has been attempted. When submission is attempted, the
   * underlying validation control (i.e.: ValidationHint) may choose to display validation states for fields which have
   * not yet been touched.
   */
  @Input() submitAttempted = false
  /**
   * Subscribe your component to this event to get notified when the array of items on this select changes
   */
  @Output() onSelectionChange = new EventEmitter<CheckboxItem[]>()

  internalControl: UntypedFormGroup

  constructor(private formBuilder: UntypedFormBuilder) {
    super()
  }

  ngOnInit(): void {
    this.setupFormControls()
  }

  private setupFormControls(): void {
    this.internalControl = this.initializeFormControlGroup(this.items)
    this.internalControl.updateValueAndValidity()
  }

  protected initializeFormControlGroup(items: CheckboxItem[]): UntypedFormGroup {
    const group = this.formBuilder.group({})

    for (const item of items) {
      const control = new UntypedFormControl(item.selected)
      group.addControl(item.id, control)
      control.valueChanges.pipe(takeUntil(this.finalize)).subscribe(() => {
        this.emitItems()
      })
    }
    if (this.required) {
      group.setValidators(this.requireCheckboxesToBeCheckedValidator(1, this.singular))
    }

    return group
  }

  protected emitItems(): void {
    const items = this.items.map(
      item => new CheckboxItem(item.title, item.id, this.internalControl.controls[item.id].value),
    )
    this.onSelectionChange.emit(items)
  }

  get selectedLabels(): string {
    return this.items
      .filter(item => this.internalControl.controls[item.id]?.value)
      .map(({ title }) => title)
      .join(',')
  }

  getCheckboxAsFormControl(item: CheckboxItem): UntypedFormControl {
    return this.internalControl.controls[item.id] as UntypedFormControl
  }

  requireCheckboxesToBeCheckedValidator(minRequired = 1, singular = 'item'): ValidatorFn {
    return function validate(formGroup: AbstractControl) {
      if (!isUntypedFormGroup(formGroup)) return null
      const checked = Object.keys(formGroup.controls).filter(key => formGroup.controls[key].value).length

      if (checked < minRequired) {
        return {
          required: `You must select at least ${minRequired === 1 ? 'one' : minRequired} ${singular}.`,
        }
      }

      return null
    }
  }
}
