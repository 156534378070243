import { Component, Input, TemplateRef } from '@angular/core'

@Component({
  selector: 'ftr-help-link',
  template: `<a
    data-track
    data-track-group="Support"
    [className]="cls"
    [routerLink]="['./']"
    queryParamsHandling="preserve"
    [ngStyle]="{ 'text-decoration': underline ? 'underline' : 'none' }"
  >
    @if (template) {
      <ng-container *ngTemplateOutlet="template"></ng-container>
    } @else {
      {{ label }}
    }
  </a>`,
})
export class HelpLinkComponent {
  @Input() label: string

  /**
   * Optional template. If provided replaces the help link label
   */
  @Input() template: TemplateRef<unknown> | null = null
  /**
   * You have to set up a CSS Selector in Elevio and associate it with the article you wish to link.
   * @see https://app.elev.io/helpers
   */
  @Input() cls: string
  @Input() underline: boolean = true
}
