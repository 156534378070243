import { Component, computed, Input } from '@angular/core'
import { RouterModule } from '@angular/router'
import { Uuid } from '@ftr/contracts/type/shared'
import { AppPaths } from '@ftr/routing-paths'

@Component({
  selector: 'ftr-court-system-logo',
  templateUrl: './court-system-logo.component.html',
  styleUrls: ['./court-system-logo.component.css'],
  standalone: true,
  imports: [RouterModule],
})
export class CourtSystemLogoComponent {
  @Input() courtSystemId: Uuid
  @Input() imageUrl: string
  @Input() courtSystemName: string
  @Input() addLinkToCourtPage = true

  protected readonly courtSystemHomeUrl = computed(() => ['/', AppPaths.CourtSystem, this.courtSystemId])
}
