import { CommonModule } from '@angular/common'
import { Component, Input, TemplateRef, TrackByFunction } from '@angular/core'

/**
 * A bulleted list that is collapsed by default and has controls to expand and collapse it
 */
@Component({
  selector: 'ftr-collapsible-list',
  templateUrl: './collapsible-list.component.html',
  styleUrls: ['./collapsible-list.component.css'],
  standalone: true,
  imports: [CommonModule],
})
export class CollapsibleListComponent<T> {
  @Input() items: T[]
  @Input() itemTemplate: TemplateRef<unknown>
  @Input() numberOfItemsToShow: number = 4
  @Input() expandText: string = 'Show more'
  @Input() collapseText: string = 'Show less'
  @Input() trackBy: TrackByFunction<T>
  @Input() unstyled: boolean = false

  expanded = false

  toggleVisibility(event: Event): void {
    event.stopPropagation()
    this.expanded = !this.expanded
  }
}
