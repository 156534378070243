import { AudioSegmentPlaybackData } from '@ftr/contracts/api/audio-segment'
import { Timeframe, TimeframeWithLocalTimes } from '@ftr/contracts/api/shared'
import {
  Playability,
  RecordingSegment,
  RecordingSummary,
  RecordingWithSegments,
  isOnTheRecord,
} from '@ftr/contracts/read'
import { StreamRecordingSummary } from '@ftr/contracts/regional-api'
import { HearingPlaybackData } from '@ftr/contracts/regional-api/hearing'
import { SortedTimeframeCollection } from '@ftr/contracts/shared/timeframe'
import { Timecode } from '@ftr/contracts/type/shared'
import { Duration, LocalDate, LocalDateTime, ZoneId, ZonedDateTime, nativeJs } from '@js-joda/core'
import moment from 'moment-timezone'
import { findLatestSegment } from '../realtime'
import { RealTimeRecordingWithSegments } from '../types'

export function isTheRecorderRunning(segments: RecordingSegment[]): boolean {
  const latestSegment = findLatestSegment(segments)
  return latestSegment?.playability === Playability.Live
}

/**
 * The current second of the day in the given timezone, according to the local device's clock.
 */
export function nowAsSecondOfDay(timeZoneId: ZoneId): number {
  const now = moment().tz(timeZoneId.toString())
  const first = now.clone().startOf('day')
  return Math.floor(moment.duration(now.diff(first)).asSeconds())
}

/**
 * The current second of the day in the given timezone, according to the local device's clock including fractional seconds.
 */
export function nowAsFractionalSecondOfDay(timeZoneId: ZoneId): number {
  const now = moment().tz(timeZoneId.toString())
  const first = now.clone().startOf('day')
  return moment.duration(now.diff(first)).asSeconds()
}

export function anyTimeframeEndsAfter(timeframes: Timeframe[], secondOfDay: number): boolean {
  return timeframes.some(s => s.end > Math.ceil(secondOfDay))
}

/**
 * Whether this recording is currently live and on-record, potentially slightly delayed but certain.
 *
 * @param recording any of our common Recording contract types
 */
export function isRecordingLiveAndOnTheRecord(
  recording: RecordingSummary | RecordingWithSegments | StreamRecordingSummary | undefined,
): boolean {
  if (!recording?.id || !recording.onTheRecordTimeframes) {
    return false
  }
  return isOnTheRecordBySegments(recording.onTheRecordTimeframes ?? [], recording.segments ?? [])
}

export function getLatestSecondOfDay(segments: RecordingSegment[]): number | undefined {
  const latestSegment = findLatestSegment(segments)
  if (!latestSegment || !latestSegment.timecode) {
    return undefined
  }
  // segment end is updated about every 10s
  return latestSegment.timecode.end.toLocalTime().toSecondOfDay()
}

/**
 * A slightly delayed version of OnTheRecord when you do not have a court's timezone, tells you if the end of the
 * last segment is on the record, not if the current localtime is on the record
 */
export function isOnTheRecordBySegments(onTheRecordTimeframes: Timeframe[], segments: RecordingSegment[]): boolean {
  const latestSegment = findLatestSegment(segments)
  if (latestSegment?.playability !== Playability.Live) {
    return false
  }
  // segment end is updated about every 10s
  const latestSecondOfDay = getLatestSecondOfDay(segments)
  if (!latestSecondOfDay) {
    return false
  }

  return isOnTheRecord(onTheRecordTimeframes, latestSecondOfDay)
}

export function isOnTheRecordByDeviceClock(timeframes: Timeframe[], recordingTimeZoneId: ZoneId): boolean {
  return isOnTheRecord(timeframes, nowAsSecondOfDay(recordingTimeZoneId))
}

export function hasEqualSegments(segmentsA: RecordingSegment[], segmentsB: RecordingSegment[]): boolean {
  if (segmentsA.length !== segmentsB.length) {
    return false
  }
  return segmentsA.every((t, i) => isEqualTimecode(t.timecode, segmentsB[i].timecode))
}

function isEqualTimecode(timecodeA: Timecode | undefined, timecodeB: Timecode | undefined): boolean {
  if (!timecodeA || !timecodeB) {
    return false
  }

  return timecodeA.start.isEqual(timecodeB.start) && timecodeA.end.isEqual(timecodeB.end)
}

export function hasEqualTimeframes(onTheRecordTimeframesA: Timeframe[], onTheRecordTimeframesB: Timeframe[]): boolean {
  if (onTheRecordTimeframesA.length !== onTheRecordTimeframesB.length) {
    return false
  }
  return onTheRecordTimeframesA.every((t, i) => isEqualTimeframe(t, onTheRecordTimeframesB[i]))
}

function isEqualTimeframe(timeframeA: Timeframe, timeframeB: Timeframe): boolean {
  return timeframeA.start === timeframeB.start && timeframeA.end === timeframeB.end
}

export function timeframeWithLocalTimesToTimecodes(timeframes: TimeframeWithLocalTimes[], date: LocalDate): Timecode[] {
  return timeframes.map(tf => {
    const start = tf.start.atDate(date)
    const end = tf.end.atDate(date)
    return new Timecode(start, Duration.between(start, end).toMillis())
  })
}

export function deriveTimecodes(availableTimeframes: TimeframeWithLocalTimes[], hearingDate: LocalDate): Timecode[] {
  if (!availableTimeframes.length) {
    return []
  }

  return timeframeWithLocalTimesToTimecodes(availableTimeframes, hearingDate).sort((t1, t2) =>
    t1.start.compareTo(t2.start),
  )
}

export function deriveTimecodesForRecording({ availableTimeframes, date }: RealTimeRecordingWithSegments): Timecode[] {
  return deriveTimecodes(availableTimeframes, date)
}

export function deriveTimecodesForHearing({ availableTimeframes, recordingSegments }: HearingPlaybackData): Timecode[] {
  const date = recordingSegments.at(0)!.startDateTime.toLocalDate()
  return deriveTimecodes(availableTimeframes, date)
}

export function deriveTimecodesForAudioSegment({
  availableTimeframes,
  timecodes,
}: AudioSegmentPlaybackData): Timecode[] {
  if (!timecodes.length) {
    return []
  }

  const hearingDate = timecodes[0].start.toLocalDate()
  return deriveTimecodes(availableTimeframes, hearingDate)
}

export function getCurrentTimeInTimeZone(recordingTimeZoneId: ZoneId): LocalDateTime {
  // Get the current time in the recording timezone (but with UTC offset - Since LocalDateTime's don't have a timezone)
  const nowMoment = moment().tz(recordingTimeZoneId.toString()).utc(true)
  const zonedDateTime = nativeJs(nowMoment.toDate(), ZoneId.UTC)
  return zonedDateTime.toLocalDateTime()
}

export function isRecordingToday(segments: RecordingSegment[], recordingDate: LocalDate): boolean {
  const zoneId = segments[0]?.zoneId

  if (!zoneId) {
    return recordingDate.equals(LocalDate.now()) || recordingDate.equals(LocalDate.now().minusDays(1))
  }

  const nowInRecorderTimezone = ZonedDateTime.now().withZoneSameInstant(ZoneId.of(zoneId.id()))
  return recordingDate.equals(nowInRecorderTimezone.toLocalDate())
}

export function isAudioSegmentToday(audioSegment: AudioSegmentPlaybackData): boolean {
  const timecodeStart = audioSegment.timecodes[0]?.start
  if (!timecodeStart) {
    return false
  }
  return isRecordingToday(audioSegment.recordingSegments, timecodeStart.toLocalDate())
}

/**
 * Returns true if recording is live and the current client time is within the last sealed segments time range.
 *
 * Sealed segments are bounded by on-the-record session times, and can only span from past to current
 * timeframes (it is impossible to have a sealed segment in the future).
 *
 * TODO timeZoneId can become a mandatory parameter once all customers upgrade to recorder v 0.29, at the time of
 *  writing (Apr 2024) only one customer is left using an older version, we will also be forcing upgrades in a few weeks time
 */
export function isLiveSealing(
  segments: RecordingSegment[],
  sealedTimeframes: Timeframe[] = [],
  timeZoneId: ZoneId | undefined,
): boolean {
  if (!isTheRecorderRunning(segments) || !sealedTimeframes.length || !timeZoneId) {
    return false
  }

  const collection = new SortedTimeframeCollection(sealedTimeframes)
  const lastSealedSegment = collection.timeframes.at(-1)
  return lastSealedSegment ? nowAsFractionalSecondOfDay(timeZoneId) <= lastSealedSegment.end : false
}
