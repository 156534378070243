import { Injectable } from '@angular/core'
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { JUDGES_MAX_LENGTH, LANGUAGES_MAX_LENGTH, Order } from '@ftr/contracts/api/order'
import { Turnaround } from '@ftr/contracts/api/transcript-turnaround'
import { SavedCard } from '@ftr/contracts/read/order-account'
import { VocabularyTerms } from '@ftr/contracts/type/core'
import { OrderAttachment, OrderAttachmentType } from '@ftr/contracts/type/order'
import { momentValidator, setControlValidators, validateOptionalSingleLineText } from '@ftr/forms'
import { UploadStates } from '@ftr/ui-files'
import { TranscriptFields, buildTranscriptFields, extractHearingDateMomentFromSegments } from '@ftr/ui-ordering'
import { OrderReviewFieldNames } from '~app/features/order-form-shared/steps/order-review/order-review.component'
import { TranscriptAdditionalInformationFieldsData } from '~app/order-shared/transcript-additional-information-fields/transcript-additional-information-fields.component'
import { HearingFieldsData } from './hearing-fields/HearingFieldsData'
/**
 * Information fetched from the server in order to render the transcript order form
 */
export interface OrderFormData {
  isPaymentRequired: boolean
  paymentApiKey?: string
  transcriptTurnarounds: Turnaround[]
  savedCards: SavedCard[]
  terms: VocabularyTerms
  canConfigureDistributionList: boolean
}

@Injectable()
export class EditOrderService {
  constructor(private readonly formBuilder: UntypedFormBuilder) {}

  buildHearingFields(
    defaults: Partial<HearingFieldsData> = {},
    isDepartmentsEnabled = false,
    isCaseTypeEnabled = false,
  ): UntypedFormGroup {
    return buildHearingFields(defaults, this.formBuilder, isDepartmentsEnabled, isCaseTypeEnabled)
  }

  buildTranscriptFields(defaults: Partial<TranscriptFields> = {}, isPaperCopiesEnabled: boolean): UntypedFormGroup {
    return buildTranscriptFields(defaults, this.formBuilder, isPaperCopiesEnabled)
  }

  buildTranscriptAdditionalInformationFields(
    defaults: Partial<TranscriptAdditionalInformationFieldsData> = {},
  ): UntypedFormGroup {
    return buildTranscriptAdditionalInformationFields(defaults, this.formBuilder)
  }

  buildCostWaiverAttachmentsField(defaults: Order): UntypedFormControl {
    return this.formBuilder.control(buildAttachmentsWithType(defaults, OrderAttachmentType.CostWaiverDocument))
  }

  buildSupportingDocumentsAttachmentsField(defaults: Order): UntypedFormControl {
    return this.formBuilder.control(buildAttachmentsWithType(defaults, OrderAttachmentType.SupportingDocument))
  }
  /**
   * Setup the payment fields.
   * If payment is not required, this form group doesn't exist.
   */
  buildPaymentFields(savedCards: SavedCard[], saveCardByDefault: boolean): UntypedFormGroup {
    const firstCard = (savedCards || []).filter(card => card.isDefault)[0]
    const saveCreditOrDebitCard = saveCardByDefault ? { value: true, disabled: true } : []
    const formGroup = this.formBuilder.group({
      saveCreditOrDebitCard,
      cardId: [firstCard || undefined],
      newCard: [undefined],
    })

    if (savedCards && savedCards.length) {
      setControlValidators(formGroup.get(OrderReviewFieldNames.PaymentSavedCardId)!)
    } else {
      setControlValidators(formGroup.get(OrderReviewFieldNames.PaymentNewCard)!)
    }

    return formGroup
  }
}

export function buildHearingFields(
  defaults: Partial<HearingFieldsData> = {},
  formBuilder: UntypedFormBuilder,
  isDepartmentsEnabled = false,
  isCaseTypeEnabled = false,
): UntypedFormGroup {
  const departmentValue = isDepartmentsEnabled ? defaults.departmentId : undefined
  const departmentValidator = isDepartmentsEnabled ? Validators.required : undefined
  return formBuilder.group({
    hearingSegments: [(defaults && defaults.hearingSegments) || [], Validators.required],
    // hearing portions are only for transcript orders; validators are set up in the optional form component
    hearingPortions: [defaults && defaults.hearingPortions],
    hearingDate: [
      extractHearingDateMomentFromSegments(defaults.hearingSegments),
      [Validators.required, momentValidator],
    ],
    departmentId: [departmentValue || undefined, departmentValidator],
    courtroom: [defaults.location || undefined, Validators.required],
    caseNumber: [defaults.caseNumber || undefined, Validators.required],
    caseTitle: [defaults.caseTitle || undefined, Validators.required],
    caseType: [defaults.caseType || undefined, isCaseTypeEnabled ? Validators.required : undefined],
  })
}

export function buildTranscriptAdditionalInformationFields(
  defaults: Partial<TranscriptAdditionalInformationFieldsData> = {},
  formBuilder: UntypedFormBuilder,
): UntypedFormGroup {
  return formBuilder.group({
    judges: [defaults.judges || undefined, [Validators.maxLength(JUDGES_MAX_LENGTH), validateOptionalSingleLineText]],
    languages: [
      defaults.languages || undefined,
      [Validators.maxLength(LANGUAGES_MAX_LENGTH), validateOptionalSingleLineText],
    ],
    instructions: [defaults.instructions || undefined],
    appearances: [defaults.appearances || undefined],
  })
}

function buildAttachmentsWithType(defaults: Order, filterType: OrderAttachmentType): OrderAttachment[] {
  const attachments: OrderAttachment[] =
    defaults?.attachments
      ?.filter(a => a.type === filterType)
      .map(a => ({
        id: a.id,
        file: {
          name: a.name,
          size: a.byteSize,
        },
        status: UploadStates.Success,
        fileId: a.fileId,
        label: a.userId !== defaults.userId ? ' by admin' : '',
        props: {
          courtSystemId: defaults.courtSystemId,
        },
        type: a.type,
        orderId: a.orderId,
      })) || []
  return attachments
}
