import { UntypedFormGroup } from '@angular/forms'
import { ArrayUtils, isOfNonZeroLength } from '@ftr/foundation'
import { QUERY_PARAM_SEPARATOR, mapFilterValueToQueryStringValue, mapQueryStringValueToFilterValue } from '../list'
import { ComplexFilter, ComplexFilterData, ComplexFilterSection, ComplexFilterValue } from './ComplexFilter'
import { RadioListFilter } from './RadioListFilter'
import { getComplexDateFilterFormattedDate } from './date'

/**
 * Calculates the number of filter options currently applied
 * @param filters complex filter data to work with
 * @param excludedFilterKeys optional filter keys to exclude from the total amount
 * @param filterKeysCountAsOne optional filter keys to count an array value as one selected filter (e.g. date range)
 * @param filterKey an optional filter key to only include this one filter
 */
export function filtersApplied(
  filters: ComplexFilterData,
  excludedFilterKeys: string[] = [],
  filterKeysCountAsOne: string[] = [],
  filterKey?: string,
): number {
  if (!filters || Object.keys(filters).length === 0) {
    return 0
  }

  return (
    Object.entries(filters)
      .filter(([key]) => !excludedFilterKeys.includes(key))
      // Just this one filter if its filterKey is provided
      .filter(([key]) => (filterKey ? key === filterKey : true))
      .map(([key, value]) => [
        key,
        (filterKeysCountAsOne || []).includes(key) && Array.isArray(value) ? !!value?.length : value,
      ])
      .reduce((acc, [_, value]) => (acc += Array.isArray(value) ? value.length : value ? 1 : 0), 0)
  )
}

export function isEqualComplexFilterValue<T extends string = string>(
  one: ComplexFilterValue<T> | null,
  two: ComplexFilterValue<T> | null,
): boolean {
  if (Array.isArray(one) && Array.isArray(two)) {
    return ArrayUtils.shallowEquals(one, two)
  }

  return one === two
}

/**
 * If a multi-select filter has a limit on the number of selected options,
 * disable unselected form controls when the limit is reached.
 * Can't disable selected controls, we want the user to be able to unselect them.
 * Also re-enables form controls when the selection is under the limit.
 */
export function checkMaxSelectedOptions(
  formGroup: UntypedFormGroup,
  filterValue: string[],
  maxSelectedOptions: number | undefined,
): void {
  if (!maxSelectedOptions) {
    return
  }

  Object.keys(formGroup.controls).forEach(key => {
    const control = formGroup.controls[key]
    control.enable({ emitEvent: false })
    if (filterValue.length >= maxSelectedOptions && !control.value) {
      control.disable({ emitEvent: false })
    }
  })
}

/**
 * Sections and subsections are only required for component rendering,
 * we need a flat array of filters to work with
 */
export function getFilters(sections: ComplexFilterSection[]): ComplexFilter[] {
  return sections
    .map(s => s.subsections)
    .reduce((acc, val) => acc.concat(val))
    .map(s => s.filters)
    .reduce((acc, val) => acc.concat(val))
}

export function arrayToComplexFilterData(arr: Record<string, ComplexFilterValue>[]): ComplexFilterData {
  return arr.reduce((acc, val) => ({ ...acc, ...val }), {})
}

export function mapFilterValueToQSValue(value: ComplexFilterValue): ComplexFilterValue {
  if (!value) {
    return undefined
  }
  if (Array.isArray(value)) {
    const queryParamArray = value.map(v => mapFilterValueToQueryStringValue(v || '')).join(QUERY_PARAM_SEPARATOR)
    return queryParamArray.length ? queryParamArray : undefined
  }

  return mapFilterValueToQueryStringValue(value)
}

export function getFilterValue(filter: ComplexFilter, value: string | undefined): ComplexFilterValue {
  if (filter.type === 'date') {
    const queryDateValue = mapQueryStringValueToFilterValue(value)
    return queryDateValue ? getComplexDateFilterFormattedDate(queryDateValue) : undefined
  }

  const queryStringValueSplit = (value?.split(QUERY_PARAM_SEPARATOR) || []).map(q =>
    mapQueryStringValueToFilterValue(q),
  )

  if (filter.type === 'radio-list') {
    const values = [filter]
      .concat(filter instanceof RadioListFilter && filter.additionalFilters ? filter.additionalFilters : [])
      .map((q, i) => (queryStringValueSplit && queryStringValueSplit[i] ? queryStringValueSplit[i] : q.defaultValue))

    return values && values.length === 1 ? values[0] : values
  }

  if (!queryStringValueSplit) {
    return undefined
  }

  if (!Array.isArray(queryStringValueSplit)) {
    return queryStringValueSplit || filter.defaultValue
  }

  return queryStringValueSplit.filter(isOfNonZeroLength)
}
