import { Component, Input, ViewEncapsulation } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { Observable } from 'rxjs'

const PASSWORD_TYPE = 'password'
const TEXT_TYPE = 'text'

@Component({
  selector: 'ftr-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css'],
  host: {
    class: 'password',
  },
  encapsulation: ViewEncapsulation.None,
})
export class PasswordComponent {
  @Input() label = 'Password'
  @Input() autofocus = false
  @Input() ngFormControl: UntypedFormControl
  @Input() highlightError: Observable<boolean>
  @Input() hideValidationErrors: boolean = false
  @Input() autocomplete: 'current-password' | 'new-password'

  type = PASSWORD_TYPE

  toggleVisibility(): void {
    this.type = this.type === PASSWORD_TYPE ? TEXT_TYPE : PASSWORD_TYPE
  }
}
