// eslint-disable-next-line max-classes-per-file
import { UpcomingHearing } from '@ftr/annotations-contracts'
import { Uuid } from '@ftr/contracts/type/shared'

export class UpcomingHearingsListAccessForbidden {
  static readonly type = '[Upcoming Hearings List] Upcoming Hearings List Access Forbidden'
  constructor(readonly courtroomId: Uuid) {}
}

export class UpcomingHearingTriggered {
  static readonly type = '[Upcoming Hearings List] Upcoming Hearing Triggered'
  constructor(
    readonly upcomingHearing: UpcomingHearing,
    readonly triggerFunction: 'Add' | 'Start' | 'Stop',
  ) {}
}
