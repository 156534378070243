import { SelectItem } from '@ftr/forms'
import { Icon } from '@ftr/foundation'
import { Observable } from 'rxjs'
import { ComplexFilter } from './ComplexFilter'

export enum DateRadioListOptions {
  All = 'all',
  SelectDate = 'date',
  SelectDateRange = 'range',
}

export class DateRadioListFilter<T extends string = string> extends ComplexFilter {
  options: SelectItem<T>[]

  constructor(
    filterKey: string,
    collapsible: boolean,
    icon: Icon,
    options: SelectItem<T>[],
    title?: string | Observable<string>,
    defaultValue?: T,
    noClear?: boolean,
    isEnabled?: Observable<boolean>,
  ) {
    super('date-radio-list', filterKey, collapsible, icon, title, defaultValue, noClear, [], isEnabled)
    this.options = options
  }
}
