import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { RootParams } from '@ftr/api-shared'

@Component({
  template: '<ftr-external-login [authorizationCode]="authorizationCode" [state]="state"></ftr-external-login>',
})
export class ExternalLoginPage {
  authorizationCode: string
  state: string

  constructor(route: ActivatedRoute) {
    this.authorizationCode = route.snapshot.queryParamMap.get(RootParams.AuthorizationCode)!
    this.state = route.snapshot.queryParamMap.get(RootParams.State)!
  }
}
