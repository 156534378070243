<ul class="form-list payment-fields" [formGroup]="formGroup">
  <li>
    @if (savedCards.length > 0) {
      <ftr-saved-cards
        [control]="formGroup.controls['cardId']"
        [items]="savedCards"
        [highlight]="highlightErrors"
        [formGroup]="formGroup"
        [paymentApiKey]="paymentApiKey"
        (selectedSavedCard)="useSavedCard($event)"
      >
      </ftr-saved-cards>
    }
    @if (!savedCards.length) {
      <ftr-payment
        formControlName="newCard"
        [control]="formGroup.controls['newCard']"
        [paymentApiKey]="paymentApiKey"
        [highlight]="highlightErrors"
      ></ftr-payment>
    }
    @if (showSaveCreditOrDebitCard()) {
      <ftr-checkbox
        class="payment-fields__save-card-checkbox"
        label="Securely save card"
        name="saveCreditOrDebitCard"
        [control]="asFormControl(formGroup.controls['saveCreditOrDebitCard'])"
        [highlightError]="highlightErrors"
      >
      </ftr-checkbox>
    }
  </li>
</ul>
