import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { ButtonComponent, IconComponent } from '@ftr/foundation'
import { LaunchDarklyModule } from '@ftr/ui-feature-flags'
import { SingleSignOnComponent } from '~app/features/single-sign-on/single-sign-on.component'

@NgModule({
  imports: [ButtonComponent, CommonModule, IconComponent, LaunchDarklyModule, RouterModule],
  declarations: [SingleSignOnComponent],
  exports: [SingleSignOnComponent],
})
export class SingleSignOnModule {}
