import { CommonModule } from '@angular/common'
import { ModuleWithProviders, NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { DepartmentsServiceModule } from '@ftr/api-core'
import {
  BasicCheckboxComponent,
  CheckboxComponent,
  DatePickerModule,
  FormInputComponent,
  SelectComponent,
  SliderComponent,
  TimeRangeTextComponent,
} from '@ftr/forms'
import {
  ButtonComponent,
  CapitalizeFirstLetterPipe,
  DateComponent,
  IconComponent,
  IndefinitePipe,
  LineLoaderComponent,
  MarkdownDirective,
  NotificationModule,
} from '@ftr/foundation'
import { CollapsibleListComponent } from '@ftr/lists'
import { AppearancesComponent, AppearancesEditComponent } from '@ftr/ui-admin'
import { LaunchDarklyModule } from '@ftr/ui-feature-flags'
import { LocationSelectComponent } from '@ftr/ui-location'
import { MoneyModule } from '@ftr/ui-money'
import { OrderPricesService, OrderService, OrderStepComponent, TranscriptFieldsComponent } from '@ftr/ui-ordering'
import { TranscriptOrderAdditionalInformationComponent, TranscriptTurnaroundService } from '@ftr/ui-transcript'
import { OrderAttachmentsModule } from '~app/features/order-attachments/order-attachments.module'
import { OrderLocationService } from '~app/features/order-location/order-location.service'
import { OrderPlayLinkRangeComponent } from '~app/order-shared/play-link-range/order-play-link-range.component'
import { OrderSealedSegmentsComponent } from '~app/order-shared/sealed-segments/order-sealed-segments.component'
import { TranscriptAdditionalInformationFieldsComponent } from '~app/order-shared/transcript-additional-information-fields/transcript-additional-information-fields.component'
import { FtrFormsModule } from '~app/shared/ftr-forms.module'
import { HearingSegmentsModule } from '../features/hearing-segments/hearing-segments.module'
import { EditOrderService } from './edit-order.service'
import { HearingFieldsComponent } from './hearing-fields/hearing-fields.component'
import { OrderAudioListComponent } from './order-audio-list/order-audio-list.component'
import { OrderPaymentBreakdownComponent } from './order/order-payment-breakdown.component'
import { OrderTranscriptDueByComponent } from './order/order-transcript-due-by.component'
import { TranscriptOrderDetailsComponent } from './transcript-order-details/transcript-order-details.component'

/**
 * Shared module with components that are common to all views that process orders
 */
@NgModule({
  imports: [
    DatePickerModule,
    HearingSegmentsModule,
    DepartmentsServiceModule,
    AppearancesComponent,
    AppearancesEditComponent,
    OrderAttachmentsModule,
    RouterModule,
    IndefinitePipe,
    CapitalizeFirstLetterPipe,
    SliderComponent,
    FormInputComponent,
    FtrFormsModule,
    CommonModule,
    SelectComponent,
    NotificationModule,
    MoneyModule,
    LineLoaderComponent,
    ButtonComponent,
    IconComponent,
    TimeRangeTextComponent,
    DateComponent,
    LaunchDarklyModule,
    CheckboxComponent,
    BasicCheckboxComponent,
    MarkdownDirective,
    CollapsibleListComponent,
    LocationSelectComponent,
    OrderStepComponent,
  ],
  declarations: [
    TranscriptOrderAdditionalInformationComponent,
    HearingFieldsComponent,
    OrderPaymentBreakdownComponent,
    OrderTranscriptDueByComponent,
    TranscriptFieldsComponent,
    TranscriptOrderDetailsComponent,
    TranscriptAdditionalInformationFieldsComponent,
    OrderPlayLinkRangeComponent,
    OrderSealedSegmentsComponent,
    OrderAudioListComponent,
  ],
  providers: [EditOrderService, OrderService, OrderLocationService, TranscriptTurnaroundService],
  exports: [
    TranscriptOrderAdditionalInformationComponent,
    HearingFieldsComponent,
    OrderPaymentBreakdownComponent,
    OrderStepComponent,
    OrderTranscriptDueByComponent,
    TranscriptFieldsComponent,
    TranscriptOrderDetailsComponent,
    TranscriptAdditionalInformationFieldsComponent,
    OrderPlayLinkRangeComponent,
    OrderSealedSegmentsComponent,
    OrderAudioListComponent,
  ],
})
export class OrderSharedModule {
  static forRoot(): ModuleWithProviders<OrderSharedModule> {
    return {
      ngModule: OrderSharedModule,
      providers: [OrderPricesService],
    }
  }
}
