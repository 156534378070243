class CognitoValidationError {
  /**
   * Semicolon separated list of semi user-friendly error messages
   * @type {string}
   */
  message: string
  /**
   * String parameter indicating the error type (e.g.: 'InvalidParameterException')
   * @type {string}
   */
  code: string
  /**
   * String parameter indicating the error type (e.g.: 'InvalidParameterException')
   * @type {string}
   */
  name: string
}

export function isCognitoValidationError(obj: Object): obj is CognitoValidationError {
  return typeof obj === 'object' && 'message' in obj && 'code' in obj && 'name' in obj && !('statusCode' in obj)
}
