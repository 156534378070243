// eslint-disable-next-line max-classes-per-file
import { CommonModule } from '@angular/common'
import { Component, NgModule } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { ButtonComponent, IconComponent } from '@ftr/foundation'
import { Observable, of } from 'rxjs'
import { SelectComponent, SelectItem } from '../select'

/**
 * Note: This should really be in `@ftr/foundation` but its dependancy
 */
@Component({
  selector: 'ftr-design-system-overlay',
  templateUrl: './design-system-overlay.component.html',
  styleUrls: ['./design-system-overlay.component.css'],
})
export class DesignSystemOverlayComponent {
  readonly overlayForm: UntypedFormGroup

  readonly pageLayouts: Observable<SelectItem<string>[]> = of([
    { value: 'none', key: 'None' },
    { value: 'default', key: 'Default' },
    { value: 'narrow', key: 'Narrow' },
    { value: 'side-panel-sml', key: 'Small Side Panel' },
    { value: 'side-panel-lg', key: 'Large Side Panel' },
    { value: 'grid', key: 'Grid' },
    { value: 'recording-page', key: 'Recording Page' },
    { value: 'recording-page-video-not-expanded', key: 'Recording Page (Video not expanded)' },
    { value: 'fluid', key: 'Fluid' },
  ])

  readonly opacities: Observable<SelectItem<string>[]> = of([
    { key: '0', value: '0' },
    { key: '0.1', value: '0.1' },
    { key: '0.2', value: '0.2' },
    { key: '0.3', value: '0.3' },
    { key: '0.4', value: '0.4' },
    { key: '0.5', value: '0.5' },
    { key: '0.6', value: '0.6' },
    { key: '0.7', value: '0.7' },
    { key: '0.8', value: '0.8' },
    { key: '0.9', value: '0.9' },
    { key: '1', value: '1' },
  ])

  readonly pageLayoutsControlName = 'pageLayouts'
  readonly opacitiesControlName = 'opacities'

  showFormAndGrid = true

  constructor(readonly formBuilder: UntypedFormBuilder) {
    this.overlayForm = formBuilder.group({
      [this.pageLayoutsControlName]: [''],
      [this.opacitiesControlName]: [''],
    })
  }
}

/**
 * This must be in the same file as the lazily loaded component, and just to keep
 * the linter happy it is exported, but it shouldn't and doesn't need to be
 */
@NgModule({
  imports: [ButtonComponent, CommonModule, IconComponent, SelectComponent],
  declarations: [DesignSystemOverlayComponent],
})
export class DesignSystemOverlayModule {}
