import { HttpParams } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { ActivatedRoute, Params, Router } from '@angular/router'
import {
  AUTH_CONFIGURATION,
  AUTH_IDENTIFIER,
  AccountAlreadyVerified,
  AuthConfiguration,
  AuthEvent,
  AuthEventType,
  DEFAULT_LOGIN_ROUTE,
  IDENTITY_PROVIDER,
  LOGIN_STARTED,
  PasswordResetRequired,
  RETURN_TO_DESKTOP,
  RETURN_URL_KEY,
  RootParams,
  TokenStatus,
  UnauthorizedApiError,
  base64URLEncode,
  toSha256,
} from '@ftr/api-shared'
import { UserEventTypes } from '@ftr/audit-types'
import { EventOutcome } from '@ftr/contracts/api/audit-proxy'
import { CourtSystem } from '@ftr/contracts/api/court-system'
import { RegisterUserRequest, RegisterUserResponse, UserWithGlobalAdministratorRole } from '@ftr/contracts/api/user'
import { UserGroupPermissionId } from '@ftr/contracts/api/user-group'
import { DJ_COURT_SYSTEM_ID, DJ_KEY, DJ_REGION } from '@ftr/contracts/digital-justice'
import { IdentityProviderName } from '@ftr/contracts/type/account'
import { Uuid } from '@ftr/contracts/type/shared'
import {
  ApiResult,
  LocalStorageService,
  NotificationDisplayType,
  NotificationService,
  NotificationType,
  SimpleWindowRefService,
  tapData,
  unwrapData,
} from '@ftr/foundation'
import { AppPaths } from '@ftr/routing-paths'
import { LoggingService } from '@ftr/ui-observability'
import { Store } from '@ngxs/store'
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  catchError,
  distinctUntilChanged,
  interval,
  lastValueFrom,
  merge,
  mergeMap,
  of,
  publishReplay,
  refCount,
  startWith,
} from 'rxjs'
import { CURRENT_COURT_SYSTEM_KEY, GetCurrentUserGroupsAction, LogoutAction } from '../store'
import { AuditService } from './audit'
import { CognitoAuthenticationService } from './cognito-authentication'
import { DigitalJusticeAuthService } from './digital-justice-auth'
import { OAuthService } from './oauth'
import { UserPermissionService } from './permissions'
import { UnauthorizedEventsMonitorService } from './unauthorized-events-monitor'
import { UserService } from './user.service'

export enum SignOutReason {
  EXPIRED = 'expired',
  LOGOUT = 'logout',
  UNAUTHORIZED = 'unauthorized',
}

const INVALID_RETURN_URL_PATHS = [AppPaths.Login, AppPaths.Logout, AppPaths.MultiFactorAuthentication]

export const GLOBAL_ADMIN_URL_PREFIX = 'https://cloudplatformadmin'

/**
 * In most cases we only need to call refresh token once the token expires, but to prevent cookies
 * from going stale, we will need to refresh the tokens when their expiry time is less than this threshold.
 */

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  static authHeader = 'Auth'

  private static readonly LOGIN_CHECK_INTERVAL_MILLIS = 10000

  /**
   * Subscribers will immediately receive the most recent event (if available), plus an event on each subsequent
   * login/logout, either caused by user action or after a periodic check shows that the session has expired
   */
  authEvents: Observable<AuthEvent>

  /**
   * The `CookieAuthenticationService` subscribes to this subject and calls `updateApiCookie`
   * when the value changes to true.
   */
  tokenRefreshed = new Subject<boolean>()

  private authEventsSource: Subject<AuthEvent>
  private internalCurrentUser: UserWithGlobalAdministratorRole | undefined
  private internalSignupUsername: string | undefined
  private isStillLoggedIn = interval(AuthenticationService.LOGIN_CHECK_INTERVAL_MILLIS).pipe(
    startWith(-1),
    mergeMap(() => this.tokenStatus),
    catchError(async () => {
      await this.expireSession()
      return { isTokenValid: false } as TokenStatus
    }),
    mergeMap(tokenStatus => {
      this.tokenRefreshed.next(tokenStatus.isTokenValid && !!tokenStatus.isTokenNew)
      return tokenStatus.isTokenValid
        ? this.currentUser
          ? of(this.createLoginEvent())
          : EMPTY
        : of(new AuthEvent(AuthEventType.Logout))
    }),
  )

  constructor(
    private readonly cognitoAuthenticationService: CognitoAuthenticationService,
    private readonly userService: UserService,
    private readonly unauthorizedEventsMonitorService: UnauthorizedEventsMonitorService,
    private readonly loggingService: LoggingService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly notificationService: NotificationService,
    private readonly store: Store,
    private readonly oAuthService: OAuthService,
    private readonly localStorageService: LocalStorageService,
    @Inject(AUTH_CONFIGURATION) readonly configurationService: AuthConfiguration,
    private readonly windowRefService: SimpleWindowRefService,
    private readonly digitalJusticeAuthService: DigitalJusticeAuthService,
    private readonly auditService: AuditService,
    private readonly userPermissionService: UserPermissionService,
  ) {
    this.authEventsSource = new Subject<AuthEvent>()

    this.authEvents = merge(this.authEventsSource.asObservable(), this.isStillLoggedIn).pipe(
      distinctUntilChanged((a, b) => a.type === b.type),
      publishReplay(1),
      refCount(),
    )

    this.unauthorizedEventsMonitorService.unauthorizedErrors.pipe(distinctUntilChanged()).subscribe(() => {
      this.signOut(SignOutReason.UNAUTHORIZED)
    })

    this.authEvents.subscribe(event =>
      event.type === AuthEventType.Login && this.currentUser && this.currentUser.id
        ? (this.loggingService.username = this.currentUser && this.currentUser.id)
        : (this.loggingService.username = ''),
    )
  }

  get currentUser(): UserWithGlobalAdministratorRole | undefined {
    return this.internalCurrentUser
  }

  set currentUser(currentUser: UserWithGlobalAdministratorRole | undefined) {
    this.internalCurrentUser = currentUser

    // set for LocalStorageService to avoid a circular dependency
    this.localStorageService.currentUserId = currentUser?.id
  }

  get signupUsername(): string | undefined {
    return this.internalSignupUsername
  }

  set signupUsername(signupUsername: string | undefined) {
    this.internalSignupUsername = signupUsername

    // set for LocalStorageService to avoid a circular dependency
    this.localStorageService.signupUsername = signupUsername
  }

  get isLoggedIn(): Promise<boolean> {
    return this.tokenStatus.then(s => s.isTokenValid)
  }

  get multifactorAuthenticationEnabled(): Promise<boolean | undefined> {
    return this.isUsingCognitoAuth()
      ? this.cognitoAuthenticationService.getMultifactorAuthenticationEnabled()
      : Promise.resolve(this.oAuthService.getMultifactorAuthenticationEnabled())
  }

  get identityProvider(): IdentityProviderName | undefined {
    return this.localStorageService.getGlobal<IdentityProviderName>(IDENTITY_PROVIDER)
  }

  get tokenStatus(): Promise<TokenStatus> {
    return this.isUsingCognitoAuth()
      ? this.cognitoAuthenticationService.getTokenStatus()
      : this.oAuthService.getTokenStatus()
  }

  get currentJwtToken(): Promise<string> {
    return this.isUsingCognitoAuth()
      ? this.cognitoAuthenticationService.currentJwtToken
      : this.oAuthService.getIdToken()
  }

  async initialize(): Promise<void> {
    if (await this.isLoggedIn) {
      await this.postLogin()
    }
  }

  async login(email: string, password: string, metadata?: Record<string, string>): Promise<void> {
    try {
      this.localStorageService.setGlobal(LOGIN_STARTED, new Date().toISOString())
      this.localStorageService.setGlobal(IDENTITY_PROVIDER, IdentityProviderName.Cognito)
      await this.cognitoAuthenticationService.login(email, password, metadata)
      await this.postLogin()
    } catch (err) {
      this.authEventsSource.next(new AuthEvent(AuthEventType.Logout))
      throw err
    }
  }

  async loginWithRedirect(
    identityProvider: IdentityProviderName,
    returnUrl: string | undefined,
    codeChallenge?: string | undefined,
    state?: string | undefined,
    returnToDesktop: boolean = false,
  ): Promise<void> {
    if (returnToDesktop) {
      this.localStorageService.setGlobal(RETURN_TO_DESKTOP, true)
    } else {
      this.localStorageService.removeGlobal(RETURN_TO_DESKTOP)
    }

    this.localStorageService.setGlobal(LOGIN_STARTED, new Date().toISOString())
    this.localStorageService.setGlobal(IDENTITY_PROVIDER, identityProvider)

    if (returnUrl) {
      this.localStorageService.setGlobal(RETURN_URL_KEY, returnUrl)
    }

    await this.oAuthService.loginWithRedirect(identityProvider, codeChallenge, state)
  }

  async nativeLoginToBrowser(identityProvider: IdentityProviderName, returnUrl: string | undefined): Promise<void> {
    this.localStorageService.setGlobal(LOGIN_STARTED, new Date().toISOString())
    this.localStorageService.setGlobal(IDENTITY_PROVIDER, identityProvider)

    if (returnUrl) {
      this.localStorageService.setGlobal(RETURN_URL_KEY, returnUrl)
    }

    // Generate and store the nonces on the native app side
    const codeChallenge = this.oAuthService.generateCodeChallenge()
    const state = this.oAuthService.generateState()

    // Using target _blank on ftr-desktop invokes setWindowOpenHandler, which then calls shell.openExternal(url),
    //   this will make the OS open the users default browser (or what they have set as a handler for the https:// URI scheme)
    this.windowRefService.open(buildNativeLoginUrl(identityProvider, codeChallenge, state), '_blank')

    // Show the launching splash with the outgoing message (featuring the identity provider)
    await this.router.navigate([`/${AppPaths.DesktopLoginSplash}`], {
      queryParams: { identityProvider },
    })
  }

  async exchangeCodeForToken(identityProvider: IdentityProviderName, authCode: string, state?: string): Promise<void> {
    try {
      await this.oAuthService.exchangeCodeForToken(identityProvider, authCode, state)
      await this.postLogin()
    } catch (error) {
      if (!(error instanceof UnauthorizedApiError)) {
        this.loggingService.info({ message: 'Failed to exchange code for token', error })
        this.signOut(SignOutReason.UNAUTHORIZED)
      }
    }
  }

  async verifyAccount(userId: string, code: string): Promise<void> {
    try {
      await this.login(userId, code, { step: 'verifyAccount' })
    } catch (error) {
      if (error instanceof PasswordResetRequired) {
        return
      }
      throw error
    }

    this.loggingService.warn({
      message: 'Attempt to verify account with long-lived password',
      userId,
    })
    throw new AccountAlreadyVerified(userId)
  }

  signUp(request: RegisterUserRequest): ApiResult<RegisterUserResponse> {
    return this.userService.register(request).pipe(tapData(({ userId }) => (this.signupUsername = userId)))
  }

  resendCode(userId: string): ApiResult {
    return this.userService.resendConfirmation(userId)
  }

  async signOut(reason: SignOutReason): Promise<void> {
    const identityProvider = this.localStorageService.getGlobal<IdentityProviderName>(IDENTITY_PROVIDER)

    this.notificationService.clear()

    if (reason === SignOutReason.LOGOUT && (await this.isLoggedIn)) {
      await lastValueFrom(
        this.auditService.logEvent(UserEventTypes.USER_LOGGED_OUT, { outcome: new EventOutcome('SUCCESS') }),
      )
    }

    if (this.isUsingCognitoAuth(identityProvider)) {
      this.cognitoAuthenticationService.signOut()
    } else {
      this.oAuthService.logOut()
    }
    this.currentUser = undefined
    this.signupUsername = undefined
    this.localStorageService.removeGlobal(AUTH_IDENTIFIER)
    this.localStorageService.removeGlobal(LOGIN_STARTED)
    this.localStorageService.removeGlobal(IDENTITY_PROVIDER)
    this.authEventsSource.next(new AuthEvent(AuthEventType.Logout))
    this.store.dispatch(new LogoutAction())

    // Don't need to redirect if already in the login page
    if (this.router.url.startsWith(`/${AppPaths.Login}`)) {
      return
    }

    this.setDJLocalStorageItems()

    if (this.cognitoLogoutIsRequired(identityProvider)) {
      this.redirectToCognitoLogout(reason)
    } else {
      this.redirectToLoginPage(reason)
    }
  }

  async resetPasswordSendVerification(email: string): Promise<void> {
    await lastValueFrom(this.userService.sendResetPasswordInstructions(email))
  }

  resetPasswordVerification(email: string, verificationCode: string, newPassword: string): Promise<void> {
    return this.cognitoAuthenticationService.resetPasswordVerification(email, verificationCode, newPassword)
  }

  async completeNewPasswordChallenge(newPassword: string): Promise<void> {
    this.localStorageService.setGlobal(IDENTITY_PROVIDER, IdentityProviderName.Cognito)
    await this.cognitoAuthenticationService.completeNewPasswordChallenge(newPassword)
    await this.postLogin()
  }

  /**
   * Gets the current user's id from <code>currentUser</code> which is populated with the response
   * from `GET /user` called after a successful authentication <code>postLogin</code>.
   */
  getCurrentUserId(): string | undefined {
    return this.currentUser?.id
  }

  async refreshLogin(): Promise<void> {
    // Fake a log-out to clear any caches which might be waiting for the Logout event to be emitted.
    this.authEventsSource.next(new AuthEvent(AuthEventType.Logout))
    await this.initialize()
  }

  hasUserChanged(): boolean {
    if (!this.currentUser) {
      return true
    }
    const currentIdentifier = generateAuthIdentifier(this.currentUser.identityProvider, this.currentUser.email)
    const storedIdentifier = this.localStorageService.getGlobal<string>(AUTH_IDENTIFIER)
    return currentIdentifier !== storedIdentifier
  }

  private async postLogin(): Promise<void> {
    this.authEventsSource.next(new AuthEvent(AuthEventType.Authenticated))
    this.currentUser = await lastValueFrom(this.userService.getUserDetails().pipe(unwrapData()))
    this.setAuthIdentifier(this.currentUser!.identityProvider, this.currentUser!.email)
    this.authEventsSource.next(this.createLoginEvent())
  }

  private createLoginEvent(): AuthEvent {
    const event = new AuthEvent(AuthEventType.Login)
    event.user = this.currentUser
    return event
  }

  private async expireSession(): Promise<void> {
    this.loggingService.info({
      message: 'Users session has expired',
      user: this.currentUser,
    })
    this.signOut(SignOutReason.EXPIRED)
  }

  private isUsingCognitoAuth(
    identityProvider = this.localStorageService.getGlobal<IdentityProviderName>(IDENTITY_PROVIDER),
  ): boolean {
    return !identityProvider || identityProvider === IdentityProviderName.Cognito
  }

  private setAuthIdentifier(identityProvider: IdentityProviderName, email: string): void {
    const authIdentifier = generateAuthIdentifier(identityProvider, email)
    this.localStorageService.setGlobal(AUTH_IDENTIFIER, authIdentifier)
  }

  private cognitoLogoutIsRequired(identityProvider: IdentityProviderName | undefined): boolean {
    return (
      !identityProvider ||
      this.isUsingCognitoAuth(identityProvider) ||
      this.oAuthService.isUsingCognitoIntegration(identityProvider)
    )
  }

  private redirectToCognitoLogout(reason: SignOutReason): void {
    this.setNotificationMessage(reason, NotificationDisplayType.AfterExternalRedirect)

    if (reason !== SignOutReason.LOGOUT) {
      this.localStorageService.setGlobal(RETURN_URL_KEY, this.router.routerState.snapshot.url)
    }

    const queryParams = new HttpParams()
      .append('client_id', this.configurationService.userPool.getClientId())
      .append('logout_uri', `${this.windowRefService.getHostName()}/${AppPaths.Login}`)
    this.windowRefService.location().replace(`${this.configurationService.cognitoLogoutUrl}?${queryParams.toString()}`)
  }

  private redirectToLoginPage(reason: SignOutReason): void {
    this.setNotificationMessage(reason, NotificationDisplayType.AfterNextNavigation)

    if (reason === SignOutReason.LOGOUT) {
      this.windowRefService.location().replace(`/${AppPaths.Login}`)
    } else {
      this.router.navigate([`/${AppPaths.Login}`], {
        queryParams: { returnUrl: this.router.routerState.snapshot.url },
      })
    }
  }

  setNotificationMessage(reason: SignOutReason, displayType: NotificationDisplayType): void {
    if (reason === SignOutReason.EXPIRED) {
      this.notificationService.show(displayType, {
        type: NotificationType.Warn,
        message: 'Your session has expired. Please log in to continue.',
      })
    }

    if (reason === SignOutReason.UNAUTHORIZED) {
      this.notificationService.show(displayType, {
        type: NotificationType.Error,
        message: 'Your credentials could not be verified, please try again.',
      })
    }
  }

  private setDJLocalStorageItems(): void {
    // The library typings are incorrect, this is in fact a BehaviorSubject
    const region = (this.route.queryParams as BehaviorSubject<Params>)?.value[DJ_REGION]
    if (region) {
      this.localStorageService.setGlobal(DJ_REGION, region)
    }
  }

  /**
   * Performs clean up tasks after a successful login, including removing the RETURN_URL_KEY from local storage
   * and verifying the user's authentication for digital justice.
   * @returns {boolean} true if the user is authenticating for digital justice, false otherwise.
   */
  async postLoginProcess(): Promise<{
    result: boolean
    getValidReturnUrl: (returnUrl: string | undefined) => string
  }> {
    this.store.dispatch(new GetCurrentUserGroupsAction())
    this.localStorageService.removeGlobal(RETURN_URL_KEY)

    const currentIdentityProvider = this.localStorageService.getGlobal(IDENTITY_PROVIDER)
    if (currentIdentityProvider && currentIdentityProvider !== IdentityProviderName.Cognito) {
      await lastValueFrom(
        this.auditService.logEvent(UserEventTypes.USER_LOGGED_IN_SSO, { outcome: new EventOutcome('SUCCESS') }),
      )
    }
    this.authEventsSource.next(new AuthEvent(AuthEventType.LoginComplete))

    const courtSystemId = this.localStorageService.getGlobal<Uuid>(DJ_COURT_SYSTEM_ID)
    const djKey = this.localStorageService.getGlobal<Uuid>(DJ_KEY)
    const shouldRedirectToCourtHome = await this.shouldRedirectToCourtHome()

    if (courtSystemId && djKey) {
      this.digitalJusticeAuthService.handlePostKey(courtSystemId, djKey).subscribe()
      return { result: true, getValidReturnUrl: this.getValidReturnUrl.bind(this) }
    } else if (!shouldRedirectToCourtHome) {
      return { result: false, getValidReturnUrl: this.getValidReturnUrl.bind(this) }
    }
    return { result: false, getValidReturnUrl: this.getValidUserAwareReturnUrl.bind(this) }
  }

  async shouldRedirectToCourtHome(): Promise<boolean> {
    const courtSystem = this.localStorageService.get<CourtSystem | undefined>(CURRENT_COURT_SYSTEM_KEY)

    const hasOnlyTranscriberPermission = courtSystem
      ? await lastValueFrom(
          this.userPermissionService.hasOnlyPermissionInCourtSystem(
            courtSystem.id,
            UserGroupPermissionId.TranscribeRecordings,
          ),
        )
      : false

    const isGlobalAdminUrl = this.windowRefService.getHostName().startsWith(GLOBAL_ADMIN_URL_PREFIX)

    return !hasOnlyTranscriberPermission && !isGlobalAdminUrl
  }

  getValidUserAwareReturnUrl(returnUrl: string | undefined): string {
    const courtSystem = this.localStorageService.get<CourtSystem | undefined>(CURRENT_COURT_SYSTEM_KEY)

    // An internal user should not be redirected to the home page
    const newReturnUrl =
      returnUrl && returnUrl !== DEFAULT_LOGIN_ROUTE
        ? returnUrl
        : courtSystem && courtSystem.id
          ? `/${AppPaths.CourtSystem}/` + courtSystem.id
          : returnUrl

    return this.getValidReturnUrl(newReturnUrl)
  }

  /**
   * Checks if the returnUrl is going to redirect the user to an invalid path
   * i.e. (/login). Once a user is authenticated they should be redirected to where they were before
   * the auth check or the landing page.
   */
  private getValidReturnUrl(returnUrl: string | undefined): string {
    if (!returnUrl) {
      return DEFAULT_LOGIN_ROUTE
    }

    return INVALID_RETURN_URL_PATHS.some(path => returnUrl.split(RETURN_URL_KEY)[0].startsWith(`/${path}`))
      ? DEFAULT_LOGIN_ROUTE
      : returnUrl
  }
}

export function generateAuthIdentifier(identityProvider: IdentityProviderName, email: string): string {
  return base64URLEncode(toSha256(`${identityProvider}:${email}`))
}

export function buildNativeLoginUrl(
  identityProvider: IdentityProviderName,
  codeChallenge: string,
  state: string,
): string {
  const params = new HttpParams({
    fromObject: {
      [RootParams.IdentityProvider]: identityProvider,
      [RootParams.CodeChallenge]: codeChallenge,
      [RootParams.State]: state,
    },
  })

  return `/${AppPaths.Login}?${params.toString()}`
}
