/**
 * How close to the edge of the viewport we can be before the tooltip will switch to a different position in pixels
 */
export const VIEWPORT_MARGIN = 8 /* 0.5 rem (--half-spacer) */

/**
 * How close to the attached element the point of the tooltip arrow should be in pixels
 */
export const ARROW_OFFSET = 4 /* 0.25 rem (--quarter-spacer) */

/**
 * Tooltip arrow height in pixels
 */
export const ARROW_SIZE = 6 /* 0.375 rem */

/**
 * How far away the tooltip body should be from the element
 */
export const OFFSET = ARROW_SIZE + ARROW_OFFSET /* 0.625 rem */

export const ALIGNMENTS_CLASS_PREFIX = 'alignments'
