@if (times().updated.length > 0) {
  <div class="real-time-sessions-conflicts-item__container">
    <div class="real-time-sessions-conflicts-item__times">
      <div>
        <strong>Existing {{ term() | lowercase }} time{{ pluralizeArray(times().updated) }}</strong>
      </div>
      <div>
        <strong>New {{ term() | lowercase }} time{{ pluralizeUpdatedLocalTimeRangeAfter(times().updated) }}</strong>
      </div>
    </div>
    @for (updatedTime of times().updated; track updatedTime) {
      <div class="real-time-sessions-conflicts-item__times">
        <div>
          <ul>
            <li>
              {{ updatedTime.before.start | ftrDate: { format: dateFormat } }} to
              {{ updatedTime.before.end | ftrDate: { format: dateFormat } }}
            </li>
          </ul>
        </div>
        <div>
          <ul>
            @for (updatedAfter of updatedTime.after; track updatedAfter) {
              <li>
                {{ updatedAfter.start | ftrDate: { format: dateFormat } }} to
                {{ updatedAfter.end | ftrDate: { format: dateFormat } }}
              </li>
            }
          </ul>
        </div>
      </div>
    }
  </div>
}

@if (times().deleted.length > 0) {
  <div class="real-time-sessions-conflicts-item__container">
    <div class="real-time-sessions-conflicts-item__times">
      <div>
        <strong>Deleted {{ term() }} time{{ pluralizeArray(times().deleted) }}</strong>
      </div>
    </div>
    <div class="real-time-sessions-conflicts-item__times">
      <div>
        <ul>
          @for (deletedTime of times().deleted; track deletedTime) {
            <li>
              {{ deletedTime.start | ftrDate: { format: dateFormat } }} to
              {{ deletedTime.end | ftrDate: { format: dateFormat } }}
            </li>
          }
        </ul>
      </div>
    </div>
  </div>
}
