import { Directive, EventEmitter, Output } from '@angular/core'
import { AbstractControl, FormControl } from '@angular/forms'
import { RemoteData } from '@ftr/foundation'
import { ReplaySubject } from 'rxjs'

export type StepIsValid = boolean

/**
 * The direct child component of each step in a Multi Step process must extend StepNavigation
 * so that we can allow for the separation of concerns between navigating and determining whether
 * or not the component should be allowed to navigate forward or back (e.g. if validating a form).
 *
 * Note: As of Angular 10. Directive must be added to abstract class, or else you cannot use @Output.
 */
@Directive()
export abstract class StepNavigation {
  /**
   * We are declaring Remote<any> here so it can be reused or extended by concrete steps
   */
  submissionSubject = new ReplaySubject<RemoteData<any>>()
  submission = this.submissionSubject.asObservable()

  /**
   * StepComponent listens to the result of the emitted value to determine whether,
   * in a MultiStep process, it should skip to the next step.
   */
  @Output() onNextStep = new EventEmitter<StepIsValid>()

  /**
   * Emits an event to go to the next step.
   * True by default, override if required.
   */
  goToNextStep(): void {
    this.onNextStep.emit(true)
  }

  /**
   * This method is here as a placeholder, and is currently defaulted to true
   * as we have no use-case for blocking 'back' at this time.
   */
  goToPreviousStep(): boolean {
    return true
  }

  asFormControl(control: AbstractControl): FormControl {
    return control as FormControl
  }

  /**
   * An optional event that will notify the step component that this step has finished loading.
   * Define your own <code>@Output() onStepReady = new EventEmitter<void>()</code> in your
   * step component and emit after receiving your payload from the server.
   * NOTE: This should be an ASYNC EventEmitter e.g. new EventEmitter<void>(true)
   */
  onStepReady?: EventEmitter<void>
}
