/**
 * @example "13"
 */
export const MOMENT_DAY_FORMAT_NUMERIC = 'DD'

/**
 * @example "Thursday"
 */
export const MOMENT_DAY_FORMAT_LONG = 'dddd'

/**
 * @example "Fri"
 */
export const MOMENT_DAY_FORMAT_SHORT = 'ddd'
/**
 * @example "October"
 */
export const MOMENT_MONTH_FORMAT_LONG = 'MMMM'

/**
 * @example "Oct"
 */
export const MOMENT_MONTH_FORMAT_SHORT = 'MMM'

/**
 * @example 10
 */
export const MOMENT_MONTH_FORMAT_NUMERIC = 'MM'

/**
 * @example "2019"
 */
export const MOMENT_YEAR_FORMAT_LONG = 'YYYY'

/**
 * @example "Oct 13, 2019"
 */
export const MOMENT_DATE_FORMAT_NORMAL = `${MOMENT_MONTH_FORMAT_SHORT} ${MOMENT_DAY_FORMAT_NUMERIC}, ${MOMENT_YEAR_FORMAT_LONG}`

/**
 * @example "13 October, 2019"
 */
export const MOMENT_DATE_FORMAT_LONG = `${MOMENT_DAY_FORMAT_NUMERIC} ${MOMENT_MONTH_FORMAT_LONG}, ${MOMENT_YEAR_FORMAT_LONG}`

export const MOMENT_DATE_LONG_NO_COMMA = `${MOMENT_DAY_FORMAT_NUMERIC} ${MOMENT_MONTH_FORMAT_LONG} ${MOMENT_YEAR_FORMAT_LONG}`

/**
 * @example "13 October 2019, Thursday"
 */
export const MOMENT_DATE_FORMAT_WITH_DAY = `${MOMENT_DAY_FORMAT_NUMERIC} ${MOMENT_MONTH_FORMAT_LONG} ${MOMENT_YEAR_FORMAT_LONG}, ${MOMENT_DAY_FORMAT_LONG}`

/**
 * @example "10/13/2019"
 */
export const MOMENT_DATE_FORMAT_SHORT = `${MOMENT_MONTH_FORMAT_NUMERIC}/${MOMENT_DAY_FORMAT_NUMERIC}/${MOMENT_YEAR_FORMAT_LONG}`

/**
 * @example "2019-10-13"
 */
export const MOMENT_DATE_FORMAT_MACHINE = `${MOMENT_YEAR_FORMAT_LONG}-${MOMENT_MONTH_FORMAT_NUMERIC}-${MOMENT_DAY_FORMAT_NUMERIC}`
