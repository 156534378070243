import { NgClass, NgTemplateOutlet } from '@angular/common'
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewEncapsulation } from '@angular/core'
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms'
import {
  ApiResult,
  ButtonColor,
  ButtonDirection,
  ButtonSize,
  DestroySubscribers,
  FadeAnimationType,
  NotificationComponent,
  NotificationType,
  ToastService,
  fade,
} from '@ftr/foundation'
import { Observable, ReplaySubject, interval, map, startWith, takeUntil, takeWhile } from 'rxjs'
import { CanHaveUnsavedChanges } from '../../guards'
import { SubmitButtonComponent } from '../submit-button'
import { FormStates } from './form.states'

const ANIMATION_TOGGLE_MS = 500

export interface SubmitGuardContext {
  cancel: () => void
  submit: () => void
}

@Component({
  selector: 'ftr-untyped-form',
  templateUrl: './untyped-form.component.html',
  styleUrls: ['./untyped-form.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [fade(500), fade(500, 100, FadeAnimationType.HalfFade)],
  imports: [NgClass, ReactiveFormsModule, NotificationComponent, SubmitButtonComponent, NgTemplateOutlet],
  standalone: true,
})
/**
 * A generic form component that can help keep track of form state and provide feedback to the user. It has the
 * following behaviours:
 *
 * - Displays a confirmation dialog to the user when they attempt to navigate away from the form after editing a
 *   field
 * - Changes the state of the submit button based on what state the form is in
 * - Supports highlighting controls with validation errors on submission via the highlightErrors property based to
 *   it's templates
 *
 */
export class UntypedFormComponent<TData> extends DestroySubscribers implements OnInit, CanHaveUnsavedChanges {
  @Input() name?: string

  /**
   * A remote data object that represents the submission. Used to keep the form state in sync with the submission.
   */
  @Input() submission: ApiResult<TData, string | { message: string }>

  /**
   * The default label for the submit form when the form is in the ready state.
   */
  @Input() submitLabel: string

  /**
   * The label for the submit button while the form is submitting
   */
  @Input() submittingLabel: string

  /**
   * The size for the submit button
   */
  @Input() submitSize?: ButtonSize

  /**
   * Whether the submit button should be full width
   */
  @Input() submitFullWidth = false

  /**
   * Whether the submit button should be full width for mobile
   */
  @Input() submitFullWidthMobile = false

  /**
   * The value for the submit button direction
   */
  @Input() submitDirection: ButtonDirection | undefined = undefined

  /**
   * The form group containing all the controls used in this form
   */
  @Input() formGroup: UntypedFormGroup

  /**
   * A template for the main part of the form. It is expected that this will contain all the controls. Passed a
   * boolean observable highlightErrors in it's context which will be set to true for a short period after
   * submission if validation fails and can be used to highlight the failing fields.
   */
  @Input() formTemplate: TemplateRef<{ highlightErrors: Observable<boolean> }>

  /**
   * A template for the form footer. Used to place things along side the submit button
   */
  @Input() formFooterTemplate: TemplateRef<unknown>

  /**
   * A template for the form footer. Used to place things before the submit button
   */
  @Input() preFormFooterTemplate: TemplateRef<unknown> | null = null

  /**
   * A custom template that will be right aligned within the form footer.
   * When provided, the existing content will shift to the left.
   */
  @Input() formFooterRightTemplate: TemplateRef<unknown> | null = null
  /**
   * A template for placing content below the submit button. Have a long hard think about using this area for
   * anything interactive!
   */
  @Input() formInstructionsTemplate: TemplateRef<unknown>
  /**
   * Uses a minimal overlay while submitting that doesn't rely on blending with the dom stack
   */
  @Input() minimalOverlay = false
  /**
   * An optional function to open the submitGuardTemplate when true rather than submitting the form
   */
  @Input() submitGuard?: boolean
  /**
   * A template to display when the submit guard returns true
   */
  @Input() submitGuardTemplate: TemplateRef<SubmitGuardContext> | null
  /**
   * An option that enables GA tracking on the submit button
   */
  @Input() trackSubmit = false
  /**
   * The button color
   */
  @Input() buttonColor: ButtonColor = 'primary'
  /**
   * Use this to hide the submit button if you need something custom
   */
  @Input() hideSubmitButton = false
  /**
   * Aligns the submit button and the footer templates to the left
   */
  @Input() footerLeftAligned = false
  /**
   * Use a toast to display error messages
   */
  @Input() showToastOnError = false
  /**
   * An event that fires when the form is submitted and passes validation
   */
  @Output() formSubmit = new EventEmitter<void>()
  /**
   * An event that fires when the user attempts to submit the form, prior to checking validation
   */
  @Output() submitAttempt = new EventEmitter<boolean>()

  formState = FormStates.Ready

  readonly notificationType = NotificationType

  submissionError: string | undefined
  highlightErrors: Observable<boolean>
  highlightErrorsSubject = new ReplaySubject<boolean>(1)
  showGuard = false

  constructor(private readonly toastService: ToastService) {
    super()
  }

  /**
   * Used to set the form into a busy state where the submit button will have a loading indicator
   */
  @Input()
  set processing(value: boolean) {
    this.formState = value ? FormStates.Busy : FormStates.Ready
  }

  /**
   * Used to set the form into a busy state where the submit button will be disabled
   */
  @Input()
  set disabled(value: boolean) {
    this.formState = value ? FormStates.Disabled : FormStates.Ready
  }

  ngOnInit(): void {
    this.setupSubmissionResponses()
    this.setupErrorHighlighting()
  }

  submitFormFromGuard(): void {
    this.submitForm(true)
  }

  submitForm(fromGuard = false): void {
    const formValid = this.formGroup.valid
    if (!fromGuard) {
      this.submitAttempt.emit(formValid)
    }
    if (!formValid) {
      this.highlightAllFormErrors()
      return
    }
    if (this.submitGuard && !fromGuard) {
      this.showGuard = true
      return
    }
    this.closeGuard()
    this.formState = FormStates.Submitting
    this.formSubmit.emit()
  }

  closeGuard(): void {
    this.showGuard = false
  }

  /**
   * Whether the form has any changes.
   *
   * Note: Once any input on the form is touched, it will be marked dirty. Annoyingly though, if
   * all changes are reverted the FormGroup will not be set to a "pristine" state again.
   * This means that if the user inputs something and then deletes it, when they navigate
   * away from the page they will be prompted with the "Are you sure you want to leave this page?"
   * prompt. The likelihood of this happening seems low to me though, so I'm not investing too much
   * time in working around Angular's default behaviour.
   *
   * @returns {boolean}
   */
  hasUnsavedChanges(): boolean {
    return this.formGroup && this.formGroup.dirty
  }

  resetErrorState(): void {
    this.submissionError = undefined
    this.formState = FormStates.Ready
  }

  reset(): void {
    this.resetErrorState()
    this.formGroup.reset()
  }

  private highlightAllFormErrors(): void {
    touchAllFormFields(this.formGroup)
    animationToggle().subscribe(bool => this.highlightErrorsSubject.next(bool))
  }

  private setupErrorHighlighting(): void {
    this.highlightErrors = this.highlightErrorsSubject.asObservable()
    this.highlightErrorsSubject.next(false)
  }

  private setupSubmissionResponses(): void {
    this.submission.pipe(takeUntil(this.finalize)).subscribe(remote => {
      if (remote.isSuccess()) {
        this.formState = FormStates.Success
      } else if (remote.isFailure()) {
        this.formState = FormStates.Failure
        const errorMessage = getErrorMessage(remote.error)
        if (this.showToastOnError && errorMessage) {
          this.toastService.clear()
          this.toastService.error(errorMessage)
        } else {
          this.submissionError = errorMessage
        }
      }
    })
  }
}

function animationToggle(): Observable<boolean> {
  return interval(ANIMATION_TOGGLE_MS).pipe(
    startWith(-1),
    takeWhile(num => num < 1),
    map(num => num === -1),
  )
}

function touchAllFormFields(formGroup: UntypedFormGroup): void {
  formGroup.markAllAsTouched()
}

function getErrorMessage(error: string | { message: string } | any): string {
  if (typeof error === 'string') {
    return error
  }

  if ('message' in error) {
    return error.message
  }

  /*
    Because it is impossible to go back and check every occurrence of this, we retain original behaviour for backwards
    compatibility.
   */
  return error
}
