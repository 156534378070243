import { Component, Input } from '@angular/core'

@Component({
  selector: 'ftr-app-loader',
  template: `<div class="pre-bootstrap" aria-busy="true">
    <div class="pre-bootstrap__loader" role="progressbar" [attr.aria-valuetext]="ariaValueText">
      <div class="pre-bootstrap__line">
        <div class="pre-bootstrap__indeterminate"></div>
      </div>
    </div>
    <div>
      <img class="pre-bootstrap__logo" alt="For The Record" src="/assets/images/logo-themed.svg" />
    </div>
  </div>`,
  standalone: true,
})
export class AppLoaderComponent {
  @Input()
  ariaValueText = 'Loading For The Record'
}
