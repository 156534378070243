/* eslint-disable max-classes-per-file */
import { Uuid } from '@ftr/contracts/type/shared'

export class FetchSharedRecordingAction {
  static readonly type = '[Shared Recording] Fetch Shared Recording'
  constructor(public sharedRecordingId: Uuid) {}
}

export class ResetSharedRecordingStateAction {
  static readonly type = '[Shared Recording] Reset Shared Recording State'
}
