import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core'
import { SimpleWindowRefService } from '@ftr/foundation'

@Directive({ selector: '[elevioArticle]', standalone: true })
export class ElevioArticleDirective implements AfterViewInit {
  @Input() articleId: string | undefined
  @Input() elevioType = 'article'
  constructor(
    private el: ElementRef,
    private windowRefService: SimpleWindowRefService,
  ) {}

  ngAfterViewInit(): void {
    if (this.articleId) {
      const child = this.windowRefService.document().createElement('elevio-element')
      child.setAttribute('data-type', this.elevioType)
      child.setAttribute('data-id', this.articleId)
      this.el.nativeElement.appendChild(child)
    }
  }
}
