import { UntypedFormGroup } from '@angular/forms'
import { SavedCard } from '@ftr/contracts/read/order-account'
import { resetControlAndValidators, setControlValidators } from '@ftr/forms'
import { OrderReviewFieldNames } from '~app/features/order-form-shared/steps/order-review/order-review.component'

export function setupOrderCardFields(formGroup: UntypedFormGroup, savedCards: SavedCard[]): void {
  const currentSavedCard =
    formGroup.get(OrderReviewFieldNames.PaymentSavedCardId)!.value ||
    (savedCards || []).filter((card: any) => card.isDefault)[0]

  if (currentSavedCard) {
    formGroup.controls[OrderReviewFieldNames.PaymentSavedCardId].setValue(currentSavedCard)
    formGroup.controls[OrderReviewFieldNames.PaymentSavedCardId].markAsTouched({
      onlySelf: true,
    })
    setControlValidators(formGroup.controls[OrderReviewFieldNames.PaymentSavedCardId])
    resetControlAndValidators(formGroup.controls[OrderReviewFieldNames.PaymentNewCard])
  } else {
    setControlValidators(formGroup.controls[OrderReviewFieldNames.PaymentNewCard])
    resetControlAndValidators(formGroup.controls[OrderReviewFieldNames.PaymentSavedCardId])
  }
}
