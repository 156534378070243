import { CombinedError } from '@ftr/foundation'
import { ForbiddenApiError } from './forbidden-api.error'

export function isForbiddenError(error: Error): boolean {
  if (error instanceof ForbiddenApiError) {
    return true
  }
  if (error instanceof CombinedError) {
    return error.originalErrors.some(originalError => originalError instanceof ForbiddenApiError)
  }
  return false
}
