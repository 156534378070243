import { Injectable } from '@angular/core'
import { StaticMenuItem } from '@ftr/menus'
import { AppPaths } from '@ftr/routing-paths'

/**
 * Any links only for transcribers.
 */
@Injectable({
  providedIn: 'root',
})
export class TranscriberNavItems {
  jobsList(): StaticMenuItem {
    return {
      title: 'My Transcript Jobs',
      path: [`/${AppPaths.Jobs}`],
    }
  }
}
