// organize-imports-ignore (polyfills import must be first)
import './polyfills'
import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { TrackingEventType } from '@ftr/foundation'
import { ReplaySubject } from 'rxjs'
import { AppModule } from '~app/app.module'
import { WindowRef } from '~app/services/window/window-ref.service'
import { environment } from '~environments/environment'

if (environment.production) {
  enableProdMode()
}

/**
 * If we are in a production environment, we need to bootstrap angular AFTER the config.js script has loaded.
 * This script contains things like our logging url etc.
 */
const configLoaded = new ReplaySubject<void>()

if (process.env.CONFIG_URL) {
  const script = document.createElement('script')
  script.src = process.env.CONFIG_URL
  script.onload = () => configLoaded.next()
  document.head.appendChild(script)
} else {
  configLoaded.next()
}

configLoaded.subscribe(() => {
  enableTracking()
  enableNewRelicMonitoring()
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err))
})

/**
 * Google tag manager tracking script, just done in body, rather than head, due to needing the config
 */
function enableTracking(): void {
  const windowRef = window as unknown as WindowRef
  if (!windowRef.dataLayer) {
    windowRef.dataLayer = [{ 'gtm.start': new Date().getTime(), event: 'gtm.js' as TrackingEventType }]
    const script = document.createElement('script')
    script.async = true
    const config = windowRef.config || environment.config
    script.src = `https://www.googletagmanager.com/gtm.js?id=${config?.TrackingContainerCode || 'GTM-M73SKXB'}`
    document.body.appendChild(script)
  }
}

function enableNewRelicMonitoring(): void {
  const windowRef = window as unknown as WindowRef
  if (windowRef.config?.NewRelicWebAppId) {
    const newrelicScript = document.createElement('script')
    newrelicScript.src = '/newrelic.js'
    document.head.appendChild(newrelicScript)
  }
}
