import { SttUtteranceWithContentAndSpeaker } from '@ftr/contracts/regional-api/stt'
import { startTimeSorter } from '@ftr/foundation'
import { LocalDateTime } from '@js-joda/core'
import { RealTimeSttRemark } from '../types'

/**
 * NOTICE:
 * The start/endTimes are NOT deserialized from the API call, hence the LocalDateTime.parse
 */

export function utterancesToRemarks(utterances: SttUtteranceWithContentAndSpeaker[]): RealTimeSttRemark[] {
  return utterances.map(utteranceToRemark).sort(startTimeSorter)
}

function utteranceToRemark(utterance: SttUtteranceWithContentAndSpeaker): RealTimeSttRemark {
  return {
    id: utterance.id,
    content: utterance.content,
    speakerId: utterance.sttSpeakerId,
    speakerName: utterance.sttSpeaker.name,
    startTime: LocalDateTime.parse(utterance.startTime.toString()),
    endTime: LocalDateTime.parse(utterance.endTime.toString()),
  }
}
