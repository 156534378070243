/* eslint-disable max-classes-per-file */
import { Uuid } from '@ftr/contracts/type/shared'
import { RealTimePlaybackKey } from '@ftr/data-realtime-playback'
import { RealTimeSearchInstanceStateModel, RealTimeSearchScrollPosition } from './real-time-search.model'

export interface RealTimeSearchAction {
  readonly playbackKey: RealTimePlaybackKey
}

export class SetRealTimeSearchInstanceStateAction implements RealTimeSearchAction {
  static readonly type = '[RealTimeSearch] Set Instance State'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly instanceState: RealTimeSearchInstanceStateModel,
  ) {}
}

export class FetchRealTimeSearchResultsAction implements RealTimeSearchAction {
  static readonly type = '[RealTimeSearch] Fetch Search Results'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly courtSystemId: Uuid,
    readonly searchTerm: string,
  ) {}
}

export class FetchRealTimeSearchResultsNextPageAction implements RealTimeSearchAction {
  static readonly type = '[RealTimeSearch] Fetch Next Page'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly courtSystemId: Uuid,
  ) {}
}

export class ClearRealTimeSearchResultsAction implements RealTimeSearchAction {
  static readonly type = '[RealTimeSearch] Clear Search Results'

  constructor(readonly playbackKey: RealTimePlaybackKey) {}
}

export class SelectRealTimeSearchResultsAction implements RealTimeSearchAction {
  static readonly type = '[RealTimeSearch] Select Search Result'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly selectedSearchResultIndex: number | undefined,
  ) {}
}

export class HoverRealTimeSearchResultsAction implements RealTimeSearchAction {
  static readonly type = '[RealTimeSearch] Hover Search Result'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly hoveredSearchResultIndex: number | undefined,
  ) {}
}

export class SetRealTimeSearchScrollPositionAction implements RealTimeSearchAction {
  static readonly type = '[RealTimeSearch] Set Scroll Position'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly scrollPosition: RealTimeSearchScrollPosition | undefined,
  ) {}
}
