import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouterModule } from '@angular/router'
import { UnsavedChangesGuard } from '@ftr/forms'
import { LaunchDarklyModule } from '@ftr/ui-feature-flags'
import { NgxLocalStorageModule } from 'ngx-localstorage'

@NgModule({
  imports: [
    BrowserAnimationsModule,
    RouterModule,
    LaunchDarklyModule,
    NgxLocalStorageModule.forRoot({
      prefix: 'ftr-uploads',
    }),
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only')
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [UnsavedChangesGuard],
    }
  }
}
