import { isNotNullOrUndefined } from '@ftr/foundation'

const integrationTestEmailRegex = /^(int|dj-e2e)-.*@mailosaur.io$/

function isUserFromAutomation(email: string | undefined): boolean {
  return isNotNullOrUndefined(email) && integrationTestEmailRegex.test(email)
}

/* We eat a lot of our MAUs with integration tests
   remap the key if we are using an integration user so we use a single MAU
*/
export function getIdentifyKeyForUser(user: { userId: string; email?: string }): string {
  return isUserFromAutomation(user.email) ? 'automated-user' : user.userId
}
