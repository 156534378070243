import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'
import { DJ_COURT_SYSTEM_ID, DJ_KEY } from '@ftr/contracts/digital-justice'
import { AppPaths, AuthenticationActions, SignupParams } from '@ftr/routing-paths'
import { lastValueFrom } from 'rxjs'
import { AuthenticationService, DigitalJusticeAuthService } from '../../services'

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard {
  private hasRedirectedOnReload = false

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
    private readonly digitalJusticeAuthService: DigitalJusticeAuthService,
  ) {}

  canActivate(activatedRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.authenticationService.isLoggedIn.then(async isLoggedIn => {
      if (!isLoggedIn) {
        const authenticationDefaultAction = activatedRoute.queryParamMap.get(SignupParams.AuthenticationDefaultAction)
        if (authenticationDefaultAction === AuthenticationActions.SignUp) {
          const authenticationEmail = activatedRoute.queryParamMap.get(SignupParams.AuthenticationEmail)
          this.router.navigate(['/signup'], {
            queryParams: { returnUrl: state.url, authenticationEmail },
          })
        } else {
          this.router.navigate([`/${AppPaths.Login}`], {
            queryParams: { returnUrl: state.url },
          })
        }
        return false
      } else {
        /**
         * Set the currentUser into the authenticationService if not present at this point.
         * This could happen when we refresh the page.
         */
        if (!this.authenticationService.getCurrentUserId()) {
          await this.authenticationService.initialize()
        }

        const courtSystemId = activatedRoute.queryParamMap.get(DJ_COURT_SYSTEM_ID)
        const djKey = activatedRoute.queryParamMap.get(DJ_KEY)
        if (courtSystemId && djKey) {
          return lastValueFrom(this.digitalJusticeAuthService.handlePostKey(courtSystemId, djKey)).then(() => true)
        }

        const shouldRedirectToCourtHome = await this.authenticationService.shouldRedirectToCourtHome()
        if (shouldRedirectToCourtHome && state.url === '/' && !this.hasRedirectedOnReload) {
          this.hasRedirectedOnReload = true
          this.router.navigate([this.authenticationService.getValidUserAwareReturnUrl(undefined)])
          return false
        }

        return true
      }
    })
  }
}
