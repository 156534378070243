import { Injectable } from '@angular/core'
import { AuthEventType } from '@ftr/api-shared'
import { UserSettings } from '@ftr/contracts/api/user'
import { Shortcut, SHORTCUT_DEFAULTS, ShortcutMap, ShortcutMapEntries, SOLO_CHANNEL } from '@ftr/contracts/type/desktop'
import { isInDesktopApp, TrackingAction, TrackingEventType } from '@ftr/foundation'
import { LoggingService } from '@ftr/ui-observability'
import { BasePlaybackControlEventService, PlayerFocusElement } from '@ftr/ui-playback'
import { AnalyticsService, AuthenticationService } from '@ftr/ui-user'
import { Observable, Subject } from 'rxjs'
import { WindowRefService } from '~app/services/window/window-ref.service'
import { mapAcceleratorsPlatformSpecificModifiers } from '~app/util/ShortcutUtils'

// This is a singleton to ensure we don't bind a bunch of callbacks to the window API
// Services can instead just grab the observables relevant to them
@Injectable({
  providedIn: 'root',
})
export class DesktopApiService {
  private shortcut = new Subject<Shortcut>()
  private updateReady = new Subject<boolean>()

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly windowRefService: WindowRefService,
    private readonly analyticsService: AnalyticsService,
    private readonly logger: LoggingService,
  ) {}

  initialize(): void {
    if (!isInDesktopApp()) {
      return
    }

    this.authenticationService.authEvents.subscribe(authEvent => {
      if (authEvent.type === AuthEventType.Login && authEvent.user?.id) {
        this.windowRefService.getDesktopElectronApi()?.registerUserId?.(authEvent.user?.id)
      }
    })

    this.windowRefService.getDesktopElectronApi()?.handleShortcutPressed?.(shortcut => {
      this.shortcut.next(shortcut)
    })

    this.windowRefService.getDesktopElectronApi()?.handleUpdateReady?.(isMajorUpdate => {
      this.updateReady.next(isMajorUpdate)
    })
  }

  getShortcutAsObservable(): Observable<Shortcut> {
    return this.shortcut.asObservable()
  }

  getUpdateReadyAsObservable(): Observable<boolean> {
    return this.updateReady.asObservable()
  }

  setOnPlaybackPage(onPlaybackPage: boolean): void {
    this.windowRefService.getDesktopElectronApi()?.onPlaybackPage?.(onPlaybackPage)
  }

  getDefaultShortcuts(): ShortcutMap {
    return new Map(
      mapAcceleratorsPlatformSpecificModifiers([...SHORTCUT_DEFAULTS.entries()], this.windowRefService.isMac()),
    )
  }

  getUserShortcuts(userSettings: UserSettings): ShortcutMap {
    return new Map([...this.getDefaultShortcuts().entries(), ...this.getCurrentPlatformCustomShortcuts(userSettings)])
  }

  getUserShortcutsForSettings(shortcuts: ShortcutMap): Partial<UserSettings> {
    const customShortcuts = Array.from(shortcuts.entries())
    return this.windowRefService.isMac()
      ? { customShortcutsMac: customShortcuts }
      : { customShortcutsWindows: customShortcuts }
  }

  registerShortcuts(shortcuts: ShortcutMap): Promise<Shortcut[]> {
    return this.windowRefService.getDesktopElectronApi()?.registerShortcuts?.(shortcuts) ?? Promise.resolve([])
  }

  async checkShortcutBinding(shortcuts: ShortcutMap): Promise<Shortcut[]> {
    const failed = await this.registerShortcuts(shortcuts)
    // Clear out the shortcuts we tested the binding for
    await this.registerShortcuts(new Map())
    return failed
  }

  // Takes a shortcut, the relevant playback control event service and fires off the relevant event to modify playback in some way
  handlePlaybackShortcut(shortcut: Shortcut, playbackControlEventService: BasePlaybackControlEventService): void {
    if (shortcut.startsWith(SOLO_CHANNEL)) {
      const channelIndex = Number(shortcut.split(SOLO_CHANNEL).at(1)) - 1
      playbackControlEventService.toggleChannelSolo(channelIndex)
    } else {
      switch (shortcut) {
        case Shortcut.PlayPause:
          playbackControlEventService.togglePlayPause()
          break
        case Shortcut.SeekForward5:
          playbackControlEventService.skip(5)
          break
        case Shortcut.SeekBackwards5:
          playbackControlEventService.skip(-5)
          break
        case Shortcut.IncreasePlaybackSpeed:
          playbackControlEventService.cyclePlaybackSpeed(true)
          break
        case Shortcut.DecreasePlaybackSpeed:
          playbackControlEventService.cyclePlaybackSpeed(false)
          break
        case Shortcut.ToggleMute:
          playbackControlEventService.toggleMute()
          break
        case Shortcut.IncreaseVolume:
          playbackControlEventService.cycleVolume(true)
          break
        case Shortcut.DecreaseVolume:
          playbackControlEventService.cycleVolume(false)
          break
        case Shortcut.SeekToEnd:
          playbackControlEventService.seekToEnd()
          break
        case Shortcut.SelectPlaybackTime:
          playbackControlEventService.focusElement(PlayerFocusElement.WallClock)
          break
        case Shortcut.SelectPlayhead:
          playbackControlEventService.focusElement(PlayerFocusElement.Playhead)
          break
        case Shortcut.TurnAllChannelsOn:
          playbackControlEventService.turnAllChannelsOn()
          break
        case Shortcut.ToggleChannelsPanel:
          playbackControlEventService.toggleChannelsPanel()
          break
        default:
          this.logger.warn({ message: 'Got unhandled shortcut', shortcut })
          return
      }
    }

    this.analyticsService.track({
      event: TrackingEventType.PlayerControl,
      action: TrackingAction.KeyboardInput,
      eventLabel: `Global shortcut pressed: ${shortcut}`,
    })
  }

  private getCurrentPlatformCustomShortcuts(userSettings: UserSettings): ShortcutMapEntries {
    return this.windowRefService.isMac() ? userSettings.customShortcutsMac : (userSettings.customShortcutsWindows ?? [])
  }
}
