import { ChangeDetectorRef, Component, Input } from '@angular/core'
import { ComposableDropdownRenderMode } from '../ComposableDropdownRenderMode'

@Component({
  selector: 'ftr-composable-dropdown-header',
  templateUrl: './composable-dropdown-header.component.html',
  styleUrls: ['./composable-dropdown-header.component.css'],
})
export class ComposableDropdownHeaderComponent {
  @Input() showIcon = true
  public renderMode: ComposableDropdownRenderMode = 'drop-down'

  constructor(protected cdr: ChangeDetectorRef) {}

  setRenderMode(value: ComposableDropdownRenderMode): void {
    this.renderMode = value
    this.cdr.detectChanges()
  }
}
