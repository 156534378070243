import { Injectable } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router, Scroll } from '@angular/router'
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  distinctUntilChanged,
  filter,
  from,
  isObservable,
  map,
  of,
  switchMap,
} from 'rxjs'

/**
 * Exposes an observable which allows for individual routes to control the visibility of the footer.
 * If a route's data specifies the `footerVisible` prop, then that value will determine if the app template renders the global footer
 * If unspecified, defaults to footerVisible: true
 * @example
 *  // Setting the footer visibility on a route
 *  RouterModule.forChild({
 *    path: `:id`,
 *    data: {
 *      footerVisible: false
 *    }
 *  })
 */
@Injectable({
  providedIn: 'root',
})
export class FooterService {
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private router: Router,
  ) {}

  private readonly hideFooterOverrideSubject = new BehaviorSubject<boolean>(false)
  /* hideFooterVisibilityOverride overrides the footerVisibility from the route data
     @param hideFooter - if true, the footer will be hidden. If false, return back to footer visibility from route data
  */
  hideFooterVisibilityOverride(hideFooter: boolean): void {
    setTimeout(() => {
      this.hideFooterOverrideSubject.next(hideFooter)
    })
  }

  getFooterVisibility(): Observable<boolean> {
    return combineLatest([
      this.router.events.pipe(
        filter(navigationEvent => navigationEvent instanceof NavigationEnd || navigationEvent instanceof Scroll),
        map(navigationEvent => {
          // Reset hide footer visibility override when the route changes
          this.hideFooterOverrideSubject.next(false)
          return navigationEvent instanceof NavigationEnd
        }),
      ),
      this.hideFooterOverrideSubject.pipe(distinctUntilChanged()),
    ]).pipe(
      switchMap(([_, hideFooterOverride]) => {
        if (hideFooterOverride) {
          return of(!hideFooterOverride)
        }

        const data = this.getDataOfMostSpecificActivatedRoute()
        return this.asObservable(data.footerVisible)
      }),
      map(footerVisible => footerVisible ?? true),
    )
  }

  private asObservable(
    valueOrObservable: boolean | Observable<boolean> | Promise<boolean> | undefined,
  ): Observable<boolean | undefined> {
    if (isObservable(valueOrObservable)) {
      return valueOrObservable
    }
    if (typeof valueOrObservable === 'object' && 'then' in valueOrObservable) {
      return from(valueOrObservable)
    }
    return of(valueOrObservable)
  }

  private getDataOfMostSpecificActivatedRoute(): {
    footerVisible?: boolean | Observable<boolean> | Promise<boolean>
  } {
    // Finds the lower-most child in the router object to get the data from
    return this.getMostSpecificActivatedRoute(this.activatedRoute).snapshot.data
  }

  // Recursively finds the deepest child that has been activated in the route
  private getMostSpecificActivatedRoute(route: ActivatedRoute): ActivatedRoute {
    return route.firstChild ? this.getMostSpecificActivatedRoute(route.firstChild) : route
  }
}
