import { Injectable } from '@angular/core'
import { ApiClient, ApiClientFactory, serializeHttpParams } from '@ftr/api-shared'
import { PageDetails, PagedResult } from '@ftr/contracts/api/core'
import {
  ListUsersForCourtSystemQuery,
  OutstandingBalance,
  RegisterUserRequest,
  RegisterUserResponse,
  SendResetPasswordInstructionsBody,
  UpdateUserRequest,
  UpdateUserResponse,
  User,
  UserLocation,
  UserWithGlobalAdministratorRole,
} from '@ftr/contracts/api/user'
import { UserGroup } from '@ftr/contracts/api/user-group'
import { FeatureName } from '@ftr/contracts/type/core'
import { Uuid } from '@ftr/contracts/type/shared'
import { ApiResult, createAndAssign } from '@ftr/foundation'

const USER_LIST_SIZE = 100

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly userApiClient: ApiClient

  constructor(private readonly apiClientFactory: ApiClientFactory) {
    this.userApiClient = this.apiClientFactory.build('/user')
  }

  getUserDetails(): ApiResult<UserWithGlobalAdministratorRole> {
    return this.userApiClient.get()
  }

  register(request: RegisterUserRequest): ApiResult<RegisterUserResponse> {
    return this.userApiClient.post({
      path: '/register',
      body: request,
      responseBodyType: RegisterUserResponse,
      skipAuthentication: true,
    })
  }

  update(request: UpdateUserRequest): ApiResult<UpdateUserResponse> {
    return this.userApiClient.put<UpdateUserResponse, UpdateUserRequest>({
      path: '/update',
      body: request,
      skipAuthentication: false,
    })
  }

  resendConfirmation(userId: Uuid): ApiResult {
    return this.userApiClient.post({
      path: `/${userId}/resendConfirmation`,
      skipAuthentication: true,
    })
  }

  sendResetPasswordInstructions(email: string): ApiResult<undefined> {
    return this.userApiClient.post({
      path: '/sendResetPasswordInstructions',
      body: new SendResetPasswordInstructionsBody(email),
      skipAuthentication: true,
    })
  }

  checkUnpaidBalances(): ApiResult<OutstandingBalance[]> {
    return this.userApiClient.get({ path: '/checkBalances' })
  }

  listUsers(page: number, searchTerms?: string): ApiResult<PagedResult<User>> {
    return this.userApiClient.get({
      path: '/list',
      params: serializeHttpParams(
        createAndAssign(ListUsersForCourtSystemQuery, {
          searchTerms: searchTerms ? searchTerms.trim() : undefined,
          page: new PageDetails(page, USER_LIST_SIZE),
        }),
      ),
    })
  }

  getUserById(id: Uuid): ApiResult<User> {
    return this.userApiClient.get<User>({ path: `/${id}` })
  }

  getUserGroups(id: Uuid): ApiResult<UserGroup[]> {
    return this.userApiClient.get<UserGroup[]>({ path: `/${id}/groups` })
  }

  removeFromAllCourtSystems(id: Uuid): ApiResult {
    return this.userApiClient.put({ path: `/${id}/removeFromAllCourtSystems` })
  }

  getEnabledOrderingFeatures(): ApiResult<FeatureName[]> {
    return this.userApiClient.get({ path: '/enabledOrderingFeatures' })
  }

  getLocation(): ApiResult<UserLocation> {
    return this.userApiClient.get({ path: '/location' })
  }
}
