<ul class="collapsible-list" [ngClass]="{ 'collapsible-list--unstyled': unstyled }">
  @for (item of items; track item) {
    @if (item && !itemTemplate && ($index < numberOfItemsToShow || expanded)) {
      <li>
        {{ item }}
      </li>
    }
    @if (item && itemTemplate && ($index < numberOfItemsToShow || expanded)) {
      <li>
        <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"></ng-container>
      </li>
    }
    @if ($index === numberOfItemsToShow && !expanded) {
      <li class="collapsible-list__show-more">
        <a href="javascript:void(0)" (click)="toggleVisibility($event)">{{ expandText }}</a>
      </li>
    }
  }
  @if (expanded) {
    <li class="collapsible-list__show-less">
      <a href="javascript:void(0)" (click)="toggleVisibility($event)">{{ collapseText }}</a>
    </li>
  }
</ul>
