@if (iconBefore) {
  <span class="pill__icon pill__icon-before">
    <ng-container *ngTemplateOutlet="iconBefore"></ng-container>
  </span>
}
<span class="pill__label"><ng-content></ng-content></span>
@if (iconAfter) {
  <span class="pill__icon pill__icon-after" (click)="onIconAfterClick($event)">
    <div class="inner">
      <ng-container *ngTemplateOutlet="iconAfter"></ng-container>
    </div>
  </span>
}
