import { Injectable } from '@angular/core'

const GLOBAL_SCROLLBLOCK_CLASS_NAME = 'global-scrollblock'

/**
 * This service temporarily disables vertical scrolling.
 * Useful when showing modals.
 */
@Injectable({
  providedIn: 'root',
})
export class GlobalScrollblockService {
  enable(): void {
    document.body.parentElement?.classList.add(GLOBAL_SCROLLBLOCK_CLASS_NAME)
  }

  disable(): void {
    document.body.parentElement?.classList.remove(GLOBAL_SCROLLBLOCK_CLASS_NAME)
  }
}
