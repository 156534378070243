export enum AuthEventType {
  // User logged in, claims not yet verified
  Authenticated,

  // User logged in and claims verified
  Login,

  // User went through a login process
  LoginComplete,

  // User logged out
  Logout,
}
