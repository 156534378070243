import { Component, Input } from '@angular/core'

/**
 * This component must be available for any users, logged in or not, that's why we only resolve
 * `isLoggedIn` and load its children only if the user is logged in to show additional information
 * Every `help-link` should have a class which will be used in Elevio admin to assign an article to it.
 * Ftr-web has no knowledge of what articles to use, they are managed via Elevio settings: https://app.elev.io/helpers
 */
@Component({
  selector: 'ftr-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css'],
})
export class HelpComponent {
  @Input() isLoggedIn: boolean
}
