/* eslint-disable max-classes-per-file */
import { Favorite } from '@ftr/contracts/api/favorites'
import { Uuid } from '@ftr/contracts/type/shared'

export class FetchFavoritesForCourtSystemAction {
  static readonly type = '[Favorites] Get Favourites for court system'

  constructor(
    public userId: Uuid,
    public courtSystemId: Uuid,
  ) {}
}

export class SetFavoritesForCourtSystemAction {
  static readonly type = '[Favorites] Set Favourites for court system'

  constructor(
    public courtSystemId: Uuid,
    public favourites: Favorite[] = [],
  ) {}
}

export class AddFavoriteInCourtSystemAction {
  static readonly type = '[Favorites] Add favourite in court system'

  constructor(
    public userId: Uuid,
    public courtSystemId: Uuid,
    public url: string,
    public label: string,
  ) {}
}

export class UpdateFavoritesInCourtSystemAction {
  static readonly type = '[Favorites] Update labels for a list of favorites in a court system'

  constructor(
    public courtSystemId: Uuid,
    public favorites: Favorite[],
  ) {}
}

export class DeleteFavoriteFromCourtSystemAction {
  static readonly type = '[Favorites] Delete a favorite from the court system, by id'

  constructor(
    public courtSystemId: Uuid,
    public favoriteId: Uuid,
  ) {}
}

export class SetFavoritesEditModalVisible {
  static readonly type = '[Favorites] Set the visibility of the edit modal'

  constructor(public modalVisible: boolean) {}
}

export class SetFavoritesLoadingState {
  static readonly type = '[Favorites] set whether the favorites are currently loading'

  constructor(public isLoading: boolean) {}
}
