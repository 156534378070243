<div class="tippy" ftrTooltip [template]="tooltip" forceDisplayState="show" alignment="auto">
  <ng-template #tooltip>
    <ftr-card [padding]="spacing.Regular" class="tippy__tooltip" (click)="nextQuote()">
      <h2 class="tippy__tooltip__title">Did you know?</h2>
      <p class="tippy__tooltip__description">{{ quote }}</p>
    </ftr-card>
  </ng-template>
  <div class="tippy__image">
    <ftr-illustration [illustration]="illustration.Tippy" [extension]="'png'"></ftr-illustration>
  </div>
</div>
