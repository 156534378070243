import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { DesktopDownloadUrlServiceModule } from '@ftr/api-core'
import { AnchorComponent } from '@ftr/foundation'
import { DeepLinkComponent } from '~app/features/desktop/deep-link/deep-link.component'
import { DeepLinkService } from '~app/features/desktop/deep-link/deep-link.service'
import { OpenInAppComponent } from '~app/features/desktop/open-in-app/open-in-app.component'
import { UpdateAppToastComponent } from '~app/features/desktop/update-app-toast/update-app-toast.component'

@NgModule({
  imports: [AnchorComponent, CommonModule],
  declarations: [OpenInAppComponent, DeepLinkComponent, UpdateAppToastComponent],
  exports: [OpenInAppComponent, DeepLinkComponent, UpdateAppToastComponent],
  providers: [DeepLinkService, DesktopDownloadUrlServiceModule],
})
export class DesktopModule {}
