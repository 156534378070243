import { Uuid } from '@ftr/contracts/type/shared'
import { Icon, isInDesktopApp } from '@ftr/foundation'
import { AppPaths } from '@ftr/routing-paths'

export type SideNavDisplayMode = 'DEFAULT' | 'OVERLAY'

export interface SideNavGroup {
  title?: string
  isLoading?: boolean
  items?: SideNavItem[]
}

interface SideNavItemBase {
  title: string
  icon?: Icon
  logoUrl?: string
  tooltipHint?: string
  displayMode?: SideNavDisplayMode
}

type LinkNavItem = SideNavItemBase & {
  type: 'LINK'
  path: string[]
  queryParams?: Record<string, string>
}

type ButtonNavItem = SideNavItemBase & {
  type: 'BUTTON'
  onSelect: () => void
}

export type BranchNavItem = SideNavItemBase & {
  type: 'BRANCH'
  children: SideNavItem[]
}

export type SideNavItem = LinkNavItem | ButtonNavItem | BranchNavItem

export const HELP_CENTER_NAV_TITLE = 'Help Center'

export function getHelpSupportNavItem(desktopAppDownloadUrl: string): SideNavItem {
  return {
    type: 'BRANCH',
    title: 'Help & Support',
    icon: 'HelpCircle',
    displayMode: 'OVERLAY',
    children: [
      {
        type: 'BUTTON',
        title: HELP_CENTER_NAV_TITLE,
      },
      {
        type: 'BUTTON',
        title: 'Product Updates',
        onSelect: () => {
          window.open('https://fortherecord.releasenotes.io', '_blank')
        },
      },
      {
        type: 'BUTTON',
        title: 'Trust Center',
        onSelect: () => {
          window.open('https://trust.fortherecord.com', '_blank')
        },
      },
      {
        type: 'BUTTON',
        title: 'Support Portal',
        onSelect: () => {
          window.open('https://ftrgroup.service-now.com/customer', '_blank')
        },
      },
      {
        type: 'BUTTON',
        title: 'System Status',
        onSelect: () => {
          window.open('https://fortherecord.statuspage.io', '_blank')
        },
      },
      isInDesktopApp()
        ? undefined
        : {
            type: 'BUTTON',
            title: 'Desktop App',
            onSelect: () => {
              window.open(desktopAppDownloadUrl)
            },
          },
    ],
  } as SideNavItem
}

export function getGlobalAdminNavItem(courtSystemId?: Uuid): SideNavItem {
  const path = courtSystemId ? [`/${AppPaths.Admin}`, AppPaths.CourtSystems, courtSystemId] : [`/${AppPaths.Admin}`]
  return {
    type: 'LINK',
    icon: 'Global',
    title: 'Global Admin',
    path,
  }
}

export const COURT_SYSTEM_NAV_ITEMS = [
  {
    type: 'LINK',
    title: 'Select a Court System',
    path: [`/${AppPaths.CourtSystem}`],
  },
  {
    type: 'LINK',
    title: 'Exit Court System',
    path: ['/'],
  },
]
