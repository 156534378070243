<ftr-side-nav
  data-track-group="Main Side Nav"
  openFrom="left"
  [navItems]="navItems$"
  [headerTemplate]="mainNavTemplate"
></ftr-side-nav>

<ng-template #mainNavTemplate>
  @if (currentCourtSystem$ | async; as courtSystem) {
    {{ courtSystem.name }}
  }
</ng-template>
