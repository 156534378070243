import { HttpParameterCodec } from '@angular/common/http'

/**
 * Avoid Angular standard encoding which converts %2B to +, causing issues with spaces.
 * @see https://github.com/angular/angular/issues/18261#issuecomment-338354119
 */
export class CustomHttpParameterCodec implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key)
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value)
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key)
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value)
  }
}
