<div
  class="checkbox-list input"
  [ngClass]="{
    'input--highlight': highlightError | async,
    'input--invalid': !internalControl.valid,
    'input--touched': internalControl.touched || submitAttempted,
  }"
>
  <ftr-composable-dropdown [renderMode]="renderType">
    <ftr-composable-dropdown-header>
      <div class="checkbox-list__header">
        <input
          class="checkbox-list__control input__control"
          [ngClass]="{
            'input__control--has-value': !!selectedLabels,
          }"
          name="checkbox-list"
          [required]="required"
          [readOnly]="true"
          autocomplete="off"
          [value]="selectedLabels"
          [attr.placeholder]="'Check desired boxes'"
        />
        <label class="input__label" [ngClass]="{ 'input__label--required': required }">{{ label }}</label>
        <span class="input__bar"></span>

        <span
          class="input__validation"
          ftrValidationErrorHint
          [label]="label"
          [ngFormControl]="internalControl"
          [controlTouched]="internalControl.touched"
          [submitAttempted]="submitAttempted"
        ></span>
      </div>
    </ftr-composable-dropdown-header>

    <ftr-composable-dropdown-body>
      <ul class="checkbox-list__body">
        @for (item of items; track item) {
          <li class="checkbox-list__body-item">
            <ftr-checkbox
              class="checkbox-list__body-checkbox"
              [label]="item.title"
              [name]="item.id"
              [control]="getCheckboxAsFormControl(item)"
              [id]="item.id"
            ></ftr-checkbox>
          </li>
        }
      </ul>
    </ftr-composable-dropdown-body>
  </ftr-composable-dropdown>
</div>
