import { Money } from '@ftr/contracts/type/order/Money'

/**
 * "Natural" in this context means greater than 0, but not strictly true in that
 * natural numbers do not have decimals, which money does have.
 */
export function isNatural(m: Money | undefined): m is Money {
  if (m === undefined) {
    return false
  }

  return m.amount.isGreaterThan(0)
}
