import { CommonModule } from '@angular/common'
import { Component, Input, OnChanges } from '@angular/core'
import { FLAG_LIST, LocationFlag } from './flags'

export enum FlagSize {
  Regular = 'regular',
  Large = 'large',
}

@Component({
  selector: 'ftr-flag',
  templateUrl: './flag.component.html',
  styleUrls: ['./flag.component.css'],
  standalone: true,
  imports: [CommonModule],
})
export class FlagComponent implements OnChanges {
  @Input()
  locationCode: string

  @Input()
  size: FlagSize = FlagSize.Regular

  /**
   * The name of the icon is limited to those stored in the src/assets/images/svgs/flags folder.
   * @see https://storybook.ftrdev.com/?path=/story/style-guide-design--flag-library
   */
  isValid = false

  location: LocationFlag

  flagSvgPath = 'assets/images/svgs/flags'

  ngOnChanges(): void {
    const location = FLAG_LIST.find(({ code }) => code === this.locationCode.toUpperCase())
    if (location) {
      this.isValid = true
      this.location = location
      if (location.folder) {
        this.flagSvgPath = `${this.flagSvgPath}/${location.folder}`
      }
    } else {
      this.isValid = false
    }
  }
}
