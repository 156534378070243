import { Component, input, output, ViewEncapsulation } from '@angular/core'
import { Uuid } from '@ftr/contracts/type/shared'
import { DestroySubscribers, IconComponent, selectWith, TooltipModule } from '@ftr/foundation'
import { DropdownMenuComponent, DropdownMenuItemsComponent, StaticMenuItem } from '@ftr/menus'
import { SideNavDisplayMode, SideNavItem } from '@ftr/ui-core'
import {
  AddFavoriteInCourtSystemAction,
  CurrentFavoriteService,
  FavoritesState,
  SetFavoritesEditModalVisible,
} from '@ftr/ui-user'
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap'
import { Store } from '@ngxs/store'
import { take } from 'rxjs'
import { FavoritesEditModalComponent } from '~app/features/favorites/favorites-edit-modal/favorites-edit-modal.component'

@Component({
  selector: 'ftr-favorites-menu',
  templateUrl: './favorites-menu.component.html',
  styleUrls: ['./favorites-menu.component.css'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [
    TooltipModule,
    DropdownMenuComponent,
    DropdownMenuItemsComponent,
    IconComponent,
    NgbPopover,
    FavoritesEditModalComponent,
  ],
})
export class FavoritesMenuComponent extends DestroySubscribers {
  displayMode = input.required<SideNavDisplayMode>()
  collapsed = input.required<boolean>()
  courtSystemId = input.required<Uuid>()
  dropDownOpened = output<SideNavItem[]>()

  protected menuItems: StaticMenuItem[] = [
    { title: 'Favorite this page', onSelect: this.addFavoriteAction.bind(this) },
    { title: 'Edit favorites', onSelect: this.openEditModal.bind(this) },
  ]

  constructor(
    private readonly store: Store,
    private readonly currentFavoriteService: CurrentFavoriteService,
  ) {
    super()
  }

  protected readonly favorites = selectWith(() => FavoritesState.favorites(this.courtSystemId()))

  protected addFavoriteAction(): void {
    this.currentFavoriteService
      .currentFavoriteData()
      .pipe(take(1))
      .subscribe(({ userId, courtSystemId, url, label }) => {
        return this.store.dispatch(new AddFavoriteInCourtSystemAction(userId, courtSystemId!, url, label))
      })
  }

  protected openEditModal(): void {
    this.store.dispatch(new SetFavoritesEditModalVisible(true))
  }

  protected menuSideNavItems(): SideNavItem[] {
    return this.menuItems.map(menuItem => {
      return {
        type: 'BUTTON',
        ...menuItem,
      } as SideNavItem
    })
  }
}
