<ng-template #modalPortal>
  <ftr-modal
    [modalTitle]="modalDefinition().title"
    [actionText]="modalDefinition().confirmText"
    [cancelText]="modalDefinition().cancelText"
    [type]="modalDefinition().modalType"
    [actionButtonColor]="modalDefinition().confirmButtonColor"
    (action)="onAction()"
    (close)="onClose()"
    #modal
  >
    <ng-container #modalContents>
      <span markdown>{{ modalTextContent() }}</span>
    </ng-container>
  </ftr-modal>
</ng-template>
