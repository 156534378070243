import { Injectable } from '@angular/core'
import { ApiClient, ApiClientFactory, serializeHttpParams } from '@ftr/api-shared'
import { PrivateConfiguration, PublicConfiguration, UpdateConfigurationBody } from '@ftr/contracts/api/configuration'
import { EntityType } from '@ftr/contracts/api/permissions'
import { CourtSystemIdQuery } from '@ftr/contracts/api/shared'
import { Uuid } from '@ftr/contracts/type/shared'
import { ApiResult, createAndAssign, mapData } from '@ftr/foundation'
import { classToPlain } from '@ftr/serialization'

@Injectable({
  providedIn: 'root',
})
export class CoreConfigurationService {
  private readonly apiClient: ApiClient

  constructor(private apiClientFactory: ApiClientFactory) {
    this.apiClient = this.apiClientFactory.build('/configuration')
  }

  getByCourtSystem(courtSystemId: Uuid): ApiResult<PublicConfiguration> {
    const params = createAndAssign(CourtSystemIdQuery, { courtSystemId })
    return this.apiClient.get<PublicConfiguration>({
      params: serializeHttpParams(params),
    })
  }

  getExtendedConfigByCourtSystem(courtSystemId: Uuid): ApiResult<PrivateConfiguration> {
    const params = createAndAssign(CourtSystemIdQuery, { courtSystemId })
    return this.apiClient.get<PrivateConfiguration>({
      params: serializeHttpParams(params),
    })
  }

  getEnabledRestrictedPermissionEntities(courtSystemId: Uuid): ApiResult<EntityType[]> {
    return this.getExtendedConfigByCourtSystem(courtSystemId).pipe(
      mapData(configuration => {
        const restrictions: EntityType[] = []
        if (configuration.restrictedByLocation) {
          restrictions.push('location')
        }
        if (configuration.restrictedByDepartment) {
          restrictions.push('department')
        }
        return restrictions
      }),
    )
  }

  update(configurationId: Uuid, updateConfigurationBody: UpdateConfigurationBody): ApiResult<PrivateConfiguration> {
    return this.apiClient.patch({
      path: `/${configurationId}`,
      body: classToPlain(updateConfigurationBody),
    })
  }
}
