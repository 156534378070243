import { LocalTimeRange } from '@ftr/contracts/type/shared'
import { ArrayUtils } from '@ftr/foundation'
import { LocalTime } from '@js-joda/core'
import {
  HearingTimelineSection,
  SealedTimelineSection,
  SessionTimelineSection,
  TimelineSection,
  TimelineSectionWithChildren,
} from '../types'
import { sortTimelineSectionsWithChildren } from './sort-timeline-sections-with.children'

/**
 * Maps session, hearing and sealed sections to a nested structure.
 *
 * Assumptions:
 * - session sections do not overlap with each other
 * - sealed sections do not overlap with each other
 * - hearing sections do not overlap with each other
 * - sealed and hearing sections are always completely contained within exactly 1 session section
 *
 * The resulting structure is a ordered list of session sections, each containing a list of hearing sections and sealed
 * sections. Where hearings and sealed overlap, the hearing is prioritized as the parent. If needed, the sealed section
 * is split into multiple sections to accommodate this.
 *
 * @param sessionTimes
 * @param hearingTimes
 * @param sealedTimes
 * @param liveLocalTime
 */
export function mapToNestedTimelineSections(
  sessionTimes: SessionTimelineSection[],
  hearingTimes: HearingTimelineSection[],
  sealedTimes: SealedTimelineSection[],
  liveLocalTime: LocalTime | undefined,
): TimelineSectionWithChildren[] {
  // Split sealed sections into multiple sections if they overlap with hearing times
  const hearingBoundaries = ArrayUtils.deduplicateByHash(
    hearingTimes.flatMap(hearing => [hearing.startTime, hearing.endTime]),
    time => time.toString(),
  )
  const splitSealedSections = sealedTimes
    .flatMap(sealed => {
      const sections: TimelineSectionWithChildren[] = [{ ...sealed, isOngoing: false, children: [] }]
      hearingBoundaries
        .filter(boundary => boundary.isAfter(sealed.startTime) && boundary.isBefore(sealed.endTime))
        .sort((a, b) => a.compareTo(b))
        .forEach(boundary => {
          const lastSection = sections.pop()!
          sections.push({ ...lastSection, endTime: boundary }, { ...lastSection, startTime: boundary })
        })
      return sections
    })
    .map(sealedSection => ({ ...sealedSection, isOngoing: isOngoing(sealedSection, liveLocalTime) }))

  const hearingsSections: TimelineSectionWithChildren[] = hearingTimes.map(hearing => ({
    ...hearing,
    isOngoing: isOngoing(hearing, liveLocalTime),
    children: [],
  }))

  const sessionSections: TimelineSectionWithChildren[] = sessionTimes.map(session => ({
    ...session,
    isOngoing: isOngoing(session, liveLocalTime),
    children: [],
  }))

  // Merge sealed sections with hearing sections
  const hearingsAndSealedSections: TimelineSectionWithChildren[] = [...hearingsSections]
  splitSealedSections.forEach(sealed => {
    const hearing = hearingsSections.find(section =>
      new LocalTimeRange(section.startTime, section.endTime).contains(sealed.startTime),
    )
    if (hearing) {
      hearing.children.push(sealed)
    } else {
      hearingsAndSealedSections.push(sealed)
    }
  })

  // add hearings and sealed sections to the session sections
  hearingsAndSealedSections.forEach(hearingOrSealedSection => {
    const session = sessionSections.find(section =>
      new LocalTimeRange(section.startTime, section.endTime).contains(hearingOrSealedSection.startTime),
    )
    session?.children.push(hearingOrSealedSection)
  })

  return sortTimelineSectionsWithChildren(sessionSections)
}

function isOngoing(timelineSection: TimelineSection, liveLocalTime: LocalTime | undefined): boolean {
  return (
    liveLocalTime !== undefined &&
    new LocalTimeRange(timelineSection.startTime, timelineSection.endTime).contains(liveLocalTime)
  )
}
