<div
  class="toast"
  [attr.role]="payload.type === toastType.Error ? 'alert' : 'status'"
  aria-atomic="true"
  [attr.aria-live]="payload.type === toastType.Error ? 'assertive' : 'polite'"
>
  <div class="toast__icon">
    @if (payload.iconTemplate) {
      <ng-container *ngTemplateOutlet="payload.iconTemplate"></ng-container>
    } @else {
      @switch (payload.type) {
        @case (toastType.Error) {
          <ftr-icon icon="Error" color="Brand"></ftr-icon>
        }
        @case (toastType.Info) {
          <ftr-icon icon="Info" color="Primary"></ftr-icon>
        }
        @case (toastType.Success) {
          <ftr-icon icon="CheckCircle" color="Apple"></ftr-icon>
        }
        @case (toastType.Warning) {
          <ftr-icon icon="Warning" color="Lemon"></ftr-icon>
        }
      }
    }
  </div>
  <div class="toast__content">
    @if (title) {
      <div class="toast__title">{{ title }}</div>
    }
    <div class="toast__message">
      @if (payload.messageTemplate) {
        <ng-container *ngTemplateOutlet="payload.messageTemplate; context: { $implicit: this }"></ng-container>
      } @else {
        {{ message }}
      }
    </div>
  </div>
  @if (!payload.hideClose) {
    <div class="toast__close-container">
      <button class="toast__close" aria-label="Close" (click)="remove()" ftrButton displayType="icon" size="small">
        <ftr-icon icon="Close" color="GreyLight" align="Top" [interactive]="true"></ftr-icon>
      </button>
    </div>
  }
</div>
