export class CachedValue<T> {
  cachedAt: Date

  constructor(readonly value: T) {
    this.cachedAt = new Date()
  }

  hasNotExpired(expiryMs: number): boolean {
    const cutoff = new Date().getTime() - expiryMs
    return this.cachedAt.getTime() >= cutoff
  }
}
