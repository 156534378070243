export enum SearchParams {
  // Note: this query param and type are used in Google Analytics to track Site Search usage.
  // Do not change it.
  SearchTerm = 'q',
  SearchType = 'searchType',
  EntitiesToSearch = 'entities',
  RecordingId = 'recordingId',
  SearchPageNumber = 'searchPage',
  SearchPageToken = 'page',
}
