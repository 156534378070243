import { AsyncPipe, CommonModule } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'
import { RouterModule } from '@angular/router'
import { MAIN_CONTAINER_CLASS, PAGE_CONTAINER_CLASS } from '@ftr/foundation'
import { UserState } from '@ftr/ui-user'
import { Store } from '@ngxs/store'
import { Observable, combineLatest, map } from 'rxjs'
import { CourtFooterMenuItemsProvider } from '../court-footer-menu-items-provider/court-footer-menu-items.provider'
import { FooterAdminComponent } from '../footer-admin'
import { FooterDisclaimerComponent } from '../footer-disclaimer'
import { FooterHelpLinksComponent } from '../footer-help-links'
import { FooterLoggedInComponent } from '../footer-logged-in'
import { FooterLoggedOutComponent } from '../footer-logged-out'
import { FtrLogoComponent } from '../ftr-logo'

interface FooterViewModel {
  hasAdminPermission: boolean
  isLoggedIn: boolean
  hasMinimumItems: boolean
}

@Component({
  selector: 'ftr-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  standalone: true,
  imports: [
    FooterAdminComponent,
    CommonModule,
    FtrLogoComponent,
    RouterModule,
    FooterDisclaimerComponent,
    FooterHelpLinksComponent,
    AsyncPipe,
    FooterLoggedInComponent,
    FooterLoggedOutComponent,
  ],
})
export class FooterComponent implements OnInit {
  viewModel: Observable<FooterViewModel>
  @Input() hideFooterMargin = false

  readonly mainContainerClass = MAIN_CONTAINER_CLASS
  readonly pageContainerClass = PAGE_CONTAINER_CLASS

  private minAdminMenuItems = 2

  constructor(
    private readonly courtNavItemProvider: CourtFooterMenuItemsProvider,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.viewModel = combineLatest([
      this.store.select(UserState.isLoggedIn),
      this.store.select(UserState.hasAdminPermissionsInCourtSystem),
      this.courtNavItemProvider.provide().pipe(map(items => items.length >= this.minAdminMenuItems)),
    ]).pipe(
      map(([isLoggedIn, hasAdminPermission, hasMinimumItems]) => ({
        isLoggedIn,
        hasAdminPermission,
        hasMinimumItems,
      })),
    )
  }
}
