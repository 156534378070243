export enum LogoTheme {
  /**
   * White Logo and White Text
   */

  White = 'white',

  /**
   * Red Logo and Dark Text
   */
  Themed = 'themed',

  /**
   * Red Logo and White Text
   */
  ThemedWhite = 'themed-white',

  /**
   * Red Logo and No Text
   */
  ThemedNoText = 'themed-no-text',

  /**
   * White Logo and No Text
   */
  WhiteNoText = 'white-no-text',
}

export enum LogoSize {
  Default = 'default',
  Small = 'small',
  ExtraSmall = 'extra-small',
}
