import { AfterViewChecked, Directive, ElementRef, Renderer2 } from '@angular/core'
import { Renderer, marked } from 'marked'

@Directive({
  standalone: true,
  selector: '[markdown]',
})
export class MarkdownDirective implements AfterViewChecked {
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  linkRenderer(): Renderer {
    const renderer = new Renderer()
    renderer.link = function (...args) {
      const link = Renderer.prototype.link.apply(this, args)
      return link.replace('<a', "<a target='_blank'")
    }
    return renderer
  }

  ngAfterViewChecked(): void {
    if (this.elementRef.nativeElement.innerText) {
      const markText = this.elementRef.nativeElement.innerHTML

      if (!markText || markText.length <= 0) {
        return
      }

      const customMarked = marked.setOptions({ renderer: this.linkRenderer() })

      const markdownHtml = customMarked(markText)

      if (this.elementRef.nativeElement.innerHTML === markdownHtml) {
        return
      }

      this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', markdownHtml)
    }
  }
}
