import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { IconComponent } from '../icon'
import { ComposableDropdownBodyComponent } from './composable-dropdown-body.component'
import { ComposableDropdownHeaderComponent } from './composable-dropdown-header/composable-dropdown-header.component'
import { ComposableDropdownComponent } from './composable-dropdown.component'

@NgModule({
  imports: [CommonModule, IconComponent],
  declarations: [ComposableDropdownComponent, ComposableDropdownBodyComponent, ComposableDropdownHeaderComponent],
  exports: [ComposableDropdownComponent, ComposableDropdownBodyComponent, ComposableDropdownHeaderComponent],
})
export class ComposableDropdownModule {}
