import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'

@Component({
  standalone: true,
  selector: 'ftr-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.css'],
  imports: [CommonModule],
})
export class PanelComponent {
  @Input() panelStyle: 'fluid' | 'flat' | 'centered' | 'narrow' = 'fluid'
  @Input() displayMode: 'DEFAULT' | 'FILL' = 'DEFAULT'
}
