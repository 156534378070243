import { Injectable } from '@angular/core'
import { ApiClient, ApiClientFactory, serializeHttpParams } from '@ftr/api-shared'
import { Feature, FindFeaturesQuery } from '@ftr/contracts/api/feature'
import { FeatureName } from '@ftr/contracts/type/core'
import { Uuid } from '@ftr/contracts/type/shared'
import { ApiResult, MapUtils, createAndAssign, mapData } from '@ftr/foundation'

@Injectable({
  providedIn: 'root',
})
export class EnabledCourtSystemFeaturesService {
  private readonly apiClient: ApiClient

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClient = apiClientFactory.build('/feature')
  }

  /**
   * Call the API to get the features a court system has enabled.
   */
  findEnabledFeaturesForCourtSystem(courtSystemId: Uuid): ApiResult<Feature[]> {
    return this.apiClient.get({
      path: '/findEnabledFeaturesForCourtSystem',
      params: serializeHttpParams(createAndAssign(FindFeaturesQuery, { courtSystemId })),
    })
  }

  getEnabledFeatureMap(courtSystemId: Uuid): ApiResult<Map<FeatureName, boolean>> {
    return this.findEnabledFeaturesForCourtSystem(courtSystemId).pipe(
      mapData(features =>
        MapUtils.buildFromItems(
          features,
          f => f.name,
          f => f.isEnabled,
        ),
      ),
    )
  }

  isAnyEnabled(courtSystemId: Uuid, ...features: FeatureName[]): ApiResult<boolean> {
    return this.getEnabledFeatureMap(courtSystemId).pipe(
      mapData(featureMap => features.reduce((acc, feature) => acc || featureMap.get(feature) || false, false)),
    )
  }
}
