<li class="side-nav-item" routerLinkActive="side-nav-item--active" (click)="hide()">
  @if (navItem.path) {
    <a data-track [routerLink]="navItem.path" [queryParams]="navItem.queryParams" class="side-nav-item__link">
      <ng-container *ngTemplateOutlet="actionContentTemplate"></ng-container>
    </a>
  } @else {
    <button data-track class="side-nav-item__button" (click)="select()">
      <ng-container *ngTemplateOutlet="actionContentTemplate"></ng-container>
    </button>
  }

  <ng-template #actionContentTemplate>
    @if (navItem.icon) {
      <span class="side-nav-item__icon">
        <ftr-icon [icon]="navItem.icon" align="TextBottom" gapRight="Small"></ftr-icon>
      </span>
    }
    <span class="side-nav-item__title">{{ navItem.title }}</span>
  </ng-template>
</li>
