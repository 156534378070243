<div class="desktop-login-splash">
  <img src="/assets/images/logo-themed-no-text.svg" alt="For The Record" />
  <h1 class="desktop-login-splash__title">Launching For The Record</h1>
  @if (outgoingIdentityProvider) {
  <p>
    A {{ outgoingIdentityProvider }} login page will automatically open in your default web browser to complete the
    process. If it doesn’t appear, check your browser or
    <a class="link link--no-underline" href="javascript:void(0)" (click)="navigateToDefaultPage()">
      return to the login page
    </a>
    to try again.
  </p>
  } @if (!outgoingIdentityProvider) {
  <p>
    You can
    <a class="link link--no-underline" href="javascript:void(0)" (click)="navigateToDefaultPage()">
      continue to For The Record in your browser
    </a>
    <br />
    or close this tab if you're done.
  </p>
  }
</div>
