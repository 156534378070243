import { CommonModule } from '@angular/common'
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
} from '@angular/core'

export enum PillSize {
  STANDARD = 'standard',
}
@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'ftr-pill',
  templateUrl: './pill.component.html',
  styleUrls: ['./pill.component.css'],
})
export class PillComponent implements OnInit {
  @Input() size = PillSize.STANDARD
  @Input() iconBefore: TemplateRef<unknown> | null = null
  @Input() iconAfter: TemplateRef<unknown> | null = null
  /**
   * By default, pills are controls that require interaction.
   * A tabIndex allows users to tab to the component using their keyboard.
   */
  @Input()
  @HostBinding('attr.tabIndex')
  tabIndex = 1

  @Input() value?: string
  @Input() isNotClickable?: boolean
  @Output() iconAfterClick = new EventEmitter<string>()

  private prefix = 'pill'
  private delimiter = '--'
  private sizes: string[]

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    // Add sizes as required, e.g. small, large
    this.sizes = [PillSize.STANDARD]

    this.setElement()
  }

  get nativeElement(): HTMLElement {
    return this.elementRef.nativeElement
  }

  @HostListener('focus')
  focus(): void {
    if (!this.isNotClickable) {
      this.applyClass(`${this.prefix}${this.delimiter}focused`)
    }
  }

  @HostListener('blur')
  blur(): void {
    this.removeClass(`${this.prefix}${this.delimiter}focused`)
  }

  onIconAfterClick(event: Event): void {
    event.stopPropagation()
    this.iconAfterClick.emit(this.value)
  }

  private setElement(): void {
    this.applyClass(this.prefix)

    if (this.size && this.sizes.indexOf(this.size) > -1) {
      this.applyClass(`${this.prefix}${this.delimiter}${this.size}`)
    }

    if (this.iconBefore) {
      this.applyClass(`${this.prefix}${this.delimiter}${this.size}${this.delimiter}icon-before`)
    }

    if (this.iconAfter) {
      this.applyClass(`${this.prefix}${this.delimiter}${this.size}${this.delimiter}icon-after`)
    }
  }

  private applyClass(cssClass: string): void {
    this.renderer.addClass(this.elementRef.nativeElement, cssClass)
  }

  private removeClass(cssClass: string): void {
    this.renderer.removeClass(this.elementRef.nativeElement, cssClass)
  }
}
