import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { SimpleWindowRefService } from '@ftr/foundation'
import stringify from 'json-stringify-safe'
import { duration } from 'moment-timezone'
import {
  Observable,
  Subject,
  bufferWhen,
  debounceTime,
  map,
  mergeMap,
  range,
  retryWhen,
  share,
  tap,
  timer,
  zipWith,
} from 'rxjs'
import { OBSERVABILITY_CONFIGURATION, ObservabilityConfiguration } from '../observability-configuration'

type LogLevel = 'ERROR' | 'WARN' | 'INFO' | 'DEBUG'

/**
 * Prepare an object for serialization by JSON.stringify
 */
type Serializer = (data: any) => any

type Serializers = Record<string, Serializer>

interface LogMessage {
  level: LogLevel
  referrer?: string
  username: string
  userAgent: string
  dateTime: Date
  url: string
  online?: boolean
  data: Object
}

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  static readonly SEND_DEBOUNCE_MILLIS = duration(1, 'second').asMilliseconds()
  static readonly RETRY_DELAY_SECONDS = 5
  static readonly RETRY_DELAY_MILLIS = duration(LoggingService.RETRY_DELAY_SECONDS, 'seconds').asMilliseconds()
  static readonly RETRY_ATTEMPTS = 5

  static readonly MESSAGES = {
    REQUEST_FAILED: 'LoggingService: Sumologic request failed',
    RETRYING_REQUEST: 'LoggingService: Retrying...',
    RETRIES_FAILED: 'LoggingService: Retries failed, discarding message.',
  }

  username = ''
  readonly messageQueueObservable: Observable<LogMessage>

  private readonly sumoUrl: string
  private readonly userAgent: string

  private readonly messageSubject: Subject<LogMessage>

  private serializers: Serializers = {
    error: serializeError,
    err: serializeError,
  }

  constructor(
    private windowRefService: SimpleWindowRefService,
    private http: HttpClient,
    @Inject(OBSERVABILITY_CONFIGURATION) private readonly config: ObservabilityConfiguration,
  ) {
    this.sumoUrl = this.config.logging.url
    this.userAgent = this.windowRefService.nativeWindow().navigator.userAgent

    this.messageSubject = new Subject<LogMessage>()
    this.messageQueueObservable = this.messageSubject.asObservable().pipe(share())

    this.configureLogMessageQueue()
  }

  debug(data: Object): void {
    this.log('DEBUG', data)
  }

  info(data: Object): void {
    this.log('INFO', data)
  }

  warn(data: Object): void {
    this.log('WARN', data)
  }

  error(data: Object): void {
    this.log('ERROR', data)
  }

  private log(level: LogLevel, data: Object): void {
    const message = this.createMessage(level, data)

    if (!message.online && message.level === 'ERROR') {
      message.level = 'WARN'
    }

    if (this.config.logging.debug) {
      debugLog(level, message)
    } else {
      if (message.level !== 'DEBUG') {
        this.messageSubject.next(message)
      }
    }
  }

  private createMessage(level: LogLevel, data: Object): LogMessage {
    const message: LogMessage = {
      level,
      username: this.username,
      userAgent: this.userAgent,
      dateTime: new Date(),
      url: this.windowRefService.nativeWindow().location.href,
      online: this.windowRefService.isOnline(),
      data: this.prepare(data),
    }
    const referrer = this.windowRefService.referrer()
    if (referrer) {
      message.referrer = referrer
    }
    return message
  }

  private configureLogMessageQueue(): void {
    this.messageQueueObservable
      .pipe(
        bufferWhen(() => this.messageQueueObservable.pipe(debounceTime(LoggingService.SEND_DEBOUNCE_MILLIS))),
        map((messages: LogMessage[]) => messages.map(m => stringify(m)).join('\n')),
        mergeMap(this.performRetryableHttpRequest.bind(this)),
      )
      .subscribe()
  }

  private performRetryableHttpRequest(payload: string): Observable<Object> {
    return this.http.post(this.sumoUrl, payload, { responseType: 'json' }).pipe(
      tap(
        () => undefined,
        error => console.log(LoggingService.MESSAGES.REQUEST_FAILED, error),
      ),
      retryWhen(attempts =>
        /*
            This retry strategy binds together the incoming stream and a stream of 5 attempts, to sequentially
            attempt to get a successful emit from the source observable, which in this case is the HTTP post request.
            Between each retry we wait a fixed amount of time, logging each attempt for visibility.
           */
        range(1, LoggingService.RETRY_ATTEMPTS).pipe(
          zipWith(attempts),
          mergeMap(() =>
            timer(LoggingService.RETRY_DELAY_MILLIS).pipe(
              tap(() => console.log(LoggingService.MESSAGES.RETRYING_REQUEST)),
            ),
          ),
        ),
      ),
      tap(
        () => undefined,
        error => console.error(LoggingService.MESSAGES.RETRIES_FAILED, error),
      ),
    )
  }

  private prepare(data: Object): Object {
    return Object.getOwnPropertyNames(data).reduce((preparedData, propertyName) => {
      const noOp: Serializer = d => d
      const serializer = this.serializers[propertyName] || noOp
      preparedData[propertyName] = serializer((data as any)[propertyName])
      return preparedData
    }, {} as any)
  }
}

function debugLog(level: LogLevel, message: LogMessage): void {
  switch (level) {
    case 'ERROR':
      return console.error(message)
    case 'WARN':
      return console.warn(message)
    case 'DEBUG':
      // NOOP -- too many logs from API calls.
      return
    case 'INFO':
      return console.info(message)
    default:
      return console.log(message)
  }
}

function serializeError(error: any): any {
  if (!error) {
    return error
  }

  const errorProperties = ['message', 'arguments', 'type', 'name', 'code', 'stack']
  return errorProperties.reduce((preparedError, propertyName) => {
    if (error[propertyName]) {
      preparedError[propertyName] = error[propertyName]
    }
    return preparedError
  }, {} as any)
}
