/**
 * A helper function used to typenarrow an element if it isn't null or undefined
 * Mostly useful in chained filter methods in RX or array ops
 * example:
 *
 * Usage (RXJS):
 * const nullableItems$ : Observable<string | undefined>
 * const items$: Observable<string> = nullableItems.pipe(filter(isNotNullOrUndefined))
 *
 * Usage (array):
 * const nullableItems$ : Array<string | undefined>
 * const items: string[] = nullableItems.filter(isNotNullOrUndefined)
 *
 * Note you can then chain other operators (such as map) without assignment or null assertions (item!)
 *
 * example (RXJS):
 * const nullableItems$ : Observable<number | undefined>
 * const mappedItems$: Observable<string> = nullableItems.pipe(filter(isNotNullOrUndefined), map(item => item.toString()))
 *
 */
export function isNotNullOrUndefined<T>(item: T): item is NonNullable<T> {
  return item !== null && item !== undefined
}

/**
 * A helper function used to typenarrow an element if all the props specified are not null or undefined
 * Mostly useful in chained filter methods in RX or array ops
 * example:
 * Usage (RXJS):
 * const nullableItems$ : Observable<{ someProp: string | undefined, someOtherProp: number, someOtherNullProp: null}>
 * const items$: Observable<{ someProp: string, someOtherProp: number, someOtherNullProp: null}> = nullableItems.pipe(filter(propsNotNullOrUndefined('someProp')))
 */
export function hasNonNullOrUndefinedProperties<T>(
  ...propertyNames: (keyof T)[]
): (item: T) => item is { [K in keyof T]: NonNullable<T[K]> } {
  return (item): item is { [K in keyof T]: NonNullable<T[K]> } =>
    isNotNullOrUndefined(item) && propertyNames.every(propName => isNotNullOrUndefined(item[propName]))
}

/**
 * A helper function used to typenarrow a string or array if it isn't empty
 * Mostly useful in chained filter methods in RX or array ops
 * @see isNotNullOrUndefined
 */
export function isOfNonZeroLength<T extends unknown[] | string | null | undefined>(
  item: T,
): item is Exclude<NonNullable<T>, '' | []> {
  return isNotNullOrUndefined(item) && item.length > 0
}
