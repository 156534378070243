import { Inclusivity, JsJodaUtils, Optional } from '@ftr/foundation'
import { LocalDateTime } from '@js-joda/core'
import {
  RealTimeRemarkSearchHighlightingContext,
  RealTimeSearchHighlights,
  RealTimeSttRemark,
  RealTimeSttRemarkWithMetadata,
} from '../types'
export class RealTimeSttRemarkUtils {
  /**
   * It's assumed the current remark will always have been transcribed and have a speaker id and start time
   * Show the remark's speaker name when:
   * - the current speaker id is different to the previous speaker id; or
   * - it's the first remark in the segment; or
   * - the previous remark has no speaker id; or
   * - the previous remark is not finalized; or
   * - it's the first remark with a start time within that minute;
   */
  public static isFirstRemarkOfSpeaker(
    remark: RealTimeSttRemark | RealTimeSttRemarkWithMetadata | undefined,
    prevRemark: RealTimeSttRemark | RealTimeSttRemarkWithMetadata | undefined,
  ): boolean {
    return !this.isSameUtterance(remark, prevRemark)
  }

  public static getFirstRemarkOfSpeaker<T extends RealTimeSttRemark | RealTimeSttRemarkWithMetadata>(
    remarks: readonly T[],
    index: number,
  ): T {
    const remark = remarks[index]

    // base case
    if (index === 0) {
      return remark
    }

    const prevRemark = remarks[index - 1]
    if (this.isSameUtterance(remark, prevRemark)) {
      return this.getFirstRemarkOfSpeaker(remarks, index - 1)
    }

    return remark
  }

  public static isSameUtterance(
    remark: RealTimeSttRemark | RealTimeSttRemarkWithMetadata | undefined,
    otherRemark: RealTimeSttRemark | RealTimeSttRemarkWithMetadata | undefined,
  ): boolean {
    return Optional.exists2(remark, otherRemark, (a, b) => this.isSameSpeaker(a, b) && this.isSameMinute(a, b))
  }

  public static isFirstRemarkOfMinute(
    remark: RealTimeSttRemark | RealTimeSttRemarkWithMetadata | undefined,
    prevRemark: RealTimeSttRemark | RealTimeSttRemarkWithMetadata | undefined,
  ): boolean {
    return prevRemark?.startTime === undefined || !this.isSameMinute(remark, prevRemark)
  }

  private static isSameMinute(
    remark: RealTimeSttRemark | RealTimeSttRemarkWithMetadata | undefined,
    otherRemark: RealTimeSttRemark | RealTimeSttRemarkWithMetadata | undefined,
  ): boolean {
    return Optional.exists2(remark?.startTime, otherRemark?.startTime, (a, b) => a.minute() === b.minute())
  }

  private static isSameSpeaker(
    remark: RealTimeSttRemark | RealTimeSttRemarkWithMetadata,
    otherRemark: RealTimeSttRemark | RealTimeSttRemarkWithMetadata,
  ): boolean {
    return remark.speakerId === otherRemark.speakerId
  }

  static isDuring(
    remark: RealTimeSttRemark | RealTimeSttRemarkWithMetadata,
    playerTime: LocalDateTime | undefined,
    toleranceMs: number = 0,
    inclusive: Inclusivity = Inclusivity.Open,
  ): boolean {
    if (!playerTime) {
      return false
    }

    return JsJodaUtils.isDuring(remark.startTime, remark.endTime, playerTime, toleranceMs, inclusive)
  }

  static startsBeforeAndEndsDuring(
    remark: RealTimeSttRemarkWithMetadata,
    startTime: LocalDateTime,
    endTime: LocalDateTime,
    toleranceMs: number,
  ): boolean {
    return (
      JsJodaUtils.isBeforeWithTolerance(remark.startTime, startTime, toleranceMs) &&
      JsJodaUtils.isDuring(startTime, endTime, remark.endTime, toleranceMs)
    )
  }

  static startsDuringAndEndsAfter(
    remark: RealTimeSttRemarkWithMetadata | RealTimeSttRemark,
    startTime: LocalDateTime,
    endTime: LocalDateTime,
    toleranceMs: number = 0,
  ): boolean {
    return (
      JsJodaUtils.isAfterWithTolerance(remark.endTime, endTime, toleranceMs) &&
      JsJodaUtils.isDuring(startTime, endTime, remark.startTime, toleranceMs)
    )
  }

  static startsBeforeAndEndsAfter(
    remark: RealTimeSttRemarkWithMetadata | RealTimeSttRemark,
    startTime: LocalDateTime,
    endTime: LocalDateTime,
  ): boolean {
    return remark.endTime.isAfter(endTime) && remark.startTime.isBefore(startTime)
  }

  /**
   * If this remark is fully contained within the start/end time (inclusive)
   */
  static isWithin(
    remark: RealTimeSttRemarkWithMetadata | RealTimeSttRemark,
    startTime: LocalDateTime,
    endTime: LocalDateTime,
    inclusive: Inclusivity = Inclusivity.Open,
  ): boolean {
    switch (inclusive) {
      case Inclusivity.Open:
      default:
        return (
          JsJodaUtils.isSameOrAfter(remark.startTime, startTime) && JsJodaUtils.isSameOrBefore(remark.endTime, endTime)
        )
      case Inclusivity.OpenClosed:
        return (
          JsJodaUtils.isSameOrAfter(remark.startTime, startTime) &&
          JsJodaUtils.isBeforeWithTolerance(remark.endTime, endTime)
        )
      case Inclusivity.ClosedOpen:
        return (
          JsJodaUtils.isAfterWithTolerance(remark.startTime, startTime) &&
          JsJodaUtils.isSameOrBefore(remark.endTime, endTime)
        )
      case Inclusivity.Closed:
        return (
          JsJodaUtils.isAfterWithTolerance(remark.startTime, startTime) &&
          JsJodaUtils.isBeforeWithTolerance(remark.endTime, endTime)
        )
    }
  }

  static getSearchHighlightingContextForRemark(
    remark: RealTimeSttRemarkWithMetadata,
    searchHighlights: RealTimeSearchHighlights,
  ): RealTimeRemarkSearchHighlightingContext | undefined {
    // Selected highlight takes priority over hovered
    if (searchHighlights.selected && searchHighlights.selected.remarkMatchInfo.remarkId === remark.id) {
      return searchHighlights.selected.highlightContext
    }
    if (searchHighlights.hovered && searchHighlights.hovered.remarkMatchInfo.remarkId === remark.id) {
      return searchHighlights.hovered.highlightContext
    }

    return undefined
  }
}
