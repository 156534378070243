import { Injectable } from '@angular/core'
import { DesktopDownloadUrlService } from '@ftr/api-core'
import { UserGroupPermissionId } from '@ftr/contracts/api/user-group'
import { FeatureName } from '@ftr/contracts/type/core'
import { Uuid } from '@ftr/contracts/type/shared'
import { isNotNullOrUndefined, titleCase, unwrapData } from '@ftr/foundation'
import { AppPaths, CourtSystemPaths } from '@ftr/routing-paths'
import { FavoritesState, FetchFavoritesForCourtSystemAction, UserState } from '@ftr/ui-user'
import { VocabularyState } from '@ftr/ui-vocab'
import { Store } from '@ngxs/store'
import { Observable, combineLatest, filter, map, of, switchMap } from 'rxjs'
import { CourtSystemNavItems } from '../court-system-nav-items'
import { SideNavGroup, SideNavItem, getGlobalAdminNavItem, getHelpSupportNavItem } from '../side-nav-item'
import { IconUrlMapperService } from './icon-mapping-service'

const ADMIN_PAGE_PERMISSIONS = [
  UserGroupPermissionId.ManageRealtimeRecorders,
  UserGroupPermissionId.ConfigureCourtSystem,
  UserGroupPermissionId.AdministerLocations,
  UserGroupPermissionId.AdministerHolidays,
  UserGroupPermissionId.RemoveTrms,
  UserGroupPermissionId.AdministerBillingGroups,
  UserGroupPermissionId.AccessAuditLogs,
]

@Injectable({
  providedIn: 'root',
})
export class InternalUserNavItemsProvider {
  constructor(
    private readonly store: Store,
    private readonly courtSystemNavItems: CourtSystemNavItems,
    private readonly desktopDownloadUrlService: DesktopDownloadUrlService,
    private readonly iconMapperService: IconUrlMapperService,
  ) {}

  provide(currentCourtSystemId: Uuid): Observable<{ navGroups: SideNavGroup[]; footerItems: SideNavItem[] }> {
    return combineLatest([
      this.store
        .select(UserState.hasPermissionInCourtSystem)
        .pipe(map(fn => fn(UserGroupPermissionId.PlaybackRecordings))),
      this.store
        .select(UserState.hasPermissionInCourtSystem)
        .pipe(map(fn => fn(UserGroupPermissionId.PlaybackHearings))),
      this.store
        .select(UserState.hasPermissionInCourtSystem)
        .pipe(map(fn => fn(UserGroupPermissionId.ProcessAudioOrders))),
      this.store
        .select(UserState.hasPermissionInCourtSystem)
        .pipe(map(fn => fn(UserGroupPermissionId.ProcessRealTimeOrders))),
      this.store
        .select(UserState.hasPermissionInCourtSystem)
        .pipe(map(fn => fn(UserGroupPermissionId.ProcessTranscriptOrders))),
      this.store
        .select(UserState.hasPermissionInCourtSystem)
        .pipe(map(fn => fn(UserGroupPermissionId.WriteRecordingVault))),
      this.store
        .select(UserState.hasPermissionInCourtSystem)
        .pipe(map(fn => fn(UserGroupPermissionId.ViewRealTimeRecorders))),
      this.store.select(UserState.hasPermissionInCourtSystem).pipe(map(fn => fn(UserGroupPermissionId.AccessReports))),
      this.store.select(UserState.currentCourtSystemEnabledFeatures).pipe(unwrapData()),
      this.store
        .select(UserState.hasPermissionInCourtSystem)
        .pipe(map(fn => fn(UserGroupPermissionId.AdministerUserGroups))),
      this.store.select(UserState.hasAnyPermissionInCourtSystem).pipe(map(fn => fn(ADMIN_PAGE_PERMISSIONS))),
      this.store.select(VocabularyState.termsByCourtSystemId).pipe(
        map(fn => fn(currentCourtSystemId)),
        filter(isNotNullOrUndefined),
      ),
      this.store.select(UserState.isGlobalAdmin),
      this.store.select(UserState.user).pipe(
        unwrapData(),
        switchMap(user => {
          this.store.dispatch(new FetchFavoritesForCourtSystemAction(user.id, currentCourtSystemId))
          return of(user)
        }),
        switchMap(() => {
          return combineLatest([
            this.store.select(FavoritesState.favorites(currentCourtSystemId)),
            this.store.select(FavoritesState.isLoading),
          ])
        }),
        map(([favorites, favoritesLoading]) => ({ favoritesLoading, favorites })),
      ),
    ]).pipe(
      map(
        ([
          hasPlaybackRecordingsPermission,
          hasPlaybackHearingsPermission,
          hasProcessAudioOrdersPermission,
          hasProcessRealTimeOrdersPermission,
          hasProcessTranscriptOrdersPermission,
          hasWriteRecordingVaultPermission,
          hasViewRealTimeRecordersPermission,
          hasAccessReportsPermission,
          currentCourtSystemEnabledFeatures,
          hasAdministerUserGroupsPermission,
          hasAnySettingsPagePermission,
          vocabularyTerms,
          isGlobalAdmin,
          favoritesConfiguration,
        ]) => {
          const navGroups: SideNavGroup[] = []
          const footerItems: SideNavItem[] = []

          const resourceGroup: SideNavGroup = {
            items: [],
          }

          resourceGroup.items?.push({
            type: 'LINK',
            icon: 'CourtroomParticipant',
            title: titleCase(vocabularyTerms.courthouse.plural),
            path: [`/${AppPaths.CourtSystem}`, currentCourtSystemId, CourtSystemPaths.Locations],
          } as SideNavItem)

          if (hasPlaybackHearingsPermission) {
            resourceGroup.items?.push({
              type: 'LINK',
              icon: 'Work',
              ...this.courtSystemNavItems.cases(currentCourtSystemId, vocabularyTerms),
            } as SideNavItem)
          }

          if (resourceGroup.items?.length) {
            navGroups.push(resourceGroup)
          }

          navGroups.push({
            title: 'Favorites',
            isLoading: favoritesConfiguration.favoritesLoading,
            items: favoritesConfiguration.favorites?.map(favorite => {
              const [path, queryParams] = urlToRouteLinkArray(favorite.url)
              return {
                type: 'LINK',
                icon: this.iconMapperService.getIconForUrl(favorite.url),
                title: favorite.label,
                path,
                queryParams,
              } as SideNavItem
            }),
          })

          if (hasPlaybackRecordingsPermission) {
            navGroups.push({
              title: 'Content',
              items: [
                {
                  type: 'LINK',
                  icon: 'Waveform',
                  ...this.courtSystemNavItems.recordings(currentCourtSystemId),
                } as SideNavItem,
              ],
            })
          }

          const orderProcessingGroup: SideNavGroup = {
            title: 'Order Processing',
            items: [],
          }

          if (hasProcessAudioOrdersPermission) {
            orderProcessingGroup.items?.push({
              type: 'LINK',
              icon: 'OrdersAudio',
              ...this.courtSystemNavItems.audioOrders(currentCourtSystemId),
              title: 'Audio',
              tooltipHint: 'Audio Order Processing',
            } as SideNavItem)
          }

          if (hasProcessRealTimeOrdersPermission) {
            orderProcessingGroup.items?.push({
              type: 'LINK',
              icon: 'OrdersRealtime',
              ...this.courtSystemNavItems.realTimeOrders(currentCourtSystemId),
              title: 'RealTime',
              tooltipHint: 'RealTime Order Processing',
            } as SideNavItem)
          }

          if (hasProcessTranscriptOrdersPermission) {
            orderProcessingGroup.items?.push({
              type: 'LINK',
              icon: 'OrdersTranscript',
              ...this.courtSystemNavItems.transcriptOrders(currentCourtSystemId),
              title: 'Transcript',
              tooltipHint: 'Transcript Order Processing',
            } as SideNavItem)
          }

          if (hasAccessReportsPermission) {
            orderProcessingGroup.items?.push({
              type: 'LINK',
              icon: 'VerticalBarChart',
              ...this.courtSystemNavItems.reports(currentCourtSystemId),
              title: 'Reports',
            } as SideNavItem)
          }

          if (orderProcessingGroup.items?.length) {
            navGroups.push(orderProcessingGroup)
          }

          const managementGroup: SideNavGroup = {
            title: 'Management',
            items: [],
          }

          const isRealTimeRecordersEnabled = !!currentCourtSystemEnabledFeatures?.some(
            feature => feature.name === FeatureName.RealTimeRecorders,
          )

          if (hasViewRealTimeRecordersPermission && isRealTimeRecordersEnabled) {
            managementGroup.items?.push({
              type: 'LINK',
              icon: 'Recorders',
              ...this.courtSystemNavItems.producers(currentCourtSystemId),
              title: 'Recorders',
            } as SideNavItem)
          }

          if (hasAdministerUserGroupsPermission) {
            managementGroup.items?.push({
              type: 'LINK',
              icon: 'People',
              ...this.courtSystemNavItems.courtUsers(currentCourtSystemId),
              title: 'Users & Groups',
            } as SideNavItem)
          }

          if (hasWriteRecordingVaultPermission) {
            managementGroup.items?.push({
              type: 'LINK',
              icon: 'TrmRepositories',
              ...this.courtSystemNavItems.recordingVault(currentCourtSystemId),
              title: 'TRM Repositories',
            } as SideNavItem)
          }

          if (hasAnySettingsPagePermission) {
            managementGroup.items?.push({
              type: 'LINK',
              icon: 'Settings',
              ...this.courtSystemNavItems.adminMenu(currentCourtSystemId),
              title: 'Settings',
            } as SideNavItem)
          }

          if (managementGroup.items?.length) {
            navGroups.push(managementGroup)
          }

          if (isGlobalAdmin) {
            footerItems.push(getGlobalAdminNavItem(currentCourtSystemId))
          }

          footerItems.push(getHelpSupportNavItem(this.desktopDownloadUrlService.getDownloadUrl()))

          return { navGroups, footerItems }
        },
      ),
    )
  }
}

const urlToRouteLinkArray = (url: string): any[] => {
  const [path, searchString] = url.split('?')
  if (!searchString) {
    return [path]
  }
  const searchParams = new URLSearchParams(searchString)
  const queryObject: Record<string, string> = {}
  for (const [key, value] of searchParams) {
    queryObject[key] = value
  }
  return [path, queryObject]
}
