import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { SUPPORTED_STATES } from '@ftr/contracts/shared/State'
import { ProductionStatus } from '@ftr/contracts/type/court/ProductionStatus'
import { BadgeColors, BadgeComponent, BadgeConfig, IconComponent, TooltipModule } from '@ftr/foundation'
import { FilterListCardComponent } from '../../filter-list'
import { CourtSystemSelectorItem } from './court-system-selector-item.interface'

export type CourtSystemSelectorBadgeTypes =
  | ProductionStatus.Sandbox
  | ProductionStatus.PreProduction
  | ProductionStatus.Archived
  | ProductionStatus.Historical
  | 'isPublished'

const BADGE_CONFIG: Record<CourtSystemSelectorBadgeTypes, BadgeConfig> = {
  [ProductionStatus.Sandbox]: {
    color: BadgeColors.Lemon,
    text: 'Sandbox',
    icon: 'Sandbox',
  },
  [ProductionStatus.PreProduction]: {
    color: BadgeColors.Primary,
    text: 'Pilot',
    icon: 'PaperPlane',
  },
  [ProductionStatus.Archived]: {
    color: BadgeColors.Grey20,
    text: 'Archived',
    icon: 'Archive',
  },
  ['isPublished']: {
    color: BadgeColors.Apple,
    text: 'Published',
    icon: 'CheckCircle',
  },
  [ProductionStatus.Historical]: {
    color: BadgeColors.Watermelon,
    text: 'Historical',
    icon: 'Historical',
  },
}

@Component({
  selector: 'ftr-court-system-selector-item',
  templateUrl: './court-system-selector-item.component.html',
  styleUrls: ['./court-system-selector-item.component.css'],
  standalone: true,
  imports: [IconComponent, BadgeComponent, TooltipModule, FilterListCardComponent],
})
export class CourtSystemSelectorItemComponent implements OnInit {
  @Input() courtSystem: CourtSystemSelectorItem
  @Input() skipBadges: CourtSystemSelectorBadgeTypes[] = []
  @Output() onSelect = new EventEmitter<CourtSystemSelectorItem>()

  stateName: string
  badgeConfigs: BadgeConfig[] = []

  readonly externalPortalTooltip = 'You will be redirected to an external transcript ordering portal'

  ngOnInit(): void {
    this.setStateName()
    this.setBadgeConfigs()
  }

  handleOnSelect(): void {
    this.onSelect.emit(this.courtSystem)
  }

  private setStateName(): void {
    this.stateName =
      SUPPORTED_STATES.find(
        s => s.countryCode === this.courtSystem.countryCode && s.code === this.courtSystem.stateCode,
      )?.name || ''
  }

  private setBadgeConfigs(): void {
    const isPublishedBadgeConfig = this.courtSystem.isPublished ? BADGE_CONFIG.isPublished : null
    if (isPublishedBadgeConfig && !this.skipBadges.includes('isPublished')) {
      this.badgeConfigs.push(isPublishedBadgeConfig)
    }

    const productionStatusBadgeConfig =
      this.courtSystem.productionStatus !== ProductionStatus.Production
        ? BADGE_CONFIG[this.courtSystem.productionStatus]
        : null
    if (
      productionStatusBadgeConfig &&
      !this.skipBadges.includes(this.courtSystem.productionStatus as CourtSystemSelectorBadgeTypes)
    ) {
      this.badgeConfigs.push(productionStatusBadgeConfig)
    }
  }
}
