@if (turnaroundOptions | async; as turnaroundOpts) {
  <ul class="form-list transcript-fields" [formGroup]="transcriptFieldsFormGroup">
    @if (turnaroundOpts.length > 1) {
      <li>
        <ftr-slider name="turnaround" [ngFormControl]="turnaroundControl" [items]="turnaroundOptions"></ftr-slider>
      </li>
    }
    @if (transcriptFieldsFormGroup.controls['paperCopies']) {
      <li>
        <h3>Paper Copies</h3>
        <div class="row">
          <div class="col-6 transcript-fields__col-label">Quantity</div>
          <div class="col-6 transcript-fields__col-value">
            <ftr-form-input-minus-plus
              [ngFormControl]="transcriptFieldsFormGroup.controls['paperCopies']"
              label="Quantity"
            >
            </ftr-form-input-minus-plus>
          </div>
        </div>
      </li>
    }
  </ul>
}
