import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap'
import { TypedContextDirective } from '../../directives'
import { ButtonComponent } from '../button'
import { IconComponent } from '../icon'
import { ScrollableComponent } from '../scrollable'
import { TooltipModule } from '../tooltip'
import { BreadcrumbService } from './breadcrumb.service'
import { BreadcrumbsDisplayComponent } from './breadcrumbs-display.component'
import { BreadcrumbsDropdownDisplayComponent } from './breadcrumbs-dropdown-display.component'
import { BreadcrumbsComponent } from './breadcrumbs.component'
import { IsExternalHomePipe } from './is-external-home.pipe'

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    IconComponent,
    ScrollableComponent,
    NgbPopoverModule,
    TooltipModule,
    ButtonComponent,
    TypedContextDirective,
  ],
  declarations: [
    BreadcrumbsDisplayComponent,
    BreadcrumbsComponent,
    BreadcrumbsDropdownDisplayComponent,
    IsExternalHomePipe,
  ],
  providers: [BreadcrumbService],
  exports: [BreadcrumbsDisplayComponent, BreadcrumbsComponent, BreadcrumbsDropdownDisplayComponent],
})
export class BreadcrumbModule {}
