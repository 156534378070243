export enum RootParams {
  /**
   * Query parameter to specify a return URL after an authentication-related detour.
   */
  ReturnUrl = 'returnUrl',
  /**
   * Query parameter to specify the authorization code returned by Cognito which will be used
   * to request the access tokens.
   */
  AuthorizationCode = 'code',
  /**
   * Query parameter to specify the initial state set by the client. This is used to prevent CSRF attacks
   * and the response value should match the initial state.
   */
  State = 'state',

  /**
   * These are used to initiate auth for the native app (ftr-desktop), along with State and AuthorizationCode
   */
  IdentityProvider = 'identityProvider',
  CodeChallenge = 'codeChallenge',
}
