import { Inject, Injectable } from '@angular/core'
import { ActivatedRouteSnapshot } from '@angular/router'
import { API_CONFIGURATION, FullApiClientConfiguration } from '@ftr/api-shared'
import { SimpleWindowRefService } from '@ftr/foundation'

@Injectable({
  providedIn: 'root',
})
export class RedirectGlobalAdmin {
  constructor(
    @Inject(API_CONFIGURATION) readonly configurationService: FullApiClientConfiguration,
    private windowRefService: SimpleWindowRefService,
  ) {}

  canActivate(activatedRoute: ActivatedRouteSnapshot): boolean {
    const pathSegments = activatedRoute.url.map(segment => segment.path).join('/')
    const redirectUrl = `${this.configurationService.globalAdminUrl}/${pathSegments}`
    this.windowRefService.open(redirectUrl, '_blank')
    return false
  }

  canActivateChild(activatedRoute: ActivatedRouteSnapshot): boolean {
    return this.canActivate(activatedRoute)
  }
}
