import { CommonModule } from '@angular/common'
import { Component, Input, TemplateRef, ViewEncapsulation } from '@angular/core'
import { fade } from '../../animations'
import { EmptyFunction, RemoteComponent, isEmpty } from '../remote'
import { ApiResult } from '../remote-data'

/**
 * Like <code>ftr-remote</code>, but with an animated enter/leave transition between states.
 * Use this component to smooth out transitions, but fall back to <code>ftr-remote</code>
 * if a more snappy transition is needed.
 *
 * @see RemoteComponent
 */
@Component({
  selector: 'ftr-animated-remote',
  templateUrl: './animated-remote.component.html',
  styleUrls: ['./animated-remote.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [fade()],
  standalone: true,
  imports: [CommonModule, RemoteComponent],
})
export class AnimatedRemoteComponent<TData, TError> {
  @Input() notAskedTemplate: TemplateRef<unknown> | null = null
  @Input() loadingTemplate: TemplateRef<unknown>
  @Input() failureTemplate: TemplateRef<unknown>
  @Input() successTemplate: TemplateRef<unknown>
  @Input() emptyTemplate: TemplateRef<unknown>
  @Input() remote: ApiResult<TData, TError>
  @Input() isEmpty: EmptyFunction<TData> = isEmpty
  @Input() displayMode: 'DEFAULT' | 'FILL' = 'DEFAULT'
}
