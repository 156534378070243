<ftr-page>
  <article class="help">
    <h1 class="help__heading">Help & Support</h1>

    <p class="help__description">
      FTR works in partnership with courts to deliver content, so you may need to contact the court directly depending
      on your issue. A number of options are available to help resolve your issue as quickly as possible.
    </p>

    <h2 class="help__heading">User Help</h2>

    <p class="help__description">
      You can find a collection of help articles by clicking the
      <ftr-help-link cls="elevio__help" label="Help Center"></ftr-help-link>
      button (depicted with a question mark icon) located at the right hand side of the top navigation bar. This allows
      you to search for and read help articles, without affecting the screen you are using.
    </p>

    <p class="help__description">
      If you are using an ad blocker, you may not see the Help Center button. This can be fixed by adding the For The
      Record site to the ad blocker’s white list. For The Record and the Help Center do not show ads at any time.
    </p>

    <p class="help__description">
      Help can also be
      <a
        data-track
        data-track-group="Support"
        class="elevio__help-fullscreen"
        href="https://ftr.elevio.help/en"
        target="_blank"
        >opened in a separate tab</a
      >, for a full screen view.
    </p>

    <br />

    <ftr-help-ordering-information [isLoggedIn]="isLoggedIn"></ftr-help-ordering-information>

    <br />

    <ftr-help-platform-support [isLoggedIn]="isLoggedIn"></ftr-help-platform-support>
  </article>
</ftr-page>
