import { Inject, Injectable } from '@angular/core'
import { BYPASS_403_REDIRECT } from '@ftr/api-core'
import {
  API_CONFIGURATION,
  ApiClient,
  ApiClientFactory,
  CustomHttpParameterCodec,
  FullApiClientConfiguration,
  HttpHeaders,
  SerializedHttpParams,
  UnknownApiError,
  serializeHttpParams,
} from '@ftr/api-shared'
import {
  ActiveRealTimeOrderRequest,
  ActiveRealTimeOrderResponse,
  CalculateDueDateResponse,
  CanSkipPaymentRequest,
  CanSkipPaymentResponse,
  CustomerOrder,
  LineItem,
  OrderResponse,
  PayOrderBalanceRequest,
  PaymentWaivedReason,
  PlaceOrderGroupRequest,
  RealTimeLineItem,
  RealTimeOrderHeartbeatResponse,
  RealTimeRejoinEligibilityResponse,
  RecordingsForOrder,
  ResubmitOrderRequest,
  UpdateLineItemRequest,
} from '@ftr/contracts/api/order'
import { Order } from '@ftr/contracts/api/order/Order'
import { CostWaiverStatus, LineItemType, OrderStatus } from '@ftr/contracts/type/order'
import { RecordingType } from '@ftr/contracts/type/recording'
import { Uuid } from '@ftr/contracts/type/shared'
import {
  ApiResult,
  ApproximateTimeRange,
  collectData,
  createAndAssign,
  mapData,
  mapFailure,
  unwrapData,
} from '@ftr/foundation'
import { classToPlain } from '@ftr/serialization'
import { isNatural } from '@ftr/ui-money'
import { TimeRangeService, getRecordingPath } from '@ftr/ui-playback'
import { LocalDateTime, ZonedDateTime } from '@js-joda/core'
import { Store } from '@ngxs/store'
import { Observable, tap } from 'rxjs'
import { FetchSavedCardsAction } from '../../store'

@Injectable()
export class OrderService {
  private readonly apiClient: ApiClient

  constructor(
    apiClientFactory: ApiClientFactory,
    @Inject(API_CONFIGURATION) private readonly configurationService: FullApiClientConfiguration,
    private readonly store: Store,
    private readonly timeRangeService: TimeRangeService,
  ) {
    this.apiClient = apiClientFactory.build('/order')
  }

  getOrderDueDate(
    placedOn: ZonedDateTime,
    hearingEndTime: LocalDateTime,
    turnaroundId: Uuid,
    courtSystemId: Uuid,
    courtroomId: Uuid,
    hearingStartTime: LocalDateTime,
  ): ApiResult<CalculateDueDateResponse> {
    const encoder = new CustomHttpParameterCodec()
    const params = {
      placedOn: placedOn.toString(),
      hearingEndTime: hearingEndTime.toString(),
      turnaroundId,
      courtSystemId,
      courtroomId,
      hearingStartTime: hearingStartTime.toString(),
    }

    return this.apiClient
      .get<CalculateDueDateResponse>({
        path: '/calculateDueDate',
        // Force casting to SerializedHttpParams here because a custom
        // encoder is being specified so we don't need to do standard
        // serialization.
        params: params as unknown as SerializedHttpParams,
        encoder,
      })
      .pipe(
        mapData(d => ({
          dueBy: ZonedDateTime.parse(d.dueBy.toString()),
        })),
      )
  }

  getWithLineItem<TLineItem extends LineItem>(orderId: Uuid): ApiResult<CustomerOrder<TLineItem>> {
    return this.apiClient.get({ path: `${orderId}/getWithLineItem`, responseBodyType: CustomerOrder })
  }

  getSharedLineItem<TLineItem extends LineItem>(orderId: Uuid): ApiResult<CustomerOrder<TLineItem>> {
    return this.apiClient.get({
      path: `${orderId}/getSharedLineItem`,
      headers: { [BYPASS_403_REDIRECT]: 'true' },
      responseBodyType: CustomerOrder,
    })
  }

  /**
   * Resubmits an order
   */
  resubmit(request: ResubmitOrderRequest): Observable<OrderResponse> {
    const message = 'An error occurred resubmitting your order. Please check the order details and try again.'
    return this.apiClient
      .post<OrderResponse>({
        path: '/resubmit',
        body: classToPlain(request),
      })
      .pipe(
        mapFailure(err => (err instanceof UnknownApiError ? new Error(message) : err)),
        unwrapData(),
        tap(() => this.store.dispatch(new FetchSavedCardsAction())),
      )
  }

  /**
   * Places an order
   * @throws PaymentFailedCardError For a failed payment
   * @throws UnknownApiError Otherwise
   */
  placeOrder(request: PlaceOrderGroupRequest): Observable<OrderResponse[]> {
    const message = 'An error occurred placing your order. Please check the order details and try again.'
    return this.apiClient.post<OrderResponse[]>({ body: classToPlain(request) }).pipe(
      mapFailure(err => (err instanceof UnknownApiError ? new Error(message) : err)),
      unwrapData(),
      tap(() => this.store.dispatch(new FetchSavedCardsAction())),
    )
  }

  /**
   * Order Id is undefined for new orders that have not been placed
   */
  canSkipPayment(
    lineItemType: LineItemType,
    courtSystemId: Uuid,
    orderId?: Uuid | null,
    userId?: Uuid,
  ): ApiResult<CanSkipPaymentResponse> {
    return this.apiClient.get({
      path: '/canSkipPayment',
      params: serializeHttpParams(
        createAndAssign(CanSkipPaymentRequest, {
          courtSystemId,
          lineItemType,
          orderId: orderId === null ? undefined : orderId,
          userId,
        }),
      ),
    })
  }

  payBalance(orderId: Uuid, request: PayOrderBalanceRequest): Observable<OrderResponse> {
    return this.apiClient
      .post<OrderResponse>({
        path: `/${orderId}/payBalance`,
        body: request,
      })
      .pipe(unwrapData())
  }

  /**
   * Allows users with process transcript order permissions to modify the order
   */
  modifyLineItem(orderId: Uuid, body: UpdateLineItemRequest): ApiResult {
    const httpHeaders: HttpHeaders = {}
    httpHeaders[BYPASS_403_REDIRECT] = 'true'

    const message = `An error occurred while saving this order.
    Please reload the page and try again, or contact support@fortherecord.com for support.`
    return this.apiClient
      .put<null>({
        path: `/${orderId}/modifyLineItem`,
        body: classToPlain(body, { enableCircularCheck: true }),
        headers: httpHeaders,
      })
      .pipe(mapFailure(err => (err instanceof UnknownApiError ? new Error(message) : err)))
  }

  getReceiptDownloadUrl(orderId: Uuid | undefined): string {
    if (!orderId) {
      return ''
    }

    return `${this.configurationService.server.url}/api/order/${orderId}/receipt`
  }

  displayReceiptDownloadUrl(order: Order): boolean {
    const orderIsCompleted = order.status === OrderStatus.Completed
    const priceIsGreaterThanZero = isNatural(order.finalPrice)
    /**
     * TODO ST-456 Use hasUnwaivableFees instead of relying on current behaviour
     * whereby an estimate is not zero if charges are held.
     */
    const hasFullCostWaiver = order.costWaiverStatus === CostWaiverStatus.Approved && !isNatural(order.estimate)
    const notBilledToUserGroup = order.paymentWaivedReason !== PaymentWaivedReason.BilledToUserGroup

    return orderIsCompleted && priceIsGreaterThanZero && !hasFullCostWaiver && notBilledToUserGroup
  }

  listByOrderGroupId<TLineItem extends LineItem>(orderGroupId: Uuid): ApiResult<CustomerOrder<TLineItem>[]> {
    return this.apiClient.get({
      path: `/listByOrderGroupId?orderGroupId=${orderGroupId}`,
      responseBodyType: CustomerOrder,
    })
  }

  stopRealTimeOrder(orderId: Uuid): ApiResult<CustomerOrder<RealTimeLineItem>> {
    return this.apiClient.post({ path: `${orderId}/stop`, responseBodyType: CustomerOrder })
  }

  performRealTimeOrderHeartbeat(orderId: Uuid): ApiResult<RealTimeOrderHeartbeatResponse> {
    return this.apiClient.post({ path: `${orderId}/heartbeat`, responseBodyType: RealTimeOrderHeartbeatResponse })
  }

  rejoinRealTimeOrder(orderId: Uuid): ApiResult<OrderResponse> {
    return this.apiClient.post<OrderResponse>({ path: `${orderId}/rejoin`, responseBodyType: OrderResponse })
  }

  isRealTimeOrderEligibleToRejoin(orderId: Uuid): Observable<boolean> {
    return this.apiClient
      .get<RealTimeRejoinEligibilityResponse>({
        path: `${orderId}/eligibleToRejoin`,
        responseBodyType: RealTimeRejoinEligibilityResponse,
      })
      .pipe(collectData(response => response.canRejoin))
  }

  getActiveRealTimeOrderForLocation(courtSystemId: Uuid, locationId: Uuid): Observable<Uuid | undefined> {
    return this.apiClient
      .post<ActiveRealTimeOrderResponse>({
        path: '/activeRealTime',
        body: new ActiveRealTimeOrderRequest(courtSystemId, locationId),
        responseBodyType: ActiveRealTimeOrderResponse,
      })
      .pipe(collectData(response => response.order?.id))
  }

  /**
   * Gets a list of audio that's available for the requested order.
   */
  listRecordingsForOrder(orderId: Uuid): ApiResult<RecordingsForOrder> {
    return this.apiClient.get<RecordingsForOrder>({
      path: `${orderId}/listRecordings`,
      responseBodyType: RecordingsForOrder,
    })
  }

  mapToAvailableTimeRanges(recordingsForOrder: RecordingsForOrder): ApproximateTimeRange[] {
    const recordingSegments = recordingsForOrder.recordings.flatMap(r => r.segments)
    return this.timeRangeService.convertToContiguousApproximateTimeRanges(recordingSegments)
  }
}

export function toRecordingUrl(
  courtSystemId: Uuid,
  recordingId: Uuid,
  recordingType: RecordingType,
  startTime?: LocalDateTime,
): string {
  const recordingUrlParts = getRecordingPath(courtSystemId, recordingId, recordingType, true)

  if (startTime) {
    recordingUrlParts.push(`?start=${startTime.toString()}`)
  }

  return recordingUrlParts.join('/')
}
