<div class="modal modal--open" [class.modal--disable-animation]="disableAnimation" (click)="close()">
  <div
    class="modal__content"
    [ngClass]="{
      'modal__content--large': size === modalSize.Large,
      'modal__content--medium': size === modalSize.Medium,
      'modal__content--extra-large': size === modalSize.ExtraLarge,
      'modal__content--fullscreen': size === modalSize.Fullscreen,
      'modal__content--fixed-height': fixedHeight,
      'modal__content--no-scroll': !!noScroll,
    }"
    cdkTrapFocus
    (click)="$event.stopPropagation()"
    [cdkTrapFocusAutoCapture]="true"
  >
    <div
      class="modal__body"
      [class.modal__body--show-loader]="loaderStyle === modalLoaderStyle.OverlaySpinner && showLoader"
      [class.modal__body--fixed-height]="fixedHeight"
    >
      <div class="modal__header" [class.modal__header--no-close-button]="preventClose">
        @if (type === modalType.Error) {
          <ftr-icon icon="Error" color="Brand" align="Top"></ftr-icon>
        }
        @if (type === modalType.Success) {
          <ftr-icon icon="CheckCircle" color="Apple" align="Top"></ftr-icon>
        }
        @if (type === modalType.Warning) {
          <ftr-icon icon="Warning" color="Lemon" align="Top"></ftr-icon>
        }
        <h2 class="modal__title">{{ modalTitle }}</h2>
      </div>
      @if (!preventClose) {
        <button class="modal__close" aria-label="Close" ftrButton displayType="icon" size="small" (click)="close()">
          <ftr-icon icon="Close" color="GreyLight" align="Top" [interactive]="true"></ftr-icon>
        </button>
      }
      <ng-content></ng-content>
      @if (!hideActions) {
        <div class="modal__buttons" [class.modal__buttons--full-width]="fullWidthButtons">
          @if (!preventClose) {
            <button ftrButton color="secondary" (click)="close()">
              {{ cancelText }}
            </button>
          }
          <button
            class="submit"
            [class.button--submitting]="showLoader"
            ftrButton
            [color]="actionButtonColor"
            (click)="onAction()"
            [disabled]="disable"
          >
            {{ actionText }}
            @if (showOpenInNewIcon) {
              <ftr-icon class="modal__button-icon" icon="OpenInNew" align="Top"></ftr-icon>
            }
            @if (loaderStyle === modalLoaderStyle.AnimatedSubmitButton && showLoader) {
              <ftr-line-loader class="submit__loader" message="" [fitToButtonBottom]="true"></ftr-line-loader>
            }
          </button>
        </div>
      }
      <ftr-loader class="modal__loader" [isLoading]="true" [iconOnly]="true"></ftr-loader>
    </div>
  </div>
</div>
