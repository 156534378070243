import { Injectable } from '@angular/core'
import { UserGroupPermissionId } from '@ftr/contracts/api/user-group'
import { StaticMenuItem } from '@ftr/menus'
import { GlobalNavItems, NavItemsProvider } from '@ftr/ui-core'
import { UserState } from '@ftr/ui-user'
import { Store } from '@ngxs/store'
import { Observable, combineLatest, map } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class AccountMenuItemsProvider implements NavItemsProvider {
  constructor(
    private readonly globalNavItems: GlobalNavItems,
    private readonly store: Store,
  ) {}

  provide(): Observable<StaticMenuItem[]> {
    return combineLatest([
      this.store
        .select(UserState.hasAnyPermissionInCourtSystem)
        .pipe(
          map(fn =>
            fn([
              UserGroupPermissionId.ProcessAudioOrders,
              UserGroupPermissionId.ProcessRealTimeOrders,
              UserGroupPermissionId.ProcessTranscriptOrders,
              UserGroupPermissionId.PlaybackRecordings,
              UserGroupPermissionId.TranscribeRecordings,
            ]),
          ),
        ),
      this.store.select(UserState.isGlobalAdmin),
    ]).pipe(
      map(([hasProcessingPermissions, isGlobalAdmin]) => {
        const items = [this.globalNavItems.accountDetails(), this.globalNavItems.myAuditLog()]

        if (isGlobalAdmin || hasProcessingPermissions) {
          items.push(this.globalNavItems.sharedRecordings())
        }

        items.push(this.globalNavItems.myUploads(), this.globalNavItems.getDesktopApp(), this.globalNavItems.logout())

        return items
      }),
    )
  }
}
