import { AsyncPipe } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { RouterLink } from '@angular/router'
import { StaticMenuItem } from '@ftr/menus'
import { Observable } from 'rxjs'
import { FooterMenuItemsProvider } from '../../navigation/footer-menu-items-provider/footer-menu-items.provider'
import { LogoSize, LogoTheme } from '../../types/logo'
import { FooterHelpLinksComponent } from '../footer-help-links/footer-help-links.component'
import { FtrLogoComponent } from '../ftr-logo/ftr-logo.component'

@Component({
  selector: 'ftr-footer-logged-in',
  templateUrl: './footer-logged-in.component.html',
  styleUrls: ['./footer-logged-in.component.css'],
  standalone: true,
  imports: [FtrLogoComponent, AsyncPipe, RouterLink, FooterHelpLinksComponent],
})
export class FooterLoggedInComponent implements OnInit {
  readonly logoThemes = LogoTheme
  readonly logoSizes = LogoSize

  menuItems: Observable<StaticMenuItem[]>

  constructor(private readonly navItemProvider: FooterMenuItemsProvider) {}

  ngOnInit(): void {
    this.menuItems = this.navItemProvider.provide()
  }
}
