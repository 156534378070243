import { AbstractControl, Validators } from '@angular/forms'

/**
 * Set the field as required and update the controls validity to reflect on the form
 */
export function setControlValidators(control: AbstractControl): void {
  control.setValidators(Validators.required)
  control.updateValueAndValidity()
}

/**
 * Remove the validator, empty the controls value and update its validity to reflect on the form
 * @param {AbstractControl} control
 */
export function resetControlAndValidators(control: AbstractControl): void {
  control.setValidators(null)
  control.setValue(undefined)
  control.updateValueAndValidity()
}
