import { Injector, Signal, ValueEqualityFn, computed, effect, inject, signal, untracked } from '@angular/core'
import { Store } from '@ngxs/store'
import { Subscription } from 'rxjs'

/**
 * Select a slice of the state and return it as a signal
 * Allows for the usage of a state slice selector which depends on input signals of a component
 *
 * This will delay reading of the input signals until they are populated.
 * Note that the store is injected via the current injection context, and it is not required to provide it.
 *
 * Example
 * someInputValue = input.required<string>()
 *
 * someStateSlice = selectWith(() => StateSlice.selectSomePart(this.someInputValue())
 *
 * @see import('@ftr/foundation/testing/jest').mockSelector
 */
export function selectWith<T>(
  selector: () => (state: any, ...args: any) => T,
  options?: { injector?: Injector; equal?: ValueEqualityFn<T> },
): Signal<T> {
  const store = options?.injector?.get(Store) ?? inject(Store)
  // Initially, our reactive result has no value, and we cannot call the selector yet as it will throw if the input signals are not populated
  const result = signal<T | typeof UNRESOLVED_VALUE>(UNRESOLVED_VALUE)
  let subscription: Subscription | undefined

  // Here, we setup a subscription to the store, which will update the result signal when the state slice (or the selector) changes
  effect(
    cleanupCb => {
      cleanupCb(() => {
        subscription?.unsubscribe()
        untracked(() => result.set(UNRESOLVED_VALUE))
      })
      const stateSliceSelector = selector()
      subscription = store.select(stateSliceSelector).subscribe(value => {
        untracked(() => result.set(value))
      })
    },
    { injector: options?.injector },
  )

  // This computed block does three things:
  // 1. It listens to the selector, so if the value of it changes, it recomputes
  // 2. It checks if the result signal is populated, and if not, it gets the CURRENT value of the state slice (which will NOT update when the state changes)
  // 3. However, if the result signal is populated, it returns the value of the signal which is managed by the effect (which WILL update when the state changes)
  return computed(
    () => {
      const stateSliceSelector = selector()
      // This will ensure we run the computed block when the result actually gets populated
      const stateSliceValue = result()
      if (stateSliceValue === UNRESOLVED_VALUE) {
        const nonReactiveStateSlice = store.selectSnapshot(stateSliceSelector)
        return nonReactiveStateSlice
      }
      return stateSliceValue
    },
    { equal: options?.equal },
  )
}

const UNRESOLVED_VALUE = Symbol('UNRESOLVED_VALUE')
