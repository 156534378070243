@if (limitedLocations | async; as locations) {
  @for (courthouse of locations; track courthouse) {
    <div class="select-search__courthouse">
      <div class="select-search__courthouse-label">
        <ftr-icon icon="AccountBalance" align="Middle"></ftr-icon>
        <span class="select-search__courthouse-name">{{ courthouse.name }}</span>
      </div>
      @for (courtroom of courthouse.children; track courtroom) {
        @if (courtroom.isShowing) {
          <div
            class="select-search__courtroom"
            [ngClass]="{
              'select-search__courtroom--selected': isSelected(courtroom),
            }"
            (click)="selectLocation(courtroom)"
          >
            {{ courtroom.name }}
          </div>
        }
      }
    </div>
  }
  @if (isMaxResults(locations)) {
    <div class="select-search__max">
      Showing {{ maxResults }} {{ terms.courthouse.plural }}. You can search to display further results.
    </div>
  }
}
