import { assertUnreachable } from '@ftr/contracts/shared/assertUnreachable'
import { AudioOrderSttContext, audioOrderSttContextEquals } from './AudioOrderSttContext'
import { RecordingSttContext, recordingSttContextEquals } from './RecordingSttContext'
import { SharedRecordingSttContext, sharedRecordingSttContextEquals } from './SharedRecordingSttContext'

export type SttContext = AudioOrderSttContext | RecordingSttContext | SharedRecordingSttContext

export interface MatchSttContextFunctions<T> {
  mapAudioOrder: (x: AudioOrderSttContext) => T
  mapRecording: (x: RecordingSttContext) => T
  mapSharedRecording: (x: SharedRecordingSttContext) => T
}

export function matchSttContext<T>(
  context: SttContext,
  { mapAudioOrder, mapRecording, mapSharedRecording }: MatchSttContextFunctions<T>,
): T {
  switch (context.type) {
    case 'audio-order':
      return mapAudioOrder(context)
    case 'recording':
      return mapRecording(context)
    case 'shared-recording':
      return mapSharedRecording(context)
    default:
      assertUnreachable(context)
  }
}

export function sttContextEquals(a: SttContext, b: SttContext): boolean {
  return matchSttContext(a, {
    mapAudioOrder: audioOrderSttContextEquals(b),
    mapRecording: recordingSttContextEquals(b),
    mapSharedRecording: sharedRecordingSttContextEquals(b),
  })
}
