import { Component, Input } from '@angular/core'
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { emailValidator } from '@ftr/forms'
import { NotificationType, PageStyle, RemoteData } from '@ftr/foundation'
import { AppPaths } from '@ftr/routing-paths'
import { AuthenticationService } from '@ftr/ui-user'
import { ReplaySubject } from 'rxjs'
import { ResetAttributesService } from '../reset-attributes.service'

interface FormSubmission {
  email: string
}

@Component({
  selector: 'ftr-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css'],
  host: {
    class: 'reset-request page',
  },
})
export class ResetComponent {
  resetForm: UntypedFormGroup
  submissionSubject = new ReplaySubject<RemoteData<void, string>>()
  submission = this.submissionSubject.asObservable()

  readonly notificationType = NotificationType
  readonly pageStyle = PageStyle.Narrow
  readonly loginPath = `/${AppPaths.Login}`

  @Input()
  showResetPasswordWarning: boolean

  constructor(
    formBuilder: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    private readonly resetAttributesService: ResetAttributesService,
    private router: Router,
  ) {
    this.resetForm = formBuilder.group({
      email: ['', [Validators.required, emailValidator]],
    })
  }

  submitForm(value: FormSubmission): Promise<boolean> {
    this.resetAttributesService.email = value.email
    return this.authenticationService
      .resetPasswordSendVerification(value.email)
      .then(() => {
        return this.router.navigate(['/reset-sent'])
      })
      .catch(() => {
        // FTRX-218 All attempts are redirected
        return this.router.navigate(['/reset-sent'])
      })
  }

  asFormControl(control: AbstractControl): UntypedFormControl {
    return control as UntypedFormControl
  }
}
