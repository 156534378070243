<ftr-select-search
  [control]="control"
  [items]="locations"
  [label]="terms.courtroom.singular | titlecase"
  [singular]="terms.courtroom.singular"
  [plural]="terms.courtroom.plural"
  [instructions]="instructions"
  [enabled]="enabled"
  [selectItem]="selectLocationEmitter"
  [filterItems]="filterLocations"
  [itemsTemplate]="itemsTemplate"
  [valueTemplate]="valueTemplate"
  [highlightError]="highlightError"
  [selectItemFunction]="selectItem"
>
  <ng-template #valueTemplate let-value>
    <span class="location-select__courthouse">({{ value.parent.name }}) </span>
    <span class="location-select__courtroom">{{ value.name }}</span>
  </ng-template>
  <ng-template #itemsTemplate let-items let-selected="selected">
    <ftr-location-select-list
      [locations]="items"
      [selectLocationEmitter]="selectLocationEmitter"
      [selected]="selected"
      [terms]="terms"
    >
    </ftr-location-select-list>
  </ng-template>
</ftr-select-search>
