import { LowerCasePipe, NgClass } from '@angular/common'
import { Component, input } from '@angular/core'
import { RemoveLocalTimeRangeResult, UpdatedLocalTimeRange } from '@ftr/contracts/type/shared'
import { LocalTimeRange } from '@ftr/contracts/type/shared/LocalTimeRange'
import { DateFormat, DatePipe } from '@ftr/foundation'

@Component({
  standalone: true,
  selector: 'ftr-real-time-sessions-conflicts-item',
  templateUrl: './real-time-sessions-conflicts-item.component.html',
  styleUrls: ['./real-time-sessions-conflicts-item.component.css'],
  imports: [NgClass, DatePipe, LowerCasePipe],
})
export class RealTimeSessionsConflictsItemComponent {
  readonly term = input.required<string>()
  readonly times = input.required<RemoveLocalTimeRangeResult>()

  protected readonly pluralizeArray = (items: (LocalTimeRange | UpdatedLocalTimeRange)[]): string =>
    items.length > 1 ? 's' : ''

  protected readonly pluralizeUpdatedLocalTimeRangeAfter = (items: UpdatedLocalTimeRange[]): string =>
    items.length > 1 || items.some(x => x.after.length > 1) ? 's' : ''

  protected readonly dateFormat = DateFormat.TimeWithSeconds
}
