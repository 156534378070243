@if (remote.isNotAsked() && notAskedTemplate) {
  <ng-container *ngTemplateOutlet="notAskedTemplate"></ng-container>
}
@if (remote.isLoading()) {
  <ng-container *ngTemplateOutlet="loadingTemplate"></ng-container>
}
@if (remote.isFailure()) {
  <ng-container *ngTemplateOutlet="failureTemplate; context: { $implicit: asFailure(remote)._error }"></ng-container>
}
@if (remote.isSuccess()) {
  @if (!isEmpty(asSuccess(remote)._data)) {
    <ng-container *ngTemplateOutlet="successTemplate; context: { $implicit: asSuccess(remote)._data }"></ng-container>
  } @else {
    <ng-container *ngTemplateOutlet="emptyTemplate"></ng-container>
  }
}
