/**
 * The default URL to redirect to on login or after sign up,
 * if the user hasn't come in directly to a URL in the system
 */
export const DEFAULT_LOGIN_ROUTE = '/'

// The state param passed to the authentication endpoint
export const STATE_PARAM = 'stateParam'

// The verifier used to create the code_challenge, required when using PKCE
export const CODE_VERIFIER_PARAM = 'codeVerifierParam'

// The identity provider used for the authentication
export const IDENTITY_PROVIDER = 'identityProvider'

/**
 * The identity provider + email combination. This will be used to compare
 * the existing session with a new login done on a different tab.
 */
export const AUTH_IDENTIFIER = 'authIdentifier'

// localStorage key for URL to return to after login
export const RETURN_URL_KEY = 'returnUrl'
// localStorage key for how to handle URL to return to after login
export const RETURN_URL_HANDLING_KEY = 'returnUrlHandling'

// localStorage key that track whether we unpack the authorization code here,
//   or forward it to the desktop app
export const RETURN_TO_DESKTOP = 'returnToDesktop'

export const RESET_PASSWORD_WARNING = 'resetPasswordWarning'

export const LOGIN_STARTED = 'loginStarted'
