import { SelectItem } from '@ftr/forms'
import { Icon } from '@ftr/foundation'
import { Observable, of } from 'rxjs'

export const SEARCH_DEBOUNCE = 300

export type NestingDisplayMode = 'flat' | 'nested'

export type FormValueType = Record<string, boolean>

export type ComplexFilterType =
  | 'radio-list'
  | 'checkbox-list'
  | 'checkbox-tree'
  | 'date-radio-list'
  | 'date'
  | 'date-range'

export type ComplexFilterValue<T extends string = string> = T | (T | undefined)[] | undefined

export type ComplexFilterData = Record<string, ComplexFilterValue>

export enum ComplexFilterModalOnly {
  MobileOnly = 'mobile-only',
  TabletAndMobile = 'tablet-and-mobile',
}

export interface ComplexFilterSection {
  modalOnly?: ComplexFilterModalOnly
  subsections: ComplexFilterSubsection[]
}

export interface ComplexFilterSubsection {
  title?: string
  filters: ComplexFilter[]
}

export enum ComplexFilterSearchable {
  ClientSide = 'client-side',
  ServerSide = 'server-side',
}

/**
 * Each filter can be optionally searchable and collapsible.
 *
 * A filter can contain an array of additional filters. This way we display the filter and its additional filters
 * under the same title and keep all their values under the same query parameter separated with QUERY_PARAM_SEPARATOR.
 * Filter keys for any additional filters are ignored.
 * Example: ...&sort=date-placed-resubmitted,desc
 */
export abstract class ComplexFilter<T extends string = string> {
  type: ComplexFilterType
  /* filterKey must be unique on the same page, except for the additional filters,
  they use the same filterKey as the parent filter */
  filterKey: string
  collapsible: boolean
  /* Observable allows us to provide a vocabulary term */
  title?: string | Observable<string>
  defaultValue?: T
  /* Indicates that Clear All doesn't apply to this filter */
  noClear?: boolean
  /* Hidden options the user cannot select, still applied if provided in the query string
   * e.g. Order status – Error */
  hiddenOptions?: SelectItem<T>[]
  /* Conditionally enable this filter, undefined means enabled */
  isEnabled?: Observable<boolean>

  /* Used to show a max count of possible options */
  maxSelectedOptions?: number

  /* It's impossible for angular to infer title correctly, given its 3 possible values.
   This is a helper to use in templates */
  titleAsObservable: Observable<string>
  icon: Icon

  protected constructor(
    type: ComplexFilterType,
    filterKey: string,
    collapsible: boolean,
    icon: Icon,
    title?: string | Observable<string>,
    defaultValue?: T,
    noClear?: boolean,
    hiddenOptions?: SelectItem<T>[],
    isEnabled?: Observable<boolean>,
  ) {
    this.type = type
    this.filterKey = filterKey
    this.collapsible = collapsible
    this.icon = icon
    this.title = title
    this.defaultValue = defaultValue
    this.noClear = noClear
    this.hiddenOptions = hiddenOptions
    this.isEnabled = isEnabled
    this.titleAsObservable = getTitleAsObservable(title)
  }
}

function getTitleAsObservable(title?: string | Observable<string>): Observable<string> {
  if (title === undefined) {
    return of('')
  }

  if (typeof title === 'string') {
    return of(title)
  }

  return title
}

export function mapFormValueToFilter(formValue: ComplexFilterData): string[] {
  return Object.entries(formValue)
    .filter(([_, value]) => !!value)
    .map(([key]) => key)
}
