import { Injectable, Provider } from '@angular/core'
import { GenericTrackingEvent } from './tracking'

/**
 * @ftr/foundation should not understand anything about the implementation of our analytics,
 * but sometimes we do want tracking events on things like buttons/validation etc.
 */
@Injectable()
export abstract class TrackingService {
  abstract track(data: GenericTrackingEvent): void
}

const DUMMY_TRACKING_SERVICE = { track: () => undefined }

export function provideTrackingService(): Provider {
  return { provide: TrackingService, useValue: DUMMY_TRACKING_SERVICE }
}
