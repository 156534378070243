import { Component, Input } from '@angular/core'
import { Icon, IconComponent } from '../icon'

export enum Actions {
  Show = 'Show',
  Hide = 'Hide',
}

@Component({
  selector: 'ftr-collapsible',
  templateUrl: './collapsible.component.html',
  styleUrls: ['./collapsible.component.css'],
  standalone: true,
  imports: [IconComponent],
})
export class CollapsibleComponent {
  @Input() title: string
  @Input() isOpen: boolean = false

  getAction(): string {
    return this.isOpen ? Actions.Hide : Actions.Show
  }

  getIcon(): Icon {
    return this.isOpen ? 'KeyboardArrowUp' : 'KeyboardArrowDown'
  }

  toggle(): void {
    this.isOpen = !this.isOpen
  }
}
