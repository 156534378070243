import { Directive, Inject, OnChanges, OnDestroy, OnInit, Optional } from '@angular/core'

import { Input, TemplateRef, ViewContainerRef } from '@angular/core'
import { Subject, distinctUntilChanged, switchMap, takeUntil } from 'rxjs'
import { FeatureFlagKeyOfType } from '../feature-flags'
import { FEATURE_FLAG_OVERRIDES, FeatureFlagOverrides } from '../services/feature-flag-overrides'
import { LaunchDarklyService } from '../services/launch-darkly.service'

@Directive({
  selector: '[ifFeatureFlagEnabled]',
})
export class LaunchDarklyFeatureFlagEnabledDirective implements OnInit, OnChanges, OnDestroy {
  @Input()
  ifFeatureFlagEnabled: FeatureFlagKeyOfType<boolean>
  readonly finalize = new Subject<void>()
  readonly flagChanged = new Subject<FeatureFlagKeyOfType<boolean>>()

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private ldService: LaunchDarklyService,
    @Optional() @Inject(FEATURE_FLAG_OVERRIDES) public overrides: FeatureFlagOverrides | undefined,
  ) {}

  ngOnInit(): void {
    if (this.overrides) {
      const flagEnabled = this.overrides.allFlags[this.ifFeatureFlagEnabled]
      this.updateView(flagEnabled)
    } else {
      this.flagChanged
        .pipe(
          distinctUntilChanged(),
          switchMap(key => this.ldService.observeFlagChanges<boolean>(key)),
          takeUntil(this.finalize),
        )
        .subscribe(enabled => this.updateView(enabled))
      this.flagChanged.next(this.ifFeatureFlagEnabled)
    }
  }

  ngOnChanges(): void {
    this.flagChanged.next(this.ifFeatureFlagEnabled)
  }

  ngOnDestroy(): void {
    this.finalize.next()
    this.finalize.complete()
  }

  updateView(enabled: boolean): void {
    if (enabled) {
      this.viewContainer.createEmbeddedView(this.templateRef)
    } else {
      this.viewContainer.clear()
    }
  }
}
