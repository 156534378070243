import { Pipe, PipeTransform } from '@angular/core'
import { escape } from 'lodash-es'

interface HighlightHtmlOptions {
  excludeHighlightClass: boolean
}

export interface HtmlHighlightPart {
  isEmBlock: boolean
  innerText: string
}

export const HIGHLIGHT_TAG = '<em>'

export const HIGHLIGHT_TAG_CLOSING = '</em>'

export const HIGHLIGHT_TAG_CLASS = '<em class="ftr-em">'

/**
 * Strips all HTML tags except for one layer deep empty <em> from the value. It sanitises html inside of these em's
 * And adds a class to <em> so we can style it
 */

@Pipe({ name: 'ftrHighlightHtml', standalone: true })
export class HighlightHtmlPipe implements PipeTransform {
  transform(value: string | undefined, options?: HighlightHtmlOptions): string {
    const htmlHighlightParts = extractHtmlHighlightParts(value ?? '')
    return this.toEscapedHighlightHtml(htmlHighlightParts, options)
  }

  private toEscapedHighlightHtml(htmlHighlightParts: HtmlHighlightPart[], options?: HighlightHtmlOptions): string {
    return htmlHighlightParts
      .map(htmlHighlightPart => {
        const escapedContent = escape(htmlHighlightPart.innerText)
        if (htmlHighlightPart.isEmBlock) {
          return (
            (options?.excludeHighlightClass ? HIGHLIGHT_TAG : HIGHLIGHT_TAG_CLASS) +
            escapedContent +
            HIGHLIGHT_TAG_CLOSING
          )
        }
        return escapedContent
      })
      .join('')
  }
}
export function extractHtmlHighlightParts(html: string): HtmlHighlightPart[] {
  let currentIndex = 0
  let closed = true
  const htmlHighlightStringParts: HtmlHighlightPart[] = []
  while (currentIndex < html.length) {
    const findTag = closed ? HIGHLIGHT_TAG : HIGHLIGHT_TAG_CLOSING
    const index = html.indexOf(findTag, currentIndex)

    if (index === -1) {
      let remainingText = html.substring(currentIndex)
      // Add back open as it was not closed
      if (!closed) {
        remainingText = HIGHLIGHT_TAG + remainingText
      }

      // No more matches
      htmlHighlightStringParts.push({
        isEmBlock: false,
        innerText: remainingText,
      })
      break
    }

    closed = !closed
    const innerText = html.substring(currentIndex, index)
    if (innerText) {
      htmlHighlightStringParts.push({
        isEmBlock: closed,
        innerText,
      })
    }

    currentIndex = index + findTag.length
  }

  return htmlHighlightStringParts
}
