import { ClassConstructor, plainToClass } from '@ftr/serialization'

export class ConflictApiError extends Error {
  constructor(readonly responseBody: unknown = undefined) {
    super('A resource conflict occurred.')
    Object.setPrototypeOf(this, ConflictApiError.prototype)
  }

  getConflictBody<T>(classConstructor: ClassConstructor<T>): T {
    return plainToClass(classConstructor, this.responseBody)
  }
}
