import { Injectable } from '@angular/core'
import { ApiClient, ApiClientFactory } from '@ftr/api-shared'
import {
  CourtSystemUseConditions,
  CourtSystemUseConditionsBody,
  UserAcceptedConditions,
} from '@ftr/contracts/api/court-use-conditions'
import { Uuid } from '@ftr/contracts/type/shared'
import { ApiResult, mapData } from '@ftr/foundation'
import { classToPlain, plainToClass } from '@ftr/serialization'

export const USE_CONDITIONS_REDIRECT_URL = 'use_conditions_redirect_url'

@Injectable({
  providedIn: 'root',
})
export class CourtSystemUseConditionsService {
  private readonly apiClient: ApiClient

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClient = apiClientFactory.build('/court-system-use-conditions')
  }

  getForCourtSystem(courtSystemId: Uuid): ApiResult<CourtSystemUseConditions> {
    return this.apiClient.get<CourtSystemUseConditions>({
      path: `?courtSystemId=${courtSystemId}`,
      responseBodyType: CourtSystemUseConditions,
    })
  }

  create(courtSystemUseConditionsBody: CourtSystemUseConditionsBody): ApiResult<CourtSystemUseConditions> {
    return this.apiClient.post({ body: classToPlain(courtSystemUseConditionsBody) })
  }

  update(id: Uuid, courtSystemUseConditionsBody: CourtSystemUseConditionsBody): ApiResult<CourtSystemUseConditions> {
    return this.apiClient.put({
      path: `/${id}`,
      body: classToPlain(courtSystemUseConditionsBody),
    })
  }

  getForUser(): ApiResult<UserAcceptedConditions[]> {
    return this.apiClient
      .get<UserAcceptedConditions[]>({ path: 'user' })
      .pipe(mapData(ac => plainToClass(UserAcceptedConditions, ac)))
  }

  userAcceptForCourtSystem(courtSystemId: Uuid): ApiResult<UserAcceptedConditions> {
    return this.apiClient.post<UserAcceptedConditions>({ path: `/court-system/${courtSystemId}/accept` })
  }
}
