import { Component, Input } from '@angular/core'
import { IdentityProviderName } from '@ftr/contracts/type/account'
import { isInDesktopApp } from '@ftr/foundation'
import { AuthenticationService } from '@ftr/ui-user'

@Component({
  selector: 'ftr-single-sign-on',
  templateUrl: './single-sign-on.component.html',
  styleUrls: ['./single-sign-on.component.css'],
})
export class SingleSignOnComponent {
  @Input() returnUrl: string | undefined
  @Input() showMockLogin: boolean

  readonly identityProvider = IdentityProviderName
  constructor(private authenticationService: AuthenticationService) {}

  async startSSOLogin(identityProvider: IdentityProviderName): Promise<void> {
    if (isInDesktopApp()) {
      await this.authenticationService.nativeLoginToBrowser(identityProvider, this.returnUrl)
    } else {
      await this.authenticationService.loginWithRedirect(identityProvider, this.returnUrl)
    }
  }
}
