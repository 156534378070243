import { CommonModule } from '@angular/common'
import { Component, EventEmitter, HostListener, Input, Output, TemplateRef } from '@angular/core'
import { OnClickOutsideDirective } from '@ftr/foundation'
import { Observable } from 'rxjs'
import { ActivePopoverStateService, PopoverState } from '../../services'
import { StaticMenuItem } from '../static-menu-item'
import { DropdownMenuItemsComponent } from './dropdown-menu-items/dropdown-menu-items.component'

@Component({
  standalone: true,
  imports: [CommonModule, DropdownMenuItemsComponent, OnClickOutsideDirective],
  selector: 'ftr-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.css'],
})
export class DropdownMenuComponent implements PopoverState {
  @Input() ariaLabel = ''
  @Input() openerTemplate: TemplateRef<any>
  @Input() menuTemplate: TemplateRef<any> | null = null
  @Input() menuItems?: Observable<StaticMenuItem[]>
  @Input() routerLinkActiveExact: boolean = true
  @Input() isLeftPositioned: boolean = false

  /**
   * In safari, some events are setting custom components as the target which
   * incorrectly trigger the `clickOutside` event. Use invalidTargetNames if you need to filter
   * out a list of specific components.
   */
  @Input() invalidTargetNames?: string[]

  // eslint-disable-next-line @angular-eslint/no-output-rename
  @Output('isOpen') statusChangeEmitter = new EventEmitter<boolean>()

  constructor(private readonly activePopoverStateService: ActivePopoverStateService) {}

  get isShowing(): boolean {
    return this.activePopoverStateService.activePopover === this
  }

  toggle(): void {
    if (!this.isShowing) {
      this.open()
    } else {
      this.close()
    }
  }

  open(_?: MouseEvent | FocusEvent | undefined): void {
    this.activePopoverStateService.activate(this)
    this.statusChangeEmitter.emit(true)
  }

  close(_?: Event): void {
    this.activePopoverStateService.deactivate()
    this.statusChangeEmitter.emit(false)
  }

  @HostListener('document:keydown.escape', ['$event'])
  handleKeydown($event: KeyboardEvent): void {
    if (this.isShowing) {
      this.close()
      $event.stopPropagation()
    }
  }

  @HostListener('keydown.Enter', ['$event'])
  toggleOnEnter($event: KeyboardEvent): void {
    this.toggle()
    $event.stopPropagation()
  }
}
