import { Pipe, PipeTransform } from '@angular/core'
import indefinite from 'indefinite'

export interface IndefiniteOptions {
  /**
   * Return only the article.
   */
  articleOnly?: boolean
  /**
   * Capitalize the article.
   */
  capitalize?: boolean
  /**
   *  Ignore the casing of the word passed in (i.e. bypassing the acronym checking).
   *  This is useful if, for some reason, you're yelling on the internet and want to make sure
   *  "UGLY GARDEN GNOME" doesn't become "a UGLY GARDEN GNOME."
   */
  caseInsensitive?: boolean
  /**
   * When numbers are passed in, they are prefixed with "a" except for 8, 11, 18, and higher numbers starting with 8.
   * However, numbers like 1100 are ambiguous. Should it be "a one thousand one hundred" or "an eleven hundred"?
   * There's not really any programmatic way to know this for sure, but if you know for sure,
   * you can use the numbers option to tell indefinite how to handle these cases.
   * The default is "formal" in which numbers are read literally (the way you'd say them if they were written out),
   * but if you pass numbers: 'colloquial', the "eleven hundred"/"eighteen hundred" readings will be used.
   */
  numbers?: 'colloquial'
}

/**
 * This pipe prefixes a singular noun with an indefinite article – a or an –
 * based on whether it begins with a vowel. Can also detect acronyms (a UFO)
 * and irregular words (an hour).
 *
 * Usage: Instead of Add a {{ terms.courtroom.singular }}
 * use Add {{ terms.courtroom.singular | ftrIndefinite }}
 */
@Pipe({ name: 'ftrIndefinite', standalone: true })
export class IndefinitePipe implements PipeTransform {
  transform(value: string | number | undefined, options?: IndefiniteOptions | undefined): string | undefined {
    if (!value) {
      return ''
    }

    return indefinite(value, options)
  }
}
