import { ExceededTotalNewParticipantsError } from './ExceededTotalNewParticipantsError'
import { InterpreterHasNoInterpretedParticipantError } from './InterpreterHasNoInterpretedParticipantError'
import { RTHNotConfigured } from './RTHNotConfigured'
import { UniqueEmailsRequiredError } from './UniqueEmailsRequiredError'

export enum RthApiErrorNames {
  ExceededTotalNewParticipantsException = 'ExceededTotalNewParticipantsException',
  UniqueEmailsRequiredException = 'UniqueEmailsRequiredException',
  CourtroomDoesNotSupportHybridException = 'CourtroomDoesNotSupportHybridException',
  InterpreterHasNoInterpretedParticipantException = 'InterpreterHasNoInterpretedParticipantException',
}

export const RthErrorNameMap = {
  [RthApiErrorNames.ExceededTotalNewParticipantsException]: (error: Error) =>
    new ExceededTotalNewParticipantsError(error.message),
  [RthApiErrorNames.UniqueEmailsRequiredException]: () => new UniqueEmailsRequiredError(),
  [RthApiErrorNames.CourtroomDoesNotSupportHybridException]: () => new RTHNotConfigured(),
  [RthApiErrorNames.InterpreterHasNoInterpretedParticipantException]: () =>
    new InterpreterHasNoInterpretedParticipantError(),
}
