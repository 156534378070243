import { Uuid } from '@ftr/contracts/type/shared'
import { ApiResult, Identifiable, mapData, tapData } from '@ftr/foundation'
import { ApiClient, ApiClientFactory } from '../api-client'
import { CacheOptions, TimedCache } from './timed-cache'

/**
 * Can retrieve a single or multiple
 */
export abstract class CacheableApiClient<T extends Identifiable> {
  apiClient: ApiClient
  cache = new Map<Uuid, TimedCache<T>>()

  constructor(
    apiClientFactory: ApiClientFactory,
    path: string,
    private readonly options: Partial<CacheOptions> = {},
  ) {
    this.apiClient = apiClientFactory.build(path)
  }

  protected abstract retrieveAll(courtSystemId: Uuid, ids: Uuid[]): ApiResult<T[]>

  findAll(courtSystemId: Uuid, ids: Uuid[], ageMs?: number): ApiResult<T[]> {
    if (ids.length === 0) {
      return ApiResult.success([] as T[])
    }

    const cache = this.getCacheForCourtSystem(courtSystemId)
    const age = ageMs ? ageMs : cache.options.evictionAgeMs

    if (cache.hasAll(ids, age)) {
      return ApiResult.success(cache.getAll(ids, age))
    }

    const cached = cache.getAll(ids)
    const missingIds = cache.getMissingIds(ids, age)
    return this.retrieveAll(courtSystemId, missingIds).pipe(
      tapData(results => this.updateCacheWithNewValues(courtSystemId, results)),
      mapData(missing => missing.concat(cached)),
    )
  }

  private updateCacheWithNewValues(courtSystemId: string, results: T[]): Map<Uuid, TimedCache<T>> {
    return this.cache.set(courtSystemId, this.getCacheForCourtSystem(courtSystemId).addAll(...results))
  }

  findById(courtSystemId: Uuid, id: Uuid): ApiResult<T | undefined> {
    return this.findAll(courtSystemId, [id]).pipe(mapData(x => x[0]))
  }

  private getCacheForCourtSystem(courtSystemId: Uuid): TimedCache<T> {
    const timedCache = this.cache.get(courtSystemId)
    if (timedCache) {
      return timedCache
    }
    const cache = new TimedCache<T>(new Map(), this.options)
    this.cache.set(courtSystemId, cache)
    return cache
  }
}
