import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import {
  EmailPillListInputComponent,
  FormInputAutofocusDirective,
  FormInputComponent,
  FormInputListComponent,
  FormInputMinusPlusComponent,
  FormInputPillListComponent,
  FormTextareaComponent,
  PillInputDirective,
  PillNavigationDirective,
  PillsetComponent,
  SearchInputComponent,
  SubmitButtonComponent,
  UntypedFormComponent,
  ValidationErrorHintDirective,
} from '@ftr/forms'
import {
  AnchorComponent,
  IconComponent,
  IndefinitePipe,
  NotificationModule,
  PillComponent,
  StopPropagationDirective,
} from '@ftr/foundation'
import { PasswordStrengthMeterComponent } from '~app/shared/password/password-strength-meter/password-strength-meter.component'
import { PasswordValidationFeedbackComponent } from '~app/shared/password/password-validation-feedback/password-validation-feedback.component'
import { PasswordValidationComponent } from '~app/shared/password/password-validation/password-validation.component'
import { PasswordComponent } from '~app/shared/password/password.component'

const STANDALONE_COMPONENTS = [
  AnchorComponent,
  FormInputAutofocusDirective,
  FormInputComponent,
  FormInputListComponent,
  FormInputMinusPlusComponent,
  FormTextareaComponent,
  SearchInputComponent,
  SubmitButtonComponent,
  UntypedFormComponent,
  PillComponent,
  FormInputPillListComponent,
  EmailPillListInputComponent,
  PillsetComponent,
]

/**
 * This module contains reusable components and directives for forms display and manipulation
 */
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IconComponent,
    NotificationModule,
    ReactiveFormsModule,
    IndefinitePipe,
    StopPropagationDirective,
    ValidationErrorHintDirective,
    PillInputDirective,
    PillNavigationDirective,
    ...STANDALONE_COMPONENTS,
  ],
  declarations: [
    PasswordComponent,
    PasswordStrengthMeterComponent,
    PasswordValidationFeedbackComponent,
    PasswordValidationComponent,
  ],
  exports: [
    FormsModule,
    PasswordComponent,
    PasswordValidationComponent,
    ReactiveFormsModule,
    ...STANDALONE_COMPONENTS,
    PillInputDirective,
    PillNavigationDirective,
  ],
  providers: [FormInputComponent],
})
export class FtrFormsModule {}
