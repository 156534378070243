import { Injectable } from '@angular/core'
import { RegionalApiClientFactory, RegionalApiClientSetupService } from '@ftr/api-regional'
import { ApiClient } from '@ftr/api-shared'
import { AudioSegmentWithStt } from '@ftr/contracts/regional-api'
import { addSpeakerToUtterances } from '@ftr/contracts/regional-api/stt'
import { Uuid } from '@ftr/contracts/type/shared'
import { ApiResult, mapData } from '@ftr/foundation'
import { switchMap } from 'rxjs'
import { RealTimeAudioSegmentWithSttRemarks } from '../../types'
import { utterancesToRemarks } from '../../utils'

@Injectable({
  providedIn: 'root',
})
export class RealTimeAudioSegmentSttService extends RegionalApiClientSetupService {
  constructor(regionalApiClientFactory: RegionalApiClientFactory) {
    super(regionalApiClientFactory)
  }

  override regionalApiPath = 'audio-segment'

  getAudioSegmentWithSttRemarks(
    courtSystemId: Uuid,
    audioSegmentId: Uuid,
    canReadStt: boolean,
  ): ApiResult<RealTimeAudioSegmentWithSttRemarks> {
    return canReadStt
      ? this.regionalApiClient(this.regionalApiPath, courtSystemId).pipe(
          switchMap(apiClient => {
            return this.getAudioSegmentWithStt(apiClient, courtSystemId, audioSegmentId).pipe(
              mapData(({ id, showSpeakers, utterances, speakers }) => {
                return {
                  id,
                  showSpeakers,
                  remarks: utterancesToRemarks(addSpeakerToUtterances(utterances, speakers)),
                }
              }),
            )
          }),
        )
      : ApiResult.success({ id: audioSegmentId, remarks: [], showSpeakers: false })
  }

  private getAudioSegmentWithStt(
    apiClient: ApiClient,
    courtSystemId: Uuid,
    audioSegmentId: Uuid,
  ): ApiResult<AudioSegmentWithStt> {
    return apiClient.get({
      path: `${audioSegmentId}/stt`,
      headers: { courtSystemId },
    })
  }
}
