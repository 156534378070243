import { Pipe, PipeTransform } from '@angular/core'
import { DateFormatOptions, MomentConvertible, toMomentAndFormat } from '../../util'

@Pipe({ standalone: true, name: 'ftrDate' })
/**
 * Converts the given date to a human readable format.
 *
 * @see DateComponent
 */
export class DatePipe implements PipeTransform {
  transform(value: MomentConvertible | null | undefined, options: DateFormatOptions): string {
    return toMomentAndFormat(value, options)
  }
}
