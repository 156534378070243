import { Injectable } from '@angular/core'
import { isForbiddenError, isNotFoundError } from '@ftr/api-shared'
import { SharedRecordingWithCourtSystem } from '@ftr/contracts/api/shared-recording'
import { Uuid } from '@ftr/contracts/type/shared'
import { ErrorPageService, RemoteData, tapFailure } from '@ftr/foundation'
import { Action, State, StateContext, createSelector } from '@ngxs/store'
import { memoize } from 'lodash-es'
import { filter, tap } from 'rxjs'
import { SharedRecordingService } from '../../services'
import { FetchSharedRecordingAction, ResetSharedRecordingStateAction } from './shared-recording.actions'
import { SharedRecordingStateModel } from './shared-recording.model'

export function defaultSharedRecordingState(): SharedRecordingStateModel {
  return {
    sharedRecording: {},
  }
}

const sharedRecordingNotAsked = RemoteData.notAsked()
export const FORBIDDEN_ERROR_MESSAGE = 'Sorry, you do not have access to this shared recording.'
export const NOT_FOUND_ERROR_MESSAGE = 'The requested shared recording does not exist.'

@State<SharedRecordingStateModel>({
  name: 'sharedRecordingState',
  defaults: defaultSharedRecordingState(),
})
@Injectable()
export class SharedRecordingState {
  constructor(
    private readonly sharedRecordingService: SharedRecordingService,
    private readonly errorPageService: ErrorPageService,
  ) {}

  static sharedRecordingById = memoize(
    (sharedRecordingId: Uuid): ((state: SharedRecordingStateModel) => RemoteData<SharedRecordingWithCourtSystem>) => {
      return createSelector(
        [SharedRecordingState],
        state => (state.sharedRecording && state.sharedRecording[sharedRecordingId]) ?? sharedRecordingNotAsked,
      )
    },
  )

  @Action(FetchSharedRecordingAction)
  fetchSharedRecording(
    { patchState }: StateContext<SharedRecordingStateModel>,
    { sharedRecordingId }: FetchSharedRecordingAction,
  ): void {
    this.sharedRecordingService
      .getById(sharedRecordingId)
      .pipe(
        filter(remote => remote.isCompleted()),
        tapFailure(error => {
          if (isForbiddenError(error)) {
            this.errorPageService.showForbiddenPageWithMessage(FORBIDDEN_ERROR_MESSAGE)
          }

          if (isNotFoundError(error)) {
            this.errorPageService.showForbiddenPageWithMessage(NOT_FOUND_ERROR_MESSAGE)
          }
        }),
        tap(remote => {
          if (remote.isSuccess()) {
            patchState({
              /* Note: we rewrite the entire shared recording each time we fetch it, so there's only one
           recording at a time. We still save sharedRecordingId to make sure it's the correct object */
              sharedRecording: {
                [sharedRecordingId]: remote,
              },
            })
          }
        }),
      )
      .subscribe()
  }

  @Action(ResetSharedRecordingStateAction)
  resetSharedRecordingState({ setState }: StateContext<SharedRecordingStateModel>): void {
    setState(defaultSharedRecordingState())
  }
}
