<ftr-page [pageStyle]="pageStyle">
  <div class="card card--borderless card--shadowless password-card confirmation-sent-card">
    <h1 class="confirmation-sent__title">Confirm Email Address</h1>
    <p class="password-card__paragraph-text">
      We've sent a message to <span class="confirmation-sent__email">{{ email }}</span
      >. Please follow the instructions in this email to confirm your email address and create your For The Record
      account.
    </p>
    @if (!isHelpShown) {
      <a (click)="showHelp()" class="confirmation-sent__link password-card__paragraph-text" data-track
        >I have not received an email</a
      >
    } @else {
      <p class="password-card__paragraph-text">
        It can take a few minutes for emails to arrive and sometimes they may appear in your spam or junk folder. If
        there is still no sign of your message you can
        <a (click)="resendCode()" class="confirmation-sent__link" data-track>request another email</a> or contact us at
        <a href="mailto:support@fortherecord.com">support&#64;fortherecord.com</a>.
      </p>
      <ftr-notification-flash></ftr-notification-flash>
    }
  </div>
</ftr-page>
