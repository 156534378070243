import { Component, EventEmitter, Input, Output } from '@angular/core'

const PREVENT_DOUBLE_CLICK_MS = 2000

@Component({
  selector: 'ftr-side-nav-menu-toggle',
  templateUrl: './side-nav-menu-toggle.component.html',
  styleUrls: ['./side-nav-menu-toggle.component.css'],
})
export class SideNavMenuToggleComponent {
  /**
   * Is the sidenav currently open?
   */
  @Input() isShowing = false
  @Output() onToggle = new EventEmitter()

  /**
   * Prevent double click accidentally closing the account menu right away
   */
  private toggleEnabled = true

  toggle(): void {
    if (this.toggleEnabled) {
      this.toggleEnabled = false
      this.onToggle.emit()
      setTimeout(() => (this.toggleEnabled = true), PREVENT_DOUBLE_CLICK_MS)
    }
  }
}
