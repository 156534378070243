import { Injectable } from '@angular/core'
import { SimpleWindowRefService, TrackingService } from '@ftr/foundation'
import { AnalyticsEvent } from './analytics-event'

@Injectable({
  providedIn: 'root',
})
export class SimpleTrackingService extends TrackingService {
  constructor(private readonly windowRefService: SimpleWindowRefService) {
    super()
  }

  track(data: AnalyticsEvent): void {
    this.windowRefService.dataLayer().push(data)
  }
}
