import { MapUtils } from './MapUtils'

export type Primitive = string | number | boolean | undefined

export class ArrayUtils {
  /**
   * Useful if you want to do a shallow comparison of an object
   *
   * Note: If the items in an array are objects. This is an is same instance check.
   *
   * Use in distinctUntilChanged, or to avoid setting a field managed by change detection
   */
  static shallowEquals<T extends unknown[]>(one: T, another: T): boolean {
    return one.length === another.length && one.every((el, idx) => el === another[idx])
  }

  /**
   * Groups this array into sections based on the given key function.
   */
  static groupBy<K extends Primitive, V>(array: V[], grouper: (v: V) => K): [K, V[]][] {
    return Array.from(MapUtils.buildGroupedFromValues(array, grouper).entries())
  }

  /**
   * Groups this array into sections based on the given key function.
   *
   * Filters out instances when the key can be undefined
   */
  static groupByDefined<K extends Primitive, V>(array: V[], grouper: (v: V) => K | undefined): [K, V[]][] {
    return this.groupBy(array, grouper).filter(([k, _]) => k !== undefined) as [K, V[]][]
  }

  /**
   * Useful if you want to do a deep comparison of an object
   *
   * Use in distinctUntilChanged, or to avoid setting a field managed by change detection
   */
  static equals<T>(one: T[], another: T[], comparator: (a: T, b: T) => boolean): boolean {
    return one.length === another.length && one.every((el, idx) => comparator(el, another[idx]))
  }

  /**
   * Useful if you want to compare by a specific property (usually an id)
   *
   * For example comparing courtrooms by their uuid.
   *
   * Use in distinctUntilChanged, or to avoid setting a field managed by change detection
   */
  static equalsProperty<T, K extends keyof T>(one: T[], another: T[], field: K): boolean {
    return this.equals(one, another, (a, b) => a[field] === b[field])
  }

  /**
   * Partitions an array into arrays of a certain size.
   * @param array the input array
   * @param size the size of each partition
   */
  static partition<T>(array: T[], size: number): T[][] {
    const output = []

    for (let i = 0; i < array.length; i += size) {
      output[output.length] = array.slice(i, i + size)
    }

    return output
  }

  /**
   * Performant way to search backwards through an array, starting at a given index
   */
  static findLast<T>(
    array: readonly T[],
    predicate: (t: T) => boolean,
    startIdx: number = array.length - 1,
  ): T | undefined {
    const idx = this.findLastIdx(array, predicate, startIdx)
    return idx !== undefined ? array[idx] : undefined
  }

  /**
   * Performant way to search backwards through an array, starting at a given index, finding the last index that
   * meets a predicate
   */
  static findLastIdx<T>(
    array: readonly T[],
    predicate: (t: T) => boolean,
    startIdx: number = array.length - 1,
  ): number | undefined {
    let idx = startIdx
    while (idx >= 0) {
      const val = array[idx]
      if (predicate(val)) {
        return idx
      } else {
        idx--
      }
    }
    return
  }

  static distinct<T extends Primitive>(array: T[]): T[] {
    return Array.from(new Set(array))
  }

  static deduplicateByHash<T>(array: T[], hashFunc: (item: T) => string): T[] {
    const hash: Record<string, T> = {}

    for (const item of array) {
      const key = hashFunc(item)

      if (!hash[key]) {
        hash[key] = item
      }
    }

    return Object.values(hash)
  }

  static collect<A, B>(array: A[], collector: (a: A) => B | undefined): B[] {
    return array.reduce((acc, value) => {
      const res = collector(value)
      return res ? acc.concat([res]) : acc
    }, [] as B[])
  }

  static generate<T>(size: number, f: (i: number) => T): T[] {
    return new Array(size).fill(null).map((_, idx) => f(idx))
  }

  static count<T>(array: T[], value: T): number {
    return ArrayUtils.countBy(array, x => x === value)
  }

  static countBy<T>(array: T[], predicate: (t: T) => boolean): number {
    return array.reduce((acc, value) => (predicate(value) ? acc + 1 : acc), 0)
  }

  /**
   * Rotates the array so that the given value is at the start
   *
   * Eg rotate(['a', 'b', 'c'], 'b') => ['b', 'c', 'a']
   */
  static rotateTo<T>(array: T[], value: T): T[] {
    const idx = array.indexOf(value)
    return idx === -1 ? array : ArrayUtils.rotate(array, idx)
  }

  /**
   * Rotates the array so that the given value index is at the start
   *
   * Eg rotate([1, 2, 3], 1) => [2, 3, 1]
   */
  static rotate<T>(array: T[], idx: number): T[] {
    if (!array.length) {
      return []
    }

    if (array.length === 1) {
      return [...array]
    }

    return array.slice(idx).concat(array.slice(0, idx))
  }
}
