/* eslint-disable max-classes-per-file */
import { Timeframe } from '@ftr/contracts/api/shared'
import { LocalTimeRange, Uuid } from '@ftr/contracts/type/shared'
import { RealTimePlaybackKey } from '@ftr/data-realtime-playback'
import { LocalDate, LocalTime } from '@js-joda/core'
import { RealTimeSttMarkerEditSource } from '../../types'

export class SetSealingMarkersAction {
  static readonly type = '[RealTimeSealing] Set Sealing Markers'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly recordingDate: LocalDate,
    readonly timeframes: Timeframe[],
  ) {}
}

export class CreateSealedSegmentAction {
  static readonly type = '[RealTimeSealing] Create Sealed Segment'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly startTime: LocalTime,
    readonly courtSystemId: Uuid,
  ) {}
}

export class DeleteSealedSegmentAction {
  static readonly type = '[RealTimeSealing] Delete Sealed Segment'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly courtSystemId: Uuid,
    readonly timeRange: LocalTimeRange,
  ) {}
}

export class UpdateSealingBoundariesAction {
  static readonly type = '[RealTimeSealing] Update Sealing Boundaries'
  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly sealingBoundaries: LocalTimeRange[],
  ) {}
}

export class UpdateSealingLiveLocalTimeAction {
  static readonly type = '[RealTimeSealing] Update Sealing Live LocalTime'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly liveLocalTime: LocalTime | undefined,
  ) {}
}

export class UpdateSealedSegmentAction {
  static readonly type = '[RealTimeSealing] Update Sealed Segment'

  constructor(
    readonly playbackKey: RealTimePlaybackKey,
    readonly markerId: string,
    readonly startTime: string,
    readonly endTime: string,
    readonly courtSystemId: string,
    readonly source?: RealTimeSttMarkerEditSource,
  ) {}
}
