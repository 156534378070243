import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { LoggingService } from '@ftr/ui-observability'
import HttpStatus from 'http-status-codes'
import { Observable, catchError, tap, throwError } from 'rxjs'
import { ConfigurationService } from '../configuration/configuration.service'

interface LogRequest {
  url: string
  body: string
  method: string
  urlWithParams: string
  responseType: string
}

/**
 * Logging for every single usage of http-client, including ApiClient and HttpClientRemoteData
 */
@Injectable({
  providedIn: 'root',
})
export class LoggingInterceptor implements HttpInterceptor {
  constructor(
    private loggingService: LoggingService,
    private readonly configurationService: ConfigurationService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Any request that is not the FTR/Regional/Court-Uploader/DJ API should not be logged.
    const isApiReq = req.url.startsWith(this.configurationService.server.rawUrl)
    const regionalEndpoints = Object.values(this.configurationService.regionalApi.endpointMap)
    const courtUploaderServerEndpoints = Object.values(this.configurationService.courtUploaderServer.endpointMap)
    const digitalJusticeApiEndpoints = Object.values(this.configurationService.digitalJusticeApi.endpointMap)
    const digitalJusticeWebEndpoints = Object.values(this.configurationService.digitalJusticeWeb.endpointMap)
    const isRegionalReq = regionalEndpoints.some(ep => req.url.startsWith(ep))
    const isCourtUploaderServerReq = courtUploaderServerEndpoints.some(ep => req.url.startsWith(ep))
    const isDigitalJusticeApiReq = digitalJusticeApiEndpoints.some(ep => req.url.startsWith(ep))
    const isDigitalJusticeWebReq = digitalJusticeWebEndpoints.some(ep => req.url.startsWith(ep))
    if (
      !isApiReq &&
      !isRegionalReq &&
      !isCourtUploaderServerReq &&
      !isDigitalJusticeApiReq &&
      !isDigitalJusticeWebReq
    ) {
      return next.handle(req)
    }

    const started = Date.now()

    return next.handle(req).pipe(
      tap(event => {
        if (event.type === HttpEventType.Response) {
          const log = {
            elapsed: Date.now() - started,
            request: formatRequestForLogs(req),
            response: event,
          }
          this.loggingService.debug(log)
        }
      }),
      catchError(err => {
        const log = {
          elapsed: Date.now() - started,
          request: formatRequestForLogs(req),
          error: err,
        }
        if (err.status && err.status < HttpStatus.INTERNAL_SERVER_ERROR) {
          this.loggingService.warn(log)
        } else {
          this.loggingService.error(log)
        }
        return throwError(() => err)
      }),
    )
  }
}

/**
 * Strip out params, headers, withCredentials, reportProgress and various other request properties.
 */
function formatRequestForLogs(req: HttpRequest<any>): LogRequest {
  const { url, body, method, urlWithParams, responseType } = req
  return {
    url,
    body,
    method,
    urlWithParams,
    responseType,
  }
}
