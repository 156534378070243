import { SelectItem } from '@ftr/forms'
import { Icon } from '@ftr/foundation'
import { Observable } from 'rxjs'
import { ComplexFilter } from './ComplexFilter'

/**
 * This filter modifies the value of the dependent filter according to the association object.
 * Any change in the value of the dependent filter will switch the radio list filter value to catchAllValue.
 * It is handled in the complex list status type component and is used to connect status type to status.
 * @see { ComplexListStatusTypeComponent }
 *
 * Example:
 * This filter – order status type (Open, All, Closed)
 * Dependent filter – order status (Awaiting Approval, In Progress, Completed)
 * Catch-all – All
 *
 * Association:
 * Open -> [Awaiting Approval, In Progress],
 * Closed -> [Completed],
 * All -> [],
 *
 * Selecting Open will change order status to [Awaiting Approval, In Progress].
 * When another order status is selected, such as Completed,
 * the value of this filter will change to All and the order status value will be retained.
 * Selecting All in this filter will clear the order status.
 */
export interface DependentFilter<DepFilterType extends string = string, MainFilterType extends string = string> {
  filter: ComplexFilter<DepFilterType>
  catchAllValue: MainFilterType
  association: { [key in MainFilterType]: DepFilterType[] }
}

export class RadioListFilter<
  MainFilterType extends string = string,
  DepFilterType extends string = string,
> extends ComplexFilter {
  options: SelectItem<MainFilterType>[]
  additionalFilters?: RadioListFilter[]
  dependentFilter?: DependentFilter<DepFilterType>

  constructor(
    filterKey: string,
    collapsible: boolean,
    icon: Icon,
    options: SelectItem<MainFilterType>[],
    title?: string | Observable<string>,
    defaultValue?: MainFilterType,
    noClear?: boolean,
    hiddenOptions?: SelectItem<MainFilterType>[],
    additionalFilters?: RadioListFilter[],
    isEnabled?: Observable<boolean>,
    dependentFilter?: DependentFilter<DepFilterType>,
  ) {
    super('radio-list', filterKey, collapsible, icon, title, defaultValue, noClear, hiddenOptions, isEnabled)
    this.options = options
    this.additionalFilters = additionalFilters
    this.dependentFilter = dependentFilter
  }
}
