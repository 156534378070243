// eslint-disable-next-line max-classes-per-file
import { Uuid } from '@ftr/contracts/type/shared'
import { LocalDate } from '@js-joda/core'

export class InitialiseUpcomingHearingsListCommand {
  static readonly type = '[Upcoming Hearings List] Initialise Upcoming Hearings List'
  constructor(
    readonly courtSystemId: Uuid,
    readonly courtroomId: Uuid,
    readonly date: LocalDate,
    readonly searchTerm: string | undefined,
  ) {}
}

export class FetchUpcomingHearingsPageCommand {
  static readonly type = '[Upcoming Hearings List] Fetch Upcoming Hearings Page'
  constructor(
    readonly instanceId: string,
    readonly offset: number,
    readonly limit: number,
  ) {}
}

export class FetchNextUpcomingHearingsPageCommand {
  static readonly type = '[Upcoming Hearings List] Fetch Next Upcoming Hearings Page'
  constructor(
    readonly courtroomId: Uuid,
    readonly date: LocalDate,
  ) {}
}

export class MarkUpcomingHearingTriggerAsCompletedCommand {
  static readonly type = '[Upcoming Hearings List] Mark Upcoming Hearing Trigger As Completed'
  constructor(readonly upcomingHearingId: Uuid) {}
}

export class SetOngoingCaseReferenceCommand {
  static readonly type = '[Upcoming Hearings List] Set Ongoing Case Reference'
  constructor(
    readonly courtroomId: Uuid,
    readonly date: LocalDate,
    readonly caseReference: string | undefined,
  ) {}
}
