import { catchError, combineLatest, map, Observable, ObservableInput, of, from as toObservable } from 'rxjs'
import { Primitive } from '../../util'
import { CombinedError } from './combined-error'
import { Failure, Loading, NotAsked, RemoteData } from './remote-data'
import { mapData } from './remote-data.operators'

type ApiResultInput<ResultType, ErrorType> = ObservableInput<RemoteData<ResultType, ErrorType>>

type ApiResultInputTuple<T> = {
  [K in keyof T]: ApiResultInput<T[K], any>
}
type ApiResultTypeOf<A extends ApiResultInput<any, any>> = A extends ApiResultInput<infer T, any> ? T : never

/**
 * An observable RemoteData response from the api
 */
export class ApiResult<TData = null, TError = Error> extends Observable<RemoteData<TData, TError>> {
  static success<TData>(result: TData): ApiResult<TData, never> {
    return of(RemoteData.success(result))
  }

  static failure<TError = Error>(error: TError): ApiResult<never, TError> {
    return of(RemoteData.failure(error))
  }

  static notAsked(): ApiResult<never, never> {
    return of(RemoteData.notAsked())
  }

  static loading(): ApiResult<never, never> {
    return of(RemoteData.loading())
  }

  /**
   * Convert a Promise to a RemoteData.
   */
  static fromPromise<TData>(promise: Promise<TData>): ApiResult<TData> {
    return ApiResult.from(toObservable(promise))
  }

  /**
   * Convert an Observable to a RemoteData.
   *
   * Often used when retrieving data from the store
   */
  static from<TData>(request: Observable<TData>): ApiResult<TData> {
    return request.pipe(
      map(result => RemoteData.success(result)),
      catchError(error => of(RemoteData.failure(error))),
    )
  }

  /**
   * Hidden traverse (if you know what that means)
   *
   * Essentially, It's easy to get Map<string, ApiResult<T>> via MapUtils.buildFromKeys(keys, generator)
   * It's a lot harder to get ApiResult<Map<string, T>> due to the nature of combining.
   *
   * This exists to make it easy.
   *
   * Primary driver is enums, so you can Object.values(some enum) and then return results for each of them
   */
  static generate<TKey extends Primitive, TData>(
    keys: TKey[],
    generator: (key: TKey) => ApiResult<TData>,
  ): ApiResult<Map<TKey, TData>> {
    const alternateGenerator = (key: TKey): ApiResult<readonly [TKey, TData]> =>
      generator(key).pipe(mapData(results => [key, results] as const))
    return ApiResult.combine(keys.map(alternateGenerator)).pipe(mapData(results => new Map(results)))
  }

  /**
   * Combines multiple RemoteData observables into one RemoteData observable which
   * emits values as follows:
   *
   * - When any of the RemoteData observables are `NOT_ASKED`, emit `NOT_ASKED`.
   * - When any of the RemoteData observables are `LOADING`, emit `LOADING`.
   * - When any of the RemoteData observables are `FAILURE`, emit `FAILURE`.
   * - When *all* of the RemoteData observables are `SUCCESS`, emit `SUCCESS`.
   *
   * If you have an observable you want to include in the view model,
   * but it is NOT remote data, see ApiResult.from
   *
   * @param {ApiResultInput<any, any>>} observables
   * @returns {ApiResult<any[], CombinedError>}
   *
   * Note: This method is typed up to 7 inputs.
   * Use combineAny for completely untyped
   *
   */
  static combine<A extends unknown[], ErrorType extends Error = Error>(
    observables: [...ApiResultInputTuple<A>],
  ): ApiResult<A, CombinedError<ErrorType>>
  static combine<T extends Record<string, ApiResultInput<unknown, ErrorType>>, ErrorType extends Error = Error>(
    namedObservables: T,
  ): ApiResult<
    {
      [name in keyof T]: ApiResultTypeOf<T[name]>
    },
    CombinedError<ErrorType>
  >
  static combine<ResultType = any, ErrorType extends Error = Error>(
    arg: ApiResultInput<ResultType, ErrorType>[] | Record<string, ApiResultInput<ResultType, ErrorType>>,
  ): ApiResult<ResultType[] | Record<string, ResultType>, CombinedError<ErrorType>> {
    if (Array.isArray(arg)) {
      return this.combineAny(...arg)
    }
    return this.namedCombine(arg)
  }

  static combineAny<ResultType = any, ErrorType extends Error = Error>(
    ...observables: ApiResultInput<any, any>[]
  ): ApiResult<ResultType[], CombinedError<ErrorType>> {
    return combineLatest(observables).pipe(
      map(remotes => this.nonSuccessfulCombine(remotes) ?? RemoteData.success(remotes.map(remote => remote.data))),
    )
  }

  private static namedCombine<ResultType, ErrorType extends Error = Error>(
    namedObservables: Record<string, ApiResultInput<ResultType, ErrorType>>,
  ): ApiResult<Record<string, ResultType>, CombinedError<ErrorType>> {
    return combineLatest(namedObservables).pipe(
      map(
        remotesObject =>
          this.nonSuccessfulCombine<ErrorType>(Object.values<RemoteData<any, any>>(remotesObject)) ??
          RemoteData.success(
            Object.fromEntries(
              Object.entries(remotesObject).map(([remoteName, remote]) => [remoteName, remote.data as ResultType]),
            ),
          ),
      ),
    )
  }

  private static nonSuccessfulCombine<ErrorType extends Error>(
    remotes: RemoteData<any, any>[],
  ): Failure<never, CombinedError<ErrorType>> | NotAsked | Loading | undefined {
    const errors = remotes.filter(remote => remote.isFailure()).map(remote => remote.error)

    if (errors.length > 0) {
      return RemoteData.failure(new CombinedError<ErrorType>(errors))
    }

    if (remotes.some(remote => remote.isNotAsked())) {
      return RemoteData.notAsked()
    }

    if (remotes.some(remote => remote.isLoading())) {
      return RemoteData.loading()
    }

    return undefined
  }
}
