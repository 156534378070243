/**
 * Returns an iterator over two *SORTED* iterators, which will be the result of merging the two and sorting them
 * iterateSorted([1, 2, 8, 10],[1, 3, 7, 8, 9], (a,b)=> a<b ? -1 : 1) === [1, 1, 2, 3, 7, 8, 8, 9, 10]
 */
export function* iterateSorted<T, K>(
  iter1: IterableIterator<T>,
  iter2: IterableIterator<K>,
  compare: (item1: T, item2: K) => number,
): IterableIterator<T | K> {
  let current1: IteratorResult<T, T> = iter1.next()
  let current2: IteratorResult<K, K> = iter2.next()
  while (!current1.done || !current2.done) {
    if (!current2.value || (!current1.done && compare(current1.value, current2.value) < 0)) {
      yield current1.value
      current1 = iter1.next()
    } else {
      yield current2.value
      current2 = iter2.next()
    }
  }
}

/**
 * Returns an iterator over N *SORTED* iterators, which will be the result of merging them all and sorting them
 * iterateSorted([[1, 2, 8, 10],[1, 3, 7, 8, 9]], (a,b)=> a<b ? -1 : 1) === [1, 1, 2, 3, 7, 8, 8, 9, 10]
 */
export function iterateAllSorted<T>(
  iters: IterableIterator<T>[],
  compare: (item1: T, item2: T) => number,
): IterableIterator<T> {
  let iterator: IterableIterator<T> = [][Symbol.iterator]()
  for (const iter of iters) {
    iterator = iterateSorted(iterator, iter, compare)
  }
  return iterator
}

export function* iterateReversed<T>(arr: readonly T[]): IterableIterator<T> {
  for (let i = arr.length - 1; i >= 0; i--) {
    yield arr[i]
  }
}

/**
 * Returns the item in the iterator which has the lowest value that is selected by the selector.
 * If there are multiple items that share the min value, the first value encountered of these is returned.
 * Like lodash minBy but for iterables
 */
export function minBy<T>(iter: IterableIterator<T> | T[], selector: (item: T) => number): T | undefined {
  let result: { item: T; value: number } | undefined
  for (const item of iter) {
    const value = selector(item)
    if (!result || result.value > value) {
      result = { item, value }
    }
  }
  return result?.item
}

export function mapIt<T, K>(iter: IterableIterator<T>, mapper: (item: T) => K): IterableIterator<K> {
  return (function* () {
    for (const item of iter) {
      yield mapper(item)
    }
  })()
}
