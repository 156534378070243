/**
 * Courts only consider something "live" when it is off the record.
 */
export enum OnRecordState {
  /**
   * Is Recording and is On The Record
   */
  OnRecord = 'on-record',

  /*
   * Is Recording and is Off The Record
   */
  OffRecord = 'off-record',

  /**
   * Is Not Recording
   */
  NotRecording = 'not-recording',
}
