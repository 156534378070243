import { Injectable } from '@angular/core'
import { Uuid } from '@ftr/contracts/type/shared'
import { ChildLocation, LocationService, RootLocation } from '@ftr/data-location'
import { ApiResult, RemoteData, mapData } from '@ftr/foundation'
import { ToggleableRootLocation } from '@ftr/ui-location'
import { startWith } from 'rxjs'

type ToggleableCourthousesCache = Record<string, ToggleableRootLocation[]>

@Injectable()
export class OrderLocationService {
  /**
   * Cache toggleable courthouses to minimise calls to the api
   */
  private readonly toggleableCourthousesCache: ToggleableCourthousesCache = {}

  constructor(private readonly locationService: LocationService) {}

  // TODO (FPD-220) getAllRootsForCourtSystem can just return ApiResult rather then observable
  getToggleableCourthouses(courtSystemId: Uuid): ApiResult<ToggleableRootLocation[]> {
    if (this.toggleableCourthousesCache[courtSystemId]) {
      return ApiResult.success(this.toggleableCourthousesCache[courtSystemId])
    }
    return this.locationService.getAllRootsForCourtSystem(courtSystemId).pipe(
      mapData(courthouses => {
        const courtrooms = getCourtrooms(courthouses)
        const toggleableCourthouses = reduceCourtroomsToHouses(courtrooms)
        this.toggleableCourthousesCache[courtSystemId] = toggleableCourthouses
        return toggleableCourthouses
      }),
      startWith(RemoteData.loading()),
    )
  }
}

function getCourtrooms(courthouses: RootLocation[]): ChildLocation[] {
  return courthouses.reduce((acc, val) => acc.concat(val.children), [] as ChildLocation[])
}

function reduceCourtroomsToHouses(locations: ChildLocation[]): ToggleableRootLocation[] {
  return locations.reduce((accumulator, courtroom) => {
    let index = accumulator.findIndex(courthouse => courthouse.id === courtroom.parent!.id)
    /*
     * Note: there appears to be a performance regression in this code from when strict null checks were enabled
     * See FTRX-3239 for notes
     */
    if (index === -1) {
      accumulator.push({
        ...courtroom.parent!,
        children: [],
        open: true,
      })
      index = accumulator.length - 1
    }
    accumulator[index].children.push({ ...courtroom, isShowing: true })
    return accumulator
  }, [] as ToggleableRootLocation[])
}
