import { Injectable } from '@angular/core'
import { ApiClient, ApiClientFactory, NotFoundApiError, serializeHttpParams } from '@ftr/api-shared'
import { AudioPlaybackFeatureGetByCourtSystemRequest } from '@ftr/contracts/api/audio-playback-feature'
import { AudioPlaybackFeature } from '@ftr/contracts/read/audio-playback-feature'
import { Uuid } from '@ftr/contracts/type/shared'
import { ApiResult, createAndAssign, mapData, recoverType, unwrapData } from '@ftr/foundation'
import { Observable, shareReplay } from 'rxjs'

/**
 * Manages audio playback feature API calls available to non-global admins
 */
@Injectable({
  providedIn: 'root',
})
export class AudioPlaybackFeatureService {
  private readonly apiClient: ApiClient

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClient = apiClientFactory.build('/audio-playback-feature')
  }

  isEnabled(courtSystemId: Uuid): Observable<boolean> {
    return this.getByCourtSystem(courtSystemId).pipe(
      mapData(feature => feature.isEnabled),
      recoverType(NotFoundApiError, false),
      unwrapData(),
      shareReplay(1),
    )
  }

  isAutoscriptEnabled(courtSystemId: Uuid): ApiResult<boolean> {
    return this.getByCourtSystem(courtSystemId).pipe(
      mapData(feature => feature.configuration?.autoscriptEnabled ?? false),
      recoverType(NotFoundApiError, false),
      shareReplay(1),
    )
  }

  getByCourtSystem(courtSystemId: Uuid): ApiResult<AudioPlaybackFeature> {
    return this.apiClient.get({
      path: '/byCourtSystem',
      responseBodyType: AudioPlaybackFeature,
      params: serializeHttpParams(createAndAssign(AudioPlaybackFeatureGetByCourtSystemRequest, { courtSystemId })),
    })
  }
}
