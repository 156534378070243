import { Validators } from '@angular/forms'
import { NAME_MAX_LENGTH, NAME_MIN_LENGTH } from '@ftr/contracts/api/user'
import { isLatinExtendedName } from './latin-extended-text.validator'

export const USER_NAME_VALIDATORS = [
  Validators.required,
  isLatinExtendedName(false, 'Please provide a valid name, valid punctuation includes . , \' " - '),
  Validators.minLength(NAME_MIN_LENGTH),
  Validators.maxLength(NAME_MAX_LENGTH),
]
