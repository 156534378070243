import { Component } from '@angular/core'
import { IconComponent, TooltipModule } from '@ftr/foundation'
import { LogoTheme } from '../../types'
import { FtrLogoComponent } from '../ftr-logo'
@Component({
  selector: 'ftr-header-logged-out',
  templateUrl: './header-logged-out.component.html',
  styleUrls: ['./header-logged-out.component.css'],
  standalone: true,
  imports: [FtrLogoComponent, TooltipModule, IconComponent],
})
export class HeaderLoggedOutComponent {
  readonly logoThemes = LogoTheme
}
