export type TristateValue = boolean | 'indeterminate'

export function getTristateValue(selectedCount: number, totalCount: number): TristateValue {
  if (selectedCount === 0) {
    return false
  } else if (selectedCount === totalCount) {
    return true
  }
  return 'indeterminate'
}

export class CheckboxItem {
  // Used for filtering
  readonly normalizedName: string
  // Additional data that can be used for filtering
  normalizedFilterMetadata: string

  constructor(
    readonly title: string,
    readonly id: string,
    public selected: boolean,
    readonly hidden: boolean = false,
    readonly icon: boolean = true,
    readonly disabled: boolean = false,
    // Additional searchable data
    filterMetadata = '',
  ) {
    this.normalizedName = title.toLowerCase().trim()
    this.normalizedFilterMetadata = filterMetadata
  }

  withFilter(normalizedFilter: string): CheckboxItem {
    return this.withHidden(
      !this.normalizedName.includes(normalizedFilter) && !this.normalizedFilterMetadata.includes(normalizedFilter),
    )
  }

  withFilterAndSelected(normalizedFilter: string): CheckboxItem {
    return this.withHidden(
      !this.normalizedName.includes(normalizedFilter) &&
        !this.normalizedFilterMetadata.includes(normalizedFilter) &&
        !this.selected,
    )
  }

  withHidden(hidden: boolean): CheckboxItem {
    return new CheckboxItem(this.title, this.id, this.selected, hidden, this.icon, this.disabled)
  }
}
