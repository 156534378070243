import { NgxsConfig } from '@ngxs/store/src/symbols'
import { environment } from '../environments/environment'

/**
 * We have elected to follow the proposed ngxs v4 selector options, to avoid the full state being injected
 * as the first parameter in every @Selector decorator.
 *
 * @usage With injectContainerState: false
 *
 * Example 1: This selector will emit a value any time anything in state changes.
 * @Selector() // Injects SomeState
 * static function someFoo (state: SomeStateModel): boolean {
 *     return state.someFoo
 * }
 *
 * Example 2: This selector will emit a value only when 'someFoo' changes.
 * @Selector([SomeState.someFoo]) // Injects SomeState.someFoo ONLY
 * static function someFoo (someFoo: SomeStateModel['someFoo']): boolean {
 *     return someFoo && someFoo.foo.isSuccess()
 * }
 *
 * Example 3:
 * @Selector([SomeState.someFoo, SomeState.otherFoo]) // Injects SomeState.someFoo and SomeState.otherFoo
 * static function someFoo (someFoo: SomeStateModel['someFoo'], otherFoo: SomeStateModel['otherFoo']): boolean {
 *     return someFoo.foo && otherFoo.foo
 * }
 * @see https://www.ngxs.io/concepts/select#selector-options
 * @see https://www.ngxs.io/concepts/select#joining-selectors
 */
export const NGXS_OPTIONS: Partial<NgxsConfig> = {
  developmentMode: !environment.production,
  selectorOptions: { injectContainerState: false, suppressErrors: false },
}
