import {
  AudioLineItem,
  CustomerTranscriptLineItem,
  LineItem,
  RealTimeLineItem,
  TranscriptLineItem as TranscriptLineItemWithoutTranscript,
} from '@ftr/contracts/api/order'
import { assertUnreachable } from '@ftr/contracts/shared/assertUnreachable'
import { LineItemType } from '@ftr/contracts/type/order'

export type AudioOrTranscriptLineItem = AudioLineItem | TranscriptLineItem

export type AudioOrRealTimeLineItem = AudioLineItem | RealTimeLineItem

interface MatchOrderLineItemFunctions<T> {
  audio: (x: AudioLineItem) => T
  realtime: (x: RealTimeLineItem) => T
  transcript: (x: TranscriptLineItem) => T
}

type TranscriptLineItem = CustomerTranscriptLineItem | TranscriptLineItemWithoutTranscript

export function isAudioLineItem(lineItem: LineItem): lineItem is AudioLineItem {
  return lineItem.type === LineItemType.Audio
}

export function isAudioOrRealTimeLineItem(lineItem: LineItem): lineItem is AudioOrRealTimeLineItem {
  return isAudioLineItem(lineItem) || isRealTimeLineItem(lineItem)
}

export function isAudioOrTranscriptLineItem(lineItem: LineItem): lineItem is AudioOrTranscriptLineItem {
  return isAudioLineItem(lineItem) || isTranscriptLineItem(lineItem)
}

export function isRealTimeLineItem(lineItem: LineItem): lineItem is RealTimeLineItem {
  return lineItem.type === LineItemType.RealTime
}

export function isTranscriptLineItem(lineItem: LineItem): lineItem is TranscriptLineItem {
  return lineItem.type === LineItemType.CertifiedTranscript
}

export function matchLineItemType<T>(
  lineItem: LineItem,
  { audio, realtime, transcript }: MatchOrderLineItemFunctions<T>,
): T {
  switch (lineItem.type) {
    case LineItemType.Audio:
      return audio(lineItem as AudioLineItem)
    case LineItemType.CertifiedTranscript:
      return transcript(lineItem as TranscriptLineItem)
    case LineItemType.RealTime:
      return realtime(lineItem as RealTimeLineItem)
    default:
      assertUnreachable(lineItem.type)
  }
}
