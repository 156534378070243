/* eslint-disable max-classes-per-file */
import { Uuid } from '@ftr/contracts/type/shared'
import { CourtSystemUseConditionsFormData, CourtSystemUseConditionsStateModel } from './use-conditions.model'

export class SetCourtSystemUseConditionsFormDataStateAction {
  static readonly type = '[UseConditions] Set Court System Use Conditions Form Data State'

  constructor(
    readonly courtSystemId: Uuid,
    readonly formData?: CourtSystemUseConditionsFormData,
  ) {}
}

export class SetCourtSystemUseConditionsStateAction {
  static readonly type = '[UseConditions] Set Court System Use Conditions State'

  constructor(
    readonly courtSystemId: Uuid,
    readonly state: CourtSystemUseConditionsStateModel,
  ) {}
}

export class FetchCourtSystemUseConditionsAction {
  static readonly type = '[UseConditions] Fetch Court System Use Conditions'

  constructor(
    readonly courtSystemId: Uuid,
    readonly bypassCache?: boolean,
  ) {}
}

export class FetchConditionsAcceptedByUserAction {
  static readonly type = '[UseConditions] Fetch Conditions Accepted By User'

  constructor(
    readonly courtSystemId: Uuid,
    readonly bypassCache?: boolean,
  ) {}
}
