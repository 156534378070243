<div class="footer-admin">
  <div class="footer-admin__row row justify-content-between">
    <div class="footer-admin__court-menu col-10 col-md-9">
      <ftr-logo [theme]="logoThemes.White"> </ftr-logo>
      <div class="court-menu">
        <div class="court-menu__heading">
          {{ (currentCourtSystem$ | async)?.name }}
        </div>
        <div class="court-menu__container" [class]="columnClass">
          @for (menuItem of courtMenuItems | async; track menuItem) {
            <a data-track [routerLink]="menuItem.path" [queryParams]="menuItem.queryParams" class="court-menu__link">
              {{ menuItem.title }}
            </a>
          }
        </div>
      </div>
    </div>
    <div class="footer-admin__general-menu justify-content-between">
      <div class="general-menu">
        @for (menuItem of generalMenuItems | async; track menuItem) {
          <a data-track [routerLink]="menuItem.path" [queryParams]="menuItem.queryParams" class="general-menu__link">
            {{ menuItem.title }}
          </a>
        }
      </div>
      <div class="general-menu">
        <a class="general-menu__link" href="https://fortherecord.releasenotes.io/" target="_blank">Product Updates</a>
        <ftr-footer-help-links></ftr-footer-help-links>
      </div>
    </div>
  </div>
</div>
