import { CdkPortalOutlet, PortalModule, PortalOutlet } from '@angular/cdk/portal'
import { Component, OnInit, ViewChild } from '@angular/core'
import { PortalContent, PortalService } from './portal.service'

/*
 This component is a tiny wrapper around CDK's Portal which just exists to
 allow us to render content visually on top of the rest of the content,
 regardless of DOM tree depth of the source component.

 <ftr-portal></ftr-portal> should be rendered at the bottom of the App
 component's DOM tree
 */
@Component({
  selector: 'ftr-portal',
  template: '<ng-container *cdkPortalOutlet></ng-container>',
  standalone: true,
  imports: [PortalModule],
})
export class PortalComponent implements OnInit {
  @ViewChild(CdkPortalOutlet, { static: false }) portalOutlet: PortalOutlet

  constructor(private portalService: PortalService) {}

  ngOnInit(): void {
    this.portalService.getPortal().subscribe((portal?: PortalContent) => {
      if (this.portalOutlet) {
        this.portalOutlet.detach()
        if (portal?.content !== undefined) {
          this.portalOutlet.attach(portal.content)
        }
      }
    })
  }
}
