import { Injectable } from '@angular/core'
import { ApiClient, ApiClientFactory, serializeHttpParams } from '@ftr/api-shared'
import { PagedResult } from '@ftr/contracts/api/core/response/PagedResult'
import { CourtSystemIdQuery } from '@ftr/contracts/api/shared'
import {
  CreateTurnaroundBody,
  FindTurnaroundsParams,
  Turnaround,
  UpdateTurnaroundBody,
} from '@ftr/contracts/api/transcript-turnaround'
import { Uuid } from '@ftr/contracts/type/shared'
import { TurnaroundUnit } from '@ftr/contracts/type/transcript'
import { SelectItem } from '@ftr/forms'
import { ApiResult, DateFormat, createAndAssign, mapData } from '@ftr/foundation'
import { classToPlain } from '@ftr/serialization'

@Injectable()
export class TranscriptTurnaroundService {
  private readonly apiClient: ApiClient

  constructor(apiClientFactory: ApiClientFactory) {
    this.apiClient = apiClientFactory.build('transcript-turnaround')
  }

  listTurnarounds(courtSystemId: Uuid, billingGroupId?: Uuid): ApiResult<PagedResult<Turnaround>> {
    return this.apiClient
      .get<Turnaround, Turnaround[]>({
        params: serializeHttpParams(createAndAssign(FindTurnaroundsParams, { courtSystemId, billingGroupId })),
        responseBodyType: Turnaround,
      })
      .pipe(mapData(turnarounds => wrapInPagedResult(turnarounds)))
  }

  get(turnaroundId: Uuid): ApiResult<Turnaround> {
    return this.apiClient.get({ path: turnaroundId, responseBodyType: Turnaround })
  }

  add(params: CreateTurnaroundBody): ApiResult<Turnaround> {
    return this.apiClient.post({
      body: classToPlain(params),
      responseBodyType: Turnaround,
    })
  }

  update(turnaroundId: Uuid, params: UpdateTurnaroundBody): ApiResult<Turnaround> {
    return this.apiClient.patch({
      path: turnaroundId,
      body: classToPlain(params),
      responseBodyType: Turnaround,
    })
  }

  remove(turnaroundId: Uuid): ApiResult {
    return this.apiClient.delete({
      path: turnaroundId,
    })
  }

  listNames(courtSystemId: Uuid): ApiResult<string[]> {
    return this.apiClient.get({
      path: '/names',
      params: serializeHttpParams(createAndAssign(CourtSystemIdQuery, { courtSystemId })),
    })
  }
}

/**
 * @see turnaroundIsValid
 */
export const INVALID_TURNAROUND_MESSAGE =
  'The turnaround for this order is no longer valid. Please select a new turnaround.'

/**
 * Creates a list of all active turnarounds plus the current turnaround if archived
 */
export function buildAvailableTurnarounds(
  activeTurnarounds: Turnaround[],
  currentTurnaround: Turnaround,
): Turnaround[] {
  const availableTurnarounds = [...activeTurnarounds]

  // Ensure the chosen turnaround appears as a valid option. This is for cases when an order is being resubmitted
  // and the original turnaround has been deleted.
  if (currentTurnaround && !availableTurnarounds.find(t => t.id === currentTurnaround.id)) {
    availableTurnarounds.push(currentTurnaround)
  }

  return availableTurnarounds.sort(sortByRateAscending)
}

/**
 * Creates the selectItem list required by ftr-select
 */
export function buildTurnaroundSelectItems(availableTurnarounds: Turnaround[]): SelectItem<number>[] {
  return availableTurnarounds.map((turnaround, index) => ({
    key: turnaround.name,
    value: index,
  }))
}

export function getTurnaroundIndex(turnarounds: Turnaround[], turnaround?: Turnaround): number {
  if (!turnaround) {
    return 0
  }
  return turnarounds.findIndex(t => t.id === turnaround.id)
}

export function getTurnaroundByIndex(turnarounds: Turnaround[], index: number): Turnaround | undefined {
  return turnarounds[index]
}

/**
 * Returns true if activeTurnarounds includes currentTurnaround.
 */
export function turnaroundIsValid(activeTurnarounds: Turnaround[], currentTurnaround: Turnaround): boolean {
  return activeTurnarounds.some(turnaround => turnaround.id === currentTurnaround.id)
}

export function getDateFormatForTurnaround(turnaround: Turnaround | undefined, long = false): DateFormat {
  if (turnaround?.duration.unit === TurnaroundUnit.BusinessHours) {
    return long ? DateFormat.DateAndTime : DateFormat.DateAndTimeShorter
  }

  return long ? DateFormat.DateLong : DateFormat.Date
}

function sortByRateAscending(a: Turnaround, b: Turnaround): number {
  return a.rate.amount.comparedTo(b.rate.amount)
}

function wrapInPagedResult<T>(data: T[]): PagedResult<T> {
  return {
    meta: {
      number: 1,
      size: data.length,
      totalItems: data.length,
    },
    items: data,
  }
}
