<div
  [ngClass]="['filter-list-card', displayMode === 'COMPACT' ? 'filter-list-card--compact' : '', className]"
  tabindex="0"
  role="button"
  (click)="handleOnClick($event)"
  (keyup.enter)="handleOnEnter($event)"
  (keydown.arrowleft)="setFocusToPrevious($event)"
  (keydown.arrowright)="setFocusToNext($event)"
  (keydown.arrowUp)="setFocusToAbove($event)"
  (keydown.arrowDown)="setFocusToBelow($event)"
>
  <ng-content></ng-content>
</div>
