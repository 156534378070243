import { AsyncPipe } from '@angular/common'
import { Component, ViewEncapsulation } from '@angular/core'
import { Observable, map } from 'rxjs'
import { MarkdownDirective } from '../../directives'
import { DisplayableNotification } from './notification'
import { NotificationComponent } from './notification.component'
import { NotificationService } from './notification.service'

/**
 * Shows notifications scheduled with <code>NotificationService</code>. You must include these in your
 * templates where you want notifications to be shown.
 *
 * If this component proves to be useful, potential nice-to-have features to add would be:
 * - Dismissable notifications.
 * - Floating notifications which do not require the user to be scrolled to
 *   the top of the page to view them.
 */
@Component({
  selector: 'ftr-notification-flash',
  templateUrl: './notification-flash.component.html',
  encapsulation: ViewEncapsulation.None,
  imports: [NotificationComponent, AsyncPipe, MarkdownDirective],
  standalone: true,
})
export class NotificationFlashComponent {
  notifications: Observable<DisplayableNotification[]>
  hasNotificationsToShow: Observable<boolean>

  constructor(private readonly notificationService: NotificationService) {
    this.notifications = this.notificationService.notifications
    this.hasNotificationsToShow = this.notifications.pipe(map(notifications => notifications.length > 0))
  }
}
