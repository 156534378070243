import { Component, TemplateRef, ViewChild } from '@angular/core'
import { DestroySubscribers, ToastComponent, ToastService } from '@ftr/foundation'
import { ActiveToast } from 'ngx-toastr'
import { takeUntil } from 'rxjs'
import { DesktopApiService } from '~app/services/desktop-api/desktop-api.service'
import { WindowRefService } from '~app/services/window/window-ref.service'

@Component({
  selector: 'ftr-update-app-toast',
  templateUrl: './update-app-toast.component.html',
})
export class UpdateAppToastComponent extends DestroySubscribers {
  @ViewChild('updateMessage') updateMessage: TemplateRef<any>

  private currentToast: ActiveToast<ToastComponent> | undefined

  constructor(
    private readonly windowRefService: WindowRefService,
    private readonly desktopApiService: DesktopApiService,
    private readonly toastService: ToastService,
  ) {
    super()

    this.desktopApiService
      .getUpdateReadyAsObservable()
      .pipe(takeUntil(this.finalize))
      .subscribe(isMajorUpdate => {
        if (this.currentToast) {
          this.currentToast.toastRef.close()
        }

        this.currentToast = this.toastService.info(
          this.updateMessage,
          isMajorUpdate ? 'Major Update Available' : 'Update Available',
          {
            disableTimeOut: true,
            hideClose: isMajorUpdate,
          },
        )
      })
  }

  restartApp(toast: ToastComponent): void {
    this.currentToast = undefined
    this.windowRefService.getDesktopElectronApi()?.restartApp?.()
    toast.remove()
  }
}
