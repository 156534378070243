import { Uuid } from '@ftr/contracts/type/shared'
import { ArrayUtils, Identifiable, MapUtils } from '@ftr/foundation'
import { CachedValue } from './cached-value'

export const FIVE_MINUTES = 5 * 60 * 1000
export const DEFAULT_CACHE_SIZE = 1000

export interface CacheOptions {
  evictionAgeMs: number
  maxCount: number
}

const defaultCacheOptions: CacheOptions = {
  evictionAgeMs: FIVE_MINUTES,
  maxCount: DEFAULT_CACHE_SIZE,
}

/**
 * Time based immutable cache, which has a max capacity, and automatically evicts old entries when adding new ones.
 */
export class TimedCache<T extends Identifiable> {
  readonly options: CacheOptions

  constructor(
    private readonly values: Map<Uuid, CachedValue<T>> = new Map(),
    options?: Partial<CacheOptions>,
  ) {
    this.options = {
      ...defaultCacheOptions,
      ...options,
    }
  }

  get size(): number {
    return this.values.size
  }

  getMissingIds(ids: Uuid[], ageMs: number = this.options.evictionAgeMs): Uuid[] {
    return ids.filter(id => !this.has(id, ageMs))
  }

  hasAll(ids: Uuid[], ageMs: number = this.options.evictionAgeMs): boolean {
    return ids.every(id => this.has(id, ageMs))
  }

  has(id: Uuid, ageMs: number = this.options.evictionAgeMs): boolean {
    const value = this.values.get(id)
    if (value) {
      return value.hasNotExpired(ageMs)
    }
    return false
  }

  get(id: Uuid, ageMs: number = this.options.evictionAgeMs): T | undefined {
    if (this.has(id, ageMs)) {
      return this.values.get(id)?.value
    }
    return
  }

  getAll(ids: Uuid[], ageMs: number = this.options.evictionAgeMs): T[] {
    return ArrayUtils.collect(ids, x => this.get(x, ageMs))
  }

  addAll(...ts: T[]): TimedCache<T> {
    const allValues = Array.of(...this.values.values(), ...ts.map(t => new CachedValue(t)))

    return new TimedCache(
      MapUtils.buildFromValues(this.getValuesToCache(allValues), x => x.value.id),
      this.options,
    )
  }

  private getValuesToCache(allValues: CachedValue<T>[]): CachedValue<T>[] {
    const validValues = allValues.filter(x => x.hasNotExpired(this.options.evictionAgeMs))
    if (validValues.length > this.options.maxCount) {
      return validValues.sort((a, b) => b.cachedAt.getTime() - a.cachedAt.getTime()).slice(0, this.options.maxCount)
    }
    return validValues
  }
}
